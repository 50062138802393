import React, { useState } from "react";
import ModalView from "../../../../stylized/ModalView";
import TextBox from "../../../../stylized/TextBox";
import Button from "@material-ui/core/Button";
import { validateName } from "../../../../../bae-client/Validations";

function EditRippleNameModal({
    rippleName,
    updateRippleName,
    open,
    closeModal,
    rippledList,
}) {
    const updatedRippleName = rippleName === "" ? "Unsaved Ripple" : rippleName;
    const [updatedName, setupdatedName] = useState(updatedRippleName);
    const [error, setError] = useState("");
    const existingRippleNames = rippledList.map((ripple) =>
        ripple.rippleName.toUpperCase().trim()
    );
    const handleSubmit = () => {
        let isValidName = validateName(updatedName);
        if (isValidName !== true) {
            setError(isValidName);
            return;
        } else if (
            existingRippleNames.includes(updatedName.toUpperCase().trim()) &&
            updatedName !== "Unsaved Ripple"
        ) {
            setError("Ripple name already exists!");
            return;
        } else if (/[^a-zA-Z0-9\s]+/.test(updatedName)) {
            setError("Special characters are not allowed in the name.");
            return;
        }
        updateRippleName(updatedName);
    };
    const setFromText = (value, id, event) => {
        setError("");
        switch (id) {
            case "rippleName":
                {
                    setupdatedName(value);
                }
                break;
            default:
                break;
        }
    };
    const handleFocus = (event) => event.target.select();

    return (
        <ModalView
            type="submit-cancel"
            id="doneRippleNameBtn"
            value="Rename"
            onSubmit={handleSubmit}
            showHeader="false"
            footer="false"
            show={open}
            size="md"
            onHide={closeModal}
            title="Rename"
            onclick={closeModal}
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div className="addWorkspaceModal addspace">Rename Ripple</div>

                <TextBox
                    id="rippleName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onchange={setFromText}
                    // label={
                    //     rippleName === "" ? "Name your Ripple..." : rippleName
                    // }
                    value={updatedName}
                    onFocus={handleFocus}
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {error && (
                <>
                    <small style={{ color: "red", paddingLeft: "18px" }}>
                        {error}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
export default EditRippleNameModal;
