import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React from "react";

export default function PopoverViewCustom(props) {
    const [showChildren, setShowChildren] = React.useState(true);
    return (
        <PopupState variant="popover" popupId={props.id}>
            {(popupState) => (
                <div
                    className="w-100"
                    onClick={(e) => {
                        if (
                            e.target.parentElement.tagName === "SPAN" ||
                            e.target.parentElement.tagName === "BUTTON"
                        ) {
                            setShowChildren(true);
                            props.setReloadResult &&
                                props.setReloadResult(true);
                        } else {
                            setShowChildren(false);
                        }
                    }}
                >
                    <Button
                        id={props.id}
                        className={"rippleBtnFont dropdownBtn"}
                        style={{ background: "#fbfbfb", color: "black" }}
                        variant="contained"
                        color="primary"
                        {...bindTrigger(popupState)}
                    >
                        <span
                            className="textEllipsis"
                            title={props.title || props.btnLabel}
                        >
                            {props.btnLabel}
                        </span>
                        <Icon
                            className={props.iconClass + " dropdownIcon"}
                        ></Icon>
                    </Button>
                    {!props.isDropdown ? (
                        <Popover
                            id={props.id}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            style={{ width: "14rem" }}
                            onClick={(e) => {
                                setShowChildren(false);
                                !(e.target.innerHTML || e.target.id) &&
                                    props.setReloadResult &&
                                    props.setReloadResult(false);
                            }}
                        >
                            {props.children}
                        </Popover>
                    ) : (
                        showChildren && (
                            <Popover
                                id={props.id}
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                style={{ width: "14rem" }}
                                onClick={() => {
                                    setShowChildren(false);
                                }}
                            >
                                {props.children}
                            </Popover>
                        )
                    )}
                </div>
            )}
        </PopupState>
    );
}
