import React, { Component } from "react";

class InlineRow extends Component {
    constructor(props) {
        super(props);
    }

    renderClassname = () => {
        if (this.props.classname !== undefined) {
            return this.props.classname;
        } else return "";
    };
    handleClick = () => {
        if (this.props.onclick !== undefined) {
            this.props.onclick();
        }
    };
    render() {
        return (
            <div
                className={
                    (this.props.noRow ? "" : "row ") + this.renderClassname()
                }
                onClick={this.handleClick}
                id={this.props.id !== undefined ? this.props.id : null}
            >
                {this.props.children}
            </div>
        );
    }
}

export default InlineRow;
