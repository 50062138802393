import React, { Component } from "react";
import { Handle } from "react-flow-renderer";

const pointsOnCircumferenceOfCircle = (radius, angle, cx, cy) => {
    angle = angle * (Math.PI / 180); // Convert from Degrees to Radians
    const x = cx + radius * Math.cos(angle);
    const y = cy + radius * Math.sin(angle);
    return { xpoint: x, ypoint: y };
};

const nodeIdentifier = {
    fontWeight: "bold",
    fontSize: "20px",
    color: "black",
    //position: "absolute",
    alignItems: "center",
    lineHeight: "16px",
    top: "85px",
    left: "-2px",
    height: "40px",
    //width: "105px",
    //minWidth: "90px",
    width: "max-content",
    minWidth: "100px",
    padding: "5px",
    backgroundColor: "white",
    border: "1px solid  #3E4345",
    borderRadius: "6px",
};
const UCCircle = (props) => {
    let test1 = pointsOnCircumferenceOfCircle(50, 0, 50, 50);
    let test2 = pointsOnCircumferenceOfCircle(50, 90, 50, 50);
    let test3 = pointsOnCircumferenceOfCircle(50, 180, 50, 50);
    let test4 = pointsOnCircumferenceOfCircle(50, 270, 50, 50);
    let nodeImage = `./images/ControlTower/${props.data.nodeImage}.png` || "";
    return (
        <div
            className="doCenter"
            id={props.id}
            style={{ flexDirection: "column" }}
        >
            <div style={props.data.style}>
                <Handle
                    id="s1"
                    type="source"
                    position="left"
                    style={{
                        top: test1.ypoint,
                        left: test1.xpoint,
                        borderRadius: 0,
                        opacity: "0",
                    }}
                />
                <Handle
                    id="s2"
                    type="source"
                    position="left"
                    style={{
                        top: test2.ypoint,
                        left: test2.xpoint,
                        borderRadius: 0,
                        opacity: "0",
                    }}
                />
                <div>
                    <img src={nodeImage} alt="" />
                </div>
                <Handle
                    id="t1"
                    type="target"
                    position="right"
                    style={{
                        top: test3.ypoint,
                        left: test3.xpoint,
                        borderRadius: 0,
                        opacity: "0",
                    }}
                />
                <Handle
                    id="t2"
                    type="target"
                    position="right"
                    style={{
                        top: test4.ypoint,
                        left: test4.xpoint,
                        borderRadius: 0,
                        opacity: "0",
                    }}
                />
            </div>
            <div className="doCenter" style={nodeIdentifier}>
                <span>{props.data.text}</span>
            </div>
        </div>
    );
};

export default UCCircle;
