import React, { memo, useEffect, useState } from "react";
import { Handle } from "react-flow-renderer";
import Step from "./Step";
export default memo(({ data, isConnectable }) => {
    const [expectedDateMap, setexpectedDateMap] = useState(null);
    useEffect(() => {
        if (data?.instanceSteps?.length) {
            let instanceMap = new Map();
            const getActualDate = (prevStep, duration) => {
                if (!prevStep) return "";
                if (instanceMap.get(prevStep.expectedDurationFrom)) {
                    instanceMap.set(prevStep.instanceStepId, {
                        expectedDate:
                            new Date(
                                instanceMap.get(
                                    prevStep?.expectedDurationFrom
                                )?.expectedDate
                            ).getTime() +
                            duration * 60000,
                    });
                    return (
                        new Date(
                            instanceMap.get(
                                prevStep.expectedDurationFrom
                            ).expectedDate
                        ).getTime() +
                        duration * 60000
                    );
                }
            };
            const getExpectedDateForStep = (instanceStep, index) => {
                if (!instanceStep) return "";
                if (
                    instanceStep.expectedDate ||
                    instanceStep.instanceBlockDocumentName
                )
                    instanceMap.set(instanceStep.instanceStepId, {
                        expectedDate:
                            instanceStep?.expectedDate ||
                            instanceStep.actualDate,
                    });
                if (instanceStep.actualDate) return;

                getActualDate(instanceStep, instanceStep.expectedDuration);
            };
            setexpectedDateMap(instanceMap);
            data?.instanceSteps.map((item, index) => {
                getExpectedDateForStep(item, index);
            });
        }
    }, []);
    return (
        <>
            <Handle
                type="target"
                position="left"
                style={{ color: "#1B75BC", stroke: "#1B75BC" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            {/* <div
                style={{
                    padding: "12px",
                    minWidth: "130px",
                    height: "45px",
                    maxWidth: "200px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}
            > */}
            <Step
                key={data.genericStepId}
                instanceDetails={data}
                data={data}
                genericId={data.genericId}
                expectedDateMap={expectedDateMap}
            ></Step>
            {/* </div> */}

            <Handle
                type="source"
                position="right"
                id="b"
                style={{ color: "#1B75BC", stroke: "#1B75BC" }}
                isConnectable={isConnectable}
            />
        </>
    );
});
