import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setSelectedAlertIndex,
    setAlertData,
    setActivePage,
    setControlTowerData,
    setActiveRipple,
} from "../../../actions";
import { setChipArray } from "../../../actions/Rippleaction";
import CardView from "../../stylized/CardView";
import Column from "../../stylized/Column";
import IconView from "../../stylized/IconView";
import InlineRow from "../../stylized/InlineRow";
import PopoverView from "../../stylized/PopoverView";
import ListAlertView from "../../stylized/ListAlertView";
import SliderView from "../../stylized/SliderView";
import "./AlertListViewer.css";
import Button from "../../stylized/Button";
import http from "../../../bae-client/HTTP";
import Ripple from "../../../bae-client/Ripple";
class AlertViewer extends Component {
    constructor(props) {
        super(props);
        this.isReadOnlyUser =
            sessionStorage.getItem("isReadOnlyUser") === "true";
        this.state = {
            homeAlerts: null,
            popoverTarget: null,
            showPopover: false,
            listStartPosition: 0,
            listEndPosition: 0,
        };
        this.currentAlertId = null;
    }
    componentDidMount() {
        // this.setState({ listEndPosition: this.props.alertsCountInRow });
    }
    renderPopover = () => {
        return (
            <PopoverView
                id={"menu_"}
                show={this.state.showPopover}
                anchorEl={this.state.popoverTarget}
                closePopover={this.closePopover}
            >
                {this.renderMenuItems()}
            </PopoverView>
        );
    };
    showPopover = (e, id) => {
        this.props.handleSelectedAlert("");
        let alertId;
        if (e.target && e.target.id) {
            alertId = e.target.id.substr(4);
            this.props.handleSelectedAlert(alertId);
        }
        this.props.setSelectedAlertIndex(id);
        this.setState({ showPopover: true, popoverTarget: e.currentTarget });
        // return <PopoverView id={"menu_" + e.currentTarget.id} show={this.state.showPopover} anchorEl={this.state.popoverTarget}></PopoverView>
    };
    closePopover = (e) => {
        this.setState({ showPopover: false, popoverTarget: null });
    };
    renderMenuItems = () => {
        if (this.props.menuItems !== undefined) {
            return this.props.menuItems;
        }
    };
    titleMenuClick = (currentAlertId) => {};
    renderIcon = (iconType, i, classname, rowId) => {
        if (iconType === "alert") {
            return (
                <IconView
                    id={"img_" + i}
                    dataType="leftIcon"
                    src="./images/redAlert.svg"
                    className={
                        classname !== undefined ? classname : "alertHeaderIcon"
                    }
                />
            );
        } else if (iconType.toLowerCase() === "reminder") {
            return (
                <IconView
                    id={"img_" + i}
                    dataType="leftIcon"
                    src="./images/remiderIcon.svg"
                    className={
                        classname !== undefined ? classname : "alertHeaderIcon"
                    }
                />
            );
        } else if (iconType === "notification") {
            return (
                <IconView
                    id={"img_" + i}
                    className={
                        classname !== undefined ? classname : "alertHeaderIcon"
                    }
                    dataType="leftIcon"
                    src="./images/Vector.png"
                />
            );
            // iconPath = "./images/Vector.png";
        } else if (iconType === "ripple") {
            return (
                <IconView
                    id={"img_" + i}
                    className={
                        classname !== undefined ? classname : "alertHeaderIcon"
                    }
                    dataType="leftIcon"
                    src="./images/Ripple.svg"
                />
            );
        } else if (iconType === "menu") {
            return (
                <IconView
                    id={"img_" + (rowId ? rowId : i)}
                    dataType="rightIcon"
                    src="./images/alertMenu.png"
                    onclick={() => this.titleMenuClick(this.currentAlertId)}
                />
            );
        } else if (iconType === "integration") {
            return (
                <IconView
                    id={"img_" + i}
                    className={
                        classname !== undefined ? classname : "alertHeaderIcon"
                    }
                    dataType="rightIcon"
                    src="./images/Integrations.png"
                    onclick={() => this.titleMenuClick(this.currentAlertId)}
                />
            );
        }
    };
    generateListViewData = () => {
        // logic for render one row after click arrow
        // this.setState({ listStartPosition: this.state.listStartPosition + 1, listEndPosition: this.state.listEndPosition + 1 })
    };
    handleListBodyClick = (type, alert) => {
        if (type === "alert" && alert.clickAction === "ControlTower.Open") {
            this.jsFunCTView(alert.clickActionParameters);
        } else if (alert.clickAction === "Ripple.open") {
            this.jsFunRippleView(alert.clickActionParameters);
        }
    };
    jsFunCTView = (params) => {
        let ids = params.split("|");
        let obj = {
            genericTblId: ids[0],
            instanceId: ids[1],
        };
        this.props.setControlTowerData(obj);
        this.props.setActivePage("Control Tower");
    };
    jsFunRippleView = (params) => {
        let rippleRegistryId = parseInt(params);
        let url = `ripple/rippleRegistry/${rippleRegistryId}`;
        http.sendRestRequest(url, "GET").then((resp) => {
            this.props.setChipArray([]);
            let rippleData = resp;
            rippleData["rippleId"] = resp.rippleRegistryId;
            this.props.setActiveRipple(rippleData);
            this.props.setActivePage("Ripple");
        });
    };
    renderAddAlertContent = () => {
        return (
            // <div className="w-100" style={{ height: "95%", display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div
                className="doCenter"
                style={{
                    flexDirection: "column",
                }}
            >
                <span
                    style={{
                        fontSize: "28px",
                        padding: "8px",
                        textAlign: "center",
                    }}
                >
                    Add your first Notification
                </span>
                <span
                    className="doCenter"
                    style={{
                        textAlign: "center",
                    }}
                >
                    A notification or reminder that enables you to stay
                </span>
                <span
                    className="doCenter"
                    style={{
                        textAlign: "center",
                    }}
                >
                    up to date with all your activities within Unity Central
                </span>
                <Button
                    title=" Add Notification"
                    style={{
                        border: "none",
                        padding: "8px 20px",
                    }}
                    id="addAlertButton"
                    label={"Add Notification"}
                    onclick={(e) =>
                        this.isReadOnlyUser ? null : this.handleAddNewAlertBtn()
                    }
                    className={
                        this.isReadOnlyUser
                            ? "addAlertBtn readOnly"
                            : "addAlertBtn"
                    }
                ></Button>
            </div>
        );
    };
    computeListViewAlerts = () => {
        let alertList = [];
        let maxlimit = this.props.alertsCountInRow;
        this.undefinedSpcaeCounter = 0;
        let alertData = this.props.alertData;
        if (
            this.props.alertData !== undefined &&
            this.props.alertData !== null &&
            this.props.alertData.length > 0 &&
            this.props.activePage !== undefined
        ) {
            // let alertList = this.props.alertData.slice(this.state.listStartPosition, this.state.listEndPosition);
            for (let i = 0; i < this.props.alertData.length; i++) {
                alertList.push(
                    <div>
                        <img
                            className={
                                alertData[i].status !== "true"
                                    ? "notificationDotHide"
                                    : ""
                            }
                            src="./images/Ellipse 50.png"
                            alt=""
                        />
                        <div>
                            <ListAlertView
                                id={i}
                                index={i}
                                alertInstance={alertData[i]}
                                type={alertData[i].alertType}
                                isHaveAction={
                                    alertData[i].clickAction !== ""
                                        ? true
                                        : false
                                }
                                headerData={this.generateAlertHeader(
                                    "Test" + "_" + i
                                )}
                                onBodyClick={this.handleListBodyClick}
                                onMenuBtnClick={(e) => this.showPopover(e, i)}
                                headerLeftIcon={this.renderIcon(
                                    alertData[i].alertType,
                                    i,
                                    "listLeftIcon"
                                )}
                                headerRightIcon={this.renderIcon(
                                    "menu",
                                    i,
                                    "",
                                    alertData[i].rowId
                                )}
                                headerTitle={alertData[i].alertTitle}
                                bodyData={alertData[i].alertBody}
                                spaceId={i}
                            ></ListAlertView>
                        </div>
                    </div>
                );
            }
        } else {
            alertList.push(this.renderAddAlertContent());
        }

        let centerClass =
            this.props.alertData && this.props.alertData.length
                ? "w-100"
                : "doCenter w-100";
        return <div className={centerClass}>{alertList}</div>;
    };
    computeHomePageAlerts = () => {
        let AccordianList = [];
        this.undefinedSpcaeCounter = 0;
        let alertData = this.props.alertData;
        let totalAlertCount = this.props.alertsCountInRow;
        let extraCards = -1;
        let cardSizeInSlide = this.props.alertCountInSlide;
        let colSize = 12 / totalAlertCount;

        if (
            this.props.alertData !== undefined &&
            this.props.alertData !== null &&
            this.props.alertData.length > 0 &&
            this.props.activePage !== undefined
        ) {
            let alertList = this.props.alertData;
            let cardLenght = alertList.length % totalAlertCount;
            extraCards = totalAlertCount - cardLenght;
            extraCards = cardSizeInSlide - extraCards;
            extraCards = extraCards < 0 ? extraCards * -1 : extraCards;
            for (let i = 0; i < alertList.length; i++) {
                AccordianList.push(
                    <CardView
                        id={i}
                        index={i}
                        viewType="list"
                        cardClass={
                            colSize === 6
                                ? "workspaceAlertCard"
                                : "homeAlertCard"
                        }
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="alertCard-body"
                        headerData={this.generateAlertHeader("Test" + "_" + i)}
                        onMenuBtnClick={(e) => this.showPopover(e, i)}
                        headerLeftIcon={this.renderIcon(
                            alertData[i].alertType,
                            i
                        )}
                        headerRightIcon={this.renderIcon("menu", i)}
                        headerTitle={alertData[i].alertTitle}
                        bodyData={alertData[i].alertBody}
                        spaceId={i}
                    ></CardView>
                );
            }
            let index = 0;
            if (alertData.length === 1) {
                extraCards = cardSizeInSlide - alertData.length;
            }
            while (index < extraCards) {
                AccordianList.push(
                    <CardView
                        index={alertList.length + index}
                        activeIndex={this.state.selectedSpaceId}
                        cardClass={
                            colSize === 6
                                ? "workspaceAlertCard"
                                : "homeAlertCard"
                        }
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            let rowsForSlider = this.renderRowsForSlider(
                AccordianList,
                totalAlertCount
            );
            return (
                <SliderView
                    rawData={rowsForSlider}
                    cardCountPerSlide={this.props.rowsPerSlide}
                ></SliderView>
            );
        } else {
            extraCards = cardSizeInSlide;
            let index = 0;
            while (index < extraCards) {
                AccordianList.push(
                    <CardView
                        index={index}
                        activeIndex={-1}
                        cardClass={
                            colSize === 6
                                ? "workspaceAlertCard"
                                : "homeAlertCard"
                        }
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="alertCard-body"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            let rowsForSlider = this.renderRowsForSlider(
                AccordianList,
                totalAlertCount
            );
            return (
                <SliderView
                    rawData={rowsForSlider}
                    cardCountPerSlide={this.props.rowsPerSlide}
                ></SliderView>
            );
        }
    };
    handleViewFullscreen = () => {
        if (this.props.setViewFullScreen !== undefined) {
            this.props.setViewFullScreen();
        }
    };
    renderRowsForSlider = (cardList, displayCount) => {
        let rowItems = [];
        let temp = [];
        let cardLists = cardList;
        let colSize = 12 / displayCount;
        if (cardLists.length > 0) {
            for (let index = 0; index < cardLists.length; index++) {
                temp.push(
                    <Column className={"p-2 doCenter"} size={colSize}>
                        {cardLists[index]}
                    </Column>
                );
                if (temp.length === displayCount) {
                    rowItems.push(
                        <InlineRow classname={"m-0 w-100 pb-2 ht-50"}>
                            {temp}
                        </InlineRow>
                    );
                    temp = [];
                }
            }
            // this.setState({ homeAlerts: rowItems });
        }
        return rowItems;
    };
    generateAlertHeader = (i) => {
        let items = [];
        items.push(
            <IconView
                id={"img_" + i}
                dataType="rightIcon"
                src="./images/alertMenu.png"
                // onclick={this.titleMenuClick}
            />
        );
        return items;
    };
    renderClassname = () => {
        if (this.props.alertClassname !== undefined) {
            return this.props.alertClassname;
        } else {
            return "";
        }
    };
    renderFullScreenButton = () => {
        if (this.props.showFullScreenButton) {
            return (
                <div className="row w-100 doCenter alertsFullScreen">
                    <div className="col-6">
                        <input
                            id="fullscreenBtn"
                            type="button"
                            className="btn btn-light viewFSbtn newFontMontserrat"
                            value="View Fullscreen"
                            style={{ marginTop: "40px" }}
                            onClick={this.handleViewFullscreen}
                        ></input>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
    handleAddNewAlertBtn = () => {
        this.props.createNewAlert();
    };
    handleRefreshBtn = () => {
        if (this.props.onRefresh) this.props.onRefresh();
    };
    renderTitle = () => {
        // if (this.props.viewType === "list") {
        //     return (
        //         <div
        //             className="row w-100 m-0 mt-2 alertHead"
        //             // style={{ marginTop: "15px", marginBottom: "15px" }}
        //         >
        //             <span className="feedTitle">Notifications</span>

        //             <div
        //                 style={{
        //                     display: "flex",
        //                     justifyContent: "right",
        //                     alignItems: "center",
        //                     cursor: "pointer",
        //                 }}
        //             >
        //                 {/* <img
        //                     src="./images/AddWorkspace.svg"
        //                     style={{ cursor: "pointer" }}
        //                 ></img> */}
        //                 {/* <i
        //                     className="fa fa-plus mr-1"
        //                     style={{ cursor: "pointer" }}
        //                 ></i> */}
        //                 <span
        //                     title="Add Notification"
        //                     className={
        //                         this.isReadOnlyUser
        //                             ? "bw_plusButton readOnly"
        //                             : "bw_plusButton"
        //                     }
        //                     style={{ height: "24px" }}
        //                     onClick={
        //                         this.isReadOnlyUser
        //                             ? null
        //                             : this.handleAddNewAlertBtn
        //                     }
        //                 >
        //                     +
        //                 </span>
        //                 <span
        //                     id="addNewHomeAlert"
        //                     className={
        //                         this.isReadOnlyUser
        //                             ? "addLabel newFontMontserrat readOnly"
        //                             : "addLabel newFontMontserrat"
        //                     }
        //                     title="Add Notification"
        //                     style={{
        //                         whiteSpace: "nowrap",
        //                         overflow: "hidden",
        //                         textOverflow: "ellipsis",
        //                     }}
        //                     onClick={
        //                         this.isReadOnlyUser
        //                             ? null
        //                             : this.handleAddNewAlertBtn
        //                     }
        //                 >
        //                     Add Notification
        //                 </span>
        //             </div>
        //         </div>
        //     );
        // } else {
        return (
            <div
                // className="row w-100"
                id="alert-container"
                style={{
                    width: "100%",
                }}
            >
                {/* <div 
                    className="col-7 heading" 
                    

                    
                    > */}
                <span
                    // className="spacesTitle newFontMontserrat"
                    className="col-7 heading"
                    style={{
                        width: "61px",
                        height: "24px",
                        gap: "10px",
                    }}
                >
                    {/* Notifications (Notifications) */}
                    Alerts
                </span>
                {/* </div> */}
                {/* <div className="col-3 doLeft">
                        <img
                            src="./images/AddWorkspace.svg"
                            title="Add Notification"
                            style={{ cursor: "pointer" }}
                        ></img>
                        <span
                            id="addNewHomeAlert"
                            className={
                                this.isReadOnlyUser
                                    ? "addLabel newFontMontserrat readOnly"
                                    : "addLabel newFontMontserrat"
                            }
                            onClick={
                                this.isReadOnlyUser
                                    ? null
                                    : this.handleAddNewAlertBtn
                            }
                        >
                            Add Notification
                        </span>
                    </div> */}
            </div>
        );
        // }
    };
    renderDownArrowBtn = () => {
        if (this.props.viewType === "list") {
            return (
                <div
                    className="row m-0 doCenter mt-3 bootomArrow"
                    onClick={this.generateListViewData}
                >
                    <img src="./images/DownArrow.png"></img>
                </div>
            );
        } else return null;
    };
    renderContent = () => {
        return (
            // <div className={this.renderClassname()}>//className="alertSection"
            <div
                // className="invisibleScroller"

                style={{
                    width: "100%",
                    height: "95%",
                }}
                className={
                    this.props.viewType === "list"
                        ? "invisibleScroller"
                        : "alertSection"
                }
                // className={"alertViewContainer invisibleScroller pt-4"}
            >
                {this.props.viewType === "list"
                    ? this.computeListViewAlerts()
                    : this.computeHomePageAlerts()}
                {/* {this.renderDownArrowBtn()} */}
                {this.renderFullScreenButton()}
                {this.renderPopover()}
            </div>
        );
    };
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "38em",
                    height: "100%",
                }}
            >
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        setAlertData,
        setActiveRipple,
        setActivePage,
        setChipArray,
        setSelectedAlertIndex,
        setControlTowerData,
    };
};

const mapStateToProps = (state) => {
    return {
        activePage: state.activePage,
        selectedAlertIndex: state.selectedAlertIndex,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(AlertViewer);
