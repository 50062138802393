import { TextField, RaisedButton, Button } from "@material-ui/core";
import { ThirtyFpsSelectRounded } from "@mui/icons-material";
import React from "react";

class TextBox extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.id === undefined || this.props.id === null)
            this.id = this.props.label.replace(/\s+/, "_");
        else this.id = this.props.id;
        this.handleChange = this.handleChange.bind(this);
    }

    renderPlaceHolder = () => {
        if (this.props.placeholder !== false) {
            return this.props.label;
        } else {
            return "";
        }
    };
    handleChange(event) {
        this.props.onchange(
            event.currentTarget.value,
            event.currentTarget.id,
            event
        );
    }
    handleFocus = (e) => {
        if (this.props.onFocus !== undefined) this.props.onFocus(e);
    };
    handleFocusOut = (e) => {
        if (this.props.onfocusout !== undefined) this.props.onfocusout(e);
    };
    handleKeyDown = (e) => {
        if (e.currentTarget.value.length === 0 && e.keyCode === 32) {
            e.preventDefault();
        }
        if (this.props.handleRippleKeys !== undefined && e.keyCode === 188) {
            this.props.handleRippleKeys(e.currentTarget.value, e.keyCode);
            e.preventDefault();
        }
        if (
            this.props.handleSpecialKeys !== undefined &&
            (e.keyCode === 32 ||
                e.keyCode === 186 ||
                e.keyCode === 188 ||
                e.keyCode === 59 ||
                e.keyCode === 58 ||
                e.keyCode === 13 ||
                e.keyCode === 9)
            //key code space ,semicolon,colon
        ) {
            this.props.handleSpecialKeys(e.currentTarget.value, e.keyCode);
            e.preventDefault();
        }
        if (
            this.props.onkeydown !== undefined &&
            (e.keyCode === 13 || e.keyCode === 9)
        ) {
            this.props.onkeydown(e.currentTarget.value, e);
            e.preventDefault();
        }
    };
    onEnterKey = (e) => {
        if (this.props.onEnterKey !== undefined && e.keyCode === 13) {
            this.props.onEnterKey();
        }
    };
    renderInputClassname = () => {
        let className = "";
        if (this.props.className !== undefined)
            if (
                this.props.formControl !== undefined &&
                this.props.formControl === "Off"
            ) {
                className = this.props.className;
                return className;
            } else {
                return className + " " + this.props.className;
            }
        else return className;
    };
    render() {
        let className = null;
        this.props.doCenter !== undefined && this.props.doCenter === true
            ? (className = "doCenter")
            : (className = this.props.divClass);
        return (
            <div className={className}>
                <input
                    autocomplete="off"
                    type={this.props.type}
                    className={this.renderInputClassname()}
                    value={this.props.value}
                    name={this.id}
                    id={this.id}
                    maxLength={this.props.maxLength}
                    title={this.props.title}
                    aria-label={this.props.label}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.onEnterKey}
                    onChange={this.handleChange}
                    onKeyPress={this.props.onKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocusOut}
                    placeholder={this.renderPlaceHolder()}
                    disabled={this.props.disabled}
                />
                {this.props.showChild ? this.props.child : null}
            </div>
        );
    }
}

export default TextBox;
