import React from "react";
import Container from "@mui/material/Container";
import "./style.css";

const EmptyFolderPage = (props) => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    return (
        <React.Fragment>
            <div
                className="makeStyles-root-11 addSpace"
                style={{ marginTop: "170px" }}
            >
                <Container maxWidth="lg">
                    <div>
                        <img
                            alt="Folder"
                            className="newSpace"
                            src="./images/Vector (1).png"
                        ></img>
                    </div>
                    <div>
                        <h2
                            className="workText"
                            style={{ textAlign: "center" }}
                        >
                            Upload a new document or add a new folder
                        </h2>
                        <br />
                    </div>

                    <div className="btnSpace">
                        <div
                            className={
                                isReadOnlyUser ||
                                props.currentWorkspace.workspaceType ===
                                    "SYSTEM"
                                    ? "readOnly"
                                    : "cursorPointer"
                            }
                            title={"Add Folder"}
                            onClick={
                                isReadOnlyUser ||
                                props.currentWorkspace.workspaceType ===
                                    "SYSTEM"
                                    ? null
                                    : props.handleAddFolderClick
                            }
                            id={"btn_space_addfolder"}
                        >
                            <img
                                src="./images/Add Folder.svg"
                                alt="add folder"
                                title={"Add Folder"}
                                className={
                                    isReadOnlyUser ||
                                    props.currentWorkspace.workspaceType ===
                                        "SYSTEM"
                                        ? "bw_plusButton readOnly"
                                        : "bw_plusButton"
                                }
                            ></img>
                            <span
                                id="title"
                                title={"Add Folder"}
                                className={
                                    isReadOnlyUser ||
                                    props.currentWorkspace.workspaceType ===
                                        "SYSTEM"
                                        ? "addLabel newFontMontserrat readOnly"
                                        : "addLabel newFontMontserrat"
                                }
                            >
                                Add Folder
                            </span>
                        </div>
                        <div
                            className={
                                isReadOnlyUser ||
                                props.currentWorkspace.workspaceType ===
                                    "SYSTEM"
                                    ? "readOnly"
                                    : "cursorPointer"
                            }
                            style={{ marginLeft: "15px" }}
                            title={"Upload File"}
                            onClick={
                                isReadOnlyUser ||
                                props.currentWorkspace.workspaceType ===
                                    "SYSTEM"
                                    ? null
                                    : props.addNewFile
                            }
                            id={"btn_space_addfile"}
                        >
                            <img
                                src="./images/Upload File.svg"
                                alt="upload file"
                                title={"Upload File"}
                                className={
                                    isReadOnlyUser ||
                                    props.currentWorkspace.workspaceType ===
                                        "SYSTEM"
                                        ? "bw_plusButton readOnly"
                                        : "bw_plusButton"
                                }
                            ></img>
                            <span
                                id="title"
                                title={"Upload File"}
                                className={
                                    isReadOnlyUser ||
                                    props.currentWorkspace.workspaceType ===
                                        "SYSTEM"
                                        ? "addLabel newFontMontserrat readOnly"
                                        : "addLabel newFontMontserrat"
                                }
                            >
                                Upload File
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmptyFolderPage;
