export const getInstanceSteps = (response, genSteps) => {
    const object = {};
    let possibleSourceSteps = [];
    const mainBranchStep = response.filter(
        (step) => step.parentInstanceStepId === "0"
    );
    const returnedSteps = [...mainBranchStep];
    response.forEach((element, index) => {
        if (index) {
            const prevStepId =
                returnedSteps[returnedSteps.length - 1].instanceStepId;
            const nextStep = response.find(
                (step) =>
                    parseInt(step.parentInstanceStepId) === parseInt(prevStepId)
            );
            if (nextStep) returnedSteps.push(nextStep);
        }
    });
    const isMergeBlock = response.filter((step) => step.blockType === "SPLIT");
    if (!isMergeBlock.length) return returnedSteps;

    const mainBranch = mainBranchStep.length
        ? mainBranchStep[0].branchName
        : "";
    response.forEach((step, index) => {
        if (step.branchName)
            if (object[step.branchName])
                object[step.branchName] = [...object[step.branchName], step];
            else object[step.branchName] = [step];
    });
    const orderedSteps = [];
    let order = 0;
    const branchNames = Object.keys(object);
    for (const branchName in object) {
        if (
            Object.hasOwnProperty.call(object, branchName) &&
            branchName === mainBranch
        ) {
            const branchSteps = object[branchName];
            const firstStep = branchSteps.filter(
                (step) => step.parentInstanceStepId === "0"
            )[0];
            orderedSteps.push({ ...firstStep, instanceViewOrder: 0 });
            for (let index = 0; index < branchSteps.length; index++) {
                const stepId =
                    orderedSteps[orderedSteps.length - 1].instanceStepId;
                const nextStep = branchSteps.filter(
                    (step) =>
                        parseInt(step.parentInstanceStepId) === parseInt(stepId)
                )[0];
                orderedSteps.push({ ...nextStep, instanceViewOrder: ++order });
            }
        }
    }
    branchNames.forEach((element) => {
        if (element !== mainBranch) {
            const branchSteps = object[element];
            branchSteps.forEach((step) => {
                const oSteps = orderedSteps.map((ele) => ele.instanceStepName);
                const index = oSteps.lastIndexOf(step.instanceStepName);
                orderedSteps.splice(index + 1, 0, {
                    ...step,
                    instanceViewOrder: index + 2,
                });
            });
        }
    });
    const mergeBlocks = response.filter((step) => step.blockType === "MERGE");
    return [...orderedSteps, ...mergeBlocks];
};
