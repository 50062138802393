import React, { Component } from "react";

class GenericTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: "",
        };
        this.genericTblId = null;
        this.genericTblName = null;
        this.genericStepId = null;
        this.genericStepName = null;
    }
    setGenericFormData = (response) => {
        Object.keys(response).map((key) => {
            switch (key) {
                case "genericTblId":
                    {
                        this.genericTblId = response[key];
                    }
                    break;
                case "genericTblName":
                    {
                        this.genericTblName = response[key];
                    }
                    break;
                case "genericStepId":
                    {
                        this.genericStepId = response[key];
                    }
                    break;
                case "genericStepName":
                    {
                        this.genericStepName = response[key];
                    }
                    break;
                default:
                    break;
            }
        });
    };
    componentDidMount = () => {
        this.setGenericFormData(this.props.response);
        this.setState({ data: "updated" });
    };

    setFormText = (value, id, event) => {
        switch (id) {
            case "genericTblId":
                {
                    this.genericTblId = value;
                }
                break;
            case "genericTblName":
                {
                    this.genericTblName = value;
                }
                break;
            case "genericStepId":
                {
                    this.genericStepId = value;
                }
                break;
            case "genericStepName":
                {
                    this.genericStepName = value;
                }
                break;
            default:
                break;
        }
    };
    renderFormFields = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Table Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="genericTblId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.genericTblId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Table Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="genericTblName"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.genericTblName}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Step Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="genericStepId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.genericStepId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Step Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="genericStepName"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.genericStepName}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>

                {/* <div className="marginTopTen doCenter">
                    <Button id="submitButton" label={"SUBMIT"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"REFRESH"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"BACK"} onclick={this.props.onclick} className="ctButton"></Button>
                </div> */}
            </div>
        );
    };
    render() {
        return <>{this.renderFormFields()}</>;
    }
}

export default GenericTab;
