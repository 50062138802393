import React from "react";
import appInfo from "../../../../package.json";
import http from "../../../bae-client/HTTP";
import { Paper } from "@material-ui/core";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AppInfo() {
    const [appInfo, setAppInfo] = React.useState(null);
    React.useEffect(() => {
        http.sendRestRequest("version/unityCentral")
            .then((response) => {
                if (response.ucClient && response.ucServer) {
                    const date = response.ucClient.gitBuildTime.split(":")[0];
                    const clientTime = date.split("-").join("").split("T")[0];
                    const clientVersion =
                        response.ucClient.gitBuildVersion + "." + clientTime;
                    const serverDate =
                        response.ucServer.gitBuildTime.split(":")[0];
                    const serverTime = serverDate
                        .split("-")
                        .join("")
                        .split("T")[0];
                    const serverVersion =
                        response.ucServer.gitBuildVersion + "." + serverTime;
                    setAppInfo({
                        clientVersion: clientVersion,
                        client: response.ucClient,
                        serverVersion: serverVersion,
                        server: response.ucServer,
                        docReader: response.ucServerDocumentReader,
                        outlook: response.ucServerOutlookIntegration,
                        gmail: response.ucServerGmailIntegration,
                        sso: response.ucServerSSO,
                    });
                } else {
                    setAppInfo({
                        clientVersion: "no data available",
                        client: response.ucClient,
                        serverVersion: "no data available",
                        server: response.ucServer,
                        docReader: response.ucServerDocumentReader,
                        outlook: response.ucServerOutlookIntegration,
                        gmail: response.ucServerGmailIntegration,
                        sso: response.ucServerSSO,
                    });
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const renderAppInfoItems = () => {
        return (
            <>
                <Stack spacing={2} style={{ padding: "16px" }}>
                    <Typography>
                        Client Version: {appInfo.clientVersion}
                    </Typography>
                    <Typography>
                        Server Version: {appInfo.serverVersion}
                    </Typography>
                </Stack>
                <Divider></Divider>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Advanced</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} style={{ padding: "4px" }}>
                            <Typography>Outlook</Typography>
                            <Typography>GMAIL</Typography>
                            <Typography>SSO</Typography>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </>
        );
    };
    return (
        <div
            className="col-12 invisibleScroller"
            style={{
                overflowY: "auto",
                padding: "4px",
                height: "500px",
                width: "50%",
                margin: "auto",
            }}
        >
            <Paper elevation={3}>{appInfo && renderAppInfoItems()}</Paper>
        </div>
    );
}
