import React, { useState } from "react";
import Container from "@mui/material/Container";
import "./style.css";
import "./modal.css";
import { Button } from "react-bootstrap";
import ModalView from "../../../../stylized/ModalView";
import Stepper from "./Stepper2";
export default function WelcomeShow(props) {
    const [openStep, setOpenStep] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const onClose = () => setOpenStep(false);
    const handleClick = () => {
        setOpenStep(true);
        setShowModal(false);
    };
    const handleClose = () => {
        setShowModal(false);
        props.onHide();
    };
    return (
        <React.Fragment>
            <ModalView show={showModal} onHide={handleClose} footer={"false"}>
                <div
                    className="makeStyles-root-11"
                    style={{
                        paddingBottom: "32px",
                        backgroundColor: "#EDF8FF",
                    }}
                >
                    <Container maxWidth="lg">
                        <div>
                            <h1>Welcome to Unity Central! 👋</h1>
                        </div>
                        <div>
                            <h6>
                                Transform your information universe by managing,
                                searching, analyzing, and sharing your data in
                                one unified location, so you can make better
                                decisions, faster!
                            </h6>
                        </div>
                        <Button className="workbtn test" onClick={handleClick}>
                            Get Started!
                        </Button>
                    </Container>
                </div>
            </ModalView>
            <Stepper
                showModal={openStep}
                onClose={props.onHide}
                onHide={props.onHide}
                openCreateWorkspaceModal={props.openCreateWorkspaceModal}
                showView={props.showModal}
                closeStepper={setOpenStep}
            />
        </React.Fragment>
    );
}
