import { Avatar, Card, CardHeader } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReactDOM from "react-dom";
import CallIcon from "@mui/icons-material/Call";
import Portal from "../../100ms-portal";
import { callNotification } from "../../../../../../../actions/WebSocketActions";
import { selectRoomID } from "@100mslive/hms-video-store";

export default function ChatUser({
    selectedUser,
    newChatSend,
    newUserChat,
    isNewChat,
}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPortal, setOpenPortal] = React.useState(false);
    const [roomId, setRoomId] = React.useState("");
    const callNotificationData = useSelector((state) => {
        return state.webSocketNotification;
    });
    useEffect(() => {
        if (callNotificationData && callNotificationData.roomId) {
            dispatch(callNotification(null));
            setOpenPortal(true);
            setRoomId(callNotificationData.roomId);
        }
    }, [callNotificationData]);
    const open = Boolean(anchorEl);
    const chatCard = selectedUser[0];
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCallClick = () => {
        setOpenPortal(!openPortal);
    };
    const getAvatar = () => {
        let displayResult = "";
        for (let i = 0; i < selectedUser.length; i++) {
            if (selectedUser.length > 2) {
                if (i == 2) {
                    displayResult = displayResult;
                    // + "+" + parseInt(selectedUser.length - 2)
                    break;
                } else {
                    displayResult =
                        displayResult +
                        selectedUser[i].address.charAt(0).toLocaleUpperCase() +
                        " ";
                }
            } else if (selectedUser.length == 2) {
                if (i === selectedUser.length - 1) {
                    displayResult =
                        displayResult +
                        selectedUser[i].address.charAt(0).toLocaleUpperCase();
                } else {
                    displayResult =
                        displayResult +
                        selectedUser[i].address.charAt(0).toLocaleUpperCase() +
                        " ";
                }
            } else {
                displayResult =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName.charAt(0).toLocaleUpperCase() +
                          chatCard.lastName.charAt(0).toLocaleUpperCase()
                        : chatCard.address.charAt(0).toLocaleUpperCase();
            }
        }

        if (displayResult) return displayResult;
    };

    const getTitle = () => {
        let result = [];
        for (let i = 0; i < selectedUser.length; i++) {
            if (selectedUser.length > 2) {
                if (i == 6) {
                    result.push(
                        <div
                            className="textEllipsis"
                            style={{ maxWidth: "100px" }}
                        >
                            <span>
                                {", + " + parseInt(selectedUser.length - 6)}
                            </span>
                        </div>
                    );
                    break;
                } else if (i === 0) {
                    result.push(
                        <div
                            className="textEllipsis "
                            style={{ maxWidth: "100px" }}
                        >
                            <span>{selectedUser[i].firstName}</span>
                        </div>
                    );
                } else {
                    result.push(
                        <div
                            className="textEllipsis"
                            style={{ maxWidth: "100px" }}
                        >
                            <span>{", " + selectedUser[i].firstName}</span>
                        </div>
                    );
                }
            } else if (selectedUser.length == 2) {
                if (i === selectedUser.length - 1) {
                    result.push(
                        <div
                            className="textEllipsis"
                            style={{ maxWidth: "100px" }}
                        >
                            <span>{selectedUser[i].firstName}</span>
                        </div>
                    );
                } else {
                    result.push(
                        <div
                            className="textEllipsis"
                            style={{ maxWidth: "100px" }}
                        >
                            <span>{selectedUser[i].firstName + " and "}</span>
                        </div>
                    );
                }
            } else {
                result =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName + " " + chatCard.lastName
                        : chatCard.address;
            }
        }

        return result;
    };
    const getValue = () => {
        let result = "";
        for (let i = 0; i < selectedUser.length; i++) {
            if (selectedUser.length > 1) {
                if (i === selectedUser.length - 1) {
                    result = result + selectedUser[i].firstName;
                } else {
                    result = result + selectedUser[i].firstName + ", ";
                }
            } else {
                result =
                    chatCard.firstName && chatCard.lastName
                        ? chatCard.firstName + " " + chatCard.lastName
                        : chatCard.address;
            }
        }

        return result;
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <div
            id={`communicatorSelectedChatInfo`}
            className="borderBottom"
            style={{
                padding: selectedUser ? "0" : "16px",
                height: "3rem",
            }}
        >
            {selectedUser.length > 0 ? (
                <>
                    <div title={getValue()}>
                        <Card
                            style={{
                                padding: "0 8px",
                                height: "100%",
                                boxShadow: "none",
                            }}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar
                                        aria-label="user"
                                        style={{
                                            background: "#1B75BC",
                                            height: "35px",
                                            width: "35px",
                                            fontSize: "1rem",
                                            cursor: "pointer",
                                            textAlign: "center",
                                        }}
                                        onClick={handleClick}
                                    >
                                        {getAvatar()}
                                    </Avatar>
                                }
                                title={
                                    <span className="demo-2">{getTitle()}</span>
                                }
                                action={
                                    <>
                                        {/* <div
                                            id={"newChatButton"}
                                            style={{
                                                float: "left",
                                                cursor: "pointer",
                                                padding: "4px",
                                            }}
                                            onClick={handleCallClick}
                                        >
                                            <CallIcon
                                                style={{
                                                    width: "17px",
                                                    paddingTop: "6px",
                                                }}
                                            ></CallIcon>
                                        </div> */}
                                        <div
                                            id={"div_newChat"}
                                            style={{
                                                float: "right",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                newUserChat();
                                            }}
                                        >
                                            <img
                                                src="./images/AddWorkspace.svg"
                                                alt="Invite User"
                                                title="Add New User"
                                            />
                                        </div>
                                    </>
                                }
                            ></CardHeader>
                        </Card>
                    </div>
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {selectedUser.map((user, index) => {
                            return (
                                <MenuItem
                                    key={user.id}
                                    onClick={handleClose}
                                    disableRipple
                                    id={user.id}
                                >
                                    {user.firstName}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </>
            ) : (
                <div
                    style={{
                        float: "left",
                        height: "inherit",
                        padding: "10px",
                    }}
                >
                    New Message
                </div>
            )}
            {openPortal
                ? ReactDOM.createPortal(
                      <Portal
                          closePortal={handleCallClick}
                          roomId={roomId}
                          setRoomId={setRoomId}
                          selectedUsers={selectedUser}
                      ></Portal>,
                      document.getElementById("portal")
                  )
                : null}
        </div>
    );
}
