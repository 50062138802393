import React from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";
import Button from "@material-ui/core/Button";

export default function AddFolderModal({
    showFolderModal,
    closeModal,
    setText,
    newFolderName,
    addNewFolder,
    error,
}) {
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showFolderModal}
            size="md"
            onHide={closeModal}
            title="Add Folder"
            id="btn_folder_submit"
            onSubmit={addNewFolder}
            value="Add Folder"
            onclick={closeModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div className="updateSpaceNameTitle addFolder">Add Folder</div>
                <TextBox
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    style={{ marginTop: "-20px" }}
                    onchange={setText}
                    label="Name new folder"
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {error && (
                <>
                    <small
                        id="small_addFolder_errorMessage"
                        style={{ color: "red", paddingLeft: "18px" }}
                    >
                        {error}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
