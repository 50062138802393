import React, { useState, useEffect } from "react";
import "./Workspaces.css";
import SearchField from "../../../../stylized/search-field";
import WorkspaceModalInfo from "../../../../stylized/workspaceModalInfo";
import Members from "../WorkspaceContainer/members-modal/Members";
import { ButtonComponent } from "../../../../button/button";
import { useDispatch, useSelector } from "react-redux";
import NestedModal from "../../../../stylized/nested-modal/NestedModal";
import Loader from "../../../../stylized/Loader";
import {
    fetchDirectoriesAction,
    fetchDirectoryUsersAction,
} from "../../../../../services/services/app";
const RightActions = ({
    handleRedirection,
    breadcrumb,
    searchText,
    onSearchHandler,
    emails,
    breadcrumbData,
    rowsSelected,
    currentRows,
    activeSpace,
}) => {
    const dispatch = useDispatch();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [membersList, setMembersList] = useState([]);
    const [showMembersModal, setShowMembersModal] = useState(false);
    const [isEmailWS, setIsEmailWS] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const directories = useSelector((state) => state.directories);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const [currentMembers, setCurrentMembers] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);
    useEffect(() => {
        if (
            activeWorkspace?.workspaceName === "Emails" &&
            activeWorkspace?.workspaceType === "SYSTEM"
        ) {
            setIsEmailWS(true);
        } else setIsEmailWS(false);
    }, [activeWorkspace]);

    useEffect(() => {
        if (showInfoModal) {
            dispatch(fetchDirectoriesAction());
        }
    }, [showInfoModal]);

    useEffect(() => {
        if (directories && directories.length)
            dispatch(fetchDirectoryUsersAction(directories[0].directoryId));
    }, [directories]);

    const btnStyle = {
        display: "flex",
        width: "135px",
        justifyContent: "center",
    };
    const infoHandler = () => {
        setShowInfoModal(true);
    };
    return (
        <>
            <div
                className="right-actions"
                // style={{ paddingLeft: isModalOpen ? "970px" : "0px" }}
                style={{
                    marginLeft:
                        (activeWorkspace?.workspaceType !== "SYSTEM" ||
                            activeWorkspace?.workspaceName === "Integration") &&
                        showInfoModal
                            ? "27rem"
                            : "",
                }}
            >
                {isEmailWS && rowsSelected.length === 0 ? (
                    <ButtonComponent
                        type={"outlined"}
                        text={"Move Rules"}
                        BtnHandler={() => setIsModalOpen(true)}
                    />
                ) : null}
                {isEmailWS && rowsSelected.length === 0 && (
                    <ButtonComponent
                        btnStyle={btnStyle}
                        type={"outlined"}
                        icon={"./images/info-circle.svg"}
                        text={"Info"}
                        BtnHandler={infoHandler}
                    />
                )}
                {!isEmailWS && (
                    <ButtonComponent
                        btnStyle={btnStyle}
                        type={"outlined"}
                        icon={"./images/info-circle.svg"}
                        text={"Info"}
                        BtnHandler={infoHandler}
                    />
                )}
                {isEmailWS || breadcrumb === true ? null : (
                    <ButtonComponent
                        btnStyle={btnStyle}
                        type={"primary"}
                        text={"Invite"}
                        // disable={
                        //     breadcrumbData[breadcrumbData.length - 1]?.type ===
                        //         "space" ||
                        //     breadcrumbData[breadcrumbData.length - 1]?.type ===
                        //         "folder"
                        //         ? true
                        //         : false
                        // }
                        BtnHandler={() => setShowMembersModal(true)}
                    />
                )}
            </div>

            {currentRows.length > 0 &&
            !currentRows.some(
                (row) => row.name === "Inbox" || row.name === "Outbox"
            ) ? (
                <div
                    style={{
                        display:
                            breadcrumb === true || isEmailWS ? null : "none",
                        marginLeft:
                            isModalOpen || showInfoModal ? "35rem" : "0px",
                    }}
                >
                    <SearchField
                        text={searchText}
                        id={false}
                        value={"Search..."}
                        onChangeHandler={onSearchHandler}
                    ></SearchField>
                </div>
            ) : null}

            <div style={{ display: "none" }}>
                {showMembersModal ? (
                    <Members
                        setMembersList={setMembersList}
                        showMembersModal={showMembersModal}
                        onHide={setShowMembersModal}
                        membersList={membersList}
                        activeSpace={activeSpace}
                        activeFolder={
                            breadcrumbData?.length > 2
                                ? breadcrumbData[breadcrumbData.length - 1]
                                : null
                        }
                        currentMembers={currentMembers}
                        setCurrentMembers={setCurrentMembers}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                    ></Members>
                ) : null}
            </div>
            {showInfoModal ? (
                <WorkspaceModalInfo
                    open={showInfoModal}
                    onHide={setShowInfoModal}
                    handleClose={setShowInfoModal}
                    handleRedirection={handleRedirection}
                    membersList={membersList}
                    setShowMembersModal={setShowMembersModal}
                    breadcrumbData={breadcrumbData}
                    rowsSelected={rowsSelected}
                    // disableManageMembersBtn={
                    //     breadcrumbData[breadcrumbData.length - 1]?.type ===
                    //         "space" ||
                    //     breadcrumbData[breadcrumbData.length - 1]?.type ===
                    //         "folder"
                    //         ? true
                    //         : false
                    // }
                    currentRows={currentRows}
                    activeSpace={activeSpace}
                    activeFolder={
                        breadcrumbData?.length > 2
                            ? breadcrumbData[breadcrumbData.length - 1]
                            : null
                    }
                    setMembersList={setMembersList}
                    setCurrentMembers={setCurrentMembers}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                ></WorkspaceModalInfo>
            ) : null}
            {isModalOpen && (
                <NestedModal
                    open={isModalOpen}
                    onClose={setIsModalOpen}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}
                />
            )}
            <Loader show={showLoader} />
        </>
    );
};

export default RightActions;
