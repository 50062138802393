import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
export default memo(({ data, isConnectable }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(data.isCollapsed);
    if (data && data.hide) return null;
    else
        return (
            <>
                <Handle
                    type="target"
                    position="left"
                    style={{ color: "#1B75BC", stroke: "#1B75BC" }}
                    onConnect={(params) =>
                        console.log("handle onConnect", params)
                    }
                    isConnectable={isConnectable}
                />
                <div
                    style={{
                        padding: "12px",
                        minWidth: "130px",
                        height: "45px",
                        maxWidth: "200px",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    {data.img === "./images/fileTypeIcon/xml-file.svg" ||
                    data.img === "./images/fileTypeIcon/htmlIcon.svg" ? (
                        <img
                            src={data.img}
                            alt="test"
                            style={{ height: "28px" }}
                        />
                    ) : (
                        <img
                            src={data.img}
                            alt="test"
                            style={{ width: "28px" }}
                        />
                    )}
                    <strong>{data.color}</strong>
                    <p
                        title={data.label}
                        className="textEllipsis"
                        style={{
                            width: "100px",
                            paddingLeft: "10px",
                            fontWeight: "500",
                            fontSize: "20px",
                            height: "20px",
                            marginBottom: "11px",
                            marginRight: "30px",
                            lineHeight: "15px",
                            color: "#000",
                        }}
                    >
                        {data.label}
                    </p>
                    {/* {data.nodeType === "Folder" ? (
                        <img
                            src={
                                isCollapsed
                                    ? "./images/plus-circle.svg"
                                    : "./images/collapse.svg"
                            }
                            alt="test"
                            style={{ height: "18px", cursor: "pointer" }}
                            onClick={() => {
                                data.expandCollapse(isCollapsed);
                                setIsCollapsed(!isCollapsed);
                            }}
                        />
                    ) : null} */}
                </div>

                <Handle
                    type="source"
                    position="right"
                    id="b"
                    style={{ color: "#1B75BC", stroke: "#1B75BC" }}
                    isConnectable={isConnectable}
                />
            </>
        );
});
