export const getUser = () => {
    const userStr = sessionStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    else return null;
};

export const getToken = () => {
    return sessionStorage.getItem("token") || null;
};

export const removeUserSession = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("nhId");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("user");
    localStorage.removeItem("token");
};

export const setUserSession = (token, user, userId, nhId, memberId, isReadOnlyUser) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("nhId", nhId);
    sessionStorage.setItem("memberId", memberId);
    sessionStorage.setItem("user", user);
    sessionStorage.setItem("isReadOnlyUser", isReadOnlyUser);
};
