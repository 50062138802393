export const cuboidInfoByName = (cuboidData = null, action) => {
    if (action.type === "CUBOID_iNFO_DATA_BY_NAME") {
        return action.payload;
    }
    return cuboidData;
};
export const cuboidInfoById = (cuboidData = null, action) => {
    if (action.type === "CUBOID_iNFO_DATA_BY_ID") {
        return action.payload;
    }
    return cuboidData;
};
