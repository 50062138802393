import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            // <div className='row loginPageFooter'>
            //     <img src="./images/boardwalk-logo 1.png" ></img>
            // </div>
            // https://www.boardwalktech.com/
            <footer className="loginPageFooter doCenter">
                <div className="row w-100 m-0 doCenter">
                    <a href="https://www.boardwalktech.com/">
                        Copyright 2006-2024 Boardwalktech, Inc
                    </a>
                </div>
                {/* <img src="./images/boardwalk-logo 1.png" ></img> */}
            </footer>
        );
    }
}

export default Footer
