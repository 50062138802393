import React, { useEffect, useState } from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";

export default function RenameFolderModal({
    modalShow,
    closeModal,
    folderName,
    handleUpdatedFolderNameSubmit,
    error,
    selectedObjectData,
    activeFolderItems,
}) {
    const updatedFolderName = folderName;
    const [updatedName, setupdatedName] = useState("");
    const [errorMessage, setErrorMessage] = useState(error);

    useEffect(() => {
        if (selectedObjectData.length === 1) {
            const selectedFolder = activeFolderItems.filter(
                (item) =>
                    parseInt(item.id) === selectedObjectData[0] &&
                    item.type === "folder"
            );
            if (selectedFolder.length) {
                setupdatedName(selectedFolder[0].name);
            }
        }
    }, [selectedObjectData, activeFolderItems]);

    // useEffect(() => {
    //     setupdatedName(folderName);
    // }, [folderName]);

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    const handleCloseModal = () => {
        setErrorMessage("");
        closeModal();
        setupdatedName("");
    };
    const handleSubmit = () => {
        if (errorMessage) return;
        handleUpdatedFolderNameSubmit(updatedName);
    };
    const handleFocus = (event) => event.target.select();
    const setFromText = (value, id, event) => {
        switch (id) {
            case "updateFolderName":
                {
                    setupdatedName(value);
                    if (value && value.length > 100)
                        setErrorMessage(
                            "Name should not exceed 100 char length"
                        );
                    else setErrorMessage("");
                }
                break;
            default:
                break;
        }
    };
    if (modalShow) {
        return (
            <ModalView
                // disabled={label.trim() === spaceName.trim() ? true : false}
                onSubmit={handleSubmit}
                show={modalShow}
                onHide={handleCloseModal}
                value="change"
                title="Change"
                type="submit-cancel"
                footer={"false"}
                size="md"
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <span
                        className="addWorkspaceModal addwork"
                        style={{
                            marginLeft: "-255px",
                            marginBottom: " 34px",
                            width: "auto",
                        }}
                    >
                        Rename Folder
                    </span>
                    <TextBox
                        id="updateFolderName"
                        className="updateSpaceName loginPageBorder paddingLeft "
                        type="text"
                        onchange={setFromText}
                        value={updatedName}
                        onFocus={handleFocus}
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                </div>
                {errorMessage && (
                    <>
                        <small
                            id="small_renameFolder_errorMessage"
                            style={{ color: "red", paddingLeft: "18px" }}
                        >
                            {errorMessage}
                        </small>
                        <br />
                    </>
                )}
            </ModalView>
        );
    } else {
        return null;
    }
}
