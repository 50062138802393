import SpaceManager from "../bae-client/SpaceManager";

export const fetchFolderListData = (spaceId) => {
    return async (dispatch) => {
        const _sm = new SpaceManager();
        const response = await _sm.getFolderStructureByAPI(spaceId);
        dispatch({
            type: "FETCH_FOLDER_DATA",
            payload: response,
        });
    };
};
export const resetFolderListData = () => {
    return {
        type: "RESET_FOLDER_LIST_DATA",
    };
};
export const setFolderIdToNameMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "FOLDER_ID_TO_NAME_MAP",
            payload: response,
        });
    };
};
