import React from "react";
import ModalView from "../../../../stylized/ModalView";

export default function RippleLogLinkToSpaceModal({
    open,
    closeModal,
    handleLinkBtnClickInModal,
}) {
    const handleSubmit = () => {
        handleLinkBtnClickInModal();
    };
    const renderLinkDocumentModal = () => {
        return (
            <div className="row doCenter" style={{ minHeight: "150px" }}>
                {/* <div
                    className="col-6"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                > */}
                <button
                    class="btn btnLabel doCenter newFontMontserrat"
                    onClick={handleSubmit}
                >
                    LINK TO Space
                </button>
            </div>
            // </div>
        );
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={open}
            size="md"
            title=""
            onHide={closeModal}
            onclick={closeModal}
            submitButtonText="Close"
        >
            {renderLinkDocumentModal()}
        </ModalView>
    );
}
