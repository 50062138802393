import React from "react";
import "../../stylized/Archive.css";

const EmptyTrash = (props) => {
    return (
        <React.Fragment>
            <div className="emptyTrashContainer">
                <div style={{ margin: "auto" }}>
                    <div>
                        <img
                            alt="Trash_Bin"
                            className="newSpace"
                            src="./images/Vector1.png"
                        ></img>
                    </div>
                    <div>
                        <span className="workText Archieve">
                            Your Trash Bin is empty
                        </span>
                        <br />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmptyTrash;
