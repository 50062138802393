import React from "react";

const OutlineTextBoxView = ({
    id,
    value,
    placeholder,
    label,
    title,
    disabled,
    type,
    tabIndex,
    onKeyDown,
    errorMsg,
    onChangeInput,
    onBlurInput,
    onFocusInput,
    parentDivClass,
    inputClass,
    pwdStrength,
}) => {
    const [typePassword, setTypePassword] = React.useState("password");
    const handleChangeInput = (event) => {
        if (onChangeInput !== undefined) {
            onChangeInput(
                event.currentTarget.value,
                event.currentTarget.id,
                event
            );
        }
    };
    const handleOnBlurInput = (event) => {
        if (onBlurInput !== undefined) {
            onBlurInput(
                event.currentTarget.value,
                event.currentTarget.id,
                event
            );
        }
    };
    const handleOnFocusInput = (event) => {
        if (onFocusInput !== undefined) {
            onFocusInput(
                event.currentTarget.value,
                event.currentTarget.id,
                event
            );
        }
    };
    const showPassword = () => {
        typePassword === "password"
            ? setTypePassword("text")
            : setTypePassword("password");
    };
    const renderLabel = () => {
        if (label !== undefined && label !== "") {
            return (
                <div className="row w-100 ">
                    <label className="loginPageTextBox_label">{label}</label>
                </div>
            );
        } else {
            return null;
        }
    };
    const inputRowClass =
        inputClass !== undefined
            ? "row m-0 inp_TextBoxRow " + inputClass
            : "row m-0 inp_TextBoxRow ";
    return (
        <div
            className={
                parentDivClass !== undefined
                    ? "form-group w-100 center " + parentDivClass
                    : "form-group w-100 center"
            }
        >
            {renderLabel()}
            <div
                className={
                    errorMsg === ""
                        ? inputRowClass
                        : inputRowClass + " err_border"
                }
            >
                <div className="col p-0">
                    <input
                        style={{ cursor: disabled ? "no-drop" : "default" }}
                        autocomplete="off"
                        title={title}
                        type={type !== "password" ? type : typePassword}
                        className={"inp_TextBox"}
                        id={id}
                        onKeyDown={onKeyDown}
                        autofocus
                        placeholder={placeholder}
                        disabled={disabled}
                        name="password"
                        value={value}
                        onKeyUp={handleOnBlurInput}
                        onChange={handleChangeInput}
                        onFocus={handleOnFocusInput}
                        onClick={(e) =>
                            e.currentTarget.removeAttribute("readonly")
                        }
                    />
                </div>
                {type == "password" && (
                    <div
                        onClick={showPassword}
                        role="button"
                        tabindex="0"
                        className="col-2 p-0 doCenter"
                    >
                        <img
                            src={
                                typePassword === "password"
                                    ? "images/eye-icon.svg"
                                    : "images/eye-slash-icon.svg"
                            }
                            className="err_icon"
                        />
                    </div>
                )}
                {type !== "password" && errorMsg !== "" ? (
                    <div className="col-2 p-0 doCenter">
                        <img
                            src="images/exclamation-circle 1.png"
                            className="err_icon"
                        />
                    </div>
                ) : null}
            </div>
            {errorMsg !== "" ? (
                <div
                    className="rows m-0 w-100"
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                    }}
                >
                    {pwdStrength !== "" ? (
                        <div
                            className="w-100"
                            style={{
                                height: "3px",
                                backgroundColor: pwdStrength,
                                marginTop: "5px",
                                borderRadius: "10px",
                            }}
                        ></div>
                    ) : null}
                    <span
                        className="err_msg"
                        id={"err_" + id}
                        style={{ marginLeft: "0px" }}
                    >
                        {errorMsg}
                    </span>
                </div>
            ) : (
                <div
                    className="row m-0 w-100"
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                    }}
                >
                    <div
                        className="w-100"
                        style={{
                            height: "3px",
                            backgroundColor: pwdStrength,
                            marginTop: "5px",
                            borderRadius: "10px",
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default OutlineTextBoxView;
