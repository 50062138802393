import Document from "./Document";
import http from "./HTTP";
import RippleManager from "./RippleManager";

class Ripple {
    constructor() {
        this.rippleData = {
            rippleId: "",
            rippleName: "",
            rippleKeywords: "",
            rippleDate: "",
            documentIds: "",
            recureTime: "",
        };
        this.rippleManager = null;
        this.fetcher = null;
    }
    setCollabFetcher = (fetcherData) => {
        this.fetcher = fetcherData;
    };
    getRippleData = () => {
        return this.rippleData;
    };
    getRippleDocumentsIdList = () => {
        if (this.rippleData.documentIds !== "") {
            return this.rippleData.documentIds.split(",").map((i) => Number(i));
        } else {
            return null;
        }
    };
    setRippleData = (data) => {
        this.rippleData.rippleId = data.rippleRegistryId;
        this.rippleData.rippleName = data.rippleName;
        this.rippleData.rippleKeywords = data.searchCriteria;
        this.rippleData.documentIds = data.searchResults;
        this.rippleData.recureTime = data.rippleRecur;
    };
    generateDocumentObj = (docId) => {
        let option = {};
        let collabId = -1;
        let wbId = -1;
        let documentId = -1;
        let documentHeaderId = -1;
        let collabName = "";
        let wbname = "";
        let headerName = "";
        let fileName = "";
        let files = {};
        let cuboidInfo =
            this.fetcher.getCollabAndWhiteboardAndCuboidNamesBYCuboidID(
                parseInt(docId)
            );
        collabId = this.fetcher.getCollabIdByName(cuboidInfo[0]);
        wbId = this.fetcher.getWBIdByName(collabId, cuboidInfo[1]);
        documentId = this.fetcher.getCuboidIdByName(
            collabId,
            wbId,
            cuboidInfo[2]
        );
        documentHeaderId = this.fetcher.getCuboidIdByName(
            collabId,
            wbId,
            cuboidInfo[2] + ".h"
        );
        collabName = cuboidInfo[0];
        wbname = cuboidInfo[1];
        headerName = cuboidInfo[2] + ".h";
        fileName = cuboidInfo[2];
        files = { name: fileName };
        option = {
            collabId: collabId,
            wbId: wbId,
            documentId: documentId,
            documentHeaderId: documentHeaderId,
            collabName: collabName,
            wbname: wbname,
            headerName: headerName,
            fileName: fileName,
            files: files,
            isChoosen: false,
            isLinked: true,
            isPersisted: false,
        };
        let document = new Document();
        document.setData(option);
        return document;
    };
    getDocuments = (documentIdArray) => {
        return new Promise((resolve, reject) => {
            let url = "document/documentInfo";
            http.sendRestRequest(url, "POST", documentIdArray)
                .then((documentObjects) => {
                    resolve(documentObjects);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };
    loadRippleCuboid = () => {
        this.rippleManager = new RippleManager(this.options);
        this.rippleManager
            .loadRippleCuboid()
            .then((response) => {
                return response;
            })
            .catch((errResponse) => {
                return errResponse;
            });
    };
}
export default Ripple;
