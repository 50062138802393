export const chatList = (chatListData = [], action) => {
    if (action.type === "CHAT_LIST") {
        return action.payload;
    }
    return chatListData;
};
export const activeChatUser = (chatListData = null, action) => {
    if (action.type === "ACTIVE_CHAT_LIST") {
        return action.payload;
    }
    return chatListData;
};
const initialState = 0;

export const unreadChatCount = (state = initialState, action) => {
    switch (action.type) {
        case "SET_UNREAD_CHAT_COUNT":
            return action.payload;
        default:
            return state;
    }
};

export const sharedData = (data = null, action) => {
    if (action.type === "SHARE_DATA") {
        return action.payload;
    }
    return data;
};
