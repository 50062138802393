import React, { useState } from "react";
import http from "../../../bae-client/HTTP";
import AlertDialog from "../../stylized/AlertDialog";
import Button from "../../stylized/Button";
import Loader from "../../stylized/Loader";

function ResetOptions() {
    const [showAlertModal, setshowAlertModal] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    let errMsgTitle = "";
    let errMsgBody = "";
    const resetInstanceInformation = () => {
        return new Promise((resolve, reject) => {
            //instanceBlockchain/instanceInformation?instanceInformationTblId=2000741
            let url =
                "instanceBlockchain/instanceInformation?instanceInformationTblId=2000741";
            let method = "PUT";
            let data = {
                instanceBlockchainId: 19471,
                key: "Promised Date",
                value: "44359",
            };
            http.sendRestRequest(url, method, data)
                .then((response) => {
                    resetExceptionRegistry()
                        .then((response1) => {
                            resolve(response1);
                        })
                        .catch((err) => {
                            console.log(err);
                            setShowAlertDialog(true);
                            setAlertDialogMessage(err.error);
                            reject(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    reject(err);
                });
        });
    };
    const resetExceptionRegistry = () => {
        return new Promise((resolve, reject) => {
            //instanceBlockchain/exceptionRegistry?exceptionRegistryTblId=2000772
            let url =
                "instanceBlockchain/exceptionRegistry?exceptionRegistryTblId=2000772";
            let method = "PUT";
            let data = {
                exceptionLogObjectId: 19553,
                status: "Waiting for update",
            };
            http.sendRestRequest(url, method, data)
                .then((response) => {
                    //console.log(response);
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    reject(err);
                });
        });
    };

    const handleClick = () => {
        setShowLoader(true);
        resetInstanceInformation()
            .then((response) => {
                showAlertView("", "Reset Done.");
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setShowAlertDialog(true);
                setShowLoader(false);
                setAlertDialogMessage(err.error);
            });
    };
    const hideAlertView = () => {
        setshowAlertModal(false);
    };
    const showAlertView = (title, body) => {
        errMsgTitle = title;
        errMsgBody = body;
        // setState({ showAlertModal: true });
        setshowAlertModal(true);
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={showAlertModal}
                setOpen={hideAlertView}
                title={errMsgTitle}
                message={errMsgBody}
            ></AlertDialog>
        );
    };
    return (
        <div className="doCenter" style={{ height: "80vh" }}>
            <Button
                id="resetOptions"
                label={"Reset DLA Demo"}
                className="dlaResetButton"
                onclick={handleClick}
            ></Button>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            {renderAlertModal()}
            <Loader show={showLoader} />
        </div>
    );
}

export default ResetOptions;
