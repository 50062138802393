import { default as React, useState } from "react";
import CardView from "../../../../../stylized/CardView";
import Column from "../../../../../stylized/Column";
import InlineRow from "../../../../../stylized/InlineRow";
import SliderView from "../../../../../stylized/SliderView";
export default function GridList({
    selectedCard,
    changeActiveCard,
    changeSelectedCard,
    gridListData,
    isSpaceSelected,
    isFileOrFolderSelected,
    // onItemSelect,
    imageSource,
}) {
    // const cardlist = [];
    const [selectedSpaceId, setSelectedSpaceId] = useState(selectedCard);
    const [selected, setSelected] = React.useState([]);
    const [selectedRowData, setSelectedRowData] = React.useState([]);
    let selectedRowIndex = null;
    const displayCount = 6;
    const handleCheckboxClick = (event, name, id, index, rowdata) => {
        const selectedIndex = selected.indexOf(id);
        const selectedRowDataIndex = selectedRowData.indexOf(rowdata);
        selectedRowIndex = selectedIndex;
        let newSelected = [];
        let newSelectedRowData = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (selectedRowDataIndex === -1) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData,
                rowdata
            );
        } else if (selectedRowDataIndex === 0) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(1)
            );
        } else if (selectedRowDataIndex === selectedRowData.length - 1) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(0, -1)
            );
        } else if (selectedRowDataIndex > 0) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(0, selectedRowDataIndex),
                selectedRowData.slice(selectedRowDataIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedRowData(newSelectedRowData);
        newSelected = newSelected.map((i) => Number(i));
        changeActiveCard(event, index, id, rowdata, newSelected);
    };
    const computeSpaceCards = () => {
        // this.setState({ showLoader: true });
        let AccordianList = [];
        // this.undefinedSpcaeCounter = 0;
        let totalAlertCount = 12;
        if (
            gridListData !== undefined &&
            gridListData !== null &&
            gridListData.length > 0
        ) {
            let spaceList = gridListData;
            let cardLenght = spaceList.length % totalAlertCount;
            let extraCards = totalAlertCount - cardLenght;
            // for (let i = 0; i < spaceList.length; i++) {
            //     AccordianList.push(
            //         <CardView
            //             id={spaceName + "|" + i}
            //             index={i}
            //             activeIndex={selectedSpaceId}
            //             onbodyclick={this.changeActiveSpace}
            //             onMenuBtnClick={(e) =>
            //                 this.showPopover(
            //                     e,
            //                     i,
            //                     spaceInstance,
            //                     spaceName + "|" + i
            //                 )
            //             }
            //             spaceInstance={spaceInstance}
            //             cardClass="spaceCard"
            //             cardHeaderClass="spaceCard-header"
            //             cardBodyClass="spaceCard-body"
            //             headerData={this.generateAlertHeader(
            //                 spaceName + "|" + i
            //             )}
            //             headerRightIcon={
            //                 <IconView
            //                     id={"img_" + this.state.selectedSpaceId}
            //                     dataType="rightIcon"
            //                     src="./images/alertMenu.png"
            //                     onclick={this.titleMenuClick}
            //                 />
            //             }
            //             bodyData={spaceName}
            //             spaceId={spaceID}
            //         ></CardView>
            //     );
            // }
            spaceList.forEach((space, id) => {
                AccordianList.push(
                    <CardView
                        id={space.name + space.id + "|" + space.id}
                        index={id}
                        handleCheck={handleCheckboxClick}
                        activeIndex={selectedCard}
                        setSelectedCard={changeSelectedCard}
                        onbodyclick={changeActiveCard}
                        // onMenuBtnClick={(e) =>
                        //     this.showPopover(
                        //         e,
                        //         i,
                        //         spaceInstance,
                        //         name + "|" + i
                        //     )
                        // }
                        //spaceInstance={spaceInstance}
                        cardClass="spaceCardGrid"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCardGridBodyWrapper"
                        cardBodyText="spaceCardGridBodyText"
                        fileName={space.name}
                        fileType={space.fileType}
                        imageSource={space.imageSource}
                        bodyData={space.name || ""}
                        spaceId={space.name + space.id || space.id}
                        spaceInstance={{
                            id: space.id,
                            name: space.name,
                            type: space.type,
                            time: space.time,
                        }}
                    ></CardView>
                );
            });
            let index = 0;
            while (index < extraCards) {
                AccordianList.push(
                    <CardView
                        isFileOrFolderSelected={isFileOrFolderSelected}
                        index={spaceList.length + index}
                        activeIndex={selectedCard}
                        handleCheck={handleCheckboxClick}
                        cardClass="spaceCardGrid"
                        setSelectedCard={changeSelectedCard}
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCardGridBodyWrapper"
                        cardBodyText="spaceCardGridBodyText"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            return AccordianList;
        } else {
            let index = 0;
            while (index < 12) {
                AccordianList.push(
                    <CardView
                        index={index}
                        activeIndex={-1}
                        handleCheck={handleCheckboxClick}
                        cardClass="spaceCardGrid"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            return AccordianList;
        }
    };
    const renderRowsForSlider = () => {
        let rowItems = [];
        let temp = [];
        let cardLists = computeSpaceCards();
        // let ColSize = 12 / displayCount;
        let ColSize = 2;
        if (cardLists.length > 0) {
            for (let index = 0; index < cardLists.length; index++) {
                temp.push(
                    <Column className="doCenter p-0" size={ColSize}>
                        {cardLists[index]}
                    </Column>
                );
                if (temp.length === displayCount) {
                    rowItems.push(
                        <InlineRow classname="ht-50 pb-2 integration_card_row">
                            {temp}
                        </InlineRow>
                    );
                    temp = [];
                }
            }
            return rowItems;
        }
    };
    return (
        <div className="spaceCardGridWrapper">
            <SliderView
                rawData={renderRowsForSlider()}
                cardCountPerSlide={2}
            ></SliderView>
        </div>
    );
}
