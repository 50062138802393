import React, { useState, useEffect } from "react";
import GenericStep from "./generic-step";
import ModalView from "../../../../stylized/ModalView";
import ShareFlow from "../WorkspaceContainer/AddFolderModal/ShareFlow";

const ShareGenericSteps = ({
    selectedGenericSteps,
    showGenericStepsModal,
    setShowGenericStepsModal,
    openShareFlow,
    setSelectedStepId,
    selectedStepId,
}) => {
    const [selectAllChecked, setSelectAllChecked] = React.useState(false);
    const [inputRadioState, setInputRadioState] = React.useState([]);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const handleCloseOrCancel = () => {
        closeModal();
    };

    const handleSelectAll = () => {
        let stepIds = [];
        if(!selectAllChecked) {
            selectedGenericSteps.map((item) => {
                stepIds.push(item.genericStepId);
            });
            setSelectedStepId(stepIds);
            setSelectedValues(selectedGenericSteps);
        } else {
            setSelectedStepId(stepIds);
            setSelectedValues([]);
        }
        const updatedInputRadioState = inputRadioState.map(
            () => !selectAllChecked
        );
        setInputRadioState(updatedInputRadioState);
        setSelectAllChecked(!selectAllChecked);
    };

    useEffect(() => {
        const initialInputRadioState = new Array(
            selectedGenericSteps.length
        ).fill(false);
        setInputRadioState(initialInputRadioState);
    }, [selectedGenericSteps]);
    const handleSubmit = () => {
        if(selectedStepId.length) {
            closeModal();
            openShareFlow();
        }
    };
    const allFalse = inputRadioState.every((isSelected) => !isSelected);
    const atLeastOneTrue = inputRadioState.some((isSelected) => isSelected);
    const closeModal = () => {
        setShowGenericStepsModal(false);
    };
    const handleInputCheckboxClick = (stepId, index) => {
        const updatedInputRadioState = [...inputRadioState];
        updatedInputRadioState[index] = !updatedInputRadioState[index];
        setInputRadioState(updatedInputRadioState);
        setSelectedStepId((prevStepId) => {
            return stepId !== undefined ? stepId : prevStepId;
        });

        // Update selectedValues based on the checkbox selection
        const updatedSelectedValues = [...selectedValues];
        if (updatedInputRadioState[index]) {
            updatedSelectedValues.push(stepId);
        } else {
            const selectedIndex = updatedSelectedValues.indexOf(stepId);
            if (selectedIndex !== -1) {
                updatedSelectedValues.splice(selectedIndex, 1);
            }
        }
        setSelectedValues(updatedSelectedValues);
        let newArray = [];
        updatedSelectedValues.map((item) => newArray.push(item.genericStepId));
        if(newArray.length === selectedGenericSteps.length) {
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
        }
        setSelectedStepId(newArray);
    };

    return (
        <>
            <ModalView
                showHeader="false"
                footer="false"
                show={showGenericStepsModal}
                onHide={handleCloseOrCancel}
                size="md"
                title={"Okay"}
                setSubmitButtonText={"Okay"}
                id="btn_folder_submit"
                onSubmit={handleSubmit}
                disable={allFalse || !atLeastOneTrue}
                value="Name"
                type="submit-cancel"
                submitButtonText="Close"
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    {/* <img src="./images/Space 2.svg"></img> */}
                    <div
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "22px",
                            lineHeight: "27px",
                            color: "#404040",
                            width: "430px",
                            marginTop: "-32px",
                            marginBottom: "28px",
                            marginRight: "-9px",
                        }}
                    >
                        Select Source Steps
                    </div>
                    <div
                        className="cardDiv invisibleScroller"
                        style={{
                            padding: "16px",
                            height: "60vh",
                            width: "85%",
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={handleSelectAll}
                        />
                        <label
                            id="share_select_all_option"
                            htmlFor="selectAll"
                            style={{ paddingLeft: "12px" }}
                        >
                            Select All
                        </label>
                        {selectedGenericSteps.map((item, index) => (
                            <>
                                <GenericStep
                                    handleInputCheckboxClick={
                                        handleInputCheckboxClick
                                    }
                                    inputRadioState={inputRadioState}
                                    handleSelectAll={handleSelectAll}
                                    setSelectedGenericSteps={
                                        selectedGenericSteps
                                    }
                                    genericSteps={selectedGenericSteps}
                                    step={item}
                                    stepIndex={index}
                                    isMergeModal={true}
                                    isSelected={inputRadioState[index]}
                                    handleCheckboxClick={() =>
                                        handleInputCheckboxClick(item, index)
                                    }
                                ></GenericStep>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "relative",
                                    }}
                                >
                                    {index ===
                                    selectedGenericSteps.length - 1 ? null : (
                                        <img
                                            src="./images/Arrow 7.png"
                                            alt="arrow"
                                        />
                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </ModalView>
            ;
            {/* <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <Loader show={showLoader}></Loader> */}
        </>
    );
};

export default ShareGenericSteps;
