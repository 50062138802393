import React from "react";
import classNames from "classnames";
import "./inputs.css";
import errorIcon from "./img/error.svg";
import successIcon from "./img/success.svg";
import disableSearche from "./img/disable_srch.svg";

const Inputs = ({
    type,
    placeholder,
    id,
    disable,
    showMore,
    isError,
    onChange,
    value,
    icon,
    handleKeyDown,
}) => {
    return (
        <div
            className={classNames("form-controls", {
                error: isError === true,
                success: isError === false,
            })}
        >
            <div className="input-container">
                <input
                    type={type}
                    style={
                        id === "stepId" || id === "stepName"
                            ? { width: "100%" }
                            : (id === "fromValue" && showMore === true) ||
                              (id === "toValue" && showMore === true)
                            ? { width: "214px" }
                            : (id === "fromValue" && showMore === false) ||
                              (id === "toValue" && showMore === false)
                            ? { width: "204px" }
                            : {}
                    }
                    id={id}
                    placeholder={placeholder}
                    disabled={disable}
                    onChange={(e) => onChange(e.target.value, id)} // Call onChange with value and id
                    value={value}
                    onKeyDown={handleKeyDown}
                />
                {icon && (
                    <img
                        src={
                            disable
                                ? disableSearche
                                : isError
                                ? errorIcon
                                : successIcon
                        }
                        alt={
                            disable
                                ? "Search Icon"
                                : isError
                                ? "Error Icon"
                                : "Success Icon"
                        }
                        className="icon"
                    />
                )}
            </div>
        </div>
    );
};

export default Inputs;
