import React, { Component } from "react";
import { connect } from "react-redux";
import { setActivePage } from "../../../actions";
class TitleBar extends Component {
    constructor(props) {
        super(props);
    }

    renderUserImageNadIcon = () => {
        return (
            <div className="row userTitleDiv">
                <img
                    className="titleIcon"
                    src="./images/CommunicatorIcon.png"
                ></img>
                <img
                    className="titleIcon"
                    src="./images/UserImageIcon.png"
                    onClick={() => {
                        this.props.setActivePage("Settings");
                    }}
                ></img>
                <span className="userLabel">{this.props.username}</span>
            </div>
        );
    };

    renderManageSpaceHeader = () => {
        return (
            <div className="titleSection row w-100" style={{ height: "5rem" }}>
                <div className="titleDiv">
                    <span
                        className="mainTitle textEllipsis"
                        style={{ paddingLeft: "10px" }}
                    >
                        {this.props.title}
                    </span>
                    <span className="subTitle" style={{ paddingLeft: "10px" }}>
                        {this.props.subTitle}
                    </span>
                </div>
                {this.renderUserImageNadIcon()}
            </div>
        );
    };
    renderTitleImage = () => {
        if (this.props.titleImage !== undefined) {
            return <img src={this.props.titleImage} alt="titleImage" />;
        } else {
            return null;
        }
    };
    renderWorkspaceEditHeader = () => {
        return (
            <div className="titleSection row w-100">
                <div className="">
                    <span
                        className="mainTitle textEllipsis"
                        style={{ paddingLeft: "10px" }}
                    >
                        {this.props.title}
                    </span>
                    <span
                        className="editLabel"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.handleEditClick()}
                    >
                        {this.props.subTitle}
                    </span>
                </div>
                {this.renderUserImageNadIcon()}
            </div>
        );
    };

    render() {
        if (this.props.page === "managespace") {
            return this.renderManageSpaceHeader();
        } else if (this.props.page === "workspace") {
            return this.renderWorkspaceEditHeader();
        } else {
            return (
                <div className="titleSection row w-100">
                    <div className="activePageLeftTitle titleDiv">
                        {this.renderTitleImage()}
                        <span
                            className="mainTitle textEllipsis"
                            style={{ paddingLeft: "10px" }}
                        >
                            {this.props.title}
                        </span>
                    </div>
                    {this.renderUserImageNadIcon()}
                </div>
            );
        }
    }
}
const mapDispatchToProps = () => {
    return {
        setActivePage,
    };
};
const mapStateToProps = (state) => {
    return {
        activePage: state.activePage,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(TitleBar);
