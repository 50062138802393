import { Box, Button, Modal, Tab } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ControlledTreeView from "../../../../../stylized/tree-view/TreeView";
import CustomDropdown from "../../../../../stylized/CustomDropdown";
// import "../../../../../stylized/nested-modal/style.css";
import { ButtonComponent } from "../../../../../button/button";
import Loader from "../../../../../stylized/Loader";
import http from "../../../../../../bae-client/HTTP";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarAction } from "../../../../../../services/services/app";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getDateByFormat } from "../../../../../../bae-client/Utils";
import FlowRuleRow from "./FlowRuleRow";
import { EmptyRuleScreen } from "./EmptyRuleScreen";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #D7DEEA",
    boxShadow: 24,
    padding: "24px",
    paddingRight: "0px",
    paddingBottom: "16px",
};
const inputStyle = {
    width: "100%",
    margin: "8px",
    background: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};
const convertData = (data) => {
    const response = [];
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (element && element.documentId === 0) {
                response.push({
                    id: parseInt(key),
                    name: element.objectName,
                    children: convertData(element.children),
                    type: "folder",
                });
            }
        }
    }
    return response;
};
const getSpaceData = async (workspaceId, spaceId) => {
    const spaceData = await http.sendRestRequest(
        `spacePersistence/objectList?spaceId=${spaceId}`
    );
    const response = convertData(spaceData);

    return response;
};

export default function NestedModal({
    open,
    onClose,
    showLoader,
    setShowLoader,
    selectedGenericSteps,
    genericId,
    genericName,
}) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState("1");
    const [openSecondModal, setOpenSecondModal] = React.useState(false);
    const [selectedRule, setSelectedRule] = React.useState(null);
    const [rules, setRules] = React.useState([]);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [recentValues, setRecentValues] = React.useState([]);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    React.useEffect(() => {
        loadAllRules();
        getRecentActivityList();
    }, []);

    const getRecentActivityList = () => {
        setShowLoader(true);
        const url = `generic/Rules/logs?genericId=${genericId}`;
        http.sendRestRequest(url, "GET")
            .then((resp) => {
                resp.sort(function (a, b) {
                    return (
                        new Date(b.documentAttachedOn) -
                        new Date(a.documentAttachedOn)
                    );
                });
                setRecentValues(resp);
                setShowLoader(false);
            })
            .catch((error) => {
                console.error("Error fetching recent activity list:", error);
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message:
                            error?.error ||
                            "Error fetching recent activity list",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };

    const loadAllRules = () => {
        setShowLoader(true);
        http.sendRestRequest(`generic/Rule?genericId=${genericId}`)
            .then((response) => {
                let sortedResponse = response.sort(
                    (a, b) => b.genericRuleId - a.genericRuleId
                );
                setShowLoader(false);
                setRules(sortedResponse);
            })
            .catch((error) => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: error?.error || "Error loading rules",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };

    const deleteHandler = (rule) => {
        const { genericRuleId } = rule;
        setShowLoader(true);
        http.sendRestRequest(
            `generic/Rule?genericRuleId=${genericRuleId}`,
            "DELETE"
        )
            .then(() => {
                setShowLoader(false);
                loadAllRules();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Flow rule deleted successfully",
                        severity: "success",
                    })
                );
            })
            .catch((error) => {
                console.error("Error deleting Rule:", error);
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error?.error || "Error deleting Rule",
                        severity: "error",
                    })
                );
            });
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const renameHandler = (rule) => {
        setSelectedRule(rule);
        setOpenSecondModal(true);
    };

    const clickNewMoveHandler = () => {
        setSelectedRule(null);
        setOpenSecondModal(true);
    };
    const slashRemove = (path) => {
        const lastCharacter = path[path.length - 1];
        if (lastCharacter === "\\") {
            path = path.slice(0, -1);
        }
        return path;
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 850 }}>
                    <TabContext value={value}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: 1,
                                borderColor: "divider",
                                marginLeft: "24px",
                                marginRight: "24px",
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab
                                    id="tab_moveRule_flow"
                                    data-id="tab_moveRule_flow"
                                    label="Flow Rules"
                                    title="Flow Rules"
                                    value="1"
                                    sx={{
                                        width: "152px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "-0.006em",
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_activity_flow"
                                    data-id="tab_activity_flow"
                                    label="Flow Rules Log"
                                    title="Flow Rules Log"
                                    value="2"
                                    sx={{
                                        width: "224px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "-0.006em",
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                    }}
                                />
                            </TabList>
                            <Button
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                variant="contained"
                                size="small"
                                title="New Flow Rule"
                                sx={{ textTransform: "none" }}
                                onClick={clickNewMoveHandler}
                            >
                                New Flow Rule
                            </Button>
                        </Box>
                        <TabPanel value="1">
                            <div
                                className={`${
                                    rules?.length ? "" : "recent-activity-log"
                                } nested-modal-body invisibleScroller`}
                                style={{
                                    height: "300px",
                                }}
                            >
                                {rules?.length ? (
                                    rules.map((rule) => (
                                        <FlowRuleRow
                                            ruleName={rule?.genericRuleName}
                                            paragraph={""}
                                            active={rule.isActive}
                                            key={rule.genericRuleId}
                                            rule={rule}
                                            deleteHandler={deleteHandler}
                                            renameHandler={renameHandler}
                                            setShowLoader={setShowLoader}
                                            loadAllRules={loadAllRules}
                                        />
                                    ))
                                ) : (
                                    <EmptyRuleScreen
                                        label={"No rules to show"}
                                    />
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <div
                                className={`${
                                    recentValues?.length
                                        ? ""
                                        : "recent-activity-log"
                                } nested-modal-body invisibleScroller`}
                                style={{ height: "300px" }}
                            >
                                {recentValues.length ? (
                                    recentValues.map((item) => (
                                        <div
                                            key={item?.id}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div className="row-activity">
                                                <div
                                                    className="rule-name-heading textEllipsis"
                                                    title={
                                                        item?.genericRuleName
                                                    }
                                                >
                                                    {item?.genericRuleName}
                                                </div>
                                                <div className="rule-details">
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Document name:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item?.documentName
                                                            }
                                                        >
                                                            {item?.documentName}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            From workspace
                                                            location:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item?.fromWorkspaceLocation
                                                            }
                                                        >
                                                            {
                                                                item?.fromWorkspaceLocation
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            To Flow location:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item?.toFlowLocation
                                                            }
                                                        >
                                                            {slashRemove(
                                                                item?.toFlowLocation
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Date of document
                                                            getting entered in
                                                            Unity Central:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.documentCreatedOn
                                                                    ? getDateByFormat(
                                                                          item.documentCreatedOn,
                                                                          "mm/dd/yyyy"
                                                                      )
                                                                    : null
                                                            }
                                                        >
                                                            {item.documentCreatedOn
                                                                ? getDateByFormat(
                                                                      item.documentCreatedOn,
                                                                      "mm/dd/yyyy"
                                                                  )
                                                                : null}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Date the document
                                                            got attached to
                                                            Flow:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.documentAttachedOn
                                                                    ? getDateByFormat(
                                                                          item.documentAttachedOn,
                                                                          "mm/dd/yyyy"
                                                                      )
                                                                    : null
                                                            }
                                                        >
                                                            {item.documentAttachedOn
                                                                ? getDateByFormat(
                                                                      item.documentAttachedOn,
                                                                      "mm/dd/yyyy"
                                                                  )
                                                                : null}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    border: "1px solid #D7DEEA",
                                                }}
                                            ></div>
                                        </div>
                                    ))
                                ) : (
                                    <EmptyRuleScreen
                                        label={"No logs to show"}
                                    />
                                )}
                            </div>
                        </TabPanel>
                    </TabContext>
                    <div className="nested-modal-container">
                        <div
                            className="action"
                            style={{ textAlign: "right", marginRight: "24px" }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "none" }}
                                onClick={() => onClose(false)}
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                    color: "black",
                                }}
                                title="Cancel"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    {openSecondModal && (
                        <FlowRule
                            open={openSecondModal}
                            handleClose={() => setOpenSecondModal(false)}
                            loadAllRules={loadAllRules}
                            existingData={selectedRule}
                            setSelectedRule={setSelectedRule}
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            rules={rules}
                            selectedGenericSteps={selectedGenericSteps}
                            genericId={genericId}
                            genericName={genericName}
                            allRules={rules.map((item) => item.genericRuleName)}
                        />
                    )}
                </Box>
            </Modal>
            <Loader show={showLoader} />
        </div>
    );
}
const FlowRule = ({
    genericId,
    open,
    handleClose,
    selectedGenericSteps,
    isEdit,
    existingData,
    loadAllRules,
    setSelectedRule,
    genericName,
    allRules,
}) => {
    const useStyles = makeStyles({
        root: {
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            background: "white !important",
            borderRadius: "6px !important",
            border: "none !important",
            margin: "0px",
        },
    });
    const classes = useStyles();

    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);
    const documentChainList = useSelector(
        (state) => state.DocumentChainReducer.documentChainList
    );

    const [error, setError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [documentAttributes, setDocumentAttributes] = useState([]);
    const [flowAttributes, setFlowAttributes] = useState([]);
    const [attributesValues, setAttributeValues] = useState([]);
    const [flowAttributesValues, setFlowAttributeValues] = useState([
        {
            text: "Flow Name",
            value: "Flow Name",
            id: 1,
        },
        {
            text: "Instance Name",
            value: "Instance Name",
            id: 2,
        },
        {
            text: "Step Name",
            value: "Step Name",
            id: 3,
        },
        {
            text: "Branch Name",
            value: "Branch Name",
            id: 4,
        },
    ]);
    const [flowAttributesValuesList, setFlowAttributesValuesList] = useState(
        []
    );
    const [resetFlowRow, setResetFlowRow] = useState(false);
    const [resetDocumentRow, setResetDocumentRow] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedFlowAttribute, setSelectedFlowAttribute] = useState(null);
    const [selectedAttributeValue, setSelectedAttributeValue] = useState("");
    const [selectedFlowAttributeValue, setSelectedFlowAttributeValue] =
        useState(null);
    const [treeData, setTreeData] = React.useState([]);
    const [selectedStorage, setSelectedStorage] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [flowRuleName, setFlowRuleName] = React.useState("");
    const [defaultFlowName, setDefaultFlowName] = React.useState({
        text: genericName,
        value: "generic",
        id: 1,
    });
    useEffect(() => {
        if (existingData) {
            const { workspaceId, spaceId, folderId, genericRuleName } =
                existingData;
            setExpanded([workspaceId, spaceId, folderId]);
            if (folderId) setSelected(folderId);
            else setSelected(spaceId);
            setFlowRuleName(genericRuleName);
            const existingDocumentAttributes =
                existingData.documentAttributes?.map((attribute) => {
                    return {
                        [attribute.attributeName]: attribute.regularExpression,
                    };
                });
            setDocumentAttributes(existingDocumentAttributes);
            const existingFlowAttributes = existingData.flowAttributes.map(
                (attribute) => {
                    return {
                        [attribute.attributeName]: {
                            text: attribute.regularExpression,
                            value: attribute.attributeType,
                            id: attribute.id,
                        },
                    };
                }
            );
            setFlowAttributes(existingFlowAttributes);
        } else {
            setSelectedFlowAttribute({
                text: "Flow Name",
                value: "Flow Name",
                id: 1,
            });
            setSelectedFlowAttributeValue({
                text: genericName,
                value: "generic",
                id: genericId,
            });
        }
    }, [existingData]);

    const buildTree = async () => {
        const data = [];
        for (let i = 0; i < workspaceList.length; i++) {
            if (workspaceList[i].workspaceType !== "SYSTEM") {
                let children =
                    workspaceList[i].workspaceId === existingData?.workspaceId
                        ? await http.sendRestRequest(
                              `workspace/spaceList?workspaceId=${workspaceList[i].workspaceId}`
                          )
                        : [];
                data.push({
                    id: workspaceList[i].workspaceId,
                    name: workspaceList[i].workspaceName,
                    type: "workspace",
                    children: children.map((s) => ({
                        id: s.spaceId,
                        name: s.spaceName,
                        type: "space",
                    })),
                });
            }
        }
        if (existingData) {
            const { workspaceId, spaceId, folderId } = existingData;
            setExpanded([workspaceId, spaceId, folderId]);
            setSelectedStorage({
                workspaceId,
                spaceId,
                folderId,
            });
        }
        setTreeData(data);
    };

    React.useEffect(() => {
        // if (workspaceList && workspaceList.length) {
        //     const data = [];
        //     for (let i = 0; i < workspaceList.length; i++) {
        //         if (
        //             workspaceList[i].workspaceName !== "Emails" &&
        //             workspaceList[i].workspaceName !== "Flow"
        //         ) {
        //             data.push({
        //                 id: workspaceList[i].workspaceId,
        //                 name: workspaceList[i].workspaceName,
        //                 type: "workspace",
        //                 children: [
        //                     ...workspaceList[i].spaceList.map((s) => ({
        //                         id: s.spaceId,
        //                         name: s.spaceName,
        //                         type: "space",
        //                     })),
        //                 ],
        //             });
        //         }
        //     }
        //     if (existingData) {
        //         const { workspaceId, spaceId, folderId } = existingData;
        //         let validWorkspace, validSpace;
        //         validWorkspace = workspaceList.findIndex(
        //             (w) => w.workspaceId === workspaceId
        //         );
        //         if (validWorkspace >= 0) {
        //             validSpace = workspaceList[
        //                 validWorkspace
        //             ]?.spaceList.findIndex((s) => s.spaceId === spaceId);
        //         }

        //         if (validWorkspace >= 0 && validSpace >= 0) {
        //             setShowLoader(true);
        //             getSpaceData(workspaceId, spaceId)
        //                 .then((response) => {
        //                     const workspaceIndex = data.findIndex(
        //                         (item) => item.id === workspaceId
        //                     );
        //                     const spaceIndex = data[
        //                         workspaceIndex
        //                     ].children.findIndex((item) => item.id === spaceId);
        //                     data[workspaceIndex].children[spaceIndex].children =
        //                         response;
        //                     setTreeData(data);
        //                     setSelectedStorage({
        //                         workspaceId: workspaceId,
        //                         spaceId: spaceId,
        //                         folderId: folderId,
        //                     });
        //                     setError("");
        //                     setShowLoader(false);
        //                 })
        //                 .catch((err) => setShowLoader(false));
        //         } else setTreeData(data);
        //     } else setTreeData(data);
        // }
        if (workspaceList && workspaceList.length) buildTree();
    }, [workspaceList]);
    useEffect(() => {
        if (selectedStorage && selectedStorage.spaceId) {
            if (selectedStorage.folderId)
                loadAttibutes(selectedStorage.folderId);
            else loadAttibutes(selectedStorage.spaceId);
        }
    }, [selectedStorage]);
    useEffect(() => {
        if (selectedFlowAttribute) {
            const filteredAttributeValues = attributesValues.filter(
                (attribute) => attribute?.value?.toUpperCase() === "CUSTOM"
            );
            if (selectedFlowAttribute.id === 1) {
                const flows = documentChainList.map((flow) => {
                    return {
                        text: flow.genericDisplayName,
                        value: "generic",
                        id: flow.genericId,
                    };
                });
                setFlowAttributesValuesList((prev) => [
                    ...filteredAttributeValues,
                    ...flows,
                ]);
            } else if (
                selectedFlowAttribute.id === 2 ||
                selectedFlowAttribute.id === 4
            )
                setFlowAttributesValuesList(filteredAttributeValues);
            else {
                const genSteps = selectedGenericSteps.map((step, index) => {
                    return {
                        text: step,
                        value: "generic",
                        id: index,
                    };
                });
                setFlowAttributesValuesList([
                    ...filteredAttributeValues,
                    ...genSteps,
                ]);
            }
        }
    }, [selectedFlowAttribute]);
    useEffect(() => {
        if (flowAttributes.length) setResetFlowRow(false);
    }, [flowAttributes]);
    useEffect(() => {
        if (documentAttributes.length) setResetDocumentRow(false);
    }, [documentAttributes]);
    const loadAttibutes = (objectedId) => {
        setShowLoader(true);
        http.sendRestRequest(
            `object/attributes?objectId=${objectedId}&&addFileAttributes=${true}`
        )
            .then((response) => {
                setShowLoader(false);
                const attributes = response.map((attribute, index) => {
                    return {
                        text: attribute.attributeName,
                        value: attribute.attributeType,
                        id: attribute.attributeId || index,
                    };
                });
                setAttributeValues(attributes);
            })
            .catch((e) => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: e?.error || "Something went wrong",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };
    const handleSave = () => {
        if (!selectedStorage || !selectedStorage.spaceId) {
            setError("Please select Storage with atleast a space.");
            return;
        }
        if (!flowRuleName) {
            setError("Please enter rule name.");
            return;
        }
        if (allRules.includes(flowRuleName) && !existingData) {
            setError("Rule name already exists.");
            return;
        }
        const _documentAttributes = [
            ...documentAttributes.map((item) => {
                const data = Object.values(item)[0];
                const dataKey = Object.keys(item)[0];
                return {
                    attributeName: dataKey,
                    regularExpression: data,
                    attributeType: "attribute",
                };
            }),
        ]; // [...documentAttributes];

        if (selectedAttribute?.text && selectedAttributeValue)
            _documentAttributes.push({
                attributeName: selectedAttribute.text,
                regularExpression: selectedAttributeValue,
                attributeType: selectedAttribute.value,
            });
        const _flowAttributes = [
            ...flowAttributes.map((item) => {
                const data = Object.values(item)[0];
                const dataKey = Object.keys(item)[0];
                return {
                    attributeName: dataKey,
                    regularExpression: data.text,
                    attributeType: data.value,
                };
            }),
        ];

        if (selectedFlowAttribute?.text && selectedFlowAttributeValue?.text)
            _flowAttributes.push({
                attributeName: selectedFlowAttribute.text,
                regularExpression: selectedFlowAttributeValue.text,
                attributeType: selectedFlowAttributeValue.value,
            });
        const payload = {
            genericId: parseInt(genericId),
            genericRuleName: flowRuleName,
            workspaceId: selectedStorage.workspaceId,
            spaceId: selectedStorage.spaceId,
            folderId: selectedStorage.folderId || 0,
            action: "attach file to step",
            documentAttributes: _documentAttributes,
            flowAttributes: _flowAttributes.map((item) => {
                return {
                    attributeName: item.attributeName,
                    regularExpression: item.regularExpression,
                    attributeType:
                        item.attributeType === "generic"
                            ? item.attributeType
                            : "attribute",
                };
            }),
        };
        const putPayload = {
            genericRuleId: existingData ? existingData.genericRuleId : 0,
            genericRuleName: flowRuleName,
        };
        if (existingData) {
            const { workspaceId, spaceId, folderId } = existingData;
            const deletedDocumentAttributes =
                existingData?.documentAttributes?.filter(
                    (item) =>
                        !_documentAttributes.find(
                            (attribute) =>
                                attribute.attributeName ===
                                    item.attributeName &&
                                attribute.regularExpression ===
                                    item.regularExpression
                        )
                );
            const deletedFlowAttributes = existingData?.flowAttributes.filter(
                (item) =>
                    !_flowAttributes.find(
                        (attribute) =>
                            attribute.attributeName === item.attributeName &&
                            attribute.regularExpression ===
                                item.regularExpression
                    )
            );
            const changedDocumentAttributes = _documentAttributes.filter(
                (attribute) =>
                    !existingData?.documentAttributes?.find(
                        (item) =>
                            item.attributeName === attribute.attributeName &&
                            item.regularExpression ===
                                attribute.regularExpression
                    )
            );
            const changedFlowAttributes = _flowAttributes.filter(
                (attribute) =>
                    !existingData.flowAttributes.find(
                        (item) =>
                            item.attributeName === attribute.attributeName &&
                            item.regularExpression ===
                                attribute.regularExpression
                    )
            );
            if (
                !changedDocumentAttributes.length &&
                !changedFlowAttributes.length &&
                !deletedDocumentAttributes.length &&
                !deletedFlowAttributes.length &&
                existingData.genericRuleName === flowRuleName &&
                selectedStorage.workspaceId === workspaceId &&
                selectedStorage.spaceId === spaceId &&
                selectedStorage.folderId === folderId
            ) {
                setError("Nothing changed!");
                return;
            }
            if (
                changedDocumentAttributes?.length ||
                deletedDocumentAttributes?.length
            ) {
                // putPayload.documentAttributes = changedDocumentAttributes;
                putPayload.documentAttributes = _documentAttributes;
            }
            if (
                changedFlowAttributes?.length ||
                deletedFlowAttributes?.length
            ) {
                // putPayload.flowAttributes = changedFlowAttributes;
                putPayload.flowAttributes = _flowAttributes.map((item) => {
                    return {
                        attributeName: item.attributeName,
                        regularExpression: item.regularExpression,
                        attributeType:
                            item.attributeType === "generic"
                                ? item.attributeType
                                : "attribute",
                    };
                });
            }
            if (
                workspaceId !== selectedStorage.workspaceId ||
                spaceId !== selectedStorage.spaceId ||
                folderId !== selectedStorage.folderId
            ) {
                putPayload.workspaceId = selectedStorage.workspaceId;
                putPayload.spaceId = selectedStorage.spaceId;
                putPayload.folderId = selectedStorage.folderId || 0;
            }
        }
        setShowLoader(true);
        http.sendRestRequest(
            `generic/Rule${
                existingData ? `?genericId=${existingData.genericId}` : ""
            }`,
            `${existingData ? "PUT" : "POST"}`,
            existingData ? putPayload : payload
        )
            .then(() => {
                setShowLoader(false);
                const message = existingData
                    ? "Flow Rule updated successfully"
                    : "Flow Rule created successfully";
                dispatch(
                    setSnackbarAction({
                        message: message,
                        severity: "success",
                        open: true,
                    })
                );
                loadAllRules();
                handleClose(false);
            })
            .catch((error) => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: error.error,
                        severity: "error",
                        open: true,
                    })
                );
            });
    };
    const selectHandler = async (workspaceId, spaceId, folderId) => {
        if (!folderId && spaceId) {
            setShowLoader(true);
            getSpaceData(workspaceId, spaceId)
                .then((response) => {
                    const oldData = [...treeData];
                    const workspaceIndex = oldData.findIndex(
                        (item) => item.id === workspaceId
                    );
                    const spaceIndex = oldData[
                        workspaceIndex
                    ].children.findIndex((item) => item.id === spaceId);
                    oldData[workspaceIndex].children[spaceIndex].children =
                        response;
                    setTreeData(oldData);
                    setSelectedStorage({
                        workspaceId: workspaceId,
                        spaceId: spaceId,
                        folderId: folderId,
                    });
                    setError("");
                    setShowLoader(false);
                    if (expanded.includes(spaceId)) return;
                    setExpanded([...expanded, spaceId]);
                })
                .catch((err) => setShowLoader(false));
        } else if (!spaceId) {
            const spaceList = await http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            );
            const oldData = [...treeData];
            const workspaceIndex = oldData.findIndex(
                (item) => item.id === workspaceId
            );
            oldData[workspaceIndex].children = spaceList.map((s) => ({
                id: s.spaceId,
                name: s.spaceName,
                type: "space",
            }));
            setTreeData(oldData);
            if (expanded.includes(workspaceId)) return;
            setExpanded([...expanded, workspaceId]);
        } else {
            setSelectedStorage({
                workspaceId: workspaceId,
                spaceId: spaceId,
                folderId: folderId,
            });
            setError("");
            if (expanded.includes(folderId)) return;
            setExpanded([...expanded, folderId]);
        }
    };
    const udpateDocumentAttributes = (type, item, index) => {
        const newDocumentAttributes = [...documentAttributes];
        if (type === "dropdown") {
            newDocumentAttributes[index] = {
                [item.text]: Object.values(documentAttributes[index])[0],
            };
        }
        if (type === "input") {
            newDocumentAttributes[index] = {
                [Object.keys(documentAttributes[index])[0]]: item,
            };
        }
        setDocumentAttributes(newDocumentAttributes);
    };
    const udpateFlowAttributes = (type, item, index, isFirst) => {
        const newFlowAttributes = [...flowAttributes];
        if (type === "dropdown") {
            if (isFirst) {
                newFlowAttributes[index] = {
                    [item.text]: Object.values(flowAttributes[index])[0],
                };
            } else
                newFlowAttributes[index] = {
                    [Object.keys(flowAttributes[index])[0]]: item,
                };
        }
        if (type === "input") {
            newFlowAttributes[index] = {
                [Object.keys(flowAttributes[index])[0]]: item,
            };
        }
        setFlowAttributes(newFlowAttributes);
    };

    const handleRemoveDocumentAttributes = (index) => {
        const newDocumentAttributes = [...documentAttributes];
        newDocumentAttributes.splice(index, 1);
        setDocumentAttributes(newDocumentAttributes);
    };
    const handleRemoveFlowAttributes = (index) => {
        const newFlowAttributes = [...flowAttributes];
        newFlowAttributes.splice(index, 1);
        setFlowAttributes(newFlowAttributes);
    };
    const handleAddDocumentAttribute = (event) => {
        if (
            selectedAttribute &&
            selectedAttribute.text &&
            selectedAttributeValue
        ) {
            setResetDocumentRow(true);
            setDocumentAttributes([
                ...documentAttributes,
                { [selectedAttribute.text]: selectedAttributeValue },
            ]);
            setSelectedAttribute(null);
            setSelectedAttributeValue("");
        }
        return;
    };
    const handleAddFlowAttribute = (event) => {
        if (
            selectedFlowAttribute &&
            selectedFlowAttribute.text &&
            selectedFlowAttributeValue &&
            selectedFlowAttributeValue.text
        ) {
            setResetFlowRow(true);
            setFlowAttributes([
                ...flowAttributes,
                { [selectedFlowAttribute.text]: selectedFlowAttributeValue },
            ]);
            setSelectedFlowAttribute(null);
            setFlowAttributesValuesList([]);
            // setSelectedAttributeValue(null);
        }
        return;
    };
    const clearData = () => {
        handleClose(false);
        setError("");
        setSelectedAttribute(null);
        setSelectedAttributeValue("");
        setSelectedFlowAttribute(null);
        setSelectedFlowAttributeValue(null);
    };

    const getDropdownValues = (attribute) => {
        const selectedValue = Object.keys(attribute)[0];
        const filteredAttributeValues = attributesValues.filter(
            (attribute) => attribute?.value?.toUpperCase() === "CUSTOM"
        );
        if (selectedValue === "Flow Name") {
            const flows = documentChainList.map((flow) => {
                return {
                    text: flow.genericDisplayName,
                    value: "generic",
                    id: flow.genericId,
                };
            });
            return [...filteredAttributeValues, ...flows];
        } else if (
            selectedValue === "Instance Name" ||
            selectedValue === "Branch Name"
        )
            return filteredAttributeValues.map((item) => {
                return {
                    text: item.text,
                    value: "attribute",
                    id: item.id,
                };
            });
        else {
            const genSteps = selectedGenericSteps.map((step, index) => {
                return {
                    text: step,
                    value: "generic",
                    id: index,
                };
            });
            return [...filteredAttributeValues, ...genSteps];
        }
    };
    const getItemsForFlowAttributes = () => {
        const existingFlowAttributes = flowAttributes.map(
            (item) => Object.keys(item)[0]
        );
        return flowAttributesValues.filter(
            (item) => !existingFlowAttributes.includes(item.text)
        );
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 650 }}>
                    <div
                        className="invisibleScroller"
                        id="flowModal"
                        style={{ paddingRight: "27px" }}
                    >
                        <div className="nested-modal-container invisibleScroller">
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-1.svg" alt="" />
                                Flow Rule Name
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <input
                                    autocomplete="off"
                                    type="text"
                                    placeholder="Name Rule"
                                    name="ruleName"
                                    className="input-rule-name"
                                    value={flowRuleName}
                                    onChange={(e) =>
                                        setFlowRuleName(e.target.value)
                                    }
                                    style={inputStyle}
                                />
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-2.svg" alt="" />
                                Attach From Source
                            </div>
                            <div className="tree-view invisibleScroller">
                                <ControlledTreeView
                                    data={treeData}
                                    setSelected={setSelected}
                                    setExpanded={setExpanded}
                                    expanded={expanded}
                                    selected={selected}
                                    selectHandler={selectHandler}
                                />
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-3.svg" alt="" />
                                Select Document Attributes
                            </div>

                            {documentAttributes.map(
                                (attribute, conditionIndex) => (
                                    <>
                                        <div
                                            className="conditions"
                                            style={{ gap: "2px" }}
                                        >
                                            <CustomDropdown
                                                items={attributesValues}
                                                label={"Select an attribute"}
                                                customClassName={classes.root}
                                                handleSelection={(
                                                    index,
                                                    item
                                                ) =>
                                                    udpateDocumentAttributes(
                                                        "dropdown",
                                                        item,
                                                        conditionIndex
                                                    )
                                                }
                                                selection={attributesValues.find(
                                                    (item) =>
                                                        item.text ===
                                                        Object.keys(
                                                            attribute
                                                        )[0]
                                                )}
                                            />
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                placeholder="text"
                                                name="ruleTwo"
                                                className="input-rule-name"
                                                value={
                                                    Object.values(attribute)[0]
                                                }
                                                onChange={(e) => {
                                                    udpateDocumentAttributes(
                                                        "input",
                                                        e.target.value,
                                                        conditionIndex
                                                    );
                                                }}
                                                style={inputStyle}
                                            />
                                            <div
                                                className="labels"
                                                title="Remove"
                                            >
                                                <ButtonComponent
                                                    type={"secondary"}
                                                    // text={"Remove Condition"}
                                                    icon={
                                                        "./images/trash 2.svg"
                                                    }
                                                    BtnHandler={() =>
                                                        handleRemoveDocumentAttributes(
                                                            conditionIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                            {!resetDocumentRow ? (
                                <div
                                    className="conditions"
                                    style={{ gap: "2px" }}
                                >
                                    <CustomDropdown
                                        items={attributesValues}
                                        label={"Select an attribute"}
                                        customClassName={classes.root}
                                        handleSelection={(index, item) =>
                                            setSelectedAttribute(item)
                                        }
                                        selection={""}
                                    />
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        placeholder="text"
                                        name="ruleTwo"
                                        className={`input-rule-name `}
                                        style={inputStyle}
                                        value={selectedAttributeValue}
                                        onChange={(e) => {
                                            setSelectedAttributeValue(
                                                e.target.value
                                            );
                                        }}
                                    />
                                    <div
                                        className="labels"
                                        style={{ visibility: "hidden" }}
                                        title="Remove"
                                    >
                                        <ButtonComponent
                                            type={"secondary"}
                                            // text={"Remove Condition"}
                                            icon={"./images/trash 2.svg"}
                                            BtnHandler={() => {}}
                                            disable={true}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div
                                className="labels"
                                style={{ marginLeft: "8px" }}
                            >
                                <ButtonComponent
                                    type={"ghost"}
                                    text={"Add Condition"}
                                    icon={"./images/plus.svg"}
                                    BtnHandler={handleAddDocumentAttribute}
                                    disable={
                                        selectedAttribute &&
                                        selectedAttributeValue
                                            ? false
                                            : true
                                    }
                                />
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-4.svg" alt="" />
                                Select Flow Attributes
                            </div>
                            {flowAttributes.map(
                                (flowAttribute, conditionIndex) => (
                                    <>
                                        <div
                                            className="conditions"
                                            style={{
                                                marginBottom: "8px",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                gap: "2px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CustomDropdown
                                                items={getItemsForFlowAttributes()}
                                                label={"Select an option"}
                                                customClassName={classes.root}
                                                handleSelection={(
                                                    index,
                                                    item
                                                ) =>
                                                    udpateFlowAttributes(
                                                        "dropdown",
                                                        item,
                                                        conditionIndex,
                                                        true
                                                    )
                                                }
                                                selection={flowAttributesValues.find(
                                                    (item) =>
                                                        item.text ===
                                                        Object.keys(
                                                            flowAttribute
                                                        )[0]
                                                )}
                                            />
                                            <CustomDropdown
                                                items={getDropdownValues(
                                                    flowAttribute
                                                )}
                                                label={"Select an Attribute"}
                                                // customClassName={"rippleDD rippleDDFilterView"}
                                                handleSelection={(
                                                    index,
                                                    item
                                                ) => {
                                                    udpateFlowAttributes(
                                                        "dropdown",
                                                        item,
                                                        conditionIndex
                                                    );
                                                }}
                                                selection={getDropdownValues(
                                                    flowAttribute
                                                )?.find(
                                                    (item) =>
                                                        item.text ===
                                                        Object.values(
                                                            flowAttribute
                                                        )[0]?.text
                                                )}
                                                customClassName={classes.root}
                                            />
                                            <div
                                                className="labels"
                                                title="Remove"
                                            >
                                                <ButtonComponent
                                                    type={"secondary"}
                                                    // text={"Remove Condition"}
                                                    icon={
                                                        "./images/trash 2.svg"
                                                    }
                                                    BtnHandler={() =>
                                                        handleRemoveFlowAttributes(
                                                            conditionIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                            {!resetFlowRow ? (
                                !existingData && !flowAttributes.length ? (
                                    <div
                                        className="conditions"
                                        style={{ gap: "2px" }}
                                    >
                                        <CustomDropdown
                                            items={getItemsForFlowAttributes()}
                                            label={"Select an option"}
                                            customClassName={classes.root}
                                            handleSelection={(index, item) =>
                                                setSelectedFlowAttribute(item)
                                            }
                                            selection={
                                                selectedFlowAttribute || {
                                                    text: "Flow Name",
                                                    value: "Flow Name",
                                                    id: 1,
                                                }
                                            }
                                        />
                                        <CustomDropdown
                                            items={flowAttributesValuesList}
                                            label={"Select an attribute"}
                                            // customClassName={"rippleDD rippleDDFilterView"}
                                            handleSelection={(index, item) => {
                                                setSelectedFlowAttributeValue(
                                                    item
                                                );
                                            }}
                                            customClassName={classes.root}
                                            selection={
                                                selectedAttributeValue ||
                                                defaultFlowName
                                            }
                                        />
                                        <div
                                            className="labels"
                                            style={{ visibility: "hidden" }}
                                            title="Remove"
                                        >
                                            <ButtonComponent
                                                type={"secondary"}
                                                // text={"Remove Condition"}
                                                icon={"./images/trash 2.svg"}
                                                BtnHandler={() => {}}
                                                disable={true}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="conditions"
                                        style={{ gap: "2px" }}
                                    >
                                        <CustomDropdown
                                            items={getItemsForFlowAttributes()}
                                            label={"Select an option"}
                                            customClassName={classes.root}
                                            handleSelection={(index, item) =>
                                                setSelectedFlowAttribute(item)
                                            }
                                        />
                                        <CustomDropdown
                                            items={flowAttributesValuesList}
                                            label={"Select an attribute"}
                                            // customClassName={"rippleDD rippleDDFilterView"}
                                            handleSelection={(index, item) => {
                                                setSelectedFlowAttributeValue(
                                                    item
                                                );
                                            }}
                                            customClassName={classes.root}
                                        />
                                        <div
                                            className="labels"
                                            style={{ visibility: "hidden" }}
                                            title="Remove"
                                        >
                                            <ButtonComponent
                                                type={"secondary"}
                                                // text={"Remove Condition"}
                                                icon={"./images/trash 2.svg"}
                                                BtnHandler={() => {}}
                                                disable={true}
                                            />
                                        </div>
                                    </div>
                                )
                            ) : null}

                            <div
                                className="labels"
                                style={{ marginLeft: "8px" }}
                            >
                                <ButtonComponent
                                    type={"ghost"}
                                    text={"Add Condition"}
                                    icon={"./images/plus.svg"}
                                    BtnHandler={handleAddFlowAttribute}
                                    disable={
                                        selectedFlowAttribute &&
                                        selectedFlowAttributeValue
                                            ? false
                                            : true
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="action"
                        style={{ paddingTop: "8px", paddingRight: "12px" }}
                    >
                        {error && (
                            <small
                                className="error"
                                style={{ color: "red", paddingLeft: "4px" }}
                            >
                                {error}
                            </small>
                        )}
                        <ButtonComponent
                            type={"outlined"}
                            text={"Cancel"}
                            BtnHandler={clearData}
                        />
                        <ButtonComponent
                            type={"primary"}
                            text={"Save"}
                            BtnHandler={handleSave}
                        />
                    </div>
                </Box>
            </Modal>
            <Loader show={showLoader} />
        </div>
    );
};
