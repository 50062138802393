import React, { Component, useEffect } from "react";
import user from "../../../bae-client/User";
import Loader from "../../stylized/Loader";
import AlertDialog from "../../stylized/AlertDialog";
import ResetOptions from "./ResetOptions";
import SettingsGeneral from "./SettingsGeneral";
import TitleBar from "./TiltleBar";
import WorkspaceSettings from "./WorkspaceSettings";
import AppInfo from "./AppInfo";
import RefreshData from "./RefreshData";
import config from "../../../config";
import http from "../../../bae-client/HTTP";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as Mixpanel from "../../../mix-panel/Mixpanel";

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoader: false,
            emailConfigurationCuboidId: "",
            dashboardReportRuleConfCuboidId: "",
            value: "1",
            showAlertModal: false,
        };
        this.bwUserInfo = user.getBwUserInfo();
        this.handleTabChange = this.handleTabChange.bind(this);
    }
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderTitle = () => {
        return (
            <TitleBar
                username={this.props.username}
                title={"Settings"}
            ></TitleBar>
        );
    };
    handleTabChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    componentDidMount = () => {
        Mixpanel.callPageViewEvent("Settings");
        if (config.showLeftPaneDocumentChain) {
            this.setState({ showLoader: true });
            http.sendRestRequest("documentChain/ruleConfigurationTblIds")
                .then((response) => {
                    this.setState({
                        emailConfigurationCuboidId:
                            response["Exception Email Rule Configuration"],
                        dashboardReportRuleConfCuboidId:
                            response["Dashboard Report Rule Configuration"],
                        showLoader: false,
                    });
                })

                .catch((error) => {
                    this.showAlertView("", error.error);
                    console.log(error.message);
                    this.setState({ showLoader: false });
                });
        }
    };
    renderContent = () => {
        return (
            <>
                <Box
                    sx={{ width: "100%", typography: "body1", height: "90vh" }}
                >
                    <TabContext value={this.state.value}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                marginLeft: "24px",
                            }}
                        >
                            <TabList
                                onChange={this.handleTabChange}
                                aria-label="lab API"
                            >
                                <Tab
                                    label="General"
                                    title="General"
                                    id="tab_settings_general"
                                    data-id="tab_settings_general"
                                    value="1"
                                    sx={{
                                        width: "159px",
                                        height: "28px",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    label="Workspaces"
                                    title="Workspaces"
                                    id="tab_settings_workspaces"
                                    data-id="tab_settings_workspaces"
                                    value="3"
                                    sx={{
                                        width: "159px",
                                        height: "28px",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                {window.runtimeConfig.showRefreshDataTab && (
                                    <Tab
                                        label="Refresh Data"
                                        title="Refresh Data"
                                        id="tab_settings_refresh_data"
                                        data-id="tab_settings_refresh_data"
                                        value="4"
                                        sx={{
                                            width: "159px",
                                            height: "28px",
                                            color: "black",
                                            fontWeight: "bold",
                                            fontFamily: "Montserrat",
                                            fontSize: "18px",
                                            lineHeight: "29px",
                                            textTransform: "capitalize",
                                        }}
                                    />
                                )}
                                <Tab
                                    label="App Info"
                                    title="App Info"
                                    id="tab_settings_app_info"
                                    data-id="tab_settings_app_info"
                                    value="5"
                                    sx={{
                                        width: "159px",
                                        height: "28px",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1" style={{ height: "92%" }}>
                            <SettingsGeneral
                                bwUserInfo={this.bwUserInfo}
                                handleLogout={this.props.handleLogout}
                                setUser={this.props.setUser}
                            />
                        </TabPanel>
                        <TabPanel value="2">
                            <ResetOptions></ResetOptions>
                        </TabPanel>
                        <TabPanel value="3">
                            <WorkspaceSettings></WorkspaceSettings>
                        </TabPanel>
                        <TabPanel value="4">
                            <RefreshData></RefreshData>
                        </TabPanel>
                        <TabPanel value="5">
                            <AppInfo></AppInfo>
                        </TabPanel>
                    </TabContext>
                </Box>
            </>
        );
    };
    render() {
        return (
            <>
                <Loader show={this.state.showLoader} />
                <AlertDialog
                    open={this.state.showAlertModal}
                    setOpen={this.hideAlertView}
                    title={this.errMsgTitle}
                    message={this.errMsgBody}
                ></AlertDialog>
                <div className="w-100 pl-0 pr-0 contentPaneBottom">
                    {this.renderContent()}
                </div>
            </>
        );
    }
}

export default Settings;
