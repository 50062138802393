import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setCollabInfo } from "../../../actions";
import Document from "../../../bae-client/Document";
import DocumentUploader from "../../../bae-client/DocumentUploader";
import http from "../../../bae-client/HTTP";
import config from "../../../config";
//import Button from '../../stylized/Button'
import Checkbox from "../../stylized/Checkbox";
import Dropdown from "../../stylized/Dropdown";
import "../../../index.css";
import "../UnityCentral/index.css";
import { Button, Icon, TextField, makeStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import PopoverView from "../../stylized/PopoverView";
import ListView from "../../stylized/ListView";
import ControlTower from "../../../bae-client/ControlTower";
import AlertDialog from "../../stylized/AlertDialog";
import Loader from "../../stylized/Loader";
import { setShowDrawer } from "../../../actions/Rippleaction";
const controlTower = new ControlTower();
const useStyles = makeStyles((theme) => ({
    noBorder: {
        border: "none",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));
function ControlTowerPopUpNew({
    onHide,
    documentMetaData,
    updateParentState,
    handleInstanceData,
    updateInstanceData,
    genericInfo,
}) {
    const [showLoader,setShowLoader]=useState(false)
    const [genericListResponse, setGenericListResponse] = useState(null);
    const [parentStateData, setParentStateData] = useState({});
    const [selectedGenericBlockchain, setSelectedGenericBlockchain] = useState(
        updateInstanceData.selectedGenericBlockchain
            ? updateInstanceData.selectedGenericBlockchain
            : {
                  genericTblName: "",
                  genericTblId: "",
                  instanceRegistryTblId: "",
                  instanceDetailsTblId: "",
                  instanceBlockchainTblId: "",
              }
    );
    const [genericBlockchainDDList, setGenericBlockchainDDList] = useState(
        updateInstanceData.genericBlockchainDDList
            ? updateInstanceData.genericBlockchainDDList
            : []
    );
    const [genericElement, setgenericAnchorEl] = useState(null);

    const [genericStepResponse, setGenericStepResponse] = useState(null);
    const [selectedGenericStep, setSelectedGenericStep] = useState(
        updateInstanceData.selectedGenericStep
            ? updateInstanceData.selectedGenericStep
            : {
                  genericStepName: "",
                  genericStepId: "",
              }
    );
    const [genericStepDDList, setGenericStepDDList] = useState(
        updateInstanceData.genericStepDDList
            ? updateInstanceData.genericStepDDList
            : []
    );
    const [genericStepElement, setgenericStepAnchorEl] = useState(null);

    const [instanceResponse, setInstanceResponse] = useState(null);
    const [selectedInstance, setSelectedInstance] = useState(
        updateInstanceData.selectedInstance
            ? updateInstanceData.selectedInstance
            : {
                  instanceId: "",
                  instanceName: "",
              }
    );
    const [instanceDDList, setInstanceDDList] = useState(
        updateInstanceData.instanceDDList
            ? updateInstanceData.instanceDDList
            : []
    );
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [instanceElement, setInstanceAnchorEl] = useState(null);

    const [InstanceInformationResponse, setInstanceInformationResponse] =
        useState(null);

    const [selectedParentInstance, setSelectedParentInstance] = useState(
        updateInstanceData.selectedParentInstance
            ? updateInstanceData.selectedParentInstance
            : {
                  parentInstanceId: "",
                  parentInstanceName: "",
              }
    );
    const [parentInstanceDDList, setParentInstanceDDList] = useState(
        updateInstanceData.parentInstanceDDList
            ? updateInstanceData.parentInstanceDDList
            : []
    );
    const [parentInstanceElement, setParentInstanceAnchorEl] = useState(null);

    const [selectedFromName, setSelectedFromName] = useState(
        updateInstanceData.selectedFromName
            ? updateInstanceData.selectedFromName
            : {
                  from: "",
              }
    );
    const [fromDDList, setFromDDList] = useState(
        updateInstanceData.fromDDList ? updateInstanceData.fromDDList : []
    );
    const [fromAnchorElement, setfromAnchorEl] = useState(null);

    const [instanceCheckStatus, setInstanceCheckStatus] = useState(
        updateInstanceData.instanceCheckStatus
            ? updateInstanceData.instanceCheckStatus
            : false
    );

    const [newParentName, setNewParentName] = useState(null);
    const [newInstanceName, setNewInstanceName] = useState(null);
    const [newFrom, setNewFrom] = useState(null);
    const [showAlertModal, setshowAlertModal] = useState(false);
    const classes = useStyles();
    let errMsgTitle = "";
    let errMsgBody = "";
    const setFromText = (value, id, event) => {
        switch (id) {
            case "newparentname":
                {
                    setNewParentName(value);
                }
                break;
            case "newinstancename":
                {
                    setNewInstanceName(value);
                }
                break;
            case "newfromdoc":
                {
                    setNewFrom(value);
                }
                break;
            default:
                break;
        }
    };
    const computeGenericBlockchainNameList = () => {
        setShowLoader(true)
        controlTower
            .retrieveGenericList()
            .then((response) => {
                setShowLoader(false)
                let genericInformation = genericInfo;
                setGenericListResponse(response);
                let items = [];
                let genericName = [];
                response.forEach((instance) => {
                    if (
                        genericInformation &&
                        genericInformation.genericName &&
                        instance.genericTblName ===
                            genericInformation.genericName
                    ) {
                        setSelectedGenericBlockchain(() => ({
                            genericTblName: instance.genericTblName,
                            genericTblId: instance.genericTblId,
                            instanceRegistryTblId:
                                instance.instanceRegistryTblId,
                            instanceDetailsTblId: instance.instanceDetailsTblId,
                            instanceBlockchainTblId:
                                instance.instanceBlockchainTblId,
                        }));
                        setParentStateData((prevState) => {
                            return {
                                ...prevState,
                                informationTblId:
                                    instance.instanceInformationTblId,
                            };
                        });
                    }
                    let obj = {};
                    obj["text"] = instance.genericTblName;
                    obj["value"] = instance.genericTblName;
                    obj["id"] = instance.genericTblId;
                    genericName.push(obj);
                    items.push(instance.genericTblName);
                    setGenericBlockchainDDList(genericName);
                });
            })
            .catch((error) => {
                setShowLoader(false)
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const computeGenericStepList = (genericTblId) => {
        let promise = new Promise((resolve, reject) => {
            let genericInformation = genericInfo;
            let genericSteps = [];
            let obj = {};
            if (
                genericInformation &&
                genericInformation.genericStepName &&
                genericInformation.genericStepId
            ) {
                setSelectedGenericStep({
                    genericStepName: genericInformation.genericStepName,
                    genericStepId: genericInformation.genericStepId,
                });
                obj["text"] = genericInformation.genericStepName;
                obj["value"] = genericInformation.genericStepName;
                obj["id"] = genericInformation.genericStepId;
                genericSteps.push(obj);
                setGenericStepDDList(genericSteps);
                resolve(genericTblId);
            }
        });
        return promise;
    };
    const computeInstanceList = (genericTblId) => {
        let promise = new Promise((resolve, reject) => {
            let instanceInformation = genericInfo.instances;
            let instanceList = [];
            instanceInformation.forEach((instance, id) => {
                let obj = {};
                obj["text"] = instance.instanceName;
                obj["value"] = instance.instanceName;
                obj["id"] = instance.instanceId;
                instanceList.push(obj);
            });
            setInstanceResponse(instanceInformation);
            setInstanceDDList(instanceList);

            let fromDropDownList = [];
            genericInfo.froms.forEach((from, id) => {
                let fromObj = {};
                fromObj["text"] = from;
                fromObj["value"] = from;
                fromObj["id"] = id;
                fromDropDownList.push(fromObj);
            });
            if (genericInfo.from !== "") {
                setSelectedFromName(() => ({
                    from: genericInfo.from,
                }));
            }
            setFromDDList(fromDropDownList);
        });
        return promise;
    };
    const computeInstanceInformation = (instanceRegistryTblId, instanceId) => {
        let promise = new Promise((resolve, reject) => {
            let parentsList = [];
            genericInfo.parents.map((step, id) => {
                if (instanceId === step.instanceId) {
                    let parentObj = {};
                    parentObj["text"] = step.instanceBlockDocumentName;
                    parentObj["value"] = step.instanceBlockDocumentName;
                    parentObj["id"] = step.instanceBlockDocumentId;
                    parentsList.push(parentObj);
                }
            });
            setParentInstanceDDList(parentsList);
            setInstanceInformationResponse(genericInfo.parents);
        });
        return promise;
    };
    const setPayloadData = () => {
        // let obj = {
        // "instanceId": 8979,
        // "instanceBlockDocumentId": 2000003,
        //  'instanceBlockDocumentName':''
        // "genericStepId": 2000356,
        // "parentInstanceBlockchainId": "0",
        // "from": "from"
        // }
        let payloadObj = {};
        return new Promise((resolve, reject) => {
            if (
                selectedGenericStep &&
                selectedGenericStep.genericStepId === ""
            ) {
                reject("Choose generic step value");
            }
            if (
                !instanceCheckStatus &&
                selectedInstance &&
                selectedInstance.instanceId === ""
            ) {
                reject("Choose instance value");
            }
            if (
                !instanceCheckStatus &&
                selectedParentInstance &&
                selectedParentInstance.parentInstanceId === ""
            ) {
                reject("Choose instance value");
            }
            if (
                !instanceCheckStatus &&
                selectedFromName &&
                selectedFromName.from === ""
            ) {
                reject("Choose from value");
            }

            if (instanceCheckStatus) {
                payloadObj["genericStepId"] = selectedGenericStep.genericStepId;
                payloadObj["instanceName"] = newInstanceName;
                payloadObj["parentInstanceBlockchainId"] = "0";
                payloadObj["instanceBlockDocumentId"] =
                    documentMetaData.documentId;
                payloadObj["instanceBlockDocumentName"] =
                    documentMetaData.fileName;
                payloadObj["from"] = selectedFromName.from;
            } else {
                payloadObj["genericStepId"] = selectedGenericStep.genericStepId;
                payloadObj["instanceId"] = selectedInstance.instanceId;
                payloadObj["parentInstanceBlockchainId"] =
                    selectedParentInstance.parentInstanceId;
                payloadObj["instanceBlockDocumentId"] =
                    documentMetaData.documentId;
                payloadObj["instanceBlockDocumentName"] =
                    documentMetaData.fileName;
                payloadObj["from"] = selectedFromName.from;
            }
            resolve(payloadObj);
        });
    };
    const createNewInstance = () => {
        setPayloadData()
            .then((data) => {
                //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/object?instanceBlockchainTblId=2000380
                let url =
                    "instanceBlockchain/object?instanceBlockchainTblId=" +
                    selectedGenericBlockchain.instanceBlockchainTblId;
                let method = "POST";
                http.sendRestRequest(url, method, data)
                    .then((response) => {
                        setParentStateData((prevState) => {
                            return {
                                ...prevState,
                                instanceBlockChainId:
                                    response.instanceBlockchainId,
                                instanceId: response.instanceId,
                            };
                        });
                        if (response.instanceBlockchainId !== 0) {
                            //(response.instanceId !== 0 && response.instanceName !== null)
                            showAlertView("", "Document uploaded");
                            // if (this.props.onHide) {
                            //     this.props.onHide();
                            // }
                        } else if (response.instanceBlockchainId === 0) {
                            showAlertView(
                                "",
                                "Invalid cuboid id and/or row id"
                            );
                            return;
                        }
                    })
                    .catch((error) => {
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                        console.error(error);
                    });
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.error(error);
            });
    };
    useEffect(() => {
        if (
            selectedGenericBlockchain &&
            genericBlockchainDDList.length &&
            selectedGenericStep &&
            genericStepDDList.length
        ) {
            if (
                !instanceCheckStatus &&
                selectedInstance &&
                instanceDDList.length &&
                selectedParentInstance &&
                parentInstanceDDList.length &&
                selectedFromName &&
                fromDDList.length
            ) {
                handleInstanceData({
                    selectedGenericBlockchain,
                    genericBlockchainDDList,
                    selectedGenericStep,
                    genericStepDDList,
                    selectedInstance,
                    instanceDDList,
                    selectedParentInstance,
                    parentInstanceDDList,
                    selectedFromName,
                    fromDDList,
                    instanceCheckStatus,
                });
            } else if (instanceCheckStatus) {
                handleInstanceData({
                    selectedGenericBlockchain,
                    genericBlockchainDDList,
                    selectedGenericStep,
                    genericStepDDList,
                    instanceCheckStatus,
                    newInstanceName,
                    newParentName,
                    newFrom,
                });
            }
        }
    }, [
        selectedGenericBlockchain,
        genericBlockchainDDList,
        selectedGenericStep,
        genericStepDDList,
        selectedInstance,
        instanceDDList,
        selectedParentInstance,
        parentInstanceDDList,
        parentInstanceElement,
        selectedFromName,
        fromDDList,
        instanceCheckStatus,
        newInstanceName,
        newFrom,
    ]);
    useEffect(() => {
        computeGenericBlockchainNameList();
    }, []);
    useEffect(() => {
        if (selectedGenericBlockchain.genericTblId !== "") {
            setShowLoader(true)
            computeGenericStepList(selectedGenericBlockchain.genericTblId)
                .then(() => {
                    setShowLoader(false)
                    computeInstanceList(selectedGenericBlockchain.genericTblId);
                })
                .catch((err) => {
                    setShowLoader(false)
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
        }
    }, [selectedGenericBlockchain]);
    useEffect(() => {
        if (
            selectedGenericBlockchain &&
            selectedGenericBlockchain.instanceRegistryTblId !== "" &&
            selectedInstance &&
            selectedInstance.instanceId !== ""
        ) {
            setShowLoader(true)
            computeInstanceInformation(
                selectedGenericBlockchain.instanceRegistryTblId,
                selectedInstance.instanceId
            )
                .then((response) => {
                    setShowLoader(false)
                    // console.log(response);
                })
                .catch((err) => {
                    setShowLoader(false)
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
        }
    }, [selectedGenericBlockchain, selectedInstance]);

    // FOR FROM DROP DOWN
    const handleFromChange = (index, listValue, listText, event) => {
        // InstanceInformationResponse.forEach((instance, instanceIndex) => {
        //     if (instance.from === listValue) {
        //         //setSelectedGenericBlockchain(obj);
        //         setSelectedFromName(() => ({
        //             from: listValue,
        //         }));
        //     }
        // });
        setSelectedFromName(() => ({
            from: listValue,
        }));
    };
    const handleFromBtnClick = (event) => {
        setfromAnchorEl(event.currentTarget);
    };

    const handleFromClose = () => {
        setfromAnchorEl(null);
    };
    const fromOpen = Boolean(fromAnchorElement);
    const renderFromDD = () => {
        return (
            <div className="ctDropDownButton w-100">
                <Button
                    id={"FromDDButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleFromBtnClick}
                >
                    {selectedFromName.from !== "" ? (
                        selectedFromName.from
                    ) : (
                        <div></div>
                    )}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"fromPopup"}
                    show={fromOpen}
                    anchorEl={fromAnchorElement}
                    closePopover={handleFromClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"fromList"}
                        items={fromDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleFromChange}
                    />
                </PopoverView>
            </div>
        );
    };

    // FOR PARENT INSTANCE DROP DOWN
    const handleParentInstanceChange = (index, listValue, listText, event) => {
        InstanceInformationResponse.forEach((instance, instanceIndex) => {
            if (instance.instanceBlockDocumentName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedParentInstance(() => ({
                    parentInstanceName: instance.instanceBlockDocumentName,
                    parentInstanceId: instance.instanceBlockchainId,
                }));
            }
        });
    };
    const handleParentInstanceBtnClick = (event) => {
        setParentInstanceAnchorEl(event.currentTarget);
    };

    const handleParentInstanceClose = () => {
        setParentInstanceAnchorEl(null);
    };
    const parentInstanceOpen = Boolean(parentInstanceElement);
    const renderParentInstanceDD = () => {
        return (
            <div className="ctDropDownButton w-100">
                <Button
                    id={"ParentInstanceDDButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleParentInstanceBtnClick}
                >
                    {selectedParentInstance.parentInstanceName !== "" ? (
                        selectedParentInstance.parentInstanceName
                    ) : (
                        <div></div>
                    )}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"ParentInstancePopup"}
                    show={parentInstanceOpen}
                    anchorEl={parentInstanceElement}
                    closePopover={handleParentInstanceClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"parentInstanceName"}
                        items={parentInstanceDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleParentInstanceChange}
                    />
                </PopoverView>
            </div>
        );
    };

    // FOR INSTANCE DROP DOWN
    const handleInstanceChange = (index, listValue, listText, event) => {
        instanceResponse.forEach((instance, instanceIndex) => {
            if (instance.instanceName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedInstance(() => ({
                    instanceName: instance.instanceName,
                    instanceId: instance.instanceId,
                }));
            }
        });
    };
    const handleInstanceBtnClick = (event) => {
        setInstanceAnchorEl(event.currentTarget);
    };

    const handleInstanceClose = () => {
        setInstanceAnchorEl(null);
    };
    const instanceOpen = Boolean(instanceElement);
    const renderInstanceDD = () => {
        return (
            <div className="ctDropDownButton w-100">
                <Button
                    id={"instanceDDButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleInstanceBtnClick}
                >
                    {selectedInstance.instanceName !== "" ? (
                        selectedInstance.instanceName
                    ) : (
                        <div></div>
                    )}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"instancePopup"}
                    show={instanceOpen}
                    anchorEl={instanceElement}
                    closePopover={handleInstanceClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={instanceDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleInstanceChange}
                    />
                </PopoverView>
            </div>
        );
    };

    // FOR GENERIC STEP DROP DOWN
    const handleGenericStepChange = (index, listValue, listText, event) => {
        genericStepResponse.forEach((step, stepIndex) => {
            if (step.genericStepName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedGenericStep(() => ({
                    genericStepName: step.genericStepName,
                    genericStepId: step.genericStepId,
                }));
            }
        });
    };
    const handleGenericStepClick = (event) => {
        setgenericStepAnchorEl(event.currentTarget);
    };

    const handleGenericStepClose = () => {
        setgenericStepAnchorEl(null);
    };
    const genericStepOpen = Boolean(genericStepElement);
    const renderGenericStepDD = () => {
        return (
            <div className="ctDropDownButton w-100">
                {/* <Button
                    id={"GenricDropdownButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleGenericStepClick}
                >
                    {selectedGenericStep.genericStepName !== ""
                        ? selectedGenericStep.genericStepName
                        : null}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"genericPopup"}
                    show={genericStepOpen}
                    anchorEl={genericStepElement}
                    closePopover={handleGenericStepClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={genericStepDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleGenericStepChange}
                    />
                </PopoverView> */}
                {selectedGenericStep &&
                selectedGenericStep.genericStepName !== "" ? (
                    <InputBase
                        className={classes.margin}
                        defaultValue={selectedGenericStep.genericStepName}
                        inputProps={{ "aria-label": "naked", readOnly: true }}
                    />
                ) : null}
            </div>
        );
    };

    // FOR GENERIC BLOCKCHAIN NAME DROP DOWN
    const handleGenericBlockchainChange = (
        index,
        listValue,
        listText,
        event
    ) => {
        genericListResponse.forEach((step, stepIndex) => {
            if (step.genericTblName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedGenericBlockchain(() => ({
                    genericTblName: step.genericTblName,
                    genericTblId: step.genericTblId,
                    instanceRegistryTblId: step.instanceRegistryTblId,
                    instanceDetailsTblId: step.instanceDetailsTblId,
                    instanceBlockchainTblId: step.instanceBlockchainTblId,
                }));
            }
        });
    };
    const handleGenericClick = (event) => {
        setgenericAnchorEl(event.currentTarget);
    };

    const handleGenericClose = () => {
        setgenericAnchorEl(null);
    };
    const genericOpen = Boolean(genericElement);
    const renderGenericBlockchainDD = () => {
        return (
            <div className="ctDropDownButton w-100">
                {/* <Button
                    id={"GenricDropdownButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black", textOverflow: 'ellipsis', overflow: 'hidden', whiteSpacepace: 'nowrap', }}
                    variant="contained"
                    color="primary"
                    onClick={handleGenericClick}
                >
                    {selectedGenericBlockchain.genericTblName !== ""
                        ? selectedGenericBlockchain.genericTblName
                        : null}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"genericPopup"}
                    show={genericOpen}
                    anchorEl={genericElement}
                    closePopover={handleGenericClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={genericBlockchainDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleGenericBlockchainChange}
                    />
                </PopoverView> */}
                {selectedGenericBlockchain &&
                selectedGenericBlockchain.genericTblName !== "" ? (
                    <InputBase
                        className={classes.margin}
                        defaultValue={selectedGenericBlockchain.genericTblName}
                        inputProps={{ "aria-label": "naked", readOnly: true }}
                    />
                ) : null}
            </div>
        );
    };
    const handleSelect = (value, id, event) => {
        //this.setFromText(value, id, event)
    };
    const handleInstanceCheckUncheck = (value, id, event) => {
        switch (id) {
            case "newInstance":
                {
                    // this.checkedStatus = !this.checkedStatus;
                    // document.getElementById("newparentname").disabled = this.checkedStatus;
                    // if (this.checkedStatus) { this.newparentname = null; }
                    // this.setState({ instanceCheckedStatus: !this.checkedStatus });
                    setInstanceCheckStatus(!instanceCheckStatus);
                }
                break;
            default:
                break;
        }
    };
    const renderForm = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <div className="marginTopTen doCenter">
                    <label className="ctPopupLabel">
                        Generic Blockchain Name
                    </label>
                    {/* <input type="text" id='newgenericblockchainname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.newgenericblockchainname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input> */}
                    {/* <Dropdown dropdownID={"newgenericblockchainname"} dropdownName={"newgenericblockchainname"} items={[]}
                        handleChanges={handleSelect}></Dropdown> */}
                    {renderGenericBlockchainDD()}
                </div>
                <div className="marginTopTen doCenter">
                    <label className="ctPopupLabel">Generic Step Name</label>
                    {/* <input type="text" id='newgenericsteptname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.newgenericsteptname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input> */}
                    {/* <Dropdown dropdownID={"newgenericsteptname"} dropdownName={"newgenericsteptname"} items={this.state.genericStepList || []}
                        handleChanges={this.handleSelect}></Dropdown> */}
                    {renderGenericStepDD()}
                </div>
                <div
                    className="marginTopTen doCenter"
                    style={{ display: "flex", flexDirection: "row" }}
                >
                    <Checkbox
                        id="newInstance"
                        onclick={handleInstanceCheckUncheck}
                        checked={instanceCheckStatus}
                    ></Checkbox>
                    <label
                        className="ctPopupLabel"
                        style={{
                            marginTop: "0.5rem",
                            width: "130px",
                            marginRight: "50px",
                        }}
                    >
                        New Instance
                    </label>
                </div>
                <div className="marginTopTen doCenter">
                    <label className="ctPopupLabel">Instance Name</label>
                    {instanceCheckStatus ? (
                        <input
                            type="text"
                            id={"newinstancename"}
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            onChange={(event) =>
                                setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                            style={{ margin: "0px 0px 0px 10px" }}
                        ></input>
                    ) : (
                        renderInstanceDD()
                    )}
                </div>
                <div className="marginTopTen doCenter">
                    <label className="ctPopupLabel">Parent Name</label>
                    {instanceCheckStatus ? (
                        <input
                            type="text"
                            id={"newparentname"}
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            onChange={(event) =>
                                setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                            style={{
                                background: "rgb(224 224 224)",
                                margin: "0px 0px 0px 10px",
                            }}
                            disabled={instanceCheckStatus}
                        ></input>
                    ) : (
                        renderParentInstanceDD()
                    )}
                </div>
                <div className="marginTopTen doCenter">
                    <label className="ctPopupLabel">From</label>
                    {renderFromDD()}
                    {/* {instanceCheckStatus ? (
                        <input
                            type="text"
                            id={"newfromdoc"}
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            onChange={(event) =>
                                setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    ) : (
                        renderFromDD()
                    )} */}
                </div>
                {/* <div className="marginTopTen doCenter">
                    <Button
                        id="submitButton"
                        label={"SUBMIT"}
                        onClick={createNewInstance}
                        className="ctButton"
                    >
                        {"SUBMIT"}
                    </Button>
                    <Button
                        id="closeButton"
                        label={"CLOSE"}
                        onClick={() => onHide()}
                        className="ctButton"
                    >
                        {"CLOSE"}
                    </Button>
                </div> */}
            </div>
        );
    };
    const hideAlertView = () => {
        setshowAlertModal(false);
    };
    const showAlertView = (title, body) => {
        errMsgTitle = title;
        errMsgBody = body;
        // setState({ showAlertModal: true });
        setshowAlertModal(true);
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={showAlertModal}
                setOpen={hideAlertView}
                title={errMsgTitle}
                message={errMsgBody}
            ></AlertDialog>
        );
    };
    return (
        <>
            {renderForm()}
            {renderAlertModal()}
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
}
const mapDispatchToProps = () => {
    return {
        setCollabInfo,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        genericInfo: state.genericInfo,
        documentMetaData: state.documentMetaData,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(ControlTowerPopUpNew);
