import React from "react";
import ModalView from "../../../../../../stylized/ModalView";
import TextBox from "../../../../../../stylized/TextBox";

export default function EditDocumentChainModal({
    showEditDocumentChainModal,
    documentChainName,
    closeModal,
    editDocumentChainHandler,
}) {
    const [name, setName] = React.useState(documentChainName);
    const [errorMessage, setErrorMessage] = React.useState("");
    const handleSubmit = () => {
        if (!name) setErrorMessage("Name cannot be empty");
        else {
            editDocumentChainHandler(name);
            setName("");
        }
    };
    const onChange = (value) => {
        if (errorMessage) setErrorMessage("");
        setName(value);
    };
    const closeTheModal = () => {
        setName("");
        closeModal();
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showEditDocumentChainModal}
            size="md"
            onHide={closeTheModal}
            title="Okay"
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            value="Name"
            onclick={closeTheModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                {/* <img src="./images/Space 2.svg"></img> */}
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-32px",
                        marginBottom: "32px",
                    }}
                >
                    Rename Flow
                </div>
                <TextBox
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    style={{ marginTop: "-20px" }}
                    onchange={onChange}
                    label={documentChainName}
                    value={name}
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {errorMessage && (
                <>
                    <small style={{ color: "red", paddingLeft: "18px" }}>
                        {errorMessage}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
