import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { validateName } from "../../../../../../bae-client/Validations";
import AlertDialog from "../../../../../stylized/AlertDialog";
import ModalView from "../../../../../stylized/ModalView";
import TextArea from "../../../../../stylized/TextArea";
import TextBox from "../../../../../stylized/TextBox";
import "./style.css";
export default function EditAlertModalView({
    // setText,
    onIconClick,
    onSubmit,
    onHide,
    showModal,
    alertBodyRawData,
}) {
    const [alertTitle, setalertTitle] = useState(
        alertBodyRawData?.alertTitle || ""
    );
    const [alertBody, setalertBody] = useState(
        alertBodyRawData?.alertBody || ""
    );
    const [alertType, setalertType] = useState(
        alertBodyRawData?.alertType || ""
    );
    const [showAlert, setshowAlert] = useState(false);
    const [error, setError] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");
    let errorTitle = "";
    let errorMessage = "";
    const [disable, setDisable] = React.useState(true);

    useEffect(() => {
        if (alertBodyRawData !== undefined && alertBodyRawData !== null) {
            setalertTitle(alertBodyRawData.alertTitle);
            setalertBody(alertBodyRawData.alertBody);
            setalertType(alertBodyRawData.alertType);
        }
    }, [alertBodyRawData]);

    const handleSelect = (e) => {
        setalertType(e);
        if (e.trim() === "Reminder") {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };
    const handleClickIcon = (index) => {
        setalertType(index);
        onIconClick(index);
    };
    const handlealertBody = (bodyText, id) => {
        const textLimit = 2000;
        if (bodyText.trim() === "") {
            setalertBody(bodyText);
            setErrorMsg("Message size should not be empty");
        } else if (bodyText.length <= textLimit) {
            setalertBody(bodyText);
            setErrorMsg("");
        } else {
            setErrorMsg("Max limit of body is 2000");
        }
    };
    const handleSubmit = () => {
        if (!alertBody) {
            setError("Please enter body message for alert");
            return;
        } else if (!alertTitle) {
            setError("Please enter title for alert");
            return;
        } else setError("");
        const data = {
            alertTitle,
            alertBody,
            alertType,
        };
        onSubmit(data);
    };
    const handleModalClose = () => {
        setalertTitle(alertBodyRawData.alertTitle);
        setalertBody(alertBodyRawData.alertBody);
        setalertType(alertBodyRawData.alertType);
        setErrMsg("");
        setErrorMsg("");
        setError("");
        onHide();
    };
    const setText = (value, id, event) => {
        switch (id) {
            case "addNewWorkspaceAlert":
                {
                    let isValidName = validateName(value);
                    if (isValidName !== true) {
                        setErrMsg(isValidName);
                        setalertTitle(value);
                    } else {
                        setalertTitle(value);
                        setErrMsg("");
                    }
                }
                break;
            case "addNewWorkspaceAlertBody":
                {
                    handlealertBody(value);
                }
                break;
            default:
                break;
        }
    };

    const hideAlertView = () => {
        setshowAlert(false);
    };
    const showAlertView = () => {
        setshowAlert(true);
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={showAlert}
                setOpen={hideAlertView}
                title={errorTitle}
                message={errorMessage}
            ></AlertDialog>
        );
    };
    return (
        <>
            <ModalView
                disable={
                    errMsg !== "" ||
                    alertTitle == "" ||
                    alertType == "" ||
                    alertBody == ""
                        ? ""
                        : false
                } // && "enable"
                type="submit-cancel"
                id="addNewHomeAlertModalBtn"
                className="btn btn-primary bw_btn"
                value="Submit"
                title="Submit"
                onSubmit={handleSubmit}
                show={showModal}
                onHide={handleModalClose}
                footer={"false"}
                size="md"
                showHeader={true}
                headerTitle="Edit Notification"
            >
                <div
                    className="row doCenter"
                    style={{
                        flexDirection: "column",
                    }}
                >
                    <div className=" w-100 mb-4" style={{ marginLeft: "51px" }}>
                        <TextBox
                            style={{ marginLeft: "28px" }}
                            id="addNewWorkspaceAlert"
                            className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft cursorPointer "
                            divClass="w-100"
                            title="Notification Name"
                            type="text"
                            onchange={(value, id) => setText(value, id)}
                            label={alertTitle}
                            value={alertTitle}
                            formControl="Off"
                            doCenter={false}
                        ></TextBox>
                        {errMsg && (
                            // <div className="w-100 m-0">
                            <>
                                <small
                                    style={{
                                        color: "red",
                                        // paddingLeft: "40px",

                                        paddingTop: "10px",
                                    }}
                                >
                                    {errMsg}
                                </small>
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                        }}
                        title="Select Notification Type"
                    >
                        <DropdownButton
                            className="loginPageTextBox loginPageBorder paddingLeft"
                            title={alertType}
                            id="dropdown_selectedValue_alert"
                            onSelect={handleSelect}
                            style={{ marginLeft: "-58px" }}
                        >
                            <Dropdown.Item
                                eventKey="Reminder"
                                id="editNotification_reminder"
                            >
                                Reminder
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Integration" disabled>
                                Integration (Coming Soon)
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Ripple" disabled>
                                Ripple (Coming Soon)
                            </Dropdown.Item>
                        </DropdownButton>

                        <div>
                            <div
                                id="editAlertModal"
                                className="input-group doCenter w-100 mb-2"
                                style={{
                                    marginRight: "35px",
                                    marginLeft: "-58px",
                                }}
                            >
                                <TextArea
                                    id="addNewWorkspaceAlertBody"
                                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft invisibleScroller"
                                    name="homeAlertBodyData"
                                    value={alertBody}
                                    onchange={(value, id) =>
                                        handlealertBody(value, id)
                                    }
                                    rows={3}
                                    title="Description"
                                    cols={10}
                                    label={alertBody}
                                ></TextArea>
                            </div>
                        </div>
                    </div>
                    {errorMsg && (
                        <div className={"row m-0 w-100"}>
                            <span
                                style={{
                                    color: "red",
                                    // paddingLeft: "27px",
                                    marginLeft: "2rem",
                                    paddingTop: "10px",
                                }}
                                className="err_msg"
                                id="err_inp_signUp_department_dropDown"
                            >
                                {errorMsg}
                            </span>
                        </div>
                    )}
                </div>
            </ModalView>
            {/* {renderAlertModal()} */}
        </>
    );
}
