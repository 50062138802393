import CuboidManager from "./CuboidManager";
import Space from "./Space";
import SpaceFileSystem from "./FileManager";
import _ from "lodash";
import http from "./HTTP";
const spacePersistanceColumn = {
    bwRowId: "BWID",
    spaceId: "Space Id",
    parentRowId: "Parent Row Id",
    objectId: "Object Id",
    objectName: "Object Name",
};
class SpaceManager {
    constructor(option) {
        if (option !== undefined) {
            this.spaceCollabId = option.spaceCollabId;
            this.spaceWBId = option.spaceWBId;
            this.spacePersistenceId = option.spacePersistenceId;
            this.spaceRegistryId = option.spaceRegistryId;
        }
        // this.cuboidManager = new CuboidManager(
        //     this.spaceCollabId,
        //     this.spaceWBId
        // );
        this.fileSystem = new Map();
        this.foldersBySpaceId = new Map();
        this.spaceResponse = null;
        this.spaceIdToIndex = {};
        this.spaceNameToIndex = {};
        this.spaceIdToSpaceName = {};
        this.archivedSpaceId = [];
        this.archivedSpaceData = [];
        this.ids = {};
        this.persistanceDataStructure = {};
    }
    getFolderStructureByAPI = (spaceId) => {
        let url = `spacePersistence/objectList?spaceId=${spaceId}`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };
    insertNewObject = (data) => {
        return new Promise((resolve, reject) => {
            let url = "spacePersistence/object";
            http.sendRestRequest(url, "POST", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    generateSpaceRegistryRow(data) {
        let rows = {
            rows: [[data.spaceId, data.spaceName, "", "", "", "", ""]],
        };
        return rows;
    }
    generateSpaceRegistryRowNew(data) {
        let rows = {
            rows: [[data.spaceName]],
        };
        return rows;
    }
    setIDs = () => {
        this.ids = {
            spaceCollabId: this.spaceCollabId,
            spaceWBId: this.spaceWBId,
            spacePersistenceId: this.spacePersistenceId,
            spaceRegistryId: this.spaceRegistryId,
        };
    };
    getIDs = () => {
        return this.ids;
    };
    generateSpacePersistanceRows(data, parentId) {
        let documentsArray = data.documentObjects;
        let parentRowId = parentId !== undefined ? parentId : "0";
        let rowsArray = [];
        for (let index = 0; index < documentsArray.length; index++) {
            rowsArray.push([
                data.spaceId,
                parentRowId,
                documentsArray[index].data.documentId,
                documentsArray[index].data.fileName,
            ]);
        }
        let rows = {
            rows: rowsArray,
            count: rowsArray.length,
        };
        return rows;
    }
    generateSpacePersistanceFolderRow(data) {
        let rows = {
            rows: [data],
        };
        return rows;
    }
    createDataStructure = () => {
        let response = this.getLoadSpaceResponse();
        let index = null;

        response.forEach((key, index) => {
            if (key.spaceName !== null && key.spaceName !== undefined) {
                this.spaceNameToIndex[key.spaceName] = {
                    index: index,
                };
            }
            if (key.spaceId !== null && key.spaceId !== undefined) {
                this.spaceIdToIndex[key.spaceId] = {
                    index: index,
                };
            }
            this.spaceIdToSpaceName[key.spaceId] = key.spaceName;
        });
    };
    getArchivedSpaceData = () => {
        return this.archivedSpaceData;
    };
    getSpaceIdToIndex = () => {
        return this.spaceIdToIndex;
    };
    getSpaceNameToIndex = () => {
        return this.spaceNameToIndex;
    };
    getSpaceIDFromIDtoName = (spaceName) => {
        let newSpaceId = null;

        Object.keys(this.spaceIdToSpaceName).forEach((spaceid, i) => {
            if (this.spaceIdToSpaceName[spaceid] === spaceName) {
                newSpaceId = spaceid;
            }
        });
        return newSpaceId;
    };
    getSpaceID = (spaceName) => {
        let spaceID = null;
        let index = null;
        Object.keys(this.spaceNameToIndex).map((key, i) => {
            if (key === spaceName) {
                index = i;
            }
        });
        Object.keys(this.spaceIdToIndex).map((key, i) => {
            if (i === index) {
                spaceID = key;
            }
        });
        return spaceID;
    };
    getMaximumSpaceID = () => {
        let maximumSpaceId = 0;
        if (Object.keys(this.spaceIdToIndex).length > 0) {
            Object.keys(this.spaceIdToIndex).forEach((key) => {
                if (parseInt(key) > maximumSpaceId) {
                    maximumSpaceId = parseInt(key);
                }
            });
        } else {
            maximumSpaceId = 20000000;
        }
        return maximumSpaceId;
    };

    checkExistingSpaceName = (spaceName) => {
        let flag = false;
        let checkString = spaceName.trim();
        if (checkString in this.spaceNameToIndex) {
            flag = true;
        }
        return flag;
    };
    setLoadSpaceResponse(response) {
        // take 2 cuboid data after loadSpace() call
        this.spaceResponse = response;
        //---------------------------------this.spacePersistenceCuboid = response[0];
    }
    setEditedSpaceRegistryResponse = (response) => {
        // take single cuboid data spaceRegistry
        this.spaceResponse[1] = response;
        // this.spacePersistenceCuboid = response;
    };
    setEditedSpacePersistanceResponse = (response) => {
        this.spaceResponse[0] = response;
        this.spacePersistenceCuboid = response;
    };
    getLoadSpaceResponse() {
        return this.spaceResponse;
    }

    getFolderStructureByParentId = (spaceRawDataArray, parent, tree) => {
        tree = typeof tree !== "undefined" ? tree : [];
        parent = typeof parent !== "undefined" ? parent : { id: 0 };
        var children = spaceRawDataArray.filter((child) => {
            return child.parentid == parent.id;
        });
        if (children.length > 0) {
            if (parent.id == 0) {
                // tree = children;
                let obj = {};
                children.forEach((child, index) => {
                    obj[children[index].id] = children[index];
                    tree = obj;
                });
            } else {
                let obj = {};
                children.forEach((child, index) => {
                    obj[children[index].id] = children[index];
                    parent["children"] = obj;
                });
                // parent['children'] = children;
            }
            children.forEach((child) => {
                this.getFolderStructureByParentId(spaceRawDataArray, child);
            });
        }
        return tree;
    };
    getPersistanceDataStructure = () => {
        return this.persistanceDataStructure;
    };
    getPersistanceDataStructureBySpaceId = (spaceId, currentFolderPath) => {
        return new Promise((resolve, reject) => {
            this.persistanceDataStructure = {};
            this.getFolderStructureByAPI(this.spacePersistenceId, spaceId)
                .then((data) => {
                    this.persistanceDataStructure[spaceId] = data;
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    parseDataForFileManager = (workspaceResponse) => {
        let spaceItemRecords = workspaceResponse;
        let folderList = [];

        let workspaces = workspaceResponse;
        if (workspaces !== null && workspaces !== undefined) {
            workspaces.forEach((workspaceItem) => {
                workspaceItem.spaceList.forEach((spaceItem) => {
                    let spaceData = spaceItem;
                    this.foldersBySpaceId.set(
                        spaceData.spaceId,
                        spaceData.folders
                    );
                });
            });
        }
        // spaceItemRecords.forEach((row, rowIndex) => {
        //     let spaceData = row;
        //     folderList.push();
        //     this.foldersBySpaceId.set(parseInt(spaceData.spaceId), spaceData.folders);
        // });
    };
    getAllSpaces() {
        let response = this.getLoadSpaceResponse();
        let spaceIDs = [];
        let spaceNames = [];
        let spaceList = [];

        let index = null;
        for (let i = 0; i < response.length; i++) {
            if (response[i].gridInfo.gridName === "Space Registry") {
                index = i;
                break;
            }
        }
        if (index !== null) {
            let columns = response[index].cells.mapCol;
            let spaceStatusData = [];
            let spaceNames = [];
            if (
                columns.size !== 0 &&
                columns !== undefined &&
                columns !== null
            ) {
                columns.forEach((key, index) => {
                    let columnData = key;
                    if (index === 1) {
                        columnData.forEach((key, index) => {
                            spaceIDs.push(key.rowId);
                            spaceNames.push(key.stringValue);
                        });
                    }
                });
                spaceNames.forEach((key, i) => {
                    let spaceInstance = new Space();
                    let boardType = spaceInstance.getBoardType();
                    let options = {};
                    options["spaceName"] = spaceNames[i];
                    options["spaceId"] = spaceIDs[i];
                    options["documentObjects"] = this.getDocumentListForSpaceID(
                        spaceIDs[i]
                    );
                    options["boardType"] = boardType;
                    spaceInstance.setData(options);

                    spaceList.push(spaceInstance);
                });
            }
        }
        return spaceList;
    }
    getAllSpacesByWorkspace(activeWorkspace) {
        let spaceList = activeWorkspace.spaceList;
        let boardlist = [];
        if (spaceList) {
            spaceList.map((item, i) => {
                let isArchived = this.archivedSpaceId.find(
                    (e) => e == item.spaceId
                );
                if (isArchived === undefined) {
                    let spaceInstance = new Space();
                    //let boardType = spaceInstance.getBoardType();
                    let options = {};
                    options["spaceName"] = item.spaceName; //spaceNames[i]
                    options["spaceId"] = item.spaceId; //spaceIDs[i]
                    options["documentObjects"] = this.getDocumentListForSpaceID(
                        item.spaceId
                    ); //(spaceIDs[i]);
                    //options['boardType'] = boardType;
                    spaceInstance.setData(options);
                    boardlist.push(spaceInstance);
                }
            });
        }
        return boardlist;
    }
    getDocumentListForSpaceID(spaceID) {
        let tree = {};
        let response = this.getLoadSpaceResponse();
        let spaceIDIndex = [];
        let spaceCuboidIDs = [];

        let index = null;
        for (let i = 0; i < response.length; i++) {
            if (response[i].gridInfo.gridName === "Space Persistence") {
                index = i;
                break;
            }
        }
        let columns = response[index].cells.mapCol;
        if (columns !== undefined && columns !== null && columns.size !== 0) {
            columns.forEach((key, index) => {
                if (index === 1) {
                    let spaceIDColumn = key;
                    spaceIDColumn.forEach((key, index) => {
                        if (key.stringValue == "" + spaceID) {
                            spaceIDIndex.push(index);
                        }
                    });
                }
                if (index === 3) {
                    if (spaceIDIndex.length !== 0) {
                        let cuboidIDColumn = key;
                        cuboidIDColumn.forEach((key, index) => {
                            if (
                                key.stringValue !== null &&
                                key.stringValue !== undefined &&
                                key.stringValue !== "0"
                            ) {
                                if (/^[0-9]+$/g.test(key.stringValue)) {
                                    if (spaceIDIndex.includes(index)) {
                                        spaceCuboidIDs.push(key.stringValue);
                                    }
                                }
                            }
                        });
                    } else {
                        tree["No Documents"] = {
                            value: "Neighborhood",
                        };
                        return tree;
                    }
                }
            });
        }
        spaceCuboidIDs.forEach((key, index) => {
            tree[index] = key;
        });
        return tree;
    }
    getFolderListBySpaceId = (spaceId) => {
        return this.foldersBySpaceId.get(spaceId);
    };
    loadSpaceList(workspaceId) {
        let spaceObjects = [
            {
                spaceId: 16044,
                spaceName: "UC Folder Structure 1",
                archive: false,
            },
            {
                spaceId: 16045,
                spaceName: "UC Folder Structure 2",
                archive: false,
            },
            {
                spaceId: 17840,
                spaceName: "Test21",
                archive: false,
            },
            {
                spaceId: 17849,
                spaceName: "Test4",
                archive: false,
            },
        ];
        return new Promise((resolve, reject) => {
            let url = `workspace/spaceList?workspaceId=${workspaceId}`;
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }
    addNewSpace = (spaceRegistryTblId, workspaceId, data) => {
        let url = `space/space?workspaceId=${workspaceId}`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    updateSpaceNameWithAPI = (spaceRegistryTblId, workspaceId, data) => {
        let url = `space/space?workspaceId=${workspaceId}`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "PUT", data)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default SpaceManager;
