import React from "react";
import "./style.css";

const NumberInfo = ({ type, value }) => {
    return (
        <div className="number-info">
            <div className="number-info-icon">
                <img src={`./images/${type}.svg`} alt="members" />
            </div>
            <div className="number-info-text">
                <div className="text">{type}</div>
                <div className="number">{value}</div>
            </div>
        </div>
    );
};

export default NumberInfo;
