export const newMessage = (message) => {
    return {
        type: "UPDATE_NOTIFICATION",
        payload: message,
    };
};

export const callNotification = (roomId) => {
    return {
        type: "CALL_NOTIFICATION",
        payload: roomId,
    }
}
