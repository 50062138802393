import React from "react";
import http from "../../../bae-client/HTTP";
import CustomSnackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import "./style.css";
import Loader from "../../stylized/Loader";

const RefreshData = () => {
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const refreshException = () => {
        setShowLoader(true);
        http.sendRestRequest(`documentChain/exception/refreshExceptions`)
            .then((response) => {
                setShowLoader(false);
                setSnackbarMessage("Refreshed Exceptions Successfully!");
                setShowSnackbar(true);
            })
            .catch((error) => {
                setShowLoader(false);
                console.log(error);
            });
    };
    const refreshEmail = () => {
        setShowLoader(true);
        http.sendRestRequest(`emails/refresh`)
            .then((response) => {
                setSnackbarMessage("Refreshed successfully!");
                setShowSnackbar(true);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setShowLoader(false);
            });
    };

    return (
        <>
            <div>
                <button className="ref_btn" onClick={refreshEmail}>
                    Refresh Emails
                </button>
                <button className="ref_btn" onClick={refreshException}>
                    Refresh Exceptions
                </button>
                <CustomSnackbar
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    alertMessage={snackbarMessage}
                ></CustomSnackbar>
                <Loader show={showLoader} />
            </div>
        </>
    );
};

export default RefreshData;
