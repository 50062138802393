export const setCollabInfo = (collabInfo) => {
    return {
        type: "SET_COLLAB_INFO",
        payload: collabInfo,
    };
};

export const setFilesforUpload = (files) => {
    return {
        type: "SET_FILES_FOR_UPLOAD_IN_CANVAS",
        payload: files,
    };
};
export const setDocumentsforUpload = (files) => {
    return {
        type: "SET_FILES_FOR_UPLOAD_IN_SPACE",
        payload: files,
    };
};
export const setCollabsView = (collabsView) => {
    return {
        type: "SET_COLLABS_VIEW",
        payload: collabsView,
    };
};

export const selectCollab = (collabIndex, collabId, collabName) => {
    return {
        type: "SELECT_COLLAB",
        payload: {
            collabIndex,
            collabId,
            collabName,
        },
    };
};

export const selectWB = (WBIndex, WBId, WBName) => {
    return {
        type: "SELECT_WB",
        payload: {
            WBIndex,
            WBId,
            WBName,
        },
    };
};

export const selectCuboid = (cuboidIndex, cuboidId, cuboidName) => {
    return {
        type: "SELECT_CUBOID",
        payload: {
            cuboidIndex,
            cuboidId,
            cuboidName,
        },
    };
};
export const setPage = (page) => {
    return {
        type: "SET_PAGE",
        payload: page,
    };
};

export const setPageType = (page) => {
    return {
        type: "SET_PAGE_TYPE",
        payload: page,
    };
};
export const setLeftPanelView = (flag) => {
    return {
        type: "SET_LEFT_PANEL",
        payload: flag,
    };
};
export const setRightPanelView = (flag) => {
    return {
        type: "SET_RIGHT_PANEL",
        payload: flag,
    };
};
export const setSpaceManager = (files) => {
    return {
        type: "SET_SPACE",
        payload: files,
    };
};

export const setJiraManager = (data) => {
    return {
        type: "SET_JIRA",
        payload: data,
    };
};
export const setGmailManager = (data) => {
    return {
        type: "SET_GMAIL",
        payload: data,
    };
};
export const setAlertManager = (data) => {
    return {
        type: "SET_ALERT",
        payload: data,
    };
};
export const setBoardList = (boardList) => {
    return {
        type: "SET_BOARD_LIST",
        payload: boardList,
    };
};

export const setActiveSpace = (activeSpace) => {
    return {
        type: "SET_ACTIVE_SPACE",
        payload: activeSpace,
    };
};

export const setAlertsWidget = (alertWidget) => {
    return {
        type: "SET_ALERT_WIDGET",
        payload: alertWidget,
    };
};

export const setCurrentSettingsPage = (page) => {
    return {
        type: "SET_CURRENT_SETTINGS_PAGE",
        payload: page,
    };
};

export const setSplitView = (SplitView) => {
    return {
        type: "SET_SPLIT_VIEW",
        payload: SplitView,
    };
};
export const setActivePage = (page) => {
    return {
        type: "SET_ACTIVE_PAGE",
        payload: page,
    };
};
export const setLoginContent = (content) => {
    return {
        type: "SET_LOGIN_CONTENT",
        payload: content,
    };
};
export const setAlertData = (alertData) => {
    return {
        type: "SET_ALERT_DATA",
        payload: alertData,
    };
};
export const setSelectedAlertIndex = (alertIndex) => {
    return {
        type: "SET_SELECTED_ALERT_INDEX",
        payload: alertIndex,
    };
};
export const setCurrentClickedSpaceIndex = (spaceIndex) => {
    return {
        type: "SET_CURRENT_CLICKED_SPACE_INDEX",
        payload: spaceIndex,
    };
};
export const setWorkspaceManager = (workspaceManager) => {
    return {
        type: "SET_WORKSPACE_MANAGER",
        payload: workspaceManager,
    };
};
export const setActiveWorkspace = (activeWorkspace) => {
    return {
        type: "SET_ACTIVE_WORKSPACE",
        payload: activeWorkspace,
    };
};

export const setActiveFolderData = (activeFolder) => {
    return {
        type: "SET_ACTIVE_FOLDER",
        payload: activeFolder,
    };
};

export const setActiveRipple = (activeRipple) => {
    return {
        type: "SET_ACTIVE_RIPPLE",
        payload: activeRipple,
    };
};
export const setChatRippleMsg = (errRipple) => {
    return {
        type: "SET_CHAT_RIPPLE_MSG",
        payload: errRipple,
    };
};
export const setWorkspaceTreeStructure = (wsStructure) => {
    return {
        type: "SET_WS_TREE_SSTRUCTURE",
        payload: wsStructure,
    };
};
export const setWorkspaceFetcher = (wsFetcher) => {
    return {
        type: "SET_WS_FETCHER",
        payload: wsFetcher,
    };
};
export const setArchiveInfo = (archiveInfo) => {
    return {
        type: "SET_ARCHIVE_INFO",
        payload: archiveInfo,
    };
};

export const updateArchiveInfoAlert = (archiveInfo) => {
    return {
        type: "UPDATE_ARCHIVE_INFO_ALERT",
        payload: archiveInfo,
    };
};
export const setActiveFolderPath = (folderPath) => {
    return {
        type: "ACTIVE_FOLDER_PATH",
        payload: folderPath,
    };
};
export const setActiveBreadcrumPath = (folderPath) => {
    return {
        type: "ACTIVE_BREADCRUM_PATH",
        payload: folderPath,
    };
};
export const setInformationFeedData = (informationFeedData) => {
    return {
        type: "SET_INFORMATION_FEED_DATA",
        payload: informationFeedData,
    };
};
export const setControlTowerData = (controlTowerData) => {
    return {
        type: "SET_CONTROL_TOWER_DATA",
        payload: controlTowerData,
    };
};
export const setDocumentHierarchyInfo = (documentHierarchy) => {
    return {
        type: "SET_DOCUMENT_HIERARCHY_INFO",
        payload: documentHierarchy,
    };
};
export const setGenericInfo = (genericInfo) => {
    return {
        type: "SET_GENERIC_INFO",
        payload: genericInfo,
    };
};
export const setDocumentMetaData = (documentMetaData) => {
    return {
        type: "SET_DOCUMENT_META_DATA",
        payload: documentMetaData,
    };
};

export const setBlockchainResponse = (blockchainResponse) => {
    return {
        type: "SET_BLOCKCHAIN_RESPONSE",
        payload: blockchainResponse,
    };
};

export const setBwModulesOfLeftPane = (bwModulesResponse) => {
    return {
        type: "SET_BW_MODULES_OF_LEFTPANE",
        payload: bwModulesResponse,
    };
};

export const resetStore = () => {
    return {
        type: "RESET_STORE",
    };
};

export const setWorkspaceInforRedirection = (payload) => {
    return {
        type: "WORKSPACE_INFO_REDIRECTION",
        payload: payload,
    };
};
export const setHomeRedirection = (payload) => {
    return {
        type: "HOME_REDIRECTION",
        payload: payload,
    };
};
export const setAddWorkspaceShowModal = (payload) => {
    return {
        type: "ADD_WORKSPACE_SHOW_MODAL",
        payload: payload,
    };
};
