import React, { useEffect } from "react";
import "./style.css";
import {
    Avatar,
    Divider,
    Menu,
    MenuItem,
    Popover,
    Typography,
} from "@mui/material";
import {
    useUserQuery,
    useUsersQuery,
} from "../../../../../services/services/usersAPI";
import { BrowserRouter, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActivePageAction } from "../../../../../services/services/app";
import InviteModal from "../../../common/Invite/InviteModal";

const UserSection = ({ handleLogout, setUser, hideWSList }) => {
    const dispatch = useDispatch();
    const currentUserId = parseInt(sessionStorage.getItem("userId"));
    const [profile, setProfile] = React.useState(null);
    const [showInviteModal, setShowInviteModal] = React.useState(false);
    useUsersQuery();
    const { data } = useUserQuery(currentUserId);
    const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
    const openHelp = Boolean(helpAnchorEl);
    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const openProfile = Boolean(profileAnchorEl);
    const handleHelpClick = (event) => {
        hideWSList(false);
        setHelpAnchorEl(event.currentTarget);
    };
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";
    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };
    const handleProfileClick = (event) => {
        hideWSList(false);
        setProfileAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    const handleInviteClick = () => {
        hideWSList(false);
        setShowInviteModal(true);
    };

    useEffect(() => {
        if (data) {
            const { userEmail, firstName, lastName } = data;
            const logo =
                firstName && lastName
                    ? `${firstName[0]}${lastName[0]}`
                    : userEmail.slice(0, 2);
            setProfile({
                logo: logo.toUpperCase(),
                firstName,
                lastName,
                userEmail,
            });
        }
    }, [data]);
    return (
        <div className="user-section">
            <div className="user-row" onClick={handleHelpClick} title={"help"}>
                <div className="user-icon">
                    <img src="./images/fileTypeIcon/info.svg" alt="help" />
                </div>
                <div className="user-text">help</div>
            </div>
            <Menu
                classes={{ list: "menu-custom" }}
                id="basic-menu"
                anchorEl={helpAnchorEl}
                open={openHelp}
                onClose={handleHelpClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                className="menu-surface"
            >
                <MenuItem
                    onClick={handleHelpClose}
                    className="menu-item-custom"
                >
                    <a
                        href="mailto:support@boardwalktech.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        Submit Feedback
                    </a>
                </MenuItem>
                <MenuItem
                    onClick={handleHelpClose}
                    className="menu-item-custom"
                >
                    <a
                        href="https://ucinfo.boardwalktech.com/knowledge-center"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        Knowledge Center
                    </a>
                </MenuItem>
                <MenuItem
                    onClick={handleHelpClose}
                    className="menu-item-custom"
                >
                    <a
                        href="mailto:support@boardwalktech.com"
                        target="_blank"
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                        rel="noopener noreferrer"
                    >
                        Contact Support
                    </a>
                </MenuItem>
            </Menu>
            <div
                className="user-row"
                onClick={handleInviteClick}
                title={"Invite"}
            >
                <div className="user-icon">
                    <img src="./images/fileTypeIcon/userAdd.svg" alt="invite" />
                </div>
                <div className="user-text">Invite</div>
            </div>
            <div className="profile" onClick={handleProfileClick}>
                <Avatar
                    style={{
                        background: "#1b75bc",
                        fontSize: "1rem",
                        cursor: "pointer",
                    }}
                    title={profile?.userEmail}
                >
                    {profile?.firstName.slice(0, 1).toUpperCase() +
                        profile?.lastName.slice(0, 1).toUpperCase() ||
                        profile?.userEmail.slice(0, 2).toUpperCase()}
                </Avatar>
            </div>
            <Menu
                id="basic-menu"
                classes={{ list: "menu-custom" }}
                anchorEl={profileAnchorEl}
                open={openProfile}
                onClose={handleProfileClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
            >
                <MenuItem
                    onClick={handleProfileClose}
                    className="menu-item-custom"
                >
                    <div>
                        <div
                            style={{
                                fontSize: "14px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                fontFamily: "Montserrat",
                            }}
                        >
                            {profile?.firstName} {profile?.lastName}
                        </div>
                        <div
                            style={{
                                fontSize: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                fontFamily: "Montserrat",
                            }}
                        >
                            {profile?.userEmail}
                        </div>
                    </div>
                </MenuItem>
                <Divider style={{ margin: "none" }}></Divider>
                <MenuItem
                    onClick={handleProfileClose}
                    className="menu-item-custom"
                >
                    <div
                        onClick={() =>
                            dispatch(setActivePageAction("Settings"))
                        }
                    >
                        <NavLink
                            to={basePathName + "/settings"}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                                fontSize: "14px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                fontFamily: "Montserrat",
                            }}
                        >
                            {"Settings"}
                        </NavLink>
                    </div>
                </MenuItem>
                <Divider></Divider>
                <MenuItem
                    className="menu-item-custom"
                    onClick={() => {
                        handleLogout();
                        setUser(false);
                        sessionStorage.clear();
                    }}
                >
                    <div
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            fontWeight: "600",
                            fontFamily: "Montserrat",
                        }}
                    >
                        Logout
                    </div>
                </MenuItem>
            </Menu>
            <InviteModal
                show={showInviteModal}
                hide={() => setShowInviteModal(false)}
            />
        </div>
    );
};

export default UserSection;
