import axios from "axios";
import React, { useState, useEffect } from "react";
import http from "../../../../bae-client/HTTP";
import config from "../../../../config";
import Loader from "../../../stylized/Loader";
import { setUserSession } from "./ActiveUser";
import "./style.css";
import * as Mixpanel from "../../../../mix-panel/Mixpanel";
import { getErrorMessage } from "../../../../server-errors";

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value.trim());
    };
    return {
        value,
        onChange: handleChange,
    };
};
export default function Login({ setUser, changeCurrentPage }) {
    const username = useFormInput("");
    const password = useFormInput("");
    const [error, setError] = useState("");
    const [isValidMail, setvalidMail] = useState(true);
    const [isValidPassword, setisValidPassword] = useState(true);
    const [showLoader, setshowLoader] = useState(false);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            let samlToken = token
                .substring(token.indexOf(":") + 2, token.length - 2)
                .replaceAll('"', "'");
            loginUser(samlToken);
        }
    }, []);
    const validateUsername = () => {
        if (username.value !== "") {
            setvalidMail(true);
            return true;
        }
        // if (ValidateEmail(username.value)) {
        //     setvalidMail(true);
        //     return true;
        // }
        else {
            setvalidMail(false);
            setError("");
            return false;
        }
    };
    const validatePassword = () => {
        if (password.value !== "") {
            setisValidPassword(true);
            return true;
        } else {
            setError("");
            setisValidPassword(false);
            return false;
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === " " && username.value.length === 0) {
            e.preventDefault();
        }
    };
    const loginUser = (token) => {
        if (
            token ||
            (validateUsername() === true && validatePassword() === true)
        ) {
            setshowLoader(true);
            let data = token
                ? {
                      authenticationType: "saml",
                      username: "",
                      password: "",
                      samlToken: token,
                  }
                : {
                      authenticationType: "basic",
                      username: username.value,
                      password: password.value,
                      samlToken: "",
                  };
            let restApp = window.location.pathname.split("/")[1]
                ? window.location.pathname.split("/")[1]
                : config.restAppName;
            let axiosConfig = {
                url:
                    // (process.env.NODE_ENV === "development"
                    //     ? config.env.TEST_URL
                    //     : window.location.origin) +
                    // "/" +
                    // process.env.REACT_APP_REST_APP.toString() +
                    // "/rest/authentication",
                    window.runtimeConfig &&
                    window.runtimeConfig.restURL +
                        "/" +
                        window.runtimeConfig.restApp +
                        "/rest/authentication",
                method: "POST",
                responseType: "json",
                data: data,
            };
            axios(axiosConfig)
                .then((response) => {
                    if (
                        response.data.authorizationToken === "null" ||
                        response.data.memberId <= -1 ||
                        response.data.nhId <= -1 ||
                        response.data.userId <= -1
                    ) {
                        setError(
                            "Unknown error occurred on server, please contact \n Boardwalk admin!"
                        );
                        setshowLoader(false);
                        return;
                    }
                    let authorizationToken = token
                        ? "saml:" + response.data.authorizationToken
                        : "basic:" + response.data.authorizationToken;
                    setUserSession(
                        authorizationToken,
                        response.data.userName,
                        response.data.userId,
                        response.data.nhId,
                        response.data.memberId,
                        response.data.readOnlyUser
                    );

                    http.initialize();
                    http.setUserName(response.data.userName);
                    setUser(true);
                    setshowLoader(false);
                })
                .catch((error) => {
                    let errorMessage = null;
                    if (error.response)
                        errorMessage = getErrorMessage(
                            error.response.data[0],
                            error.response.status
                        );
                    else errorMessage = getErrorMessage(null);
                    setError(errorMessage.error);
                    setshowLoader(false);
                    setError(
                        "The information you provided does not match our records.\n Please re-enter and try again"
                    );
                });
            // http.sendRestRequest("/authentication", "GET", null, authentication)
            //     .catch((error) => console.log(error));
        }
    };

    const loginUserWithOkta = (e) => {
        // axios
        //     .get("authentication/ssoURL", "GET")
        http.sendRestRequest("authentication/ssoURL")
            .then((response) => {
                let url = response.ssoURL;
                window.open(url, "_self");
            })
            .catch((error) => console.log(error));
    };

    return (
        <div className="row center m-0" style={{ height: "85%" }}>
            <div className="center mb-3">
                <div className="bw_header_row doCenter">
                    <img
                        src="images/UCLogo.png"
                        alt="UC Logo"
                        className="bw_header_icon"
                    />
                    <span className="bw_header_text"> Unity Central</span>
                </div>
            </div>
            <div className="row justify-content-center w-100 m-0">
                <div className="w-100 center">
                    <form
                        name="loginform"
                        className="w-100 center"
                        onSubmit={(event) => event.preventDefault()}
                    >
                        <div className="form-group w-100 center">
                            <div className="row w-100 ">
                                <label
                                    htmlFor="inp_login_userName"
                                    className="loginPageTextBox_label"
                                >
                                    Email
                                </label>
                            </div>
                            <div
                                className={
                                    isValidMail
                                        ? "row m-0 loginPageTextBoxRow"
                                        : "row m-0 loginPageTextBoxRow err_border"
                                }
                                style={error ? { width: "27rem" } : null}
                            >
                                <div className="col p-0">
                                    <input
                                        type="text"
                                        className="inp_outline_textBox"
                                        id="inp_login_userName"
                                        placeholder="Enter Your Username"
                                        name="username"
                                        onKeyDown={handleKeyDown}
                                        onBlur={validateUsername}
                                        {...username}
                                    />
                                </div>
                                {!isValidMail ? (
                                    <div className="col-1 p-0 doCenter">
                                        <img
                                            src="images/exclamation-circle 1.png"
                                            className="err_icon"
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className="row m-0 w-100"
                                style={{
                                    display: isValidMail ? "none" : "block",
                                }}
                            >
                                <span
                                    className="err_msg"
                                    id={"span_err_login_username"}
                                >
                                    Please enter valid email
                                </span>
                            </div>
                        </div>

                        <div className="form-group w-100 center">
                            <div className="row w-100 ">
                                <label
                                    htmlFor="inp_login_userPassword"
                                    className="loginPageTextBox_label"
                                >
                                    Password
                                </label>
                            </div>
                            <div
                                className={
                                    isValidPassword
                                        ? "row m-0 loginPageTextBoxRow"
                                        : "row m-0 loginPageTextBoxRow err_border"
                                }
                                style={error ? { width: "27rem" } : null}
                            >
                                <div className="col p-0">
                                    <input
                                        type="password"
                                        className={"inp_outline_textBox"}
                                        id="inp_login_userPassword"
                                        placeholder="Enter Your Password"
                                        name="password"
                                        onBlur={validatePassword}
                                        {...password}
                                    />
                                </div>

                                {!isValidPassword ? (
                                    <div className="col-1 p-0 doCenter">
                                        <img
                                            src="images/exclamation-circle 1.png"
                                            className="err_icon"
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className="row m-0 w-100"
                                style={{
                                    display: isValidPassword ? "none" : "block",
                                }}
                            >
                                <span
                                    className="err_msg"
                                    id="span_err_login_password"
                                >
                                    Please enter your password
                                </span>
                            </div>
                        </div>

                        <div className="form-group w-100 center">
                            {error && (
                                <div className="row m-0 w-100">
                                    <span
                                        className="err_msg"
                                        id="spn_login_serverErr"
                                    >
                                        {error}
                                    </span>
                                </div>
                            )}
                            <br />
                            <button
                                data-testid="login-button"
                                id={"btn_login_submit"}
                                type="submit"
                                className="btn btn-primary submitBtn"
                                style={{ fontWeight: "400", marginBottom: "0" }}
                                name="go"
                                onClick={() => loginUser()}
                            >
                                Login
                            </button>
                        </div>
                        {!window.runtimeConfig.enableSSO ? null : (
                            <div className="form-group w-100 center">
                                <br />
                                <button
                                    id={"btn_login_octa"}
                                    type="submit"
                                    className="btn btn-primary submitBtn"
                                    style={
                                        !window.runtimeConfig.enableSSO
                                            ? {
                                                  cursor: "not-allowed",
                                                  fontWeight: "500",
                                                  color: "#000000",
                                                  border: "1px solid #000000",
                                                  backgroundColor: "#ffffff",
                                              }
                                            : {
                                                  fontWeight: "500",
                                                  color: "#000000",
                                                  border: "1px solid #000000",
                                                  backgroundColor: "#ffffff",
                                              }
                                    }
                                    name="go"
                                    disabled={!window.runtimeConfig.enableSSO}
                                    onClick={loginUserWithOkta}
                                >
                                    Login With SSO
                                </button>
                            </div>
                        )}
                        <div className="form-group w-100 center">
                            <span
                                id="btn_login_forgotPassword"
                                className="linkBtn"
                                onClick={() => {
                                    changeCurrentPage("forgotPassword");
                                }}
                            >
                                Forgot your password
                            </span>
                        </div>
                        <div className="form-group w-100 center">
                            <span
                                id="btn_login_signUp"
                                className="linkBtn"
                                onClick={() => {
                                    changeCurrentPage("signUp");
                                }}
                            >
                                New to Unity Central? Sign up
                            </span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row justify-content-center mt3">
                <div className="col-md-5"></div>
            </div>
            <Loader show={showLoader}></Loader>
        </div>
    );
}
