import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import ModalView from "../../../../../stylized/ModalView";
import http from "../../../../../../bae-client/HTTP";
import user from "../../../../../../bae-client/User";
import ContactList from "../../communicator/chatSection/newChatUser/contactList";
import { setActivePage } from "../../../../../../actions";
import {
    setChatList,
    setActiveChat,
    setSharedData,
} from "../../../../../../actions/CommunicatorActions";
import "./style.css";

import AlertDialog from "../../../../../stylized/AlertDialog";
import { convertObjectMapToArrayMap } from "../../../../../stylized/multilevel-select-option";
import {
    fetchUserListData,
    fetchDirectoryListData,
} from "../../../LeftPane/Directory/DirectoryActions";
import {
    directoryList,
    directoryUsersList,
} from "../../../LeftPane/Directory/DirectoryReducers";
import Loader from "../../../../../stylized/Loader";

function ShareFlow({
    showShareModal,
    closeModal,
    selectedStepId,
    genericId,
    selectedGenericSteps,
    genericDetails,
    chatList,
    setChatList,
    setActiveChat,
    activeChatUser,
}) {
    const [userArray, setUserArray] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [error, setError] = useState("");
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const dispatch = useDispatch();
    const workspaceIdToName = useSelector((state) => state.workspaceIdToName);
    const spaceIdToName = useSelector((state) => state.spaceIdToName);
    const directoryList = useSelector((state) => state.directoryList);
    const directoryUsersList = useSelector((state) => state.directoryUsersList);
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);
    const handleDropdownSelect = (userArray) => {
        if (userArray.length) {
            setError("");
            setUserArray(userArray);
        } else {
            setUserArray([]);
        }
    };
    useEffect(() => {
        dispatch(fetchDirectoryListData());
    }, []);

    useEffect(() => {
        if (directoryList && directoryList.length) {
            dispatch(
                fetchUserListData(
                    directoryList[directoryList.length - 1].directoryId
                )
            );
        }
    }, [directoryList]);
    useEffect(() => {
        if (directoryUsersList && directoryUsersList.length) {
            http.sendRestRequest("user/?active=true")
                .then((res) => {
                    const filteredItems = res.filter(
                        (user) =>
                            user.userId !== parseInt(sessionStorage.userId)
                    );
                    let contacts = [];
                    directoryUsersList.forEach((user) => {
                        filteredItems.forEach((item) => {
                            if (
                                user.emailAddress.toLowerCase() ===
                                item.userEmail.toLowerCase()
                            ) {
                                contacts.push(item);
                            }
                        });
                    });
                    setAllContacts(contacts);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    }, [directoryUsersList]);
    const shareFlows = (userId, genericIds, sharedUsers, fullFlow) => {
        let url = `share/generic?ownerUserId=${userId}&ownerGenericId=${genericIds}&sharedUserId=${sharedUsers[0]}&shareWholeFlow=${fullFlow}`;
        let data = selectedStepId;
        const genericId = genericDetails.genericId;
        const genericName = genericDetails.docuChain;
        const genericDisplayName = genericDetails.genericDisplayName;
        const obj = {
            genericId,
            genericName,
            shareType: "flow",
            genericDisplayName,
        };
        // const putPayload = {
        //     chatRegistryId: userId,
        //     message: JSON.stringify(obj),
        //     messageBy: parseInt(sessionStorage.getItem("userId")),
        //     messageType: "MSG_FLOW_OBJECT",
        // };

        http.sendRestRequest(url, "POST", data)
            .then(() => {
                dispatch(
                    setSharedData({
                        users: sharedUsers,
                        message: JSON.stringify(obj),
                        messageType: "MSG_FLOW",
                    })
                );
                dispatch(setActivePage("Communicator"));
                let basePathName = window.runtimeConfig.instanceName
                    ? "/" + window.runtimeConfig.instanceName
                    : "";
                navigate(`${basePathName}/chat`);
                setShowLoader(false);
            })
            .catch((err) => {
                closeModal();
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
                setShowLoader(false);
            });
    };
    const currentUser = sessionStorage.getItem("userId");

    const onSubmit = () => {
        if (!userArray.length) setError("Please select at least one user");
        else {
            const users = userArray.map((user) => parseInt(user.userId));
            shareFlows(
                currentUser,
                genericId,
                users,
                false,
                selectedStepId.genericStepId
            );
        }
    };

    const handleAlertDialogClose = (flag) => {
        setShowAlertDialog(flag);
    };

    return (
        <>
            <ModalView
                showHeader="false"
                footer="false"
                show={showShareModal}
                size="md"
                onHide={closeModal}
                title={"Okay"}
                id="btn_folder_submit"
                value={"Share"}
                onclick={closeModal}
                type="submit-cancel"
                submitButtonText="Close"
                onSubmit={onSubmit}
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <div
                        className="updateSpaceNameTitle addFolder ShareFile"
                        style={{ marginLeft: "-220px" }}
                    >
                        Select Members
                    </div>

                    <div
                        id={"newChatAddress"}
                        style={{
                            padding: "8px 16px",
                            minHeight: "64px",
                        }}
                    >
                        <div style={{ float: "left" }}>
                            <ContactList
                                style={{ border: "none" }}
                                dropdownList={allContacts}
                                handleDropdownSelect={handleDropdownSelect}
                                flowShare={true}
                            ></ContactList>
                        </div>
                    </div>
                </div>
                {error && (
                    <>
                        <small
                            className="doCenter"
                            style={{
                                color: "red",
                                paddingLeft: "18px",
                                margin: "-8px 192px 0px 0px",
                            }}
                        >
                            {error}
                        </small>
                    </>
                )}
            </ModalView>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={handleAlertDialogClose}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        setChatList,
        setActiveChat,
        setSharedData,
    };
};
// const mapDispatchToProps = (dispatch) => {
//     return { chatList: state.chatList, activeChatUser: state.activeChatUser };
// };
export default connect(mapStateToProps)(ShareFlow);
