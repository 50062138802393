import React from "react";
import { validateName } from "../../../../../../../bae-client/Validations";
import ModalView from "../../../../../../stylized/ModalView";
import TextBox from "../../../../../../stylized/TextBox";

export default function AddDocumentChainModal({
    showAddDocumentChainModal,
    closeModal,
    selectedRow,
    heading,
    list,
    ids,
    addNewDocumentChain,
    addCloneDocumentChain,
}) {
    const [name, setName] = React.useState("");
    const [id, setId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [isGenericChanged, setIsGenericChanged] = React.useState(false);

    const handleSubmit = () => {
        if (errMsg || errorMessage) {
            return;
        }
        const isValidName = validateName(name);
        const isValidDisplayName = validateName(id);
        if (heading === "Clone the generic flow") {
            if (isValidName === true && isValidDisplayName === true) {
                const documentNames = list.map((documentName) =>
                    documentName.genericName.toUpperCase().trim()
                );
                const documentDisplayNames = list.map((documentName) =>
                    documentName.genericDisplayName.toUpperCase().trim()
                );

                if (documentNames.includes(name.trim().toUpperCase()))
                    setErrorMessage("Generic Name Already Exist");
                else if (!name) setErrorMessage("Name cannot be empty");
                else if (
                    documentDisplayNames.includes(id.trim().toUpperCase())
                ) {
                    setErrMsg("Generic Display Name Already Exist");
                } else if (heading === "Clone the generic flow") {
                    let genericValue = list.find(
                        (obj) => obj.genericId === selectedRow.genericId
                    );
                    addCloneDocumentChain(genericValue.genericName, name, id);
                    setName("");
                    setId("");
                    setErrMsg("");
                    setErrorMessage("");
                } else {
                    addNewDocumentChain(id, name);
                    setName("");
                    setId("");
                }
            } else {
                setErrorMessage(isValidName);
                setErrMsg(isValidDisplayName);
            }
        } else {
            const documentNum = list.map((num) =>
                num.genericDisplayName.toUpperCase().trim()
            );
            if (name === "" && id === "") {
                setErrorMessage("This field should not be empty");
                setErrMsg("This field should not be empty");
            } else if (name === "") {
                setErrorMessage("This field should not be empty");
            } else if (id === "") {
                setErrMsg("This field should not be empty");
            } else if (id === "" && name.length > 0) {
                setErrMsg("This field should not be empty");
            } else if (documentNum.includes(id.trim().toUpperCase())) {
                setErrMsg("Generic Display Name Already Exist");
            } else {
                if (isValidName === true && isValidDisplayName === true) {
                    const documentNames = list.map((documentName) =>
                        documentName.genericName.toUpperCase().trim()
                    );

                    if (documentNames.includes(name.trim().toUpperCase()))
                        setErrorMessage("Flow Name Already Exist");
                    else if (!name) setErrorMessage("Name cannot be empty");
                    else if (heading === "Clone the generic flow") {
                        let genericValue = list.find(
                            (obj) => obj.genericId === selectedRow.genericId
                        );
                        addCloneDocumentChain(
                            genericValue.genericName,
                            name,
                            id
                        );
                        setName("");
                        setId("");
                        setErrMsg("");
                        setErrorMessage("");
                    } else {
                        addNewDocumentChain(id, name);
                        setName("");
                        setId("");
                        setErrMsg("");
                        setErrorMessage("");
                    }
                } else {
                    if (!isValidName) setErrorMessage(isValidName);
                    if (!isValidDisplayName) setErrMsg(isValidDisplayName);
                }
            }
        }
    };
    const handleCloseModal = () => {
        setIsGenericChanged(false);
        setErrMsg("");
        setErrorMessage("");
        setName("");
        setId("");
        closeModal();
    };
    //     const documentNames = list.map((documentName) =>
    //         documentName.genericName.toUpperCase().trim()
    //     );
    //     if (documentNames.includes(name.trim().toUpperCase()))
    //         setErrorMessage("Flow Name Already Exist");
    //     else if (!name) setErrorMessage("Name cannot be empty");
    //     else addNewDocumentChain(name);
    // };
    const onChange = (value) => {
        setName(value);
        if ((id === name || id === "") && !isGenericChanged) {
            setId(value);
            setErrMsg("");
        }
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length > 100 || !Boolean(value.match(regex))) {
            setErrorMessage(
                "Only letters and numbers up to 100 characters are allowed"
            );
        } else {
            setErrorMessage("");
        }
    };

    const onGenericNameBlur = (e) => {
        let value = e.target.value;
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length <= 100 && Boolean(value.match(regex))) {
            setErrorMessage("");
        }
    };
    const onGenericChange = (value) => {
        if (value !== "") {
            setIsGenericChanged(true);
        } else {
            setIsGenericChanged(false);
        }

        setId(value);
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length > 100 || !Boolean(value.match(regex))) {
            setErrMsg(
                "Only letters and numbers upto 100 characters are allowed"
            );
        } else {
            setErrMsg("");
        }
    };
    const onGenericDisplayNameBlur = (e) => {
        let value = e.target.value;
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length <= 100 && Boolean(value.match(regex))) {
            setErrMsg("");
        }
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showAddDocumentChainModal}
            size="md"
            onHide={handleCloseModal}
            title="Okay"
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            value="Name"
            onclick={handleCloseModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                {/* <img src="./images/Space 2.svg"></img> */}
                <div
                    id={ids}
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-32px",
                    }}
                >
                    {heading}
                </div>
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column", marginTop: "20px" }}
                >
                    <div
                        id={"div_genericname_flow"}
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "100",
                            fontSize: "14px",
                            lineHeight: "27px",
                            color: "#404040",
                            width: "430px",
                            marginRight: "-9px",
                        }}
                    >
                        Generic Name
                    </div>
                    {/* <img src="./images/Space 2.svg"></img> */}
                    <TextBox
                        title="Generic Name"
                        id={"textbox_genericname_flow"}
                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                        type="text"
                        style={{ marginTop: "-20px" }}
                        onchange={onChange}
                        onfocusout={onGenericNameBlur}
                        label="Name"
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                </div>

                {errorMessage && (
                    <>
                        <small
                            id={"small_errMsg_flow"}
                            style={{
                                color: "red",
                                paddingLeft: "36px",
                                marginRight: "auto",
                            }}
                        >
                            {errorMessage}
                        </small>
                    </>
                )}
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column", marginTop: "20px" }}
                >
                    <div
                        id={"div_genericdisplay_flow"}
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "100",
                            fontSize: "14px",
                            lineHeight: "27px",
                            color: "#404040",
                            width: "430px",

                            marginRight: "-9px",
                        }}
                    >
                        Generic Display Name
                    </div>
                    {/* <img src="./images/Space 2.svg"></img> */}
                    <TextBox
                        id={"textbox_genericdisplay_flow"}
                        title="Generic Display Name"
                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                        type="text"
                        label="Generic Display Name"
                        value={id}
                        onchange={onGenericChange}
                        onfocusout={onGenericDisplayNameBlur}
                        style={{ marginTop: "-30px" }}
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                </div>
                {errMsg && (
                    <>
                        <small
                            id={"small_errMsg_flow"}
                            style={{
                                color: "red",
                                paddingLeft: "36px",
                                marginRight: "auto",
                                marginBottom: "-25px",
                            }}
                        >
                            {errMsg}
                        </small>
                        <br />
                    </>
                )}
            </div>
        </ModalView>
    );
}
