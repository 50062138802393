const endPoint = "https://prod-in2.100ms.live/hmsapi/bwcc.app.100ms.live/";
export async function GetToken(role, roomId) {
    const response = await fetch(`${endPoint}api/token`, {
        method: 'POST',
        body: JSON.stringify({
         user_id: '2234', // a reference user id assigned by you
             role: role, // stage, viewer 
         room_id: roomId // copied from the dashboard
        }),
    });
    const { token } = await response.json();
    return token;
}

export async function createRoom (name, mgmtToken) {
    const url = "https://api.100ms.live/v2/rooms"
    const response = await fetch(`${url}`, {
        method: 'POST',
        headers:{Authorization: "Bearer " + mgmtToken},
        body: JSON.stringify({
            name: name,
            description: "This is a sample description for the room",
            template_id: "63dd062f40a3136b43f136a3",
            region: "us"
        }),
    });
    const room = await response.json();
    return room;
}