import React from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";

export default function EditWorkspace({
    editWSNameModal,
    closeModal,
    setText,
    handleEditWorkspaceName,
}) {
    if (editWSNameModal) {
        return (
            <ModalView
                show={editWSNameModal}
                onHide={closeModal}
                footer={"false"}
                size="md"
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <img
                        className="addWorkspaceModalImg"
                        src="./images/Workspace A.svg"
                        alt="add workspace"
                    ></img>
                    <span className="addWorkspaceModal">
                        Rename your Workspace...
                    </span>
                    <TextBox
                        id="editworkspacename"
                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                        type="text"
                        onchange={setText}
                        label="Rename your workspace..."
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                    <input
                        type="button"
                        id="editworkspacenameBtn"
                        className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                        style={{
                            height: "40px",
                            marginTop: "45px",
                            marginLeft: "10px",
                        }}
                        value="CHANGE"
                        onClick={handleEditWorkspaceName}
                    ></input>
                </div>
            </ModalView>
        );
    } else {
        return null;
    }
}
