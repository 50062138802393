import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { _ALL_USERS_ENDPOINT, _REST_APP, _URL } from "../constants";
import http from "../../bae-client/HTTP";

export const usersAPI = createApi({
    reducerPath: "Users",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        /**
         * Adds necessary headers for the API request.
         * @param {Headers} headers - The headers to be modified.
         * @returns {Headers} - The modified headers.
         */
        prepareHeaders: (headers) => {
            // Set Access-Control-Allow-Origin to allow all origins
            headers.set("Access-Control-Allow-Origin", "*");
            // Set Content-Type to application/json
            headers.set("Content-Type", "application/json");
            // Set Authorization header with the value of sessionStorage.getItem("token")
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    /**
     * Defines endpoints for interacting with the user data.
     * @param {object} builder - The query builder object.
     */
    endpoints: (builder) => ({
        /**
         * Query to get a list of users.
         * @param {object} options - The query options, including 'active' flag.
         * @returns {string} - The constructed endpoint URL.
         */
        users: builder.query({
            query: (active) =>
                _ALL_USERS_ENDPOINT + `?active=${active || true}`,
        }),
        /**
         * Query to get a single user by ID.
         * @param {string} id - The ID of the user.
         * @returns {string} - The constructed endpoint URL.
         */
        user: builder.query({
            query: (id) => _ALL_USERS_ENDPOINT + `/${id}`,
        }),
    }),
});

export const { useUsersQuery, useUserQuery } = usersAPI;
