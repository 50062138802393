import React, { Component } from "react";
import SignUp from "./SignUp";

import { connect } from "react-redux";
import { setLoginContent } from "../../../actions";
class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curruntPage: "",
        };
    }
    handleSaveBtn = () => {
        if (this.props.activePage !== "Settings") {
            this.setState({ curruntPage: "signUp" });
        }
    };
    render() {
        if (this.state.curruntPage === "signUp") {
            return <SignUp></SignUp>;
        } else {
            return (
                <div
                    className="doCenter row m-0"
                    style={{ flexDirection: "column", marginLeft: "25px" }}
                >
                    <div className="col"></div>
                    <div className="col-12 col-md-4 col-lg-4 col-sm-6">
                        <div className="row m-0 w-100 mb-5 doCenter">
                            <span className="billingHeading">Billing Info</span>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-2">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    autocomplete="off"
                                    placeholder="First:"
                                ></input>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="Last:"
                                ></input>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-2 p-1">
                            <input
                                autocomplete="off"
                                type="text"
                                className="loginPageTextBox loginPageBorder loginTextBoxLarge"
                                placeholder="Address:"
                            ></input>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-2">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="City:"
                                ></input>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="Zip:"
                                ></input>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-2 mt-2">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="Country:"
                                ></input>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="State:"
                                ></input>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-4 p-1">
                            <input
                                autocomplete="off"
                                type="text"
                                className="loginPageTextBox loginPageBorder loginTextBoxLarge"
                                placeholder="Card Number:"
                            ></input>
                        </div>
                        <div className="row m-0 w-100 doCenter mt-2">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="Expiration:"
                                ></input>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="loginPageTextBox loginPageBorder loginTextBoxSmall"
                                    placeholder="CVV:"
                                ></input>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter p-1 mt-4">
                            <input
                                type="button"
                                className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                                style={{ height: "2.8rem", marginTop: "10px" }}
                                onClick={this.handleSaveBtn}
                                value="SIGN UP"
                            ></input>
                        </div>
                    </div>
                    <div className="col"></div>
                    {/* <div className="doCenter" style={{ marginTop: '10px' }}>
                        <input type="button" className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                            style={{ height: '40px', marginTop: "10px" }}
                            value="SAVE"
                            onClick={this.handleSaveBtn}
                        ></input>
                    </div> */}
                </div>
            );
        }
    }
}
const mapDispatchToProps = () => {
    return {
        setLoginContent,
    };
};

const mapStateToProps = (state) => {
    return {
        loginContent: state.loginContent,
        activePage: state.activePage,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(Billing);
