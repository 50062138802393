import React, { Component } from "react";
import IntegrationContainer from "./ContentPane/Integration";
import TitleBar from "./TiltleBar";

class Integrations extends Component {
    render() {
        return (
            <div
                className="col-12 invisibleScroller"
                style={{ overflowY: "scroll" }}
            >
                <div className="w-100 h-100 pl-0 pr-0">
                    <IntegrationContainer></IntegrationContainer>
                </div>
            </div>
        );
    }
}

export default Integrations;
