import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setActivePage,
    setActiveWorkspace,
    setBoardList,
    setCollabInfo,
    setPageType,
    setAlertData,
    setAlertManager,
    setAlertsWidget,
    setWorkspaceManager,
    updateArchiveInfoAlert,
} from "../../../../../actions";
import CollaborationFetcher from "../../../../../bae-client/CollaborationFetcher";
import http from "../../../../../bae-client/HTTP";
import AlertManagers from "../../../../../bae-client/AlertManager";
import ListView from "../../../../stylized/ListView";
import Loader from "../../../../stylized/Loader";
import ModalView from "../../../../stylized/ModalView";
import TextBox from "../../../../stylized/TextBox";
import InformationFeed from "../../InformationFeed";
import AlertViewer from "../../AlertViewer";
import TitleBar from "../../TiltleBar";
import EditWorkspace from "./EditWorkspace";
import Header from "./Header";
import Spaces from "./Spaces";
import SpaceTableHandler from "./Spaces/SpaceFolderTable";

class WorkspaceHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: "",
            editWSNameModal: false,
            alertInfoModal: false,
            addNewAlertModal: false,
        };
        let nhid = http.getNHId();
        this.collabFetcher = new CollaborationFetcher(nhid);
        this.updatedWorkspaceName = null;
        this.curruntAlertOperation = "";
        this.selectedAlertRowId = -1;
        this.alertData = null;
        this.alertTitle = "";
        this.alertBody = "";
        this.alertType = "notification";
        this.workspaceCollabId = null;
        this.workspaceWBId = null;
        this.workspaceRegistryId = null;
        this.workspacePersistenceId = null;

        this.spaceWBId = null;
        this.spaceRegistryId = null;
        this.spacePersistenceId = null;
        this.data = [
            {
                source: "./images/twitterIcon.png",
                contentText:
                    "Join our #CEO & #SVP as they discuss gaining #Enterprise insight into #pdfs, #b2b #documents...",
                attachment: false,
            },
            {
                source: "./images/OutlookIcon.png",
                contentText:
                    "jb.kuppe@boardwalktech : “Unity Central Marketing” ",
                attachment: true,
            },
            {
                source: "./images/OutlookIcon.png",
                contentText:
                    "roh.krishnan@boardwalktech : “Unity Central PPT” ",
                attachment: true,
            },
            {
                source: "./images/OutlookIcon.png",
                contentText:
                    "“@boardwalktech: Boardwalktech awarded contract for DLA supply chain visibility",
                attachment: true,
            },
        ];
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.props.cuboidInfoByName !== null &&
            this.workspaceCollabId === null
        ) {
            let dataByName = this.props.cuboidInfoByName;
            this.workspaceCollabId = dataByName.get("__Workspace");
            this.workspaceWBId = dataByName.get("Workspace");
            this.workspaceRegistryId = dataByName.get("Workspace Registry");
            this.workspacePersistenceId = dataByName.get(
                "Workspace Persistence"
            );

            this.spaceWBId = dataByName.get("Space");
            this.spaceRegistryId = dataByName.get("Space Registry");
            this.spacePersistenceId = dataByName.get("Space Persistence");
        }
    };
    handleBackBtnClick = () => {
        this.props.setPageType("workspace");
    };
    setText = (value, id, event) => {
        switch (id) {
            case "editworkspacename":
                {
                    this.updatedWorkspaceName = value;
                }
                break;
            case "addNewWorkspaceAlert":
                {
                    this.alertTitle = value;
                }
                break;
            case "addNewWorkspaceAlertBody":
                {
                    this.alertBody = value;
                }
                break;
            default:
                break;
        }
    };
    // generateRow = (spaceName) => {
    //     let rows = {
    //         rows: [[spaceName]],
    //     };
    //     return rows;
    // };
    // handleEditWorkspaceName = () => {
    //     if (
    //         this.props.workspaceManager !== undefined &&
    //         this.props.workspaceManager !== null
    //     ) {
    //         let workspaceManager = this.props.workspaceManager;
    //         if (this.updatedWorkspaceName === null) {
    //             alert("Please enter Workspace Name");
    //             return;
    //         } else if (this.updatedWorkspaceName !== null) {
    //             let flag = workspaceManager.checkExistingWorkpaceName(
    //                 this.updatedWorkspaceName
    //             );
    //             if (flag) {
    //                 alert(this.updatedWorkspaceName + " name already exist");
    //                 this.setState({ showLoader: false });
    //                 return;
    //             }
    //         }

    //         let data = this.generateRow(this.updatedWorkspaceName);
    //         let IDs = workspaceManager.getIDs();
    //         // this.workspaceCollabId = null;
    //         // this.workspaceWBId = null;
    //         // this.workspaceRegistryId = null;
    //         // this.workspacePersistenceId = null;

    //         // this.spaceWBId = null;
    //         // this.spaceRegistryId = null;
    //         // this.spacePersistenceId = null;
    //         if (
    //             this.props.activeWorkspace !== undefined &&
    //             this.props.activeWorkspace !== null
    //         ) {
    //             let wsRegistryCuboid = new CuboidManager(
    //                 //IDs.workspaceCollabId,
    //                 //IDs.workspaceWBId
    //                 this.workspaceCollabId,
    //                 this.workspaceWBId
    //             );
    //             wsRegistryCuboid
    //                 .loadGridById(
    //                     //IDs.workspaceRegistryId
    //                     this.workspaceRegistryId)
    //                 .then((response) => {
    //                     let wsspaceNameColumnSeqNo = -1;
    //                     let columnArray = response.columns.colArr;
    //                     let rowID = -1;
    //                     columnArray.forEach((item, i) => {
    //                         switch (item.columnName) {
    //                             case "Workspace Name":
    //                                 wsspaceNameColumnSeqNo =
    //                                     item.columnSequenceNo;
    //                                 break;
    //                             default:
    //                                 break;
    //                         }
    //                     });
    //                     let activeWorkspaceID =
    //                         this.props.activeWorkspace.activeWorkspaceID;
    //                     response.getRows().forEach((re, rei) => {
    //                         let currentRowId = re.rowData.getRowId();
    //                         let value = response.getCellEditorRC(
    //                             re.rowSequenceNo,
    //                             wsspaceNameColumnSeqNo
    //                         ).stringValue;
    //                         if (activeWorkspaceID === currentRowId) {
    //                             rowID = re.rowData.getRowId();
    //                         }
    //                     });
    //                     //wsRegistryCuboid.updatecell(data, 1, 1);
    //                     wsRegistryCuboid.updateCell(
    //                         rowID,
    //                         "Workspace Name",
    //                         this.updatedWorkspaceName
    //                     );
    //                     wsRegistryCuboid
    //                         .submit()
    //                         .then((resp) => {
    //                             let response = resp;
    //                             // if (response.Updation > 0) {
    //                             let newIDs = workspaceManager.getIDs();
    //                             let newWsRegistryCuboid = new CuboidManager(
    //                                 newIDs.workspaceCollabId,
    //                                 newIDs.workspaceWBId
    //                             );
    //                             newWsRegistryCuboid
    //                                 .loadGridById(newIDs.workspaceRegistryId)
    //                                 .then(() => {
    //                                     this.updateWorkspaceTreeStructure()
    //                                         .then((response) => {
    //                                             //this.updateSpaceManager();

    //                                             alert("Workspace name updated");
    //                                             this.closeModal();
    //                                             this.setState({
    //                                                 showLoader: false,
    //                                             });
    //                                         })
    //                                         .catch((err) => {
    //                                             console.log(err);
    //                                         });
    //                                 })
    //                                 .catch((err) => {
    //                                     console.log(err);
    //                                 });
    //                             // }
    //                         })
    //                         .catch((err) => {
    //                             console.log(err);
    //                         });
    //                 })
    //                 .catch((err) => {
    //                     console.log(err);
    //                 });
    //         }
    //     }
    // };
    closeModal = () => {
        this.setState({
            editWSNameModal: false,
            addNewAlertModal: false,
            alertInfoModal: false,
        });
    };
    handleEditClick = () => {
        this.setState({
            editWSNameModal: true,
        });
    };
    renderTitle = () => {
        let activeWorkspaceName = null;
        if (this.props.activeWorkspace !== undefined) {
            let id = this.props.activeWorkspace.length - 1;
            activeWorkspaceName =
                this.props.activeWorkspace.activeWorkspaceName;
        } else {
            activeWorkspaceName = "Workspace";
        }
        if (this.props.currentPageType === "managespace") {
            return (
                <TitleBar
                    username={this.props.username}
                    page={"managespace"}
                    title={"Manage Spaces"}
                    subTitle={activeWorkspaceName}
                ></TitleBar>
            );
        } else if (this.props.currentPageType === "workspace") {
            return (
                <TitleBar
                    username={this.props.username}
                    page={"workspace"}
                    title={activeWorkspaceName}
                    subTitle={"Edit"}
                    handleEditClick={this.handleEditClick}
                ></TitleBar>
            );
        } else if (this.props.currentPageType === "AlertsFullScreen") {
            return (
                <TitleBar
                    username={this.props.username}
                    page={"managespace"}
                    title={"Manage Notification/Notification"}
                    subTitle={activeWorkspaceName}
                ></TitleBar>
            );
        } else if (this.props.currentPageType === "SpacesFullScreen") {
            return (
                <TitleBar
                    username={this.props.username}
                    page={"managespace"}
                    title={"Spaces"}
                    subTitle={activeWorkspaceName}
                ></TitleBar>
            );
        }
    };
    changeCurrentPage = (event) => {
        if (this.props.currentPageType !== "SpacesFullScreen")
            this.props.setPageType("managespace");
    };
    setManageSpaceScreen = () => {
        this.props.setPageType("managespace");
    };
    setAlertsViewFullScreen = () => {
        this.props.setPageType("AlertsFullScreen");
    };
    setSpacesViewFullScreen = () => {
        this.props.setPageType("SpacesFullScreen");
    };
    renderMenuItems = () => {
        let items = [];
        items.push({
            value: this.state.selectedSpaceId + "_menu_0",
            text: "Edit Notification",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_1",
            text: "Link To Ripple",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_2",
            text: "Archive Notification",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_3",
            text: "Share Notification",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_4",
            text: "Delete Notification",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_5",
            text: "Add To Home",
        });
        return (
            <ListView
                className="noBorder"
                listItemClassname="pl-4"
                id={"" + this.state.selectedSpaceId}
                label={"list_" + this.state.selectedSpaceId}
                items={items}
                showIcon="false"
                disableActiveIndex="true"
                onchange={this.handleListItemClick}
            />
        );
    };
    handleListItemClick = (value, text, event, i) => {
        let alertIndex = this.props.selectedAlertIndex;
        this.curruntAlertOperation = event;
        if (event !== undefined && event === "Edit Title") {
            let alertIndex = this.props.selectedAlertIndex;
            let alertData = this.renderAlertsByWorkspaceId();
            let selectedAlertData = alertData[alertIndex];
            this.selectedAlertRowId = selectedAlertData.rowId;
            this.alertTitle = selectedAlertData.alertTitle;
            this.alertBody = selectedAlertData.alertBody;
            this.alertType = selectedAlertData.alertType;
            this.showAddAlertInfoModal();
        } else if (event && event === "Archive Notification") {
            this.setState({ showLoader: true });
            let alertIndex = parseInt(this.props.selectedAlertIndex);
            let alertData = this.renderAlertsByWorkspaceId();
            let selectedAlertData = alertData[alertIndex];
            this.selectedAlertRowId = selectedAlertData.rowId;
            let obj = {
                alertRegistryId: this.selectedAlertRowId,
                archive: 1,
                attachToHome: 0,
            };
            this.alertManager.updateAlertRowByAPI(obj).then((updatedObject) => {
                let rawObj =
                    this.alertManager.parseArchiveAlertObj(updatedObject);
                let allalertData = this.alertManager.archiveNewAlert(
                    this.selectedAlertRowId,
                    rawObj
                );
                this.props.setAlertData(allalertData);
                this.setState({ showLoader: false });
            });
        } else if (event !== undefined && event === "Add To Home") {
            this.setState({ showLoader: true });
            this.curruntAlertOperation = event;
            let alertIndex = parseInt(this.props.selectedAlertIndex);
            let workspaceAlertData = this.renderAlertsByWorkspaceId();
            let selectedAlertData = workspaceAlertData[alertIndex];
            this.selectedAlertRowId = selectedAlertData.rowId;
            let obj = {
                alertRegistryId: this.selectedAlertRowId,
                archive: 0,
                attachToHome: 1,
            };
            this.alertManager.updateAlertRowByAPI(obj).then((updatedObject) => {
                let rawObj =
                    this.alertManager.parseAlertRawObject(updatedObject);
                let allalertData = this.alertManager.updateAlertByRowId(
                    this.selectedAlertRowId,
                    rawObj
                );
                this.props.setAlertData(allalertData);
                this.setState({ showLoader: false });
            });
        } else if (event === "Link To Ripple") {
            this.props.setActivePage("Ripple Log");
        }
    };

    convertJSDateToExcelDate = (inDate) => {
        const retValue =
            25569 +
            (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
                (1000 * 60 * 60 * 24);
        return Number(retValue.toString().split(".")[0]);
    };
    renderAlertsByWorkspaceId = () => {
        let workspaceID = this.props.activeWorkspace.activeWorkspaceID;
        let alerts = [];
        if (this.alertData !== null) {
            this.alertData.forEach((item) => {
                if (
                    parseInt(item.workspaceId) === workspaceID &&
                    item.homeAlert !== 1
                ) {
                    alerts.push(item);
                }
            });
        }
        return alerts;
    };

    /** alert add code start here */
    showAddAlertInfoModal = () => {
        this.setState({ alertInfoModal: true });
    };
    handleCreateNewAlertBtn = () => {
        this.curruntAlertOperation = "Add New";
        this.setState({ addNewAlertModal: true });
    };
    handleAlertOperations = () => {
        if (this.curruntAlertOperation === "Edit Title") {
            this.updateExistingAlert();
        } else if (this.curruntAlertOperation === "Add New") {
            this.createNewAlert();
        }
    };
    createNewAlert = () => {
        this.closeModal();
        this.setState({ showLoader: true });
        let workspaceID = this.props.activeWorkspace.activeWorkspaceID;
        let alertTitle = this.alertTitle;
        let alertBody = this.alertBody;
        let alertType = this.alertType;
        let alertData = this.props.alertData;
        let row = {
            alertName: alertTitle,
            workspaceId: workspaceID,
            alertType: alertType,
            attachToHome: 0,
            alertBody: alertBody,
            archive: 0,
            clickAction: "-",
            clickActionParameters: "-",
        };
        this.alertManager.insertNewAlertRowByAPI(row).then((updatedObject) => {
            let alertWidget =
                this.alertManager.parseAlertRawObject(updatedObject);
            alertData.push(alertWidget);
            this.alertManager.insertNewAlertByObject(alertWidget);
            this.props.setAlertData(alertData);
            this.props.setAlertsWidget(alertData);
            this.setState({ showLoader: false });
        });
        this.props.setAlertManager(this.alertManager);
    };
    updateExistingAlert = () => {
        this.setState({ showLoader: true });
        this.closeModal();
        let alertData = this.props.alertData;
        let workspaceID = this.props.activeWorkspace.activeWorkspaceID;
        let alertTitle = this.alertTitle;
        let alertBody = this.alertBody;
        let alertType = this.alertType;
        let isAttachToHome = 0;
        let alertIndex = parseInt(this.props.selectedAlertIndex);
        let row = {
            alertRegistryId: this.selectedAlertRowId,
            alertName: alertTitle,
            workspaceId: workspaceID,
            alertType: alertType,
            attachToHome: isAttachToHome,
            alertBody: alertBody,
            archive: 0,
        };
        this.alertManager
            .updateAlertRowByAPI(row)
            .then((updatedObject) => {
                let alertRawObj =
                    this.alertManager.parseAlertRawObject(updatedObject);
                this.alertManager.updateAlertByRowId(
                    this.selectedAlertRowId,
                    alertRawObj
                );
                alertData[alertIndex] = alertRawObj;
                this.props.setAlertData(alertData);
                this.setState({ showLoader: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    updateAlertManager = () => {
        let promise = new Promise((resolve, reject) => {
            let alertManager = new AlertManagers();
            alertManager
                .loadAlertConfig()
                .then((response) => {
                    let alertArray = [];
                    let existingAlerts = alertManager.loadAllAlerts();
                    this.alertData = alertManager.getAllAlerts();
                    if (
                        existingAlerts.length !== 0 ||
                        existingAlerts.length !== null
                    ) {
                        existingAlerts.forEach((item) => {
                            alertArray.push(item);
                        });
                        this.props.setAlertsWidget(alertArray);
                    }
                    resolve(this.alertData);
                })
                .catch((errResp) => {
                    console.log(errResp);
                    this.setState({ showLoader: false });
                    reject(errResp);
                });
            this.props.setAlertManager(alertManager);
        });
        return promise;
    };
    renderAddNewAlertModalBody = () => {
        return (
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <img
                    className="addWorkspaceModalImg"
                    src="./images/Workspace A.svg"
                ></img>
                <span className="addWorkspaceModal">Add Notification</span>
                <TextBox
                    id="addNewWorkspaceAlert"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onchange={this.setText}
                    label="Name Your Alert..."
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
                <input
                    type="button"
                    id="addNewWorkspaceAlertBtn"
                    className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                    style={{ height: "40px", marginTop: "45px" }}
                    value="Add Notification"
                    onClick={this.showAddAlertInfoModal}
                ></input>
            </div>
        );
    };
    renderCreateNewAlertModal = () => {
        if (this.state.addNewAlertModal) {
            return (
                <ModalView
                    show={this.state.addNewAlertModal}
                    onHide={this.closeModal}
                    footer={"false"}
                    size="md"
                >
                    {this.renderAddNewAlertModalBody()}
                </ModalView>
            );
        } else {
            return null;
        }
    };
    handleAlertInfoButtonList = (index) => {
        switch (index) {
            case 1:
                this.alertType = "notification";
                break;
            case 2:
                this.alertType = "hidden";
                break;
            case 3:
                this.alertType = "communicator";
                break;
            case 4:
                this.alertType = "alert";
                break;
            default:
                break;
        }
    };
    renderAddNewAlertInfoModalBody = () => {
        return (
            <div
                className="container doCenter"
                style={{ flexDirection: "column", minHeight: "18rem" }}
            >
                <div className="row w-100 mb-4">
                    <TextBox
                        id="addNewWorkspaceAlert"
                        className="updateSpaceName loginPageTextBox paddingLeft loginPageBorder w-100 m-0"
                        type="text"
                        onchange={this.setText}
                        label={this.alertTitle}
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                </div>
                <div
                    className="row loginPageBorder w-100 m-0"
                    style={{ flexDirection: "column" }}
                >
                    <div className="input-group doCenter w-100 mb-2">
                        <TextBox
                            id="addNewWorkspaceAlertBody"
                            className="updateSpaceName loginPageTextBox paddingLeft mb-3 w-100 noBorder"
                            type="text"
                            onchange={this.setText}
                            label="Edit Notification Body"
                            // label={this.alertBody !== "" ? this.alertBody : "Edit Tiles Body"}
                            formControl="Off"
                            doCenter={true}
                        ></TextBox>
                    </div>
                    <div className="row w-100 mb-2 ml-3">
                        <img
                            src="./images/penIcon.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(0)}
                        />
                        <img
                            src="./images/Vector.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(1)}
                        />
                        <img
                            src="./images/ShowHide.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(2)}
                        />
                        <img
                            src="./images/Communicator.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(3)}
                        />
                        <img
                            src="./images/bellicon.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(4)}
                        />
                        <img
                            src="./images/mediaIcon.png"
                            className="mr-3 iconAlertModalBtn"
                            alt=""
                            onClick={(e) => this.handleAlertInfoButtonList(5)}
                        />
                    </div>
                </div>
                <div className="row w-100">
                    <input
                        type="button"
                        id="addNewWorkspaceAlertModelBtn"
                        className="roundedIconBtn"
                        value="+"
                        onClick={this.handleAlertOperations}
                    ></input>
                </div>
            </div>
        );
    };
    renderNewAlertInfoModal = () => {
        if (this.state.alertInfoModal) {
            return (
                <ModalView
                    show={this.state.alertInfoModal}
                    onHide={this.closeModal}
                    footer={"false"}
                    size="md"
                >
                    {this.renderAddNewAlertInfoModalBody()}
                </ModalView>
            );
        } else {
            return null;
        }
    };
    /** alert add code end here */
    renderRightPanel = (alertsCount, rowsCount) => {
        if (this.alertManager !== null) {
            this.alertData = this.alertManager.getAllAlerts();
            if (this.props.currentPageType === "managespace") {
                return <SpaceTableHandler></SpaceTableHandler>;
            } else if (this.props.currentPageType === "workspace") {
                return (
                    <AlertViewer
                        alertData={this.renderAlertsByWorkspaceId()}
                        menuItems={this.renderMenuItems()}
                        createNewAlert={this.handleCreateNewAlertBtn}
                        alertsCountInRow={alertsCount}
                        alertCountInSlide={4}
                        rowsPerSlide={rowsCount}
                        showFullScreenButton={true}
                        setViewFullScreen={this.setAlertsViewFullScreen}
                    ></AlertViewer>
                ); //alertClassname="alertSection"
            } else if (this.props.currentPageType === "AlertsFullScreen") {
                return (
                    <AlertViewer
                        alertData={this.renderAlertsByWorkspaceId()}
                        menuItems={this.renderMenuItems()}
                        alertCountInSlide={12}
                        createNewAlert={this.handleCreateNewAlertBtn}
                        alertsCountInRow={alertsCount}
                        rowsPerSlide={rowsCount}
                    ></AlertViewer>
                );
            }
        } else {
            return null;
        }
    };
    renderInformation = () => {
        if (this.props.currentPageType === "workspace") {
            return (
                <InformationFeed
                    data={this.props.informationFeedData || this.data}
                ></InformationFeed>
            );
        }
    };
    render() {
        if (this.alertManager !== null) {
            this.alertManager = this.props.alertManager;
        }
        return (
            <>
                <div className="w-100 h-100">
                    <Header
                        currentPageType={this.props.currentPageType}
                        handleBackBtnClick={this.handleBackBtnClick}
                    />
                    <Spaces
                        currentPageType={this.props.currentPageType}
                        changeCurrentPage={this.changeCurrentPage}
                        setSpacesViewFullScreen={this.setSpacesViewFullScreen}
                        setManageSpaceScreen={this.setManageSpaceScreen}
                        renderRightPanel={this.renderRightPanel}
                    />
                    {/* {this.renderSpaceArea()} */}
                    {/* {this.renderInformation()} */}
                    {/* {this.renderEditWorkspaceModal()} */}
                    <EditWorkspace
                        editWSNameModal={this.state.editWSNameModal}
                        closeModal={this.closeModal}
                        setText={this.setText}
                        handleEditWorkspaceName={this.handleEditWorkspaceName}
                    />
                    {this.renderCreateNewAlertModal()}
                    {this.renderNewAlertInfoModal()}
                </div>
                <Loader show={this.state.showLoader} />
            </>
        );
    }
}

const mapDispatchToProps = () => {
    return {
        setActivePage,
        setPageType,
        setAlertData,
        setWorkspaceManager,
        setAlertsWidget,
        setAlertManager,
        setCollabInfo,
        updateArchiveInfoAlert,
        setBoardList,
        setActiveWorkspace,
    };
};
const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        currentPageType: state.currentPageType,
        selectedAlertIndex: state.selectedAlertIndex,
        alertData: state.alertData,
        workspaceManager: state.workspaceManager,
        alertManager: state.alertManager,
        spaceManager: state.spaceManager,
        activeWorkspace: state.activeWorkspace,
        activeSpace: state.activeSpace,
        workspaceFetcher: state.workspaceFetcher,
        informationFeedData: state.informationFeedData,
        cuboidInfoByName: state.cuboidInfoByName,
        cuboidInfoById: state.cuboidInfoById,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(WorkspaceHandler);
