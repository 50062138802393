import Alert from "@material-ui/lab/Alert";
import React, { Component } from "react";
import http from "../../../bae-client/HTTP";
import config from "../../../config";
import AlertDialog from "../../stylized/AlertDialog";
import CustomSnackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import OutlineTextBoxView from "../../stylized/OutlineTextBoxView";
export class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mailRequested: false,
            isValidPassword: null,
            isValidPasswordPattern: null,
            isValidCode: null,
            showAlertModal: false,
            err_password: "",
            err_confPassword: "",
            err_activation: "",
            showSnackbar: false,
            alertDialogContent: {
                title: "",
                message: "",
                isAlert: false,
                messageId: "",
                buttonId: "",
            },
            userEmail: "",
            password: "",
            confirmPassword: "",
            activationCode: "",
        };
        this.userEmail = "";
        this.password = "";
        this.confirmPassword = "";
        this.activationCode = "";
    }
    setFromText = (value, id, event) => {
        switch (id) {
            case "inp_updatePassword_password":
                {
                    this.password = value;
                    this.setState({ password: value });
                }
                break;
            case "inp_updatePassword_confirmpassword":
                {
                    this.confirmPassword = value;
                    this.setState({ confirmPassword: value });
                }
                break;
            case "inp_updatePassword_activationCode":
                {
                    this.activationCode = value;
                    this.setState({ activationCode: value });
                    this.validateActivationCode();
                }
                break;
            default:
                break;
        }
    };
    validatePasswordPatern = () => {
        var password_Pattern =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,256}$/;
        if (this.password !== "" && this.password.match(password_Pattern)) {
            this.setState({ isValidPasswordPattern: true, err_password: "" });
            return true;
        } else {
            // alert("wrong password format" + "\n" + "Try with 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character")
            this.setState({
                isValidPasswordPattern: false,
                err_password:
                    "Password must contain atleast 8 characters, one uppercase, one lowercase, one special character, and one number ",
            });
            return false;
        }
    };
    validatePassword = () => {
        if (this.password !== "" && this.password === this.confirmPassword) {
            this.setState({ isValidPassword: true, err_confPassword: "" });
            return true;
        } else if (this.confirmPassword === "") {
            this.setState({
                isValidPassword: false,
                err_confPassword: "Please enter your password .",
            });
            return false;
        } else {
            // alert("Password and Re-enter password are not matching.");
            this.setState({
                isValidPassword: false,
                err_confPassword:
                    "Password and Re-enter password are not matching.",
            });
            return false;
        }
    };
    validateActivationCode = () => {
        if (this.activationCode.trim() !== "") {
            this.setState({ isValidCode: true, err_activation: "" });
            return true;
        } else {
            this.setState({
                isValidCode: false,
                err_activation: "Please enter valid verification code",
            });
            // alert("Activation code should not empty...");
            return false;
        }
    };
    getUserEmail = () => {
        if (this.props.userEmail !== undefined && this.props.userEmail !== "") {
            this.userEmail = this.props.userEmail;
        }
    };
    handleSubmitBtn = () => {
        if (
            this.validateActivationCode() &&
            this.validatePasswordPatern() &&
            this.validatePassword() === true
        ) {
            this.setState({ showLoader: true });
            let data = {
                username: this.userEmail,
                password: this.password,
                activationCode: this.activationCode,
            };
            let url = "password/updatePassword";
            http.sendRestRegistraionRequest(url, "POST", data)
                .then((response, status) => {
                    this.setState({ showLoader: false });
                    this.showSnacbar(
                        "",
                        "Password updated successfully",
                        "passwordUpdateMsg",
                        "passwordUpdateBtn"
                    );
                    window.location.href =
                        window.location.origin + window.location.pathname;
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({ showLoader: false });
                });
        }
    };
    handleSubmitResendBtn = () => {
        if (this.userEmail !== "") {
            this.setState({ showLoader: true });
            let url = "password/forgotPassword/?username=" + this.userEmail;
            http.sendRestRegistraionRequest(url, "POST")
                .then((response) => {
                    this.setState({
                        showLoader: false,
                        resendBtnDisabled: false,
                    });
                    this.showAlertView("", "Email sent successfully !");
                    // this.props.changeUserEmail(this.userEmail);
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({
                        showLoader: false,
                        resendBtnDisabled: true,
                    });
                });
        }
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    setShowSnackbar = (flag) => {
        this.setState({ showSnackbar: flag });
    };
    showSnacbar = (title, body, messageId, buttonId) => {
        this.setState({
            alertDialogContent: {
                title: title,
                message: body,
                messageId: messageId,
                buttonId: buttonId,
            },
        });
        this.setShowSnackbar(true);
    };
    renderSnackBar = () => {
        return (
            <CustomSnackbar
                open={this.state.showSnackbar}
                setOpen={this.setShowSnackbar}
                alertMessage={this.state.alertDialogContent.message}
            ></CustomSnackbar>
        );
    };
    render() {
        this.getUserEmail();
        return (
            <div className="doCenter">
                <div className="col"></div>
                <div className="col-12 col-md-4 col-lg-4 col-sm-6">
                    <div className="row m-0 w-100 doCenter p-1">
                        <OutlineTextBoxView
                            id="inp_updatePassword_password"
                            placeholder="New Password"
                            type="password"
                            title="Try with 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
                            onChangeInput={this.setFromText}
                            onBlurInput={this.validatePasswordPatern}
                            errorMsg={this.state.err_password}
                        ></OutlineTextBoxView>
                        {/* {this.state.isValidPasswordPattern === false ? (
                            <Alert severity="error">
                                Try with 8 to 15 characters which contain at
                                least one lowercase letter, one uppercase
                                letter, one numeric digit, and one special
                                character
                            </Alert>
                        ) : null} */}
                    </div>
                    <div className="row m-0 w-100 doCenter p-1">
                        <OutlineTextBoxView
                            id="inp_updatePassword_confirmpassword"
                            placeholder="Re-enter Password"
                            type="password"
                            onChangeInput={this.setFromText}
                            onBlurInput={this.validatePassword}
                            errorMsg={this.state.err_confPassword}
                        ></OutlineTextBoxView>
                        {/* {this.state.isValidPassword === false ? (
                            <Alert severity="error">
                                Password and Re-enter password are not matching.
                            </Alert>
                        ) : null} */}
                    </div>
                    <div className="row m-0 w-100 doCenter p-1">
                        <OutlineTextBoxView
                            id="inp_updatePassword_activationCode"
                            placeholder="Enter verification code"
                            type="text"
                            onChangeInput={this.setFromText}
                            onBlurInput={this.validateActivationCode}
                            errorMsg={this.state.err_activation}
                        ></OutlineTextBoxView>
                        {/* {this.state.isValidCode === false ? (
                            <Alert severity="error">
                                Activation code should not be Empty...
                            </Alert>
                        ) : null} */}
                    </div>
                    <div className="row m-0 w-100 doCenter p-1 mt-3">
                        <button
                            type="submit"
                            id="inp_updatePassword_submit"
                            className="btn btn-primary submitBtn"
                            style={{ fontWeight: "400" }}
                            name="btn_signup"
                            disabled={
                                this.state.password.trim() !== "" &&
                                this.state.confirmPassword.trim() !== "" &&
                                this.state.activationCode.trim() !== ""
                                    ? false
                                    : true
                            }
                            onClick={this.handleSubmitBtn}
                        >
                            SUBMIT
                        </button>
                    </div>
                    <div className="w-100 doCenter">
                        <span
                            id="btn_updatePassword_resendMail"
                            className="linkBtn"
                            onClick={this.handleSubmitResendBtn}
                            disabled={
                                this.userEmail !== undefined &&
                                this.userEmail !== ""
                                    ? false
                                    : true
                            }
                        >
                            Didn’t recieve an email? Click here to try again
                        </span>
                        {/* <input
                            type="button"
                            className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder"
                            style={{
                                height: "40px",
                                marginTop: "45px",
                                letterSpacing: "1.25px",
                            }}
                            disabled={
                                this.userEmail !== undefined &&
                                this.userEmail !== ""
                                    ? false
                                    : true
                            }
                            onClick={this.handleSubmitResendBtn}
                            value="Didn’t Recieve an Email? Click here to try again"
                        ></input> */}
                    </div>
                </div>
                <div className="col"></div>
                {this.renderAlertModal()}
                {this.renderSnackBar()}
            </div>
        );
    }
}

export default UpdatePassword;
