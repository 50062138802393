import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setActiveBreadcrumPath,
    setActiveFolderPath,
    setActivePage,
    setActiveSpace,
    setActiveWorkspace,
    setArchiveInfo,
    setBoardList,
    setCollabInfo,
    setCurrentClickedSpaceIndex,
    setCurrentSettingsPage,
    setDocumentMetaData,
    setDocumentsforUpload,
    setFilesforUpload,
    setGenericInfo,
    setGmailManager,
    setJiraManager,
    setLeftPanelView,
    setPage,
    setPageType,
    setRightPanelView,
    setSpaceManager,
    setAlertManager,
    setAlertsWidget,
    setWorkspaceManager,
    updateArchiveInfoAlert,
} from "../../../../../../../actions";
import Canvas from "../../../../../../../bae-client/Canvas";
import CollaborationFetcher from "../../../../../../../bae-client/CollaborationFetcher";
import http from "../../../../../../../bae-client/HTTP";
import Space from "../../../../../../../bae-client/Space";
import config from "../../../../../../../config";
import AlertDialog from "../../../../../../stylized/AlertDialog";
import BreadcumView from "../../../../../../stylized/BreadcumView";
import DialogView from "../../../../../../stylized/DialogView";
import DocViwer from "../../../../../../stylized/DocViwer";
import Loader from "../../../../../../stylized/Loader";
import ModalView from "../../../../../../stylized/ModalView";
import TableView from "../../../../../../stylized/TableView";
import WorkflowStepper from "../../../../WorkflowStepper";
import NewFolderModal from "./NewFolderModal";
import { getParsedDateByDateAndTime } from "../../../../../../../bae-client/Utils";
import { getDateByFormat } from "../../../../../../../bae-client/Utils";
import * as Mixpanel from "../../../../../../../mix-panel/Mixpanel";
class SpaceTableHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headers: null,
            rows: null,
            dataLoaded: false,
            rowForDelete: null,
            spaceOpen: false,
            showLoader: false,
            showModal: false,
            showFolderModal: false,
            showAddNewDocPopover: false,
            currentShowFileName: "",
            activeFolder: "",
            newFolderName: "",
            folderPath: [],
            breadcumPathValues: {},
            showGenericInfoModal: false,
            documentMetaData: null,
            showAlertModal: false,
        };
        this.documentManager = null;
        this.canvas = new Canvas();
        this.space = new Space();
        this.refs = React.createRef();
        const nhid = http.getNHId();
        this.collabFetcher = new CollaborationFetcher(nhid);
        this.fetcher = this.props.collabInfo;
        this.title = "";
        this.breadcumPathValues = new Map();

        this.sourceCollabName = config.documentCollaborationName;
        this.sourceWBName = ";";
        this.sourceCollabId = -1;
        this.sourceWBId = -1;
        this.chosenFile = null;
        this.spaceName = "";
        this.activeFolder = "";

        this.sepretor = String.fromCharCode(1);
    }
    componentDidMount() {
        this.headCells = [
            {
                id: "Type",
                numeric: true,
                disablePadding: true,
                width: 60,
                label: "Type",
                align: "left",
            },
            {
                id: "name",
                numeric: false,
                disablePadding: true,
                width: 140,
                label: "Name",
                align: "left",
            },
            {
                id: "addedToSpace",
                numeric: true,
                disablePadding: true,
                width: 130,
                label: "Added to space",
                align: "center",
            },
            {
                id: "actions",
                numeric: true,
                disablePadding: true,
                width: 70,
                label: "",
                align: "center",
            },
        ];
        this.renderSpaceArea();
    }
    componentWillUnmount() {
        // this.props.setPageType("workspace");
        this.props.setActiveBreadcrumPath(this.breadcumPathValues);
        this.props.setCollabInfo(this.fetcher);
    }
    componentDidUpdate(prevProp, prevState) {
        if (prevProp.activeSpace === null) {
            this.renderSpaceArea();
        }
        if (this.props.activeSpace !== null && prevProp.activeSpace !== null) {
            if (
                prevProp.activeSpace.spaceName !==
                this.props.activeSpace.spaceName
            ) {
                this.renderSpaceArea();
            }
            if (
                prevProp.activeSpace.spaceId !== this.props.activeSpace.spaceId
            ) {
                this.renderSpaceArea();
            }
        }
    }
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    addNewDocument = () => {
        this.setState({ showAddNewDocPopover: true });
        this.refs.tableFileUplode.value = null;
        this.refs.tableFileUplode.click();
    };
    renderControlTowerForm = () => {
        //return <ControlTowerPopUp onHide={this.closeModal} documentMetaData={this.state.documentMetaData}></ControlTowerPopUp>
        // return <ControlTowerPopUpNew onHide={this.closeModal} documentMetaData={this.state.documentMetaData}
        //     ></ControlTowerPopUpNew>
        // return <ControlTowerPopUpTabs onHide={this.closeModal} documentMetaData={this.state.documentMetaData}></ControlTowerPopUpTabs>
        return (
            <WorkflowStepper
                onHide={this.closeModal}
                documentMetaData={this.state.documentMetaData}
            ></WorkflowStepper>
        );
    };
    renderGenericInfoModal = () => {
        return (
            // <ModalView
            //     showHeader="false"
            //     footer="false"
            //     show={this.state.showGenericInfoModal}
            //     size="lg"
            //     onHide={this.closeModal}
            //     title=""
            //     //onclick={this.closeModal}
            //     submitButtonText="Close"
            // >
            //     {this.renderControlTowerForm()}
            // </ModalView>
            <DialogView
                show={this.state.showGenericInfoModal}
                size="lg"
                handleClose={this.closeModal}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
            >
                {this.renderControlTowerForm()}
            </DialogView>
        );
    };
    updateTableData = (spaceId) => {
        this.setState({ Loader: true });
        return new Promise((resolve, reject) => {
            this.spaceManager
                .getPersistanceDataStructureBySpaceId(parseInt(spaceId))
                .then((resp) => {
                    this.activeSpaceDocuments = resp;
                    this.persistanceDataStructure =
                        this.spaceManager.getPersistanceDataStructure();
                    this.activeFolderData =
                        this.activeSpaceDocuments !== undefined
                            ? this.activeSpaceDocuments
                            : {};
                    this.changeCurrentActiveFolderByBreadcum(
                        this.props.activeFolderPath,
                        this.activeFolder
                    );
                    let val = this.generateTableData(this.activeFolderData);
                    resolve(val);
                    this.setState({ Loader: false });
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                    this.setState({ Loader: false });
                });
        });
    };

    addNewFolder = () => {
        if (this.state.newFolderName.trim().length > 0) {
            this.setState({ showLoader: true, showFolderModal: false });
            this.spaceManager = this.props.spaceManager;
            let tempParentId = this.getLastParentId();
            let spaceId = this.space.getSpaceId();
            let parentId =
                parseInt(tempParentId) !== parseInt(spaceId)
                    ? tempParentId
                    : "0";
            let data = {
                spaceId: this.space.getSpaceId(),
                objectName: this.state.newFolderName.trim(),
                parentRowId: parentId,
            };
            this.spaceManager
                .insertNewObject(data)
                .then((resp) => {
                    this.updateTableData(spaceId)
                        .then((resp) => {
                            this.setState({
                                rows: resp,
                                showLoader: false,
                            });
                        })
                        .catch((err) => {
                            this.showAlertView(
                                "",
                                err && err.error
                                    ? err.path
                                    : "Something went wrong, please try again!"
                            );
                            console.log(err);
                            this.setState({ showLoader: false });
                        });
                })
                .catch((errorMsg) => {
                    this.showAlertView(
                        "",
                        errorMsg && errorMsg.error
                            ? errorMsg.path
                            : "Something went wrong, please try again!"
                    );
                    console.log(errorMsg);
                    // alert("Folder name already exists");
                    this.showAlertView("", "Folder name already exists");
                    this.setState({ showLoader: false });
                });
        } else {
            // alert("Invalid folder name");
            this.showAlertView("", "Invalid folder name");
        }
    };
    uploadDocumentsInSpace = () => {
        let spaceId = this.space.getSpaceId();
        let fileName = this.chosenFile[0].name;
        this.setState({ showLoader: true });
        let documentId = -1;
        let documentHeaderId = -1;
        let tempParentId = this.getLastParentId();
        let parentId =
            parseInt(tempParentId) !== parseInt(spaceId) ? tempParentId : "0";
        const data = new FormData();
        data.append("file", this.chosenFile[0]);
        http.uploadFile(data)
            .then((response) => {
                if (response.status === 200) {
                    Mixpanel.callFileManagementEvent("Upload");

                    this.setState({
                        showLoader: true,
                    });
                    let dataModal = response.data;
                    if (dataModal !== null && dataModal !== "") {
                        documentId = dataModal.documentCuboidId;
                        documentHeaderId = dataModal.headerCuboidId;
                        this.spaceManager = this.props.spaceManager;
                        let data = {
                            spaceId: spaceId,
                            objectId: documentId,
                            objectName: fileName,
                            parentRowId: parentId,
                        };
                        if (documentId > 0) {
                            this.spaceManager
                                .insertNewObject(data)
                                .then((res) => {
                                    this.updateTableData(spaceId)
                                        .then((resp) => {
                                            this.setState({
                                                rows: resp,
                                                showLoader: false,
                                            });
                                        })
                                        .catch((err) => {
                                            this.showAlertView(
                                                "",
                                                err && err.error
                                                    ? err.path
                                                    : "Something went wrong, please try again!"
                                            );
                                            console.log(err);
                                            this.setState({
                                                showLoader: false,
                                            });
                                        });
                                })
                                .catch((err) => {
                                    this.showAlertView(
                                        "",
                                        err && err.error
                                            ? err.path
                                            : "Something went wrong, please try again!"
                                    );
                                    console.log(err);
                                    // alert("Document already exists");
                                    this.showAlertView(
                                        "",
                                        "Document already exists"
                                    );

                                    this.setState({ showLoader: false });
                                });
                        }
                    }
                } else {
                    // alert("Some server side issue");
                    this.showAlertView("", "Some server side issue");
                    this.setState({ showLoader: false });
                }
            })
            .catch((err) => {
                this.showAlertView(
                    "",
                    err && err.error
                        ? err.path
                        : "Something went wrong, please try again!"
                );
                console.log(err);
                if (err === 500) {
                    // alert("Invalid file type");
                    this.showAlertView("", "Invalid file type");
                } else {
                    this.showAlertView("", "Document already exists");
                    // alert("Document already exists");
                }
                this.setState({ showLoader: false });
            });
    };
    onChangeFileUpload = (event) => {
        let formFileData = event.target.files;
        this.chosenFile = formFileData;
        if (formFileData.length > 0) {
            this.uploadDocumentsInSpace();
        }
    };

    getLastParentId = () => {
        return this.props.activeFolderPath[
            this.props.activeFolderPath.length - 1
        ];
    };

    isValidFolderName = (folderName) => {
        let invalitPattern = /[\/:"*?<>|]/g;
        let spaceName = this.props.activeSpace.spaceName;
        let flag = true;
        if (invalitPattern.test(folderName)) {
            flag = false;
            // alert("A folder name can't contain following character : /:*?<>|");
            this.showAlertView(
                "",
                "A folder name can't contain following character : /:*?<>|"
            );
        }
        // else if (folderName === spaceName) {
        //     alert("folder name should diffrent by space name");
        //     flag = false;
        // }
        return flag;
        //return false if pattern matches
    };
    setText = (value, id, event) => {
        switch (id) {
            case "newFolderName":
                {
                    if (this.isValidFolderName(value)) {
                        this.setState({ newFolderName: value });
                    } else {
                        this.setState({ newFolderName: "" });
                    }
                }
                break;
            default:
                break;
        }
    };
    showFolderModal = () => {
        this.setState({ showFolderModal: true, newFolderName: "" });
    };

    handleCheckboxClick = (e, index, id, rowData) => {
        const checkValue = e.target.checked;
        let selectedRowData = rowData;
    };
    deleteRow = (selectedDocIds) => {
        if (selectedDocIds !== null && selectedDocIds.length > 0) {
            this.setState({ showLoader: true });
            let spaceId = this.space.getSpaceId();
            this.spaceManager = this.props.spaceManager;
            this.spaceManager
                .deleteDocumentRowById(selectedDocIds)
                .then((resp) => {
                    this.updateTableData(spaceId)
                        .then((resp) => {
                            this.setState({
                                rows: resp,
                                showLoader: false,
                            });
                        })
                        .catch((err) => {
                            this.showAlertView(
                                "",
                                err && err.error
                                    ? err.path
                                    : "Something went wrong, please try again!"
                            );
                            console.log(err);
                            this.setState({ showLoader: false });
                        });
                })
                .catch((err) => {
                    this.showAlertView(
                        "",
                        err && err.error
                            ? err.path
                            : "Something went wrong, please try again!"
                    );
                    console.log(err);
                    this.setState({ showLoader: false });
                });
        }
    };
    createData(id, type, name, time, btn) {
        return { id, type, name, time, btn };
    }
    getDocType = (docName) => {
        if (docName !== undefined) {
            let docType = docName.split(".");
            return (docType = docType[docType.length - 1]);
        }
    };
    generateTableData = (object) => {
        // logic for generating table data
        let rowsData = [];
        if (object !== null && object !== undefined) {
            let Keys = Object.keys(object);
            Keys.forEach((key, index) => {
                let txDate = "Loading";
                let id = key;
                let name = object[key].objectName;
                let documentId = object[key].documentId;
                let txDateAfterCheck = getParsedDateByDateAndTime(
                    object[key].createdOn
                );
                let dataType = documentId > 0 ? "file" : "folder";
                let type =
                    dataType === "file" ? this.getDocType(name) : "folder";
                let btnName = dataType === "folder" ? "Open" : "View";
                rowsData.push(
                    this.createData(id, type, name, txDateAfterCheck, btnName)
                );
            });
        }
        return rowsData;
    };
    setDefualtDataByActiveBreadcrumPath = () => {
        if (!this.props.activeFolderPath) {
            const paths = this.props.activeFolderPath;
            this.activeFolderData = paths.reduce((object, path, index) => {
                if (index === 0) {
                    return (object || {})[path];
                } else {
                    return (object || {})[path].children;
                }
            }, this.persistanceDataStructure);
        }
    };
    setDefualtFolderPath = () => {
        let oldFolderPath = this.props.activeFolderPath;
        let spaceId = this.props.activeSpace.spaceId.toString();
        let folderPath = [];
        if (oldFolderPath.length > 0) {
            if (oldFolderPath.includes(spaceId)) {
                folderPath = oldFolderPath;
            } else {
                folderPath.push(spaceId); // put only if space id is changed
            }
        } else {
            folderPath.push(spaceId);
        }
        this.breadcumPathValues.set(
            spaceId + this.sepretor + this.props.activeSpace.spaceName,
            folderPath
        );
        this.props.setActiveFolderPath(folderPath);
    };
    renderSpaceArea = () => {
        this.setState({ showLoader: true });
        let docArray = [];
        let spaceManager = this.props.spaceManager;
        this.breadcumPathValues = new Map();
        spaceManager
            .getPersistanceDataStructureBySpaceId(
                this.props.activeSpace.spaceId
            )
            .then((data) => {
                this.activeSpaceDocuments = data;
                this.persistanceDataStructure =
                    spaceManager.getPersistanceDataStructure();
                this.activeFolderData =
                    this.activeSpaceDocuments !== undefined
                        ? this.activeSpaceDocuments
                        : {};
                this.setDefualtFolderPath();
                this.setDefualtDataByActiveBreadcrumPath();
                this.activeFolder =
                    this.props.activeSpace.spaceId +
                    this.sepretor +
                    this.props.activeSpace.spaceName;
                if (this.props.activeSpace) {
                    this.space.setData(this.props.activeSpace);
                    this.title = this.space.getSpaceName();
                    let val = this.generateTableData(this.activeFolderData);
                    this.setState({
                        dataLoaded: true,
                        rows: val,
                        showLoader: false,
                    });
                    return docArray;
                } else {
                    this.setState({ dataLoaded: false, showLoader: false });
                }
            });
    };
    generateBreadcumPath = (folderName) => {
        let oldBreadcumPathValues = this.breadcumPathValues;
        this.breadcumPathValues = new Map();
        let path = Array.from(oldBreadcumPathValues.keys());
        for (let i = 0; i < path.length; i++) {
            this.breadcumPathValues.set(
                path[i],
                oldBreadcumPathValues.get(path[i])
            );
            if (path[i] === folderName) {
                break;
            }
        }
        this.props.setActiveBreadcrumPath(this.breadcumPathValues);
    };
    generateBreadcumString = () => {
        let path = Array.from(this.breadcumPathValues.keys());
        let stringArray = [];
        for (let i = 1; i < path.length; i++) {
            stringArray.push(path[i].split(this.sepretor)[1]);
        }
        return stringArray.join("|");
    };

    changeCurrentActiveFolderByBreadcum = (pathArray, folderName) => {
        this.setState({ showLoader: true });
        const paths = pathArray;
        this.activeFolderData = paths.reduce((object, path, index) => {
            if (index === 0) {
                return (object || {})[path];
            } else if ((object || {})[path].children !== undefined) {
                return (object || {})[path].children;
            }
        }, this.persistanceDataStructure);

        let folderPath = pathArray;
        this.activeFolder = folderName;
        this.breadcumPathValues.set(folderName, folderPath);
        this.generateBreadcumPath(folderName);

        let val = this.generateTableData(this.activeFolderData);
        this.props.setActiveFolderPath(folderPath);
        this.setState({
            dataLoaded: true,
            rows: val,
            breadcumPathValues: this.breadcumPathValues,
            showLoader: false,
        });
    };
    changeCurruntActiveFolder = (itemId, itemName) => {
        this.setState({ showLoader: true });
        if (this.activeFolderData[itemId] !== undefined) {
            let folderPath = [];
            let tableRawData = null;
            folderPath = folderPath.concat(this.props.activeFolderPath);
            if (folderPath.indexOf(itemId.toString()) === -1) {
                folderPath.push(itemId.toString());
            }
            this.activeFolder =
                itemId +
                this.sepretor +
                this.activeFolderData[itemId].objectName;
            this.breadcumPathValues.set(
                itemId +
                    this.sepretor +
                    this.activeFolderData[itemId].objectName,
                folderPath
            );
            if (this.activeFolderData[itemId].children !== undefined) {
                this.activeFolderData = this.activeFolderData[itemId].children;
                tableRawData = this.generateTableData(this.activeFolderData);
            } else {
                this.activeFolderData = {};
                tableRawData = [];
            }
            this.props.setActiveFolderPath(folderPath);
            this.setState({
                dataLoaded: true,
                rows: tableRawData,
                showLoader: false,
            });
        }
    };
    renderViewDocumentModal = (event) => {
        // document Viewer component to show documents
        return <DocViwer docName={this.state.currentShowFileName}></DocViwer>;
    };
    showModalView = (event, index, fileName, row) => {
        if (row.type === "folder") {
            this.changeCurruntActiveFolder(row.id, fileName);
        } else {
            this.setState({
                showModal: true,
                currentShowFileName: fileName,
            });
        }
    };
    closeModal = () => {
        this.setState({
            showModal: false,
            showFolderModal: false,
            showGenericInfoModal: false,
        });
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    renderBreadcum = () => {
        return (
            <BreadcumView
                items={this.breadcumPathValues}
                sepretor={this.sepretor}
                onChangeFolder={this.changeCurrentActiveFolderByBreadcum}
            ></BreadcumView>
        );
    };
    render() {
        return (
            <div className="spaceTableDiv">
                {this.state.dataLoaded === true ? (
                    <TableView
                        title={this.title}
                        breadcumView={this.renderBreadcum()}
                        rows={this.state.rows}
                        activeFolder={this.activeFolder}
                        showModal={this.showModalView}
                        columns={this.headCells}
                        handleClick={this.handleCheckboxClick}
                        addNewDocument={this.addNewDocument}
                        showFolderModal={this.showFolderModal}
                        deleteRow={(e, index) => this.deleteRow(e, index)}
                    ></TableView>
                ) : null}
                <input
                    type="file"
                    id="file"
                    ref={"tableFileUplode"}
                    hidden={true}
                    onChange={this.onChangeFileUpload}
                    multiple
                />
                <ModalView
                    showHeader="false"
                    footer="false"
                    show={this.state.showModal}
                    size="lg"
                    onHide={this.closeModal}
                    title=""
                    modalBodyClass="documentViewModal"
                    onclick={this.closeModal}
                    submitButtonText="Close"
                >
                    {this.renderViewDocumentModal()}
                </ModalView>
                {/* {this.renderModalForNewFolder()} */}
                <NewFolderModal
                    showFolderModal={this.state.showFolderModal}
                    closeModal={this.closeModal}
                    setText={this.setText}
                    newFolderName={this.state.newFolderName}
                    addNewFolder={this.addNewFolder}
                />
                {this.renderGenericInfoModal()}
                {this.renderAlertModal()}
                <Loader show={this.state.showLoader}></Loader>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        currentPage: state.currentPage,
        leftPanel: state.leftPanel,
        rightPanel: state.rightPanel,
        boardList: state.boardList,
        activeBoard: state.activeBoard,
        activeSpace: state.activeSpace,
        activeFolderPath: state.activeFolderPath,
        spaceForUpload: state.spaceForUpload,
        spaceManager: state.spaceManager,
        workspaceManager: state.workspaceManager,
        currentClickedSpaceIndex: state.currentClickedSpaceIndex,
        currentPageType: state.currentPageType,
        activeWorkspace: state.activeWorkspace,
        workspaceFetcher: state.workspaceFetcher,
        archiveInfo: state.archiveInfo,
        breadcrumPath: state.breadcrumPath,
    };
};

const mapDispatchToProps = () => {
    return {
        setActiveBreadcrumPath,
        setDocumentsforUpload,
        setPageType,
        setActiveFolderPath,
        setFilesforUpload,
        setCollabInfo,
        setPage,
        setActiveSpace,
        setLeftPanelView,
        setRightPanelView,
        setSpaceManager,
        setBoardList,
        setCurrentSettingsPage,
        setJiraManager,
        setGmailManager,
        setAlertsWidget,
        setAlertManager,
        setCurrentClickedSpaceIndex,
        setActiveWorkspace,
        setWorkspaceManager,
        setActivePage,
        updateArchiveInfoAlert,
        setArchiveInfo,
        setGenericInfo,
        setDocumentMetaData,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(SpaceTableHandler);
