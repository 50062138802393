import { useEffect, useState } from "react";
import http from "../../../../../../bae-client/HTTP";
import ModalView from "../../../../../stylized/ModalView";
import InstanceStep from "../instance-step";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import Loader from "../../../../../stylized/Loader";

const MergeInstanceSteps = ({
    showMergeInstanceStepsModal,
    selectedInstanceSteps,
    genericId,
    instanceId,
    selectedInstance,
    fetchInstanceData,
    closeModal,
}) => {
    const [selectedStepsToMerge, setSelectedStepsToMerge] = useState([]);
    const [stepIdToBeMergedInto, setStepIdToBeMergedInto] = useState(0);
    const [submitButtonText, setSubmitButtonText] = useState("Next");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [branchNameToStepDataMap, setBranchNameToStepDataMap] = useState({});
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {}, [stepIdToBeMergedInto]);

    const handleCloseOrCancel = () => {
        setSubmitButtonText("Next");
        setBranchNameToStepDataMap({});
        setSelectedStepsToMerge([]);
        setStepIdToBeMergedInto(0);
        closeModal();
    };

    const handleSubmit = () => {
        if (submitButtonText === "Next") {
            setSubmitButtonText("Merge");
        } else if (submitButtonText === "Merge") {
            mergeInstanceSteps();
        }
    };

    const mergeInstanceSteps = () => {
        let sourceSteps = selectedStepsToMerge.toString();
        let targetStep = stepIdToBeMergedInto.toString();
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/instance/step/merge?genericId=${genericId}&instanceId=${instanceId}&sourceInstanceStepIds=${sourceSteps}&targetInstanceStepId=${targetStep}`,
            "PUT"
        )
            .then((response) => {
                closeModal();
                fetchInstanceData(selectedInstance);
                setSelectedStepsToMerge([]);
                setStepIdToBeMergedInto(0);
                setSubmitButtonText("Next");
                setSnackbarMessage("Steps merged successfully!");
                setShowSnackbar(true);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setSelectedStepsToMerge([]);
                setStepIdToBeMergedInto(0);
                setSnackbarMessage("Step merge failed!");
                setShowSnackbar(true);
                setSubmitButtonText("Next");
                setShowLoader(false);
            });
    };

    const [possibleTargetSteps, setPossibleTargetSteps] = useState([]);
    const handleSourceRadioClick = (
        event,
        stepId,
        stepIdentifier,
        branchName
    ) => {
        branchNameToStepDataMap[branchName] = {
            stepId: stepId,
            stepIdentifier: stepIdentifier,
        };
        let sourceStepIds = Object.values(branchNameToStepDataMap).map(
            (item) => item.stepId
        );
        setSelectedStepsToMerge(sourceStepIds);
        let selectedStepIdentifiers = Object.values(
            branchNameToStepDataMap
        ).map((item) => item.stepIdentifier);
        let stepIdentifiersOfGeneric = [];
        selectedInstanceSteps.target[0].forEach((step) => {
            stepIdentifiersOfGeneric.push(step.instanceUniqueStepIdentifier);
        });
        let heighestIndex = 0;
        let heighestStepIdentifier = "";
        selectedStepIdentifiers.forEach((selectedStepIdentifier) => {
            if (
                stepIdentifiersOfGeneric.indexOf(selectedStepIdentifier) >=
                heighestIndex
            ) {
                heighestStepIdentifier = selectedStepIdentifier;
                heighestIndex = stepIdentifiersOfGeneric.indexOf(
                    selectedStepIdentifier
                );
            }
        });
        let targetSteps = [];
        selectedInstanceSteps.target.forEach((branchSteps) => {
            let index = branchSteps
                .map((item) => item.instanceUniqueStepIdentifier)
                .indexOf(heighestStepIdentifier);
            let targetStepsOfBranch = [];
            if (index !== -1) {
                targetStepsOfBranch = branchSteps.slice(
                    index + 1,
                    branchSteps.length
                );
            } else {
                if (
                    stepIdentifiersOfGeneric.indexOf(
                        branchSteps[0].instanceUniqueStepIdentifier
                    ) < stepIdentifiersOfGeneric.indexOf(heighestStepIdentifier)
                ) {
                    targetStepsOfBranch = [];
                } else {
                    targetStepsOfBranch = branchSteps;
                }
            }
            targetSteps = [...targetSteps, ...targetStepsOfBranch];
        });
        // targetSteps = targetSteps.filter(
        //     (step) =>
        //         step.durationStatus === "PENDING" ||
        //         step.instanceBlockDocumentName
        // );
        setPossibleTargetSteps(targetSteps);
    };

    const handleTargetRadioClick = (event, stepId) => {
        setStepIdToBeMergedInto(stepId);
    };
    return (
        <>
            <ModalView
                showHeader="false"
                footer="false"
                show={showMergeInstanceStepsModal}
                size="md"
                onHide={handleCloseOrCancel}
                title={submitButtonText}
                setSubmitButtonText={setSubmitButtonText}
                id="btn_folder_submit"
                onSubmit={handleSubmit}
                value="Name"
                onclick={closeModal}
                type="submit-cancel"
                submitButtonText="Close"
                disable={
                    (selectedStepsToMerge.length >= 2 &&
                        submitButtonText === "Next") ||
                    stepIdToBeMergedInto !== 0
                        ? "false"
                        : ""
                }
                isMergeModal={submitButtonText === "Merge" && true}
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    {/* <img src="./images/Space 2.svg"></img> */}
                    <div
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "22px",
                            lineHeight: "27px",
                            color: "#404040",
                            width: "430px",
                            marginTop: "-32px",
                            marginBottom: "28px",
                            marginRight: "-9px",
                        }}
                    >
                        {submitButtonText === "Next"
                            ? "Select Source Steps"
                            : "Select Target Step"}
                    </div>
                    <div
                        className="cardDiv invisibleScroller"
                        style={{
                            padding: "16px",
                            height: "60vh",
                            width: "85%",
                        }}
                    >
                        {submitButtonText === "Next"
                            ? selectedInstanceSteps.sources.map(
                                  (item, index) => (
                                      <>
                                          <InstanceStep
                                              instanceBlockDocumentName={
                                                  item.instanceBlockDocumentName
                                              }
                                              durationStatus={
                                                  item.durationStatus
                                              }
                                              key={item.genericStepId}
                                              instanceDetails={item}
                                              steps={
                                                  selectedInstanceSteps.sources
                                              }
                                              genericId={genericId}
                                              previousStep={
                                                  selectedInstanceSteps.sources[
                                                      index - 1
                                                  ]
                                              }
                                              instanceSteps={
                                                  selectedInstanceSteps.sources
                                              }
                                              index={index}
                                              isMergeModal={true}
                                              handleSourceRadioClick={
                                                  handleSourceRadioClick
                                              }
                                              handleTargetRadioClick={
                                                  handleTargetRadioClick
                                              }
                                              action={submitButtonText}
                                          ></InstanceStep>
                                          {index ===
                                          selectedInstanceSteps.sources.length -
                                              1 ? null : (
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      position: "relative",
                                                      visibility: "hidden",
                                                  }}
                                              >
                                                  <img
                                                      src="./images/Arrow 7.png"
                                                      alt="arrow"
                                                  />
                                              </div>
                                          )}
                                      </>
                                  )
                              )
                            : possibleTargetSteps.map((item, index) => (
                                  <>
                                      <InstanceStep
                                          instanceBlockDocumentName={
                                              item.instanceBlockDocumentName
                                          }
                                          durationStatus={item.durationStatus}
                                          key={item.genericStepId}
                                          instanceDetails={item}
                                          steps={possibleTargetSteps}
                                          genericId={genericId}
                                          previousStep={
                                              possibleTargetSteps[index - 1]
                                          }
                                          instanceSteps={possibleTargetSteps}
                                          index={index}
                                          isMergeModal={true}
                                          handleSourceRadioClick={
                                              handleSourceRadioClick
                                          }
                                          handleTargetRadioClick={
                                              handleTargetRadioClick
                                          }
                                          action={submitButtonText}
                                      ></InstanceStep>
                                      {index ===
                                      possibleTargetSteps.length - 1 ? null : (
                                          <div
                                              style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  position: "relative",
                                                  visibility: "hidden",
                                              }}
                                          >
                                              <img
                                                  src="./images/Arrow 7.png"
                                                  alt="arrow"
                                              />
                                          </div>
                                      )}
                                  </>
                              ))}
                    </div>
                </div>
            </ModalView>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <Loader show={showLoader}></Loader>
        </>
    );
};
export default MergeInstanceSteps;
