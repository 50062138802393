import http from "../bae-client/HTTP";

export const reloadIntegrations = (message) => {
    return {
        type: "RELOAD_INTEGRATIONS",
        payload: message,
    };
};

export const fetchIntegrations = () => {
    return async (dispatch) => {
        const response = await http.sendRestRequest(
            "integration/integrationList"
        );
        dispatch({
            type: "LOAD_INTEGRATIONS",
            payload: response,
        });
    };
};

export const setIntegrations = (payload) => {
    return {
        type: "LOAD_INTEGRATIONS",
        payload: payload,
    };
};
