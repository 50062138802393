import config from '../config'
import http from './HTTP';

class UserProfile {
    constructor(userEntry) {
        this.data = userEntry;
    }
    getId() {
        return this.data.userId;
    }

    getFirstName() {
        return ((this.data.firstName) ? this.data.firstName : "");
    }

    getLastName() {
        return ((this.data.lastName) ? this.data.lastName : "");
    }

    getFullName() {
        return ((this.data.firstName || this.data.lastName) ? `${this.getFirstName()} ${this.getLastName()}`.trim() : "");
    }

    getUserEmail() {
        return ((this.data.userEmail) ? this.data.userEmail : "");
    }

    getProfilePicture() {
        return ((this.data.profilePicture) ? this.data.profilePicture : "");
    }

    getCompanyName() {
        return ((this.data.companyName) ? this.data.companyName : "");
    }

    getDepartmentName() {
        return ((this.data.departmentName) ? this.data.departmentName : "");
    }

}

export default UserProfile;