import React, { useEffect } from "react";

import { validateName } from "../../../../../../../bae-client/Validations";
import config from "../../../../../../../config";
import ModalView from "../../../../../../stylized/ModalView";
import TextBox from "../../../../../../stylized/TextBox";

export default function RenameDocumentChainModal({
    showRenameDocumentChainModal,
    id,
    // genericName,
    closeModal,
    selectedRow,
    heading,
    list,
    addNewDocumentChain,
    renameDocumentChain,
}) {
    const [updatedName, setupdatedName] = React.useState("");
    const [updatedRename, setUpdateRename] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() => {
        setUpdateRename(selectedRow.docuChain);
        setupdatedName(selectedRow.genericDisplayName);
    }, [selectedRow]);
    const handleSubmit = () => {
        if (errMsg || errorMessage) {
            return;
        }
        let genricId = selectedRow.genericId;
        const isValidName = validateName(updatedName);
        if (isValidName === true) {
            const genericDisplayNames = list.map((genericName) =>
                genericName.genericDisplayName.toUpperCase().trim()
            );
            const genericNames = list.map((genericName) =>
                genericName.genericName.toUpperCase().trim()
            );

            if (!updatedRename) {
                setErrMsg("Name cannot be empty");
                return;
            }
            if (updatedRename !== selectedRow.docuChain) {
                if (genericNames.includes(updatedRename.trim().toUpperCase())) {
                    setErrMsg("Flow Name Already Exist");
                    return;
                }
            }
            if (!updatedName) {
                setErrorMessage("Name cannot be empty");
                return;
            }
            if (updatedName !== selectedRow.genericDisplayName) {
                if (
                    genericDisplayNames.includes(
                        updatedName.trim().toUpperCase()
                    )
                ) {
                    setErrorMessage("Display Name Already Exist");
                    return;
                }
            }
            if (
                updatedName !== selectedRow.genericDisplayName ||
                updatedRename !== selectedRow.docuChain
            ) {
                renameDocumentChain(genricId, updatedRename, updatedName);
                setErrMsg("");
                setErrorMessage("");
            }
        } else {
            setErrorMessage(isValidName);
        }
    };
    const handleCloseModal = () => {
        setErrorMessage("");
        closeModal();
    };
    //     const genericNames = list.map((genericName) =>
    //         genericName.genericName.toUpperCase().trim()
    //     );
    //     if (genericNames.includes(name.trim().toUpperCase()))
    //         setErrorMessage("Flow Name Already Exist");
    //     else if (!name) setErrorMessage("Name cannot be empty");
    //     else addNewDocumentChain(name);
    // };
    const onGenericChange = (value) => {
        let regex = "^[A-Za-z0-9_ -]*$";
        if(value.length > 100 || !Boolean(value.match(regex))) {
            setErrorMessage(
                "Only letters and numbers upto 100 characters are allowed"
            );
        } else {
            setErrorMessage("");
        }
        
        setUpdateRename(value);
    };
    const onGenericNameBlur = (e) => {
        let value = e.target.value;
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length <= 100 && Boolean(value.match(regex))) {
            setErrorMessage("");
        }
    };
    const onChange = (value) => {
        let regex = "^[A-Za-z0-9_ -]*$";
        if(value.length > 100 || !Boolean(value.match(regex))) {
            setErrorMessage(
                "Only letters and numbers upto 100 characters are allowed"
            );
        } else {
            setErrorMessage("");
        }
        setupdatedName(value);
    };
    const onGenericDisplayNameBlur = (e) => {
        let value = e.target.value;
        let regex = "^[A-Za-z0-9_ -]*$";
        if (value.length <= 100 && Boolean(value.match(regex))) {
            setErrorMessage("");
        }
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showRenameDocumentChainModal}
            size="md"
            onHide={handleCloseModal}
            title="Okay"
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            value="Name"
            onclick={handleCloseModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                {/* <img src="./images/Space 2.svg"></img> */}
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-32px",
                        marginBottom: "28px",
                        marginRight: "24px",
                    }}
                >
                    {heading}
                </div>
                {config.editable(sessionStorage.user) && <><div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "100",
                        fontSize: "14px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",

                        marginRight: "-9px",
                    }}
                >
                    Generic Name
                </div>
                <TextBox
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    value={updatedRename}
                    style={{ marginTop: "-20px" }}
                    label="Name"
                    formControl="Off"
                    doCenter={true}
                    title="Generic Name"
                    onchange={onGenericChange}
                    onfocusout={onGenericNameBlur}
                    disabled={!config.editable(sessionStorage.user)}
                ></TextBox> </>}
            </div>
            {errMsg && (
                <>
                    <small
                        id={"small_errMsg_flow"}
                        style={{
                            color: "red",
                            paddingLeft: "18px",
                            marginRight: "235px",
                            marginBottom: "-25px",
                        }}
                    >
                        {errMsg}
                    </small>
                    <br />
                </>
            )}
            <div
                className="row doCenter"
                style={{ flexDirection: "column", marginTop: "20px" }}
            >
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "100",
                        fontSize: "14px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",

                        marginRight: "-9px",
                    }}
                >
                    Generic Display Name
                </div>
                {/* <img src="./images/Space 2.svg"></img> */}
                <TextBox
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    value={updatedName}
                    style={{ marginTop: "-20px" }}
                    onchange={onChange}
                    onfocusout={onGenericDisplayNameBlur}
                    label="Name"
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>

            {errorMessage && (
                <>
                    <small style={{ color: "red", paddingLeft: "18px" }}>
                        {errorMessage}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
