import React, { Component } from "react";
import TextBox from "../../stylized/TextBox";

class InstanceTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
        };
        this.instanceBlockchainId = null;
        this.instanceId = null;
        this.instanceName = null;
        this.instanceBlockId = null;
        this.instanceBlockDocumentId = null;
        this.instanceBlockDocumentName = null;
    }
    setInstanceFormData = (response) => {
        Object.keys(response).map((key) => {
            switch (key) {
                case "instanceBlockchainId":
                    {
                        this.instanceBlockchainId = response[key];
                    }
                    break;
                case "instanceId":
                    {
                        this.instanceId = response[key];
                    }
                    break;
                case "instanceName":
                    {
                        this.instanceName = response[key];
                    }
                    break;
                case "instanceBlockId":
                    {
                        this.instanceBlockId = response[key];
                    }
                    break;
                case "instanceBlockDocumentId":
                    {
                        this.instanceBlockDocumentId = response[key];
                    }
                    break;
                case "instanceBlockDocumentName":
                    {
                        this.instanceBlockDocumentName = response[key];
                    }
                    break;
                default:
                    break;
            }
        });
    };
    componentDidMount = () => {
        this.setInstanceFormData(this.props.response);
        this.setState({ data: "updated" });
    };

    setFormText = (value, id, event) => {
        switch (id) {
            case "instanceBlockchainId":
                {
                    this.instanceBlockchainId = value;
                }
                break;
            case "instanceId":
                {
                    this.instanceId = value;
                }
                break;
            case "instanceName":
                {
                    this.instanceName = value;
                }
                break;
            case "instanceBlockId":
                {
                    this.instanceBlockId = value;
                }
                break;
            case "instanceBlockDocumentId":
                {
                    this.instanceBlockDocumentId = value;
                }
                break;
            case "instanceBlockDocumentName":
                {
                    this.instanceBlockDocumentName = value;
                }
                break;
            default:
                break;
        }
    };
    renderFormFields = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Blockchain Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceBlockchainId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceBlockchainId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceName"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceName}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Block Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceBlockId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceBlockId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">
                        Instance Block Document Id
                    </label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceBlockDocumentId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceBlockDocumentId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">
                        Instance Block Document Name
                    </label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="instanceBlockDocumentName"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.instanceBlockDocumentName}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>

                {/* <div className="marginTopTen doCenter">
                    <Button id="submitButton" label={"SUBMIT"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"REFRESH"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"BACK"} onclick={this.props.onclick} className="ctButton"></Button>
                </div> */}
            </div>
        );
    };
    render() {
        return <>{this.renderFormFields()}</>;
    }
}

export default InstanceTab;
