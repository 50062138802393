import React, {useState} from 'react'
import VideoTile from './videoTile'
import {
    useHMSStore, 
    useHMSActions,
    selectPeers,
    selectIsLocalAudioEnabled,
    selectIsLocalVideoEnabled,
    selectIsConnectedToRoom
  
} from "@100mslive/react-sdk"


export default function Conference({handleModalClose}) {
    const peers = useHMSStore(selectPeers)

    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();

    const muteIcon = "https://img.icons8.com/material-rounded/24/000000/mute.png";
    const unmuteIcon = "https://img.icons8.com/external-tal-revivo-bold-tal-revivo/24/000000/external-volume-mute-function-key-on-computer-keyboard-keyboard-bold-tal-revivo.png";
    const videoIcon = "https://img.icons8.com/ios-glyphs/24/000000/video-call.png"
    const unVideoIcon = "https://img.icons8.com/ios-filled/24/000000/no-video--v1.png"

    const [mediaStatus, setMediaStatus] = useState(true);
    const [videoStatus, setVideoStatus] = useState(true)
    const audioEnabled = useHMSStore(selectIsLocalAudioEnabled)
    const videoEnabled = useHMSStore(selectIsLocalVideoEnabled)

    const toggleAudio = async () =>{
        setMediaStatus(!mediaStatus)
        await hmsActions.setLocalAudioEnabled(!audioEnabled)
    }
    const toggleVideo = async () =>{
        setVideoStatus(!videoStatus)
        await hmsActions.setLocalVideoEnabled(!videoEnabled)

    }

    const handleLeaveRoom = () =>{
        if(isConnected) {
            hmsActions.leave();
            handleModalClose();
        }
    }


  return (
    <div style={{position: "relative", height: "100%"}}>
        <div id="videoRow" style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
          {peers.map(peer =>(
                <VideoTile handleModalClose={handleModalClose} key={peer.id} peer={peer} />
          ))}
        </div>
          <div className="func-btn" style={{position: "absolute", bottom: 0, right: 0}}>

            <button onClick={toggleVideo}>
                <img src={videoStatus ? videoIcon : unVideoIcon} alt="video media" />
            </button>
            <button onClick={toggleAudio}>
                <img src={mediaStatus  ? unmuteIcon : muteIcon} alt="mute"/>

            </button>
            <button onClick={handleLeaveRoom}>
            <img src="https://img.icons8.com/color/24/000000/end-call.png" alt="cut call"/>
            </button>

          </div>
    </div>
  )
}