import React, { useState, useEffect } from "react";
import {
    validateName,
    validateNameLength,
} from "../../../../../../bae-client/Utils";
import { validateWorkspaceUserName } from "../../../../../../bae-client/Validations";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";

const RenameWorkspace = ({
    modalShow,
    handleCloseModal,
    submitRenameWorkspace,
    workspace,
    workspaces,
}) => {
    const [name, setName] = useState(workspace ? workspace.workspaceName : "");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (workspace) {
            setErrorMessage("");
            setName(workspace.workspaceName.trim());
        }
    }, [workspace]);
    const handleSubmit = () => {
        if (errorMessage) return;
        if (!name) {
            setErrorMessage("Workspace name shouldn't be empty");
            return;
        }
        if (workspaces.includes(name.toLowerCase().trim())) {
            setErrorMessage("Workspace already present with same name");
            return;
        }
        // if (validateName(name)) {
        //     setErrorMessage(
        //         'Name should not contain any of the following characters\n \\ / . ? : * | " < >'
        //     );
        //     return;
        // }
        if (!validateNameLength(name)) {
            let msg = "Workspace name should be less than 100 characters";
            setErrorMessage(msg);
            return;
        }
        submitRenameWorkspace(name, workspace.workspaceId);
    };
    const handleCloseModalClick = () => {
        clearData();
        handleCloseModal();
    };
    const handleFocus = (event) => event.target.select();

    const setFromText = (value, id, event) => {
        setName(value);
        const pattern = /[^A-Za-z0-9\s]{2}/;
        let isValidName = validateWorkspaceUserName(value);
        if (isValidName === true) {
            if (pattern.test(value)) {
                setErrorMessage("Two consecutive special characters are not allowed.")
            } else {
                setErrorMessage("")
            }
        } else {
            setErrorMessage(isValidName)
        }
    };

    const clearData = () => {
        setName(workspace ? workspace.workspaceName.trim() : "");
        setErrorMessage("");
    };
    return (
        <ModalView
            onSubmit={handleSubmit}
            show={modalShow}
            onHide={handleCloseModalClick}
            value="Rename"
            title="Rename"
            type="submit-cancel"
            footer={"false"}
            size="md"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <span
                    className="addWorkspaceModal addwork"
                    style={{ marginLeft: "-210px", marginBottom: " 49px" }}
                >
                    Rename Workspace
                </span>
                <TextBox
                    id="updateSpacename"
                    className="updateSpaceName loginPageBorder paddingLeft "
                    type="text"
                    onchange={setFromText}
                    value={name}
                    onFocus={handleFocus}
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {errorMessage && (
                <>
                    <small
                        id="small_renameWorkspace_errorMessage"
                        style={{ color: "red", paddingLeft: "18px" }}
                    >
                        {errorMessage}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
};

export default RenameWorkspace;
