import React from "react";
import SpaceViewer from "./SpaceViewer";

export default function Spaces({
    currentPageType,
    changeCurrentPage,
    setSpacesViewFullScreen,
    setManageSpaceScreen,
    renderRightPanel,
}) {
    let temp = null;
    currentPageType === "workspace" || currentPageType === "AlertsFullScreen"
        ? (temp = "alert")
        : (temp = "table");
    if (currentPageType === "workspace" || currentPageType === "managespace") {
        return (
            <div
                className="invisibleScroller bottomSection w-100 m-0 pl-3"
                style={{
                    height: "85vh",
                    overflowY: "scroll",
                    display: "flex",
                    flexBasis: "wrap",
                }}
            >
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                    <SpaceViewer
                        onclick={changeCurrentPage}
                        rightPanelType={temp}
                        setViewFullScreen={setSpacesViewFullScreen}
                        setManageSpaceScreen={setManageSpaceScreen}
                        fullScreenView={false}
                    ></SpaceViewer>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                    {renderRightPanel(2, 2)}
                </div>
            </div>
        );
    } else if (currentPageType === "AlertsFullScreen") {
        return (
            <div
                className="container-fluid h-100"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                }}
            >
                <div className="col-12">{renderRightPanel(6, 2)}</div>
            </div>
        );
    } else if (currentPageType === "SpacesFullScreen") {
        return (
            <div
                className="invisibleScroller bottomSection w-100"
                style={{
                    height: "85vh",
                    overflow: "scroll",
                    display: "flex",
                    flexBasis: "wrap",
                }}
            >
                <div className="col-12 p-0">
                    <SpaceViewer
                        onclick={changeCurrentPage}
                        rightPanelType={temp}
                        fullScreenView={true}
                        setManageSpaceScreen={setManageSpaceScreen}
                    ></SpaceViewer>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
