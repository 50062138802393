export const userIdToFullName = (newSpaceNameMap = null, action) => {
    if (action.type === "ID_TO_USER_FULLNAME_MAP") {
        return action.payload;
    }
    return newSpaceNameMap;
};
export const userIdToEmail = (newSpaceNameMap = null, action) => {
    if (action.type === "ID_TO_USER_EMAIL") {
        return action.payload;
    }
    return newSpaceNameMap;
};
