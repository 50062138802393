let singleClickTimer = null;
let clickCount = 0;

export function executeClickEvents(
    event,
    singleClickHandler,
    doubleClickHandler,
    id
) {
    let ids = [];
    ids = id ? id.split("|")[1] : [];
    clickCount = clickCount + 1;

    if (clickCount === 1) {
        singleClickTimer = setTimeout(() => {
            clickCount = 0;
            singleClickHandler(event, ids);
        }, 300);
    } else if (clickCount === 2) {
        clearTimeout(singleClickTimer);
        clickCount = 0;
        doubleClickHandler(event);
    }
}
