import DirectoryManager from "./DirectoryManager";

export const fetchDirectoryListData = (options) => {
    return async (dispatch) => {
        const _wm = new DirectoryManager(options);
        const response = await _wm.fetchDirectoryData();
        dispatch({
            type: "FETCH_DIRECTORY_LIST",
            payload: response,
        });
    };
};

export const fetchUserListData = (id) => {
    return async (dispatch) => {
        const _wm = new DirectoryManager();
        const response = await _wm.fetchInivteUserData(id);
        dispatch({
            type: "FETCH_DIRECTORY_USERS_LIST",
            payload: response,
        });
    };
};
