export const folderListData = (folderListData = null, action) => {
    if (action.type === "FETCH_FOLDER_DATA") {
        return action.payload;
    }
    if (action.type === "RESET_FOLDER_LIST_DATA") {
        return null;
    }
    return folderListData;
};
export const folderIdToName = (folderIdToNameMap = null, action) => {
    if (action.type === "FOLDER_ID_TO_NAME_MAP") {
        return action.payload;
    }
    return folderIdToNameMap;
};
