import http from "../../../../../bae-client/HTTP";

class DirectoryManager {
    constructor(option) {}

    fetchDirectoryData = () => {
        let url = `directory/list`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };
    fetchInivteUserData = (id) => {
        let url = `directory/user/list?directoryId=${id}`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };
}
export default DirectoryManager;
