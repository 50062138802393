export const refreshFeeds = (flag) => {
    return {
        type: "REFRESH_FEED",
        payload: flag,
    };
};
export const setFeedItems = (items) => {
    return {
        type: "SET_FEED_ITEMS",
        payload: items,
    };
};
export const setFeedItemToRedirect = (item) => {
    return {
        type: "SET_FEED_ITEM_TO_REDIRECT",
        payload: item,
    };
};
export const setIntegrationList = (items) => {
    return {
        type: "SET_INTEGRATION_ITEM_LIST",
        payload: items,
    };
};

export const reloadInformationFeed = (items) => {
    return {
        type: "RELOAD_INFORMATION_FEED",
        payload: items,
    };
};
