export const linkImport = (cuboidData = null, action) => {
    if (action.type === "LINK_IMPORT") {
        return action.payload;
    }
    return cuboidData;
};

export const refresh = (refreshData = null, action) => {
    if (action.type === "REFRESH") {
        return action.payload;
    }
    return refreshData;
};
export const submit = (submitData = null, action) => {
    if (action.type === "SUBMIT") {
        return action.payload;
    }
    return submitData;
};
