import React from "react";
import { Col } from "react-bootstrap";
import http from "../../../../../../bae-client/HTTP";
import CustomTable from "../aggr-dashboard/table";
import Loader from "../../../../../stylized/Loader";
import HeaderView1 from "../dashboard/HeaderSection/HeaderView1";
import LineChart from "../dashboard/LineChart";
import StatsCard from "../dashboard/StatsCard";
import TableCard from "../dashboard/TableCard";
import AlertDialog from "../../../../../stylized/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
const Compare = () => {
    const [filterOneData, setFilterOneData] = React.useState({});
    const [genericElementsOne, setGenericElementsOne] = React.useState([]);
    const [genericElementsTwo, setGenericElementsTwo] = React.useState([]);
    const [filterTwoData, setFilterTwoData] = React.useState({});
    const [alertSteps, setAlertSteps] = React.useState({});
    const [filterOneTimelineData, setFilterOneTimelineData] = React.useState(
        []
    );
    const [filterTwoTimelineData, setFilterTwoTimelineData] = React.useState(
        []
    );
    const filterRawData = useSelector((state) => state.DashboardDCFilterData);
    const dispatch = useDispatch();
    const [filterOneErr, setFilterOneErr] = React.useState("");
    const [filterTwoErr, setFilterTwoErr] = React.useState("");
    const [filterOne, setFilterOne] = React.useState("Filter 1");
    const [filterTwo, setFilterTwo] = React.useState("Filter 2");
    const [showLoader, setShowLoader] = React.useState(false);
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const clearFilterOne = () => {
        setFilterOneData({});
        setFilterOne("Filter 1");
    };
    const clearFilterTwo = () => {
        setFilterTwoData({});
        setFilterTwo("Filter 2");
    };
    const handleFilter1Submit = (filterData) => {
        setShowLoader(true);
        setGenericElementsOne([]);
        http.sendRestRequest("documentChain/dashboardData", "POST", filterData)
            .then((response) => {
                response.exceptions.sort((a, b) => {
                    return Date.parse(b.createdOn) - Date.parse(a.createdOn);
                });
                setAlertSteps(response.exceptionStepNames);
                setFilterOneData(response);
                setGenericElementsOne(response.genericElements);
                let genericIdToNameMap = new Map();
                filterRawData.forEach((filterItem) => {
                    genericIdToNameMap.set(
                        filterItem.genericRegistry.genericId,
                        filterItem.genericRegistry.genericName
                    );
                });
                let newArray = response.instanceElements.map((ele) => {
                    return {
                        document: ele.instanceBlockDocumentName,
                        date: ele.actualDate,
                        documentId: ele.instanceBlockDocumentId,
                        genericName: genericIdToNameMap.get(ele.genericId),
                        genericStepName: ele.instanceStepName,
                        genericId: ele.genericId,
                        instanceId: ele.instanceId,
                    };
                });
                setFilterOneTimelineData(
                    newArray.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    })
                );
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const handleFilter2Submit = (filterData) => {
        setShowLoader(true);
        setGenericElementsTwo([]);
        http.sendRestRequest("documentChain/dashboardData", "POST", filterData)
            .then((response) => {
                response.exceptions.sort((a, b) => {
                    return Date.parse(b.createdOn) - Date.parse(a.createdOn);
                });
                setFilterTwoData(response);
                setAlertSteps(response.exceptionStepNames);
                setGenericElementsTwo(response.genericElements);
                setShowLoader(false);
                let genericIdToNameMap = new Map();
                filterRawData.forEach((filterItem) => {
                    genericIdToNameMap.set(
                        filterItem.genericRegistry.genericId,
                        filterItem.genericRegistry.genericName
                    );
                });
                let newArray = response.instanceElements.map((ele) => {
                    return {
                        document: ele.instanceBlockDocumentName,
                        date: ele.actualDate,
                        documentId: ele.instanceBlockDocumentId,
                        genericName: genericIdToNameMap.get(ele.genericId),
                        genericStepName: ele.instanceStepName,
                        genericId: ele.genericId,
                        instanceId: ele.instanceId,
                    };
                });
                setFilterTwoTimelineData(
                    newArray.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    })
                );
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const filterProperties = [
        {
            handleSubmitFilter: handleFilter1Submit,
            clearDashboardData: clearFilterOne,
            filterName: filterOne,
            setFilterName: setFilterOne,
            filterNo: "one",
            filterErr: filterOneErr,
            setFilterErr: setFilterOneErr,
        },
        {
            handleSubmitFilter: handleFilter2Submit,
            clearDashboardData: clearFilterTwo,
            filterName: filterTwo,
            setFilterName: setFilterTwo,
            filterNo: "two",
            filterErr: filterTwoErr,
            setFilterErr: setFilterTwoErr,
        },
    ];
    const stepAlertProperties = [
        { title: "Total", key: "totalAlerts" },
        { title: "Skipped", key: "totalSKippedAlerts" },
        { title: "Delayed", key: "totalDelayedAlerts" },
        {
            title: "Proactive",
            key: "totalProactiveStepAlerts",
        },
    ];
    const summaryProperties = [
        { title: "Total Documents", key: "totalDocuments" },

        { title: "Total Initiated Instances", key: "instancesInitiated" },
        { title: "Total On Time Instances", key: "instances" },
        {
            title: "Total Delayed Instances",
            key: "instancesDelayed",
        },
        { title: "Total Completed Instances", key: "instancesCompleted" },
    ];
    return (
        <div
            className="invisibleScroller compare-container"
            style={{ height: "100%" }}
        >
            <div className="filter-container">
                {filterProperties.map((item) => {
                    return (
                        <div className="filters">
                            <Col
                                className="Header_dashboard-compare"
                                style={{ padding: "20px 8px" }}
                            >
                                <HeaderView1
                                    handleSubmitFilter={item.handleSubmitFilter}
                                    clearDashboardData={item.clearDashboardData}
                                    isCompareView={true}
                                    exceptionSteps={alertSteps}
                                    alertData1={filterOneData.exceptions}
                                    alertData2={filterTwoData.exceptions}
                                    filterName={item.filterName}
                                    setFilterName={item.setFilterName}
                                    filterNo={item.filterNo}
                                    filterErr={item.filterErr}
                                    setFilterErr={item.setFilterErr}
                                ></HeaderView1>
                            </Col>
                        </div>
                    );
                })}
            </div>
            <div
                className="sub-containers alerts"
                style={{ display: "flex", gap: "12px" }}
            >
                <div
                    className="dashboard_col1 dashboard_cards1"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "49%",
                        height: "100%",
                    }}
                >
                    <div>
                        <StatsCard title={"Step Alerts"} />
                    </div>

                    <div>
                        <TableCard
                            title={"                         "}
                            amount={filterOne}
                            amount2={filterTwo}
                            isCompare={true}
                        />

                        {stepAlertProperties.map((alertType) => (
                            <TableCard
                                key={alertType.title}
                                title={alertType.title}
                                amount={
                                    filterOneData[alertType.key] > 0
                                        ? filterOneData[alertType.key]
                                        : 0
                                }
                                amount2={
                                    filterTwoData[alertType.key] > 0
                                        ? filterTwoData[alertType.key]
                                        : 0
                                }
                                isCompare={true}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="dashboard_col1 dashboard_cards1"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                    }}
                >
                    <div
                        style={{
                            padding: "24px",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: "600",
                        }}
                    >
                        Summary
                    </div>

                    <div>
                        <TableCard
                            title={"                         "}
                            amount={filterOne}
                            amount2={filterTwo}
                            isCompare={true}
                        />
                        {summaryProperties.map((item, i) => {
                            return (
                                <TableCard
                                    title={item.title}
                                    amount={
                                        i === 2
                                            ? filterOneData.instances &&
                                              filterOneData.instancesCompleted -
                                                  filterOneData.instancesDelayed
                                            : filterOneData[item.key] > 0
                                            ? filterOneData[item.key]
                                            : 0
                                    }
                                    amount2={
                                        i === 2
                                            ? filterTwoData.instances &&
                                              filterTwoData.instancesCompleted -
                                                  filterTwoData.instancesDelayed
                                            : filterTwoData[item.key] > 0
                                            ? filterTwoData[item.key]
                                            : 0
                                    }
                                    isCompare={true}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="dashboard_items1" id="item1_dashboard">
                <span className="dashboard-doc">Step Summary</span>
                <div className="invisibleScroller" id="graph">
                    {genericElementsOne.length && genericElementsTwo.length ? (
                        <CustomTable
                            height={"275px"}
                            spaceData={genericElementsOne}
                            filterOneData={genericElementsOne}
                            filterTwoData={genericElementsTwo}
                            type="compare"
                            setSelectedItems={() => {}}
                            filterOne={filterOne}
                            filterTwo={filterTwo}
                            popup
                        ></CustomTable>
                    ) : (
                        <div
                            id="step_sum_div"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                margin: "70px",
                            }}
                        >
                            <div
                                className="img-flow-alert"
                                style={{ paddingBottom: "20px" }}
                            >
                                <img src="./images/q11.svg" alt="" />
                            </div>
                            <div className="grey-head ">
                                Select Filters Above <br></br> to View Documents
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="dashboard_chart" id="card_dashboard">
                <div className="dashboard_chart-items">
                    <Col className="dashboard_graph">
                        {filterOneData &&
                        filterOneData.genericElements &&
                        filterOneTimelineData.length &&
                        filterTwoTimelineData.length ? (
                            <LineChart
                                data={filterOneTimelineData}
                                type={"Spike"}
                                filterData={filterOneData}
                                filterData2={filterTwoData}
                                data2={filterTwoTimelineData}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    margin: "70px",
                                }}
                            >
                                <div
                                    className="img-flow-alert"
                                    style={{ paddingBottom: "20px" }}
                                >
                                    <img src="./images/q11.svg" alt="" />
                                </div>
                                <div className="grey-head ">
                                    Select Filters Above <br></br>to View
                                    Documents
                                </div>
                            </div>
                        )}
                    </Col>
                </div>
                <div className="dashboard_chart-items">
                    <Col className="dashboard_graph">
                        {filterOneData && filterOneData.genericElements ? (
                            <LineChart
                                data={
                                    filterOneData &&
                                    filterOneData.genericElements
                                        ? filterOneData.genericElements.filter(
                                              function (el) {
                                                  return (
                                                      el.genericStepName !==
                                                      "Email"
                                                  );
                                              }
                                          )
                                        : []
                                }
                                type={"Performance"}
                                filterData={filterOneData}
                                filterData2={filterTwoData}
                                data2={
                                    filterTwoData &&
                                    filterTwoData.genericElements
                                        ? filterTwoData.genericElements.filter(
                                              function (el) {
                                                  return (
                                                      el.genericStepName !==
                                                      "Email"
                                                  );
                                              }
                                          )
                                        : []
                                }
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    marginTop: "50px",
                                }}
                            >
                                <div
                                    className="img-flow-alert"
                                    style={{ paddingBottom: "20px" }}
                                >
                                    <img src="./images/g1.svg" alt="" />
                                </div>
                                <div className="grey-head">
                                    Select Filters Above <br></br>to View Chart
                                </div>
                            </div>
                        )}
                    </Col>
                </div>
            </div>
            <Loader show={showLoader}></Loader>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
        </div>
    );
};

export default Compare;
