const dev = {
    TEST_URL: "http://localhost:8080",
};
//for addStepStepper.jsx
const ids = ["admin"]; //list of ids who can edit
let ans = false;
const ifIdExist = (ids) => {
    ids.forEach((element) => {
        if (element === "amin") ans = true;
    });
    return ans;
};
const editableFlag = (user) => {
    if (user === "admin_uc@meta.com") return true;
    else return false;
};
ans = ifIdExist(ids);
let config = {
    editable: editableFlag,
    env: dev,
    restAppName: "BW_UNITY_CENTRAL_QA",
    rootFolder: "UnityCentral",
    uploadFolder: "UnityCentralDocuments",
    helpURL: "https://ucinfo.boardwalktech.com/knowledge-center",

    showLeftPaneRipple: true,
    showLeftPaneRippleLog: true,
    showLeftPaneIntegration: true,
    showLeftPaneCommunicator: true,
    showLeftPaneArchive: true,
    showLeftPaneSetting: true,
    showLeftPaneTutorial: false,
    showLeftPaneTrash: true,
    showLeftPaneCTForm: true,
    showLeftPaneCTTestAPI: true,
    showLeftPaneControlTowerView: true,
    showLeftPaneDocumentChain: true,

    showPdf: true,
    showJpg: false,
    showPng: false,
    showText: true,
    showDocx: false,
    showMp4: false,
    showDoc: true,
    showHtml: false,
    showHtm: false,
    showXml: true,
    showAi: true,
    allowedDocTypes: [
        ".pdf",
        ".txt",
        ".docx",
        ".xml",
        ".pptx",
        ".xlsx",
        ".png",
        ".jpg",
        ".gif",
        ".mp3",
        ".mp4",
        ".svg",
        ".ai",
    ],
    maxFileSize: 25, //In MB
    allowedFileUploadSize: 15 * 1024 * 1024,
    maxAttempts: 50,
    reAttemptIn: 300, //milisecs,
    documentCollaborationName: "__Documents",
    documentWhiteboardName: "Documents",
    departmentList:
        "Accounts|Engineering|Finance|IT|Manufacturing|Marketing|Sales|Supply Chain|Academic|Design|Product Development|Healthcare|Human Resources|Travel|Art/Entertainment|Music|Film|Sports|Others",
};

// export default ans;
export default config;
