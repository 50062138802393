import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    _URL,
    _ALL_WORKSPACE_ENDPOINT,
    _REST_APP,
    _WORKSPACE_ENDPOINT,
    _DELETE,
    _PUT,
    _POST,
} from "../../constants";
import http from "../../../bae-client/HTTP";

export const workspaceAPI = createApi({
    reducerPath: "Workspace",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        /**
         * Prepares the headers for the HTTP request.
         *
         * @param {Headers} headers - The headers to be prepared
         * @returns {Headers} - The prepared headers
         */
        prepareHeaders: (headers) => {
            // Set Access-Control-Allow-Origin to *
            headers.set("Access-Control-Allow-Origin", "*");
            // Set Content-Type to application/json
            headers.set("Content-Type", "application/json");
            // Set Authorization to the defined sessionStorage.getItem("token") value
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
        tagTypes: ["Workspace"],
    }),
    /**
     * Defines endpoints for workspace related queries and mutations
     * @param {Object} builder - Object to build queries and mutations
     */
    endpoints: (builder) => ({
        // Query to get all workspaces
        workspaces: builder.query({
            query: () => _ALL_WORKSPACE_ENDPOINT,
        }),
        // Query to get a specific workspace by id
        workspace: builder.query({
            query: (id) => _WORKSPACE_ENDPOINT + `/${id}`,
        }),
        createWorksapce: builder.mutation({
            /**
             * Function to generate a POST request options
             * @param {object} payload - The payload for the POST request
             * @returns {object} - The POST request options
             */
            query: (payload) => {
                return {
                    url: _WORKSPACE_ENDPOINT,
                    method: _POST,
                    body: payload,
                };
            },
            invalidatesTags: ["Workspace"],
        }),
        // Mutation to delete a workspace
        deleteWorkspace: builder.mutation({
            /**
             * Function to generate a DELETE request options
             * @param {object} payload - The payload for the DELETE request
             * @returns {object} - The DELETE request options
             */
            query: (payload) => {
                return {
                    url: _WORKSPACE_ENDPOINT,
                    method: _DELETE,
                    body: payload,
                };
            },
            invalidatesTags: ["Workspace"],
        }),
        // Mutation to update a workspace
        updateWorkspace: builder.mutation({
            /**
             * Generates a query object for making a PUT request to the workspace endpoint.
             * @param {object} payload - The payload for the PUT request.
             * @returns {object} - The query object.
             */
            query: (payload) => {
                const { workspaceId, workspaceName } = payload;
                return {
                    url: `${_WORKSPACE_ENDPOINT}/${workspaceId}`,
                    method: _PUT,
                    body: {
                        workspaceName,
                    },
                };
            },
            invalidatesTags: ["Workspace"],
        }),
    }),
});

export const {
    useWorkspacesQuery,
    useDeleteWorkspaceMutation,
    useCreateWorksapceMutation,
    useUpdateWorkspaceMutation,
} = workspaceAPI;
