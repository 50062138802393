import React, { Component } from "react";

class List extends Component {
    constructor(props) {
        super(props);
    }

    renderList() {
        const listData = this.props.items;
        let listItems = [];
        listData.forEach((element, index) => {
            listItems.push(
                <li
                    onClick={(e) => {
                        if (
                            e.target.nodeName === "LI" &&
                            e.target.children &&
                            e.target.children.length
                        ) {
                            e.target.children[0].click();
                        }
                    }}
                    style={{ width: "inherit" }}
                    id={element + "_" + index}
                    {...(this.props.role !== undefined &&
                    this.props.role !== null &&
                    this.props.role === "none"
                        ? { role: "none" }
                        : null)}
                    {...(this.props.activeItemIndex !== undefined &&
                    this.props.activeItemIndex !== null &&
                    this.props.activeItemIndex === index
                        ? {
                              className:
                                  "active list-group-item list-group-item-action pt-2 pb-2 " +
                                  "flowBox " +
                                  this.renderliClassName(),
                          }
                        : {
                              className:
                                  "list-group-item list-group-item-action pt-2 pb-2 " +
                                  "flowBox " +
                                  this.renderliClassName(),
                          })}
                >
                    {element}
                </li>
            );
        });
        return listItems;
    }
    renderUlClassName() {
        if (
            this.props.className !== undefined &&
            this.props.className !== null
        ) {
            return this.props.className;
        }
    }
    renderliClassName() {
        if (
            this.props.liClassName !== undefined &&
            this.props.liClassName !== null
        ) {
            return this.props.liClassName;
        }
    }
    render() {
        return (
            <ul
                className={
                    "list-group list-group-flush " + this.renderUlClassName()
                }
                {...(this.props.role !== undefined &&
                this.props.role !== null &&
                this.props.role === "none"
                    ? { role: "none" }
                    : null)}
                style={{ cursor: "pointer" }}
            >
                {this.renderList()}
            </ul>
        );
    }
}
export default List;
