import React from "react";
import { connect } from "react-redux";
import { setAlertData, resetStore } from "../../../actions";
import {
    fetchWorkspaceListData,
    resetWorkspaceList,
    setWorkspaceIdToNameMap,
    setWorkspaceNameToIdMap,
} from "../../../actions/WokspaceActions";
import {
    setSpaceIdToNameMap,
    setSpaceNameToIdMap,
    setSpaceIdToWorkspaceIdMap,
} from "../../../actions/SpaceActions";

import { setFolderIdToNameMap } from "../../../actions/FolderActions";
import { fetchHierarchyResponse } from "../../../actions/HierarchyActions";
import { setChatList } from "../../../actions/CommunicatorActions";
import http from "../../../bae-client/HTTP";
import user from "../../../bae-client/User";
import "../../../index.css";
import Loader from "../../stylized/Loader";
import Section from "../../stylized/Section";
import Header from "./Header";
// import LeftPane from "../UnityCentral/LeftPane";
import LeftPane from "./LeftPane/LeftPane";
import ContentPane from "./ContentPane";
import AlertDialog from "../../stylized/AlertDialog";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import Loading from "../../stylized/Loading";

// let basePathName = "UnityCentral";
let basePathName = window.runtimeConfig.instanceName
    ? "/" + window.runtimeConfig.instanceName
    : "";
// if (process.env.NODE_ENV !== "development") {
//     basePathName += "/" + http.restApp + "/UnityCentral";
// }
export const paths = [
    { path: "/", activePage: "Home" },
    { path: "/home", activePage: "Home" },
    { path: "/workspace", activePage: "Workspace" },
    { path: "/ripple", activePage: "Ripple" },
    { path: "/chat", activePage: "Chat" },
    { path: "/apps", activePage: "Apps" },
    { path: "/archive", activePage: "Archive" },
    { path: "/alertLog", activePage: "AlertLog" },
    { path: "/settings", activePage: "Settings" },
    { path: "/flow", activePage: "DocuChain" },
    { path: "/ripplelog", activePage: "Ripple Log" },
    { path: "/controltower", activePage: "Control Tower" },
    { path: "/trashbin", activePage: "Trash Bin" },
    { path: "/directory", activePage: "Directory" },
    { path: "/none", activePage: "None" },
];

class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(window.location.search);
        let theToken;
        let membershipId;
        let nhId;
        let userId;
        theToken = sessionStorage.getItem("token"); //params.get("token");
        membershipId = sessionStorage.getItem("memberId"); //params.get("mId");
        nhId = sessionStorage.getItem("nhId"); //params.get("nhId");
        userId = sessionStorage.getItem("userId"); //params.get("userId");
        //}
        //http.setAuthorization(theToken);
        http.setUserName(sessionStorage.getItem("user"));
        http.setMembershipId(membershipId);
        http.setNhId(nhId);
        http.setUserId(userId);
        let userInfo = user.getUserInfo();
        this.userName = userInfo.username;
        let nhid = http.getNHId();
        this.state = {
            selectedCollabIndex: -1,
            loginSuccess: false,
            showLeftPane: true,
            modalShow: false,
            showLoader: false,
            showWSHLoader: false,
            jiraDataLoaded: false,
            chatLoading: false,

            windowSize: window.innerWidth,
            activeWidgetPage: "Jira",
            gmailCount: null,
            workspaceOptions: null,
            showAlertDialog: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            isAlert: false,
            userInfo: null,
            showModal: false,
            isHelpDropDownOpen: false,
        };
        this.navCollabItems = [];
        this.fileName = "";
        this.canvas = null;
        this.alertData = null;
        this.workspaceTreeStructure = [];
        this.wsRegistryData = null;
        this.wsPersistenceData = null;
        this.spaceRegistryData = null;
        this.spacePersistenceData = null;

        this.workspaceCollabId = null; // __Workspace Collaboration
        this.workspaceWBId = null; //Workspace Whiteboard
        this.workspaceRegistryId = null; //Workspace Registry Cuboid
        this.workspacePersistenceId = null; //Workspace Persistence Cuboid

        this.spaceWBId = null; //Space Whiteboard
        this.spaceRegistryId = null; //Space Registry Cuboid
        this.spacePersistenceId = null; //Space Persistence Cuboid
        this.errorMsg = "";
        this.archiveSpaceList = [];
        this.handleLogout = this.handleLogout.bind(this);
    }
    changeOpenDialogFlag = (flag) => {
        this.setState({ showAlertDialog: flag });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertDialog: true });
    };
    computeAndInitializeCommunicatorData = () => {
        let url = "communicator/chatList";
        // this.setState({ showLoader: true, chatLoading: true });
        http.sendRestRequest(url)
            .then((res) => {
                res.sort((a, b) => {
                    return (
                        Date.parse(b.messageOn.split(".")[0]) -
                        Date.parse(a.messageOn.split(".")[0])
                    );
                });
                this.props.setChatList(res);
                // this.setState({ showLoader: false, chatLoading: false });
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                console.log(err);
                this.setState({ showLoader: false });
            });
    };
    setAlertData = () => {
        this.props.setAlertData(this.alertData);
    };
    computeAndInitializeUserDetails = () => {
        let userId = http.getUserId();
        let url = "user/" + userId;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url)
                .then((response) => {
                    user.setBwUserInfo(response);
                    this.setState({ userInfo: response });
                    Mixpanel.callUserIdentityEvent("Login", {
                        email: response.userEmail,
                        name: response.firstName + " " + response.lastName,
                        company: response.companyName,
                        industry: response.departmentName,
                        plan: "Free",
                    });
                    resolve(1);
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    reject(0);
                });
        });
    };
    componentDidMount() {
        this.computeAndInitializeCommunicatorData();
    }
    handleLogout() {
        this.props.resetStore();
        Mixpanel.callUserIdentityEvent("Logoff");
    }
    vibilityModal = (value) => {
        this.setState({ showModal: value });
    };
    handleHelpDropDownModal = (value) => {
        this.setState({ isHelpDropDownOpen: value });
    };
    handleClick = () => {
        this.setState({ showModal: false, isHelpDropDownOpen: false });
    };
    renderErrorPageBody = () => {
        return (
            <Section id="doc-upload" content="Please Enter Valid Token">
                <Header></Header>
                <div className="row mt-5 w-100 doCenter">
                    <div className="row w-100 doCenter mb-5">
                        <span className="confirmMailLabel">
                            {this.errorMsg}
                        </span>
                    </div>
                    <div className="row w-100 doCenter">
                        <input
                            type="button"
                            className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder"
                            style={{ height: "40px", marginTop: "10px" }}
                            onClick={this.handleLogout}
                            value="GO BACK TO LOGIN SCREEN"
                        ></input>
                    </div>
                </div>
            </Section>
        );
    };
    hideLeftPane = () => {
        this.setState((prevState) => ({
            showLeftPane: !prevState.showLeftPane,
        }));
    };

    render() {
        return (
            <>
                <div
                    className="marginZero row"
                    style={{ marginLeft: "0", marginRight: "0" }}
                >
                    <BrowserRouter>
                        {this.state.showLeftPane ? (
                            <LeftPane
                                visibility={this.vibilityModal}
                                showModal={this.state.showModal}
                                handleHelpDropDownModal={
                                    this.handleHelpDropDownModal
                                }
                                isHelpDropDownOpen={
                                    this.state.isHelpDropDownOpen
                                }
                                handleLogout={this.handleLogout}
                                setUser={this.props.setUser}
                                userInfo={this.state.userInfo}
                                workspaceOptions={this.state.workspaceOptions}
                            ></LeftPane>
                        ) : null}

                        <div
                            onClick={this.handleClick}
                            className="p-0 containerBody"
                            style={{ width: "calc(100% - 100px)" }}
                        >
                            <Routes>
                                {paths.map((path) => (
                                    <Route
                                        exact
                                        path={basePathName + path.path}
                                        element={
                                            <ContentPane
                                                activePage={
                                                    this.props.activePage
                                                }
                                                userName={this.userName}
                                                closeModal={this.closeModal}
                                                documentMetaData={
                                                    this.documentMetaData
                                                }
                                                setUser={this.props.setUser}
                                                handleLogout={this.handleLogout}
                                            />
                                        }
                                    ></Route>
                                ))}
                            </Routes>
                        </div>
                    </BrowserRouter>

                    {/* <Loader
                        show={this.state.showLoader || this.state.showWSHLoader}
                    />
                    {this.state.chatLoading ? <Loading /> : null} */}

                    <AlertDialog
                        open={this.state.showAlertDialog}
                        message={this.state.alertDialogMessage}
                        title={this.state.alertDialogTitle}
                        isAlert={this.state.isAlert}
                        setOpen={this.changeOpenDialogFlag}
                    ></AlertDialog>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = () => {
    return {
        fetchWorkspaceListData,
        setAlertData,
        resetWorkspaceList,
        resetStore,
        setChatList,
        fetchHierarchyResponse,
        setWorkspaceIdToNameMap,
        setWorkspaceNameToIdMap,
        setSpaceIdToNameMap,
        setSpaceNameToIdMap,
        setSpaceIdToWorkspaceIdMap,
        setFolderIdToNameMap,
    };
};

const mapStateToProps = (state) => {
    return {
        activePage: state.activePage,
        alertData: state.alertData,
        hierarchyResponse: state.hierarchyResponse,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(DashBoard);
