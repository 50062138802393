import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    isNode,
    Controls,
} from "react-flow-renderer";
import dagre from "dagre";
import RippleReactFlow from "../../../bae-client/DLA-Ripple/RippleReactFlow";
import {
    setFeedItemToRedirect,
    reloadInformationFeed,
    setFeedItems,
} from "../../../actions/InformationFeedActions";
import { setActiveChat } from "../../../actions/CommunicatorActions";
import { fetchSpaceListData } from "../../../actions/SpaceActions";
import { fetchFolderListData } from "../../../actions/FolderActions";
import {
    setActivePage,
    setBoardList,
    setActiveWorkspace,
    setActiveSpace,
    setActiveFolderData,
} from "../../../actions";
import Space from "../../../bae-client/Space";

// import initialElements from './components/initialElements';

import "../../../bae-client/DLA-Ripple/App.css";
import { connect, useDispatch, useSelector } from "react-redux";
import ColorSelectorNode from "./ColorSelectorNode";
import http from "../../../bae-client/HTTP";
import {
    setActiveWorkspaceAction,
    setSpaceListAction,
    setWorkspaceRedirectionAction,
} from "../../../services/services/app";
const nodeTypes = {
    selectorNode: ColorSelectorNode,
};

function RippleRenderer({
    setActiveChat,
    documentArray,
    isDrawerOpen,
    rippleSelectedNode,
    getNode,
    onNodeClick,
    rippleKeywordSearched,
    setActivePage,
    setBoardList,
    setActiveWorkspace,
    setActiveSpace,
    fetchSpaceListData,
    fetchFolderListData,
    setFeedItemToRedirect,
    searchedText,
}) {
    const chatList = useSelector((state) => state.chatList);
    const documentHierarchyInfo = useSelector(
        (state) => state.documentHierarchyInfo
    );
    const spaceListData = useSelector((state) => state.spaceListData);
    const folderListData = useSelector((state) => state.folderListData);
    const [selectedFolderOrSpaceDetails, setSelectedFolderOrSpaceDetails] =
        useState({});
    const [isLocationView, setIsLocationView] = useState(false);
    const [isFolderLocation, setIsFolderLocation] = useState(false);
    useEffect(() => {
        if (spaceListData && spaceListData.length && isLocationView) {
            // if (isFolderLocation) {
            //     // setIsLocationView(false);
            //     fetchFolderListData(selectedFolderOrSpaceDetails["spaceId"]);
            //     //setActiveWorkspaceTree();
            // } else {
            //     setIsLocationView(false);
            //     setActiveWorkspaceTree();
            // }
            setActiveWorkspaceTree();
        }
    }, [spaceListData]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            folderListData !== null &&
            folderListData !== {} &&
            isLocationView
        ) {
            setIsLocationView(false);
            // setActiveFolderTree();
            setActiveWorkspaceTree();
        }
    }, [folderListData]);

    useEffect(() => {
        if (selectedFolderOrSpaceDetails.workspaceId) renderLocation();
    }, [selectedFolderOrSpaceDetails]);

    const rippleResult = useSelector((state) => state.rippleResult);
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    // In order to keep this example simple the node width and height are hardcoded.
    // In a real world app you would use the correct width and height values of
    // const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

    const nodeWidth = 172;
    const nodeHeight = 36;

    const getLayoutedElements = (elements, direction = "LR") => {
        const isHorizontal = direction === "LR";
        dagreGraph.setGraph({ rankdir: direction });
        elements.forEach((el) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, {
                    width: nodeWidth,
                    height: nodeHeight,
                });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = isHorizontal ? "left" : "top";
                el.sourcePosition = isHorizontal ? "right" : "bottom";

                // unfortunately we need this little hack to pass a slightly different position
                // to notify react flow about the change. Moreover we are shifting the dagre node position
                // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
                el.position = {
                    x:
                        nodeWithPosition.x -
                        nodeWidth / 2 +
                        Math.random() / 1000,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }

            return el;
        });
    };
    let dla = {
        100001: {
            DocName: "POS5864.pdf",
            TransactionId: "1",
            WorkspaceName: "Coastal Pacific",
            WID: "100",
            spaceName: "Tyson",
            SpaceID: "200",
            Folder1: "PO Shipment",
            F1id: "300",
            Folder2: "",
            F2id: "",
            Folder3: "",
            F3id: "",
            Folder4: "",
            F4id: "",
            Folder5: "",
            F5id: "",
            Folder6: "",
            F6id: "",
            Folder7: "",
            F7id: "",
            Folder8: "",
            F8id: "",
            Folder9: "",
            F9id: "",
            Folder10: "",
            F10id: "",
        },
        100002: {
            DocName: "COA.pdf",
            TransactionId: "2",
            WorkspaceName: "Coastal Pacific",
            WID: "100",
            spaceName: "Tyson",
            SpaceID: "200",
            Folder1: "Suppliers",
            F1id: "301",
            Folder2: "S1",
            F2id: "400",
            Folder3: "Certificate of Analysis",
            F3id: "500",
            Folder4: "",
            F4id: "",
            Folder5: "",
            F5id: "",
            Folder6: "",
            F6id: "",
            Folder7: "",
            F7id: "",
            Folder8: "",
            F8id: "",
            Folder9: "",
            F9id: "",
            Folder10: "",
            F10id: "",
        },
        100003: {
            DocName: "Shipping Notice.documentArray",
            TransactionId: "3",
            WorkspaceName: "Coastal Pacific",
            WID: "100",
            spaceName: "Tyson",
            SpaceID: "200",
            Folder1: "Suppliers",
            F1id: "301",
            Folder2: "S1",
            F2id: "400",
            Folder3: "Shipping Notice",
            F3id: "501",
            Folder4: "",
            F4id: "",
            Folder5: "",
            F5id: "",
            Folder6: "",
            F6id: "",
            Folder7: "",
            F7id: "",
            Folder8: "",
            F8id: "",
            Folder9: "",
            F9id: "",
            Folder10: "",
            F10id: "",
        },
    };
    // if (documentHierarchyInfo) { dla = documentHierarchyInfo }
    dla = documentHierarchyInfo;
    const documents = documentArray;
    let dladata = {};
    // const createReactFlowElements = () => {
    //     if (documentHierarchyInfo && Object.keys(dla).length !== 0) {
    //         Object.keys(dla).forEach((item) => {
    //             if (documents.includes(item)) {
    //                 dladata[item] = dla[item];
    //             }
    //         });
    //         return dladata;
    //     }
    // };
    //let ele = createReactFlowElements();

    let ele = rippleResult;
    const getFolderData = (folderObject, folderId, response) => {
        const children = folderObject.children;
        const updatedChildren = {};
        for (const key in children) {
            if (Object.hasOwnProperty.call(children, key)) {
                const element = children[key];
                if (
                    element.children &&
                    Object.keys(element.children).length === 0
                ) {
                    updatedChildren[key] = { ...element };
                }
                if (element.folderId === parseInt(folderId)) {
                    updatedChildren[key] = { ...response };
                } else {
                    updatedChildren[key] = {
                        ...element,
                        children: getFolderData(element, folderId, response),
                    };
                }
            }
        }
        return updatedChildren;
    };
    const [shadow, setShadow] = useState([]);
    const handleExpandCollapse = (payload) => {
        const expandFolderPayload = {
            workspaceId: payload.workspaceId,
            spaceId: payload.spaceId,
            folderId: payload.folderId,
            rippleSearchStrings: searchedText,
        };
        http.sendRestRequest("search/expandFolder", "POST", expandFolderPayload)
            .then((response) => {
                setElements([]);
                let updatedResponse = {};
                if (payload.isCollapsed) updatedResponse = { ...response };
                else
                    updatedResponse = {
                        ...response,
                        children: {},
                        isCollapsed: true,
                    };
                let updatedResult = {};
                for (const key in ele) {
                    if (Object.hasOwnProperty.call(ele, key)) {
                        const element = ele[key];
                        updatedResult[key] = { ...element };
                        if (
                            element.workspaceId ===
                            parseInt(payload.workspaceId)
                        ) {
                            const spaces = element.spaces;
                            updatedResult[key].spaces = spaces;
                            for (const space in spaces) {
                                if (Object.hasOwnProperty.call(spaces, space)) {
                                    const spaceObject = spaces[space];
                                    if (
                                        spaceObject.spaceId ===
                                        parseInt(payload.spaceId)
                                    ) {
                                        updatedResult[key].spaces[space] = {
                                            ...spaceObject,
                                            children: getFolderData(
                                                spaceObject,
                                                payload.folderId,
                                                updatedResponse
                                            ),
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
                const newElements = getLayoutedElements(
                    RippleReactFlow(updatedResult || null, handleExpandCollapse)
                );
                setElements(newElements);
            })
            .catch((error) => console.log(error));
    };
    const layoutedElements = getLayoutedElements(
        RippleReactFlow(ele || null, handleExpandCollapse)
    );
    const graphStyles = { width: "100%", padding: "10px" };

    const [elements, setElements] = useState(layoutedElements);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const onConnect = (params) =>
        setElements((els) =>
            addEdge({ ...params, type: "smoothstep", animated: true }, els)
        );
    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els));
    const onLayout = useCallback(
        (direction) => {
            const layoutedElements = getLayoutedElements(elements, direction);
            setElements(layoutedElements);
        },
        [elements]
    );

    useEffect(() => {
        if (shadow.length) {
            setElements(shadow);
            setShadow([]);
        }
    }, [shadow]);

    const renderLocation = () => {
        // fetchSpaceListData(selectedFolderOrSpaceDetails["workspaceId"]);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${selectedFolderOrSpaceDetails["workspaceId"]}`
        )
            .then((response) => {
                // setActiveWorkspaceAction(workspace);
                dispatch(setSpaceListAction(response));
            })
            .catch((err) => console.log(err));
    };
    const convertObjToMap = (object, map) => {
        Object.entries(object).forEach(([k, v]) => {
            map.set(k, v);
            if (v && typeof v === "object") {
                convertObjToMap(v, map);
            }
        });
        return map;
    };
    const handleChatSelection = (chatDetails, chat, index) => {
        if (!chatDetails) {
            setActivePage("Communicator");
        } else {
            let chatObj = { chatDetails, chat, selectedContactIndex: index };
            setActiveChat(chatObj);
            setActivePage("Communicator");
        }
    };

    const setActiveWorkspaceTree = () => {
        let activeSpace = null;
        let activeWSObject = {};
        activeWSObject["activeWorkspaceID"] =
            selectedFolderOrSpaceDetails.workspaceId;
        activeWSObject["activeWorkspaceName"] =
            selectedFolderOrSpaceDetails.workspaceName;
        activeWSObject["spaceList"] = spaceListData ? spaceListData : [];
        let spaceList = spaceListData ? spaceListData : [];
        let spaceArray = [];
        const breadCrumbItems = [
            {
                id: selectedFolderOrSpaceDetails.workspaceId,
                name: selectedFolderOrSpaceDetails.workspaceName,
                type: "workspace",
                parentId: 0,
            },
        ];
        spaceList.forEach((item, i) => {
            let spaceInstance = new Space();
            let options = {};
            options["spaceName"] = item.spaceName; //spaceNames[i]
            options["spaceId"] = item.spaceId; //spaceIDs[i]
            options["archive"] = item.archive;
            spaceInstance.setData(options);
            if (selectedFolderOrSpaceDetails.spaceId === item.spaceId) {
                // activeSpace = options;
                breadCrumbItems.push({
                    id: item.spaceId,
                    name: item.spaceName,
                    type: "space",
                    parentId: selectedFolderOrSpaceDetails.workspaceId,
                });
                // selectedSpaceIndex = i;
                activeSpace = {
                    id: item.spaceId,
                    name: item.spaceName,
                    updatedOn: item.updatedOn,
                    createdOn: item.createdOn,
                    isShared: item.isShared ? "Shared" : "Personal",
                    isStarred: item.isStarred,
                    type: "space",
                    documentId: null,
                };
            }
            spaceArray.push(spaceInstance);
        });

        if (isFolderLocation) {
            const folderId = selectedFolderOrSpaceDetails["folderId"];
            let folderName = "";
            http.sendRestRequest(
                `spacePersistence/folderDetails?folderId=${folderId}`
            )
                .then((resp) => {
                    const folderIdArray = resp.folderIdPath.split("/");
                    const folderNameArray = resp.folderNamePath.split("/");
                    folderIdArray.forEach((element, index) => {
                        if (parseInt(folderId) === parseInt(element)) {
                            folderName = folderNameArray[index];
                        }
                        breadCrumbItems.push({
                            id: parseInt(element),
                            name: folderNameArray[index],
                            type: "folder",
                            parentId:
                                index === 0
                                    ? 0
                                    : parseInt(folderIdArray[index - 1]),
                        });
                    });
                    const redirectionObject = {
                        breadCrumbItems: breadCrumbItems,
                        folderId: parseInt(folderId),
                        folderName,
                        type: "folder",
                        activeSpace: activeSpace,
                        activeWorkspace: {
                            workspaceId:
                                selectedFolderOrSpaceDetails.workspaceId,
                            workspaceName:
                                selectedFolderOrSpaceDetails.workspaceName,
                        },
                    };
                    dispatch(setWorkspaceRedirectionAction(redirectionObject));
                    setActivePage("Workspace");
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            dispatch(
                setWorkspaceRedirectionAction(
                    {
                        activeSpace: activeSpace,
                        breadCrumbItems: breadCrumbItems,
                        folderId: 0,
                        folderName: activeSpace.name,
                        activeWorkspace: {
                            workspaceId:
                                selectedFolderOrSpaceDetails.workspaceId,
                            workspaceName:
                                selectedFolderOrSpaceDetails.workspaceName,
                        },
                    },
                    true
                )
            );
            setActivePage("Workspace");
        }
        // setActiveWorkspace(activeWSObject);
        // setBoardList(spaceArray);
        // setActivePage("Workspace");
        // // this.props.setActiveSpace(null);
        // setActiveSpace(activeSpace);
    };

    var found = elements.map((e) => e.data);
    let labelData = [];
    found.forEach((e) => e && labelData.push(e.label));

    const onElementClick = (event, object) => {
        getNode(event.target.innerText);
    };
    const onClickElement = (event, node) => {
        if (node.data.nodeType === "Message") {
            const chatArray = rippleResult.filter(
                (item) => item.chatRegistryId === node.id
            );
            const chatObject = chatArray.length === 1 ? chatArray[0] : null;
            if (chatObject && chatObject.chatCuboidId) {
                let value = chatList.filter((ele) => {
                    return ele.chatCuboidId === chatObject.chatCuboidId
                        ? ele.chatCuboidId
                        : null;
                });
                let chat = value[0];
                let chatDetails = value[0].participantUserIds.filter(
                    (item) =>
                        item.id.toString() !== sessionStorage.getItem("userId")
                );
                const index = chatList.findIndex((ele) => {
                    return ele.chatCuboidId === chatObject.chatCuboidId
                        ? ele.chatCuboidId
                        : null;
                });
                handleChatSelection(chatDetails, chat, index);
            }
        } else if (node.data.nodeType !== "Doc") {
            if (node.data.nodeType === "Workspace") {
                return;
            }
            let selectedFolderOrSpaceDetailsObj = {};
            rippleResult.forEach((item) => {
                if (
                    item.spaces &&
                    item.workspaceId === parseInt(node.data.workspaceId)
                ) {
                    selectedFolderOrSpaceDetailsObj["workspaceId"] =
                        item.workspaceId;
                    selectedFolderOrSpaceDetailsObj["workspaceName"] =
                        item.workspaceName;
                    const space = item.spaces[node.data.spaceId];
                    selectedFolderOrSpaceDetailsObj["spaceId"] = space.spaceId;
                    selectedFolderOrSpaceDetailsObj["spaceName"] =
                        space.spaceName;
                }
            });
            setIsLocationView(true);
            if (node.data.nodeType === "Space") {
                setIsFolderLocation(false);
            } else if (node.data.nodeType === "Folder") {
                selectedFolderOrSpaceDetailsObj["folderId"] = node.id;
                setIsFolderLocation(true);
            }
            setSelectedFolderOrSpaceDetails({
                ...selectedFolderOrSpaceDetailsObj,
            });
        } else {
            const id = node.data.docId;
            onNodeClick(id);
        }
    };
    const handleOnLoad = (instance) => {
        setReactFlowInstance(instance);
    };
    const showDefaultScreen = () => {
        return (
            <div
                className={"doCenter"}
                style={{ flexDirection: "column", height: "100%" }}
            >
                <img
                    src="./images/Nucleus 5.png"
                    height="70px"
                    alt="Ripple logo"
                />
                <span
                    style={{
                        fontSize: "20px",
                        paddingTop: "10px",
                        fontFamily: "Montserrat",
                    }}
                >
                    Enter a keyword in the Ripple search field
                </span>
            </div>
        );
    };
    const showNoResultScreen = () => {
        return (
            <div
                className={"doCenter"}
                style={{ flexDirection: "column", height: "100%" }}
            >
                <img
                    src="./images/Nucleus 5.png"
                    height="70px"
                    alt="Ripple logo"
                />
                <span
                    style={{
                        fontSize: "30px",
                        paddingTop: "10px",
                        fontFamily: "Montserrat",
                    }}
                >
                    No Ripple results found
                </span>
                <span style={{ paddingTop: "10px", fontFamily: "Montserrat" }}>
                    Please run another Ripple search
                </span>
            </div>
        );
    };
    useEffect(() => {
        setElements(layoutedElements);
    }, [documents]);
    useEffect(() => {
        let timer = setTimeout(() => {
            if (reactFlowInstance) {
                reactFlowInstance.fitView();
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [elements]);
    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    });

    //createReactFlowElements();
    return (
        <div className="layoutflow w-100 h-100" style={{ margin: "10px" }}>
            <ReactFlow
                elements={rippleResult ? elements : []}
                style={graphStyles}
                onConnect={onConnect}
                onElementClick={onElementClick}
                onNodeDoubleClick={onClickElement}
                onElementsRemove={onElementsRemove}
                onLoad={handleOnLoad}
                nodeTypes={nodeTypes}
                connectionLineType="smoothstep"
                nodesDraggable={false}
                defaultZoom={elements.length > 15 ? 0.3 : 0.5}
                minZoom={elements.length > 15 ? 0.1 : 0.3}
            >
                <Controls
                    style={{ border: "1px solid black", marginLeft: "-10px" }}
                    showFitView={true}
                    showInteractive={false}
                ></Controls>
                {!rippleKeywordSearched && documentArray === null
                    ? showDefaultScreen()
                    : // <div>No Ripple results found</div>
                    null}
                {rippleKeywordSearched &&
                    documentArray !== null &&
                    documentArray.length === 0
                    ? showNoResultScreen()
                    : null}
            </ReactFlow>
            {/* <div>
                <button
                    id="btn_Ripple_verticalLayout"
                    onClick={() => onLayout("TB")}
                >
                    Vertical Layout
                </button>

                <button
                    id="btn_Ripple_horizontalLayout"
                    onClick={() => onLayout("LR")}
                >
                    Horizontal Layout
                </button>
            </div> */}
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSpaceListData,
        fetchFolderListData,
        setActivePage,
        setBoardList,
        setActiveChat,
        setActiveWorkspace,
        setActiveSpace,
        setFeedItemToRedirect,
    };
};

const mapStateToProps = (state) => {
    return {
        documentHierarchyInfo: state.documentHierarchyInfo,
        spaceListData: state.spaceListData,
        folderListData: state.folderListData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(RippleRenderer);
