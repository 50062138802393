// Loader.js
import React from "react";
import "./Loading.css"; // Import the loader styles

const Loading = () => {
    return (
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    );
};

export default Loading;
