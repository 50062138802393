import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AlertDialog from "../../../../stylized/AlertDialog";
import { setControlTowerData } from "../../../../../actions";
import ControlTowerView from "./ControlTowerView";
import ControlTower from "../../../../../bae-client/ControlTower";
import ListView from "../../../../stylized/ListView";
import InlineRow from "../../../../stylized/InlineRow";
import TitleBar from "../../TitleBar";
import { Button, Icon } from "@material-ui/core";
import PopoverView from "../../../../stylized/PopoverView";
import "../../../UnityCentral/index.css";
import "../../../../../index.css";
import "./controlTower.css";
import { ReactFlowProvider } from "react-flow-renderer";
import Loader from "../../../../stylized/Loader";
function ControlTowerContainer({
    genericResponse,
    settings,
    controlTowerData,
    activePage,
    setControlTowerData,
}) {
    //To stroe Block chain name related information
    const [genericBlockchainList, setGenericBlockchainList] = useState(null);
    const [selectedGenericBlockchain, setSelectedGenericBlockchain] = useState({
        genericName: "",
        genericId: "",
        instanceRegistryTblId: "",
        instanceDetailsTblId: "",
        instanceInformationTblId: "",
    });
    const [showLoader,setShowLoader]=useState(false)
    const [genericBlockchainDDList, setGenericBlockchainDDList] = useState([]);
    //To stroe instance name related information
    const [instanceList, setInstanceList] = useState(null);
    const [instanceDropdownList, setInstanceDropdownList] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState({
        instanceId: "",
        instanceName: "",
    });
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    //For POPover views
    const [genericElement, setGenericAnchorEl] = useState(null);
    const [instanceElement, setInstanceAnchorEl] = useState(null);
    const [saveClick, setSaveClick] = useState(null);
    const [restoreClick, setRestoreClick] = useState(null);
    useEffect(() => {
        if (selectedGenericBlockchain.genericId) {
            createInstanceDropdownList();
            setSelectedInstance(() => ({ instanceId: "", instanceName: "" }));
        }
    }, [selectedGenericBlockchain.genericId]);
    const handleGenericBlockchainChange = (
        index,
        listValue,
        listText,
        event
    ) => {
        genericBlockchainList.forEach((step, stepIndex) => {
            if (step.genericName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedGenericBlockchain(() => ({
                    genericName: step.genericName,
                    genericId: step.genericId,
                    instanceRegistryTblId: step.instanceRegistryTblId,
                    instanceDetailsTblId: step.instanceDetailsTblId,
                    instanceInformationTblId: step.instanceInformationTblId,
                }));
            }
        });
    };
    const handleInstanceChnage = (index, listValue, listText, event) => {
        instanceList.forEach((step, stepIndex) => {
            if (step.instanceName === listValue) {
                setSelectedInstance(() => ({
                    instanceId: step.instanceId,
                    instanceName: step.instanceName,
                }));
            }
        });
    };
    const handleGenericClick = (event) => {
        setGenericAnchorEl(event.currentTarget);
    };

    const handleGenericClose = () => {
        setGenericAnchorEl(null);
    };
    const genericOpen = Boolean(genericElement);
    const renderGenericBlockchainDD = () => {
        if (!genericBlockchainList && genericResponse) {
            let genericSteps = [];
            if (controlTowerData && controlTowerData.genericId) {
                let selectedgenericId = controlTowerData.genericId;
                genericResponse.forEach((instance) => {
                    if (instance.genericId === parseInt(selectedgenericId)) {
                        setSelectedGenericBlockchain(() => ({
                            genericName: instance.genericName,
                            genericId: instance.genericId,
                            instanceRegistryTblId:
                                instance.instanceRegistryTblId,
                            instanceDetailsTblId: instance.instanceDetailsTblId,
                            instanceInformationTblId:
                                instance.instanceInformationTblId,
                        }));
                    }
                    let obj = {};
                    obj["text"] = instance.genericName;
                    obj["value"] = instance.genericName;
                    obj["id"] = instance.genericId;
                    genericSteps.push(obj);
                });
            } else {
                genericResponse.forEach((instance) => {
                    let obj = {};
                    obj["text"] = instance.genericName;
                    obj["value"] = instance.genericName;
                    obj["id"] = instance.genericId;
                    genericSteps.push(obj);
                });
            }
            setGenericBlockchainList(genericResponse);
            setGenericBlockchainDDList(genericSteps);
        }
        return (
            <div className="nameRippleBtn w-100" style={{ maxWidth: "13rem" }}>
                <Button
                    id={"GenricDropdownButton"}
                    className={
                        "ctBtnFont dropdownBtn noBorder w-100 textEllipsis"
                    }
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleGenericClick}
                >
                    {selectedGenericBlockchain.genericName !== ""
                        ? selectedGenericBlockchain.genericName
                        : "Generic Flow"}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"genericPopup"}
                    show={genericOpen}
                    anchorEl={genericElement}
                    closePopover={handleGenericClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={genericBlockchainDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleGenericBlockchainChange}
                    />
                </PopoverView>
            </div>
        );
    };
    useEffect(() => {
        return () => {
            setControlTowerData(null);
        };
    }, [activePage]);
    const createInstanceDropdownList = () => {
        let controlTower = new ControlTower();
        setShowLoader(true)
        controlTower
            .retrieveInstanceList(selectedGenericBlockchain.genericId)
            .then((response) => {
                setShowLoader(false)
                let instances = [];
                if (controlTowerData && controlTowerData.instanceId) {
                    let selectedInstanceId = controlTowerData.instanceId;
                    response.forEach((instance) => {
                        if (
                            instance.instanceId === parseInt(selectedInstanceId)
                        ) {
                            setSelectedInstance(() => ({
                                instanceId: instance.instanceId,
                                instanceName: instance.instanceName,
                            }));
                        }
                        let obj = {};
                        obj["text"] = instance.instanceName;
                        obj["value"] = instance.instanceName;
                        obj["id"] = instance.instanceId;
                        instances.push(obj);
                    });
                    setInstanceList(response);
                    setInstanceDropdownList(instances);
                } else {
                    response.forEach((item) => {
                        let obj = {};
                        obj["text"] = item.instanceName;
                        obj["value"] = item.instanceName;
                        obj["id"] = item.instanceId;
                        instances.push(obj);
                    });
                }
                setInstanceList(response);
                setInstanceDropdownList(instances);
            })
            .catch((error) => {
                setShowLoader(false)
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    useEffect(() => {
        if (instanceList && controlTowerData) {
            let selectedInstanceId = controlTowerData.instanceId;
            instanceList.forEach((instance) => {
                if (instance.instanceId === parseInt(selectedInstanceId)) {
                    setSelectedInstance(() => ({
                        instanceId: instance.instanceId,
                        instanceName: instance.instanceName,
                    }));
                }
            });
        }
    }, [controlTowerData]);
    const handleInstanceClick = (event) => {
        setInstanceAnchorEl(event.currentTarget);
    };

    const handleInstanceClose = () => {
        setInstanceAnchorEl(null);
    };
    const instanceOpen = Boolean(instanceElement);
    const renderInstanceNameDD = () => {
        if (!instanceList && selectedGenericBlockchain.genericId !== "") {
            // createInstanceDropdownList();
        }
        return (
            <div className="nameRippleBtn w-100 noBorder">
                <Button
                    id={"InstanceDropdownButton"}
                    className={"ctBtnFont dropdownBtn noBorder w-100"}
                    style={{ background: "#fbfbfb", color: "black" }}
                    variant="contained"
                    color="primary"
                    onClick={handleInstanceClick}
                >
                    {selectedInstance.instanceName !== ""
                        ? selectedInstance.instanceName
                        : "Instance Name"}
                    <Icon className={"fas fa-angle-down dropdownIcon"}></Icon>
                </Button>
                <PopoverView
                    id={"instancePopup"}
                    show={instanceOpen}
                    anchorEl={instanceElement}
                    closePopover={handleInstanceClose}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"InstanceName"}
                        items={instanceDropdownList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleInstanceChnage}
                    />
                </PopoverView>
            </div>
        );
    };
    const renderDropdownsRow = () => {
        return (
            <InlineRow
                classname="controlTowerTopbar mt-2 doCenter"
                style={{ justifyContent: "space-between" }}
            >
                <div className="doCenter mr-1 ml-2">
                    {renderGenericBlockchainDD()}
                    {settings ? null : renderInstanceNameDD()}
                </div>
                <div className="doCenter mr-4">
                    {settings ? (
                        <>
                            <span
                                className={"TopbarOptionsEnabled"}
                                onClick={() => setSaveClick(Math.random())}
                            >
                                SAVE
                            </span>
                            <span
                                className={"TopbarOptionsEnabled"}
                                onClick={() => setRestoreClick(Math.random())}
                            >
                                RESTORE
                            </span>
                        </>
                    ) : null}
                </div>
            </InlineRow>
        );
    };
    return (
        <>
            <div
                id="react-flow-container"
                // className={
                //     settings ? "col-12 settingsCTHeight" : "col-10 defaultScroller"
                // }
                style={{ height: "92vh" }}
                className={"defaultScroller"}
                data-testid="control-tower-container"
            >
                <div className="w-100 h-100 pl-0 pr-0">
                    {renderDropdownsRow()}
                    <ReactFlowProvider key={"ReactFlowProvider"}>
                        <ControlTowerView
                            genericId={selectedGenericBlockchain.genericId}
                            instanceDetailsTblId={
                                selectedGenericBlockchain.instanceDetailsTblId
                            }
                            instanceInformationTblId={
                                selectedGenericBlockchain.instanceInformationTblId
                            }
                            instanceId={selectedInstance.instanceId}
                            settings={settings}
                            saveClick={saveClick}
                            restoreClick={restoreClick}
                        />
                    </ReactFlowProvider>
                </div>
            </div>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader}/>
        </>
    );
}
const mapDispatchToProps = () => {
    return {
        setControlTowerData,
    };
};

const mapStateToProps = (state) => {
    return {
        controlTowerData: state.controlTowerData,
        activePage: state.activePage,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(ControlTowerContainer);
