import { CircularProgress, Modal } from "@material-ui/core";
import React from "react";

export class Loader extends React.Component {
    constructor(props) {
        super(props);
    }
    hanleClickModal = (event) => {
        event.stopPropagation();
    };
    handleClickOnWindow = () => {
        if (this.props.hideLoader) {
            this.props.hideLoader();
        }
    };
    renderModalShow() {
        if (this.props.show === true) {
            return "modal modal-show";
        } else {
            return "modal modal-hide";
        }
    }
    renderLoader() {
        return <CircularProgress />;
    }
    render() {
        return (
            <div
                className={this.renderModalShow()}
                style={{ zIndex: "1500" }}
                onClick={this.handleClickOnWindow}
                data-backdrop="static"
                id="myModal"
            >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content modalcontent">
                        <div className="modal-body modalBody">
                            {this.renderLoader()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;
