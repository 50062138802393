import React, { useEffect, useState } from "react";
import { NativeSelect, Input } from "@mui/material";
import "./style.css";
import CustomDropdown from "../../stylized/CustomDropdown";
import RecureModalView from "../UnityCentral/ContentPane/RippleLog/RecureModalView";
import CustomSearchDropdown from "../../stylized/CustomDropDown/index";
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const CustomCell = ({
    columnKey,
    cellValue,
    values,
    onChangeHandler,
    index,
    cuboidName,
    type,
    rowId,
    onValueChange,
    columnId,
}) => {
    const [disableSelect, setDisableSelect] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(cellValue);
    const [error, setError] = useState(false);
    const [selection, setSelection] = useState([]);
    useEffect(() => {
        console.log(index, cellValue);
        if (index === 2) {
            const selectedValues = cellValue.split(",").map((item) => {
                return { text: item, value: item };
            });
            setSelection(selectedValues);
        }
    }, [index, cellValue]);
    const handleSelection = (currentIndex, items) => {
        onChangeHandler(
            items.map((item) => item.text).join(","),
            columnKey,
            rowId
        );
        onValueChange(
            {
                target: {
                    textContent: items.map((item) => item.text).join(","),
                    id: rowId,
                    dataset: { itemid: columnId },
                },
            },
            false,
            index
        );
    };
    if (type === "input") {
        return (
            <input
                style={{ color: error ? "red" : "black" }}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                onBlur={() => {
                    if (!inputValue.match(regexEmail)) setError(!error);
                    else {
                        setError(!error);
                        if (inputValue === cellValue) return;
                        onChangeHandler(
                            {
                                target: {
                                    textContent: inputValue,
                                    id: rowId,
                                    dataset: { itemid: columnId },
                                },
                            },
                            false,
                            index
                        );
                    }
                }}
                value={inputValue}
            ></input>
        );
    } else {
        if (index === 0 || index === 2 || index === 6) {
            return (
                <CustomSearchDropdown
                    items={values.map((item) => {
                        return { text: item, value: item };
                    })}
                    multiSelect={true}
                    selection={selection}
                    handleSelection={handleSelection}
                ></CustomSearchDropdown>
            );
        }
        // else if (index === 9) return <RecureModalView></RecureModalView>;
        else
            return (
                <NativeSelect
                    inputProps={{
                        name: "age",
                        id: "uncontrolled-native",
                    }}
                    // value={cell.cellValue}
                    // title={cell.cellValue}
                    onChange={(e) => {
                        const value =
                            e.target.value !== "Select"
                                ? setDisableSelect(true)
                                : null;
                        if (
                            (index === 0 || index === 2 || index === 6) &&
                            cuboidName === "Dashboard Report Rule Configuration"
                        )
                            onChangeHandler(e.target.value, cuboidName);
                        if (index === 1 && cuboidName === "Email Tile")
                            onChangeHandler(e.target.value, cuboidName);
                    }}
                    title={cellValue}
                >
                    {values.filter(onlyUnique).map((value) => (
                        <option
                            defaultValue={cellValue === "" && values[0]}
                            key={value}
                            value={value}
                            selected={cellValue === value}
                            disabled={
                                disableSelect && value === "Select"
                                    ? true
                                    : false
                            }
                        >
                            {value}
                        </option>
                    ))}
                    {/* <option value={"DELAYED"} selected={cellValue === "DELAYED"}>
            DELAYED
        </option>
        <option value={"SKIPPED"} selected={cellValue === "SKIPPED"}>
            SKIPPED
        </option>
        <option
            value={"KEY MISSING"}
            selected={cellValue === "KEY MISSING"}
        >
            KEY MISSING
        </option>
        <option
            value={"KEY MISMATCH"}
            selected={cellValue === "KEY MISMATCH"}
        >
            KEY MISMATCH
        </option> */}
                </NativeSelect>
            );
    }
};

export default CustomCell;
