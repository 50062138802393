import React, { useEffect, useRef } from 'react'
import Message from './message';
import { 
    useHMSActions,
    useHMSStore,
    selectCameraStreamByPeerID,
    
} from '@100mslive/react-sdk'

export default function VideoTile({peer, handleModalClose}) {

    const videoRef = useRef(null)
    const hmsActions = useHMSActions();

    const videoTrack = useHMSStore(selectCameraStreamByPeerID(peer.id))
    useEffect(() =>{
        if(videoRef.current && videoTrack){
            if(videoTrack.enabled){
                hmsActions.attachVideo(videoTrack.id, videoRef.current)
            }
            else{
                hmsActions.detachVideo(videoTrack.id, videoRef.current)
            }
        }
    }, [videoTrack, hmsActions])

    //

    //mute button icon

    
   
  return (
      
        <div className="video-container" style={{display:"inline-block"}}>
            <div className="cont">
                <video className="center-vid"
                ref={videoRef} autoPlay muted playsInline
                style={{height: "25vh", width: "25vh"}}
                >

                </video>

            </div>

            
            {/* <div style={{display:"inline-block", width:"40%", float:"right"}}>
           <Message />
        </div> */}
        </div>
        
      
    )
}