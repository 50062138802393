import React, { useEffect, useState } from "react";
import http from "../../../../../../../bae-client/HTTP";
import Row from "./Row";
import "./style.css";

const SplitView = ({ genericId }) => {
    const [splitViewData, setSplitViewData] = useState([]);
    useEffect(() => {
        if (genericId !== -1) {
            const url = `documentChain/genericData?genericId=${genericId}`;
            http.sendRestRequest(url)
                .then((resp) => {
                    setSplitViewData(resp);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [genericId]);

    return (
        <div className="containerClass">
            <div className="containerColumns" style={{ marginRight: "18px" }}>
                <div className="columnHeading">DocuChains</div>
                <div className="columnData invisibleScroller">
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <Row key={item + index}></Row>
                    ))}
                </div>
            </div>
            <div className="containerColumns" style={{ marginRight: "18px" }}>
                <div className="columnHeading">Instances</div>
                <div className="columnData invisibleScroller">
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <Row key={item + index} isInstance></Row>
                    ))}
                </div>
            </div>
            <div className="containerColumns">
                <div className="columnHeading">Files</div>
                <div className="columnData invisibleScroller">
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <Row key={item + index}></Row>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SplitView;
