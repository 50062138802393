import Document from "./Document";

class UnityBoard {
    constructor() {
        this.boardType = "canvas";
    }
    data = {
        documentObjects: null,
        spaceName: "",
        spaceId: null,
        boardType: null
    }
    getData = () => {
        return this.data;
    }
    getBoardType() {
        return this.data.boardType;
    }
    setBoardType(spaceType) {
        this.data.boardType = spaceType;
    }
    setData = (options) => {
        if (options.documentObjects !== null || options.documentObjects !== undefined) {
            this.data.documentObjects = options.documentObjects;
        }
        if (options.spaceName !== null || options.spaceName !== undefined) {
            this.data.spaceName = options.spaceName;
        }
        if (options.spaceId !== null || options.spaceId !== undefined) {
            this.data.spaceId = options.spaceId;
        } 
        if (options.boardType !== null || options.boardType !== undefined) {
            this.data.boardType = options.boardType;
        }
    }
    getAllDocumentIds() {
        let documentArray = this.getDocuments();
        let cuboidIdArray = [];
        if(documentArray>0){
            for (let index = 0; index < documentArray.length; index++) {
                cuboidIdArray.push(documentArray[index].data.documentId);
            }
        }
        return cuboidIdArray;
    }
    getDocuments = () => {
        let docArray = [];
        if(this.data.documentObjects!==null){
            for (let index = 0; index < this.data.documentObjects.length; index++) {
                let doc = new Document();
                doc.setData(this.data.documentObjects[index]);
                docArray.push(doc);
            }
        }
        return docArray;
    }
    getDocumentsToUpload() {
        let notSubmittedDocs = [];
        let submittedDocs = this.getDocuments();
        for (let index = 0; index < submittedDocs.length; index++) {
            if (submittedDocs[index].isLinked() === true && submittedDocs[index].data.isPersisted !== true) {
                notSubmittedDocs.push(submittedDocs[index]);
            }
        }
        return notSubmittedDocs;
    }
    setDocuments = (documentObjectsWithMethods) => {
        let docArray = [];
        for (let index = 0; index < documentObjectsWithMethods.length; index++) {
            docArray.push(documentObjectsWithMethods[index].getData());
        }
        this.data.documentObjects = docArray;
    }
}
export default UnityBoard;