import { Menu, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useSelector } from "react-redux";
import { getIconPathByType } from "../../../../common/IconList";
import "./style.css";
import { getChatTimestamp } from "../../../../../../bae-client/Utils";
const columns = [
    { id: "icon", label: "", width: "1rem" },
    { id: "instanceBlockDocumentName", label: "File Name", width: "14rem" },
    { id: "genericStepName", label: "Type", width: "6rem" },
    { id: "from", label: "From", width: "6rem" },
    { id: "to", label: "To", width: "6rem" },

    {
        id: "instanceBlockDate",
        label: "Time",
        width: "6rem",
    },
    {
        id: "genericName",
        label: "Model",
        width: "6rem",
    },
    {
        id: "instanceName",
        label: "Instance",
        width: "6rem",
        // align: "center",
    },
    {
        id: "promiseType",
        label: "Promise",
        width: "6rem",
        // align: "center",
    },
];

export default function DocumentListView({ list, openDocument, openInstance }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [selectedRow, setselectedRow] = React.useState(null);
    const genericIdToNameMap = useSelector((state) => state.genericIdToNameMap);

    const createData = (
        instanceBlockDocumentName,
        instanceBlockDocumentId,
        blockType,
        to,
        from,
        instanceBlockDate,
        genericStepName,
        genericId,
        instanceId,
        instanceName,
        promiseType
    ) => {
        const fileType = instanceBlockDocumentName.split(".").pop();
        const localTime = getChatTimestamp(
            instanceBlockDate,
            "mm/dd/yyyy hh:mm aa"
        );
        return {
            icon: getIconPathByType(fileType.toLowerCase()),
            instanceBlockDocumentName,
            instanceBlockDocumentId,
            blockType,
            to,
            from,
            instanceBlockDate: localTime,
            genericStepName,
            genericId,
            instanceId,
            instanceName,
            promiseType,
            genericName: genericIdToNameMap.get(genericId),
        };
    };

    const listRows = list.map((item) =>
        createData(
            item.instanceBlockDocumentName,
            item.instanceBlockDocumentId,
            item.blockType,
            item.to,
            item.from,
            item.actualDate,
            item.instanceStepName,
            item.genericId,
            item.instanceId,
            item.instanceName,
            item.promiseType
        )
    );
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        // setOpen(true);
        setselectedRow(row);
    };
    const handleOpenDocument = () => {
        handleClose();
        openDocument(
            selectedRow.instanceBlockDocumentName,
            selectedRow.instanceBlockDocumentId
        );
    };
    const handleOpenInstance = () => {
        handleClose();
        const libData = {
            genericId: selectedRow.genericId,
            genericName: selectedRow.genericStepName,
            instanceIds: [selectedRow.instanceId],
        };
        openInstance(libData);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getCell = (column, row, value, idx) => {
        if (column === "instanceBlockDocumentName")
            return (
                <div
                    id={`file_document_${idx+1}`}
                    onClick={(event) => handleClick(event, row)}
                    style={{
                        cursor: "pointer",
                        height: "24px",
                    }}
                >
                    {value}
                </div>
            );
        else return value;
    };
    const renderMenu = () => {
        return (
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleOpenDocument}>
                    <div>
                        <img
                            style={{
                                width: "15px",
                                height: "15px",
                            }}
                            className="renameIcon"
                            src="./images/open.png"
                            alt="Edit"
                            title="Open Document"
                        />
                        <span className="btn_title" title="Open Document" id="open-document-btn">
                            Open Document
                        </span>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleOpenInstance}>
                    <div>
                        <img
                            className="deleteIcon"
                            title="Open Instance"
                            src="./images/Rename.svg"
                            alt="Open Instance"
                        />
                        <span className="btn_title" title="Open Instance">
                            Open Instance
                        </span>
                    </div>
                </MenuItem>
            </Menu>
        );
    };
    return (
        <>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    height: "18rem",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <TableContainer
                    sx={{ paddingTop: "0px", height: "85%", flexBasis: "85%" }}
                    className="invisibleScroller"
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            width: column.width,
                                            fontWeight: "600",
                                        }}
                                        title={column.label}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderMenu()}
                            {listRows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row,idx) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.instanceBlockDocumentId}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={
                                                            column.id +
                                                            "_" +
                                                            row.instanceBlockDocumentId
                                                        }
                                                        align={column.align}
                                                        style={{
                                                            paddingTop: "5px",
                                                            paddingBottom:
                                                                "5px",
                                                            maxWidth:
                                                                column.width,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {column.id ===
                                                        "icon" ? (
                                                            <img
                                                                height={"25px"}
                                                                width={"25px"}
                                                                src={value}
                                                            />
                                                        ) : (
                                                            <div
                                                                className="textEllipsis"
                                                                title={value}
                                                            >
                                                                {/* {value} */}
                                                                {getCell(
                                                                    column.id,
                                                                    row,
                                                                    value,
                                                                    idx
                                                                )}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={listRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ height: "15%", flexBasis: "15%", overflow: "hidden" }}
                />
            </Paper>
        </>
    );
}
