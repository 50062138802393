const customStyleForNode = {
    // background: '#9CA8B3',
    // color: '#FFF',
    padding: 10,
    borderRadius: "50%",
    height: "65px",
    width: "65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
    fontWeight: "bold",
    //margin: "-10px",
    margin: "40px 0px 0px 0px",
    border: "5px solid royalblue",
    backgroundColor: "white",
};
const mapResponseToElements = (data) => {
    // add implementation for elements depending on API data
    if (data && data.nodeDefinition && data.linkDefinition) {
        const nodeDefinition = data.nodeDefinition;
        const linkDefinition = data.linkDefinition;
        let reactFlowElement = [];
        let reactFlowLinkElements = [];
        let positionElement = {};
        nodeDefinition.forEach((node) => {
            if (node.position !== "") {
                const xPosition = node.position.split(";")[0];
                const yPosition = node.position.split(";")[1];
                if (
                    xPosition !== "" &&
                    yPosition !== "" &&
                    yPosition !== "NaN"
                ) {
                    let entity = node.entity;
                    positionElement[entity] = { x: xPosition, y: yPosition };
                    reactFlowElement.push({
                        id: node.entity,
                        type: "circle", // api dependant
                        position: { x: xPosition, y: yPosition },
                        data: {
                            style: customStyleForNode,
                            text: node.text !== "" ? node.text : node.entity,
                            labelText: "",
                            nodeImage: node.image,
                        },
                        style: {
                            padding: 10,
                            borderRadius: "50%",
                            height: "100px",
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                            backgroundColor: "white",
                            boxShadow: "0 0 0 3px lightgrey",
                        },
                    });
                }
            }
        });
        linkDefinition.forEach((link, index) => {
            let sourceXpos = positionElement[link.sourceEntity].x;
            let targetXpos = positionElement[link.targetEntity].x;
            reactFlowElement.push({
                id: link.sourceEntity + link.targetEntity,
                source: link.sourceEntity,
                target: link.targetEntity,
                type: "custom",
                data: {
                    text: link.text,
                    linkImage: link.image,
                    linkType: "straight",
                },
                arrowHeadType: "arrowclosed",
                arrowDirection:
                    parseInt(targetXpos) > parseInt(sourceXpos)
                        ? "Right"
                        : "Left",
            });
        });
        const newElements = reactFlowElement.map((element) => {
            if (data.exception.hasOwnProperty(element.id))
                return {
                    ...element,
                    data: {
                        ...element.data,
                        color: data.exception[element.id],
                    },
                };
            else return element;
        });
        return newElements;
    }
};
const getFormattedDate = (currentDate) => {
    let documentDate = new Date(currentDate);
    let date = documentDate.getDate();
    if (date < 10) {
        date = "0" + date;
    }
    let month = documentDate.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = documentDate.getFullYear();
    let stringDate = month + "-" + date + "-" + year;
    return stringDate;
};

export { mapResponseToElements, getFormattedDate };
