import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../stylized/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getParsedDateByDateAndTime } from "../../../../../bae-client/Utils";
import ShareFile from "./AddFolderModal/ShareFile";
import { setFolderIdToNameMap } from "../../../../../actions/FolderActions";
import WorkspaceModalInfo from "../../../../stylized/workspaceModalInfo";

import {
    fetchSpaceListData,
    addSpace,
    removeAddedSpace,
    updateSpaceName,
} from "../../../../../actions/SpaceActions";
import { fetchFolderListData } from "../../../../../actions/FolderActions";
import {
    setActiveWorkspace,
    setBoardList,
    setActiveSpace,
    setWorkspaceInforRedirection,
} from "../../../../../actions";
import DialogView from "../../../../stylized/DialogView";

import TableView from "../../../../stylized/TableView";
import "../../index.css";
import AddSpaceModal from "./AddSpaceModal/AddSpaceModal";
import AlertDialog from "../../../../stylized/AlertDialog";
import Snackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import Space from "../../../../../bae-client/Space";
import { connect } from "react-redux";
import SpaceContainer from "./SpaceContainer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import http from "../../../../../bae-client/HTTP";
import SpaceManager from "../../../../../bae-client/SpaceManager";
import AddFolderModal from "./AddFolderModal";
import ComposerModal from "./ComposerModal";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";

import GridList from "./GridList";
import {
    validateName,
    validateNameLength,
    getDateByFormat,
} from "../../../../../bae-client/Utils";
import "./style.css";
import RenameSpaceModal from "./RenameSpaceModal";
import RenameFolderModal from "./RenameFolderModal";
import NoSpacePage from "./NoSpacePage";
import ConfirmationBoxView from "../../../../stylized/ConfirmationBoxView";
import config from "../../../../../config";
import MenuDropDown from "../../../../stylized/MenuDropDown";
import Move from "../communicator/chatSection/chatHistory/shareOptions/Move";
import { saveAs } from "file-saver";
import Dropdown from "react-bootstrap/Dropdown";
import Members from "./members-modal/Members";
import { set } from "lodash";
import RecentActivityManager from "../../../../../bae-client/RecentActivityManager";
import NestedModal from "../../../../stylized/nested-modal/NestedModal";
var selectedFileType = "";
function WorkspaceContainer({
    username,
    addSpace,
    fetchSpaceListData,
    setActiveWorkspace,
    setBoardList,
    removeAddedSpace,
    fetchFolderListData,
    setActiveSpace,
    updateSpaceName,
}) {
    const jsonData = [
        {
            type: "space",
            heading: "Purchase Orders",
        },
        {
            type: "space",
            heading: "Advanced Ship Notices",
        },
        {
            type: "folder",
            heading: "PO 1232453",
        },
    ];

    const spaceManager = new SpaceManager();
    const dispatch = useDispatch();

    const [spaceListValue, setSpaceListValue] = useState([]);
    const workspaceIdToName = useSelector((state) => state.workspaceIdToName);
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const chatList = useSelector((state) => state.chatList);
    const workspaceList = useSelector((state) => state.workspaceList);
    const userIdToFullName = useSelector((state) => state.userIdToFullName);
    const folderIdToName = useSelector((state) => state.folderIdToName);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const spaceListData = useSelector((state) => state.spaceListData);
    const folderListData = useSelector((state) => state.folderListData);
    spaceListData.slice().sort((a, b) => {
        return (
            Date.parse(b.createdOn.split(".")[0]) -
            Date.parse(a.createdOn.split(".")[0])
        );
    });
    const newSpace = useSelector((state) => state.newSpace);
    const activeSpace = useSelector((state) => state.activeSpace);
    const [showConfirmationModal, setshowConfirmationModal] = useState(false);
    const [mainBreadcrumb, setMainBreadcrumb] = useState(
        activeWorkspace.workspaceName || ""
    );
    const [tableRows, setTableRows] = useState([]);
    const [showUploadOption, setShowUploadOption] = useState(false);
    const [isFileFolderDeleted, setisFileFolderDeleted] = useState(0); // This state is used for deleting the File/Folder Delete activity
    const [loadPage, setLoadPage] = useState(null);
    const [isMoveableItem, setIsMoveableItem] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [recentlyCreatedSpace, setRecentlyCreatedSpace] = useState("");
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [currentFileLocation, setShowFileLocation] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [isListState, setIsListState] = useState(true);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showMembersModal, setShowMembersModal] = useState(false);
    let folderCount = 0;
    let fileCount = 0;
    let spaceCount = 0;

    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [currentWorkspace, setCurrentWorkspace] = useState({
        workspaceType: "",
    });
    const [addSpaceModalShow, setAddSpaceModalShow] = useState(false);
    const [newSpacename, setNewSpacename] = useState(null);
    const [renceUploadedFile, setRenceUploadedFile] = useState(null);
    const [renceUploadedDocumentId, setRenceUploadedDocumentId] =
        useState(null);
    const [recentlyUploadedFileNames, setRecentlyUploadedFileNames] = useState(
        []
    );
    let objectId;
    const tableFileUplode = useRef(null);
    const [selectedFolder, setSelectedFolder] = useState([]);
    const [selected, setSelected] = useState(false);

    const [newFolderName, setNewFolderName] = useState("");
    const [addFolderModalShow, setAddFolderModalShow] = useState(false);
    const [ComposerModalShow, setComposerModalShow] = useState(false);
    const [recentUploadedFolder, setRecentUploadedFolder] = useState(null);
    const [recentUploadedFolderId, setRecentUploadedFolderId] = useState(null);
    const [shareFile, setShareFile] = useState(false);
    const [gridListData, setGridListData] = useState([]);
    const [activeCard, setActiveCard] = useState(-1);
    const [changeFlag, setChangeFlag] = useState(false);
    const [parentFolderId, setParentFolderId] = useState("0");
    const [folderToIdMap, setFolderToIdMap] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [isSpaceSelected, setIsSpaceSelected] = useState(false);
    const [isFileOrFolderSelected, setIsFileOrFolderSelected] = useState(false);
    const [selectedCheckedData, setSelectedCheckedData] = useState([]);
    const [lastSelectedRowData, setLastSelectedRowData] = useState([]);
    const [updatedSpacename, setUpdatedSpacename] = useState("");
    const [rename, setRename] = useState(false);
    const [renameSpaceModalShow, setRenameSpaceModalShow] = useState(false);
    const [error, setError] = useState("");
    const [activePageFolders, setActivePageFolders] = useState([]);
    const [activeFolderItems, setActiveFolderItems] = useState([]);
    const [folderData, setFolderData] = useState(null);
    const [selectedAcceptIndex, setSelectedAcceptIndex] = useState("");
    const [emailRender, setEmailRender] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isRenameEnabled, setIsRenameEnabled] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const [renameFolderModalShow, setRenameFolderModalShow] = useState(false);
    const [selectedFolderName, setSelectedFolderName] = useState("");
    const [isHover, setIsHover] = useState(false);
    const [isFolder, setIsFolder] = useState(false);
    const [isMove, setIsMove] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [displayViewData, setDisplayViewData] = useState();
    const [membersList, setMembersList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const opensModal = () => {
        setIsModalOpen(true);
    };

    const closesModal = () => {
        setIsModalOpen(false);
    };
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };
    const openModal = () => {
        setIsOpen(true);
        infoData();
        viewData();
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (workspaceList && workspaceList.length) {
            if (activeWorkspace) {
                const currentWorkspace = workspaceList.find(
                    (workspace) =>
                        workspace.workspaceId === activeWorkspace.workspaceId
                );
                if (currentWorkspace) {
                    setCurrentWorkspace(currentWorkspace);
                } else setCurrentWorkspace({ workspaceType: "USER" });
            }
        }
    }, [workspaceList, activeWorkspace]);
    useEffect(() => {
        setParentFolderId("0");
        setSelectedRowData([]);
        setSelectedCheckedData([]);
        setShowLoader(true);
        createRowsForWorkspace();
        if (activeWorkspace.workspaceName) {
            handleWorkspaceChangeClick(activeWorkspace.workspaceName);
            setIsSpaceSelected(false);
            setSelectedSpace(null);
            setSelectedFolder(null);
            setIsFileOrFolderSelected(false);
        }
    }, [activeWorkspace && activeWorkspace.workspaceId]);
    useEffect(() => {
        selectedRowData.map((selectedRowDataItem) => {
            if (folderData[selectedRowDataItem].documentId === 0) {
                setIsFolder(true);
            } else {
                setIsFolder(false);
            }
        });
    }, [selectedRowData]);
    useEffect(() => {
        if (activeSpace !== null) {
            let spaceIndex = -1;
            let rowData = null;
            spaceListData.forEach((spaceItem, index) => {
                if (
                    parseInt(activeSpace.spaceId) ===
                    parseInt(spaceItem.spaceId)
                ) {
                    spaceIndex = index;
                    rowData = spaceItem;
                }
            });
            rowData = createData(
                rowData.spaceId,
                "space",
                rowData.spaceName,
                rowData.createdOn,
                "Open",
                rowData.updatedOn
            );
            handleSpaceSelectionClick(
                null,
                activeSpace.spaceName,
                activeSpace.spaceId,
                spaceIndex,
                rowData
            );
            setEmailRender(true);
        }
    }, [activeSpace]);

    const createData = (id, type, name, createdOn, btnName, updatedOn) => {
        return { id, type, name, createdOn, btnName, updatedOn };
    };
    useEffect(() => {
        setSelectedCheckedData([]);
        setSelectedRowData([]);
    }, [selectedSpace]);
    useEffect(() => {
        return () => {
            setIsSpaceSelected(false);
        };
    }, [selectedSpace]);

    useEffect(() => {
        if (selectedRowData.length === 1) {
            const selectedFolder = activeFolderItems.filter(
                (item) =>
                    parseInt(item.id) === selectedRowData[0] &&
                    item.type === "folder"
            );
            if (selectedFolder.length) {
                setIsRenameEnabled(true);
            } else setIsRenameEnabled(false);
        } else setIsRenameEnabled(false);
    }, [selectedRowData, activeFolderItems]);

    const defaultWorkspaceScreen = () => {
        return (
            <div
                style={{
                    height: "80vh",
                    width: "80vw",
                    borderRadius: "10px",
                    margin: "1rem",
                    background: "rgba(255, 255, 255, 1)",
                    boxShadow:
                        "0px 0px 1px 0px rgba(0, 0, 0, 0.04) 0px 2px 6px 0px rgba(0, 0, 0, 0.04) 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
                }}
            ></div>
        );
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        );
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (selected) {
            tableFileUplode.current.click();
            setSelected(false);
            setSelectedAcceptIndex("");
            handleClose();
        }
    }, [selectedAcceptIndex]);
    useEffect(() => {
        dispatch(setWorkspaceInforRedirection(null));
    }, []);
    const handleChange = (selectedIndex, event) => {
        selectedFileType = selectedIndex;
        setSelected(true);
        setSelectedAcceptIndex(selectedIndex);
    };

    const addNewFolder = () => {
        if (error) return;
        if (newFolderName.trim().length > 0) {
            if (
                activePageFolders.includes(newFolderName.toUpperCase().trim())
            ) {
                setError("Folder name already exists");
                return;
            }
            if (!validateNameLength(newFolderName)) {
                let msg = "Folder name should be less than 100 characters";
                setError(msg);
                return;
            }
            setShowLoader(true);
            setAddFolderModalShow(false);
            let spaceId = selectedSpace.id;
            let parentId = parentFolderId;
            let data = {
                spaceId: spaceId,
                objectName: newFolderName.trim(),
                parentRowId: parentId,
            };

            let url = "spacePersistence/folder";
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    if (resp) {
                        let key = Object.keys(resp);
                        let recentActivity = new RecentActivityManager();
                        let dataobj = {
                            objectType: "FOLDER",
                            objectId: parseInt(key[0]),
                            description: `Created ${data.objectName}`,
                        };
                        recentActivity
                            .createRecentActivity(dataobj)
                            .then((recentRes) => {})
                            .catch((err) => console.log(err.error));
                        Mixpanel.callFolderManagementEvent("Add");
                        showAlertView(
                            "",
                            "Folder added successfully",
                            "alert_folder",
                            "btn_folder_close"
                        );
                        let idToName = folderIdToName;
                        idToName.set(parseInt(key[0]), data.objectName);
                        dispatch(setFolderIdToNameMap(idToName));
                        setRecentUploadedFolder(resp);
                        setRecentUploadedFolderId(key[0]);
                        setNewFolderName("");
                        setShowLoader(false);
                        setIsFileOrFolderSelected(false);
                    }
                })
                .catch((err) => {
                    setNewFolderName("");
                    showAlertView(
                        "Operation Failed",
                        err && err.error
                            ? err.error
                            : "Something went wrong, please try again!",
                        "alert_folder",
                        "btn_folder_close",
                        true
                    );
                    setShowLoader(false);
                });
        } else {
            setError("Please enter folder name");
            return;
        }
        if (!validateNameLength(newFolderName)) {
            let msg = "Folder name should be less than 100 characters";
            setError(msg);
            return;
        }
    };
    const extensions = [
        "pdf",
        "jpg",
        "png",
        "txt",
        "gif",
        "docx",
        "mp3",
        "mp4",
        "doc",
        "html",
        "htm",
        "xml",
        "pptx",
        "xlsx",
        "ai",
    ];
    let acceptableFormats = [
        ".pdf",
        ".jpg",
        ".png",
        ".text",
        ".docx",
        ".mp4",
        ".doc",
        ".html",
        ".htm",
        ".xml",
    ];
    let acceptableFormatsValues = {
        PDF: {
            value: "PDF",
            ext: ".pdf",
        },
        JPG: {
            value: "JPG",
            ext: ".jpg",
        },
        PNG: {
            value: "PNG",
            ext: ".png",
        },
        TXT: {
            value: "TXT",
            ext: ".txt",
        },
        DOCS: {
            value: "DOCS",
            ext: ".docs",
        },
        MP4: {
            value: "MP4",
            ext: ".mp4",
        },
        DOC: {
            value: "DOC",
            ext: ".docx",
        },
        HTML: {
            value: "HTML",
            ext: ".html",
        },
        HTM: {
            value: "HTM",
            ext: ".htm",
        },
        XML: {
            value: "XML",
            ext: ".xml",
        },
        AI: {
            value: "AI",
            ext: ".ai",
        },
    };
    var itemList = [];
    let finalList = {};
    if (config.showPdf) {
        finalList[".pdf"] = acceptableFormatsValues.PDF;
        itemList.push(".pdf");
    }
    if (config.showJpg) {
        finalList[".jpg"] = acceptableFormatsValues.JPG;
        itemList.push(".jpg");
    }
    if (config.showPng) {
        finalList[".png"] = acceptableFormatsValues.PNG;
        itemList.push(".png");
    }
    if (config.showText) {
        finalList[".text"] = acceptableFormatsValues.TXT;
        itemList.push(".text");
    }
    if (config.showDocx) {
        finalList[".docx"] = acceptableFormatsValues.DOCS;
        itemList.push(".docx");
    }
    if (config.showMp4) {
        finalList[".mp4"] = acceptableFormatsValues.MP4;
        itemList.push(".mp4");
    }
    if (config.showDoc) {
        finalList[".doc"] = acceptableFormatsValues.DOC;
        itemList.push(".doc");
    }
    if (config.showHtml) {
        finalList[".html"] = acceptableFormatsValues.HTML;
        itemList.push(".html");
    }
    if (config.showHtm) {
        finalList[".htm"] = acceptableFormatsValues.HTM;
        itemList.push(".htm");
    }
    if (config.showXml) {
        finalList[".xml"] = acceptableFormatsValues.XML;
        itemList.push(".xml");
    }
    if (config.showAi) {
        finalList[".ai"] = acceptableFormatsValues.XML;
        itemList.push(".ai");
    }
    const validateFileType = (fileName) => {
        const fileType = fileName.split(".").pop();
        const supportedFileTypes = extensions;
        const supportedExt = selectedAcceptIndex.trim();
        return supportedFileTypes.includes(fileType.trim().toLowerCase());
    };
    const uploadDocumentsInSpace = async (chosenFile) => {
        let spaceId = selectedSpace.id;
        let fileError = false;
        let recUploadedFileNames = [];
        chosenFile.forEach((files) => {
            let fileNames = [];
            let fileSizeArray = [];
            // files.forEach((file) => {
            let fileName = files.name;
            fileNames.push(fileName);
            let fileSize = files.size;
            let maxSizeLimit = window.runtimeConfig.maxFileSize; //in mb
            let fileSizeInMB = fileSize / 1024 / 1024;
            fileSizeArray.push(fileSizeInMB);
            let isValidFileType = validateFileType(fileName);
            if (!isValidFileType) {
                fileError = true;
                showAlertView(
                    "Alert",
                    "File not supported yet.",
                    "fileUploadErroMsg",
                    "fileUploadErroBtn",
                    true
                );
                return;
            }
            if (fileSizeInMB > maxSizeLimit) {
                fileError = true;
                showAlertView(
                    "Alert",
                    "File size should be maximum " + maxSizeLimit + " MB",
                    "fileUploadErroMsg",
                    "fileUploadErroBtn",
                    true
                );
                return;
            }
            // });
            if (fileError === true) return;

            setShowLoader(true);
            let documentId = -1;
            let documentHeaderId = -1;
            let parentId = parentFolderId;
            const data = new FormData();
            // chosenFile.forEach((files) => {
            // files.forEach((file) => {
            data.append("file", files, encodeURIComponent(fileName));
            // });
            // });

            setShowLoader(true);
            http.uploadFile(
                data,
                `rest/spacePersistence/file?spaceId=${spaceId}&folderId=${parentId}`,
                "POST"
            )
                .then((response) => {
                    if (response.status === 200) {
                        fileNames.forEach((fileName, index) => {
                            recUploadedFileNames.push(fileName);
                            let fileExt = fileName.split(".").pop();
                            let fileSize = fileSizeArray[index] + "MB";
                            Mixpanel.callFileManagementEvent(
                                "Upload",
                                fileExt,
                                fileSize
                            );
                        });

                        showAlertView(
                            "",
                            "File added successfully",
                            "alert_file",
                            "btn_file_close"
                        );
                        setRecentlyUploadedFileNames(recUploadedFileNames);
                        setRenceUploadedFile(data);
                        setRenceUploadedDocumentId(
                            response.data ? response.data : data
                        );
                        setShowLoader(false);
                        setSelectedAcceptIndex("");
                    } else {
                        showAlertView(
                            "",
                            "Some server side issue",
                            "alert_file",
                            "btn_file_close"
                        );
                        setShowLoader(false);
                    }
                })
                .catch((err) => {
                    showAlertView(
                        "Operation Failed",
                        err && err.error
                            ? err.error
                            : "Something went wrong, please try again!",
                        "alert_file",
                        "btn_file_close",
                        true
                    );
                    setShowLoader(false);
                });
        });
    };
    const onChangeFileUpload = (event) => {
        let formFileData = event.target ? event.target.files : event;

        if (formFileData.length > 0) {
            let chosenFile = Array.from(formFileData);
            uploadDocumentsInSpace(chosenFile);
            setIsFileOrFolderSelected(false);
        }
    };
    const addNewFile = () => {
        // addFile();
        tableFileUplode.current.click();
    };
    async function addFile() {
        const pickerOpts = {
            // types: [
            //     {
            //         description: "File",
            //         accept: {
            //             "file/*": config.allowedDocTypes,
            //         },
            //     },
            // ],
            // excludeAcceptAllOption: true,
            multiple: true,
        };

        let fileHandle;
        fileHandle = await window.showOpenFilePicker(pickerOpts);
        let selectedFile = fileHandle.map(async (file) => {
            const showfile = await file.getFile();
            return showfile;
        });
        const fileData = await Promise.all(selectedFile);

        onChangeFileUpload([fileData]);
    }
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const changeFolderToIdArray = (map) => {
        setFolderToIdMap(map);
    };
    const changeParentFolderId = (parentFolderId) => {
        setParentFolderId(parentFolderId);
    };
    const changeFolderStructure = (flag) => {
        setChangeFlag(flag);
    };
    const addFolderBreadCrumb = (folderName) => {
        let breadcrumb = `${mainBreadcrumb}`;
        breadcrumb = breadcrumb + "/" + folderName;
        setMainBreadcrumb(breadcrumb);
    };
    const handleBreadCrumbClick = (e) => {
        let breadcrumb = `${mainBreadcrumb}`;
        let breadCrumbItems = breadcrumb.split("/").map((item) => item.trim());
        setIsRenameEnabled(false);
        setIsSpaceSelected(false);
        setIsFileOrFolderSelected(false);
        const item = e.target.getAttribute("id-data")
            ? e.target.getAttribute("id-data").toString()
            : null;
        if (item === "ws" && breadCrumbItems.length > 1) {
            breadCrumbItems.length = 1;
            setMainBreadcrumb(breadCrumbItems.join("/"));
            setSelectedSpace(null);
            setSelectedFolder(null);
            setActiveCard(-1);
            setFolderToIdMap([]);
            setParentFolderId("0");
            setLastSelectedRowData([]);
            setSelectedCheckedData([]);
            setSelectedRowData([]);
        } else if (item === "s" && breadCrumbItems.length > 1) {
            breadCrumbItems.length = 2;
            setMainBreadcrumb(breadCrumbItems.join("/"));
            setChangeFlag(true);
            setFolderToIdMap([]);
            setParentFolderId("0");
            setLastSelectedRowData([]);
            setSelectedCheckedData([]);
            setSelectedRowData([]);
            setSelectedFolder(null);
        } else if (breadCrumbItems.length > 2) {
            breadCrumbItems.length = folderToIdMap.indexOf(item) + 3;
            setMainBreadcrumb(breadCrumbItems.join("/"));
            const map = [...folderToIdMap];
            map.length =
                breadCrumbItems.length > 1
                    ? folderToIdMap.indexOf(item) + 1
                    : 0;
            let parentId = map.length ? map[map.length - 1] : "0";
            setParentFolderId(parentId);
            setFolderToIdMap(map);
            setLastSelectedRowData([]);
            setSelectedCheckedData([]);
            setSelectedRowData([]);
        }
    };
    const renderBreadCrumRow = () => {
        let breadcrumb = `${mainBreadcrumb}`;
        return (
            <Breadcrumbs
                aria-label="breadcrumb"
                itemsBeforeCollapse={2}
                style={{
                    paddingTop: "1rem",
                }}
            >
                {breadcrumb.split("/").map((item, index) => {
                    let id =
                        index > 1
                            ? folderToIdMap[index - 2]
                            : index === 0
                            ? "ws"
                            : "s";
                    const breadCrumbLength = breadcrumb.split("/").length;
                    const isLastItem = breadCrumbLength - 1 === index;
                    return (
                        <Link
                            title={item}
                            id-data={id}
                            key={item + index}
                            className={
                                index < 2
                                    ? index === 0
                                        ? "mainBreadcrumb mainPageIconRadius"
                                        : "subBreadcrumb"
                                    : ""
                            }
                            underline={isLastItem ? "none" : "hover"}
                            color="inherit"
                            onClick={isLastItem ? null : handleBreadCrumbClick}
                            style={{
                                cursor: isLastItem ? "default" : "pointer",
                                fontFamily: "Montserrat",
                                whiteSpace: "pre",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                lineHeight: "16px",
                                textDecoration: isLastItem ? "none" : "",
                                color: isLastItem
                                    ? "#212121"
                                    : isHover
                                    ? "#0e7adf"
                                    : "#212121",
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                className={
                                    index < 2
                                        ? index === 0
                                            ? "workspaceIcon"
                                            : "spaceIcon"
                                        : "folderIcon"
                                }
                                src={
                                    index < 2
                                        ? index === 0
                                            ? "./images/workspace-icon-color.svg"
                                            : "./images/spaceIcon.svg"
                                        : index === 2 &&
                                          currentWorkspace.workspaceName ===
                                              "Emails"
                                        ? "./images/email-icon 1.svg"
                                        : "./images/FOLDER.svg"
                                }
                                style={{
                                    marginRight: "10px",
                                    marginBottom: "4px",
                                }}
                                alt=""
                            />
                            {item.trim()}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        );
    };
    const addStarFill = (id, star) => {
        let url = "space/starred";
        let data = {
            spaceId: id,
            isStarred: star,
        };
        http.sendRestRequest(url, "PUT", data)
            .then((resp) => {
                setShowLoader(false);

                fetchSpaceListData(activeWorkspace.workspaceId);
            })
            .catch((err) => {
                showAlertView(
                    "Operation Failed",
                    err && err.error
                        ? err.error
                        : "Something went wrong, please try again!",
                    "alert_folder",
                    "btn_folder_close",
                    true
                );
                setShowLoader(false);
            });
    };
    const handleCheckboxClick = (e, index, id, rowData, selectedIds) => {
        let breadcrumb = `${mainBreadcrumb}`;
        let breadCrumbItems = breadcrumb.split("/").map((item) => item.trim());

        if (
            rowData &&
            rowData.type === "space" &&
            breadCrumbItems.length === 1 &&
            selectedIds &&
            selectedIds.length
        ) {
            setIsSpaceSelected(true);
        } else setIsSpaceSelected(false);
        setLastSelectedRowData(rowData);
        setSelectedCheckedData(selectedIds);
    };

    const handleLastSelectedRowData = (data) => setLastSelectedRowData(data);
    const handleisSpaceSelected = (data) => {
        setIsSpaceSelected(data);
    };

    const handleFileOrFolderSelected = (data) => {
        setIsFileOrFolderSelected(data);
    };
    const handleCheckAllClick = (checkedAll, selectedIds) => {
        setIsSpaceSelected(checkedAll);
        if (!checkedAll) {
            setLastSelectedRowData([]);
            setSelectedRowData([]);
        }
        setSelectedCheckedData(selectedIds ? selectedIds : []);
    };

    const handleSpaceSelectionClick = (e, name, id, index, rowData) => {
        setShowLoader(true);
        let slashindex = mainBreadcrumb.indexOf("/");
        if (slashindex !== -1) {
            let oldMainBreadcrumb = mainBreadcrumb.slice(0, slashindex - 1);
            let newBreadcrumb = `${oldMainBreadcrumb} / ${name}`;
            setMainBreadcrumb(newBreadcrumb);
            setSelectedSpace(rowData);
            setShowLoader(false);
        } else {
            let newBreadcrumb = `${mainBreadcrumb} / ${name}`;
            setMainBreadcrumb(newBreadcrumb);
            setSelectedSpace(rowData);
            setShowLoader(false);
        }
    };
    const handleWorkspaceChangeClick = (name) => {
        setIsRenameEnabled(false);
        setMainBreadcrumb(name);
    };
    const setText = (value, id, event) => {
        if (value && value.length > 100)
            setError("Name should not exceed 100 char length");
        else setError("");
        switch (id) {
            case "updateSpacename":
                {
                    setUpdatedSpacename(value);
                }
                break;
            case "spacename":
                {
                    setNewSpacename(value);
                }
                break;
            case "newFolderName": {
                setNewFolderName(value);
            }
            default:
                break;
        }
    };
    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
    };
    const updateNewSpaceData = () => {
        setShowLoader(true);
        let activeSpaceResponse = newSpace || [];
        let activeSpace = null;
        let activeWSObject = {};
        activeWSObject["activeWorkspaceID"] = activeWorkspace.workspaceId;
        activeWSObject["activeWorkspaceName"] = activeWorkspace.workspaceName;
        activeWSObject["spaceList"] = spaceListData;
        activeWSObject["workspacePersistenceId"] =
            activeWorkspace.workspacePersistenceId;
        let spaceList = spaceListData;
        let spaceArray = [];
        let selectedSpaceIndex = -1;
        spaceList.forEach((item, i) => {
            let spaceInstance = new Space();
            let options = {};
            options["spaceName"] = item.spaceName; //spaceNames[i]
            options["spaceId"] = item.spaceId; //spaceIDs[i]
            options["archive"] = item.archive;
            spaceInstance.setData(options);
            spaceArray.push(spaceInstance);
            if (activeSpaceResponse.spaceId === item.spaceId) {
                activeSpace = options;
                selectedSpaceIndex = i;
            }
        });

        setActiveWorkspace(activeWSObject);
        setBoardList(spaceArray);
        setActiveSpace(null);
        setActiveSpace(activeSpace);
        setNewSpacename(null);
        createRowsForWorkspace();
        createGridList();
        return spaceList;
    };
    useEffect(() => {
        let spaces = updateNewSpaceData();
        if (recentlyCreatedSpace !== "") {
            let recentSpace = spaces.find(
                (item) => item.spaceName.trim() === recentlyCreatedSpace.trim()
            );
            let recentActivity = new RecentActivityManager();
            let dataObj = {
                objectType: "SPACE",
                objectId: recentSpace.spaceId,
                description: `Created ${recentSpace.spaceName}`,
            };
            recentActivity
                .createRecentActivity(dataObj)
                .then((recentRes) => {})
                .catch((err) => console.log(err.error));
            setRecentlyCreatedSpace("");
        }
    }, [spaceListData]);
    const addNewSpace = () => {
        if (error) return;
        let spaces = spaceListData.map((space) =>
            space.spaceName.toUpperCase()
        );
        if (newSpacename === null || newSpacename.trim().length === 0) {
            setError("Please Enter Space Name");
            return;
        }
        if (spaces.includes(newSpacename.trim().toUpperCase())) {
            setNewSpacename("");
            setError("Space name already exists!");
            return;
        }
        if (!validateNameLength(newFolderName)) {
            let msg = "Space name should be less than 100 characters";
            setError(msg);
            return;
        }
        setShowLoader(true);
        closeAddSpaceModal();
        let data = {};
        data = { spaceName: newSpacename.trim() };
        addSpace("", activeWorkspace.workspaceId, data)
            .then((resp) => {
                setRecentlyCreatedSpace(data.spaceName);
                Mixpanel.callSpaceManagementEvent("Add");
                fetchSpaceListData(activeWorkspace.workspaceId);
                removeAddedSpace();
                showAlertView(
                    "",
                    "Space added successfully",
                    "alert_space",
                    "btn_space_close"
                );
                setIsSpaceSelected(false);
            })
            .catch((err) => {
                setNewSpacename(null);
                showAlertView(
                    "Operation Failed",
                    err && err.error
                        ? err.error
                        : "Something went wrong, please try again!",
                    "alert_space",
                    "btn_space_close",
                    true
                );
                setShowLoader(false);
            });
    };
    const invokingElement = (event) => {
        // addFile();
        addNewFile();
    };
    const handleAddSpaceClick = () => {
        setAddSpaceModalShow(true);
    };
    const closeAddSpaceModal = () => {
        setAddSpaceModalShow(false);
        setNewSpacename("");
        setError("");
    };
    const handleAddFolderClick = () => {
        setAddFolderModalShow(true);
        setIsMove("");
    };
    const handleShareClick = () => {
        if (selectedRowData.length === 1) setShareFile(true);
        else
            showAlertView(
                "Share",
                "Multiple items cannot be shared",
                "shareFileFolder",
                "",
                true
            );
    };
    const infoData = () => {
        let url =
            "workspace/starredObjects?workspaceId=" +
            activeWorkspace.workspaceId;

        http.sendRestRequest(url, "GET")
            .then((resp) => {
                const merged = [...resp.spaces, ...resp.folders];
                setSpaceListValue(merged);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const viewData = () => {
        let url =
            "userInteractions/workspace/recentlyViewed?workspaceId=" +
            activeWorkspace.workspaceId;

        http.sendRestRequest(url, "GET")
            .then((resp) => {
                setDisplayViewData(resp);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDownloadClick = (event) => {
        setAnchorEl(null);
        selectedRowData.map((selectedRowDataItem) => {
            if (folderData[selectedRowDataItem].documentId === 0) {
                showAlertView(
                    "Download",
                    "Folder cannot be downloaded",
                    "download",
                    "",
                    true
                );
                return;
            }
            if (selectedSpace !== null) {
                if (!selectedRowData.length) {
                    showAlertView(
                        "",
                        "Please select at least single file/folder!"
                    );
                    return;
                }
                const baseUrl = window.location.origin;
                setShowLoader(true);
                http.sendRestRequest(
                    `document/documentDetail?documentId=${folderData[selectedRowDataItem].documentId}`
                )
                    .then((response) => {
                        setShowLoader(false);
                        setCurrentShowFileName(response.documentName);
                        saveAs(
                            baseUrl +
                                "/" +
                                response.documentLocation +
                                "/" +
                                folderData[selectedRowDataItem].objectName,
                            folderData[selectedRowDataItem].objectName
                        );
                        let fileExt = folderData[selectedRowDataItem].objectName
                            .split(".")
                            .pop();
                        Mixpanel.callFileManagementEvent("Download", fileExt);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        console.log(error);
                        showAlertView("", error.error);
                    });
            }
        });
        return;
    };

    const closeAddFolderClick = () => {
        setAddFolderModalShow(false);
        setNewFolderName("");
        setError("");
    };
    const closeShareClick = () => {
        setShareFile(false);
    };

    const handleRenameSpaceClick = () => {
        selectedCheckedData.length === 1
            ? setRenameSpaceModalShow(true)
            : alert("Multiple items cannot be selected");
    };

    const handleFolderRenameClick = () => {
        let selectedFolder = activeFolderItems.filter(
            (item) => selectedFolderId == item.id
        );
        if (selectedFolder && selectedFolder.length) {
            setSelectedFolderName(selectedFolder[0].name);
        }
        setRenameFolderModalShow(true);
    };
    const closeRenameSpaceClick = () => {
        setRenameSpaceModalShow(false);
        setNewSpacename("");
        setUpdatedSpacename("");
        setError("");
    };
    const closeRenameFolderClick = () => {
        setRenameFolderModalShow(false);
        setError("");
    };
    const renderAddFolderOrFileIcons = () => {
        let selectedItem =
            selectedFolder !== null ? selectedFolder : selectedSpace;
        if (selectedItem === undefined) selectedItem = { owner: "" };
        return (
            <>
                <div
                    title={"Add Folder"}
                    onClick={
                        !isReadOnlyUser &&
                        // selectedItem.owner === "" &&
                        currentWorkspace.workspaceType === "USER"
                            ? handleAddFolderClick
                            : () => {}
                    }
                    id={"btn_space_addfolder"}
                    style={{
                        cursor:
                            !isReadOnlyUser &&
                            // selectedItem.owner === "" &&
                            currentWorkspace.workspaceType === "USER"
                                ? "pointer"
                                : "no-drop",
                    }}
                >
                    <img
                        src="./images/Add Folder.svg"
                        className="bw_plusButton"
                        alt="Add Folder"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    >
                        Add Folder
                    </span>
                </div>
                <div
                    style={{
                        marginLeft: "15px",
                        cursor:
                            !isReadOnlyUser &&
                            // selectedItem.owner === "" &&
                            currentWorkspace.workspaceType === "USER"
                                ? "pointer"
                                : "no-drop",
                    }}
                    role="button"
                    tabIndex={0}
                    title={"Upload File"}
                    onClick={
                        !isReadOnlyUser &&
                        // selectedItem.owner === "" &&
                        currentWorkspace.workspaceType === "USER"
                            ? invokingElement
                            : () => {}
                    }
                    id={"btn_space_addfile"}
                >
                    <img
                        src="./images/Upload File.svg"
                        id="icons"
                        alt="Add File"
                        className="bw_plusButton"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    >
                        Upload File
                    </span>
                </div>
                <div
                    // className="cursorPointer"
                    style={{
                        marginLeft: "15px",
                        cursor:
                            !isReadOnlyUser &&
                            // selectedItem.owner === "" &&
                            currentWorkspace.workspaceType === "USER"
                                ? "pointer"
                                : "no-drop",
                    }}
                    title={"New Note"}
                    onClick={
                        !isReadOnlyUser &&
                        // selectedItem.owner === "" &&
                        currentWorkspace.workspaceType === "USER"
                            ? () => setComposerModalShow(true)
                            : () => {}
                    }
                    id={"btn_space_addnote"}
                >
                    <img
                        src="./images/Add Folder.svg"
                        alt="New Note"
                        className="bw_plusButton"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor:
                                !isReadOnlyUser &&
                                // selectedItem.owner === "" &&
                                currentWorkspace.workspaceType === "USER"
                                    ? "pointer"
                                    : "no-drop",
                        }}
                    >
                        New Note
                    </span>
                </div>
            </>
        );
    };
    const renderAddSpaceIcons = () => {
        return (
            <div
                className={
                    isReadOnlyUser ||
                    currentWorkspace.workspaceType === "SYSTEM"
                        ? "readOnly"
                        : "cursorPointer"
                }
                title={"Add Space"}
                onClick={
                    isReadOnlyUser ||
                    currentWorkspace.workspaceType === "SYSTEM"
                        ? null
                        : handleAddSpaceClick
                }
                id={"btn_space_addspace"}
            >
                <img
                    src="./images/plus.svg"
                    className={
                        isReadOnlyUser ||
                        currentWorkspace.workspaceType === "SYSTEM"
                            ? "bw_plusButton plusIcon readOnly"
                            : "bw_plusButton plusIcon"
                    }
                    title="Add Space"
                ></img>
                <span
                    id="title"
                    className={
                        isReadOnlyUser ||
                        currentWorkspace.workspaceType === "SYSTEM"
                            ? "addLabel newFontMontserrat readOnly"
                            : "addLabel newFontMontserrat"
                    }
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    title="Add Space"
                >
                    Add Space
                </span>
            </div>
        );
    };
    const handleSelectedArchiveItems = (selectedIds) => {
        setSelectedRowData(selectedIds);
    };

    const archiveClickHandler = (e) => {
        if (selectedSpace !== null) {
            if (!selectedRowData.length) {
                showAlertView("", "Please select at least single file/folder!");
                return;
            }
            setAlertDialogContent({
                title: "Archive Item",
                message: "Are you sure you want to archive the selected item?",
                isAlert: true,
                buttonName: "Archive",
                buttonId: "button_modal_archive",
            });
            setAlertDialogShow(true);
        } else {
            if (activeWorkspace && activeWorkspace.workspaceId) {
                setAlertDialogContent({
                    title: "Archive Item",
                    message:
                        "Are you sure you want to archive the selected item?",
                    isAlert: true,
                    buttonName: "Archive",
                    buttonId: "button_modal_archive",
                });
                setAlertDialogShow(true);
            }
        }
    };
    const moveAttachment = (shareObject, folderStructure, typeOfMsg) => {
        setIsMoveableItem(true);
        alert("test");
    };

    const deleteClickHandler = (e) => {
        if (selectedSpace !== null) {
            if (!selectedRowData.length) {
                showAlertView("", "Please select at least single file/folder!");
                return;
            }
            setAlertDialogContent({
                title: "Delete Item",
                message: "Are you sure you want to Delete the selected item?",
                isAlert: true,
                buttonName: "Delete",
                buttonId: "button_modal_delete",
            });
            setAlertDialogShow(true);
        } else {
            if (activeWorkspace && activeWorkspace.workspaceId) {
                setAlertDialogContent({
                    title: "Delete Item",
                    message:
                        "Are you sure you want to delete the selected item?",
                    isAlert: true,
                    buttonName: "Delete",
                    buttonId: "button_modal_delete",
                });
                setAlertDialogShow(true);
            }
        }
    };
    const deleteItems = (deleteItemList) => {
        if (selectedSpace !== null) {
            return new Promise((resolve, reject) => {
                let url =
                    "spacePersistence/objects?spaceId=" + selectedSpace.id;
                let body = deleteItemList;
                http.sendRestRequest(url, "DELETE", body)
                    .then((resp) => {
                        resolve(resp);
                        setIsFileOrFolderSelected(false);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } else if (activeWorkspace && activeWorkspace.workspaceId) {
            return new Promise((resolve, reject) => {
                let url =
                    "space/spaces?workspaceId=" + activeWorkspace.workspaceId;
                let body = deleteItemList;
                http.sendRestRequest(url, "DELETE", body)
                    .then((resp) => {
                        resolve(resp);
                        setIsSpaceSelected(false);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }
    };
    const archiveItems = (archiveItemList) => {
        setShowLoader(true);
        if (selectedSpace !== null) {
            return new Promise((resolve, reject) => {
                let url =
                    "spacePersistence/object/?spaceId=" + selectedSpace.id;
                let body = archiveItemList;

                http.sendRestRequest(url, "PUT", body)

                    .then((resp) => {
                        resolve(resp);
                        setIsFileOrFolderSelected(false);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } else if (activeWorkspace && activeWorkspace.workspaceId) {
            return new Promise((resolve, reject) => {
                let url =
                    "space/archive?workspaceId=" + activeWorkspace.workspaceId;
                let body = archiveItemList;
                http.sendRestRequest(url, "PUT", body)
                    .then((resp) => {
                        resolve(resp);
                        setIsSpaceSelected(false);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }
    };

    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            if (isDelete === "DELETE") {
                const body =
                    selectedSpace !== null
                        ? selectedRowData
                        : selectedCheckedData;
                setShowLoader(true);
                deleteItems(body)
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            if (selectedSpace !== null)
                                fetchFolderListData(selectedSpace.id);
                            if (activeWorkspace && activeWorkspace.workspaceId)
                                fetchSpaceListData(activeWorkspace.workspaceId);

                            setSelectedRowData([]);
                            Mixpanel.callItemManagementEvent(
                                "Delete",
                                parseInt(body.length)
                            );
                            showAlertView(
                                "Delete Item",
                                `${body.length} Item${
                                    body.length > 1 ? "s" : ""
                                } deleted successfully!`
                            );
                            setActiveCard(-1);
                            isFileFolderDeleted === 0
                                ? setisFileFolderDeleted(-1)
                                : setisFileFolderDeleted(0); // useEffect for this state change is written in spaceContianer
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err && err.error
                                ? err.error
                                : "Something went wrong, please try again!",
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            } else {
                const body =
                    selectedSpace !== null
                        ? selectedRowData
                        : selectedCheckedData;
                archiveItems(body)
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            if (selectedSpace !== null)
                                fetchFolderListData(selectedSpace.id);
                            if (activeWorkspace && activeWorkspace.workspaceId)
                                fetchSpaceListData(activeWorkspace.workspaceId);
                            setSelectedRowData([]);
                            setActiveCard(-1);
                            isFileFolderDeleted === 0
                                ? setisFileFolderDeleted(-1)
                                : setisFileFolderDeleted(0);

                            Mixpanel.callItemManagementEvent(
                                "Archive",
                                parseInt(body.length)
                            );
                            showAlertView(
                                "Archive Item",
                                `${body.length} Item${
                                    body.length > 1 ? "s" : ""
                                } archived successfully!`
                            );
                        } else {
                            showAlertView(
                                "Archive Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        setShowLoader(false);
                        showAlertView(
                            "Operation Failed",
                            err && err.error
                                ? err.error
                                : "Something went wrong, please try again!",
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };
    const handleEditSpaceName = (spaceName) => {
        let spaces = spaceListData.map((space) =>
            space.spaceName.toUpperCase()
        );
        if (spaceName === null || spaceName.trim().length === 0) {
            setError("Please Enter Space Name");
            return;
        }
        if (spaces.includes(spaceName.trim().toUpperCase())) {
            setNewSpacename("");
            setError("Space name already exists!");
            return;
        }
        if (!validateNameLength(spaceName)) {
            let msg = "Space name should be less than 100 characters";
            setError("Space name should be less than 100 characters");
            return;
        }
        let data = {};
        closeRenameSpaceClick();
        setShowLoader(true);
        data = {
            spaceName: spaceName.trim(),
            spaceId: selectedCheckedData[0],
        };
        updateSpaceName("", activeWorkspace.workspaceId, data)
            .then(() => {
                setShowLoader(false);
                Mixpanel.callSpaceManagementEvent("Rename");
                renderSpaceListforWorkspace();
                fetchSpaceListData(activeWorkspace.workspaceId);
                showAlertView("", "Space name updated successfully");
                setUpdatedSpacename("");
                setSelectedCheckedData([]);
                setIsSpaceSelected(false);
                setIsFileOrFolderSelected(false);
            })
            .catch((err) => {
                showAlertView(
                    "Operation Failed",
                    err && err.error
                        ? err.error
                        : "Something went wrong, please try again!",
                    "",
                    "",
                    true
                );
                setUpdatedSpacename("");
                setShowLoader(false);
            });
    };

    const handleEditFolderName = (updatedFolderName) => {
        if (updatedFolderName.trim().length > 0) {
            if (
                activePageFolders.includes(
                    updatedFolderName.toUpperCase().trim()
                )
            ) {
                setError("Folder name already exists");
                return;
            }
            if (!validateNameLength(updatedFolderName)) {
                let msg = "Folder name should be less than 100 characters";
                setError(msg);
                return;
            }
            setShowLoader(true);
            setRenameFolderModalShow(false);
            let spaceId = selectedSpace.id;
            let parentId = parentFolderId;
            let data = {
                spacePersistenceId: selectedFolderId,
                objectName: updatedFolderName.trim(),
            };
            let url = "spacePersistence/folder";
            http.sendRestRequest(url, "PUT", data)
                .then((resp) => {
                    if (resp) {
                        Mixpanel.callFolderManagementEvent("Rename");
                        showAlertView(
                            "",
                            "Folder renamed successfully",
                            "alert_folder",
                            "btn_folder_close"
                        );
                        let key = Object.keys(resp);
                        fetchFolderListData(selectedSpace.id);
                        setIsFileOrFolderSelected(false);
                        setShowLoader(false);
                        setIsRenameEnabled(false);
                        setError("");
                    }
                })
                .catch((err) => {
                    setNewFolderName("");
                    showAlertView(
                        "Operation Failed",
                        err && err.error
                            ? err.error
                            : "Something went wrong, please try again!",
                        "alert_folder",
                        "btn_folder_close",
                        true
                    );
                    setShowLoader(false);
                });
        } else {
            setError("Please enter folder name");
            return;
        }
    };

    const leftsideIcons = () => {
        let enable = true;
        if (currentWorkspace.workspaceType === "SYSTEM") {
            enable = false;
        } else if (selectedRowData.length > 0) {
            selectedRowData.map((id) => {
                if (
                    isReadOnlyUser ||
                    (folderData[id] &&
                        folderData[id].isShared &&
                        folderData[id].sharedBy !==
                            parseInt(sessionStorage.getItem("userId")))
                ) {
                    enable = false;
                    return;
                }
            });
        } else {
            spaceListData.map((item) => {
                if (selectedCheckedData.includes(item.spaceId)) {
                    if (
                        isReadOnlyUser ||
                        (item.isShared &&
                            item.sharedBy !==
                                parseInt(sessionStorage.getItem("userId")))
                    ) {
                        // item.isShared && item.sharedBy !== parseInt(sessionStorage.getItem('userId'))
                        enable = false;
                        return;
                    }
                }
            });
        }
        return (
            <div
                className={
                    !isSpaceSelected && selectedSpace === null
                        ? "doCenter col-9 btn_space_dropdown_none"
                        : isSpaceSelected
                        ? "doCenter col-9 btn_space_dropdown_iPad"
                        : !isFileOrFolderSelected
                        ? "doCenter col-9 btn_space_dropdown_iPad2"
                        : "doCenter col-9 btn_space_dropdown"
                }
                style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    justifyContent: "flex-start",
                    height: "5vh",
                }}
            >
                {selectedSpace === null
                    ? renderAddSpaceIcons()
                    : renderAddFolderOrFileIcons()}
                {selectedRowData &&
                    selectedRowData.forEach((item) => {
                        if (
                            folderData[item] &&
                            folderData[item].documentId === 0
                        ) {
                            folderCount++;
                        } else {
                            fileCount++;
                        }
                    })}

                {(isSpaceSelected &&
                    !selectedSpace &&
                    selectedCheckedData &&
                    isListState) ||
                (folderCount === 1 && fileCount === 0) ? (
                    <div
                        // className="cursorPointer"
                        style={{
                            marginLeft: "15px",
                            display:
                                (isSpaceSelected &&
                                    !selectedSpace &&
                                    isListState &&
                                    selectedCheckedData.length === 1) ||
                                isFileOrFolderSelected
                                    ? // ||
                                      // isRenameEnabled
                                      "block"
                                    : "none",
                            cursor: "pointer",
                        }}
                        title={"Rename"}
                        id={"div_table_rename"}
                        onClick={
                            (isSpaceSelected &&
                            !selectedSpace &&
                            selectedCheckedData &&
                            (isListState
                                ? !isFileOrFolderSelected
                                : isFileOrFolderSelected)
                                ? handleRenameSpaceClick
                                : null) || handleFolderRenameClick
                        }
                    >
                        <img
                            src="./images/Rename.svg"
                            id="icons"
                            alt="rename_img"
                            className="bw_plusButton"
                            style={{
                                cursor: "pointer",
                            }}
                        ></img>
                        <span
                            id="title"
                            className="addLabel newFontMontserrat"
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            Rename
                        </span>
                    </div>
                ) : null}
                <div
                    // className="cursorPointer"
                    style={{
                        marginLeft: "15px",
                        display:
                            (isSpaceSelected &&
                                !selectedSpace &&
                                isListState) ||
                            isFileOrFolderSelected
                                ? "block"
                                : "none",
                        cursor: "pointer",
                    }}
                    title={"Archive"}
                    id={"div_table_archive"}
                    onClick={archiveClickHandler}
                >
                    <img
                        src="./images/Archive (1).svg"
                        id="icons"
                        alt="archive_img"
                        className="bw_plusButton"
                        style={{
                            cursor: "pointer",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        Archive
                    </span>
                </div>

                <div
                    // className="cursorPointer"
                    style={{
                        marginLeft: "15px",
                        display:
                            (isSpaceSelected && !selectedSpace) ||
                            isFileOrFolderSelected
                                ? "block"
                                : "none",

                        cursor: "pointer",
                    }}
                    title={"Delete"}
                    id={"div_table_trash"}
                    onClick={deleteClickHandler}
                >
                    <img
                        src="./images/trash 2.svg"
                        id="icons"
                        alt="delete_img"
                        className="bw_plusButton"
                        style={{
                            cursor: "pointer",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        Delete
                    </span>
                </div>

                <div
                    style={{
                        marginLeft: "15px",
                        display:
                            (isFileOrFolderSelected && isListState) ||
                            (isFileOrFolderSelected &&
                                !isSpaceSelected &&
                                !isListState)
                                ? "block"
                                : "none",
                        cursor: "pointer",
                    }}
                    title={"Share"}
                    id={"div_table_share"}
                    onClick={handleShareClick}
                >
                    <img
                        src="./images/share 2.svg"
                        id="icons"
                        alt="share_img"
                        className="bw_plusButton"
                        style={{
                            cursor: "pointer",
                        }}
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        Share
                    </span>
                </div>

                <div
                    style={{
                        marginLeft: "15px",
                        display:
                            (isFileOrFolderSelected && isListState) ||
                            (isFileOrFolderSelected &&
                                !isSpaceSelected &&
                                !isListState)
                                ? "block"
                                : "none",
                        cursor: enable ? "pointer" : "no-drop",
                    }}
                    id={"div_table_move"}
                >
                    <Move
                        setIsMove={setIsMove}
                        activeWorkspace={activeWorkspace}
                        folderData={folderData}
                        itemSpace={selectedSpace}
                        isFileOrFolderSelected={setIsFileOrFolderSelected}
                        selectedRowData={selectedRowData}
                        setSelectedRowData={setSelectedRowData}
                        selectedCheckedData={selectedCheckedData}
                        parentFolderId={parentFolderId}
                        lastSelectedRowData={
                            activeFolderItems.length
                                ? activeFolderItems.filter(
                                      (item) =>
                                          parseInt(item.id) ===
                                          selectedRowData[
                                              selectedRowData.length - 1
                                          ]
                                  )[0]
                                : {}
                        }
                        enable={enable}
                    ></Move>
                </div>
                <div
                    className="cursorPointer"
                    style={{
                        marginLeft: "15px",
                        display:
                            (!isFolder &&
                                isFileOrFolderSelected &&
                                isListState) ||
                            (isFileOrFolderSelected &&
                                !isSpaceSelected &&
                                !isListState)
                                ? "block"
                                : "none",
                    }}
                    title={"Download"}
                    id={"div_table_download"}
                    onClick={handleDownloadClick}
                >
                    <img
                        src="./images/fileTypeIcon/download 1.svg"
                        id="icons"
                        alt="delete_img"
                        className="bw_plusButton"
                    ></img>
                    <span id="title" className="addLabel newFontMontserrat">
                        Download
                    </span>
                </div>

                <div
                    className="cursorPointer"
                    style={{ marginLeft: "15px" }}
                    id={
                        !isSpaceSelected && selectedSpace === null
                            ? "btn_space_dropdown_none"
                            : isSpaceSelected
                            ? "btn_space_dropdown_iPad"
                            : !isFileOrFolderSelected
                            ? "btn_space_dropdown_iPad2"
                            : "btn_space_dropdown"
                    }
                >
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="Secondary"
                            id="dropdown-basic title"
                            className="addLabel newFontMontserrat"
                        >
                            <img
                                src="./images/three-dots-icon.svg"
                                className="bw_threeDots"
                                alt="Add Folder"
                            ></img>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ backgroundColor: "white" }}>
                            <Dropdown.Item
                                //className="cursorPointer"
                                style={{
                                    display:
                                        (isSpaceSelected && !selectedSpace) ||
                                        isRenameEnabled
                                            ? "block"
                                            : "none",
                                    cursor: "pointer",
                                }}
                                title={"Rename"}
                                id={"dropdown_editspace"}
                                onClick={
                                    (isSpaceSelected &&
                                    !selectedSpace &&
                                    selectedCheckedData &&
                                    (isListState
                                        ? !isFileOrFolderSelected
                                        : isFileOrFolderSelected)
                                        ? handleRenameSpaceClick
                                        : null) || handleFolderRenameClick
                                }
                            >
                                <img
                                    src="./images/Rename.svg"
                                    id="icons"
                                    alt="rename_img"
                                    className="bw_plusButton"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className="addLabel newFontMontserrat"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Rename
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                //className={enable ? "cursorPointer" : "readOnly"}
                                title={"Upload File"}
                                onClick={invokingElement}
                                id={"dropdown_addfile"}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src="./images/Upload File.svg"
                                    id="icons"
                                    alt="Add File"
                                    className={"bw_plusButton"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className={"addLabel newFontMontserrat"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Upload File
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                //className={enable ? "cursorPointer" : "readOnly"}
                                title={"New Note"}
                                onClick={() => setComposerModalShow(true)}
                                id={"dropdown_addnote"}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src="./images/Add Folder.svg"
                                    alt="New Note"
                                    className={"bw_plusButton"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className={"addLabel newFontMontserrat"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    New Note
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                // className={enable ? "cursorPointer" : "readOnly"}
                                style={{
                                    display:
                                        (isSpaceSelected &&
                                            !selectedSpace &&
                                            isListState) ||
                                        isFileOrFolderSelected
                                            ? "block"
                                            : "none",
                                    cursor: "pointer",
                                }}
                                title={"Archive"}
                                id={"dropdown_archive"}
                                onClick={archiveClickHandler}
                            >
                                <img
                                    src="./images/Archive (1).svg"
                                    id="icons"
                                    alt="archive_img"
                                    className={"bw_plusButton"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className={"addLabel newFontMontserrat"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Archive
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                // className={enable ? "cursorPointer" : "readOnly"}
                                style={{
                                    display:
                                        (isSpaceSelected && !selectedSpace) ||
                                        isFileOrFolderSelected
                                            ? "block"
                                            : "none",
                                    cursor: "pointer",
                                }}
                                title={"Delete"}
                                id={"dropdown_trash"}
                                onClick={deleteClickHandler}
                            >
                                <img
                                    src="./images/trash 2.svg"
                                    id="icons"
                                    alt="delete_img"
                                    className={"bw_plusButton"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className={"addLabel newFontMontserrat"}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Delete
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                // className={"cursorPointer"}
                                style={{
                                    display:
                                        (isFileOrFolderSelected &&
                                            isListState) ||
                                        (isFileOrFolderSelected &&
                                            !isSpaceSelected &&
                                            !isListState)
                                            ? "block"
                                            : "none",

                                    cursor: "pointer",
                                }}
                                title={"Share"}
                                id={"dropdown_share"}
                                onClick={handleShareClick}
                            >
                                <img
                                    src="./images/share 2.svg"
                                    id="icons"
                                    alt="share_img"
                                    className="bw_plusButton"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                ></img>
                                <span
                                    id="title"
                                    className="addLabel newFontMontserrat"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Share
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                // className={"cursorPointer"}
                                style={{
                                    display:
                                        (isFileOrFolderSelected &&
                                            isListState) ||
                                        (isFileOrFolderSelected &&
                                            !isSpaceSelected &&
                                            !isListState)
                                            ? "block"
                                            : "none",

                                    cursor: enable ? "pointer" : "no-drop",
                                }}
                                id={"dropdown_move"}
                            >
                                <Move
                                    setIsMove={setIsMove}
                                    activeWorkspace={activeWorkspace}
                                    folderData={folderData}
                                    itemSpace={selectedSpace}
                                    isFileOrFolderSelected={
                                        setIsFileOrFolderSelected
                                    }
                                    enable={enable}
                                    selectedRowData={selectedRowData}
                                    setSelectedRowData={setSelectedRowData}
                                    selectedCheckedData={selectedCheckedData}
                                    parentFolderId={parentFolderId}
                                    lastSelectedRowData={
                                        activeFolderItems.length
                                            ? activeFolderItems.filter(
                                                  (item) =>
                                                      parseInt(item.id) ===
                                                      selectedRowData[
                                                          selectedRowData.length -
                                                              1
                                                      ]
                                              )[0]
                                            : {}
                                    }
                                ></Move>
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="cursorPointer"
                                style={{
                                    display:
                                        (!isFolder &&
                                            isFileOrFolderSelected &&
                                            isListState) ||
                                        (isFileOrFolderSelected &&
                                            !isSpaceSelected &&
                                            !isListState) ||
                                        isFolder
                                            ? "block"
                                            : "none",
                                }}
                                title={"Download"}
                                id={"dropdown_download"}
                                onClick={handleDownloadClick}
                            >
                                <img
                                    src="./images/fileTypeIcon/download 1.svg"
                                    id="icons"
                                    alt="delete_img"
                                    className="bw_plusButton"
                                ></img>
                                <span
                                    id="title"
                                    className="addLabel newFontMontserrat"
                                >
                                    Download
                                </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    };
    const rightsideIcons = () => {
        let gridlistTooltip = isListState ? "Grid view" : "List View";
        let gridlistImageSource = isListState ? "gridView" : "listViewIcon";
        let expandCollapseTooltip = !isInfoVisible ? "Information" : "Collapse";
        let expandCollapseImageSource = !isInfoVisible
            ? "info-circle 1"
            : "double-right 3";
        return (
            <div className="doCenter" style={{ marginLeft: "4%" }}>
                {/* <div
                    className="cursorPointer"
                    title={gridlistTooltip}
                    // style={{ marginLeft: "60px"}}
                    onClick={() => {
                        // setIsSpaceSelected(false);
                        // setIsFileOrFolderSelected(false);
                        // setIsListState(!isListState);
                        setShowMembersModal(true);
                    }}
                    id={"btn_space_gridView"}
                >
                    <img src={`./images/${gridlistImageSource}.svg`}></img>
                </div> */}
            </div>
        );
    };
    const renderIconRow = () => {
        let columns = isInfoVisible ? "col-9" : "col-12";
        return (
            <div className="row ml-1 mr-1 mt-4">
                {leftsideIcons()}
                {/* {rightsideIcons()} */}

                <div
                    style={
                        workspaceIdToName.get(activeWorkspace.workspaceId) ===
                        "Emails"
                            ? {
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginLeft: "5%",
                              }
                            : {
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginLeft: "7%",
                              }
                    }
                >
                    {workspaceIdToName.get(activeWorkspace.workspaceId) ===
                    "Emails" ? (
                        <button
                            className={
                                isReadOnlyUser
                                    ? "readOnly btn share modalButton"
                                    : "btn share modalButton"
                            }
                            type="button"
                            id={"info"}
                            style={{
                                minWidth: "8rem",
                                height: "38px",
                                margin: "0rem",
                                marginLeft: "10px",
                                background: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "black",
                            }}
                            title="Move Rules"
                            value={"Move Rules"}
                            onClick={isReadOnlyUser ? null : opensModal}
                        >
                            <p
                                style={{
                                    paddingTop: "7px",
                                    paddingLeft: "4px",
                                }}
                            >
                                Move Rules
                            </p>
                        </button>
                    ) : null}

                    <button
                        className={
                            isReadOnlyUser
                                ? "readOnly btn share modalButton"
                                : "btn share modalButton"
                        }
                        type="button"
                        id={"info"}
                        style={{
                            minWidth: "6rem",
                            height: "38px",
                            margin: "0rem",
                            marginLeft: "10px",
                            background: "white",
                            textTransform: "capitalize",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black",
                        }}
                        title="info"
                        value={"Info"}
                        onClick={isReadOnlyUser ? null : openModal}
                    >
                        <img src="./images/info-circle.svg" alt=""></img>
                        <p style={{ paddingTop: "7px", paddingLeft: "4px" }}>
                            Info
                        </p>
                    </button>
                    {workspaceIdToName.get(activeWorkspace.workspaceId) ===
                    "Emails" ? null : (
                        <button
                            className={
                                "btn share modalButton cursorPointer" +
                                (currentWorkspace.workspaceType === "SYSTEM"
                                    ? " cursorNotAllowed"
                                    : "")
                            }
                            type="button"
                            id={"Invite"}
                            style={{
                                minWidth: "6rem",
                                height: "38px",
                                margin: "0rem",
                                marginLeft: "10px",
                                background: "#0798EA",
                                textTransform: "capitalize",
                            }}
                            title="Invite"
                            value={"Invite"}
                            onClick={
                                currentWorkspace.workspaceType === "SYSTEM"
                                    ? () => {}
                                    : () => setShowMembersModal(true)
                            }
                        >
                            Invite
                        </button>
                    )}
                </div>
            </div>
        );
    };
    const newHeadCells = [
        {
            id: "Spacae",
            numeric: true,
            disablePadding: true,
            width: 250,
            label: "Space Name",
            align: "left",
        },
        {
            id: "DateLastModified",
            numeric: false,
            disablePadding: true,
            width: 200,
            label: "Date Last Modified",
            align: "center",
        },
        {
            id: "DateCreated",
            numeric: true,
            disablePadding: true,
            width: 200,
            label: "Date Created",
            align: "center",
        },
        {
            id: "shared",
            numeric: false,
            disablePadding: true,
            width: 100,
            label: "Shared",
            align: "center",
        },

        {
            id: "owner",
            numeric: false,
            disablePadding: true,
            width: 150,
            label: "Owner",
            align: "center",
        },
        {
            id: "Starred",
            numeric: false,
            disablePadding: true,
            width: 150,
            label: "Starred",
            align: "center",
        },
    ];

    const getOwnerName = (ownerId) => {
        let owner = null;
        chatList.forEach((chat) => {
            chat.participantUserIds.forEach((participant) => {
                if (participant.id === ownerId) {
                    owner = participant;
                }
            });
        });
        return owner !== null ? owner.firstName + " " + owner.lastName : "";
        // let owner = chatList.filter(
        //     (item) => item.messageBy === ownerId
        // )[0].participantUserIds.filter(
        //     (item) => item.id === ownerId
        // )[0];
        // return owner.firstName + " " + owner.lastName;
    };
    const createRowsForWorkspace = () => {
        let tableRows = [];
        if (spaceListData && spaceListData.length > 0) {
            spaceListData.forEach((space, index) => {
                tableRows.push({
                    btn: "Open",
                    id: space.spaceId,
                    name: space.spaceName,
                    createdOn: getParsedDateByDateAndTime(space.createdOn),
                    updatedOn: getParsedDateByDateAndTime(space.updatedOn),
                    type: "space",
                    shared: space.isShared ? "Shared" : "Private",
                    isStarred: space.isStarred,
                    owner:
                        space.sharedBy === 0 ||
                        space.sharedBy ===
                            parseInt(sessionStorage.getItem("userId"))
                            ? ""
                            : getOwnerName(space.sharedBy),
                });
            });
        }
        setTableRows(tableRows);
        setLoadPage(tableRows.length ? "space" : "noSpace");
        setLastSelectedRowData([]);
        setSelectedCheckedData([]);
        setSelectedRowData([]);
        setShowLoader(false);
    };
    const renderInformationPanel = (hiddenColumn) => {
        return (
            <div className={hiddenColumn + " p-0 spaceListInfoWrapper"}>
                <div
                    className="ml-3 mr-3 spaceListInfoSection doCenter"
                    style={{ marginTop: "0.9rem", background: "FFFFFF" }}
                >
                    <div className="spacelistInfoTitle">Help Guide</div>
                    <div className="spaceListInfoHrLine"></div>
                    <div
                        className="spacelistInfoRows"
                        style={{ paddingLeft: "30px" }}
                    >
                        {selectedCheckedData.length === 0 ? (
                            <div className="spacelistInfoHeader">
                                {activeWorkspace.workspaceName}
                            </div>
                        ) : selectedCheckedData.length === 1 ? (
                            <>
                                <div className="spacelistInfoHeader">
                                    {lastSelectedRowData.name}
                                </div>
                                <div className="spacelistInfoHeader">
                                    Date Last Opened
                                </div>
                                <div className="spacelistInfoRow">
                                    {lastSelectedRowData.createdOn}
                                </div>
                                <div className="spacelistInfoHeader">
                                    Date Created
                                </div>
                                <div className="spacelistInfoRow">
                                    {lastSelectedRowData.updatedOn}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="spacelistInfoHeader">
                                    {activeWorkspace.workspaceName}
                                </div>
                                <div className="spacelistInfoRow">{`${selectedCheckedData.length} Spaces Selected`}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    const createGridList = () => {
        let gridList = [];
        if (spaceListData !== undefined) {
            spaceListData.forEach((space) => {
                gridList.push({
                    name: space.spaceName,
                    id: space.spaceId,
                    time: "17/06/2021",
                    type: "space",
                    imageSource: "./images/spaceIcon.svg",
                });
            });
        }
        setGridListData(gridList);
        setShowLoader(false);
    };
    const changeActiveCard = (spaceInstance, e, id) => {
        setActiveCard(id);
        let slashindex = mainBreadcrumb.indexOf("/");
        if (slashindex !== -1) {
            let oldMainBreadcrumb = mainBreadcrumb.slice(0, slashindex - 1);
            let newBreadcrumb = `${oldMainBreadcrumb} / ${spaceInstance.name}`;
            setMainBreadcrumb(newBreadcrumb);
            setSelectedSpace(spaceInstance);
        } else {
            let newBreadcrumb = `${mainBreadcrumb} / ${spaceInstance.name}`;
            setMainBreadcrumb(newBreadcrumb);
            setSelectedSpace(spaceInstance);

            setIsSpaceSelected(true);
            setIsFileOrFolderSelected(false);
        }
    };

    const changeSelectedCard = (name, id, selectedId) => {
        setActiveCard(id);
        setIsSpaceSelected(name);
        setIsFileOrFolderSelected(name);
        setSelectedCheckedData([parseInt(selectedId)]);
    };
    const renderSpaceGridList = () => {
        return (
            <GridList
                gridListData={gridListData}
                selectedCard={activeCard}
                changeSelectedCard={changeSelectedCard}
                changeActiveCard={changeActiveCard}
            ></GridList>
        );
    };
    const renderSpaceListforWorkspace = () => {
        let columns = isInfoVisible ? "col-9" : "col-12";
        let hiddenColumn = isInfoVisible ? "col-3" : "col-0";
        return (
            <div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="info_btn" onClick={openModal}>
                        <img src="./images/info-circle.svg" alt=""></img>
                        <p className="info-text">Info</p>
                    </button>
                </div> */}
                <div className="row ml-1 mr-1 mt-4">
                    <div
                        className={columns + " p-0 workspaceIconRow"}
                        style={{
                            height: "75vh",
                            background: "#FFFFFF",
                            boxShadow: "1px 1px 20px 4px rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                        }}
                    >
                        {loadPage === "noSpace" && (
                            <NoSpacePage
                                handleAddSpaceClick={handleAddSpaceClick}
                                currentWorkspace={currentWorkspace}
                            ></NoSpacePage>
                        )}
                        {loadPage === "space" &&
                            (isListState ? (
                                <TableView
                                    title={false}
                                    rows={tableRows}
                                    addStarFill={addStarFill}
                                    setTableRows={setTableRows}
                                    columns={newHeadCells}
                                    handleCheckboxClick={handleCheckboxClick}
                                    handleCheckAllClick={handleCheckAllClick}
                                    deleteRow={(e, index) =>
                                        this.deleteRow(e, index)
                                    }
                                    handleSpaceSelectionClick={
                                        handleSpaceSelectionClick
                                    }
                                    tableWrapperClass="spacelistTable"
                                    tableRowClass={"spacelistTableRow"}
                                    spaceList={true}
                                    folderBreadCrumb={mainBreadcrumb}
                                ></TableView>
                            ) : (
                                renderSpaceGridList()
                            ))}
                    </div>
                    {isInfoVisible
                        ? renderInformationPanel(hiddenColumn)
                        : null}
                </div>
            </div>
        );
    };

    const setActiveFolderList = (foldersArray) => {
        if (foldersArray && foldersArray.length) {
            setActiveFolderItems(foldersArray);
            const list = foldersArray
                .filter((item) => item.type === "folder")
                .map((item) => item.name.toUpperCase().trim());
            setActivePageFolders(list);
        } else setActivePageFolders([]);
    };
    const openConfirmationBox = () => {
        setshowConfirmationModal(true);
    };
    const hideConfirmationBox = () => {
        setshowConfirmationModal(false);
    };
    const renderConfirmationBox = () => {
        return (
            <ConfirmationBoxView
                open={showConfirmationModal}
                title={"Delete Item"}
                body={"Are you sure you want to delete the selected item?"}
                handleOk={deleteClickHandler}
                handleCancle={hideConfirmationBox}
            ></ConfirmationBoxView>
        );
    };

    return (
        <>
            <div className="contentPaneBottom invisibleScroller">
                <div className="w-100 h-100 pl-4 pr-4">
                    {renderBreadCrumRow()}
                    {renderIconRow()}
                    {renderConfirmationBox()}
                    {selectedSpace === null ? (
                        renderSpaceListforWorkspace()
                    ) : (
                        <SpaceContainer
                            viewData={viewData}
                            setIsFileOrFolderSelected={
                                setIsFileOrFolderSelected
                            }
                            displayViewData={displayViewData}
                            activeWorkspace={activeWorkspace}
                            infoData={infoData}
                            workspaceName={activeWorkspace}
                            spaceListValue={spaceListValue}
                            setComposerModalShows={setComposerModalShow}
                            setShowFileLocation={setShowFileLocation}
                            uploadDocumentsInSpace={uploadDocumentsInSpace}
                            handleAddFolderClick={handleAddFolderClick}
                            addNewFile={addFile}
                            selectedRowData={setSelectedRowData}
                            invokingElement={invokingElement}
                            selectedSpace={selectedSpace}
                            isFileOrFolderSelected={setIsFileOrFolderSelected}
                            setIsRenameEnabled={setIsRenameEnabled}
                            setSelectedFolderId={setSelectedFolderId}
                            isSnackbarOpen={showSnackbar}
                            emailRender={emailRender}
                            setIsMove={setIsMove}
                            setEmailRender={setEmailRender}
                            closeSnackbar={() => {
                                setShowSnackbar(false);
                            }}
                            selectedSpaceId={selectedSpace.id}
                            isInfoVisible={isInfoVisible}
                            isFileFolderDeleted={isFileFolderDeleted}
                            isListState={isListState}
                            addFolderBreadCrumb={addFolderBreadCrumb}
                            changeFlag={changeFlag}
                            changeFolderStructure={changeFolderStructure}
                            renceUploadedFile={renceUploadedFile}
                            recentDocumentId={renceUploadedDocumentId}
                            changeParentFolderId={changeParentFolderId}
                            handleLastSelectedRowData={
                                handleLastSelectedRowData
                            }
                            lastSelectedRowData={lastSelectedRowData}
                            archivedItems={handleSelectedArchiveItems}
                            recentUploadedFolder={recentUploadedFolder}
                            recentUploadedFolderId={recentUploadedFolderId}
                            folderBreadCrumb={mainBreadcrumb}
                            folderToIdMap={folderToIdMap}
                            changeFolderToIdArray={changeFolderToIdArray}
                            handleFileOrFolderSelected={
                                handleFileOrFolderSelected
                            }
                            handleisSpaceSelected={handleisSpaceSelected}
                            setLastSelectedRowData={setLastSelectedRowData}
                            changeIsFileFolderSelected={
                                handleFileOrFolderSelected
                            }
                            setActiveFolderList={setActiveFolderList}
                            setFolderData={setFolderData}
                            parentFolderId={parentFolderId}
                            setSelectedFolder={setSelectedFolder}
                            currentWorkspace={currentWorkspace}
                            recentlyUploadedFileNames={
                                recentlyUploadedFileNames
                            }
                            setRecentlyUploadedFileNames={
                                setRecentlyUploadedFileNames
                            }
                        ></SpaceContainer>
                    )}
                </div>
                <AddSpaceModal
                    addSpaceModalShow={addSpaceModalShow}
                    closeAddSpaceModal={closeAddSpaceModal}
                    setText={setText}
                    addNewSpace={addNewSpace}
                    error={error}
                ></AddSpaceModal>
                <MenuDropDown
                    id="spaceUploadeDocument"
                    onclose={handleClose}
                    anchorEl={anchorEl}
                    items={finalList}
                    invokerHTML={invokingElement}
                    onchange={handleChange}
                />
                <AddFolderModal
                    showFolderModal={addFolderModalShow}
                    closeModal={closeAddFolderClick}
                    setText={setText}
                    addNewFolder={addNewFolder}
                    error={error}
                />
                {ComposerModalShow ? (
                    <ComposerModal
                        setComposerModalShow={setComposerModalShow}
                        selectedSpace={selectedSpace}
                        parentFolderId={parentFolderId}
                        isSpaceSelected={selectedSpace}
                        noteString={""}
                        show={ComposerModalShow}
                        onHide={() => {
                            setComposerModalShow(false);
                            setIsFileOrFolderSelected(false);
                        }}
                    ></ComposerModal>
                ) : null}
                <ShareFile
                    showShareModal={shareFile}
                    closeModal={closeShareClick}
                    error={error}
                    space={selectedSpace}
                    activeWorkspace={activeWorkspace}
                    parentFolderId={parentFolderId}
                    lastSelectedRowData={
                        activeFolderItems.length
                            ? activeFolderItems.filter(
                                  (item) =>
                                      parseInt(item.id) ===
                                      selectedRowData[
                                          selectedRowData.length - 1
                                      ]
                              )[0]
                            : {}
                    }
                    folderData={folderData}
                />
                <RenameSpaceModal
                    rename={setRename}
                    modalShow={renameSpaceModalShow}
                    closeModal={closeRenameSpaceClick}
                    spaceName={
                        spaceListData.length &&
                        spaceListData.filter((space) =>
                            selectedCheckedData.includes(space.spaceId)
                        )[0]?.spaceName
                    }
                    handleUpdatedSpaceNameSubmit={handleEditSpaceName}
                    error={error}
                />
                {renameFolderModalShow && (
                    <RenameFolderModal
                        modalShow={renameFolderModalShow}
                        closeModal={closeRenameFolderClick}
                        folderName={selectedFolderName}
                        selectedObjectData={selectedRowData}
                        activeFolderItems={activeFolderItems}
                        handleUpdatedFolderNameSubmit={handleEditFolderName}
                        error={error}
                    />
                )}
                <input
                    type="file"
                    id="file"
                    ref={tableFileUplode}
                    hidden={true}
                    accept={selectedAcceptIndex}
                    onChange={onChangeFileUpload}
                    onClick={(event) => {
                        event.currentTarget.value = null;
                    }}
                    multiple
                />
                {renderAlertModal()}
                <DialogView
                    show={showUploadOption}
                    size="lg"
                    handleClose={handleCloseUploadDialog}
                    showTitle={false}
                    showFooter={false}
                    onBackdropClick={false}
                ></DialogView>
                <Snackbar
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    alertMessage={alertDialogContent.message}
                ></Snackbar>
                <Loader show={showLoader} />
                <Members
                    setMembersList={setMembersList}
                    showMembersModal={showMembersModal}
                    onHide={setShowMembersModal}
                    membersList={[]}
                ></Members>
                {isOpen ? (
                    <WorkspaceModalInfo
                        open={isOpen}
                        onHide={setShowMembersModal}
                        workspaceName={activeWorkspace}
                        spaceListValue={spaceListValue}
                        handleOpen={openModal}
                        handleClose={setIsOpen}
                        displayViewData={displayViewData}
                        membersList={membersList}
                    ></WorkspaceModalInfo>
                ) : null}
            </div>
            {isModalOpen && (
                <NestedModal
                    open={isModalOpen}
                    onClose={setIsModalOpen}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}
                />
            )}
        </>
    );
}
const mapDispatchToProps = () => {
    return {
        setActiveSpace,
        setActiveWorkspace,
        addSpace,
        removeAddedSpace,
        fetchSpaceListData,
        setBoardList,
        fetchFolderListData,
        updateSpaceName,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(WorkspaceContainer);
