import React, { Component } from "react";

class IconView extends Component {
    constructor(props) {
        super(props);
    }
    handleClickEvent = (e) => {
        if (this.props.onclick !== undefined) {
            this.props.onclick(e);
        }
    };
    render() {
        return (
            <div className="doCenter" onClick={this.handleClickEvent}>
                <img
                    id={this.props.id}
                    src={this.props.src}
                    className={this.props.className}
                ></img>
            </div>
        );
    }
}

export default IconView;
