import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import CustomDropdown from "../../../../../../stylized/CustomDropdown";
import CustomDateDropdown from "../../../../../../stylized/CustomDateDropdown";
import { getDateByRange } from "../../../../../../../bae-client/Utils";
import AlertDialog from "../../../../../../stylized/AlertDialog";
import ChipsView from "../../../../../../stylized/ChipsView";
import SearchBarView from "./SearchBarView";
import { FormControl } from "@mui/material";
import {
    setSelectedGenericDropdownValue,
    setSelectedInstanceDropdownValue,
    setSelectedTransactionDropdownValue,
    setSelectedPromiseDropdownValue,
    setSelectedExceptionDropdownValue,
    setSelectedExceptionStatusDropdownValue,
    setSelectedToDropdownValue,
    setDashboardSearchText,
    setSelectedFromDropdownValue,
    setSelectedTimeDropdownValue,
} from "../../../../../../../actions/DashboardActions";
import {
    setSelectedGenericDropdownValueFilterOne,
    setSelectedInstanceDropdownValueFilterOne,
    setSelectedTransactionDropdownValueFilterOne,
    setSelectedPromiseDropdownValueFilterOne,
    setSelectedExceptionDropdownValueFilterOne,
    setSelectedExceptionStatusDropdownValueFilterOne,
    setSelectedToDropdownValueFilterOne,
    setDashboardSearchTextFilterOne,
    setSelectedFromDropdownValueFilterOne,
    setSelectedTimeDropdownValueFilterOne,
    setSelectedGenericDropdownValueFilterTwo,
    setSelectedInstanceDropdownValueFilterTwo,
    setSelectedTransactionDropdownValueFilterTwo,
    setSelectedPromiseDropdownValueFilterTwo,
    setSelectedExceptionDropdownValueFilterTwo,
    setSelectedExceptionStatusDropdownValueFilterTwo,
    setSelectedToDropdownValueFilterTwo,
    setDashboardSearchTextFilterTwo,
    setSelectedFromDropdownValueFilterTwo,
    setSelectedTimeDropdownValueFilterTwo,
} from "../../../../../../../actions/FlowCompareActions";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../../../../stylized/Loader";
import CustomSearchDropdown from "../../../../../../stylized/CustomDropDown/index";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
    position: "absolute",
    top: "59%",
    left: "53%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "432px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "8px",
};

const modalTitleStyle = {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.017em",
    textAlign: "left",
};

const applyButtonStyle = {
    width: "75px",
    height: "36px",
    padding: "6px 16px",
    gap: "8px",
    borderRadius: "4px 0px 0px 0px",
    backgroundColor: "#0B5CD7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const dropdownStyles = {
    width: "360px",
    height: "41px",
    padding: "7px 10px",
    gap: "0px",
    borderRadius: "4.32px 0px 0px 0px",
    border: "1.08px solid #D7DEEA",
};
const resetButtonStyle = {
    width: "148px",
    height: "36px",
    padding: "6px 16px",
    gap: "8px",
    borderRadius: "4px 0px 0px 0px",
    border: "1px solid #D7DEEA",
    backgroundColor: "white",
    opacity: "0.8", // Assuming you meant a slight transparency, adjust as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "24px",
};

const useStyles = makeStyles({
    root: {
        // // maxWidth: "9rem",
        // display:"flex",
        // width: "276px !important",
        // margin: "8px",
        // borderRadius: "6px !important",
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
    moreBtn: {
        // // maxWidth: "9rem",
        // display:"flex",
        // width: "276px !important",
        // margin: "8px",
        // borderRadius: "6px !important",
        // maxWidth: "9rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "97%",
    },
    dateRoot: {
        marginBottom: "5%",
        marginLeft: "0.5%",
    },
    compareDate: {
        marginBottom: "7%",
        // marginLeft: "6%",
    },
});

function formatDate(date) {
    let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

export default function HeaderView1({
    handleSubmitFilter,
    alertData2,
    alertData1,
    clearDashboardData,
    isCompareView,
    filterName,
    setFilterName,
    filterNo,
    alertData,
    exceptionSteps,
    filterErr,
    setFilterErr,
    setAlertDisableDate = () => {},
}) {
    const dropdownStyle = {
        marginBottom: "16px", // Add margin bottom to create space between dropdowns
    };

    const classes = useStyles();
    const dispatch = useDispatch();
    const filterRawDataRedux = useSelector(
        (state) => state.DashboardDCFilterData
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleMoreClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const searchText = useSelector((state) => {
        if (filterNo === "one") {
            return state.dashboardSearchTextFilterOne;
        } else if (filterNo === "two") {
            return state.dashboardSearchTextFilterTwo;
        } else {
            return state.searchDashboardText;
        }
    });
    const selectedGenericDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedGenericDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedGenericDropdownValueFilterTwo;
        } else {
            return state.selectedGenericDropdownValue;
        }
    });
    const selectedInstanceDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedInstanceDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedInstanceDropdownValueFilterTwo;
        } else {
            return state.selectedInstanceDropdownValue;
        }
    });
    const selectedTransactionDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedTransactionDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedTransactionDropdownValueFilterTwo;
        } else {
            return state.selectedTransactionDropdownValue;
        }
    });
    const selectedFromDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedFromDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedFromDropdownValueFilterTwo;
        } else {
            return state.selectedFromDropdownValue;
        }
    });
    const selectedToDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedToDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedToDropdownValueFilterTwo;
        } else {
            return state.selectedToDropdownValue;
        }
    });
    const selectedPromiseTypeDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedPromiseDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedPromiseDropdownValueFilterTwo;
        } else {
            return state.selectedPromiseDropdownValue;
        }
    });
    const selectedExceptionDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedExceptionDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedExceptionDropdownValueFilterTwo;
        } else {
            return state.selectedExceptionDropdownValue;
        }
    });
    const selectedExceptionStatusDDValue = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedExceptionStatusDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedExceptionStatusDropdownValueFilterTwo;
        } else {
            return state.selectedExceptionStatusDropdownValue;
        }
    });
    const selectedTimeFilter = useSelector((state) => {
        if (filterNo === "one") {
            return state.selectedTimeDropdownValueFilterOne;
        } else if (filterNo === "two") {
            return state.selectedTimeDropdownValueFilterTwo;
        } else {
            return state.selectedTimeDropdownValue;
        }
    });
    const [dateErr, setDateErr] = useState(false);
    const [genericList, setGenericList] = useState([]);
    const [instanceList, setInstanceList] = useState([]);
    const [clearTimeDrp, setClearTimeDrp] = useState(-1);
    const [transactionList, setTransactionList] = useState([]);
    const [fromValuesList, setFromValuesList] = useState([]);
    const [toValuesList, setToValuesList] = useState([]);
    const [chipsArray, setChipsArray] = useState([]);
    const [selectedTimeState, setSelectedTimeState] = useState({});
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [searchTextState, setSearchTextState] = useState("");
    const [exportData, setExportData] = useState([]);
    const [isDataExist, setIsDataExist] = useState(false);
    const [
        selectedGenericDropdownLocalValue,
        setSelectedGenericDropdownLocalValue,
    ] = useState(null);
    const [
        selectedInstanceDropdownLocalValue,
        setSelectedInstanceDropdownLocalValue,
    ] = useState([]);
    const [
        selectedTransactionDropdownLocalValue,
        setSelectedTransactionDropdownLocalValue,
    ] = useState([]);
    const [
        selectedPromiseDropdownLocalValue,
        setSelectedPromiseDropdownLocalValue,
    ] = useState([]);
    const [
        selectedExceptionDropdownLocalValue,
        setSelectedExceptionDropdownLocalValue,
    ] = useState([]);
    const [
        selectedExceptionStatusDropdownLocalValue,
        setSelectedExceptionStatusDropdownLocalValue,
    ] = useState([]);
    const [selectedToDropdownLocalValue, setSelectedToDropdownLocalValue] =
        useState([]);
    const [selectedFromDropdownLocalValue, setSelectedFromDropdownLocalValue] =
        useState([]);
    const [loader, setLoader] = useState(false);

    const promiseTypeList = [
        {
            text: "Initiation",
            value: "Initiation",
            id: "Initiation",
        },
        {
            text: "Commitment",
            value: "Commitment",
            id: "Commitment",
        },
        {
            text: "Fulfillment",
            value: "Fulfillment",
            id: "Fulfillment",
        },
        {
            text: "Settlement",
            value: "Settlement",
            id: "Settlement",
        },
        {
            text: "Completion",
            value: "Completion",
            id: "Completion",
        },
    ];
    const exceptionList = [
        {
            text: "DELAYED",
            value: "DELAYED",
            id: "DELAYED",
        },
        {
            text: "SKIPPED",
            value: "SKIPPED",
            id: "SKIPPED",
        },
        {
            text: "KEY MISSING",
            value: "KEY MISSING",
            id: "KEY MISSING",
        },
        {
            text: "KEY MISMATCH",
            value: "KEY MISMATCH",
            id: "KEY MISMATCH",
        },
        {
            text: "PROACTIVE ALERT",
            value: "PROACTIVE ALERT",
            id: "PROACTIVE ALERT",
        },
        {
            text: "DATE CHANGED",
            value: "DATE CHANGED",
            id: "DATE CHANGED",
        },
        {
            text: "OTHERS",
            value: "OTHERS",
            id: "OTHERS",
        },
    ];
    const exceptionStatusList = [
        {
            text: "OPEN",
            value: "OPEN",
            id: "OPEN",
        },
        {
            text: "CLOSED",
            value: "CLOSED",
            id: "CLOSED",
        },
    ];

    useEffect(() => {
        setSelectedGenericDropdownLocalValue(selectedGenericDDValue);
        setSelectedInstanceDropdownLocalValue(selectedInstanceDDValue);
        setSelectedTimeState(selectedTimeFilter);
        setSelectedTransactionDropdownLocalValue(selectedTransactionDDValue);
        setSelectedFromDropdownLocalValue(selectedFromDDValue);
        setSelectedToDropdownLocalValue(selectedToDDValue);
        setSelectedPromiseDropdownLocalValue(selectedPromiseTypeDDValue);
        setSelectedExceptionDropdownLocalValue(selectedExceptionDDValue);
        setSelectedExceptionStatusDropdownLocalValue(
            selectedExceptionStatusDDValue
        );
        setChipsArray(searchText);
        if (selectedGenericDDValue) {
            setIsDataExist(true);
        }
    }, []);

    useEffect(() => {
        if (isDataExist) {
            handleSubmit();
            setIsDataExist(false);
        }
    }, [isDataExist]);

    useEffect(() => {
        if (filterRawDataRedux) {
            setLoader(false);
            createGenericDropdownList();
        } else setLoader(true);
    }, [filterRawDataRedux]);

    useEffect(() => {
        if (selectedGenericDropdownLocalValue !== null) {
            let genericIndex = getSelectedGenericIndex();
            createInstanceDropdownList(genericIndex);
            createTransactionDropdownList(genericIndex);
            createFromDropdownList(genericIndex);
            createToDropdownList(genericIndex);
            setClearTimeDrp(genericIndex);
        }
    }, [selectedGenericDropdownLocalValue]);

    useEffect(() => {
        if (alertData1 && alertData1.length) {
            getFilterCompare(alertData1);
        } else {
            getFilterCompare(alertData2);
        }
    }, [alertData1, alertData2]);

    useEffect(() => {
        let alertsExportData = [];
        alertsExportData[0] = [
            "Generic Name",
            "Instance Name",
            "Step Name",
            "Alert Type",
            "Alert Text",
        ];

        alertData &&
            alertData.length &&
            alertData.forEach((alertItem, index) => {
                alertsExportData[index + 1] = [];

                filterRawDataRedux.forEach((filterItem) => {
                    if (
                        filterItem.genericRegistry.genericId ===
                        alertItem.genericId
                    ) {
                        alertsExportData[index + 1].push(
                            filterItem.genericRegistry.genericDisplayName
                        );
                        alertsExportData[index + 1].push(
                            filterItem.instances.filter(
                                (instance) =>
                                    instance.instanceId === alertItem.instanceId
                            )[0]?.instanceName
                        );
                    }
                });
                let stepName =
                    alertItem && alertItem.instanceStepId > 0
                        ? getStepName(alertItem.instanceStepId)
                        : "NA";
                alertsExportData[index + 1].push(stepName);
                alertsExportData[index + 1].push(alertItem.exceptionType);
                let exceptionText = alertItem.exceptionText.replace(
                    /(<([^>]+)>)/gi,
                    ""
                );
                alertsExportData[index + 1].push(exceptionText);
            });
        setExportData(alertsExportData);
    }, [alertData]);

    function getStepName(stepId) {
        return exceptionSteps[stepId];
    }
    const getFilterCompare = (alertFilter) => {
        let alertsExportData = [];
        alertsExportData[0] = [
            "Generic Name",
            "Instance Name",
            "Step Name",
            "Alert Type",
            "Alert Text",
        ];

        alertFilter &&
            alertFilter.length &&
            alertFilter.forEach((alertItem, index) => {
                alertsExportData[index + 1] = [];

                filterRawDataRedux.forEach((filterItem) => {
                    // Ensure filterItem.genericRegistry and filterItem.instances exist
                    if (
                        filterItem.genericRegistry &&
                        filterItem.genericRegistry.genericId ===
                            alertItem.genericId
                    ) {
                        alertsExportData[index + 1].push(
                            filterItem.genericRegistry.genericDisplayName
                        );

                        // Ensure filterItem.instances is an array and perform the filtering
                        if (Array.isArray(filterItem.instances)) {
                            const matchedInstance = filterItem.instances.find(
                                (instance) =>
                                    instance.instanceId === alertItem.instanceId
                            );

                            // Push instanceName only if matchedInstance exists
                            alertsExportData[index + 1].push(
                                matchedInstance
                                    ? matchedInstance.instanceName
                                    : "Unknown Instance"
                            );
                        } else {
                            // Handle case where instances array is missing
                            alertsExportData[index + 1].push(
                                "Unknown Instance"
                            );
                        }
                    }
                });

                let stepName =
                    alertItem && alertItem.instanceStepId > 0
                        ? getStepName(alertItem.instanceStepId)
                        : "NA";

                alertsExportData[index + 1].push(stepName);
                alertsExportData[index + 1].push(alertItem.exceptionType);

                let exceptionText = alertItem.exceptionText.replace(
                    /(<([^>]+)>)/gi,
                    ""
                );
                alertsExportData[index + 1].push(exceptionText);
            });

        setExportData(alertsExportData);
    };

    const createGenericDropdownList = () => {
        let itemList = [];
        filterRawDataRedux.forEach((filterItem) => {
            itemList.push({
                text: filterItem.genericRegistry.genericDisplayName,
                value: filterItem.genericRegistry.genericDisplayName,
                id: filterItem.genericRegistry.genericId,
            });
        });
        itemList.sort((a, b) => a.text.localeCompare(b.text));

        setGenericList(itemList);
    };

    const createInstanceDropdownList = (genericIndex) => {
        let instances = filterRawDataRedux[genericIndex].instances;
        let instanceList = [];
        instances.forEach((instance) => {
            instanceList.push({
                text: instance.instanceName,
                value: instance.instanceName,
                id: instance.instanceId,
            });
        });
        setInstanceList(instanceList);
    };

    const createTransactionDropdownList = (genericIndex) => {
        let itemList = [];
        filterRawDataRedux[genericIndex].stepNames.forEach((item) => {
            itemList.push({
                text: item,
                value: item,
                id: item,
            });
        });
        setTransactionList(itemList);
    };

    const createFromDropdownList = (genericIndex) => {
        const itemList = [];
        filterRawDataRedux[genericIndex].fromContacts.forEach((item) => {
            itemList.push({
                text: item,
                value: item,
                id: item,
            });
        });
        setFromValuesList(itemList);
    };

    const createToDropdownList = (genericIndex) => {
        const itemList = [];
        filterRawDataRedux[genericIndex].toContacts.forEach((item) => {
            itemList.push({
                text: item,
                value: item,
                id: item,
            });
        });
        setToValuesList(itemList);
    };

    function getSelectedGenericIndex() {
        let genericIndex;
        filterRawDataRedux.forEach((generic, index) => {
            if (
                generic.genericRegistry.genericId ===
                selectedGenericDropdownLocalValue.id
            ) {
                genericIndex = index;
            }
        });
        return genericIndex;
    }
    const handleGenericSelection = (index, selectedGeneric) => {
        setSelectedGenericDropdownLocalValue(selectedGeneric);
        setSelectedInstanceDropdownLocalValue([]);
        setSelectedTransactionDropdownLocalValue([]);
        setSelectedFromDropdownLocalValue([]);
        setSelectedToDropdownLocalValue([]);
        setSelectedPromiseDropdownLocalValue([]);
        setSelectedExceptionDropdownLocalValue([]);
        setSelectedExceptionStatusDropdownLocalValue([]);
    };

    const handleInstancesSelection = (index, selectedInstances) => {
        setSelectedInstanceDropdownLocalValue(selectedInstances);
    };

    const handleSelectedTimeFilterData = (data) => {
        setSelectedTimeState(data);
    };

    const handleTransactionSelection = (index, selectedTransactions) => {
        setSelectedTransactionDropdownLocalValue(selectedTransactions);
    };
    const handleReset = () => {
        if (isCompareView) setFilterErr("");
        if (filterNo === "one") {
            dispatch(setDashboardSearchTextFilterOne([]));
            dispatch(setSelectedGenericDropdownValueFilterOne(null));
            dispatch(setSelectedInstanceDropdownValueFilterOne([]));
            dispatch(setSelectedTimeDropdownValueFilterOne({}));
            dispatch(setSelectedTransactionDropdownValueFilterOne([]));
            dispatch(setSelectedFromDropdownValueFilterOne([]));
            dispatch(setSelectedToDropdownValueFilterOne([]));
            dispatch(setSelectedPromiseDropdownValueFilterOne([]));
            dispatch(setSelectedExceptionDropdownValueFilterOne([]));
            dispatch(setSelectedExceptionStatusDropdownValueFilterOne([]));
        } else if (filterNo === "two") {
            dispatch(setDashboardSearchTextFilterTwo([]));
            dispatch(setSelectedGenericDropdownValueFilterTwo(null));
            dispatch(setSelectedInstanceDropdownValueFilterTwo([]));
            dispatch(setSelectedTimeDropdownValueFilterTwo({}));
            dispatch(setSelectedTransactionDropdownValueFilterTwo([]));
            dispatch(setSelectedFromDropdownValueFilterTwo([]));
            dispatch(setSelectedToDropdownValueFilterTwo([]));
            dispatch(setSelectedPromiseDropdownValueFilterTwo([]));
            dispatch(setSelectedExceptionDropdownValueFilterTwo([]));
            dispatch(setSelectedExceptionStatusDropdownValueFilterTwo([]));
        }
        handleCloseModal();
        dispatch(setDashboardSearchText([]));
        dispatch(setSelectedGenericDropdownValue(null));
        dispatch(setSelectedInstanceDropdownValue([]));
        dispatch(setSelectedTimeDropdownValue({}));
        dispatch(setSelectedTransactionDropdownValue([]));
        dispatch(setSelectedFromDropdownValue([]));
        dispatch(setSelectedToDropdownValue([]));
        dispatch(setSelectedPromiseDropdownValue([]));
        dispatch(setSelectedExceptionDropdownValue([]));
        dispatch(setSelectedExceptionStatusDropdownValue([]));
        setSelectedGenericDropdownLocalValue(null);
        setSelectedInstanceDropdownLocalValue([]);
        setSelectedTimeState({});
        setSelectedTransactionDropdownLocalValue([]);
        setSelectedFromDropdownLocalValue([]);
        setSelectedToDropdownLocalValue([]);
        setSelectedPromiseDropdownLocalValue([]);
        setSelectedExceptionDropdownLocalValue([]);
        setSelectedExceptionStatusDropdownLocalValue([]);
        clearDashboardData();
        if (chipsArray.length) {
            updateChipsArrayList([]);
        }
    };
    const handleFromSelection = (index, selectedFromValues) => {
        setSelectedFromDropdownLocalValue(selectedFromValues);
    };

    const handleToSelection = (index, selectedToValues) => {
        setSelectedToDropdownLocalValue(selectedToValues);
    };

    const handlePromiseTypeSelection = (index, selectedPromiseTypeValues) => {
        setSelectedPromiseDropdownLocalValue(selectedPromiseTypeValues);
    };

    const handleExceptionSelection = (index, selectedExceptionsValues) => {
        setSelectedExceptionDropdownLocalValue(selectedExceptionsValues);
    };

    const handleExceptionStatusSelection = (
        index,
        selectedExceptionStatusValues
    ) => {
        setSelectedExceptionStatusDropdownLocalValue(
            selectedExceptionStatusValues
        );
    };

    const handleClear = () => {
        if (isCompareView) setFilterName("");
        setSelectedGenericDropdownLocalValue(null);
        setSelectedInstanceDropdownLocalValue([]);
        setSelectedTimeState({});
        setSelectedTransactionDropdownLocalValue([]);
        setSelectedFromDropdownLocalValue([]);
        setSelectedToDropdownLocalValue([]);
        setSelectedPromiseDropdownLocalValue([]);
        setSelectedExceptionDropdownLocalValue([]);
        setSelectedExceptionStatusDropdownLocalValue([]);
        setChipsArray([]);

        setInstanceList([]);
        setFromValuesList([]);
        setToValuesList([]);
        setTransactionList([]);
        setDateErr(true);
        if (filterNo === "one") {
            dispatch(setDashboardSearchTextFilterOne([]));
            dispatch(setSelectedGenericDropdownValueFilterOne(null));
            dispatch(setSelectedInstanceDropdownValueFilterOne([]));
            dispatch(setSelectedTimeDropdownValueFilterOne({}));
            dispatch(setSelectedTransactionDropdownValueFilterOne([]));
            dispatch(setSelectedFromDropdownValueFilterOne([]));
            dispatch(setSelectedToDropdownValueFilterOne([]));
            dispatch(setSelectedPromiseDropdownValueFilterOne([]));
            dispatch(setSelectedExceptionDropdownValueFilterOne([]));
            dispatch(setSelectedExceptionStatusDropdownValueFilterOne([]));
        } else if (filterNo === "two") {
            dispatch(setDashboardSearchTextFilterTwo([]));
            dispatch(setSelectedGenericDropdownValueFilterTwo(null));
            dispatch(setSelectedInstanceDropdownValueFilterTwo([]));
            dispatch(setSelectedTimeDropdownValueFilterTwo({}));
            dispatch(setSelectedTransactionDropdownValueFilterTwo([]));
            dispatch(setSelectedFromDropdownValueFilterTwo([]));
            dispatch(setSelectedToDropdownValueFilterTwo([]));
            dispatch(setSelectedPromiseDropdownValueFilterTwo([]));
            dispatch(setSelectedExceptionDropdownValueFilterTwo([]));
            dispatch(setSelectedExceptionStatusDropdownValueFilterTwo([]));
        } else {
            dispatch(setDashboardSearchText([]));
            dispatch(setSelectedGenericDropdownValue(null));
            dispatch(setSelectedInstanceDropdownValue([]));
            dispatch(setSelectedTimeDropdownValue({}));
            dispatch(setSelectedTransactionDropdownValue([]));
            dispatch(setSelectedFromDropdownValue([]));
            dispatch(setSelectedToDropdownValue([]));
            dispatch(setSelectedPromiseDropdownValue([]));
            dispatch(setSelectedExceptionDropdownValue([]));
            dispatch(setSelectedExceptionStatusDropdownValue([]));
        }
        clearDashboardData();
    };

    const onSerchTextBlur = () => {
        if (searchTextState !== "") {
            createChip(searchTextState);
        }
    };

    const updateChipsArrayList = (list) => {
        setChipsArray(list);
    };

    const handleDeleteChipItem = (chipToDelete) => {
        let items = [...chipsArray];
        let indexToRemove = items.indexOf(chipToDelete);
        items.splice(indexToRemove, 1);
        updateChipsArrayList(items);
    };

    const putTextInChips = (text) => {
        let chipsArrayItems = [...chipsArray];
        if (chipsArrayItems.length === null || chipsArrayItems.length === 0) {
            chipsArrayItems.push({
                key: 0,
                label: text.trim(),
            });
        } else {
            let index = 0;
            index = chipsArrayItems.length;
            chipsArrayItems.push({
                key: index,
                label: text.trim(),
            });
        }

        updateChipsArrayList(chipsArrayItems);
    };

    const createChip = (value) => {
        if (value.trim() !== "") {
            let flag = false;
            chipsArray.forEach((item) => {
                if (item.label === value) {
                    flag = true;
                }
            });
            if (flag === false) {
                putTextInChips(value);
                setSearchTextState("");
            }
            let filterId;
            if (filterNo === "one") {
                filterId = "filter1";
            } else if (filterNo === "two") filterId = "filter2";
            else filterId = "DCSearchTextBox";
            document.getElementById(filterId).value = "";
        }
    };

    const getChipsText = () => {
        let str = "";
        chipsArray.forEach((element, index) => {
            if (index < chipsArray.length - 1) {
                str = str + element.label + ",";
            } else {
                str = str + element.label;
            }
        });
        return str;
    };

    const setFromText = (value, id, event) => {
        //takes input values from textbox component
        switch (id) {
            case "DCSearchTextBox":
                {
                    setSearchTextState(value);
                }
                break;
            case "filter1": {
                setSearchTextState(value);
            }
            case "filter2":
                {
                    setSearchTextState(value);
                }
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (validateTimeRange() && validateGenericId()) {
            handleCloseModal();
            const selectedGenericId = selectedGenericDropdownLocalValue.id;
            const selectedInstanceIds = selectedInstanceDropdownLocalValue.map(
                (selectedInstance) => selectedInstance.id
            );
            const selectedTransactionTypes =
                selectedTransactionDropdownLocalValue.map(
                    (selectedTransactionType) => selectedTransactionType.value
                );
            const selectedFromContacts = selectedFromDropdownLocalValue.map(
                (selectedFromContact) => selectedFromContact.value
            );
            const selectedToContacts = selectedToDropdownLocalValue.map(
                (selectedToContact) => selectedToContact.value
            );
            const selectedPromiseTypes = selectedPromiseDropdownLocalValue.map(
                (selectedPromiseType) => selectedPromiseType.value
            );
            const selectedExceptions = selectedExceptionDropdownLocalValue.map(
                (selectedException) => selectedException.value
            );
            const selectedExceptionStatuses =
                selectedExceptionStatusDropdownLocalValue.map(
                    (selectedExceptionStatus) => selectedExceptionStatus.value
                );

            let selectedStartDate = "";
            let selectedLastDate = "";
            if (selectedTimeState.selectedTimeType === "All") {
                selectedStartDate = "";
                selectedLastDate = "";
            } else if (selectedTimeState.selectedTimeType === "Last 90 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 7776000000
                ).toDateString();
                selectedStartDate = formatDate(startDate) + " 00:00:00.000";
                selectedLastDate = formatDate(endDate) + " 23:59:59.999";
            } else if (selectedTimeState.selectedTimeType === "Last Year") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 31556952000
                ).toDateString();
                selectedStartDate = formatDate(startDate) + " 00:00:00.000";
                selectedLastDate = formatDate(endDate) + " 23:59:59.999";
            } else if (selectedTimeState.selectedTimeType === "Last 30 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 2592000000
                ).toDateString();
                selectedStartDate = formatDate(startDate) + " 00:00:00.000";
                selectedLastDate = formatDate(endDate) + " 23:59:59.999";
            } else if (
                selectedTimeState.selectedTimeType === "Previous month"
            ) {
                selectedStartDate =
                    getDateByRange("PrevMonthStartDate") + " 00:00:00.000";
                selectedLastDate =
                    getDateByRange("PrevMonthLastDate") + " 23:59:59.999";
            } else if (selectedTimeState.selectedTimeType === "Last 14 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 1209600000
                ).toDateString();
                selectedStartDate = formatDate(startDate) + " 00:00:00.000";
                selectedLastDate = formatDate(endDate) + " 23:59:59.999";
            } else if (selectedTimeState.selectedTimeType === "Last 7 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 604800000
                ).toDateString();
                selectedStartDate = formatDate(startDate) + " 00:00:00.000";
                selectedLastDate = formatDate(endDate) + " 23:59:59.999";
            } else {
                selectedStartDate = selectedTimeState.startDate
                    ? selectedTimeState.startDate + " 00:00:00.000"
                    : "";

                selectedLastDate = selectedTimeState.endDate
                    ? selectedTimeState.endDate + " 23:59:59.999"
                    : "";
            }
            let keyArray = getChipsText().split(",");
            let keyString = keyArray.join("&");
            setAlertDisableDate({
                startDate: selectedStartDate,
                endDate: selectedLastDate,
            });
            const dataObj = {
                genericId: selectedGenericId,
                instanceIds: selectedInstanceIds,
                startDate: selectedStartDate,
                endDate: selectedLastDate,
                searchText: keyString,
                stepNames: selectedTransactionTypes,
                fromContacts: selectedFromContacts,
                toContacts: selectedToContacts,
                promiseTypes: selectedPromiseTypes,
                exceptions: selectedExceptions,
                exceptionStatuses: selectedExceptionStatuses,
            };
            if (filterNo === "one") {
                dispatch(setDashboardSearchTextFilterOne(chipsArray));
                dispatch(
                    setSelectedGenericDropdownValueFilterOne(
                        selectedGenericDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedInstanceDropdownValueFilterOne(
                        selectedInstanceDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedTimeDropdownValueFilterOne(selectedTimeState)
                );
                dispatch(
                    setSelectedTransactionDropdownValueFilterOne(
                        selectedTransactionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedFromDropdownValueFilterOne(
                        selectedFromDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedToDropdownValueFilterOne(
                        selectedToDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedPromiseDropdownValueFilterOne(
                        selectedPromiseDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionDropdownValueFilterOne(
                        selectedExceptionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionStatusDropdownValueFilterOne(
                        selectedExceptionStatusDropdownLocalValue
                    )
                );
            } else if (filterNo === "two") {
                dispatch(setDashboardSearchTextFilterTwo(chipsArray));
                dispatch(
                    setSelectedGenericDropdownValueFilterTwo(
                        selectedGenericDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedInstanceDropdownValueFilterTwo(
                        selectedInstanceDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedTimeDropdownValueFilterTwo(selectedTimeState)
                );
                dispatch(
                    setSelectedTransactionDropdownValueFilterTwo(
                        selectedTransactionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedFromDropdownValueFilterTwo(
                        selectedFromDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedToDropdownValueFilterTwo(
                        selectedToDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedPromiseDropdownValueFilterTwo(
                        selectedPromiseDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionDropdownValueFilterTwo(
                        selectedExceptionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionStatusDropdownValueFilterTwo(
                        selectedExceptionStatusDropdownLocalValue
                    )
                );
            } else {
                dispatch(setDashboardSearchText(chipsArray));
                dispatch(
                    setSelectedGenericDropdownValue(
                        selectedGenericDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedInstanceDropdownValue(
                        selectedInstanceDropdownLocalValue
                    )
                );
                dispatch(setSelectedTimeDropdownValue(selectedTimeState));
                dispatch(
                    setSelectedTransactionDropdownValue(
                        selectedTransactionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedFromDropdownValue(selectedFromDropdownLocalValue)
                );
                dispatch(
                    setSelectedToDropdownValue(selectedToDropdownLocalValue)
                );
                dispatch(
                    setSelectedPromiseDropdownValue(
                        selectedPromiseDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionDropdownValue(
                        selectedExceptionDropdownLocalValue
                    )
                );
                dispatch(
                    setSelectedExceptionStatusDropdownValue(
                        selectedExceptionStatusDropdownLocalValue
                    )
                );
            }
            handleSubmitFilter(dataObj);
            handleClose();
        }
    };

    const validateTimeRange = () => {
        if (
            selectedTimeState.selectedTimeType === "" &&
            selectedTimeState.startDate === "" &&
            selectedTimeState.endDate === ""
        ) {
            return true;
        } else if (
            selectedTimeState.selectedTimeType === "" &&
            (selectedTimeState.startDate === "" ||
                selectedTimeState.endDate === "")
        ) {
            showAlertView("", "Please select both dates in Time range");
            return false;
        } else if (
            selectedTimeState.selectedTimeType === "" &&
            (selectedTimeState.startDate !== "" ||
                selectedTimeState.endDate !== "")
        ) {
            let startDate = new Date(selectedTimeState.startDate).getTime();
            let endDate = new Date(selectedTimeState.endDate).getTime();
            if (startDate > endDate) {
                showAlertView("", "End date should greater than start date");
                return false;
            }
        }
        return true;
    };

    const validateGenericId = () => {
        if (
            selectedGenericDropdownLocalValue !== null &&
            selectedGenericDropdownLocalValue !== {}
        ) {
            return true;
        } else {
            handleCloseModal();
            showAlertView("", "Please Select Generic Model");
            return false;
        }
    };

    const showAlertView = (title, body) => {
        setErrorText(body);
        setShowAlertModal(true);
    };

    const hideAlertView = () => {
        setShowAlertModal(false);
        setErrorText("");
    };

    return (
        <React.Fragment>
            {isCompareView ? (
                <>
                    <input
                        type="text"
                        className="filterscomp textEllipsis"
                        style={{
                            height: "40px",
                            width: "30%",

                            textTransform: "none",
                        }}
                        title={filterName}
                        value={filterName}
                        onChange={(e) => {
                            if (e.target.value.length > 100) {
                                setFilterErr("Maximum 100 characters allowed");
                            } else {
                                setFilterName(e.target.value);
                                setFilterErr("");
                            }
                        }}
                    ></input>
                    <div
                        style={{
                            color: "red",
                            margin: "-10px 0px 0px 13px",
                            fontFamily: "Montserrat",
                        }}
                    >
                        {filterErr}
                    </div>
                </>
            ) : null}

            {!isCompareView ? (
                <>
                    <div
                        id="dropdownContainerRow"
                        className="m-0 w-100 mb-3"
                        style={{
                            justifyContent: "space-start",
                            display: "flex",
                            flexWrap: isCompareView ? "wrap" : "nowrap",
                            alignItems: "center",
                            paddingLeft: "0.8%",
                        }}
                    >
                        <CustomSearchDropdown
                            items={genericList}
                            label={"Flow"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={false}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleGenericSelection}
                            selection={selectedGenericDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>

                        {/* <SelectTimeRangeDropdown
                id={"btn_select_time"}
                resetData={clearTimeDrp}
                setSelectedTimeFilter={handleSelectedTimeFilterData}
            ></SelectTimeRangeDropdown> */}
                        <CustomDateDropdown
                            setDateErr={setDateErr}
                            dateErr={dateErr}
                            setSelectedTimeFilter={handleSelectedTimeFilterData}
                            selection={selectedTimeState}
                            // customClassName={classes.dateRoot}
                            customWidth={142}
                        ></CustomDateDropdown>
                        <div id="main_box">
                            <SearchBarView
                                setFromText={setFromText}
                                createChip={createChip}
                                handleDeleteChipItem={handleDeleteChipItem}
                                chipsArrayData={chipsArray}
                                updateChipsArrayList={updateChipsArrayList}
                                onSearchTextBlur={onSerchTextBlur}
                            ></SearchBarView>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "1%",
                            }}
                        >
                            <button
                                onClick={handleOpen}
                                id="header_btn_more"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "1%",
                                }}
                            >
                                <img src="./images/more1.svg" alt="" />

                                <em
                                    style={{
                                        marginRight: "9px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                    }}
                                >
                                    More
                                </em>
                            </button>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: "-12px",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        <div style={modalTitleStyle}>
                                            More Filters
                                        </div>
                                        <div
                                            style={closeButtonStyle}
                                            onClick={handleClose}
                                        >
                                            &times;
                                        </div>
                                    </div>
                                    <FormControl
                                        className="invisibleScroller"
                                        style={{ height: "390px" }}
                                    >
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={instanceList}
                                                label={"Instances"}
                                                id={"btn_select_instance"}
                                                idInfo={"instance"}
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleInstancesSelection
                                                }
                                                selection={
                                                    selectedInstanceDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                customWidth={124}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={transactionList}
                                                label={"Step Name"}
                                                id={"btn_select_transaction"}
                                                idInfo={"transaction"}
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleTransactionSelection
                                                }
                                                selection={
                                                    selectedTransactionDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                customWidth={127}
                                            ></CustomSearchDropdown>
                                        </div>

                                        <div
                                            style={{
                                                // paddingBottom: "5px",
                                                marginBottom: "10px",
                                                // marginLeft: "-4px",
                                            }}
                                        >
                                            <CustomSearchDropdown
                                                items={fromValuesList}
                                                label="From"
                                                id="btn_select_from"
                                                idInfo="from"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleFromSelection
                                                }
                                                selection={
                                                    selectedFromDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={toValuesList}
                                                label="To"
                                                id="btn_select_to"
                                                idInfo="to"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleToSelection
                                                }
                                                selection={
                                                    selectedToDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={promiseTypeList}
                                                label="Promise Type"
                                                id="btn_select_promise_type"
                                                idInfo="promise_type"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handlePromiseTypeSelection
                                                }
                                                selection={
                                                    selectedPromiseDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={exceptionList}
                                                label="Exceptions"
                                                id="btn_select_exception"
                                                idInfo="exception"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleExceptionSelection
                                                }
                                                selection={
                                                    selectedExceptionDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <CustomSearchDropdown
                                                items={exceptionStatusList}
                                                label="Exception Status"
                                                id="btn_select_status"
                                                idInfo="exception_status"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleExceptionStatusSelection
                                                }
                                                selection={
                                                    selectedExceptionStatusDropdownLocalValue
                                                }
                                                customClassName={
                                                    classes.moreBtn
                                                }
                                                customHeight="105px"
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                    </FormControl>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0px 4px",
                                        }}
                                    >
                                        <button
                                            onClick={handleReset}
                                            id="btn_reset"
                                            title="Reset All Filters"
                                            style={resetButtonStyle}
                                        >
                                            <em>Reset All Filters</em>
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            id="btn_apply"
                                            title="Apply"
                                            style={applyButtonStyle}
                                        >
                                            <em>Apply</em>
                                        </button>
                                    </div>
                                </Box>
                            </Modal>
                        </div>

                        <CSVLink
                            type="button"
                            id={"btn_export"}
                            title="Export"
                            value={"Export"}
                            filename="Dashboard_Alerts.csv"
                            data={exportData}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                cursor: "pointer",
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border:
                                    alertData && alertData.length
                                        ? "2px solid #000000"
                                        : "2px solid grey",
                                color:
                                    alertData && alertData.length
                                        ? "black"
                                        : "grey",
                                marginLeft: "1%",
                                pointerEvents:
                                    alertData && alertData.length
                                        ? "auto"
                                        : "none",
                            }}
                        >
                            <img
                                src="./images/share-btn.svg"
                                alt=""
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    pointerEvents: "auto", // Ensures the image is not affected by the link's pointerEvents style
                                }}
                            />
                            Export
                        </CSVLink>

                        <button
                            className={"btn share modalButton"}
                            type="button"
                            id={"btn_view_result"}
                            title="View Results"
                            value={"View Results"}
                            style={{ marginRight: "1.3%" }}
                            onClick={handleSubmit || null}
                        >
                            View Results
                        </button>
                        <AlertDialog
                            open={showAlertModal}
                            setOpen={hideAlertView}
                            title={""}
                            message={errorText}
                        ></AlertDialog>
                    </div>
                    {chipsArray.length ? (
                        <div id="chipContainer" style={{ marginLeft: "20px" }}>
                            <ChipsView
                                items={chipsArray}
                                ondelete={handleDeleteChipItem}
                            ></ChipsView>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "6px",
                        }}
                    >
                        <CustomSearchDropdown
                            items={genericList}
                            label={"Flow"}
                            id={"btn_select_generic"}
                            idInfo={"generic"}
                            multiSelect={false}
                            isSearchable={true}
                            isChipView={false}
                            handleSelection={handleGenericSelection}
                            selection={selectedGenericDropdownLocalValue}
                            customClassName={classes.root}
                            customWidth={92}
                        ></CustomSearchDropdown>
                        <CustomDateDropdown
                            setDateErr={setDateErr}
                            dateErr={dateErr}
                            setSelectedTimeFilter={handleSelectedTimeFilterData}
                            selection={selectedTimeState}
                            // customClassName={classes.compareDate}
                            customWidth={142}
                        ></CustomDateDropdown>
                        <div id="main_box">
                            <SearchBarView
                                setFromText={setFromText}
                                createChip={createChip}
                                handleDeleteChipItem={handleDeleteChipItem}
                                chipsArrayData={chipsArray}
                                updateChipsArrayList={updateChipsArrayList}
                                isCompareView={isCompareView}
                                onSearchTextBlur={onSerchTextBlur}
                                id={filterNo === "one" ? "filter1" : "filter2"}
                            ></SearchBarView>
                        </div>

                        {/* <SelectTimeRangeDropdown
                id={"btn_select_time"}
                resetData={clearTimeDrp}
                setSelectedTimeFilter={handleSelectedTimeFilterData}
            ></SelectTimeRangeDropdown> */}

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "1%",
                            }}
                        >
                            <button
                                onClick={handleOpen}
                                id="btn_more"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "1%",
                                }}
                            >
                                <img
                                    src="./images/more1.svg"
                                    alt=""
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "9px",
                                    }}
                                />
                                <em
                                    style={{
                                        marginRight: "9px",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "0.4px",
                                        textAlign: "left",
                                    }}
                                >
                                    More
                                </em>
                            </button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        <div style={modalTitleStyle}>
                                            More Filters
                                        </div>
                                        <div
                                            style={closeButtonStyle}
                                            onClick={handleClose}
                                        >
                                            &times;
                                        </div>
                                    </div>
                                    <FormControl>
                                        <CustomSearchDropdown
                                            items={instanceList}
                                            label={"Instances"}
                                            id={"btn_select_instance"}
                                            idInfo={"instance"}
                                            multiSelect={true}
                                            isSearchable={true}
                                            isChipView={false}
                                            handleSelection={
                                                handleInstancesSelection
                                            }
                                            selection={
                                                selectedInstanceDropdownLocalValue
                                            }
                                            customClassName={classes.root}
                                            customWidth={124}
                                        ></CustomSearchDropdown>
                                        <CustomSearchDropdown
                                            items={transactionList}
                                            label={"Step Name"}
                                            id={"btn_select_transaction"}
                                            idInfo={"transaction"}
                                            multiSelect={true}
                                            isSearchable={true}
                                            isChipView={false}
                                            handleSelection={
                                                handleTransactionSelection
                                            }
                                            selection={
                                                selectedTransactionDropdownLocalValue
                                            }
                                            customClassName={classes.root}
                                            customWidth={127}
                                        ></CustomSearchDropdown>
                                        <div style={{ marginBottom: "10px" }}>
                                            {" "}
                                            <CustomSearchDropdown
                                                items={fromValuesList}
                                                label="From"
                                                id="btn_select_from"
                                                idInfo="from"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleFromSelection
                                                }
                                                selection={
                                                    selectedFromDropdownLocalValue
                                                }
                                                customClassName={classes.root}
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            {" "}
                                            <CustomSearchDropdown
                                                items={toValuesList}
                                                label="To"
                                                id="btn_select_to"
                                                idInfo="to"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleToSelection
                                                }
                                                selection={
                                                    selectedToDropdownLocalValue
                                                }
                                                customClassName={classes.root}
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            {" "}
                                            <CustomSearchDropdown
                                                items={promiseTypeList}
                                                label="Promise Type"
                                                id="btn_select_promise_type"
                                                idInfo="promise_type"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handlePromiseTypeSelection
                                                }
                                                selection={
                                                    selectedPromiseDropdownLocalValue
                                                }
                                                customClassName={classes.root}
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            {" "}
                                            <CustomSearchDropdown
                                                items={exceptionList}
                                                label="Exceptions"
                                                id="btn_select_exception"
                                                idInfo="exception"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleExceptionSelection
                                                }
                                                selection={
                                                    selectedExceptionDropdownLocalValue
                                                }
                                                customClassName={classes.root}
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            {" "}
                                            <CustomSearchDropdown
                                                items={exceptionStatusList}
                                                label="Exception Status"
                                                id="btn_select_status"
                                                idInfo="exception_status"
                                                multiSelect={true}
                                                isSearchable={true}
                                                isChipView={false}
                                                handleSelection={
                                                    handleExceptionStatusSelection
                                                }
                                                selection={
                                                    selectedExceptionStatusDropdownLocalValue
                                                }
                                                customClassName={classes.root}
                                                customHeight="105px"
                                                style={dropdownStyle}
                                            ></CustomSearchDropdown>
                                        </div>
                                    </FormControl>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "0px 4px",
                                        }}
                                    >
                                        <button
                                            onClick={handleReset}
                                            id="btn_reset"
                                            title="Reset All Filters"
                                            style={resetButtonStyle}
                                        >
                                            <em>Reset All Filters</em>
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            id="btn_apply"
                                            title="Apply"
                                            style={applyButtonStyle}
                                        >
                                            <em>Apply</em>
                                        </button>
                                    </div>
                                </Box>
                            </Modal>
                        </div>

                        <CSVLink
                            type="button"
                            id={"btn_export"}
                            title="Export"
                            value={"Export"}
                            filename="Dashboard_Alerts.csv"
                            data={exportData}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                cursor: "pointer",
                                lineHeight: "24px",
                                letterSpacing: "0.4px",
                                textAlign: "left",
                                border: "2px solid #000000",
                                color: "black",
                                marginLeft: "1%",
                                pointerEvents:
                                    (alertData1 && alertData1.length) ||
                                    (alertData2 && alertData2.length)
                                        ? "auto"
                                        : "none",
                            }}
                        >
                            <img
                                src="./images/share-btn.svg"
                                alt=""
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    pointerEvents: "auto", // Ensures the image is not affected by the link's pointerEvents style
                                }}
                            />
                            Export
                        </CSVLink>

                        <div style={{ width: "100%", margin: "8px" }}>
                            <button
                                className={"btn share modalButton"}
                                type="button"
                                id={"btn_view_result"}
                                title="View Results"
                                value={"Submit"}
                                onClick={handleSubmit || null}
                            >
                                View Results
                            </button>
                        </div>
                    </div>
                    {chipsArray.length ? (
                        <div id="chipContainer" style={{ marginLeft: "20px" }}>
                            <ChipsView
                                items={chipsArray}
                                ondelete={handleDeleteChipItem}
                            ></ChipsView>
                        </div>
                    ) : null}
                </>
            )}
            <AlertDialog
                open={showAlertModal}
                setOpen={hideAlertView}
                title={""}
                message={errorText}
            ></AlertDialog>

            <Loader show={loader} />
        </React.Fragment>
    );
}
