import React, { Component, useEffect, useState } from "react";
import http from "../../../../../bae-client/HTTP";
import Loader from "../../../../stylized/Loader";
import TopPanel from "../Integration/TopPanel/TopPanel";
import "./integration.css";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import FilterListView from "./TopPanel/Filter/FilterListView";
import { setFilterListMap } from "../../../../../actions/FilterListAction";
import {
    reloadIntegrations,
    setIntegrations,
} from "../../../../../actions/Integrations";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationBoxView from "../../../../stylized/ConfirmationBoxView";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import AlertDialog from "../../../../stylized/AlertDialog";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";
function IntegrationContainer() {
    const topPanelData = [];
    const rightPanelData = [];
    //     {
    //         title: "Radius",
    //         bodyImgSrc: "./images/Radius 3.png",
    //     },
    // ];
    const [integrationList, setIntegrationList] = useState([]);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [selectedIntegration, setselectedIntegration] = useState("");
    const [filterList, setfilterList] = useState([]);
    const [showLoader, setshowLoader] = useState(false);
    const [showConfirmationModal, setshowConfirmationModal] = useState(false);
    const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [showPage, setshowPage] = useState("Integration");
    const webSocketNotification = useSelector(
        (state) => state.webSocketNotification
    );
    const integrations = useSelector(
        (state) => state.integrations.integrations
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (webSocketNotification) {
            fetchFilterList()
                .then((response) => {
                    setIntegrationList(response.integrations);
                    dispatch(setIntegrations(response));
                })
                .catch((err) => {
                    console.log(err);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    setIntegrationList([]);
                });
            dispatch(reloadIntegrations(null));
        }
    }, [webSocketNotification]);
    useEffect(() => {
        Mixpanel.callPageViewEvent("Apps");
        setIntegrationList(integrations);
        // fetchFilterList()
        //     .then((response) => {
        //         setIntegrationList(response.integrations);
        //     })
        //     .catch((err) => {
        //         setShowAlertDialog(true);
        //         setAlertDialogMessage(err.error);
        //         console.log(err);
        //         setIntegrationList([]);
        //     });
    }, [integrations]);
    const updateFilterData = () => {
        fetchFilterList()
            .then((response) => {
                setIntegrationList(response.integrations);
            })
            .catch((err) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
                console.log(err);
                setIntegrationList([]);
            });
    };
    const fetchFilterList = () => {
        return new Promise((resolve, reject) => {
            setshowLoader(true);
            const url = "integration/integrationList";
            http.sendRestRequest(url)
                .then((response) => {
                    setshowLoader(false);
                    resolve(response);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    setshowLoader(false);
                    reject(err);
                });
        });
    };
    const generateListOfFilterNames = (resp) => {
        let filterList = [];
        resp.forEach((filterItem) => {
            filterList.push(filterItem.filterName.toLowerCase().trim());
        });
        dispatch(setFilterListMap(filterList));
    };
    const handleSettings = (label) => {
        const url = `integration/${label}/filterList`;
        setshowLoader(true);
        setselectedIntegration(label);
        http.sendRestRequest(url)
            .then((resp) => {
                generateListOfFilterNames(resp);
                setfilterList(resp);
                setshowLoader(false);
                setshowPage("filter");
            })
            .catch((err) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
                console.log(err);
                setshowLoader(false);
            });
    };
    const handleDeleteFilter = (filterId, accountType) => {
        const url = `integration/${accountType}/filter?filterId=${filterId}`;
        setshowLoader(true);
        http.sendRestRequest(url, "DELETE")
            .then((resp) => {
                const url = `integration/${accountType}/filterList`;
                setshowLoader(true);
                setselectedIntegration(accountType);
                http.sendRestRequest(url)
                    .then((resp) => {
                        setfilterList(resp);
                        setshowLoader(false);
                        setshowPage("filter");
                        showAlertView(
                            "",
                            "Filter deleted successfully",
                            "delete_filter_alert_msg",
                            "delete_filter_alert_btn"
                        );
                    })
                    .catch((err) => {
                        setShowAlertDialog(true);
                        setAlertDialogMessage(err.error);
                        setshowLoader(false);
                    });
            })
            .catch((err) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
                setshowLoader(false);
            });
    };
    const handleBackBtn = () => {
        setshowPage("integration");
    };
    const openConfirmationBox = (id, type) => {
        setSelectedItemForDelete({ id, type });
        setshowConfirmationModal(true);
    };
    const hideConfirmationBox = () => {
        setshowConfirmationModal(false);
    };
    const handleDeleteOkClick = () => {
        hideConfirmationBox();
        handleDeleteFilter(
            selectedItemForDelete.id,
            selectedItemForDelete.type
        );
    };
    const renderConfirmationBox = () => {
        return (
            <ConfirmationBoxView
                open={showConfirmationModal}
                title={"Delete Filter"}
                body={"Are you sure you want to delete the this filter?"}
                handleOk={handleDeleteOkClick}
                handleCancle={hideConfirmationBox}
            ></ConfirmationBoxView>
        );
    };
    const showAlertView = (title, body, messageId, buttonId) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        setShowSnackbar(true);
    };
    const renderSnackBar = () => {
        return (
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={alertDialogContent.message}
            ></CustomSnackbar>
        );
    };
    if (showPage === "filter") {
        return (
            <>
                <FilterListView
                    filterListObj={filterList}
                    handleBackBtn={handleBackBtn}
                    selectedIntegration={selectedIntegration}
                    updateFilter={handleSettings}
                    handleDeleteFilter={openConfirmationBox}
                ></FilterListView>
                {renderConfirmationBox()}
                {renderSnackBar()}
            </>
        );
    } else {
        return (
            <div className="integrationContainer invisibleScroller">
                <div className="row w-100 integrationTopContainer m-0 mt-3">
                    <TopPanel
                        integrationList={integrationList}
                        handleSettings={handleSettings}
                    ></TopPanel>
                </div>
                <div className="row w-100 integrationBottomContainer">
                    <div className="col-6 p-0">
                        <LeftPanel></LeftPanel>
                    </div>
                    <div className="col-6 p-0">
                        <RightPanel
                            rightPanelData={rightPanelData}
                        ></RightPanel>
                    </div>
                </div>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
                <Loader show={showLoader}></Loader>
            </div>
        );
    }
}

export default IntegrationContainer;
