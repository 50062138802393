import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Instance from "./Instance";
import ControlTowerClass from "../../../../bae-client/ControlTower";
import ControlTowerContainer from "./ControlTowerContainer";
import TitleBar from "../TiltleBar";

import AlertDialog from "../../../stylized/AlertDialog";
import Dashboard from "../ContentPane/document-chain/dashboard/dashboard";
import * as Mixpanel from "../../../../mix-panel/Mixpanel";

const controlTower = new ControlTowerClass();

function ControlTower() {
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [genericData, setGenericData] = useState(null);
    useEffect(() => {
        Mixpanel.callPageViewEvent("Control Tower");

        controlTower
            .retrieveGenericBlockchainList()
            .then((response) => {
                setGenericData(response);
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.error(error);
            });
    }, []);
    return genericData ? (
        <>
            <Tabs
                defaultActiveKey="controlTower"
                id="uncontrolled-tab-example"
                style={{ paddingTop: "8px" }}
            >
                {/* <Tab eventKey="dashboard" title="Dashboard">
                    <Dashboard controlTowerDashboard={true}/>
                </Tab> */}
                <Tab eventKey="controlTower" title="Control Tower">
                    <ControlTowerContainer
                        genericResponse={genericData}
                    ></ControlTowerContainer>
                </Tab>
                <Tab eventKey="controltower" title="Control Tower Settings">
                    <ControlTowerContainer
                        settings={true}
                        genericResponse={genericData}
                    ></ControlTowerContainer>
                </Tab>
                {/*<Tab eventKey="instance" title="Instance">
                    <Instance genericResponse={genericData[0]}></Instance>
                    {"Coming soon feature..!"}
                </Tab>
                <Tab eventKey="genericBlockchain" title="Generic Blockchain">
                    {"Coming soon feature..!"}
                </Tab>*/}
            </Tabs>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
        </>
    ) : null;
}

export default ControlTower;
