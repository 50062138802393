import React from "react";
import "./Archive.css";

const EmptyArchive = (props) => {
    return (
        <React.Fragment>
            <div className="emptyArchiveContainer">
                <div style={{ margin: "auto" }}>
                    <div>
                        <img
                            alt="archive"
                            className="newSpace"
                            src="./images/archiveNew.png"
                        ></img>
                    </div>
                    <div>
                        <span className="workText Archieve">
                            Your Archive is empty
                        </span>
                        <br />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmptyArchive;
