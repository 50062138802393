import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import React, { useEffect } from "react";
import "./manageSpace.css";
import user from "../../bae-client/User";
import "../functional/UnityCentral/index.css";
import { event } from "jquery";

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, headCells } = props;
    const [orderBy, setOrderBy] = React.useState("asc");

    const handleSorting = (e, name) => {
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");

        if (orderBy === "asc") {
            let strAscending = 0;
            if (e.target.innerText === "Space Name") {
                strAscending = [...props.rows].sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                );
            } else if (e.target.innerText === "Name") {
                strAscending = [...props.rows].sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                );
            } else if (e.target.innerText === "Shared") {
                strAscending = [...props.rows].sort((a, b) =>
                    a.shared.toUpperCase() > b.shared.toUpperCase() ? 1 : -1
                );
            } else if (e.target.innerText === "Owner") {
                strAscending = [...props.rows].sort((a, b) =>
                    a.owner.toUpperCase() > b.owner.toUpperCase() ? 1 : -1
                );
            } else if (e.target.innerText === "Date Last Modified") {
                strAscending = [...props.rows].sort(
                    (a, b) =>
                        new Date(a.updatedOn).getTime() -
                        new Date(b.updatedOn).getTime()
                );
            } else if (e.target.innerText === "Date Created") {
                strAscending = [...props.rows].sort(
                    (a, b) =>
                        new Date(a.createdOn).getTime() -
                        new Date(b.createdOn).getTime()
                );
            }
            props.setdata(strAscending);
        } else if (orderBy === "desc") {
            let strDescending = 0;
            if (e.target.innerText === "Space Name") {
                strDescending = [...props.rows].sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase() ? -1 : 1
                );
            } else if (e.target.innerText === "Name") {
                strDescending = [...props.rows].sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase() ? -1 : 1
                );
            } else if (e.target.innerText === "Shared") {
                strDescending = [...props.rows].sort((a, b) =>
                    a.shared.toUpperCase() > b.shared.toUpperCase() ? -1 : 1
                );
            } else if (e.target.innerText === "Owner") {
                strDescending = [...props.rows].sort((a, b) =>
                    a.owner.toUpperCase() > b.owner.toUpperCase() ? -1 : 1
                );
            } else if (e.target.innerText === "Date Last Modified") {
                strDescending = [...props.rows].sort(
                    (a, b) =>
                        new Date(b.createdOn).getTime() -
                        new Date(a.createdOn).getTime()
                );
            } else if (e.target.innerText === "Date Created") {
                strDescending = [...props.rows].sort(
                    (a, b) =>
                        new Date(b.createdOn).getTime() -
                        new Date(a.createdOn).getTime()
                );
            }
            props.setdata(strDescending);
        }
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" style={{ fontWeight: "600" }}>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        color="primary"
                    />
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? "none" : "default"}
                        width={headCell.width}
                        style={{
                            minWidth: headCell.width,
                            fontWeight: "600",
                            cursor: "pointer",
                        }}
                        className={
                            orderBy === "asc"
                                ? "tableListRow headerSortUp"
                                : "tableListRow headerSortDown"
                        }
                        onClick={
                            headCell.label === "Starred" ? null : handleSorting
                        }
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const title = props.title;
    const selectedDoc = props.selected;
    const deleteRow = (deleteRows) => {
        props.deleteRow(selectedDoc);
    };
    const addNewDocument = () => {
        props.addNewDocument();
    };
    const addNewFolder = () => {
        props.showFolderModal();
    };
    return (
        <Toolbar className={" row "}>
            <Typography
                className="col-9 p-0 doCenter"
                id="tableTitle"
                component="div"
            >
                <span
                    className="tableTitle textEllipsis"
                    style={{ maxWidth: "12rem" }}
                    title={title}
                >
                    {title}
                </span>
                <span className="tableTitle ml-1">{"Item Viewer"}</span>
            </Typography>
            <div className="col-3">
                <div className="row">
                    <Tooltip title="New document">
                        <div
                            id="addFileFolder"
                            className="col-3 p-0"
                            onClick={addNewDocument}
                        >
                            <img
                                src="./images/file-earmark-plus.svg"
                                alt="file"
                                style={{
                                    height: "20px",
                                    width: "20px",
                                    cursor: "pointer",
                                }}
                            ></img>
                        </div>
                    </Tooltip>
                    <Tooltip title="New Folder">
                        <div
                            id="addNewFolder"
                            className="col-3 p-0"
                            onClick={addNewFolder}
                        >
                            <img
                                src="./images/folder-plus.svg"
                                alt=""
                                style={{
                                    height: "20px",
                                    width: "20px",
                                    cursor: "pointer",
                                }}
                            ></img>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </Toolbar>
    );
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontFamily: "Montserrat",
        background: "white",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        width: "100%",
        background: "white",
    },
    table_h_100: {
        width: "100%",
        height: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    spaceListTable: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
}));

export default function TableView(props) {
    const classes = useStyles();
    const [isStar, setIsStar] = React.useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [data, setData] = React.useState(props.rows);
    const [selectedRowData, setSelectedRowData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    let activeFolder = props.activeFolder;

    useEffect(() => {
        return () => {
            setSelected([]);
            setSelectedRowData([]);
        };
    }, []);
    useEffect(() => {
        return () => {
            setPage(0);
        };
    }, [activeFolder, props.folderBreadCrumb]);
    useEffect(() => {
        setSelected([]);
        setSelectedRowData([]);
    }, [props.folderBreadCrumb]);
    useEffect(() => {
        setSelected([]);
        setSelectedRowData([]);
    }, [props.rows]);
    let headCells = props.columns;
    let rows = props.rows;
    // let rows = data;
    let title = props.title;
    let selectedRowIndex = null;
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const deleteRow = (selectedDoc) => {
        if (validateDocumentIds(selectedDoc)) {
            alert("please uncheck folder");
        } else {
            props.deleteRow(selectedDoc);
            setSelected([]);
        }
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);
            setSelected(newSelecteds);
            setSelectedRowData(
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            );
            if (props.handleCheckAllClick)
                props.handleCheckAllClick(true, newSelecteds);
            return;
        } else {
            if (props.handleCheckAllClick) props.handleCheckAllClick(false);
            setSelected([]);
        }
    };
    const validateDocumentIds = (documentIds) => {
        let documentTypes = new Map();
        let spaceData = rows;
        let isFolderExists = false;
        spaceData.forEach((item, index) => {
            documentTypes.set(item.id, item.type);
        });
        for (let index = 0; index < documentIds.length; index++) {
            let type = documentTypes.get(documentIds[index]);
            if (type === "folder") {
                isFolderExists = true;
                break;
            }
        }
        return isFolderExists;
    };
    const handleCheckboxClick = (event, name, id, index, rowdata) => {
        const selectedIndex = selected.indexOf(id);
        const selectedRowDataIndex = selectedRowData.indexOf(rowdata);
        selectedRowIndex = selectedIndex;
        let newSelected = [];
        let newSelectedRowData = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (selectedRowDataIndex === -1) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData,
                rowdata
            );
        } else if (selectedRowDataIndex === 0) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(1)
            );
        } else if (selectedRowDataIndex === selectedRowData.length - 1) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(0, -1)
            );
        } else if (selectedRowDataIndex > 0) {
            newSelectedRowData = newSelectedRowData.concat(
                selectedRowData.slice(0, selectedRowDataIndex),
                selectedRowData.slice(selectedRowDataIndex + 1)
            );
        }
        setSelected(newSelected);
        setSelectedRowData(newSelectedRowData);
        newSelected = newSelected.map((i) => Number(i));
        props.handleCheckboxClick(event, index, id, rowdata, newSelected);
    };
    const addStarSpace = (id, star) => {
        props.addStarFill(id, !star);
    };
    const handleChangePage = (event, newPage) => {
        //setSelected([]);
        //setSelectedRowData([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showModalView = (event, index, fileName, row) => {
        event.stopPropagation();
        props.showModal(event, index, fileName, row);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const handleNewDocumentClick = () => {
        props.addNewDocument();
    };
    const handleNewFolderClick = () => {
        props.showFolderModal();
    };
    const getImageAsType = (type, parentFolderId, currentWorkspace) => {
        let source = "";
        const fileType = type.toLowerCase().trim();
        switch (fileType) {
            case "folder":
                source = "./images/FOLDER.svg";
                break;
            case "txt":
                source = "./images/TXT.svg";
                break;
            case "pdf":
                source = "./images/PDF.svg";
                break;
            case "docx":
                source = "./images/word-icon.svg";
                break;

            case "jpg":
                source = "./images/fileTypeIcon/jpg-icon.svg";
                break;
            case "svg":
                source = "./images/fileTypeIcon/svg-icon.svg";
                break;
            case "mp3":
                source = "./images/fileTypeIcon/mp3-icon.svg";
                break;
            case "gif":
                source = "./images/fileTypeIcon/gif-icon.svg";
                break;
            case "mp4":
                source = "./images/fileTypeIcon/mp4-icon.svg";
                break;
            case "xlsx":
                source = "./images/excel-icon.svg";
                break;
            case "pptx":
                source = "./images/ppt-icon.svg";
                break;
            case "xml":
                source = "./images/fileTypeIcon/xml-file.svg";
                break;
            case "html":
                source = "./images/fileTypeIcon/htmlIcon.svg";
                break;
            default:
                source = "./images/basicfile.png";
                break;
        }
        if (
            parentFolderId == 0 &&
            currentWorkspace.workspaceName === "Emails"
        ) {
            source = "./images/email-icon 1.svg";
        }
        return source;
    };
    const singleClick = (event, name, id, index, row) => {
        handleCheckboxClick(event, name, id, index, row);
    };

    const handleDoubleClick = (event, name, id, index, row) => {
        if (row.type !== "folder" && row.type !== "space") {
            showModalView(event, index, row.name, row);
        }
        if (row.type === "folder" || row.type === "space") {
            setSelected([]);
            props.handleSpaceSelectionClick(
                event,
                row.name,
                row.id,
                index,
                row
            );
        }
    };
    let singleClickTimer = null;
    let clickCount = 0;
    const handleDoubleClicks = (event, name, id, index, row) => {
        clickCount = clickCount + 1;

        if (clickCount === 1) {
            singleClickTimer = setTimeout(() => {
                clickCount = 0;
                singleClick(event, name, id, index, row);
            }, 300);
        } else if (clickCount === 2) {
            clearTimeout(singleClickTimer);
            clickCount = 0;
            handleDoubleClick(event, name, id, index, row);
        }
    };
    const renderFileandFolderList = () => {
        let bwUserInfo = user.getBwUserInfo();
        let fullname = "";
        if (bwUserInfo) {
            fullname = bwUserInfo.firstName + " " + bwUserInfo.lastName;
        }
        return rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <TableRow
                        hover
                        id={
                            row.type !== "folder"
                                ? `tblrow_file_${row.name}`
                                : `tblrow_folder_${row.name}`
                        }
                        className={
                            isItemSelected
                                ? "tableListSelectedRow tableRowClass cursorPointer"
                                : "tableRowClass cursorPointer"
                        }
                        onClick={(event) =>
                            handleDoubleClicks(
                                event,
                                row.name,
                                row.id,
                                index,
                                row
                            )
                        }
                    >
                        <TableCell
                            padding="checkbox"
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className="tableRow"
                        >
                            <Checkbox
                                id={"chk_" + row.id}
                                checked={isItemSelected}
                                inputProps={{
                                    "aria-labelledby": labelId,
                                }}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            align="left"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="nameCell"
                            title={row.name}
                        >
                            <div
                                className="doCenter"
                                style={{ justifyContent: "flex-start" }}
                            >
                                <div
                                    className="doCenter"
                                    style={{
                                        width: "35px",
                                        marginRight: "10px",
                                    }}
                                >
                                    <img
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                        src={getImageAsType(
                                            row.type,
                                            row.parentFolderId,
                                            row.currentWorkspace
                                        )}
                                        alt="Icon"
                                    ></img>
                                </div>
                                <div
                                    className="tableListName"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    id={
                                        row.type === "folder"
                                            ? "folder_" + index
                                            : "file_" +
                                              index +
                                              "_" +
                                              `${row.name}`
                                    }
                                    onClick={
                                        row.type !== "folder" &&
                                        row.type !== "space"
                                            ? (event) =>
                                                  showModalView(
                                                      event,
                                                      index,
                                                      row.name,
                                                      row
                                                  )
                                            : (event) =>
                                                  props.handleSpaceSelectionClick(
                                                      event,
                                                      row.name,
                                                      row.id,
                                                      index,
                                                      row
                                                  )
                                    }
                                >
                                    {row.name}
                                </div>
                            </div>
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.updatedOn}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.createdOn}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.shared}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.owner === "" && row.shared === "Private"
                                ? ""
                                : row.owner === ""
                                ? fullname
                                : row.owner}
                        </TableCell>
                        {row.documentId === 0 ? (
                            <TableCell align="center" padding="none">
                                {row.isStarred ? (
                                    <img
                                        style={{ width: "10%" }}
                                        src="./images/star.png"
                                        alt=""
                                        onClick={(event) => {
                                            addStarSpace(row.id, row.isStarred);
                                            event.stopPropagation();
                                        }}
                                    />
                                ) : (
                                    <img
                                        src="./images/empstar.png"
                                        alt=""
                                        style={{ width: "10%" }}
                                        onClick={(event) => {
                                            addStarSpace(row.id, row.isStarred);
                                            event.stopPropagation();
                                        }}
                                    />
                                )}
                            </TableCell>
                        ) : (
                            <TableCell
                                align="center"
                                padding="none"
                            ></TableCell>
                        )}
                    </TableRow>
                );
            });
    };

    const renderSpaceList = () => {
        let bwUserInfo = user.getBwUserInfo();
        let fullname = "";
        if (bwUserInfo) {
            fullname = bwUserInfo.firstName + " " + bwUserInfo.lastName;
        }
        return rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <TableRow
                        hover
                        id={`tblrow_space_${row.name}`}
                        className={
                            isItemSelected
                                ? "tableListSelectedRow tableRowClass cursorPointer"
                                : "tableRowClass cursorPointer"
                        }
                        onClick={(event) =>
                            handleDoubleClicks(
                                event,
                                row.name,
                                row.id,
                                index,
                                row
                            )
                        }
                    >
                        <TableCell
                            padding="checkbox"
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                        >
                            <Checkbox
                                id={"chk_" + row.id}
                                checked={isItemSelected}
                                inputProps={{
                                    "aria-labelledby": labelId,
                                }}
                                color="primary"
                            />
                        </TableCell>
                        <TableCell
                            align="left"
                            color="blue"
                            padding="none"
                            className="nameCell"
                            title={row.name}
                        >
                            <div
                                className="doCenter"
                                style={{ justifyContent: "flex-start" }}
                            >
                                <div
                                    className="doCenter"
                                    style={{ padding: "0px 15px 0px 0px" }}
                                >
                                    <img
                                        src="./images/spaceIcon.svg"
                                        alt="Icon"
                                    ></img>
                                </div>
                                <div
                                    className="tableListName"
                                    style={{ cursor: "pointer" }}
                                    id={row.name}
                                    onClick={(event) => {
                                        props.handleSpaceSelectionClick(
                                            event,
                                            row.name,
                                            row.id,
                                            index,
                                            row
                                        );
                                        event.stopPropagation();
                                    }}
                                >
                                    {row.name}
                                </div>
                            </div>
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.updatedOn}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.createdOn}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.shared}
                        </TableCell>

                        <TableCell align="center" padding="none">
                            {row.owner === "" && row.shared === "Private"
                                ? ""
                                : row.owner === ""
                                ? fullname
                                : row.owner}
                        </TableCell>
                        <TableCell align="center" padding="none">
                            {row.isStarred === true ? (
                                <img
                                    style={{ width: "10%" }}
                                    src="./images/star.png"
                                    alt=""
                                    onClick={(event) => {
                                        addStarSpace(row.id, row.isStarred);
                                        event.stopPropagation();
                                    }}
                                />
                            ) : (
                                <img
                                    src="./images/empstar.png"
                                    alt=""
                                    style={{ width: "10%" }}
                                    onClick={(event) => {
                                        addStarSpace(row.id, row.isStarred);
                                        event.stopPropagation();
                                    }}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                );
            });
    };
    return (
        <div className={classes.root}>
            {title && title !== "" ? (
                <EnhancedTableToolbar
                    addNewDocument={handleNewDocumentClick}
                    showFolderModal={handleNewFolderClick}
                    deleteRow={(rowForDelete) => {
                        deleteRow(rowForDelete);
                    }}
                    numSelected={selected.length}
                    title={title}
                    selected={selected}
                />
            ) : null}
            {props.breadcumView}
            <div>
                <TableContainer
                    className={"invisibleScroller " + props.tableWrapperClass}
                    style={{ background: "white" }}
                >
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            setdata={props.setTableRows}
                            classes={classes}
                            rows={rows}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={
                                rows.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                ).length
                            }
                            headCells={headCells}
                            title={title}
                        />
                        <TableBody>
                            {props.spaceList || props.fileOrFolderList
                                ? props.fileOrFolderList
                                    ? renderFileandFolderList()
                                    : renderSpaceList()
                                : rows
                                      .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      .map((row, index) => {
                                          const isItemSelected = isSelected(
                                              row.id
                                          );
                                          const labelId = `enhanced-table-checkbox-${index}`;
                                          return (
                                              <TableRow
                                                  hover
                                                  className={
                                                      "tableRowClass cursorPointer"
                                                  }
                                              >
                                                  <TableCell
                                                      padding="checkbox"
                                                      onClick={(event) =>
                                                          handleDoubleClicks(
                                                              event,
                                                              row.name,
                                                              row.id,
                                                              index,
                                                              row
                                                          )
                                                      }
                                                      role="checkbox"
                                                      aria-checked={
                                                          isItemSelected
                                                      }
                                                      tabIndex={-1}
                                                      key={row.id}
                                                      selected={isItemSelected}
                                                  >
                                                      <Checkbox
                                                          id={"chk_" + row.id}
                                                          checked={
                                                              isItemSelected
                                                          }
                                                          inputProps={{
                                                              "aria-labelledby":
                                                                  labelId,
                                                          }}
                                                          color="primary"
                                                      />
                                                  </TableCell>
                                                  <TableCell
                                                      align="left"
                                                      color="blue"
                                                      padding="none"
                                                  >
                                                      {row.type}
                                                  </TableCell>
                                                  <TableCell
                                                      component="th"
                                                      align="left"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      className="nameCell"
                                                      title={row.name}
                                                  >
                                                      {row.name}
                                                  </TableCell>

                                                  <TableCell
                                                      align="center"
                                                      padding="none"
                                                  >
                                                      {row.time}
                                                  </TableCell>
                                                  <TableCell
                                                      align="center"
                                                      padding="none"
                                                  ></TableCell>
                                              </TableRow>
                                          );
                                      })}
                            {rows.length == 0 && (
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        style={{ textAlign: "center" }}
                                    >
                                        No Items Available !!
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <div className="spaceListInfoHrLine"></div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}
