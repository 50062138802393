import http from "../bae-client/HTTP";

export const loadDashboardData = (data) => {
    return {
        type: "FETCH_DASHBOARD_DATA",
        payload: data,
    };
};
export const fetchFilterData = (data) => {
    return {
        type: "LOAD_FILTER_DATA",
        payload: data,
    };
};
export const fetchInstanceDataByGenericId = (id) => {
    return async (dispatch) => {
        const response = await http.sendRestRequest(
            "documentChain/instance/list?genericId=" + id
        );
        dispatch({
            type: "LOAD_INSTANCE_DATA",
            payload: response,
        });
    };
};
export const setExistingGenericData = (data) => {
    return {
        type: "SHOW_EXISTING_GENERIC",
        payload: data,
    };
};

export const openInstance = (data) => {
    return {
        type: "OPEN_INSTANCE",
        payload: data,
    };
};

export const setDashboardAlerts = (data) => {
    return {
        type: "SET_DASHBOARD_ALERTS",
        payload: data,
    };
};

export const setGenericIdToNameMap = (data) => {
    return {
        type: "SET_GENERIC_ID_TO_NAME_MAP",
        payload: data,
    };
};
export const setGenericIdToDisplayNameMap = (data) => {
    return {
        type: "SET_GENERIC_ID_TO_DISPLAY_NAME_MAP",
        payload: data,
    };
};
export const setGenericNameToIdMap = (data) => {
    return {
        type: "SET_GENERIC_NAME_TO_ID_MAP",
        payload: data,
    };
};

export const setSelectedGenericDropdownValuesAggrReport = (data) => {
    return {
        type: "SET_SELECTED_GENERIC_DROPDOWN_VALUES_AGGR_REPORT",
        payload: data,
    };
};

/////////////////////////////////
export const setSelectedModelDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_MODEL_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedGenericDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_GENERIC_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedInstanceDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_INSTANCE_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedInstanceDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_INSTANCE_DROPDOWN_VALUE",
        payload: data,
    };
};
export const setSelectedBranchDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_BRANCH_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedTimeRangeDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_TIME_RANGE_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedTimeRangeDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_TIME_RANGE_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedTransactionDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_TRANSACTION_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedTransactionDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedFromDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_FROM_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedFromDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_FROM_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedToDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_TO_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedToDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_TO_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedPromiseDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_PROMICE_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedPromiseDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_PROMISE_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedExceptionDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedExceptionDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedExceptionDropdownStatus = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_DROPDOWN_STATUS",
        payload: data,
    };
};
//new
export const setSelectedExceptionStatusDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE",
        payload: data,
    };
};
//
export const setSelectedTimeDropdownIndex = (data) => {
    return {
        type: "SET_SELECTED_TIME_DROPDOWN_INDEX",
        payload: data,
    };
};
//new
export const setSelectedTimeDropdownValue = (data) => {
    return {
        type: "SET_SELECTED_TIME_DROPDOWN_VALUE",
        payload: data,
    };
};
//

export const setDashboardSearchText = (data) => {
    return {
        type: "SET_DASHBOARD_SEARCH_TEXT",
        payload: data,
    };
};

export const setDashboardAlertData = (data) => {
    return {
        type: "SET_DASHBOARD_ALERT_DATA",
        payload: data,
    };
};

export const setSelectedGenericIndexesState = (data) => {
    return {
        type: "SET_SELECTED_GENERIC_INDEXES_STATE",
        payload: data,
    };
};

export const setAggregatedReportData = (data) => {
    return {
        type: "SET_AGGREGATED_REPORT_DATA",
        payload: data,
    };
};
