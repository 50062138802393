import React from 'react';

class Section extends React.Component {
	constructor(props) {
		super(props);
		this.id = this.props.id;
		this.headerId = this.id + "-heading";
	}

	renderTitle() {
		if (this.props.title !== null && this.props.title !== undefined) {
			return <h2 id={this.headerId}>{this.props.title}</h2>
		}
	}
	renderHeader() {
		if (this.props.header !== null && this.props.header !== undefined) {
			return <div>{this.props.header}</div>
		}
	}
	
	render() {
		return (
			<div id={this.id} aria-labelledby={this.headerId}>
				{this.renderTitle()}
				{this.renderHeader()}
				{this.props.children}
			</div>
		);
	}
}

export default Section