import React from "react";
import ModalView from "../../../../../../../stylized/ModalView";
import TextBox from "../../../../../../../stylized/TextBox";

export default function SpaceHeader({
    handleAddSpaceClick,
    addSpaceModalShow,
    closeAddSpaceModal,
    setText,
    addNewSpace,
}) {
    return (
        <div
            className="row w-100"
            style={{ marginTop: "15px", marginBottom: "15px" }}
        >
            <div className="col-7" style={{ textAlign: "end" }}>
                <span className="spacesTitle newFontMontserrat"> Spaces </span>
            </div>
            <div
                id="addNewSpaceBtn"
                className="col-5 doLeft"
                onClick={() => handleAddSpaceClick()}
            >
                <img
                    src="./images/AddWorkspace.svg"
                    title="Add Space"
                    style={{ cursor: "pointer" }}
                ></img>
                <span className="addLabel newFontMontserrat">Add Spaces </span>
            </div>
            {addSpaceModalShow ? (
                <ModalView
                    show={addSpaceModalShow}
                    onHide={closeAddSpaceModal}
                    footer={"false"}
                    size="md"
                >
                    <div
                        className="row doCenter"
                        style={{ flexDirection: "column" }}
                    >
                        <img
                            className="addWorkspaceModalImg"
                            src="./images/Workspace A.svg"
                            alt="add workspace"
                        ></img>
                        <span className="addWorkspaceModal">Add Space</span>
                        <TextBox
                            id="spacename"
                            className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                            type="text"
                            onchange={setText}
                            label="Name your space..."
                            formControl="Off"
                            doCenter={true}
                        ></TextBox>
                        <input
                            type="button"
                            id="addWorkspaceModalBtn"
                            className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                            style={{ height: "40px", marginTop: "45px" }}
                            value="ADD SPACE"
                            title={"Add Space"}
                            onClick={addNewSpace}
                        ></input>
                    </div>
                </ModalView>
            ) : null}
        </div>
    );
}
