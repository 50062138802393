export const spaceListData = (spaceList = null, action) => {
    if (action.type === "FETCH_SPACE_LIST") {
        return action.payload;
    }
    return spaceList;
};
export const newSpace = (newSpaceName = null, action) => {
    if (action.type === "ADD_SPACE") {
        return action.payload;
    }
    return newSpaceName;
};
export const spaceIdToName = (newSpaceNameMap = null, action) => {
    if (action.type === "SPACE_ID_TO_NAME_MAP") {
        return action.payload;
    }
    return newSpaceNameMap;
};
export const spaceNameToId = (spaceNameToIdMap = null, action) => {
    if (action.type === "SPACE_NAME_TO_ID_MAP") {
        return action.payload;
    }
    return spaceNameToIdMap;
};
export const spaceIdToWorkspaceId = (
    spaceIdToWorkspaceIdMap = null,
    action
) => {
    if (action.type === "SPACE_ID_TO_WORKSPACE_ID_MAP") {
        return action.payload;
    }
    return spaceIdToWorkspaceIdMap;
};
