import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../../../ControlTowerContainer/controlTower.css";

const useStyles = makeStyles({
    container: {
        marginLeft: "-15px",
        marginTop: "-8px",
        display: "flex",
        flexDirection: "column",
    },
    noNode: {
        marginLeft: "-15px",
        padding: "8px",
        fontSize: "16px",
        textAlign: "center",
    },
    element: { fontSize: "15px", marginBottom: "8px", paddingBottom: "2px" },
    heading: { fontWeight: "bold" },
    label: {},
});

function NodeDetails({ exceptionDetails }) {
    const classes = useStyles();
    const render = () => {
        if (!exceptionDetails.length)
            return (
                <div id="1234" className={classes.noNode}>
                    {"Select Control Tower Node to view details!"}
                </div>
            );
        else {
            return (
                <div className={classes.container}>
                    {/* <div className={classes.element}>
                        <div className={classes.heading}>{"Exception"}</div>
                        <div className={classes.label}>
                            {exceptionDetails[0]?.exceptionName}
                        </div>
                    </div>
                    <div className={classes.element}>
                        <div className={classes.heading}>
                            {"Expected Vessel"}
                        </div>
                        <div className={classes.label}>
                            {exceptionDetails[0]?.exceptionHeader}
                        </div>
                    </div>
                    <div className={classes.element}>
                        <div className={classes.heading}>{"Actual Vessel"}</div>
                        <div className={classes.label}>
                            {exceptionDetails[0]?.exceptionBody}
                        </div>
                    </div>
                    <div className={classes.element}>
                        <div className={classes.heading}>
                            {"Current Status"}
                        </div>
                        <div className={classes.label}>
                            {exceptionDetails[0]?.exceptionStatus}
                        </div>
                    </div> */}
                    <div
                        class="innerText show"
                        style={{ padding: "16px 37px" }}
                        dangerouslySetInnerHTML={{
                            __html: exceptionDetails[0].exceptionText,
                        }}
                    ></div>
                </div>
            );
        }
    };
    return render();
}

export default NodeDetails;
