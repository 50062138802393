import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setActivePage, resetStore } from "../../../../actions";
import user from "../../../../bae-client/User";
import PopoverView from "../../../stylized/PopoverView";
import { removeUserSession } from "../Login/ActiveUser";
import "./UserProfile.css";
function UserProfileDropdown({
    userInfo,
    show,
    target,
    hidePopover,
    resetStore,
    setActivePage,
    setUser,
    handleLogout,
}) {
    const isReadOnlyUser = sessionStorage.getItem('isReadOnlyUser') === "true";
    let userDetails = userInfo;
    userDetails = user.getBwUserInfo();
    const username =
        userDetails !== null
            ? userDetails.firstName + " " + userDetails.lastName
            : "";
    const userEmail = userDetails !== null ? userDetails.userEmail : "";
    const closePopover = () => {
        hidePopover();
    };
    const handleSettingBtnClick = () => {
        hidePopover();
        setActivePage("Settings");
    };
    const handleLogoutBtnClick = () => {
        hidePopover();
        handleLogout();
        setUser(false);
        resetStore();
        removeUserSession();
    };
    const renderUserInfo = () => {
        return (
            <div className="userInfoContainer bottomBorder">
                <div className="row m-0 w-100">
                    <span className="listItemTitle">{username}</span>
                </div>
                <div className="row m-0 w-100">
                    <span className="listItemSubTitle">{userEmail}</span>
                </div>
            </div>
        );
    };
    const renderStaticInfo = () => {
        return (
            <div className="userInfoStaticMenu">
                <div
                    className={"row m-0 w-100 bottomBorder cursorPointer"}
                    id="spn_userProfileDropdown_setting"
                    onClick={handleSettingBtnClick}
                >
                    <span className={"listItemTitle"}>Settings</span>
                </div>
                <div
                    className="row m-0 w-100 cursorPointer"
                    onClick={handleLogoutBtnClick}
                >
                    <span
                        id="spn_userProfileDropdown_logout"
                        className="listItemTitle"
                    >
                        Log Out
                    </span>
                </div>
            </div>
        );
    };
    const renderMenuItems = () => {
        let items = [];
        items.push(renderUserInfo());
        items.push(renderStaticInfo());
        return items;
    };
    return (
        <div>
            <PopoverView
                id={"pop_userProfile_userInfo"}
                show={show}
                anchorEl={target}
                thOrigin="center"
                ahOrigin="left"
                closePopover={closePopover}
            >
                {renderMenuItems()}
            </PopoverView>
        </div>
    );
}
const mapDispatchToProps = () => {
    return {
        setActivePage,
        resetStore,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(UserProfileDropdown);
