import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../index.css";
import "./style.css";
import { getDateByFormat } from "../../../../../../../bae-client/Utils";

var openInstanceValue = null;
export default function AlertList({
    alertData,
    openInstance,
    instanceList,
    closeAlert,
    selectedAlerts,
    handleCheckboxChange,
    show,
}) {
    const genericIdToNameMap = useSelector((state) => state.genericIdToNameMap);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedAlertData, setselectedAlertData] = useState([]);
    const [openInstanceData, setOpenInstanceData] = useState({});
    const open = Boolean(anchorEl);
    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setOpenInstanceData({
            genericId: data.genericId,
            instanceIds: [data.instanceId],
        });
        const updatedData = { [data.id]: true };
        setselectedAlertData(updatedData);
    };

    const handleClose = (e) => {
        if (e && e.target && e.target.id) {
            if (e.target.id === "Open Instance") {
                openInstance(openInstanceData);
            }
            if (e.target.id === "Close Alert") {
                closeAlert(selectedAlertData);
            }
        }
        setAnchorEl(null);
    };

    const data = alertData.map((alert) => {
        const icon =
            alert.blockType.trim().toLowerCase() === "optional" ||
            alert.exceptionType === "SKIPPED"
                ? "yellowAlert.png"
                : alert.exceptionType === "PROACTIVE ALERT"
                ? "Flow/t proactive.svg"
                : "redAlert.png";
        return {
            id: alert.exceptionLogObjectId,
            genericId: alert.genericId,
            instanceId: alert.instanceId,
            name: `${alert.exceptionText}`,
            desc: `${alert.exceptionLogObjectId}`,
            url: "./images/" + icon,
            background: "#ffeded;",
            createdOn: alert.createdOn,
            type: alert.exceptionType.trim(),
        };
    });
    const renderMenu = () => {
        return (
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} id="Open Instance">
                    <div id="Open Instance">
                        <img
                            className="deleteIcon"
                            title="Open Instance"
                            src="./images/Rename.svg"
                            alt="Open Instance"
                            id="Open Instance"
                        />
                        <span
                            className="btn_title"
                            title="Open Instance"
                            id="Open Instance"
                        >
                            Open Instance
                        </span>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleClose} id="Close Alert">
                    <div id="Close Alert">
                        <img
                            className="deleteIcon"
                            title="Close Alert"
                            src="./images/fileTypeIcon/close.png"
                            alt="Close Alert"
                            id="Close Alert"
                        />
                        <span
                            className="btn_title"
                            title="Close Alert"
                            id="Close Alert"
                        >
                            Close Alert
                        </span>
                    </div>
                </MenuItem>
            </Menu>
        );
    };
    const openInstanceView = () => {
        openInstance(selectedAlertData);
    };
    const returnInstanceData = (data) => {
        let string = data.type + "-";
        let fiterArray = [];
        if (instanceList && instanceList.length) {
            fiterArray = instanceList.filter(
                (instance) =>
                    parseInt(instance.instanceId) === parseInt(data.instanceId)
            );
        }
        if (fiterArray.length) {
            string += fiterArray[0].instanceName;
        } else {
            string += "";
        }
        return string;
    };
    const newdata = data.map((data) => {
        return (
            <div
                key={data.id}
                className="alert-list"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px",
                }}
            >
                {show === true ? (
                    <div>
                        <input
                            type="checkbox"
                            checked={selectedAlerts[data.id] || false}
                            onChange={() => handleCheckboxChange(data.id)}
                            onClick={(e) => e.stopPropagation()} // Stop event propagation to prevent triggering the li onClick
                            style={{
                                marginRight: "8px",
                                width: "20px",
                                height: "20px",
                                gap: "10px",
                                borderRadius: "4px",
                                border: "1px solid #9DA5B4",
                            }}
                        />
                    </div>
                ) : null}
                <div
                    className="alertBody dashboardAlert"
                    style={{ position: "relative", flex: "1" }}
                >
                    <div className="alertIcon col-1 p-0 doCenter">
                        <img src={data.url} />
                    </div>
                    <div className="alertTitle textEllipsis col-9 pr-0">
                        <div style={{ fontWeight: "700" }}>
                            {returnInstanceData(data)}
                        </div>
                        {data.type === "KEY MISMATCH" ? (
                            <div
                                style={{
                                    fontWeight: "400",
                                    height: "50px",
                                }}
                                className="listHeader invisibleScroller"
                                dangerouslySetInnerHTML={{
                                    __html: data.name,
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    fontWeight: "400",
                                    width: "300px",
                                }}
                                className="listHeader textEllipsis"
                                dangerouslySetInnerHTML={{
                                    __html: data.name,
                                }}
                            />
                        )}
                    </div>
                    <div
                        className="alertBtn col-2 doCenter p-0 cursorPointer"
                        style={{ position: "relative" }}
                    >
                        <img
                            id="img_19573"
                            src="./images/alertMenu.png"
                            title="options"
                            onClick={(event) => handleClick(event, data)}
                        />
                        <div
                            className="textEllipsis"
                            style={{
                                position: "absolute",
                                padding: "8px",
                                bottom: "-30px",
                                right: "0px",
                                fontSize: "10px",
                                fontWeight: "700",
                                width: "100%",
                            }}
                            title={
                                genericIdToNameMap &&
                                genericIdToNameMap.get(data.genericId)
                            }
                        >
                            {genericIdToNameMap &&
                                genericIdToNameMap.get(data.genericId)}
                        </div>
                        <div
                            className="textEllipsis alert-time"
                            title={getDateByFormat(
                                data.createdOn,
                                "month dd,yyyy",
                                "alert"
                            )}
                        >
                            {getDateByFormat(
                                data.createdOn,
                                "month dd,yyyy",
                                "alert"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div
            className="alert-list invisibleScroller "
            style={{ minHeight: "385px", height: "20px" }}
        >
            <>
                {renderMenu()}
                {newdata}
            </>
        </div>
    );
}
