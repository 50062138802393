let expandedFolderArray = [];
export const convertObjectMapToArrayMap = (
    objectMap,
    hideFile,
    parentId,
    parentFolderHierarchy,
    selectedId
) => {
    if (Array.isArray(objectMap)) return objectMap;
    const arr = [];

    for (const key in objectMap) {
        if (selectedId && key === selectedId)
            expandedFolderArray = [...parentFolderHierarchy, key];
        if (Object.hasOwnProperty.call(objectMap, key)) {
            const element = objectMap[key];
            if (
                element.children &&
                Object.keys(element.children).length !== 0 &&
                parentFolderHierarchy
            ) {
                arr.push({
                    ...element,
                    id: key,
                    children: convertObjectMapToArrayMap(
                        element.children,
                        hideFile,
                        key,
                        [...parentFolderHierarchy, key],
                        selectedId,
                        expandedFolderArray
                    ),
                    parentId: parentId,
                    parentFolderHierarchy,
                });
            } else if (
                element.children &&
                Object.keys(element.children).length === 0
            ) {
                arr.push({
                    ...element,
                    id: key,
                    parentId: parentId,
                    children: [],
                    parentFolderHierarchy,
                });
            } else if (!hideFile)
                arr.push({
                    ...element,
                    id: key,
                    parentId: parentId,
                    children: {},
                    parentFolderHierarchy,
                });
        }
    }
    return arr;
};
