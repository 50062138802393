import http from "./HTTP";
import config from "../config";

class User {
    constructor() {
        this.userInfo = {
            username: "",
            password: "",
            userId: null,
            membershipId: null,
            nhId: null,
            nhName: "",
            token: "",
        };
        this.bwUserInfo = null;
    }
    getUserInfo = () => {
        return this.userInfo;
    };
    setUserInfo = (userinfo) => {
        let response = "" + userinfo;
        response = response.replace("{", "");
        response = response.replace("}", "");
        let nhid = response.split(",")[1].split("=")[1];
        let nhName = response.split(",")[2].split("=")[1];
        let userId = response.split(",")[3].split("=")[1];
        let membershipId = response.split(",")[4].split("=")[1];
        // this.userInfo.username = userName;
        // this.userInfo.password = password;
        this.userInfo.nhName = nhName;
        this.userInfo.userId = parseInt(userId);
        this.userInfo.nhId = parseInt(nhid);
        this.userInfo.membershipId = parseInt(membershipId);
        this.userInfo.token = atob(
            "" +
                this.userInfo.username +
                ":" +
                this.userInfo.password +
                ":" +
                this.userInfo.nhName
        );

        http.setNhId(this.userInfo.nhId);
        http.setMembershipId(this.userInfo.membershipId);
    };
    setBwUserInfo = (userInfo) => {
        this.bwUserInfo = userInfo;
    };
    getBwUserInfo = () => {
        return this.bwUserInfo;
    };
    login(userName, password) {
        let promise = new Promise((resolve, reject) => {
            // if (password === undefined || password === null) password = "0"
            let url = "login";
            let token = btoa("" + userName + ":" + password);
            let data = {
                token: token,
            };
            http.verifyUserCredentials(url, "GET", data)
                .then((response) => {
                    this.userInfo.username = userName;
                    this.userInfo.password = password;
                    resolve(response);
                })
                .catch((errResponse) => {
                    console.log(errResponse);
                    reject(errResponse);
                });
        });
        return promise;
        // return this.verifyCredentials(userName, password).then(this.getNHInfo);
    }

    verifyCredentials(userName, password) {
        let credVerified = new Promise((resolve, reject) => {
            //can request any login servlet to authorize
            //Not authorized for now
            //hard-coding to be removed
            let authString = window.btoa(
                userName + ":" + password + ":NH0|NH1|NH2|" + userName
            );
            http.setAuthorization(authString);
            resolve(userName);
        });
        return credVerified;
    }

    verifyToken() {
        let tokenInfo = new Promise((resolve, reject) => {
            this.getNHInfo()
                .then((val) => {
                    resolve(val);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return tokenInfo;
    }
    getNhPath() {
        const params = new URLSearchParams(window.location.search);
        let authString = sessionStorage.getItem("token");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_TOKEN
        //     : sessionStorage.getItem("token"); //params.get('token');
        let decodedAuthString = atob(authString).split(":");
        let nhPath = decodedAuthString[2].split("|");
        return nhPath;
    }
    getNHInfo() {
        let nhInfoRequested = new Promise((resolve, reject) => {
            let url = "neighborhood/1";
            http.sendRestRequest(url)
                .then((result) => {
                    if (result !== undefined) {
                        resolve({ status: "valid" });
                    }
                })
                .catch((error) => {
                    reject({ status: "invalid" });
                });
        });
        return nhInfoRequested;
    }
}

let user = new User();
export default user;
