import React, { Component } from "react";

class Header extends Component {
    render() {
        return (
            <div
                className="doCenter row m-0 w-100 titleRow cursorPointer"
                onClick={this.props.changePage}
            >
                <div className="center mb-3">
                    <div className="bw_header_row doCenter">
                        <img
                            src="images/UCLogo.png"
                            alt="UC Logo"
                            className="bw_header_icon"
                        />
                        <span className="bw_header_text"> Unity Central</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
