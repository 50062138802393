export const webSocketNotification = (message = null, action) => {
    if (action.type === "UPDATE_NOTIFICATION") {
        return action.payload;
    }
    if (action.type === "RELOAD_INTEGRATIONS") {
        return action.payload;
    }
    if (action.type === "CALL_NOTIFICATION") {
        return action.payload;
    }
    return message;
};

