import React from "react";
import ChipsView from "../../../../../../stylized/ChipsView";
import TextBox from "../../../../../../stylized/TextBox";
import "./style.css";

function SearchBarView({
    chipsArrayData,
    createChip,
    selectedText,
    setFromText,
    handleDeleteChipItem,
    isCompareView,
    onSearchTextBlur,
    id
}) {
    const handleKeyDown = (value, key) => {
        if (value.trim() !== "") {
            createChip(value.trim());
        }
    };

    return (
        <div className="doCenter pr-0">
            <div
                className="row w-100 m-0 align-items-center"
                id="DC_dashboard_SearchBox"
            >
                {/* <ChipsView
                    items={chipsArrayData}
                    ondelete={handleDeleteChipItem}
                ></ChipsView> */}
                <TextBox
                    divClass={"rippleSerachDiv flowDashBoard"}
                    className="getAddress serachInputBox"
                    id={id ? id : "DCSearchTextBox"}
                    clearAfterChange={true}
                    type="text"
                    label={"Search..."}
                    placeholder={isCompareView ? true : "Search..."}
                    onkeydown={createChip}
                    handleRippleKeys={handleKeyDown}
                    onchange={setFromText}
                    onfocusout={onSearchTextBlur}
                ></TextBox>
                <img
                    src="./images/Union.svg"
                    alt=""
                    style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "10px", // Adjust icon positioning as needed
                    }}
                />
            </div>
        </div>
    );
}

export default SearchBarView;
