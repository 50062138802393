export const DocumentChainReducer = (documentChainReducer = null, action) => {
    if (action.type === "LOAD_DOCUMENT_CHAIN_LIST") {
        return { ...documentChainReducer, documentChainList: action.payload };
    }
    return documentChainReducer;
};

export const selectedFlows = (data = [], action) => {
    if (action.type === "SET_FLOWS") return action.payload;
    return data;
};
export const selectedFlowIds = (data = [], action) => {
    if (action.type === "SET_FLOW_IDS") return action.payload;
    return data;
};
export const selectedFlowIdsAlert = (data = null, action) => {
    if (action.type === "SET_FLOW_IDS_ALERT") return action.payload;
    return data;
};
export const selectedFilterOptions = (data = [], action) => {
    if (action.type === "SET_FILTER_OPTIONS_ALERT") return action.payload;
    return data;
};
export const selectedAlertIds = (state = null, action) => {
    if (action.type === "SET_ALERT_IDS") return action.payload;
    return state;
};

export const selectedExceptionIds = (data = [], action) => {
    if (action.type === "SET_EXCEPTION_IDS") return action.payload;
    return data;
};
export const selectedExceptionIdsAlert = (data = null, action) => {
    if (action.type === "SET_EXCEPTION_ALERT_IDS") return action.payload;
    return data;
};
export const selectedTimeFilterGlobal = (data = [], action) => {
    if (action.type === "SET_TIME_FILTER") return action.payload;
    return data;
};
export const selectedTimeFilterGlobalAlert = (data = null, action) => {
    if (action.type === "SET_TIME_FILTER_ALERT") return action.payload;
    return data;
};
export const selectedExceptions = (data = [], action) => {
    if (action.type === "SET_EXCEPTIONS") return action.payload;
    return data;
};
export const allInstanceWithFilter = (data = [], action) => {
    if (action.type === "SET_ALL_INSTANCES") return action.payload;
    return data;
};
export const selectedFinalFilter = (data = null, action) => {
    if (action.type === "SET_FINAL_FILTER") return action.payload;
    return data;
};

export const allInstanceWithFilterAlert = (data = null, action) => {
    if (action.type === "SET_ALL_INSTANCES_ALERT") return action.payload;
    return data;
};

export const alertLogData = (data = [], action) => {
    if (action.type === "SET_ALERT_LOG_DATA") return action.payload;
    return data;
};
