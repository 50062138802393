import React, { Component } from "react";
import { connect } from "react-redux";
import { setActiveWorkspace } from "../../../actions";
import http from "../../../bae-client/HTTP";
import AlertDialog from "../../stylized/AlertDialog";
import ArchiveListItems from "../../stylized/ArchiveListItems";
import EmptyArchive from "../../stylized/EmptyArchive";
import Loader from "../../stylized/Loader";
import * as Mixpanel from "../../../mix-panel/Mixpanel";

class Archive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logRowsData: [],
            showLoader: false,
            sortBy: "descending",
            sortAplphaType: "descending",
            selectedFilter: "",
            alertDialogShow: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
        };
        this.restoreFile = this.restoreFile.bind(this);
        this.setAlertDialogShow = this.setAlertDialogShow.bind(this);
        this.handleRestoreBtnClick = this.handleRestoreBtnClick.bind(this);
    }
    componentDidMount = () => {
        Mixpanel.callPageViewEvent("Archive");

        this.getArchivedList();
        // this.setState({ logRowsData: archiveLogsData });
    };
    restoreFile = (fileObject) => {
        let url = `archive/restore?objectId=${fileObject.objectId}&objectType=${fileObject.objectType}`;
        this.setState({ showLoader: true });
        http.sendRestRequest(url, "PUT")
            .then((resp) => {
                let item = "item";
                if (fileObject.objectType === "File") {
                    item = "File";
                } else if (fileObject.objectType === "Folder") {
                    item = "Folder";
                } else if (fileObject.objectType === "Space") {
                    item = "Space";
                } else if (fileObject.objectType === "Alert") {
                    item = "Notification";
                }
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "",
                    alertDialogMessage: `${item} has been restored successfully!`,
                });
                this.getArchivedList();
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "",
                    alertDialogMessage: err.error,
                });
            });
    };

    handleRestoreBtnClick = (row, index) => {
        if (
            row.objectType === "File" ||
            row.objectType === "Folder" ||
            row.objectType === "Space" ||
            row.objectType === "Alert"
        ) {
            this.restoreFile(row);
        } else {
            this.setState({
                alertDialogShow: true,
                alertDialogTitle: "",
                alertDialogMessage:
                    "Restoring archived item is only enabled for files and Alerts!",
            });
        }
    };
    handleRestoreAllBtnClick = () => {
        this.setState({
            alertDialogShow: true,
            alertDialogTitle: "",
            alertDialogMessage: "Restoring all items is not enabled yet!",
        });
    };
    sortDataByExistingState = (data) => {
        let dataForSorting = data;
        if (this.state.sortBy === "assending") {
            dataForSorting.sort((a, b) => {
                return (
                    new Date(a.archivedDate).getTime() -
                    new Date(b.archivedDate).getTime()
                );
            });
        } else if (this.state.sortBy === "descending") {
            dataForSorting.sort((a, b) => {
                return (
                    new Date(b.archivedDate).getTime() -
                    new Date(a.archivedDate).getTime()
                );
            });
        }
        return dataForSorting;
    };
    sortDataByDate = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(a.archivedDate).getTime() -
                    new Date(b.archivedDate).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(b.archivedDate).getTime() -
                    new Date(a.archivedDate).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        }
    };
    sortByAlphabetically = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) =>
                a.objectType.localeCompare(b.objectType)
            );
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) =>
                b.objectType.localeCompare(a.objectType)
            );
        }
    };
    handleDateFilter = (listValue) => {
        // sortBtnClicked(listValue);
        let actionType = listValue;
        if (actionType === "date") {
            if (this.state.sortBy === "assending") {
                this.sortDataByDate("descending");
                this.setState({ sortBy: "descending" });
            } else if (this.state.sortBy === "descending") {
                this.sortDataByDate("assending");
                this.setState({ sortBy: "assending" });
            }
        } else if (actionType === "type") {
            if (this.state.sortAplphaType === "assending") {
                this.sortByAlphabetically("descending");
                this.setState({ sortAplphaType: "descending" });
            } else if (this.state.sortAplphaType === "descending") {
                this.sortByAlphabetically("assending");
                this.setState({ sortAplphaType: "assending" });
            }
        }
    };
    renderCardRow = () => {
        if (this.state.logRowsData.length)
            return (
                <ArchiveListItems
                    rowItems={this.state.logRowsData}
                    sortBtnClicked={this.handleDateFilter}
                    restoreBtnClicked={this.handleRestoreBtnClick}
                    restoreAllBtnClicked={this.handleRestoreAllBtnClick}
                ></ArchiveListItems>
            );
        else if (!this.state.showLoader)
            return (
                <div
                    style={{
                        height: "84vh",
                        background: "white",
                        borderRadius: "10px",
                    }}
                >
                    <EmptyArchive></EmptyArchive>
                </div>
            );
    };

    getArchivedList = () => {
        this.setState({ showLoader: true });
        let url = "archive/objectList";
        http.sendRestRequest(url, "GET")
            .then((resp) => {
                let archivedItems = this.sortDataByExistingState(resp);
                if (archivedItems.length > 0) {
                    this.setState({
                        showLoader: false,
                        logRowsData: archivedItems,
                    });
                } else
                    this.setState({
                        showLoader: false,
                        logRowsData: [],
                    });
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "",
                    alertDialogMessage: err.error,
                });
            });
    };

    setAlertDialogShow = () => {
        this.setState({
            alertDialogShow: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
        });
    };

    render() {
        return (
            <>
                <div className="w-100 pl-0 pr-0 contentPaneBottom">
                    {this.renderCardRow()}
                </div>
                <Loader show={this.state.showLoader}></Loader>
                <AlertDialog
                    open={this.state.alertDialogShow}
                    setOpen={this.setAlertDialogShow}
                    title={this.state.alertDialogTitle}
                    message={this.state.alertDialogMessage}
                    buttonId="closeModalButton"
                ></AlertDialog>
            </>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        setActiveWorkspace,
    };
};

const mapStateToProps = (state) => {
    return {
        activeWorkspace: state.activeWorkspace,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(Archive);
