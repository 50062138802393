import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const formatDate = () => {
    var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

const convertJSDateToExcelDate = (inDate) => {
    const retValue =
        25569 +
        (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
            (1000 * 60 * 60 * 24);
    return Number(retValue.toString().split(".")[0]);
};

const excelDateToJSDate = (excelDate) => {
    var date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    var converted_date = date.toISOString().split("T")[0];
    return converted_date;
};

export default function DatePickers({ defaultValue }) {
    const classes = useStyles();
    const [state, setState] = useState();

    const onInputChange = (event) => {
        event.persist();
        setState(convertJSDateToExcelDate(new Date(event.target.value)));
    };

    return (
        <form className={classes.container} noValidate>
            <TextField
                id="date"
                type="date"
                defaultValue={
                    parseInt(defaultValue)
                        ? excelDateToJSDate(defaultValue)
                        : formatDate()
                }
                className={classes.textField}
                onChangeCapture={onInputChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </form>
    );
}
