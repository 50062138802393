import * as React from "react";
import DialogView from "../../../../../../../stylized/DialogView";
import FormWrapper from "../../../../Integration/FormWrapper/FormWrapper";
import AlertDialog from "../../../../../../../stylized/AlertDialog";
import { ButtonComponent } from "../../../../../../../button/button";

export default function Move({
    disable,
    parentFolderId,
    activeWorkspace,
    selectedCheckedData,
    selectedRowData,
    setSelectedRowData,
    isFileOrFolderSelected,
    lastSelectedRowData,
    folderData,
    itemSpace,
    setIsMove = () => {},
    enable,
    fetchFolderListData,
    parent,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [moveItem, setMoveItem] = React.useState(null);
    const [showUploadOption, setShowUploadOption] = React.useState(false);
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const handleConfirmationDialog = () => {
        showAlertView("Move Item", "Multiple Selection Not Allowed");
    };
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const handleMove = (event) => {
        setIsMove(event.target.innerText);
        setAnchorEl(null);
        setMoveItem("folder");
        setShowUploadOption(true);
    };
    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
    };

    return (
        <>
            <ButtonComponent
                type={"secondary"}
                icon={"./images/fileTypeIcon/arrows-move 1.svg"}
                text={"Move"}
                id="div_table_move"
                BtnHandler={enable ? handleMove : null}
                disable={disable}
            />
            {/* <button
                id={"folder"}
                style={{
                    // float: "left",
                    padding: "0px",
                    paddingTop: "4px",
                    border: "none",
                    background: "transparent",
                    width: "auto",
                    cursor: enable ? "pointer" : "no-drop",
                }}
                title="Move"
                onClick=
                className={
                    disable
                        ? "cursorNotAllowed dropdown-item"
                        : "cursorPointer dropdown-item"
                }
                disabled={disable}
            >
                <img
                    // className={
                    //     disable
                    //         ? "cursorNotAllowed bw_plusButton"
                    //         : "cursorPointer bw_plusButton"
                    // }
                    id="icons"
                    alt="Move File"
                    title="Move"
                    src="./images/fileTypeIcon/arrows-move 1.svg"
                    style={{
                        cursor: enable ? "pointer" : "no-drop",
                    }}
                ></img>
                <span
                    id="title"
                    class="addLabel newFontMontserrat"
                    style={{
                        cursor: enable ? "pointer" : "no-drop",
                    }}
                >
                    Move
                </span>
            </button> */}
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <DialogView
                show={showUploadOption}
                size="lg"
                handleClose={handleCloseUploadDialog}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <FormWrapper
                    setIsMove={setIsMove}
                    Display={setDisplayShow}
                    activeWorkspace={activeWorkspace}
                    documentDetails={lastSelectedRowData}
                    parentFolderId={parentFolderId}
                    onHide={handleCloseUploadDialog}
                    parent={parent}
                    folderData={folderData}
                    fetchFolderData={fetchFolderListData}
                    isFileOrFolderSelected={isFileOrFolderSelected}
                    selectedRowData={selectedRowData}
                    setSelectedRowData={setSelectedRowData}
                    selectedCheckedData={selectedCheckedData}
                    accountType={"headerTitle"}
                    itemSpace={itemSpace}
                    defaultStep={1}
                    // formType={"shareChat"}
                    heading={"Move"}
                    hideFile={moveItem === "folder"}
                    hideFolder={moveItem === "space"}
                ></FormWrapper>
            </DialogView>
        </>
    );
}
