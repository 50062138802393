import { Icon } from "@material-ui/core";
// import Button from '../../stylized/Button';
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import http from "../../../bae-client/HTTP";
import config from "../../../config";
import AlertDialog from "../../stylized/AlertDialog";
import ListView from "../../stylized/ListView";
import Loader from "../../stylized/Loader";
import OutlineTextBoxView from "../../stylized/OutlineTextBoxView";
import PopoverView from "../../stylized/PopoverView";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { validateEmail } from "../../../bae-client/Validations";
import * as Mixpanel from "../../../mix-panel/Mixpanel";

const styles = {
    root: {
        "& .MuiButton-label": {
            border: "none",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "30px",
            letterSpacing: "0.15px",
            paddingLeft: "1rem",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            outline: "none",
            marginLeft: "-8px",
        },
    },
};

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.firstName = "";

        this.lastName = "";
        this.companyName = window.runtimeConfig.companyName || "";
        this.userEmail = "";
        this.userName = "";
        this.phoneNumber = "";
        this.password = "";
        this.confirmPassword = "";
        this.userId = -1;
        this.checkedStatus = false;
        this.departmentName = "";
        this.state = {
            checked: false,
            curruntPage: "",
            departmentName: "",
            isValidData: false,
            isPasswordValid: false,
            showPopover: false,
            popoverTarget: null,
            userName: "Username",
            userId: 0,
            showLoader: false,
            phoneNumber: "",
            showAlertModal: false,
            err_firstName: "",
            err_lastName: "",
            err_compName: "",
            err_dept: "",
            err_mail: "",
            err_phone: "",
            err_password: "",
            err_confPassword: "",
            err_role: "",
            pwdStrength: "",
        };
        this.departmentList = config.departmentList.split("|");
        this.phoneNumberValid = this.phoneNumberValid.bind(this);
    }

    componentDidMount = () => {
        Mixpanel.callSignUpPageViewEvent();
    };

    validateAllFields = () => {
        let flag = true;
        if (this.firstName.trim() === "") {
            flag = false;
            this.setState({ err_firstName: "Please enter your name" });
        }
        if (this.lastName.trim() === "") {
            flag = false;
            // this.showAlertView("","Please insert last name");
            // this.showAlertView("", "Please insert last name");
            this.setState({ err_lastName: "Please enter last name" });
        }
        if (this.userEmail.trim() === "") {
            flag = false;
            // this.showAlertView("","Please insert valid email");
            // this.showAlertView("", "Please insert valid email");
            this.setState({ err_mail: "Please enter valid email" });
        }
        if (this.companyName.trim() === "") {
            flag = false;
            // this.showAlertView("","Please insert company name");
            // this.showAlertView("", "Please insert company name");
            this.setState({ err_compName: "Please enter company name" });
        }
        if (this.password.trim() === "") {
            flag = false;
            // this.showAlertView("","Please insert password");
            // this.showAlertView("", "Please insert password");
            this.setState({ err_password: "Please enter password" });
        }
        if (this.departmentName.trim() === "") {
            flag = false;
            // this.showAlertView("","Please select Department name");
            // this.showAlertView("", "Please select Department name");
            this.setState({ err_dept: "Please select Department name" });
        }
        return flag;
    };
    validatePasswordPatern = (event) => {
        var password_Pattern =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,256}$/;
        if (this.password !== "" && this.password.match(password_Pattern)) {
            this.setState({
                err_password: "",
                pwdStrength: "green",
            });
            return true;
        } else {
            this.setState({
                pwdStrength: "#CC0000",
                err_password:
                    "Min. 8 to 256 characters with at least one capital letter, a number, and a special character.",
            });
            return false;
        }
    };
    validatePassword = () => {
        if (this.password !== "" && this.password === this.confirmPassword) {
            this.setState({ err_confPassword: "" });
            return true;
        } else {
            this.setState({
                err_confPassword:
                    "Password and Re-enter password are not matching",
            });
            return false;
        }
    };
    validatePhoneNumber = () => {
        var numberformat = /^\d{10}$/;
        if (this.phoneNumber !== "" && this.phoneNumber.match(numberformat)) {
            return true;
        } else {
            if (this.phoneNumber.trim() == "") {
                return true;
            } else {
                // this.showAlertView("",
                //     "You have entered an invalid phone Number! Only 10 digits allowed"
                // );
                this.showAlertView(
                    "",
                    "You have entered an invalid phone Number! Only 10 digits allowed"
                );
                return false;
            }
        }
    };
    ValidateEmail = () => {
        const isValidMail = validateEmail(this.userEmail);
        if (isValidMail === true) {
            this.setState({ err_mail: "" });
            return true;
        } else {
            this.setState({ err_mail: isValidMail });
            return false;
        }
        // var mailformat =
        //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if (this.userEmail !== "" && this.userEmail.match(mailformat)) {
        //     this.setState({ err_mail: "" });
        //     return true;
        // } else {
        //     this.setState({
        //         err_mail: "You have entered an invalid email address!",
        //     });
        //     return false;
        // }
    };
    validateName = () => {
        if (this.firstName === "") {
            this.setState({ err_firstName: "Please enter first name" });
        } else {
            this.setState({ err_firstName: "" });
        }
    };
    validateLastName = () => {
        if (this.lastName === "") {
            this.setState({ err_lastName: "Please enter last name" });
        } else {
            this.setState({ err_lastName: "" });
        }
    };
    validateCompName = () => {
        if (this.companyName === "") {
            this.setState({ err_compName: "Please enter company name" });
            return false;
        } else if (this.companyName.length > 100) {
            this.setState({ err_compName: "Maximum 100 characters allowed" });
            return false;
        } else {
            this.setState({ err_compName: "" });
            return true;
        }
    };
    handleResendMailButton = () => {
        this.setState({ showLoader: true });
        let url = "register/sendActivationCode?userId=" + this.state.userId;
        http.sendRestRegistraionRequest(url, "POST")
            .then((response) => {
                this.showAlertView("", "Email sent successfully");
                this.setState({ showLoader: false });
            })
            .catch((err) => {
                // this.showAlertView("",err);
                this.showAlertView("", err.error);
                this.setState({ showLoader: false });
            });
    };
    phoneNumberValid = () => {
        if (this.state.phoneNumber === "") {
            return true;
        } else {
            return (
                this.state.phoneNumber &&
                isValidPhoneNumber(this.state.phoneNumber)
            );
        }
    };
    handleSubmitBtn = () => {
        if (
            this.validateAllFields() === true &&
            this.ValidateEmail() === true &&
            this.validateCompName() === true &&
            this.validatePasswordPatern() &&
            this.validatePassword() === true
        ) {
            this.setState({ showLoader: true });
            let data = {
                userEmail: this.userEmail,
                firstName: this.firstName.trim(),
                lastName: this.lastName.trim(),
                companyName: this.companyName.trim(),
                departmentName: this.departmentName,
                contactNumber: this.state.phoneNumber
                    ? this.state.phoneNumber.trim()
                    : "",
                profilePicture: "comming soon",
                password: this.password,
            };
            let url = "register/registerUser";
            http.sendRestRegistraionRequest(url, "POST", data)
                .then((response) => {
                    let user = {};
                    user["name"] = data.firstName + " " + data.lastName;
                    user["email"] = data.userEmail;
                    user["company"] = data.companyName;
                    user["industry"] = data.departmentName;
                    user["plan"] = "Free";
                    Mixpanel.callSignUpEvent(user);
                    // Mixpanel.track("User Registration", {
                    //     "Registered User": response,
                    //     Department: data.departmentName,
                    // });
                    if (response.userId > 0) {
                        this.setState({
                            userId: response.userId,
                            showLoader: false,
                        });
                    } else {
                        // Mixpanel.track("User Registration Failed", {
                        //     Message: "Server side issue",
                        // });
                        this.showAlertView(
                            "",
                            "Some server side issue please try again..."
                        );
                        this.setState({ showLoader: false });
                    }
                })
                .catch((err) => {
                    // Mixpanel.track("User Registration Failed", {
                    //     Message: "Something went wrong.",
                    // });
                    this.showAlertView("", err.error);
                    this.setState({ showLoader: false });
                });
        }
    };
    handleInstanceCheckUncheck = () => {
        this.checkedStatus = !this.checkedStatus;
    };
    setFromText = (value, id, event) => {
        switch (id) {
            case "inp_signUp_firstName":
                {
                    this.firstName = value;
                }
                break;
            case "inp_signUp_lastName":
                {
                    this.lastName = value;
                }
                break;
            case "inp_signUp_companyname":
                {
                    this.companyName = value;
                }
                break;
            case "inp_signUp_useremail":
                {
                    this.userEmail = value.trim();
                }
                break;
            case "inp_signUp_phonenumber":
                {
                    this.phoneNumber = value;
                }
                break;
            case "inp_signUp_username":
                {
                    this.userName = value;
                }
                break;
            case "inp_signUp_password":
                {
                    this.password = value;
                }
                break;
            case "inp_signUp_confirmpassword":
                {
                    this.confirmPassword = value;
                }
                break;
            default:
                break;
        }
    };

    setEmailAsUserName = () => {
        this.setState({ userName: this.userEmail });
    };
    // popover Logic start here
    renderPopover = () => {
        // id={"menu_" + spaceID}
        return (
            <PopoverView
                id={"menu_Department"}
                show={this.state.showPopover}
                anchorEl={this.state.popoverTarget}
                closePopover={this.closePopover}
            >
                {this.renderMenuItems()}
            </PopoverView>
        );
    };
    showPopover = (e) => {
        this.setState({ showPopover: true, popoverTarget: e.currentTarget });
    };
    closePopover = (e) => {
        this.setState({ showPopover: false, popoverTarget: null });
    };
    renderMenuItems = () => {
        let items = [];
        this.departmentList.forEach((element, index) => {
            items.push({
                value: element + "_menu_" + index,
                text: element,
            });
        });
        return (
            <ListView
                className="noBorder"
                listItemClassname="pl-4"
                id={"departmentList"}
                label={"Department List"}
                items={items}
                showIcon="false"
                disableActiveIndex="true"
                onchange={this.handleListItemClick}
            />
        );
    };
    handleListItemClick = (value, text, event, i) => {
        this.departmentName = event;
        this.setState({ departmentName: event });
    };
    // popover Logic end here
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    render() {
        const { classes } = this.props;
        if (this.state.userId > 0) {
            return (
                <div
                    className="row w-100 doCenter doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <div className="row w-100 doCenter mb-5 mt-5">
                        <span className="confirmMailLabel">
                            A confirmation email has been sent to your email,
                            Check your inbox to get started!
                        </span>
                    </div>
                    <div
                        className="row doCenter"
                        style={{ marginTop: "10px", width: "50%" }}
                    >
                        <div className="row w-100 doCenter">
                            <button
                                type="submit"
                                id="btn_signup_resendMail"
                                className="btn btn-primary submitBtn"
                                style={{ fontWeight: "400" }}
                                name="btn_signup"
                                onClick={this.handleResendMailButton}
                            >
                                DIDN'T RECEIVE EMAIL? CLICK HERE TO TRY AGAIN
                            </button>
                        </div>
                    </div>
                    {this.renderAlertModal()}
                    <Loader show={this.state.showLoader} />
                </div>
            );
        } else {
            return (
                <div
                    className="doCenter row m-0"
                    style={{ flexDirection: "column", marginLeft: "25px" }}
                >
                    <div className="col"></div>
                    <div className="col-12 col-md-4 col-lg-4 col-sm-6 p-0">
                        <div
                            className="row m-0 w-100 doCenter"
                            style={{ alignItems: "flex-start" }}
                        >
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter pl-0">
                                <OutlineTextBoxView
                                    id="inp_signUp_firstName"
                                    placeholder="First"
                                    type="text"
                                    // onFocusInput={this.setState({
                                    //     err_firstName: "",
                                    // })}
                                    onChangeInput={this.setFromText}
                                    onBlurInput={this.validateName}
                                    errorMsg={this.state.err_firstName}
                                ></OutlineTextBoxView>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2 doCenter pl-0 pr-0">
                                <OutlineTextBoxView
                                    id="inp_signUp_lastName"
                                    placeholder="Last"
                                    type="text"
                                    onChangeInput={this.setFromText}
                                    onBlurInput={this.validateLastName}
                                    // onFocusInput={this.setState({
                                    //     err_lastName: "",
                                    // })}
                                    errorMsg={this.state.err_lastName}
                                ></OutlineTextBoxView>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter">
                            {window.runtimeConfig.companyName ? (
                                <OutlineTextBoxView
                                    id="inp_signUp_companyname"
                                    placeholder="Company Name"
                                    type="text"
                                    onChangeInput={this.setFromText}
                                    onBlurInput={this.validateCompName}
                                    errorMsg={this.state.err_compName}
                                    disabled={true}
                                    value={this.companyName}
                                ></OutlineTextBoxView>
                            ) : (
                                <OutlineTextBoxView
                                    id="inp_signUp_companyname"
                                    placeholder="Company Name"
                                    type="text"
                                    onChangeInput={this.setFromText}
                                    onBlurInput={this.validateCompName}
                                    errorMsg={this.state.err_compName}
                                ></OutlineTextBoxView>
                            )}
                        </div>
                        <div className="row m-0 w-100 doCenter">
                            {this.renderPopover()}
                            <div className="form-group w-100 center">
                                <div className={"row m-0 inp_TextBoxRow"}>
                                    <Button
                                        id="inp_signUp_department_dropDown"
                                        onClick={this.showPopover}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            textTransform: "none",
                                        }}
                                        className={
                                            classes.root + " inp_TextBox"
                                        }
                                        onBlur={() => {
                                            if (
                                                this.state.departmentName ===
                                                    "" &&
                                                !this.state.showPopover
                                            ) {
                                                this.setState({
                                                    err_dept:
                                                        "Please select role/industry",
                                                });
                                            } else {
                                                this.setState({ err_dept: "" });
                                            }
                                        }}
                                    >
                                        {this.state.departmentName === ""
                                            ? "Pick Your Role/Industry"
                                            : this.state.departmentName}
                                        <Icon
                                            className={
                                                "fas fa-angle-down dropdownIcon"
                                            }
                                        ></Icon>
                                    </Button>
                                </div>
                                <div className={"row m-0 w-100"}>
                                    {this.state.err_dept !== "" ? (
                                        <span
                                            className="err_msg"
                                            id="err_inp_signUp_department_dropDown"
                                        >
                                            {this.state.err_dept}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 w-100 doCenter">
                            <OutlineTextBoxView
                                id="inp_signUp_useremail"
                                placeholder="Email Address"
                                type="text"
                                onChangeInput={this.setFromText}
                                onBlurInput={this.ValidateEmail}
                                errorMsg={this.state.err_mail}
                            ></OutlineTextBoxView>
                        </div>
                        <div className="row m-0 w-100 doCenter inp_TextBoxRow">
                            <PhoneInput
                                id="inp_signUp_phoneNumber"
                                defaultCountry="US"
                                className="inp_TextBox"
                                placeholder="Enter Phone Number"
                                value={this.state.phoneNumber}
                                onChange={(value) => {
                                    this.setState({ phoneNumber: value });
                                }}
                            ></PhoneInput>
                            {this.state.phoneNumber &&
                                !isValidPhoneNumber(this.state.phoneNumber) && (
                                    <div className={"row m-0 w-100"}>
                                        <span
                                            className="err_msg"
                                            id="err_inp_signUp_department_dropDown"
                                        >
                                            {
                                                "Please, enter valid phone number!"
                                            }
                                        </span>
                                    </div>
                                )}
                        </div>
                        <div className="row m-0 w-100 doCenter mt-5">
                            <OutlineTextBoxView
                                id="inp_signUp_password"
                                placeholder="Password"
                                type="password"
                                title="Try with 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
                                onChangeInput={this.setFromText}
                                onBlurInput={this.validatePasswordPatern}
                                errorMsg={this.state.err_password}
                                pwdStrength={this.state.pwdStrength}
                            ></OutlineTextBoxView>
                        </div>
                        <div className="row m-0 w-100 doCenter">
                            <OutlineTextBoxView
                                id="inp_signUp_confirmpassword"
                                placeholder="Re-enter Password"
                                type="password"
                                onChangeInput={this.setFromText}
                                onBlurInput={this.validatePassword}
                                errorMsg={this.state.err_confPassword}
                            ></OutlineTextBoxView>
                        </div>
                        {/* <div className="row m-0 w-100 doCenter">
                            <div class="form-group form-check">
                                <input
                                    onClick={() =>
                                        this.setState({
                                            checked: !this.state.checked,
                                        })
                                    }
                                    checked={this.state.checked}
                                    style={{
                                        defaultChecked: this.state.checked,
                                    }}
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                />
                            </div>
                            <p
                                style={{
                                    paddingLeft: "10px",
                                    marginTop: "20px",
                                }}
                            >
                                I accept the
                                <a href="https://www.boardwalktech.com/terms-of-use">
                                    &nbsp; Terms and Conditions
                                </a>
                                &nbsp; and the
                                <a href="https://www.boardwalktech.com/privacy-policy">
                                    &nbsp; Privacy Policy
                                </a>
                                .
                            </p>
                        </div> */}

                        <div className="row m-0 w-100 doCenter mt-3">
                            <button
                                type="submit"
                                id="btn_signUp_submit"
                                className="btn btn-primary submitBtn"
                                style={{ fontWeight: "400" }}
                                name="btn_signup"
                                onClick={this.handleSubmitBtn}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                    <div className="col"></div>
                    {this.renderAlertModal()}
                    <Loader show={this.state.showLoader} />
                </div>
            );
        }
    }
}

export default withStyles(styles)(SignUp);
