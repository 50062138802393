import React from "react";
import ModalView from "../../../../../../../stylized/ModalView";
import TextBox from "../../../../../../../stylized/TextBox";

export default function NewFolderModal({
    showFolderModal,
    closeModal,
    setText,
    newFolderName,
    addNewFolder,
}) {
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showFolderModal}
            size="md"
            onHide={closeModal}
            title=""
            onclick={closeModal}
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <img src="./images/Space 2.svg"></img>
                <TextBox
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onchange={setText}
                    value={newFolderName}
                    label="Enter Folder Name..."
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
                <input
                    type="button"
                    className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                    style={{
                        height: "40px",
                        marginTop: "45px",
                        marginLeft: "10px",
                    }}
                    disabled={newFolderName === "" ? true : false}
                    value="SUBMIT"
                    onClick={addNewFolder}
                ></input>
            </div>
        </ModalView>
    );
}
