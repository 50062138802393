let submitBufferStub = 
{
    "info": {
        "gridId": 2000703,
        "gridName": "DLA Workflow",
        "view": "LATEST",
        "importTxId": 12202,
        "exportTxId": 12202,
        "filter": "na",
        "asOfTxId": 12202,
        "baselineId": -1,
        "serverName": "na",
        "serverURL": "na",
        "collabId": 1119,
        "wbId": 1298,
        "memberId": 1018,
        "userId": 1019,
        "nhId": 40,
        "rowCount": 18,
        "colCount": 5,
        "maxTxId": 12202,
        "mode": 0,
        "criteriaTableId": -1,
        "neighborhoodHeirarchy": {
            "levels": 3,
            "nhLevel0": 37,
            "nhLevel1": 38,
            "nhLevel2": 39,
            "nhLevel3": 40
        },
        "creationTxId": -1,
        "peerAccess": 1,
        "privateAccess": 1,
        "friendAccess": 1,
        "isActive": true,
        "sequenceNumber": 1.0,
        "isLocked": true,
        "lockTxId": -1
    },
    "rowArray": [],
    "columnArray": [],
    "rowChainArray": [],
    "columnChainArray": [],
    "columnCellArrays": [],
    "gridChangeBuffer": {
        "critical": -1,
        "criticalLevel": -1,
        "newRowArray": [],
        "deletedRowArray": [],
        "newColumnArray": [],
        "deletedColumnArray": [],
        "cellChanges": [
            {
                "access": 2,
                "cellFormula": "na",
                "cellId": -1,
                "cellValue": "Customer abc",
                "changeFlag": 1,
                "columnId": 4078,
                "columnSequenceNumber": 1,
                "isActive": true,
                "rowId": 18565,
                "rowSequenceNumber": 1,
                "txId": 10176
            }
        ]
    }
}
export default submitBufferStub