import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));
export default function ChipsView(props) {
    const classes = useStyles();
    const items = props.items;
    const handleDelete = (chipToDelete, index) => () => {
        if (props.ondelete !== undefined) props.ondelete(chipToDelete, index);
    };
    const handleClick = (label) => {
        if (props.onclick !== undefined) props.onclick(label);
    };
    return (
        <ul
            className={"chipList"}
            style={
                props.heading && items.length
                    ? {
                          padding: "2px 5px",
                      }
                    : null
            }
        >
            {items.map((data, index) => {
                return (
                    <li key={data.key}>
                        <Chip
                            label={data.label}
                            title={data.label}
                            onClick={(event) => handleClick(data.label)}
                            onDelete={handleDelete(data, index)}
                            className={"chip"}
                            deleteIcon={<CloseIcon className="chipIcon" />}
                        />
                    </li>
                );
            })}
        </ul>
    );
}
