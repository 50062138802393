import React, { Component } from "react";

export default class IntegrationItem extends Component {
    renderClassname() {
        if (this.props.index === this.props.activeIndex)
            return "heighlightCard ";
        else return "";
    }
    renderTitle = (text, className) => {
        if (this.props.headerTitle !== undefined) {
            return (
                <div className="col doCenter integration_card_title p-0">
                    <span className={"textEllipsis " + className}>
                        {this.props.headerTitle}
                    </span>
                </div>
            );
        } else {
            return <div className="col p-0"></div>;
        }
    };

    renderColums = () => {
        let items = [];
        // items.push(this.renderLeftIcon());
        items.push(this.renderTitle());
        // items.push(this.renderRightIcon());
        return items;
    };
    handleClickEvent = (headerTitle) => {
        if (this.props.onclick !== undefined) {
            this.props.onclick(headerTitle);
        }
    }
    render() {
        return (
            <div
                className={
                    " card " +
                    this.props.cardClass +
                    " " +
                    this.renderClassname()
                }
                id={"card_" + this.props.id}
            //onClick={this.handleClickEvent}
            >
                <div className={"card-header " + this.props.cardHeaderClass}>
                    <div className="row w-100 m-0">{this.renderColums()}</div>
                </div>
                <div
                    className={"card-body " + this.props.cardBodyClass}
                    id={this.props.id}
                    onClick={() => this.handleClickEvent(this.props.headerTitle)}
                >
                    {this.props.bodyData}
                </div>
            </div>
        );
    }
}
