import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListView from "../../../../../../../stylized/ListView";
import PopoverViewCustom from "../../../../../../../stylized/PopoverViewCustom";
import "./style.css";
function SelectGenericDropdown({
    id,
    modelListData,
    handleModalSelect,
    selectedModalIndexesState,
    isCompareView,
}) {
    const [selectedType, setSelectedType] = useState("");
    const [modelList, setmodelList] = useState([]);
    const btnLabel = isCompareView ? "Select Generic" : "Select Flow";
    const selectedIndex = useSelector(
        (state) => state.selectedDropdownModelIndex
    );
    useEffect(() => {
        if (!selectedModalIndexesState) setSelectedType("");
    }, [selectedModalIndexesState]);
    useEffect(() => {
        if (selectedIndex !== null)
            setSelectedType(modelListData[selectedIndex]);
    }, [selectedIndex]);

    useEffect(() => {
        setmodelList(modelListData);
        if (selectedIndex !== null)
            setSelectedType(modelListData[selectedIndex]);
    }, [modelListData]);

    const handleListItemClick = (i, text) => {
        handleModalSelect(text, i);
        setSelectedType(text);
    };
    const renderMenuItems = () => {
        let items = [];
        let rawDataArr = modelList;
        rawDataArr.forEach((item, index) => {
            items.push({
                value: item,
                text: item,
                id: item + "_" + index,
            });
        });
        return (
            <ListView
                className="noBorder dropdown_dashboard "
                listItemClassname=""
                id={"selectGenericNameList"}
                label={"list_dateFilter"}
                items={items}
                showIcon="false"
                disableActiveIndex="true"
                onchange={handleListItemClick}
            />
        );
    };

    return (
        <div
            className={
                selectedType !== ""
                    ? "dropdown_Btn dropdown_Btn_active "
                    : "dropdown_Btn"
            }
        >
            <PopoverViewCustom
                className={"rippleBtnFont"}
                btnLabel={selectedType === "" ? btnLabel : selectedType}
                iconClass="fas fa-angle-down"
                id={id}
                isDropdown={true}
            >
                {renderMenuItems()}
            </PopoverViewCustom>
        </div>
    );
}
export default SelectGenericDropdown;
