import React, { useState } from "react";
import Container from "@mui/material/Container";
import "./style.css";
import { Button } from "react-bootstrap";
import ModalView from "../../../../stylized/ModalView";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import NoWorkspacePage from "./NoWorkspacePage";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        logo: "./images/workspace-logo.png",
        label: "Workspace",
        descp: "A Workspace is a centralized area for all your spaces, documents, and folders",
        imgPath: "./images/w1.svg",
    },
    {
        logo: "./images/spaceIcon.svg",
        label: "Space",
        descp: "A Space is an area within your Workspace where you can upload files and create folders. Currently supported file types include: PDF, MS Word, .txt, and .xml.",
        imgPath: "./images/sc1.svg",
    },
    {
        logo: "./images/Ripple.svg",
        label: "Ripple",
        descp: "Once you upload files into Unity Central, you can use the Ripple search engine to query your information across all Workspaces, Spaces, Folders, and Files.",
        imgPath: "./images/sc2.svg",
    },
    {
        logo: "./images/Vector.png",
        label: "Chat",
        descp: "The chat allows you to chat and share Spaces, Folders, Files, and Ripples with team members.",
        imgPath: "./images/sc3.svg",
    },
    {
        logo: "./images/flow-icon-bw.png",
        label: "Flow",
        descp: "Flow is a feature within Unity Central that enables you to track, analyze and gain visibility into documents as they move through a process.",
        imgPath: "./images/sc4.svg",
    },
    {
        logo: "./images/Group 451.png",
        label: "Thanks for taking the tour!",
        descp: "If you need further assitance, contact our support via email support@boardwalktech.com ",
        descp1: "You can also provide feedback, contact support, or learn more in the knowledge center by clicking the question mark icon at the bottom of the sidebar menue",
    },
];

function SwipeableTextMobileStepper({
    showModal,
    onHide,
    onClose,
    openCreateWorkspaceModal,
    showView,
    closeStepper,
}) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [view, setView] = React.useState("");
    const [openWorkspace, setOpenWorkspace] = React.useState(false);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleClick = (event) => {
        if (activeStep === maxSteps - 1) {
            closeStepper();
            onClose();
            onHide();
        } else {
            handleNext();
        }
    };

    const handleBack = (event) => {
        if (activeStep === maxSteps - 1 && event.target.innerText !== "Back") {
            setActiveStep(0);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleOnHideModal = () => {
        closeStepper(false);
        onHide();
    };
    const handleOnCLoseModal = () => {
        closeStepper(false);
        onClose();
    };

    return (
        <React.Fragment>
            <ModalView
                show={showModal}
                onHide={handleOnHideModal}
                footer={"false"}
                onClose={handleOnCLoseModal}
            >
                <div
                    className="makeStyles-root-11"
                    style={
                        activeStep === maxSteps - 1
                            ? {
                                  backgroundColor: "#edf8ff",
                                  paddingBottom: "32px",
                                  marginTop: "-32px",
                              }
                            : { paddingBottom: "32px", marginTop: "-32px" }
                    }
                >
                    <Container
                        maxWidth="lg"
                        style={
                            activeStep === maxSteps - 1
                                ? {
                                      backgroundColor: "#edf8ff",
                                  }
                                : {}
                        }
                    >
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                            <Paper
                                style={
                                    activeStep === maxSteps - 1
                                        ? { backgroundColor: "#edf8ff" }
                                        : {}
                                }
                                square
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 50,
                                    pl: 2,
                                    bgcolor: "background.default",
                                }}
                            >
                                <div
                                    style={
                                        activeStep === maxSteps - 1
                                            ? {
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  flexDirection: "column",
                                                  width: "100%",
                                                  alignItems: "center",
                                                  marginTop: "100px",
                                              }
                                            : {
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                              }
                                    }
                                >
                                    <img
                                        src={images[activeStep].logo}
                                        alt=""
                                        style={
                                            activeStep === maxSteps - 1
                                                ? {
                                                      width: "50px",
                                                      paddingRight: "10px",
                                                  }
                                                : { paddingRight: "10px" }
                                        }
                                    />
                                    <Typography style={{ fontSize: "28px" }}>
                                        {images[activeStep].label}
                                    </Typography>
                                </div>
                            </Paper>
                            <Paper
                                style={
                                    activeStep === maxSteps - 1
                                        ? {
                                              backgroundColor: "#edf8ff",
                                              marginBottom: "100px",
                                          }
                                        : {}
                                }
                                square
                                elevation={0}
                                sx={
                                    images[activeStep].descp1
                                        ? {
                                              alignItems: "center",
                                              height: 50,
                                              pl: 2,
                                              bgcolor: "background.default",
                                          }
                                        : {
                                              display: "flex",
                                              alignItems: "center",
                                              height: 50,
                                              pl: 2,
                                              bgcolor: "background.default",
                                          }
                                }
                            >
                                <Typography
                                    style={
                                        activeStep === maxSteps - 1
                                            ? {
                                                  backgroundColor: "#edf8ff",
                                                  marginTop: "150px",
                                                  textAlign: "center",
                                                  fontSize: "20px",
                                                  fontWeight: "700",
                                              }
                                            : { margin: "30px 0px" }
                                    }
                                >
                                    {images[activeStep].descp}
                                </Typography>
                                {images[activeStep].descp1 ? (
                                    <Typography
                                        style={
                                            activeStep === maxSteps - 1
                                                ? {
                                                      backgroundColor:
                                                          "#edf8ff",

                                                      textAlign: "center",
                                                      fontSize: "20px",
                                                      fontWeight: "700",
                                                  }
                                                : { margin: "30px 0px" }
                                        }
                                    >
                                        {images[activeStep].descp1}
                                    </Typography>
                                ) : null}
                            </Paper>

                            <AutoPlaySwipeableViews index={activeStep}>
                                {images.map((step, index) => (
                                    <div key={step.label}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            step.imgPath ? (
                                                <Box
                                                    style={
                                                        activeStep ===
                                                        maxSteps - 1
                                                            ? {
                                                                  marginTop:
                                                                      "-10px",
                                                                  height: "150px",
                                                                  marginBottom:
                                                                      "32px",
                                                              }
                                                            : {}
                                                    }
                                                    component="img"
                                                    sx={{
                                                        height: "255px",
                                                        display: "block",
                                                        maxWidth: 400,
                                                        overflow: "hidden",
                                                        width: "100%",
                                                    }}
                                                    src={step.imgPath}
                                                    alt={step.label}
                                                />
                                            ) : null
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>

                            <MobileStepper
                                style={
                                    activeStep === maxSteps - 1
                                        ? {
                                              backgroundColor: "#edf8ff",
                                          }
                                        : {}
                                }
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <>
                                        <label
                                            style={
                                                activeStep === maxSteps - 1
                                                    ? {
                                                          marginLeft: "200px",
                                                          marginTop: "12px",
                                                      }
                                                    : {}
                                            }
                                            size="small"
                                            onClick={handleBack}
                                            disabled={activeStep === 0}
                                        >
                                            {activeStep === maxSteps - 1
                                                ? "Back"
                                                : ""}
                                        </label>
                                        <Button
                                            size="small"
                                            onChange={(event) => {
                                                if (
                                                    event.target.innerText ===
                                                    "Close"
                                                ) {
                                                    setView("Close");
                                                }
                                            }}
                                            onClick={handleClick}
                                            id="myBtn"
                                        >
                                            {activeStep === maxSteps - 1
                                                ? "Close"
                                                : "Next"}
                                            {theme.direction === "rtl" ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button>
                                    </>
                                }
                                backButton={
                                    activeStep === maxSteps - 1 ? (
                                        <label
                                            title="restart"
                                            style={{ cursor: "pointer" }}
                                            size="small"
                                            onClick={handleBack}
                                            disabled={activeStep === 0}
                                        >
                                            Restart Tour
                                        </label>
                                    ) : (
                                        <Button
                                            size="small"
                                            onClick={handleBack}
                                            disabled={activeStep === 0}
                                        >
                                            {theme.direction === "rtl" ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                            Back
                                        </Button>
                                    )
                                }
                            />
                        </Box>
                    </Container>
                </div>
            </ModalView>
            <NoWorkspacePage
                showModal={openWorkspace}
                openCreateWorkspaceModal={openCreateWorkspaceModal}
                onHide={onHide}
                showView={showView}
                onClose={onClose}
            />
        </React.Fragment>
    );
}
export default SwipeableTextMobileStepper;
