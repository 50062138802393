import React, { Component } from "react";
import "./informationFeed.css";
import { getIconPathByType } from "../functional/common/IconList";
class InfoFeedItem extends Component {
    renderAttachmentIcon = () => {
        if (this.props.attachment === true) {
            return <img src="./images/Attchment.png"></img>;
        } else {
            return null;
        }
    };
    renderHeader = () => {
        return (
            <div className="row m-0 w-100 h-100">
                <div className="col-10 p-0 feedLeftTitle">
                    <span className="feedTitle1 fontFamily">
                        {this.props.title}
                    </span>
                </div>
                <div className="col-2 p-0 doCenter">
                    <img src="./images/alertMenu.png"></img>
                </div>
            </div>
        );
    };
    renderBody = () => {
        return this.props.contentText;
    };
    render() {
        let imgSource = getIconPathByType(this.props.type.toLowerCase());
        return (
            <div className="feedCard">
                <div className="row m-0 w-100 pt-4">
                    <div className="col-2 doCenter">
                        <img className="leftIcon" src={imgSource}></img>
                    </div>
                    <div className="col-10 p-0 pr-2">{this.renderHeader()}</div>
                </div>
                {this.renderBody()}
            </div>
        );
    }
}

export default InfoFeedItem;
