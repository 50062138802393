import { default as React, useState, useEffect } from "react";
import CardView from "../../../../../stylized/CardView";
import Column from "../../../../../stylized/Column";
import InlineRow from "../../../../../stylized/InlineRow";
import SliderView from "../../../../../stylized/SliderView";
import IntegrationItem from "../IntegrationItem";
import AlertDialog from "../../../../../stylized/AlertDialog";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import Loader from "../../../../../stylized/Loader";
import http from "../../../../../../bae-client/HTTP";
import { setBwModulesOfLeftPane } from "../../../../../../actions";
import { useDispatch } from "react-redux";

export default function RightPanel() {
    const [selectedModuleName, setSelectedModuleName] = useState("");
    const [rightPanelData, setRightPanelData] = useState([]);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const displayCount = 3;
    const handleBodyClick = (moduleName) => {
        setSelectedModuleName(moduleName);
        setAlertDialogShow(true);
        let message = "Do you want to add " + moduleName + " to Unity Central?";
        setAlertDialogContent({
            title: "Unity Central",
            message: message,
            isAlert: true,
            buttonName: "ADD",
        });
    };
    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            let moduleData = rightPanelData.filter((module) => {
                return module.moduleName === selectedModuleName;
            });
            moduleData[0].status = "ON";
            activateModuleInLeftMenu(moduleData[0]);
        }
    };

    const activateModuleInLeftMenu = (moduleData) => {
        setShowLoader(true);
        let url = "modules/updateStatus";
        http.sendRestRequest(url, "PUT", moduleData)
            .then((response) => {
                setRightPanelData([]);
                dispatch(setBwModulesOfLeftPane(true));
                setShowLoader(false);
                setShowSnackbar(true);
                let message = selectedModuleName + " is added to Unity Central";
                setSnackbarMessage(message);
            })
            .catch((err) => {
                setAlertDialogContent({
                    title: "Operation Failed",
                    message: err.error,
                    isAlert: true,
                    buttonName: "Ok",
                });
                setShowLoader(false);
            });
    };

    useEffect(() => {
        if (!rightPanelData.length) {
            setShowLoader(true);
            let url = `modules/list`;
            http.sendRestRequest(url, "GET")
                .then((data) => {
                    let inActiveModules = data.filter((module) => {
                        return module.status === "OFF";
                    });
                    if (inActiveModules.length)
                        setRightPanelData(inActiveModules);
                    setShowLoader(false);
                })
                .catch((err) => {
                    setAlertDialogContent({
                        title: "Operation Failed",
                        message: err.error,
                        isAlert: true,
                        buttonName: "Ok",
                    });
                    setShowLoader(false);
                });
        }
    }, [rightPanelData]);
    const computeSpaceCards = () => {
        // this.setState({ showLoader: true });
        let AccordianList = [];
        // this.undefinedSpcaeCounter = 0;
        let totalAlertCount = 6;
        if (
            rightPanelData !== undefined &&
            rightPanelData !== null &&
            rightPanelData.length > 0
        ) {
            let bwModulesList = rightPanelData;
            let cardLenght = bwModulesList.length % totalAlertCount;
            let extraCards = totalAlertCount - cardLenght;
            for (let i = 0; i < bwModulesList.length; i++) {
                if (bwModulesList[i].status === "OFF") {
                    let imageSrc =
                        "./images/Integration/BW_Modules/" +
                        bwModulesList[i].moduleName +
                        ".png";
                    const bodyImg = (
                        <div>
                            <img
                                className="integration_card_body_img"
                                src={imageSrc}
                            ></img>
                        </div>
                    );
                    AccordianList.push(
                        <IntegrationItem
                            id={i}
                            index={i}
                            // activeIndex={selectedSpaceId}
                            onclick={handleBodyClick}
                            cardClass="spaceCard"
                            cardHeaderClass="spaceCard-header"
                            cardBodyClass="spaceCard-body"
                            headerTitle={bwModulesList[i].moduleName}
                            bodyData={bodyImg}
                        ></IntegrationItem>
                    );
                }
            }
            let index = 0;
            while (index < extraCards) {
                AccordianList.push(
                    <IntegrationItem
                        index={bwModulesList.length + index}
                        activeIndex={selectedModuleName}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></IntegrationItem>
                );
                index++;
            }
            return AccordianList;
        } else {
            let index = 0;
            while (index < 6) {
                AccordianList.push(
                    <IntegrationItem
                        index={index}
                        activeIndex={-1}
                        cardClass="integration_card"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></IntegrationItem>
                );
                index++;
            }
            return AccordianList;
        }
    };
    const renderRowsForSlider = () => {
        let rowItems = [];
        let temp = [];
        let cardLists = computeSpaceCards();
        // let ColSize = 12 / displayCount;
        let ColSize = 4;
        if (cardLists.length > 0) {
            for (let index = 0; index < cardLists.length; index++) {
                temp.push(
                    <Column className="doCenter p-0" size={ColSize}>
                        {cardLists[index]}
                    </Column>
                );
                if (temp.length === displayCount) {
                    rowItems.push(
                        <InlineRow classname="ht-50 pb-2 integration_card_row">
                            {temp}
                        </InlineRow>
                    );
                    temp = [];
                }
            }
            return rowItems;
        }
    };
    return (
        <>
            <div className="h-100">
                <div className="row m-0 w-100 doCenter">
                    <span className="integration_Title">Boardwalk Modules</span>
                </div>
                <div className="integrationContainer_body">
                    <SliderView
                        rawData={renderRowsForSlider()}
                        cardCountPerSlide={2}
                    ></SliderView>
                </div>
            </div>
            <AlertDialog
                // open={showAlertDialog}
                // message={alertDialogMessage}
                // title={""}
                // isAlert={false}
                // setOpen={changeOpenDialogFlag}
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <Loader show={showLoader}></Loader>
        </>
    );
}
