import React, { useRef, useState } from "react";
import http from "../../../../../../bae-client/HTTP";
import AlertDialog from "../../../../../stylized/AlertDialog";
import Column from "../../../../../stylized/Column";
import DialogView from "../../../../../stylized/DialogView";
import InlineRow from "../../../../../stylized/InlineRow";
import Loader from "../../../../../stylized/Loader";
import SliderView from "../../../../../stylized/SliderView";
import FormWrapper from "../FormWrapper/FormWrapper";
import IntegrationItem from "../IntegrationItem";
import OutlookModal from "./OutlookModal/index";
function LeftPanel() {
    const [selectedSpaceId, setSelectedSpaceId] = useState(0);
    const [outlookModalShow, setOutlookModalShow] = useState(false);
    const [gmailModalShow, setgmailModalShow] = useState(false);
    const [showStepperForm, setShowStepperForm] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
    });
    const [userInfo, setUserInfo] = useState({
        username: "",
        password: "",
    });
    const [gamilRedirectURL, setGamilRedirectURL] = useState("#");
    const [outlookRedirectURL, setOutlookRedirectURL] = useState("#");
    const gmailApi = useRef(null);
    const outlookApi = useRef(null);
    const LeftPanelData = [
        {
            title: "Outlook",
            bodyImgSrc: "./images/outlook-icon.svg",
        },
        // {
        //     title: "Gmail",
        //     bodyImgSrc: "./images/Gmail_icon.png",
        // },
    ];
    const handleCloseOutlook = () => {
        setOutlookModalShow(false);
    };
    const hideGmailModal = () => {
        setgmailModalShow(false);
    };
    const handleCloseStepperForm = () => {
        setShowStepperForm(false);
    };
    const handleFormShow = () => {
        setOutlookModalShow(false);
        setShowStepperForm(true);
    };

    const showAlertView = (title, body) => {
        setAlertDialogContent({ title: title, message: body });
        setAlertDialogShow(true);
    };
    const handleGmailAuthentication = (type) => {
        let url = `gmailIntegration/authentication`;
        http.sendRestRequest(url, "POST")
            .then((data) => {
                setShowLoader(false);
                if (data.redirectUrl !== undefined) {
                    setGamilRedirectURL(data.redirectUrl);
                    gmailApi.current.click();
                } else {
                    // setShowStepperForm(true);
                    showAlertView("", "Authorization successful");
                }
            })
            .catch((data) => {
                setShowLoader(false);
                if (data.error === "User Doesn't exist") {
                    setOutlookModalShow(true);
                } else if (data.error === "Unsuccessful connection") {
                    setOutlookModalShow(false);
                    showAlertView("", "Invalid Credentials");
                }
            });
    };
    const handleAuthentication = (type) => {
        let url = `outlookIntegration/authentication`;
        http.sendRestRequest(url, "POST")
            .then((data) => {
                setShowLoader(false);
                if (data.redirectUrl !== undefined) {
                    setOutlookRedirectURL(data.redirectUrl);
                    outlookApi.current.click();
                } else {
                    // setShowStepperForm(true);
                    showAlertView("", "Authorization successful");
                }
            })
            .catch((data) => {
                setShowLoader(false);
                if (data.error === "User Doesn't exist") {
                    setOutlookModalShow(true);
                } else if (data.error === "Unsuccessful connection") {
                    setOutlookModalShow(false);
                    showAlertView("", "Invalid Credentials");
                }
            });
    };
    const handleClick = (headerTitle) => {
        setHeaderTitle(headerTitle);
        if (headerTitle === "Outlook") {
            setShowLoader(true);
            handleAuthentication(headerTitle);
        } else if (headerTitle === "Gmail") {
            setShowLoader(true);
            handleGmailAuthentication(headerTitle);
        }
    };

    const displayCount = 3;
    const changeActiveIndex = () => {};
    const computeSpaceCards = () => {
        // this.setState({ showLoader: true });
        let AccordianList = [];
        // this.undefinedSpcaeCounter = 0;
        let totalAlertCount = 6;
        if (
            LeftPanelData !== undefined &&
            LeftPanelData !== null &&
            LeftPanelData.length > 0
        ) {
            let spaceList = LeftPanelData;
            let cardLenght = spaceList.length % totalAlertCount;
            let extraCards = totalAlertCount - cardLenght;
            for (let i = 0; i < spaceList.length; i++) {
                const bodyImg = (
                    <div>
                        <img
                            className="integration_card_body_img"
                            src={spaceList[i].bodyImgSrc}
                        ></img>
                    </div>
                );
                AccordianList.push(
                    <IntegrationItem
                        id={i}
                        index={i}
                        // activeIndex={selectedSpaceId}
                        onbodyclick={changeActiveIndex}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerTitle={spaceList[i].title}
                        bodyData={bodyImg}
                        onclick={handleClick}
                    ></IntegrationItem>
                );
            }
            let index = 0;
            while (index < extraCards) {
                AccordianList.push(
                    <IntegrationItem
                        index={spaceList.length + index}
                        activeIndex={selectedSpaceId}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></IntegrationItem>
                );
                index++;
            }
            return AccordianList;
        } else {
            let index = 0;
            while (index < 6) {
                AccordianList.push(
                    <IntegrationItem
                        index={index}
                        activeIndex={-1}
                        cardClass="integration_card"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></IntegrationItem>
                );
                index++;
            }
            return AccordianList;
        }
    };
    const renderRowsForSlider = () => {
        let rowItems = [];
        let temp = [];
        let cardLists = computeSpaceCards();
        // let ColSize = 12 / displayCount;
        let ColSize = 4;
        if (cardLists.length > 0) {
            for (let index = 0; index < cardLists.length; index++) {
                temp.push(
                    <Column className="doCenter p-0" size={ColSize}>
                        {cardLists[index]}
                    </Column>
                );
                if (temp.length === displayCount) {
                    rowItems.push(
                        <InlineRow classname="ht-50 pb-2 integration_card_row">
                            {temp}
                        </InlineRow>
                    );
                    temp = [];
                }
            }
            return rowItems;
        }
    };
    const handleText = (value, id) => {
        setUserInfo({ ...userInfo, [id]: value });
    };
    const handleAutherizationDetail = () => {
        setShowLoader(true);
        if (userInfo.username !== "" && userInfo.password !== "") {
            let url = "mailIntegration/detail";
            let username = userInfo.username.trim();
            let password = userInfo.password.trim();
            let accountType = headerTitle.toLowerCase();
            let data = {
                username: username,
                password: password,
                accountType: accountType,
            };
            http.sendRestRequest(url, "POST", data)
                .then((data) => {
                    setShowLoader(false);
                    if (data === 200) {
                        setOutlookModalShow(false);
                        // setShowStepperForm(true);
                        <DialogView
                            show={showStepperForm}
                            size="lg"
                            handleClose={handleCloseStepperForm}
                            showTitle={false}
                            showFooter={false}
                            onBackdropClick={false}
                        >
                            <span>Authorization done success</span>
                        </DialogView>;
                    }
                })
                .catch((data) => {
                    setShowLoader(false);
                    if (data.error === "Unsuccessful connection") {
                        setOutlookModalShow(false);
                        showAlertView("", "Invalid Credentials");
                        setOutlookModalShow(true);
                    }
                });
        } else if (userInfo.username === "" && userInfo.password === "") {
            alert("Please enter username and password");
            setShowLoader(false);
        }
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
            ></AlertDialog>
        );
    };
    return (
        <div className="h-100">
            <div className="row m-0 w-100 doCenter">
                <span className="integration_Title">Integrations </span>
            </div>
            <div className="integrationContainer_body">
                <SliderView
                    rawData={renderRowsForSlider()}
                    cardCountPerSlide={2}
                ></SliderView>
            </div>
            <OutlookModal
                outlookModalShow={outlookModalShow}
                closeOutlookModal={handleCloseOutlook}
                handleFormShow={handleAutherizationDetail}
                setText={handleText}
                modalType={headerTitle}
                //addNewSpace={addNewSpace}
                handleAutherization={handleAutherizationDetail}
            ></OutlookModal>
            {showStepperForm ? (
                <DialogView
                    show={showStepperForm}
                    size="lg"
                    handleClose={handleCloseStepperForm}
                    showTitle={false}
                    showFooter={false}
                    onBackdropClick={false}
                >
                    <FormWrapper
                        onHide={handleCloseStepperForm}
                        accountType={headerTitle}
                        hideFile={"true"}
                        saveButtonName={"NEXT"}
                    ></FormWrapper>
                </DialogView>
            ) : null}
            <Loader show={showLoader} />
            {renderAlertModal()}
            {/* anchor tag for redirect to new gmail tab  */}
            <a
                href={gamilRedirectURL}
                target="_blank"
                rel="noopener noreferrer"
                ref={gmailApi}
                style={{ display: "none" }}
            ></a>
            <a
                href={outlookRedirectURL}
                target="_blank"
                rel="noopener noreferrer"
                ref={outlookApi}
                style={{ display: "none" }}
            ></a>
        </div>
    );
}
export default LeftPanel;
