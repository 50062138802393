import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    _HOME_ALERT_ENDPOINT,
    _HOME_ELEMENTS_COUNT_ENDPOINT,
    _HOME_RECENT_ACTIVITY_ENDPOINT,
    _HOME_RESOURCES_ENDPOINT,
    _REST_APP,
    _URL,
    _USER_AGREEMENT_ENDPOINT,
} from "../../constants";
import http from "../../../bae-client/HTTP";

export const homeResourcesAPI = createApi({
    reducerPath: "homeResources",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHomeResources: builder.query({
            query: () => _HOME_RESOURCES_ENDPOINT,
        }),
    }),
});

export const homeAlertAPI = createApi({
    reducerPath: "homeAlerts",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHomeAlerts: builder.query({
            query: () => _HOME_ALERT_ENDPOINT,
        }),
    }),
});

export const homeRecentActivity = createApi({
    reducerPath: "homeRecentActivity",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHomeRecentActivity: builder.query({
            query: () => _HOME_RECENT_ACTIVITY_ENDPOINT,
        }),
    }),
});

export const homeElementCountAPI = createApi({
    reducerPath: "homeElementCount",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHomeAlerts: builder.query({
            query: () => _HOME_ELEMENTS_COUNT_ENDPOINT,
        }),
    }),
});

export const userAgreement = createApi({
    reducerPath: "userAgreement",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUserAgreement: builder.query({
            query: () => `${_USER_AGREEMENT_ENDPOINT}?agreementStatus=PENDING`,
        }),
    }),
});

export const { useHomeResourcesQuery } = homeResourcesAPI;
export const { useHomeAlertsQuery } = homeAlertAPI;
export const { useHomeRecentActivityQuery } = homeRecentActivity;
export const { useHomeElementCountQuery } = homeElementCountAPI;
export const { useUserAgreementQuery } = userAgreement;
