import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ControlTowerInstanceInfoTab from "./ControlTowerInstanceInfoTab";
import ControlTowerPopUpNew from "./ControlTowerPopUpNew";
import AlertDialog from "../../stylized/AlertDialog";
import ControlTower from "../../../bae-client/ControlTower";
import Loader from "../../stylized/Loader";
const controlTower = new ControlTower();
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return [
        "Update instance details",
        "Update instance information",
        "Confirm",
    ];
}

export default function WorkflowStepper({ onHide, documentMetaData }) {
    const [apiStatus, setApiStatus] = useState(null);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [updateInstanceData, setUpdateInstanceData] = useState({});
    const [updateInstanceInfoData, setUpdateInstanceInfoData] = useState({});
    const handleInstanceData = (state) => {
        setUpdateInstanceData(state);
    };
    const handleInstanceInfoData = (state) => {
        setUpdateInstanceInfoData(state);
    };
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const updateState = (state) => {
        setUpdateInstanceData(state);
    };
    const getConfirmation = () => {
        return (
            <div className="marginTopTen doCenter">
                {"Are you sure to submit the data?"}
            </div>
        );
    };
    const createNewInstance = (instanceBlockchainTblId, data) => {
        let method = "POST";
        setShowLoader(true)
        controlTower
            .addOrUpdateNewInstance(instanceBlockchainTblId, method, data)
            .then((response) => {
                setShowLoader(false)
                if (response.instanceBlockchainId !== 0) {
                    setApiStatus("success");
                } else if (response.instanceBlockchainId === 0) {
                    setApiStatus("failure");
                    return;
                }
            })
            .catch((error) => {
                console.error(error);
                setShowAlertDialog(true);
                setShowLoader(false)
                setAlertDialogMessage(error.error);
            });
    };
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <ControlTowerPopUpNew
                        onHide={onHide}
                        documentMetaData={documentMetaData}
                        updateParentState={updateState}
                        handleInstanceData={handleInstanceData}
                        updateInstanceData={updateInstanceData}
                    ></ControlTowerPopUpNew>
                );
            case 1:
                return (
                    <ControlTowerInstanceInfoTab
                        handleInstanceInfoData={handleInstanceInfoData}
                        updateInstanceInfoData={updateInstanceInfoData}
                    ></ControlTowerInstanceInfoTab>
                );
            case 2:
                return getConfirmation();
            default:
                return "Unknown step";
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === 2) {
            const payloadObj = {};
            if (updateInstanceData.instanceCheckStatus) {
                payloadObj["genericStepId"] =
                    updateInstanceData.selectedGenericStep.genericStepId;
                payloadObj["instanceName"] = updateInstanceData.newInstanceName;
                payloadObj["parentInstanceBlockchainId"] = "0";
                payloadObj["instanceBlockDocumentId"] = documentMetaData
                    ? documentMetaData.documentId
                    : "";
                payloadObj["instanceBlockDocumentName"] = documentMetaData
                    ? documentMetaData.fileName
                    : "";
                payloadObj["from"] = updateInstanceData.newFrom;
                payloadObj["instanceInformation"] = {
                    "Promised Quantity": updateInstanceInfoData.promiseQuantity,
                    "Actual Quantity": updateInstanceInfoData.actualQuantity,
                    "Promised Vessel": updateInstanceInfoData.promiseVessel,
                    "Actual Vessel": updateInstanceInfoData.actualVessel,
                    "Promised Date": updateInstanceInfoData.promiseDate,
                    "Actual Date": updateInstanceInfoData.actualDate,
                };
            } else {
                payloadObj["genericStepId"] =
                    updateInstanceData.selectedGenericStep.genericStepId;
                payloadObj["instanceId"] =
                    updateInstanceData.selectedInstance.instanceId;
                payloadObj["parentInstanceBlockchainId"] =
                    updateInstanceData.selectedParentInstance.parentInstanceId;
                payloadObj["instanceBlockDocumentId"] = documentMetaData
                    ? documentMetaData.documentId
                    : "";
                payloadObj["instanceBlockDocumentName"] = documentMetaData
                    ? documentMetaData.fileName
                    : "";
                payloadObj["from"] = updateInstanceData.selectedFromName.from;
                payloadObj["instanceInformation"] = {
                    "Promised Quantity": updateInstanceInfoData.promiseQuantity,
                    "Actual Quantity": updateInstanceInfoData.actualQuantity,
                    "Promised Vessel": updateInstanceInfoData.promiseVessel,
                    "Actual Vessel": updateInstanceInfoData.actualVessel,
                    "Promised Date": updateInstanceInfoData.promiseDate,
                    "Actual Date": updateInstanceInfoData.actualDate,
                };
            }
            createNewInstance(
                updateInstanceData.selectedGenericBlockchain
                    .instanceBlockchainTblId,
                payloadObj
            );
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        //setActiveStep(0);
        onHide();
    };

    const getButtonName = () => {
        if (activeStep === 1) return "Submit";
        else return "Next";
    };

    const isButtonDisable = () => {
        if (activeStep === 0) {
            if (
                !updateInstanceData.instanceCheckStatus &&
                updateInstanceData.selectedGenericBlockchain &&
                updateInstanceData.genericBlockchainDDList.length &&
                updateInstanceData.selectedGenericStep &&
                updateInstanceData.genericStepDDList.length &&
                updateInstanceData.selectedInstance &&
                updateInstanceData.instanceDDList.length &&
                updateInstanceData.selectedParentInstance.parentInstanceId &&
                updateInstanceData.parentInstanceDDList.length
            ) {
                return false;
            } else if (
                updateInstanceData.instanceCheckStatus &&
                updateInstanceData.selectedGenericBlockchain &&
                updateInstanceData.genericBlockchainDDList.length &&
                updateInstanceData.selectedGenericStep &&
                updateInstanceData.genericStepDDList.length &&
                updateInstanceData.newInstanceName
            ) {
                return false;
            } else return true;
        }
    };
    const getInstanceUpdateStatus = () => {
        if (apiStatus === "success") {
            return "Data submitted successfully!";
        } else if (apiStatus === "failure") {
            return "Something went wrong!";
        } else return "Please wait..!";
    };

    return (
        <div>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader} />
            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                <div
                                    className="marginTopTen doCenter"
                                    style={{
                                        fontWeight: "bold",
                                        margin: "50px",
                                    }}
                                >
                                    {getInstanceUpdateStatus()}
                                </div>
                            </Typography>
                            <div
                                className="marginTopTen doCenter"
                                style={{ margin: "16px" }}
                            >
                                <Button
                                    onClick={handleReset}
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                            <div
                                className="marginTopTen doCenter"
                                style={{ margin: "16px" }}
                            >
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1
                                        ? "No"
                                        : "Back"}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={isButtonDisable()}
                                >
                                    {activeStep === steps.length - 1
                                        ? "Yes"
                                        : getButtonName()}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
