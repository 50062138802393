import HierarchyManager from "../bae-client/HierarchyManager";
// export const fetchHierarchyResponse = (
//     workspacePersistenceTblId,
//     spaceRegistryTblId,
//     workspaceRegistryTblId,
//     spacePersistenceTblId
// ) => {
//     return async (dispatch) => {
//         const _hm = new HierarchyManager();
//         const response = await _hm.loadHierarchyResponse(
//             workspacePersistenceTblId,
//             spaceRegistryTblId,
//             workspaceRegistryTblId,
//             spacePersistenceTblId
//         );
//         dispatch({
//             type: "FETCH_HIERARCHY_RESPONSE",
//             payload: response,
//         });
//     };
// };

export const fetchHierarchyResponse = (response) => {
    return {
        type: "FETCH_HIERARCHY_RESPONSE",
        payload: response,
    };
};
export const resetHierarchy = () => {
    return {
        type: "RESET_HIERARCHY",
    };
};
