import config from "../config";
import axios from "axios";
import { getErrorMessage } from "../server-errors";
//import * as bcvm from "./bcvm-lite-build/index";

//import fetch from 'node-fetch';

class HTTP {
    isValideToken = false;
    authString = "";
    baseURL = "";
    constructor() {
        const params = new URLSearchParams(window.location.search);
        this.authString = sessionStorage.getItem("token");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_TOKEN
        //     : sessionStorage.getItem("token"); //params.get('token');
        this.membershipId = sessionStorage.getItem("memberId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_MEMBER
        //     : sessionStorage.getItem("memberId"); //params.get('mId');
        this.nhId = sessionStorage.getItem("nhId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_NH
        //     : sessionStorage.getItem("nhId"); //params.get('nhId');
        this.userId = sessionStorage.getItem("userId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_USER
        //     : sessionStorage.getItem("userId"); //params.get('userId');
        // this.restApp = window.location.pathname.split("/")[1]
        //     ? window.location.pathname.split("/")[1]
        //     : config.restAppName;
        // this.restApp = process.env.REACT_APP_REST_APP.toString();
        this.restApp = window.runtimeConfig && window.runtimeConfig.restApp;
        // this.baseURL =
        //     process.env.NODE_ENV === "development"
        //         ? config.env.TEST_URL
        //         : window.location.origin + "/" + this.restApp + "/"; //window.location.href.split(config.rootFolder)[0];
        // this.baseURL =
        //     (process.env.NODE_ENV === "development"
        //         ? config.env.TEST_URL
        //         : window.location.origin) +
        //     "/" +
        //     this.restApp +
        //     "/";
        this.baseURL =
            window.runtimeConfig &&
            window.runtimeConfig.restURL + "/" + this.restApp + "/";
        //this.setAuthorization(this.authString);
        this.setUserName(sessionStorage.getItem("user"));

        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }
    initialize = () => {
        this.authString = sessionStorage.getItem("token");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_TOKEN
        //     : sessionStorage.getItem("token"); //params.get('token');
        this.membershipId = sessionStorage.getItem("memberId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_MEMBER
        //     : sessionStorage.getItem("memberId"); //params.get('mId');
        this.nhId = sessionStorage.getItem("nhId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_NH
        //     : sessionStorage.getItem("nhId"); //params.get('nhId');
        this.userId = sessionStorage.getItem("userId");
        // process.env.NODE_ENV === "development"
        //     ? config.env.TEST_USER
        //     : sessionStorage.getItem("userId"); //params.get('userId');
        // this.restApp = window.location.pathname.split("/")[1]
        //     ? window.location.pathname.split("/")[1]
        //     : config.restAppName;
        // this.baseURL =
        //     process.env.NODE_ENV === "development"
        //         ? config.env.TEST_URL
        //         : window.location.origin + "/" + this.restApp + "/"; //window.location.href.split(config.rootFolder)[0];
        // this.restApp = process.env.REACT_APP_REST_APP.toString();
        this.restApp = window.runtimeConfig && window.runtimeConfig.restApp;
        // this.baseURL =
        //     (process.env.NODE_ENV === "development"
        //         ? config.env.TEST_URL
        //         : window.location.origin) +
        //     "/" +
        //     this.restApp +
        //     "/";
        this.baseURL =
            window.runtimeConfig &&
            window.runtimeConfig.restURL + "/" + this.restApp + "/";
        //this.setAuthorization(this.authString);
    };
    setNhId = (id) => {
        this.nhId = id;
    };
    setUserId = (id) => {
        this.userId = id;
    };
    getUserId = () => {
        return this.userId;
    };
    setMembershipId = (id) => {
        this.membershipId = id;
    };
    setAuthorization(authString) {
        this.authString = authString;
        this.decodedAuthString = atob(this.authString).split(":");
        this.user = this.decodedAuthString[0];
        this.password = this.decodedAuthString[1];
        this.nhPath = this.decodedAuthString[2];
    }
    setUserName = (userName) => {
        this.user = userName;
    };
    getUserName = () => {
        return this.user;
    };
    getNHId() {
        return this.nhId;
    }
    getbaseurl() {
        return this.baseURL;
    }

    handleError(reject) {
        return function (err) {
            if (err.response) {
                if (
                    err.response.status === 404 &&
                    err?.response?.headers?.["content-type"] !==
                        "application/json"
                ) {
                    reject({
                        errorCode: "Resource unavailable",
                        error: "This service is not available, Please contact UC admin.",
                    });
                }
                reject(
                    err.response &&
                        err.response.data &&
                        getErrorMessage(
                            err.response.data[0],
                            err.response.status
                        )
                );
            }
        };
    }

    handleSuccess(resolve) {
        return function (result) {
            resolve(result.data ? result.data : result.status);
        };
    }

    verifyUserCredentials(url, method, data) {
        url = this.baseURL + "rest/" + url;
        if (method === undefined || method === null) {
            method = "GET";
        }
        let response = new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                responseType: "text",
                headers: {
                    token: data.token,
                },
            })
                .then((response) => {
                    resolve(response.data ? response.data : response.status);
                })
                .catch((err) => {
                    reject(
                        err.response &&
                            err.response.data &&
                            getErrorMessage(
                                err.response.data[0],
                                err.response.status
                            )
                    );
                });
        });

        return response;
    }
    sendRestRequest(url, method, data) {
        url = this.baseURL + "rest/" + url;
        if (method === undefined || method === null) {
            method = "GET";
        }
        let response = new Promise((resolve, reject) => {
            let axiosConfig = {};
            if (data !== null && data !== undefined) {
                axiosConfig = {
                    url: url,
                    method: method,
                    data: data,
                    responseType: "application/json",
                    headers: {
                        Authorization: this.authString,
                    },
                };
            } else {
                axiosConfig = {
                    url: url,
                    method: method,
                    responseType: "application/json",
                    headers: {
                        Authorization: this.authString,
                    },
                };
            }
            axios(axiosConfig).then(
                this.handleSuccess(resolve),
                this.handleError(reject)
            );
        });
        return response;
    }
    sendRestRegistraionRequest(url, method, data) {
        url = this.baseURL + "rest/" + url;
        if (method === undefined || method === null) {
            method = "GET";
        }
        let response = new Promise((resolve, reject) => {
            let axiosConfig = {};
            axiosConfig = {
                url: url,
                method: method,
                data: data,
                responseType: "application/json",
                headers: {
                    Authorization: this.authString,
                },
            };
            axios(axiosConfig)
                .then((response) => {
                    resolve(response.data ? response.data : response.status);
                })
                .catch((err) => {
                    reject(
                        err.response &&
                            err.response.data &&
                            getErrorMessage(
                                err.response.data[0],
                                err.response.status
                            )
                    );
                });
        });
        return response;
    }
    sendWidgetRestRequestWithoutAuth(url, method, data) {
        url = this.baseURL + "rest/" + url;
        if (method === undefined || method === null) {
            method = "GET";
        }
        let response = new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                responseType: "json",
                headers: {
                    Authorization: this.authString,
                },
            })
                .then((response) => {
                    resolve(response.data ? response.data : response.status);
                })
                .catch((err) => {
                    reject(
                        err.response &&
                            err.response.data &&
                            getErrorMessage(
                                err.response.data[0],
                                err.response.status
                            )
                    );
                });
        });

        return response;
    }
    sendWidgetRestRequest(url, method, data) {
        if (method === undefined || method === null) {
            method = "GET";
        }
        let response = new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                responseType: "json",
                headers: {
                    "Content-Type": "application/json",
                },
                auth: {
                    username: data.userName,
                    password: data.token,
                },
            })
                .then((response) => {
                    resolve(response.data ? response.data : response.status);
                })
                .catch((err) => {
                    reject(
                        err.response &&
                            err.response.data &&
                            getErrorMessage(
                                err.response.data[0],
                                err.response.status
                            )
                    );
                });
        });

        return response;
    }
    getDocument = (url) => {
        let response = new Promise((resolve, reject) => {
            let axiosConfig = {};
            axiosConfig = {
                url: url,
                method: "GET",
                responseType: "text/plain",
                headers: {
                    Authorization: this.authString,
                },
            };
            axios(axiosConfig)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(
                        err.response &&
                            err.response.data &&
                            getErrorMessage(
                                err.response.data[0],
                                err.response.status
                            )
                    );
                });
        });
        return response;
    };
    uploadFile(data, apiURL, method) {
        let url =
            this.baseURL + (apiURL ? apiURL : "rest/documentUpload/document");
        let response = new Promise((resolve, reject) => {
            if (method === "PUT") {
                axios
                    .put(url, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: this.authString,
                        },
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(
                            err.response &&
                                err.response.data &&
                                getErrorMessage(
                                    err.response.data[0],
                                    err.response.status
                                )
                        );
                    });
            } else {
                axios
                    .post(url, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: this.authString,
                        },
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(
                            err.response &&
                                err.response.data &&
                                getErrorMessage(
                                    err.response.data[0],
                                    err.response.status
                                )
                        );
                    });
            }
        });
        return response;
    }
}
let http = new HTTP();
export default http;
