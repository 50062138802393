import React, { Component } from "react";

class Column extends Component {
    constructor(props) {
        super(props);
    }
    renderClassnameForSize = () => {
        if (this.props.size !== undefined) {
            return "col-" + this.props.size;
        } else {
            return "col-3";
        }
    };
    renderClassnames = () => {
        if (this.props.className !== undefined) return this.props.className;
        else return "";
    };
    render() {
        return <div className={this.renderClassnameForSize() + " " + this.renderClassnames()}>{this.props.children}</div>;
    }
}

export default Column;
