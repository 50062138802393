import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import "./manageSpace.css";
import { getDateByFormat } from "../../bae-client/Utils";

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, headCells } = props;

    return (
        <TableHead
            style={{ background: "transparent", fontFamily: "Montserrat" }}
        >
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? "none" : "default"}
                        width={headCell.width}
                        className="rippleLogTableHead"
                        style={{ minWidth: headCell.width }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const title = props.title;
    const selectedDoc = props.selected;
    const deleteRow = (deleteRows) => {
        props.deleteRow(selectedDoc);
    };
    return (
        <Toolbar
            className={
                " row " +
                clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })
            }
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className="col-9 p-0 doCenter"
                    id="tableTitle"
                    component="div"
                >
                    <span className="tableTitle">{title}</span>
                </Typography>
            )}
        </Toolbar>
    );
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        width: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function RippleLogTableView(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    let headCells = props.columns;
    let rows = props.rows;
    let title = props.title;
    let selectedRowIndex = null;
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const deleteRow = (selectedDoc) => {
        props.deleteRow(selectedDoc);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name, id, index) => {
        const selectedIndex = selected.indexOf(id);
        selectedRowIndex = selectedIndex;
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        props.handleClick(event, index, id);
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showModalView = (event, index, fileName, row) => {
        let rowIndex = rowsPerPage * page + index;
        props.showDocModalView(event, rowIndex, fileName, row);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
            {/* <Paper className={classes.paper}> */}
            {/* <EnhancedTableToolbar
                deleteRow={(rowForDelete) => { deleteRow(rowForDelete) }}
                numSelected={selected.length}
                title={title}
                selected={selected} /> */}
            <TableContainer
                className="invisibleScroller"
                style={{ maxHeight: "50vh", overflowY: "auto" }}
            >
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                        title={title}
                    />
                    <TableBody>
                        {
                            // stableSort(rows, getComparator(order, orderBy))
                            rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let documentDate = getDateByFormat(
                                        row.createdDate,
                                        "mm/dd/yyyy"
                                    );
                                    return (
                                        <TableRow hover>
                                            <TableCell
                                                align="center"
                                                padding="none"
                                            >
                                                <button
                                                    id={"btn_" + index}
                                                    className="btn btnLabel"
                                                    onClick={(event) =>
                                                        showModalView(
                                                            event,
                                                            index,
                                                            row.name,
                                                            row
                                                        )
                                                    }
                                                >
                                                    {row.btn}
                                                </button>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                color="blue"
                                                padding="none"
                                                style={{ textAlign: "left" }}
                                                title={row.type}
                                            >
                                                {row.type}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                align="center"
                                                id={labelId}
                                                scope="row"
                                                width={"70"}
                                                padding="none"
                                                className="textEllipsis"
                                                style={{ maxWidth: "5rem" }}
                                            >
                                                <div
                                                    className="textEllipsis"
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                    title={row.documentName}
                                                >
                                                    {/* <span className="w-100"> */}
                                                    {row.documentName}
                                                    {/* </span> */}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                padding="none"
                                                style={{ maxWidth: "5rem" }}
                                            >
                                                <div
                                                    className="textEllipsis"
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                    title={row.location}
                                                >
                                                    {row.location}
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                padding="none"
                                                title={documentDate}
                                                style={{ width: "7rem" }}
                                            >
                                                {documentDate}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                padding="none"
                                                title={documentDate}
                                                style={{ width: "7rem" }}
                                            >
                                                {documentDate}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        {rows.length === 0 && (
                            <TableRow style={{ height: "15rem" }}>
                                <TableCell colSpan={6}>
                                    <div className="doCenter">
                                        No result found.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {/* </Paper> */}
        </div>
    );
}
