import { Popover, Typography } from "@material-ui/core";
import React, { Component } from "react";

export class PopoverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.props.closePopover();
    };
    render() {
        return (
            <div>
                <Popover
                    id={this.props.id}
                    open={this.props.show}
                    anchorEl={this.props.anchorEl}
                    onClick={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal:
                            this.props.ahOrigin !== undefined
                                ? this.props.ahOrigin
                                : "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal:
                            this.props.thOrigin !== undefined
                                ? this.props.thOrigin
                                : "center",
                    }}
                    style={{
                        width:
                            this.props.width !== undefined
                                ? this.props.width
                                : "",
                    }}
                    className="doCenter"
                >
                    {this.props.children}
                </Popover>
            </div>
        );
    }
}

export default PopoverView;
