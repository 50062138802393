import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { validateNameLength } from "../../../../../../bae-client/Utils";

export default function WorkspaceModal({
    title,
    open,
    setOpen,
    type,
    existingName,
    buttonAction,
    actionButtonName = "Save",
    existingMatcher,
    content,
    setContent,
    id,
    textId,
}) {
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState("");
    const handleClose = () => {
        setOpen(false);
        setContent("");
    };
    const handleOkay = () => {
        if (title.includes("Delete", 0) || title.includes("Archive", 0)) {
            setOpen(false);
            setContent("");
            buttonAction();
            return;
        }
        if (error) return;
        if (existingMatcher && existingMatcher.includes(inputValue)) {
            setError(`${type} name already exists!`);
            return;
        }
        if (inputValue === null || inputValue.trim().length === 0) {
            setError(`${type} name cannot be empty!`);
            return;
        }
        if (!validateNameLength(inputValue)) {
            let msg = `${type} name should be less than 100 characters!`;
            setError(msg);
            return;
        }
        setError("");
        setOpen(false);
        buttonAction(inputValue);
    };
    const onChangeHandler = (event) => {
        setError("");
        setInputValue(event.target.value);
    };
    useEffect(() => {
        if (existingName) {
            if (title === "Add New Space" || title === "Add New Folder")
                setInputValue("");
            else setInputValue(existingName);
        }
    }, [existingName]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        padding: "12px 16px",
                        paddingBottom: "4px",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "32px",
                        color: "#404040",
                    }}
                    className="modal-heading"
                >
                    {title || "Modal Dialog"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        padding: "8px",
                        paddingRight: "6px",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ padding: "14px 16px" }}>
                    {content ? (
                        <p>{content}</p>
                    ) : (
                        <div className="modal-textbox-container">
                            <input
                                id={textId}
                                type="text"
                                placeholder={`Enter ${type} name`}
                                className="modal-textbox"
                                value={inputValue}
                                onChange={onChangeHandler}
                            />
                        </div>
                    )}

                    {error && (
                        <span
                            className="modal-error"
                            id={`small_${type}_errorMessage`}
                        >
                            {error}
                        </span>
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: "14px 16px", gap: "10px" }}>
                    <button
                        className="modal-button-outlined"
                        onClick={handleClose}
                        title="Cancel"
                        id="cancelButton"
                    >
                        Cancel
                    </button>
                    <button
                        className="modal-button-primary"
                        onClick={handleOkay}
                        title={actionButtonName || "Save"}
                        id={id}
                    >
                        {actionButtonName}
                    </button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
