import React, { useEffect, useState } from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";

export default function RenameSpaceModal({
    modalShow,
    closeModal,
    spaceName,
    handleUpdatedSpaceNameSubmit,
    error,
}) {
    const updatedRippleName = spaceName;
    const [updatedName, setupdatedName] = useState(updatedRippleName);
    const [errorMessage, setErrorMessage] = useState(error);

    useEffect(() => {
        setupdatedName(spaceName);
    }, [spaceName]);

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    const handleCloseModal = () => {
        setErrorMessage("");
        closeModal();
        setupdatedName(spaceName);
    };
    const handleSubmit = () => {
        if (errorMessage) return;
        handleUpdatedSpaceNameSubmit(updatedName);
    };
    const handleFocus = (event) => event.target.select();
    const setFromText = (value, id, event) => {
        switch (id) {
            case "updateSpacename":
                {
                    setupdatedName(value);
                    if (value && value.length > 100)
                        setErrorMessage(
                            "Name should not exceed 100 char length"
                        );
                    else setErrorMessage("");
                }
                break;
            default:
                break;
        }
    };
    if (modalShow) {
        return (
            <ModalView
                // disabled={label.trim() === spaceName.trim() ? true : false}
                onSubmit={handleSubmit}
                show={modalShow}
                onHide={handleCloseModal}
                value="Change"
                title="Change"
                type="submit-cancel"
                footer={"false"}
                size="md"
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <span
                        className="addWorkspaceModal addwork"
                        style={{
                            marginLeft: "-255px",
                            marginBottom: " 34px",
                            width: "auto",
                        }}
                    >
                        Rename Space
                    </span>
                    <TextBox
                        id="updateSpacename"
                        className="updateSpaceName loginPageBorder paddingLeft "
                        type="text"
                        onchange={setFromText}
                        value={updatedName}
                        onFocus={handleFocus}
                        formControl="Off"
                        doCenter={true}
                    ></TextBox>
                </div>
                {errorMessage && (
                    <>
                        <small
                            id="small_renameSpace_errorMessage"
                            style={{ color: "red", paddingLeft: "18px" }}
                        >
                            {errorMessage}
                        </small>
                        <br />
                    </>
                )}
            </ModalView>
        );
    } else {
        return null;
    }
}
