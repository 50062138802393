const MimeType = new Map([
    ["txt", "text/plain"],
    ["mp3", "audio/mpeg"],
    ["mp4", "video/mp4"],
    ["gif", "image/gif"],
    ["doc", "application/msword"],
    [
        "docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    ["htm", "text/html"],
    ["html", "text/html"],
    ["jpeg", "image/jpeg"],
    ["jpg", "image/jpeg"],
    ["png", "image/png"],
    ["pdf", "application/pdf"],
    ["ppt", "application/vnd.ms-powerpoint"],
    [
        "pptx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    ["svg", "image/svg+xml"],
    ["xls", "application/vnd.ms-excel"],
    [
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    ["xml", "application/xml"],
]);

export default MimeType;
