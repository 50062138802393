import { Chip } from "@mui/material";
import React from "react";

const Row = ({ rowData, isInstance }) => {
    return (
        <div className="rowContainer">
            {isInstance ? (
                <div className="rowItem">
                    <Chip label={"Pending"} title="Pending" size="small" />
                </div>
            ) : null}
            <div className="rowItem">Flow A</div>
            <div className="rowItem">
                <img
                    alt={"options"}
                    title="options"
                    src="./images/options.png"
                ></img>
            </div>
        </div>
    );
};

export default Row;
