import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

class BreadcumView extends Component {
    handleClick = (event, pathArray, folderName) => {
        event.preventDefault();
        console.info("You clicked a breadcrumb.", pathArray, folderName);
        this.props.onChangeFolder(pathArray, folderName);
    };
    renderLabel = (text) => {
        if (this.props.sepretor !== undefined) {
            return text.split(this.props.sepretor)[1];
        } else {
            return text;
        }
    };
    renderListOfItem = () => {
        let items = this.props.items;
        let listItems = [];
        let keys = Array.from(items.keys());
        keys.forEach((key, index) => {
            let label = this.renderLabel(key);
            listItems.push(
                <Link
                    className="textEllipsis"
                    title={label}
                    color="inherit"
                    href="/"
                    id={key.id}
                    onClick={(e) => this.handleClick(e, items.get(key), key)}
                >
                    {label}
                </Link>
            );
        });
        return listItems;
    };
    render() {
        return (
            <Breadcrumbs aria-label="breadcrumb" id="fileBreadcrumb">
                {this.renderListOfItem()}
            </Breadcrumbs>
        );
    }
}

export default BreadcumView;
