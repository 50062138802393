import React, { Component } from "react";
import http from "../../../bae-client/HTTP";
import Loader from "../../stylized/Loader";
import Alert from "@material-ui/lab/Alert";
import AlertDialog from "../../stylized/AlertDialog";
import OutlineTextBoxView from "../../stylized/OutlineTextBoxView";
import { ValidateEmail } from "../../../bae-client/Utils";
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.userEmail = "";
        this.state = {
            resendBtnDisabled: true,
            showLoader: false,
            showAlert: false,
            isMailValid: null,
            showAlertModal: false,
            err_email: "",
        };
    }
    showAlertView = (title, body) => {
        console.log(title, body);
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    handleSubmitBtn = () => {
        if (this.isMailValid() === true) {
            this.setState({ showLoader: true });

            let url =
                "password/forgotPassword/?username=" + this.userEmail.trim();
            http.sendRestRegistraionRequest(url, "POST")
                .then((response) => {
                    if (response) {
                        this.showAlertView("", "Email sent successfully!");
                    }
                    this.setState({
                        showLoader: false,
                        resendBtnDisabled: false,
                    });

                    setTimeout(() => {
                        this.props.changeUserEmail(this.userEmail.trim());
                    }, 5000);
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({
                        showLoader: false,
                        resendBtnDisabled: true,
                    });
                });
        }
    };

    setEmailText = (value) => {
        this.userEmail = value;
    };
    isMailValid = () => {
        // return ValidateEmail(this.userEmail);
        if (this.userEmail.trim() !== "") {
            let isValidEmail = ValidateEmail(this.userEmail);
            if (isValidEmail) {
                this.setState({ isMailValid: isValidEmail, err_email: "" });
                return isValidEmail;
            } else {
                this.setState({
                    isMailValid: isValidEmail,
                    err_email: "Please Enter valid Email",
                });
                return isValidEmail;
            }
        } else {
            this.setState({
                isMailValid: false,
                err_email: "Please Enter Email",
            });
            // alert("You have entered an invalid email address!")
            return false;
        }
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };

    renderAlertModal = () => {
        console.log(this.errMsgBody, this.state.showAlertModal);
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    render() {
        return (
            <>
                {this.renderAlertModal()}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "15px",
                    }}
                >
                    <span
                        className="forgotPassSpans"
                        style={{ marginTop: "15px" }}
                    >
                        Forgot your password?
                    </span>
                    <span
                        className="forgotPassSpans"
                        style={{ marginTop: "15px" }}
                    >
                        Enter the email associated with the account and we’ll
                        send
                    </span>
                    <span
                        className="forgotPassSpans"
                        style={{ marginTop: "15px" }}
                    >
                        you a verification code to reset your password.
                    </span>
                    <div
                        className="row"
                        style={{ width: "35%", marginTop: "3rem" }}
                    >
                        <OutlineTextBoxView
                            id="Email"
                            placeholder="Email"
                            type="text"
                            onChangeInput={this.setEmailText}
                            onBlurInput={this.ValidateEmail}
                            errorMsg={this.state.err_email}
                        ></OutlineTextBoxView>
                    </div>
                    <button
                        type="submit"
                        id="signupBtn"
                        className="btn btn-primary submitBtn"
                        style={{
                            fontWeight: "400",
                            width: "35%",
                            marginTop: "3rem",
                        }}
                        name="btn_signup"
                        onClick={this.handleSubmitBtn}
                        disabled={!this.state.resendBtnDisabled}
                    >
                        SEND E-MAIL
                    </button>
                    {/* <input
                    type="button"
                    className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                    style={{ height: "40px", marginTop: "25px" }}
                    onClick={this.handleSubmitBtn}
                    disabled={!this.state.resendBtnDisabled}
                    value="SEND E-MAIL"
                ></input> */}
                    {/* <span className="forgotPassSpans" style={{ marginTop: "40px" }}>
                    An confirmation email has been sent to your email, check your email inbox!
                </span> */}
                    {/* <input type="button" className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder"
                    style={{ height: '40px', marginTop: '45px', letterSpacing: '1.25px' }}
                    disabled={this.state.resendBtnDisabled}
                    onClick={this.handleSubmitBtn}
                    value="Didn’t Recieve an Email? Click here to try again"></input> */}
                    <Loader show={this.state.showLoader} />
                </div>
            </>
        );
    }
}

export default ForgotPassword;
