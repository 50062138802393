export const EmptyRuleScreen = ({ label }) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                }}
            >
                <div>
                    <img src="./images/Empty Rule ICON.svg" alt="Empty Rule" />
                </div>
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "21.94px",
                        letterSpacing: "0.15px",
                        textAlign: "center",
                        color: "#9DA5B4",
                    }}
                >
                    {label}
                </div>
            </div>
        </>
    );
};
