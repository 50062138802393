import React from "react";

export default function Header({ currentPageType, handleBackBtnClick }) {
    if (
        currentPageType === "managespace" ||
        currentPageType === "AlertsFullScreen" ||
        currentPageType === "SpacesFullScreen"
    ) {
        return (
            <div
                className="row middleSection w-100"
                style={{ height: "fit-content" }}
            >
                <div
                    className="backBtn"
                    style={{ marginLeft: "2.5rem", marginTop: "1.5rem" }}
                >
                    <button
                        id="manageSpaceBackBtn"
                        className="btn btnLabel doCenter newFontMontserrat"
                        onClick={handleBackBtnClick}
                    >
                        <img
                            className="btnArrowImg"
                            src="./images/LeftArrowIcon.png"
                            alt="arrow button"
                        ></img>
                        Back
                    </button>
                </div>
            </div>
        );
    } else if (currentPageType === "workspace") {
        return (
            <div className="row w-100">
                <span className="mainTitle textEllipsis doCenter w-100">
                    Welcome To Your Workspace
                </span>
            </div>
        );
    } else if (
        currentPageType === "AlertsFullScreen" ||
        currentPageType === "SpacesFullScreen"
    ) {
        return null;
    }
}
