import React from "react";

class Button extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.id === undefined || this.props.id === null)
            this.id = this.props.label.replace(/\s+/, "_");
        else this.id = this.props.id;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.props.onclick(event);
    }
    renderClassname = () => {
        if (this.props.className !== undefined) {
            return this.props.className;
        } else return "btn-primary saveBtn";
    };
    renderIcon = () => {
        if (this.props.iconClass !== undefined) {
            return <i className={this.props.iconClass}></i>;
        } else return null;
    };

    render() {
        return (
            <div className="saveBtnDiv">
                {this.props.showDrawer === true ? (
                    <img
                        src="./images/hide.png"
                        alt="Close Arrow"
                        style={
                            this.props.showDrawer
                                ? {
                                      position: "absolute",
                                      top: "14px",
                                      right: "100px",
                                      height: "24px",
                                      width: "24px",
                                  }
                                : {}
                        }
                    />
                ) : this.props.showDrawer === false ? (
                    <img
                        src="./images/show.png"
                        alt="Close Arrow"
                        style={
                            !this.props.showDrawer
                                ? {
                                      position: "absolute",
                                      top: "12px",
                                      right: "100px",
                                      height: "24px",
                                      width: "24px",
                                  }
                                : {}
                        }
                    />
                ) : null}
                <button
                    className={"btn " + this.renderClassname()}
                    id={this.id}
                    onClick={this.handleClick}
                    {...(this.props.disabled !== undefined &&
                    this.props.disabled !== null
                        ? { disabled: this.props.disabled }
                        : null)}
                    title={this.props.title}
                    {...this.props}
                    style={{
                        background: "rgb(255, 255, 255)",
                        marginLeft: "20px",
                        borderRadius: "10px",
                        color: "rgb(0, 0, 0)",
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "4px",
                        width: "8rem",
                        height: "49px",
                        textAlign: "center",
                    }}
                >
                    {this.props.label}
                    {this.renderIcon()}
                </button>
            </div>
        );
    }
}

export default Button;
