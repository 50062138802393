import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import { connect } from "react-redux";
class SliderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }
    componentDidMount() {
        if (this.props.crouselActiveIndex !== undefined) {
            this.setState({ index: this.props.crouselActiveIndex });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentPageType !== this.props.currentPageType) {
            let pageType = this.props.currentPageType.toLowerCase();
            if (pageType.includes("fullscreen")) {
                this.setState({ index: 0 });
            }
        }
        if (
            prevProps.crouselActiveIndex !== undefined &&
            this.props.crouselActiveIndex !== undefined
        ) {
            if (
                prevProps.crouselActiveIndex !== this.props.crouselActiveIndex
            ) {
                this.setState({ index: this.props.crouselActiveIndex });
            }
        }
    }
    handleSelect = (selectedIndex, e) => {
        this.setState({ index: selectedIndex });
    };
    renderItems = () => {
        let items = [];
        let crouselItems = [];
        let rawData = this.props.rawData;
        if (rawData !== null && rawData !== undefined) {
            rawData.forEach((element, index) => {
                items.push(element);
                if (items.length === this.props.cardCountPerSlide) {
                    crouselItems.push(<Carousel.Item>{items}</Carousel.Item>);
                    items = [];
                }
            });
        }
        return crouselItems;
    };
    renderBackIcon = () => {
        // return (
        //     <img
        //         id="spaceCardBackBtn"
        //         className="btnArrowSlider"
        //         src="./images/LeftArrowIcon.png"
        //     ></img>
        // );
    };
    renderNextIcon = () => {
        // return (
        //     <img
        //         id="spaceCardNextBtn"
        //         className="btnArrowSlider"
        //         src="./images/RightArrowIcon.png"
        //     ></img>
        // );
    };
    render() {
        return (
            <Carousel
                activeIndex={this.state.index}
                indicators={false}
                interval={600000}
                onSelect={this.handleSelect}
                nextIcon={this.renderNextIcon()}
                prevIcon={this.renderBackIcon()}
                className="h-100 p-3"
                pause={false}
            >
                {this.renderItems()}
            </Carousel>
        );
    }
}
const mapDispatchToProps = () => {
    return {};
};
const mapStateToProps = (state) => {
    return {
        currentPageType: state.currentPageType,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(SliderView);
