import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as DVHelper from "./../helper/documentViewerHelper";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import * as Mixpanel from "../../mix-panel/Mixpanel";
import AddDocuments from "../functional/UnityCentral/ContentPane/document-chain/modals/AddDocuments";
import AlertDialog from "./AlertDialog";
import AddInstance from "../functional/UnityCentral/ContentPane/document-chain/modals/AddInstance";
import http from "../../bae-client/HTTP";
import { fetchFilterData } from "../../actions/DashboardActions";
import { setSnackbarAction } from "../../services/services/app";

class ModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            showDocumentModal: false,
            showAddInstanceModal: false,
            anchorEl: null,
            alertDialogShow: false,
            alertDialogContent: {
                title: "",
                message: "",
                isAlert: false,
            },
            selectedGenericInstances: [],
            zoomCount: 0,
        };
        this.values = null;
        this.selectedRow = null;
        this.selectedInstance = null;
    }
    closeModal = () => {
        this.handleClose();
        this.setState({ showDocumentModal: false });
        this.setState({ isModalOpen: false });
    };
    handleClose = (e) => {
        this.setState({ anchorEl: null });
    };
    setSelectedGenericInstances = (instanceData) => {
        this.setState({ selectedGenericInstances: instanceData });
    };
    fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                console.log(response);
                this.props.fetchFilterData(response);
            })
            .catch((err) => console.log(err));
    };
    addNewInstance = (name, tags, branchId, branchName) => {
        // setShowLoader(true);
        this.setState({ showAddInstanceModal: false });
        let encodedFamilyTags = encodeURIComponent(tags);
        http.sendRestRequest(
            `documentChain/instance?genericId=${
                this.selectedRow.id
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                // setSnackbarMessage("Instance created successfully!");
                // setShowSnackbar(true);
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${this.selectedRow.id}`
                )
                    .then((instanceData) => {
                        // setShowLoader(false);
                        const prevInstances = [
                            ...this.state.selectedGenericInstances,
                        ].map((instance) => instance.instanceId);
                        this.setSelectedGenericInstances(instanceData);
                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        this.selectedInstance = newInstance[0];
                        this.setState({ isModalOpen: true });
                    })
                    .catch((error) => {
                        console.log(error);
                        // setShowLoader(false);
                        // setShowAlertDialog(true);
                        // setAlertDialogMessage(error.error);
                        this.showAlertView("", error.error);
                    });
                this.fetchLatestFlowsData();
            })
            .catch((error) => {
                console.log(error);
                // setShowLoader(false);
                // setShowAlertDialog(true);
                // setAlertDialogMessage(error.error);
                // this.setAlertDialogShow(true)
                // this.showAlertView('',error.error)
                this.props.setSnackbarAction({
                    open: true,
                    message: error.error,
                    severity: "error",
                });
            });
    };
    openDocumentModal = (e) => {
        this.handleClose();
        this.setState({ showDocumentModal: true });
    };
    closeInstance = () => {
        this.setState({ showAddInstanceModal: false, isModalOpen: true });
    };

    openAddInstanceModal = () => {
        this.setState({ showAddInstanceModal: true, isModalOpen: false });
    };
    setSelectedRow = (value) => {
        this.selectedRow = value;
    };
    handleSaveClick = (event) => {
        this.props.onclick();
    };

    handleClickModal = (event) => {
        event.stopPropagation();
    };

    handleBack = () => {
        if (this.props.isMergeModal) {
            this.props.setSubmitButtonText("Next");
        }
    };

    handleZoomIn = () => {
        let fileNameAttr = this.props.headerTitle.split(".");
        let fileType = fileNameAttr[fileNameAttr.length - 1];
        DVHelper.zoomIn(fileType);
        if (this.state.zoomCount < 100) {
            this.setState({ zoomCount: this.state.zoomCount + 10 });
        }
    };

    handleZoomOut = () => {
        let fileNameAttr = this.props.headerTitle.split(".");
        let fileType = fileNameAttr[fileNameAttr.length - 1];
        DVHelper.zoomOut(fileType);
        if (this.state.zoomCount > 0) {
            this.setState({ zoomCount: this.state.zoomCount - 10 });
        }
    };

    handleDownload = () => {
        const baseUrl = window.location.origin;
        try {
            let fileSource =
                this.props.fileSource || this.props.children.props.fileLocation;
            if (fileSource) {
                saveAs(
                    `${baseUrl}/${fileSource.documentLocation}/${fileSource.documentName}`,
                    fileSource.documentName
                );
                let fileExt = fileSource.documentName.split(".").pop();
                Mixpanel.callFileManagementEvent("Download", fileExt);
            }
        } catch (error) {
            console.log("Download failed!");
        }
    };

    handleMoreClick = () => {
        http.sendRestRequest("document/documentInfo", "POST", [
            parseInt(this.props.documentId),
        ])
            .then((response) => {
                if (response.length > 0) {
                    this.setState({ isModalOpen: true });
                } else {
                    this.setState({ isModalOpen: false });
                    this.props.setSnackbarAction({
                        open: true,
                        message: "Please save the file and then attach to flow",
                        severity: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    renderFooter() {
        if (this.props.footer !== "false") {
            return (
                <Modal.Footer>
                    <Button onClick={this.handleSaveClick}>
                        {this.props.submitButtonText}
                    </Button>
                </Modal.Footer>
            );
        } else {
            return null;
        }
    }

    renderHeader() {
        if (this.props.showHeader !== "false") {
            return (
                <Modal.Header closeButton>
                    <div style={{ width: "75%" }}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.headerTitle}
                        </Modal.Title>
                    </div>
                    <button
                        onClick={this.handleMoreClick}
                        id="btn_attach_flow"
                        title={"Attach To Flow"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "126px !important",
                            marginLeft: "auto",
                        }}
                    >
                        <em>Attach To Flow</em>
                    </button>
                </Modal.Header>
            );
        } else {
            return null;
        }
    }

    closeModal = () => {
        this.props.onHide();
        this.setState({ zoomCount: 0 });
    };
    shareFile = () => {};
    renderCloseButton = () => {
        return (
            <div className="row w-100 m-0 modalCloseIconRow" title=" ">
                <button
                    type="submit-cancel"
                    onClick={this.closeModal}
                    className="close modalCloseIcon"
                    data-dismiss="modal"
                    title="Close"
                    id={this.props.closeBtnId || "closeModalButton"}
                >
                    &times;
                </button>
            </div>
        );
    };

    renderToolBar = () => {
        return (
            // <div style={{ padding: "1rem" }}>
            //     <div style={{ width: "30%", margin: "auto", display: "flex" }}>
            //         <div style={{ flex: "1" }}>
            //             <button
            //                 onClick={this.handleDownload}
            //                 style={{ border: "none", background: "#fff" }}
            //             >
            //                 <img
            //                     src="./images/fileTypeIcon/download 1.png"
            //                     width="25px"
            //                     height="25px"
            //                     alt="Download"
            //                 />
            //             </button>
            //         </div>
            //         <div style={{ flex: "1" }}>
            //             <button
            //                 onClick={this.handleZoomIn}
            //                 style={{ border: "none", background: "#fff" }}
            //             >
            //                 <img
            //                     src="./images/fileTypeIcon/ZoomIn.png"
            //                     width="25px"
            //                     height="25px"
            //                     alt="Zoom In"
            //                 />
            //             </button>
            //         </div>
            //         <div style={{ flex: "1" }}>
            //             <button
            //                 onClick={this.handleZoomOut}
            //                 style={{ border: "none", background: "#fff" }}
            //             >
            //                 <img
            //                     src="./images/fileTypeIcon/ZoomOut.png"
            //                     width="25px"
            //                     height="25px"
            //                     alt="Zoom Out"
            //                 />
            //             </button>
            //         </div>
            //     </div>
            // </div>
            <div
                style={{
                    padding: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "455px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <div
                        style={{
                            width: "95px",
                            height: "36px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <input
                            style={{
                                textAlign: "center",
                                width: "56px",
                                height: "36px",
                                padding: "6px 8px",
                                borderRadius: "4px",
                                border: "1px solid #9DA5B4",
                                background: "#F4F7FB",
                            }}
                            defaultValue={`1`}
                        />
                        <div
                            style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                letterSpacing: "-0.006em",
                            }}
                        >
                            / 1
                        </div>
                    </div>
                    <div
                        style={{
                            width: "36px",
                            transform: "rotate(90deg)",
                            border: "1px solid black",
                        }}
                    ></div> */}
                    <div
                        style={{
                            width: "144px",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <button
                            style={{ border: "none", background: "white" }}
                            onClick={this.handleZoomOut}
                        >
                            <img src="./images/minus.svg" alt="ZoomOut" />
                        </button>
                        <input
                            style={{
                                textAlign: "center",
                                width: "56px",
                                height: "36px",
                                padding: "6px 8px",
                                borderRadius: "4px",
                                border: "1px solid #9DA5B4",
                                background: "#F4F7FB",
                            }}
                            value={`${this.state.zoomCount}%`}
                        />

                        <button
                            style={{ border: "none", background: "white" }}
                            onClick={this.handleZoomIn}
                        >
                            <img src="./images/plus.svg" alt="ZoomIn" />
                        </button>
                    </div>
                    <div
                        style={{
                            width: "36px",
                            transform: "rotate(90deg)",
                            border: "1px solid black",
                        }}
                    ></div>
                    <div
                        style={{
                            width: "88px",
                            height: "36px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <button
                            style={
                                this.props.isChecked === true
                                    ? { border: "none", background: "white" }
                                    : {
                                          cursor: "not-allowed",
                                          border: "none",
                                          background: "white",
                                      }
                            }
                            onClick={this.handleDownload}
                            disabled={this.props.isChecked ? false : true}
                        >
                            <img
                                src="./images/download-btn.svg"
                                alt="Download"
                            />
                        </button>
                        {/* <button
                            style={{
                                border: "none",
                                background: "white",
                                cursor: "not-allowed",
                            }}
                            onClick={this.shareFile}
                        >
                            <img src="./images/share-btn.svg" alt="share" />
                        </button> */}
                    </div>
                </div>
            </div>
        );
    };
    showAlertView = (title, body) => {
        this.setState({
            alertDialogShow: true,
            alertDialogContent: { title: title, message: body },
        });
    };
    setAlertDialogShow = (flag) => {
        this.setState({ alertDialogShow: flag });
    };

    render() {
        const { isModalOpen } = this.state;
        return (
            <div onContextMenu={(e) => e.preventDefault()}>
                <Modal
                    onClick={this.props.onclick ? this.handleClickModal : null}
                    {...this.props}
                    size={this.props.size || "lg"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={this.props.modalBodyClass || ""}
                    id={this.props.modalId}
                    onHide={this.closeModal || null}
                    dialogClassName="modal-90w"
                >
                    {this.renderHeader()}
                    <Modal.Body
                        style={{
                            background: "#EAEEF5",
                            // padding: "10px 200px 0px 200px",
                            padding: "10px",
                        }}
                    >
                        {this.props.children}
                    </Modal.Body>
                    {this.renderToolBar()}
                    {this.renderFooter()}
                </Modal>
                {/* {workSpaceId: 147608, spaceId: 147610, documentId: 2050291} */}
                {this.state.isModalOpen ? (
                    <AddDocuments
                        documentId={this.props.documentId}
                        setSelectedRow={this.setSelectedRow}
                        openAddInstanceModal={this.openAddInstanceModal}
                        values={this.props.selectedFileData}
                        showAlertView={this.showAlertView}
                        folderId={this.props.selectedFileData?.folderId}
                        showDocumentModal={this.state.isModalOpen}
                        closeModal={this.handleCloseModal}
                        module={"workSpace"}
                        handleSaveClick={this.handleSaveClick}
                        setAlertDialogShow={this.setAlertDialogShow}
                    />
                ) : null}
                {this.state.showAddInstanceModal ? (
                    <AddInstance
                        selectedGenericInstances={
                            this.state.selectedGenericInstances
                        }
                        selectedInstance={this.selectedInstance}
                        showAddInstanceModal={this.state.showAddInstanceModal}
                        closeModal={this.closeInstance}
                        currentFamilyTags={[]}
                        currentInstanceName={""}
                        heading={"Add New Instance"}
                        branchsId={"1"}
                        branchsName={"1"}
                        disabled={false}
                        editNewInstance={""}
                        addNewInstance={this.addNewInstance}
                    ></AddInstance>
                ) : null}
                <AlertDialog
                    open={this.state.alertDialogShow}
                    setOpen={this.setAlertDialogShow}
                    title={this.state.alertDialogContent.title}
                    message={this.state.alertDialogContent.message}
                    isAlert={this.state.alertDialogContent.isAlert}
                ></AlertDialog>
            </div>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        fetchFilterData,
        setSnackbarAction,
    };
};
const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(ModalView);
