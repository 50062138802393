import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import ControlTower from "../../../bae-client/ControlTower";
import AlertDialog from "../../stylized/AlertDialog";
import Loader from "../../stylized/Loader";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
}));
const formatDate = () => {
    var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};
const convertJSDateToExcelDate = (inDate) => {
    const retValue =
        25569 +
        (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
            (1000 * 60 * 60 * 24);
    return Number(retValue.toString().split(".")[0]);
};
function ControlTowerInstanceInfoTab({
    handleInstanceInfoData,
    updateInstanceInfoData,
    informationTblId,
    instanceBlockChainId,
    instanceId,
}) {
    const controlTower = new ControlTower();
    const classes = useStyles();
    const [showLoader,setShowLoader]=useState(false)
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [state, setState] = useState(
        updateInstanceInfoData.promiseQuantity
            ? updateInstanceInfoData
            : {
                  promiseQuantity: "",
                  actualQuantity: "",
                  promiseVessel: "",
                  actualVessel: "",
                  promiseDate: "",
                  actualDate: "",
              }
    );

    const onInputChange = (event) => {
        event.persist();
        setState((prevState) => {
            return {
                ...prevState,
                [event.target.name]:
                    event.target.name === "promiseDate" ||
                    event.target.name === "actualDate"
                        ? convertJSDateToExcelDate(new Date(event.target.value))
                        : event.target.value.toString(),
            };
        });
    };
    const submitInstanceInfo = () => {
        setShowLoader(false)
        controlTower
            .updateInstanceInfo(
                instanceBlockChainId,
                informationTblId,
                instanceId,
                state
            )
            .then((response) => {
                setState({
                    promiseQuantity: "",
                    actualQuantity: "",
                    promiseVessel: "",
                    actualVessel: "",
                    promiseDate: "",
                    actualDate: "",
                });
                setShowLoader(false)
            })
            .catch((error) => {
                console.log(error);
                setShowAlertDialog(true);
                setShowLoader(false)
                setAlertDialogMessage(error.error);
            });
    };
    useEffect(() => {
        handleInstanceInfoData(state);
    }, [state]);
    return (
        <div className="doCenter" style={{ flexDirection: "column" }}>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Promise Quantity"}</label>
                <input
                    autocomplete="off"
                    type="text"
                    id="promiseQuantity"
                    name="promiseQuantity"
                    value={state.promiseQuantity}
                    className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                    onChange={onInputChange}
                ></input>
            </div>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Actual Quantity"}</label>
                <input
                    autocomplete="off"
                    type="text"
                    id="actualQuantity"
                    name="actualQuantity"
                    value={state.actualQuantity}
                    className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                    onChange={onInputChange}
                ></input>
            </div>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Promised Vessel"}</label>
                <input
                    autocomplete="off"
                    type="text"
                    id="promiseVessel"
                    name="promiseVessel"
                    value={state.promiseVessel}
                    className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                    onChange={onInputChange}
                ></input>
            </div>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Actual Vessel"}</label>
                <input
                    autocomplete="off"
                    type="text"
                    id="actualVessel"
                    name="actualVessel"
                    value={state.actualVessel}
                    className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                    onChange={onInputChange}
                ></input>
            </div>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Promised Date"}</label>
                <TextField
                    id="promiseDate"
                    name="promiseDate"
                    type="date"
                    style={{
                        width: "250px",
                        border: "none",
                        boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    }}
                    classes={classes.textField}
                    onChangeCapture={onInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="marginTopTen doCenter">
                <label className="ctLabel">{"Actual Date"}</label>
                <TextField
                    id="actualDate"
                    name="actualDate"
                    type="date"
                    style={{
                        width: "250px",
                        border: "none",
                        boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    }}
                    classes={classes.textField}
                    onChangeCapture={onInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
                <Loader show={showLoader} />
            </div>
            {/* <div className="marginTopTen doCenter">
                <Button
                    id="submitButton"
                    label={"SUBMIT"}
                    onClick={submitInstanceInfo}
                    className="ctButton"
                >
                    {"SUBMIT"}
                </Button>
                <Button
                    id="closeButton"
                    label={"CLOSE"}
                    onClick={() => {}}
                    className="ctButton"
                >
                    {"CLOSE"}
                </Button>
            </div> */}
        </div>
    );
}

export default ControlTowerInstanceInfoTab;
