import React from "react";
import ModalView from "../../../../stylized/ModalView";

function RippleLogShareModal({ open, closeModal, handleShareBtnClickInModal }) {
    const handleSubmit = () => {
        handleShareBtnClickInModal();
    };
    const renderShareDocumentModal = () => {
        return (
            <div className="shareDiv">
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <span className="modalTitle">Share</span>

                    <input
                        type="button"
                        id="addWorkspaceModalBtn"
                        className="modalButton"
                        style={{
                            height: "40px",
                            marginTop: "45px",
                            marginLeft: "10px",
                        }}
                        value="SHARE"
                        onClick={handleSubmit}
                    ></input>
                </div>
            </div>
        );
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={open}
            size="md"
            title=""
            onHide={closeModal}
            onclick={closeModal}
            submitButtonText="Close"
        >
            {renderShareDocumentModal()}
        </ModalView>
    );
}
export default RippleLogShareModal;
