import { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import http from "../../../../../../../bae-client/HTTP";
import EmailAlertModal from "../../modals/EmailAlertModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AlertDialog from "../../../../../../stylized/AlertDialog";
import Loader from "../../../../../../stylized/Loader";
import CustomSnackbar from "../../../../../../stylized/CustomSnackbar/CustomSnackbar";
import EmptyConfigComponent from "../EmptyConfigComponent";

export default function EmailAlertConfig({ emailModal, setEmailModal }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const [emailConfigData, setEmailConfigData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [newRowToSubmit, setNewRowToSubmit] = useState({
        emailAlertConfigId: 0,
        userEmailAddress: "",
        genericName: "",
        alertTypes: [],
        stepName: "",
        from: "",
        to: "",
    });
    const [genericList, setGenericList] = useState([]);
    const [stepNameMap, setStepNameMap] = useState({});
    const [fromContactsMapData, setFromContactsMapData] = useState({});
    const [toContactsMapData, setToContactsMapData] = useState({});
    const [isRowEdit, setIsRowEdit] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const open = Boolean(anchorEl);

    useEffect(() => {
        setShowLoader(true);
        http.sendRestRequest("documentChain/objects").then((response) => {
            let genericNames = [];
            let genericObjList = [];
            const stepsMap = new Map();
            const fromContactsMap = new Map();
            const toContactsMap = new Map();
            let from = [];
            let to = [];
            let stepNames = [];
            response.forEach((gen) => {
                genericNames.push(gen.genericRegistry.genericDisplayName);
                genericObjList.push({
                    text: gen.genericRegistry.genericDisplayName,
                });
                stepNames.push(...gen.stepNames);
                stepsMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.stepNames.map((item) => {
                        return { text: item };
                    })
                );
                fromContactsMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.fromContacts.map((item) => {
                        return { text: item };
                    })
                );
                toContactsMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.toContacts.map((item) => {
                        return { text: item };
                    })
                );
                from.push(...gen.fromContacts);
                to.push(...gen.toContacts);
                genericNames = genericNames.sort((a, b) => a.localeCompare(b));
            });
            setStepNameMap(stepsMap);
            setFromContactsMapData(fromContactsMap);
            setToContactsMapData(toContactsMap);
            setGenericList(genericObjList);
            let fromContacts = from.map((item) => {
                return { text: item };
            });
            let toContacts = to.map((item) => {
                return { text: item };
            });
            getEmailAlertList();
        });
    }, []);
    const getEmailAlertList = () => {
        setShowLoader(true);
        let url = "emailAlertsConfig/list";
        http.sendRestRequest(url, "GET").then((resp) => {
            setEmailConfigData(resp);
            setShowLoader(false);
        });
    };
    const onChange = (e, key, rowId) => {
        console.log(e.target, key, rowId);
        if (rowId) {
            let cellToSubmit = {};
            cellToSubmit["emailAlertConfigId"] = rowId;
            cellToSubmit[key] = e.target.value;
            console.log(cellToSubmit);
        } else {
            let rowToSubmit = newRowToSubmit;
            rowToSubmit[key] = e.target.value;
            setNewRowToSubmit(rowToSubmit);
        }
    };

    const handleSubmit = async (payload, isRowEdit) => {
        try {
            if (!isRowEdit) {
                setShowLoader(true);
                http.sendRestRequest("emailAlertsConfig", "POST", payload).then(
                    () => {
                        setSnackbarMessage("Alert Created Successfully!");
                        setShowSnackbar(true);
                        setShowLoader(false);
                        getEmailAlertList();
                        setSelectedRowData(null);
                        setIsRowEdit(false);
                    }
                );
            } else {
                setShowLoader(true);
                http.sendRestRequest("emailAlertsConfig", "PUT", payload).then(
                    () => {
                        setSnackbarMessage("Alert Updated Successfully!");
                        setShowSnackbar(true);
                        setShowLoader(false);
                        getEmailAlertList();
                        setSelectedRowData(null);
                        setIsRowEdit(false);
                    }
                );
            }
        } catch (error) {
            setShowLoader(false);
            console.error("Error submitting data:", error);
        }
    };

    const handleEdit = (row) => {
        setSelectedRowData(row);
        setIsRowEdit(true);
        setEmailModal(true);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleOptionsClick = (event, row) => {
        setSelectedRowData(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    const deleteClickHandler = (e) => {
        setAnchorEl(null);
        setAlertDialogContent({
            title: "Delete Alert",
            message: "Are you sure you want to Delete the selected Alert?",
            isAlert: true,
            buttonName: "Delete",
            buttonId: "btn_delete_generic",
        });
        setAlertDialogShow(true);
    };
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const handleConfirmationDialog = (response, action) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            if (action === "DELETE") {
                setShowLoader(true);
                let url =
                    "emailAlertsConfig?emailAlertConfigId=" +
                    selectedRowData.emailAlertConfigId;
                http.sendRestRequest(url, "DELETE")
                    .then((resp) => {
                        if (resp === 200) {
                            setSnackbarMessage("Alert Deleted successfully!");
                            setShowSnackbar(true);
                            setShowLoader(false);
                            getEmailAlertList();
                            setSelectedRowData(null);
                            setIsRowEdit(false);
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err.error,
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        } else {
            setSelectedRowData(null);
            setIsRowEdit(false);
        }
    };
    const renderRows = (row, rowIndex) => {
        return (
            <TableRow key={rowIndex}>
                <TableCell title="edit">
                    <div style={{ margin: "auto", cursor: "pointer" }}>
                        <img
                            src="./images/pencil-fill.svg"
                            alt="edit"
                            onClick={(e) => handleEdit(row)}
                            rowId={row.emailAlertConfigId}
                        />
                    </div>
                </TableCell>
                {Object.keys(row)
                    .splice(1, 6)
                    .map((key, index) => (
                        <TableCell
                            title={
                                Array.isArray(row[key])
                                    ? row[key].join(", ")
                                    : row[key]
                            }
                            key={index}
                            style={{
                                maxWidth: "12rem",
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "-0.006em",
                                textAlign: "left",
                                color: "#4B5362",
                                cursor: "default",
                            }}
                        >
                            <div className="textEllipsis">
                                {Array.isArray(row[key])
                                    ? row[key].join(", ")
                                    : row[key]}
                            </div>
                        </TableCell>
                    ))}
                <TableCell title="option">
                    <div
                        style={{ margin: "auto", cursor: "pointer" }}
                        onClick={(event) => handleOptionsClick(event, row)}
                    >
                        <img src="./images/ellipsis.svg" alt="option" />
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            {emailConfigData.length > 0 ? (
                <>
                    <div
                        className="col-12 defaultScroller"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                        }}
                    >
                        <Paper
                            style={{
                                height: "calc(100% - 50px)",
                                position: "relative",
                            }}
                        >
                            <TableContainer
                                className="invisibleScroller spacelistTable"
                                style={{ padding: "0px", maxHeight: "100%" }}
                                id="email-alert-table"
                            >
                                <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    size="small"
                                >
                                    <TableHead
                                        className="table"
                                        style={{
                                            backgroundColor: "#EAEEF5",
                                            height: "56px",
                                            background: "#EAEEF5",
                                        }}
                                    >
                                        <TableRow>
                                            {[
                                                "Edit",
                                                "User Email Address",
                                                "Generic Name",
                                                "Exception Type",
                                                "Step Name",
                                                "From",
                                                "To",
                                                "Action",
                                            ].map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    title={column}
                                                    style={{
                                                        maxWidth: "12rem",
                                                        cursor: "default",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {emailConfigData
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, rowIndex) =>
                                                renderRows(row, rowIndex)
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={emailConfigData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            style={{
                                position: "absolute",
                                bottom: "0px",
                                right: "0px",
                            }}
                        />
                    </div>

                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={deleteClickHandler} id="deleteGen">
                            <div>
                                <img
                                    className="deleteIcon"
                                    src="./images/delete.svg"
                                    alt="Delete"
                                    title="Delete"
                                />
                                <span className="btn_title" title="Delete">
                                    Delete
                                </span>
                            </div>
                        </MenuItem>
                    </Menu>
                    <AlertDialog
                        open={alertDialogShow}
                        setOpen={setAlertDialogShow}
                        title={alertDialogContent.title}
                        handleConfirmationDialog={handleConfirmationDialog}
                        message={alertDialogContent.message}
                        isAlert={alertDialogContent.isAlert}
                        messageId={alertDialogContent.messageId}
                        buttonId={alertDialogContent.buttonId}
                        buttonName={alertDialogContent.buttonName}
                    ></AlertDialog>
                </>
            ) : (
                <EmptyConfigComponent
                    setEmailModal={setEmailModal}
                    value={"You haven’t created any email alerts yet"}
                />
            )}
            <EmailAlertModal
                setIsRowEdit={setIsRowEdit}
                setSelectedRowData={setSelectedRowData}
                setEmailModal={setEmailModal}
                emailModal={emailModal}
                isRowEdit={isRowEdit}
                editRowData={selectedRowData}
                genericList={genericList}
                alertTypeList={[
                    "DELAY",
                    "SKIPPED",
                    "KEY MISSING",
                    "KEY MISMATCH",
                    "PROACTIVE ALERT",
                    "DATE CHANGED",
                    "OTHERS",
                ]}
                stepNameMap={stepNameMap}
                toContactsMapData={toContactsMapData}
                fromContactsMapData={fromContactsMapData}
                handleSubmit={handleSubmit}
            />
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
        </>
    );
}
