import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {selectIsConnectedToRoom, useHMSStore, useHMSActions} from '@100mslive/react-sdk'
import Conference from './Conference/conference';
import * as HMSUtils from './HMSUtils/HMSUtils';
import http from "../../../../../../bae-client/HTTP";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "60%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 12,
    p: 4,
};

export default function Portal({ closePortal, roomId, setRoomId, selectedUsers }) {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
        closePortal();
    };

    const isConnected = useHMSStore(selectIsConnectedToRoom)
    const hmsActions = useHMSActions()

    const [name, setName] = React.useState("Ashapak");
    const [role, setRole] = React.useState('stage');

    
    React.useEffect(() => {
        if(roomId === "" || roomId === undefined) {
        http.sendRestRequest("communicator/mgmtToken", "GET").then((response) => {
            HMSUtils.createRoom("NewRoom6", response.ManagementToken)
            .then(room => {
                HMSUtils.GetToken(role, room.id)
                .then(token =>{
                    return hmsActions.join({
                        userName: name,
                        authToken: token
                    }).then((res)=>{
                        let toUserIds = selectedUsers.map((user) => user.id);
                        let dataObj = {
                            fromUserId : sessionStorage.userId,
                            toUserIds : toUserIds,
                            roomId : room.id
                        }
                        http.sendRestRequest("communicator/sendCallNotification", "POST", dataObj)
                        .then((response) => {
                        })
                    });
                })
                .catch(err => console.log("token error", err))
            });
        })
        
        } else {
            HMSUtils.GetToken(role, roomId)
            .then(token => {
                return hmsActions.join({
                    userName:"Pavan",
                    authToken: token
                }).then(()=> {
                    setRoomId("")
                })
            });
        }
        
    }, []);

    React.useEffect(() =>{
      window.onunload = () => {
        if(isConnected) hmsActions.leave()
      }
    }, [hmsActions, isConnected])

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
                <Box sx={style}>
                    <Conference handleModalClose={handleClose}></Conference>
                </Box>
            
            
        </Modal>
    );
}
