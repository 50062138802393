import React, { Component } from "react";
import { connect } from "react-redux";
import { setCollabInfo } from "../../../actions";
import Document from "../../../bae-client/Document";
import DocumentUploader from "../../../bae-client/DocumentUploader";
import http from "../../../bae-client/HTTP";
import config from "../../../config";
import AlertDialog from "../../stylized/AlertDialog";
import Button from "../../stylized/Button";
import Checkbox from "../../stylized/Checkbox";
import Dropdown from "../../stylized/Dropdown";
import Loader from "../../stylized/Loader";
class ControlTowerBlankForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            showAlertModal: false,
        };
        //for blankform
        this.newfilename = null;
        this.newparentname = null;
        this.newgenericsteptname = null;
        this.newinstancename = null;
        this.newgenericblockchainname = null;
        this.newfromdoc = null;
        this.checkedStatus = false;
        this.createInstancePayload = {};
        this.instanceIDS = {};
        this.refs = React.createRef();
        this.parentHeaderId = -1;
    }
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    setFromText = (value, id, event) => {
        switch (id) {
            case "newfilename":
                {
                    this.filename = value;
                }
                break;
            case "newparentname":
                {
                    this.parentname = value;
                }
                break;
            case "newgenericsteptname":
                {
                    this.genericsteptname = value;
                }
                break;
            case "newinstancename":
                {
                    this.instancename = value;
                }
                break;
            case "newgenericblockchainname":
                {
                    this.genericblockchainname = value;
                }
                break;
            case "newfromdoc":
                {
                    this.fromdoc = value;
                }
                break;
            default:
                break;
        }
    };
    computeGenericBlockchainNameList = () => {
        //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericList
        let url = "genericBlockchain/genericList";
        http.sendRestRequest(url)
            .then((response) => {
                this.genericBlockchainNameList = response;
                let items = [];
                this.genericBlockchainNameList.forEach((instance) => {
                    items.push(instance.genericTblName);
                });
                this.setState({ data: items });
            })
            .catch((error) => {
                this.showAlertView(
                    "",
                    error && error.error
                        ? error.path
                        : "Something went wrong, please try again!"
                );
                console.log(error);
            });
    };
    computeGenericStepID = (genericTblId) => {
        let promise = new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericSteps?genericTblId=2000356
            let url =
                "genericBlockchain/genericSteps?genericTblId=" + genericTblId;
            http.sendRestRequest(url)
                .then((response) => {
                    this.genericStepNameList = response;
                    let genericStepId = "-1";
                    this.genericStepNameList.forEach((step, id) => {
                        if (step.genericStepName === this.genericsteptname) {
                            genericStepId = step.genericStepId;
                        }
                    });
                    this.createInstancePayload["genericStepId"] = genericStepId;
                    resolve(genericTblId);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
        return promise;
    };
    computeInstanceID = (genericTblId) => {
        let promise = new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericInstances?genericTblId=2000356
            let url =
                "genericBlockchain/genericInstances?genericTblId=" +
                genericTblId;
            http.sendRestRequest(url)
                .then((response) => {
                    this.instanceIDList = response;
                    let instanceId = "-1";
                    this.createInstancePayload["instanceId"] = "-1";
                    if (this.instanceIDList.length !== 0) {
                        this.instanceIDList.forEach((step, id) => {
                            if (step.instanceName === this.instancename) {
                                instanceId = step.instanceId;
                                this.instanceId = step.instanceId;
                            }
                        });
                        this.createInstancePayload["instanceId"] = instanceId;
                        resolve(instanceId);
                    } else {
                        resolve(this.createInstancePayload["instanceId"]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
        return promise;
    };
    computeParentInstanceID = (instanceRegistryTblId, instanceId) => {
        let promise = new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/instance?instanceRegistryTblId=2000378&instanceId=8979
            let url =
                "instanceBlockchain/instance?instanceRegistryTblId=" +
                instanceRegistryTblId +
                "&instanceId=" +
                instanceId;
            http.sendRestRequest(url)
                .then((response) => {
                    this.instanceObjects = response;
                    if (
                        this.instanceObjects !== undefined &&
                        this.instanceObjects !== null
                    ) {
                        this.instanceObjects.forEach((instance, id) => {
                            if (
                                instance.instanceBlockDocumentName ===
                                this.parentname
                            ) {
                                resolve(instance.instanceBlockchainId);
                            }
                        });
                    } else {
                        resolve("-1");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
        return promise;
    };
    componentDidMount = () => {
        this.computeGenericBlockchainNameList();
    };

    setFormData = () => {
        let promise = new Promise((resolve, reject) => {
            let genericTblId = null;
            this.genericBlockchainNameList.forEach((instance, id) => {
                if (instance.genericTblName === this.genericblockchainname) {
                    genericTblId = instance.genericTblId;
                    this.instanceIDS["instanceBlockRegistryTblId"] =
                        instance.instanceBlockRegistryTblId;
                    this.instanceIDS["instanceBlockchainTblId"] =
                        instance.instanceBlockchainTblId;
                    this.instanceIDS["instanceRegistryTblId"] =
                        instance.instanceRegistryTblId;
                }
            });
            if (
                genericTblId !== null &&
                this.instanceIDS.instanceBlockRegistryTblId > 0 &&
                this.instanceIDS.instanceBlockchainTblId > 0 &&
                this.instanceIDS.instanceRegistryTblId > 0
            ) {
                this.computeGenericStepID(genericTblId)
                    .then((genericTblId) => {
                        this.computeInstanceID(genericTblId)
                            .then((instanceId) => {
                                if (
                                    this.checkedStatus !== true &&
                                    instanceId > 0
                                ) {
                                    this.computeParentInstanceID(
                                        this.instanceIDS.instanceRegistryTblId,
                                        instanceId
                                    )
                                        .then((instanceBlockchainId) => {
                                            this.createInstancePayload[
                                                "parentInstanceBlockchainId"
                                            ] = instanceBlockchainId;
                                            this.uploadFileInFileArray()
                                                .then((dataModal) => {
                                                    this.createInstancePayload[
                                                        "instanceBlockDocumentId"
                                                    ] =
                                                        dataModal[0].documentCuboidId;
                                                    resolve(
                                                        this
                                                            .createInstancePayload
                                                    );
                                                })
                                                .catch((error) => {
                                                    reject("Faild to submit");
                                                    console.log(error);
                                                });
                                        })
                                        .catch((error) => {
                                            this.showAlertView(
                                                "",
                                                error && error.error
                                                    ? error.path
                                                    : "Something went wrong, please try again!"
                                            );
                                            console.log(error);
                                        });
                                } else if (
                                    this.checkedStatus === true &&
                                    instanceId > 0
                                ) {
                                    this.showAlertView(
                                        "",
                                        "Instance exists already"
                                    );
                                    reject("Instance exists already");
                                } else {
                                    this.createInstancePayload["instanceName"] =
                                        this.instancename;
                                    this.createInstancePayload[
                                        "parentInstanceBlockchainId"
                                    ] = "0";
                                    this.uploadFileInFileArray()
                                        .then((dataModal) => {
                                            this.createInstancePayload[
                                                "instanceBlockDocumentId"
                                            ] = dataModal[0].documentCuboidId;
                                            resolve(this.createInstancePayload);
                                        })
                                        .catch((error) => {
                                            reject("Faild to submit");
                                            console.log(error);
                                        });
                                }
                            })
                            .catch((error) => {
                                this.showAlertView(
                                    "",
                                    error && error.error
                                        ? error.path
                                        : "Something went wrong, please try again!"
                                );
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        this.showAlertView(
                            "",
                            error && error.error
                                ? error.path
                                : "Something went wrong, please try again!"
                        );
                        console.log(error);
                    });
            } else {
                this.showAlertView(
                    "",
                    "Invalid generic blockchain name or Invalid instance cuboid ids"
                );
                reject(
                    "Invalid generic blockchain name or Invalid instance cuboid ids"
                );
            }
        });
        return promise;
    };
    createNewInstance = () => {
        this.setFormData()
            .then((data) => {
                this.createInstancePayload["from"] = this.fromdoc;
                //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/object?instanceBlockchainTblId=2000380
                let url =
                    "instanceBlockchain/object?instanceBlockchainTblId=" +
                    this.instanceIDS.instanceBlockchainTblId;
                let method = "POST";
                http.sendRestRequest(url, method, this.createInstancePayload)
                    .then((response) => {
                        if (response.instanceBlockchainId !== 0) {
                            //(response.instanceId !== 0 && response.instanceName !== null)
                            this.showAlertView("", "Document uploaded");
                        } else if (response.instanceBlockchainId === 0) {
                            this.showAlertView(
                                "",
                                "Invalid cuboid id and/or row id"
                            );
                            return;
                        }
                    })
                    .catch((error) => {
                        this.showAlertView(
                            "",
                            error && error.error
                                ? error.path
                                : "Something went wrong, please try again!"
                        );
                        console.error(error);
                    });
            })
            .catch((error) => {
                this.showAlertView(
                    "",
                    error && error.error
                        ? error.path
                        : "Something went wrong, please try again!"
                );
                console.error(error);
            });
    };
    handleInstanceCheckUncheck = (value, id, event) => {
        switch (id) {
            case "newInstance":
                {
                    this.checkedStatus = !this.checkedStatus;
                    document.getElementById("newparentname").disabled =
                        this.checkedStatus;
                    if (this.checkedStatus) {
                        this.newparentname = null;
                    }
                }
                break;
            default:
                break;
        }
    };
    handleModalSubmit = (documentsArray) => {
        this.setState({ showLoader: true });
        let promise = new Promise((resolve, reject) => {
            let flagForFile = false;
            let existingFileName;
            this.setState({ showLoader: true });
            let body = {
                from: this.formText,
                to: this.toText,
                documentType: this.documnetText,
                date: this.dateText,
                time: this.timeText,
                location: this.locationText,
                reader: this.readerText,
            };
            let option = {
                collabId: this.sourceCollabId,
                wbId: this.sourceWBId,
                collabName: this.sourceCollabName,
                wbname: this.sourceWBName,
            };
            let documnentsArrayToUpload = [];
            let uploadeingDocIndex = [];
            let documnentsArray = documentsArray;
            for (let i = 0; i < documnentsArray.length; i++) {
                let docManager = documnentsArray[i];
                let existingId = this.props.collabInfo.getCuboidIdByName(
                    this.sourceCollabId,
                    this.sourceWBId,
                    docManager.data.files.name
                );
                if (existingId > -1) {
                    existingFileName = docManager.data.files.name;
                    flagForFile = true;
                }
                docManager.setCollabId(option);
                docManager.setWbId(option);
                docManager.setParent(this.parentHeaderId);
                docManager.setMetaData(body);
                docManager.setDocumentName(docManager.data.files.name);
                docManager.setHeaderDocumentName(docManager.data.files.name);

                uploadeingDocIndex.push(i); //push indexes of chosen document
                documnentsArrayToUpload.push(docManager);
            }
            if (flagForFile === true) {
                this.showAlertView(
                    "",
                    "File with this name " + existingFileName + " already exist"
                );
                this.setState({ showLoader: false });
            } else {
                if (documnentsArrayToUpload.length > 0) {
                    let docUploader = new DocumentUploader();
                    docUploader
                        .uploadDocuments(
                            documnentsArrayToUpload,
                            this.sourceWBId
                        )
                        .then(this.props.ondocverified)
                        .then((dataModal) => {
                            this.setState({ showLoader: false });
                            if (
                                dataModal !== null &&
                                dataModal[0].documentCuboidId !== null
                            ) {
                                this.responseDataModal = dataModal;
                                resolve(dataModal);
                            } else {
                                this.showAlertView(
                                    "",
                                    "Faild to submit. \n Please submit document with unique name"
                                );
                                reject("Faild to submit");
                            }
                        })
                        .catch((errResp) => {
                            this.showAlertView(
                                "",
                                errResp && errResp.error
                                    ? errResp.path
                                    : "Something went wrong, please try again!"
                            );
                            console.log("error in new file nav modal", errResp);
                        });
                } else {
                    this.showAlertView(
                        "",
                        "This File is already linked OR No File is selected"
                    );
                    this.setState({ showLoader: false });
                }
            }
        });
        return promise;
    };
    verifyDocument = (fileName) => {
        //  chcking document name is alerady in server
        let isDocumentVerified = false;
        let existingId = this.props.collabInfo.getCuboidIdByName(
            this.sourceCollabId,
            this.sourceWBId,
            fileName
        );
        if (existingId > -1) {
            isDocumentVerified = false;
        } else {
            isDocumentVerified = true;
        }
        return isDocumentVerified;
    };
    uploadFileInFileArray() {
        let promise = new Promise((resolve, reject) => {
            let documentsArray = [];
            this.sourceCollabName = config.documentCollaborationName;
            this.sourceCollabId = this.props.collabInfo.getCollabIdByName(
                this.sourceCollabName
            );
            this.sourceWBName = this.genericsteptname;
            if (this.sourceCollabId < 0) {
                this.showAlertView("", "Given Collaboration is not found");
                return;
            }
            this.sourceWBId = this.props.collabInfo.getWBIdByName(
                this.sourceCollabId,
                this.sourceWBName
            );
            if (this.sourceWBId < 0) {
                this.sourceWBId = this.props.collabInfo.getWBIdByName(
                    this.sourceCollabId,
                    config.documentWhiteboardName
                );
            }

            if (this.sourceWBId !== -1 && this.sourceCollabId !== -1) {
                let documentsArray = [];
                if (this.chosenFile !== undefined) {
                    for (let i = 0; i < this.chosenFile.length; i++) {
                        let temp = this.verifyDocument(this.chosenFile[i].name);
                        if (temp === true) {
                            let option = {
                                files: this.chosenFile[i],
                                isChoosen: true,
                            };
                            let docManager = new Document();
                            docManager.setData(option);
                            documentsArray.push(docManager);
                            this.handleModalSubmit(documentsArray)
                                .then((dataModal) => {
                                    resolve(dataModal);
                                })
                                .catch((error) => {
                                    reject("Faild to submit");
                                    console.log(error);
                                    this.showAlertView(
                                        "",
                                        error && error.error
                                            ? error.path
                                            : "Something went wrong, please try again!"
                                    );
                                });
                        } else {
                            this.showAlertView(
                                "",
                                "This document already exists"
                            );
                        }
                    }
                } else {
                    this.showAlertView("", "Please choose a File to upload.");
                    return;
                }
            } else {
                this.showAlertView(
                    "",
                    "collaboration not found.contact to server admin"
                );
            }
        });
        return promise;
    }
    onChangeFileUpload = (event) => {
        let formFileData = event.target.files;
        this.chosenFile = formFileData;
        //this.uploadFileInFileArray();
    };
    handleSelect = (value, id, event) => {
        this.showAlertView("", value);
    };

    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    renderForm = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <div className="marginTopTen">
                    <label className="ctLabel">File Name</label>
                    {/* <input type="text" id='newfilename' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.newfilename} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input> */}
                    <input
                        type="file"
                        id="file"
                        ref={"fileUploder"}
                        style={{ marginLeft: "30px" }} //hidden={true} multiple
                        onChange={this.onChangeFileUpload}
                    />
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Blockchain Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="newgenericblockchainname"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.newgenericblockchainname}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                    <Dropdown
                        dropdownID={"gen"}
                        dropdownName={"generic"}
                        items={this.state.data}
                        handleChanges={this.handleSelect}
                    ></Dropdown>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Step Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="newgenericsteptname"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.newgenericsteptname}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="newinstancename"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.newinstancename}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div
                    className="marginTopTen"
                    style={{ display: "flex", flexDirection: "row" }}
                >
                    <Checkbox
                        id="newInstance"
                        onclick={this.handleInstanceCheckUncheck}
                    ></Checkbox>
                    <label
                        className="ctLabel"
                        style={{ marginTop: "0.5rem", width: "130px" }}
                    >
                        New Instance
                    </label>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Parent Name</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="newparentname"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.newparentname}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">From</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="newfromdoc"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.newfromdoc}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen doCenter">
                    <Button
                        id="submitButton"
                        label={"SUBMIT"}
                        onclick={this.createNewInstance}
                        className="ctButton"
                    ></Button>
                    <Button
                        id="refreshButton"
                        label={"REFRESH"}
                        onclick={this.updateUserProfile}
                        className="ctButton"
                    ></Button>
                    <Button
                        id="refreshButton"
                        label={"BACK"}
                        onclick={this.props.onclick}
                        className="ctButton"
                    ></Button>
                </div>
            </div>
        );
    };
    render() {
        return (
            <>
                {this.renderForm()}
                {this.renderAlertModal()}
                <Loader show={this.state.showLoader}/>
            </>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        setCollabInfo,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(ControlTowerBlankForm);
