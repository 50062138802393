import React, { Component } from 'react'
import TitleBar from './TiltleBar';

class Tutorial extends Component {

    renderTitle = () => {
        return (
            <TitleBar username={this.props.username} title={"Get Started"}></TitleBar>
        );
    }
    render() {
        return (
            <div className="col-10 defaultScroller">
                <div className='container w-100 h-100 pl-0 pr-0'>
                    {this.renderTitle()}
                </div>
            </div>
        )
    }
}

export default Tutorial