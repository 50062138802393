import React from "react";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";
import Button from "@material-ui/core/Button";

export default function AddSpaceModal({
    handleAddSpaceClick,
    addSpaceModalShow,
    closeAddSpaceModal,
    setText,
    addNewSpace,
    error,
}) {
    return (
        <ModalView
            onSubmit={addNewSpace}
            id="addWorkspaceModalBtn"
            value="ADD SPACE"
            title="Add Space"
            show={addSpaceModalShow}
            onHide={closeAddSpaceModal}
            footer={"false"}
            type="submit-cancel"
            size="md"
            closeBtnId={"btn_addspace_close"}
            modalId={"modal_addspace"}
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                {/* <img src="./images/Space 2.svg"></img> */}
                <div className="addWorkspaceModal addspace">Add New Space</div>

                <TextBox
                    id="spacename"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onchange={setText}
                    label="Name your space..."
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {error && (
                <>
                    <small
                        id="small_addSpace_errorMessage"
                        style={{ color: "red", paddingLeft: "18px" }}
                    >
                        {error}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
