import React, { useEffect, useState } from "react";

export default function CallAlert({open, handleAccept, handleDecline}) {
    return (
        open ? <div style={{
            dispaly:"inline-block", 
            width:"250px", 
            height:"200px", 
            backgroundColor:"#DCF0FF", 
            position:"absolute", 
            right:"20px", 
            bottom:"20px", 
            zIndex:"10000",
            alignItems:"center",
            borderRadius:"5%"
            }}>
            <div style={{
                marginLeft:"12px",
                marginTop:"100px"
            }}>
            <button onClick={handleAccept} className="btn addAlertBtn">Accept</button>
            <button onClick={handleDecline} className="btn btn-outlined" style={{border:"2px solid rgba(0, 0, 0, 0.23)", borderRadius:"10px"}}>Decline</button>
            </div>
        </div> : null
    );
}