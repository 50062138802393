import React from "react";

const EmptyConfigComponent = ({ value, setEmailModal }) => {
    return (
        <div className="emptyDiv">
            <span>{value}</span>
            <div
                style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button
                    onClick={() => setEmailModal(true)}
                    className="create-new-button"
                    title="Create New"
                    id={"createNew"}
                    style={{
                        margin: "0rem",
                        marginLeft: "10px",
                        textTransform: "capitalize",
                    }}
                >
                    {/* <div
                        style={{
                            fontSize: "xx-large",
                        }}
                    >
                        +
                    </div> */}
                    Create New
                </button>
            </div>
        </div>
    );
};

export default EmptyConfigComponent;
