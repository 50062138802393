import React, { Component } from 'react'
import Button from '../../stylized/Button';
import { Tabs, Tab } from 'react-bootstrap';
import InstanceTab from './InstanceTab';
import GenericTab from './GenericTab';
import GeneralTab from './GeneralTab';
class ControlTowerTabs extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    renderForm1 = () => {
        return (
            <div className="doCenter" style={{ flexDirection: 'column' }}>
                <div className="marginTopTen">
                    <label className="ctLabel">File Name</label>
                    <input type="text" id='filename' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.filename} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Parent Name</label>
                    <input type="text" id='parentname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.parentname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Step Name</label>
                    <input type="text" id='genericsteptname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.genericsteptname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Instance Name</label>
                    <input type="text" id='instanceame' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.instanceame} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">Generic Blockchain Name</label>
                    <input type="text" id='genericblockchaintname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.genericblockchaintname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <input type="text" id='fromdoc' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={'To'} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                    <input type="text" id='fromdoc' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={'From'} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen">
                    <input type="text" id='fromdoc' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={'Location'} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                    <input type="text" id='fromdoc' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={'Date / Time'} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input>
                </div>
                <div className="marginTopTen doCenter">
                    <Button id="submitButton" label={"SUBMIT"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"REFRESH"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"BACK"} onclick={this.props.onclick} className="ctButton"></Button>
                </div>
            </div >
        );
    }
    renderGeneralTab = () => {
        return (
            <GeneralTab response={this.props.response}></GeneralTab>
        );
    }
    renderInstanceTab = () => {
        return (
            <InstanceTab response={this.props.response}></InstanceTab>
        );
    }
    renderGenericTab = () => {
        return (
            <GenericTab response={this.props.response}></GenericTab>
        );
    }
    renderTabs = () => {
        return (
            <div className="row doCenter">
                <div className="col-10 ml-4 mr-4">
                    <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
                        <Tab eventKey="general" title="General">
                            {this.renderGeneralTab()}
                        </Tab>
                        <Tab eventKey="instance" title="Instance">
                            {this.renderInstanceTab()}
                        </Tab>
                        <Tab eventKey="generic" title="Generic">
                            {this.renderGenericTab()}
                        </Tab>
                        <Tab eventKey="tags" title="Tags">

                        </Tab>
                        <Tab eventKey="properties" title="Properties">

                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
    render() {
        return (
            <>
                {this.renderTabs()}
            </>
        );
    }
}

export default ControlTowerTabs
