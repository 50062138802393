import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Contact from "./contact";
import { setUnreadChatCount } from "../../../../../../actions/CommunicatorActions";

export default function ContactList({
    contactList,
    handleChatSelection,
    selectedContactIndex,
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (contactList && contactList.length) {
            const unreadCount = contactList.filter(
                (message) => message.isRead === 0
            ).length;
            dispatch(setUnreadChatCount(unreadCount));
        }
    }, [contactList]);

    const handleSelectedCard = (chatDetails, chat, contactIndex) => {
        handleChatSelection(chatDetails, chat, contactIndex);
    };

    return (
        <div
            id={"communicatorChatList"}
            style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
            }}
        >
            {contactList && contactList.length
                ? contactList.map((contact, index) => (
                      <Contact
                          isContactSelected={
                              index === selectedContactIndex ? true : false
                          }
                          contactIndex={index}
                          chat={contact}
                          handleSelectedChat={handleSelectedCard}
                      ></Contact>
                  ))
                : null}
        </div>
    );
}
