import React, { useState, useEffect } from "react";
import WorkspaceCardItemView from "./WorkspaceCardItemView";
import "./WorkspaceCardItemView.css";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../bae-client/HTTP";
import RenameWorkspace from "./ContentPane/settings/renameWorkspace";
import AlertDialog from "../../stylized/AlertDialog";
import CustomSnackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import {
    fetchWorkspaceListAction,
    setSnackbarAction,
} from "../../../services/services/app";
import Loader from "../../stylized/Loader";
import { fetchHierarchyResponse } from "../../../actions/HierarchyActions";

const WorkspaceSettings = () => {
    const [workspace, setWorkspace] = useState(null);
    const [workspaces, setWorkspaces] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonName: "",
        buttonId: "",
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);

    useEffect(() => {
        if (workspaceList && workspaceList.length) {
            setWorkspaces(
                workspaceList.map((workspace) =>
                    workspace.workspaceName.toLowerCase().trim()
                )
            );
        }
    }, [workspaceList]);
    const handleCloseAlert = () => {
        setShowAlertDialog(false);
    };
    const handleCloseModal = () => setModalShow(false);
    const submitRenameWorkspace = (workspaceName, workspaceId) => {
        setModalShow(false);
        let url = "workspace/workspace";
        const data = {
            workspaceId: workspaceId,
            workspaceName: workspaceName.trim(),
        };
        setLoading(true);
        http.sendRestRequest(url, "PUT", data)
            .then((response) => {
                Mixpanel.callWorkspaceManagementEvent("Rename");
                setSnackbarMessage("Workspace has been renamed successfully");
                setShowSnackbar(true);
                dispatch(fetchWorkspaceListAction());
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const renameWorkspaceHandler = (workspace) => {
        setSnackbarMessage("");
        setWorkspace(workspace);
        setModalShow(true);
    };
    const deleteWorkspaceHandler = (workspace) => {
        setWorkspace(workspace);
        setAlertDialogContent({
            title: "Delete Workspace",
            message: "Are you sure you want to delete this workspace?",
            isAlert: true,
            messageId: "workspaceDeleteMsg",
            buttonName: "Delete",
            buttonId: "workspaceDeleteBtn",
        });
        setAlertDialogShow(true);
    };
    const handleConfirmationDialog = (response) => {
        setSnackbarMessage("");
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES" && workspace && workspace.workspaceId) {
            let url = "workspace/workspace";
            const data = {
                workspaceId: workspace.workspaceId,
            };
            setLoading(true);
            http.sendRestRequest(url, "DELETE", data)
                .then((response) => {
                    Mixpanel.callWorkspaceManagementEvent("Delete");
                    setSnackbarMessage(
                        "Workspace has been deleted successfully"
                    );
                    setShowSnackbar(true);
                    dispatch(fetchWorkspaceListAction());
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                });
        }
    };
    const renderWorkspaceItems = () => {
        if (workspaceList !== undefined && workspaceList.length > 0) {
            let workspaceListArray = [];
            workspaceListArray = workspaceList.map((workspaceItem) => (
                <WorkspaceCardItemView
                    key={workspaceItem.workspaceId}
                    workspaceData={workspaceItem}
                    renameWorkspace={renameWorkspaceHandler}
                    deleteWorkspace={deleteWorkspaceHandler}
                ></WorkspaceCardItemView>
            ));
            return workspaceListArray;
        } else return <div> no workspaces found</div>;
    };

    return (
        <>
            <div
                className="wsCardContainer invisibleScroller"
                style={{ paddingTop: "20px" }}
            >
                {renderWorkspaceItems()}
            </div>
            <RenameWorkspace
                modalShow={modalShow}
                handleCloseModal={handleCloseModal}
                submitRenameWorkspace={submitRenameWorkspace}
                workspace={workspace}
                workspaces={workspaces}
            ></RenameWorkspace>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={handleCloseAlert}
            ></AlertDialog>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <Loader loading={loading} />
        </>
    );
};

export default WorkspaceSettings;
