import React from "react";

export const EmptyHomeScreen = ({ heading, image, subTitle }) => {
    return (
        <div className="home-screen-empty">
            <h2>{heading}</h2>
            <img src={image} alt="" />
            <h6>{subTitle}</h6>
        </div>
    );
};
