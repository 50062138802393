import React, { useState } from "react";
import DashboardReportConfig from "./dashboard-report-email-conf/index";
import EmptyConfigComponent from "./EmptyConfigComponent";
import EmailAlertConfig from "./email-alert-config/index";
import "./style.css";

export default function FlowSettings({
    config,
    emailConfigurationCuboidId,
    dashboardReportRuleConfCuboidId,
}) {
    const [emailModal, setEmailModal] = useState(false);
    const [dashboardModal, setDashboardModal] = useState(false);
    const [activeTab, setActiveTab] = useState("email");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const renderTabContent = () => {
        if (activeTab === "email") {
            return (
                <EmailAlertConfig
                    emailModal={emailModal}
                    setEmailModal={setEmailModal}
                />
            );
        } else if (activeTab === "contact") {
            return (
                <DashboardReportConfig
                    emailModal={dashboardModal}
                    setEmailModal={setDashboardModal}
                />
            );
        }
        return null;
    };

    return (
        <div className="main_div">
            <div className="heading-setting">
                <h1>
                    {activeTab === "email"
                        ? "Email Configuration"
                        : activeTab === "contact"
                        ? "Dashboard Report Email"
                        : null}
                </h1>
            </div>

            <div className="wrapper-div">
                <div className="tabs-container">
                    <button
                        className={`tab-button ${
                            activeTab === "email" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("email")}
                        style={{ borderRadius: "4px 0px 0px 4px" }}
                        title={"Email Alert"}
                    >
                        Email Alert
                    </button>
                    <button
                        className={`tab-button ${
                            activeTab === "contact" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("contact")}
                        style={{ borderRadius: "0px 4px 4px 0px" }}
                        title={"Dashboard Report"}
                    >
                        Dashboard Report
                    </button>
                </div>
                <div>
                    <button
                        className="create-new-button"
                        title="Create New"
                        id={"createNew"}
                        style={{
                            margin: "0rem",
                            marginLeft: "10px",
                            textTransform: "capitalize",
                        }}
                        onClick={
                            activeTab === "email"
                                ? () => setEmailModal(true)
                                : activeTab === "contact"
                                ? () => setDashboardModal(true)
                                : null
                        }
                    >
                        {/* <div
                            style={{
                                fontSize: "xx-large",
                            }}
                        >
                            +
                        </div> */}
                        Create New
                    </button>
                </div>
            </div>
            <div
                className="tab-content-container"
                style={
                    (config.showLeftPaneDocumentChain &&
                        emailConfigurationCuboidId) ||
                    (config.showLeftPaneDocumentChain &&
                        dashboardReportRuleConfCuboidId)
                        ? null
                        : { height: "65vh", padding: "120px 80px" }
                }
            >
                {renderTabContent()}
            </div>
        </div>
    );
}
