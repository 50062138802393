import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setActivePage,
    setBoardList,
    setActiveWorkspace,
    setActiveSpace,
} from "../../../actions";
import Space from "../../../bae-client/Space";
import {
    setFeedItemToRedirect,
    reloadInformationFeed,
} from "../../../actions/InformationFeedActions";
import { fetchSpaceListData } from "../../../actions/SpaceActions";
import { setFeedItems } from "../../../actions/InformationFeedActions";
import InfoFeedItem from "../../stylized/InfoFeedItem";
import AlertDialog from "../../stylized/AlertDialog";
import "./informationFeed.css";
import { TablePagination } from "@material-ui/core";
import http from "../../../bae-client/HTTP";
import Loader from "../../stylized/Loader";
export class InformationFeed extends Component {
    constructor(props) {
        super(props);
        this.infoFeedData = [];
        this.state = {
            defualtData: [],
            page: 0,
            rowsPerPage: 10,
            mails: null,
            disableRefresh: false,
            showAlertModal: false,
            showLoader: false,
        };
    }
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    componentDidMount() {
        this.infoFeedData = [
            {
                source: "./images/outlook-icon.svg",
                contentText:
                    "Connect Microsoft Outlook to your information feed",
                from: "john.doe@cpfd.com",
                attachment: true,
                status: "true",
                accountType: "Outlook",
                title: "Integrate Outlook",
            },
            {
                source: "./images/Gmail_icon.png",
                contentText: "Connect Gmail to your information feed",
                from: "john.doe@dla.gov",
                attachment: true,
                status: "true",
                accountType: "Gmail",
                title: "Integrate Gmail",
            },
        ];
        if (this.props.existingIntegrationList.length > 0) {
            this.props.existingIntegrationList.forEach((integrationType) => {
                this.infoFeedData.forEach((element, index) => {
                    if (
                        integrationType.toLowerCase() ===
                        element.accountType.toLowerCase()
                    ) {
                        this.infoFeedData.splice(index, 1);
                    }
                });
            });
            this.setState({ defualtData: this.infoFeedData });
        }

        this.fetchLatestMailsFromGmailAndOutlookToBWServer().then(() => {
            this.getEmailsFromBWServer();
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.existingIntegrationList !==
            this.props.existingIntegrationList
        ) {
            if (this.props.existingIntegrationList.length > 0) {
                this.props.existingIntegrationList.forEach(
                    (integrationType) => {
                        this.infoFeedData.forEach((element, index) => {
                            if (
                                integrationType.toLowerCase() ===
                                element.accountType.toLowerCase()
                            ) {
                                this.infoFeedData.splice(index, 1);
                            }
                        });
                    }
                );
                this.setState({ defualtData: this.infoFeedData });
            }
        }
        if (this.props.newMailNotification) {
            this.getEmailsFromBWServer(10, 0);
        }
    }

    //Author - Ashapak Patavegar
    //Task - To fetch latest emails from Gmail and Outlook server to BW server.
    fetchLatestMailsFromGmailAndOutlookToBWServer() {
        this.setState({ disableRefresh: true, showLoader: true });
        let promise = new Promise((resolve, reject) => {
            const url = "emailRuleConfig/refreshEmails";
            http.sendRestRequest(url)
                .then((response) => {
                    //latest mails fetched from gmail and outlook server to bw server.
                    resolve(response);
                    this.setState({ disableRefresh: false, showLoader: false });
                })
                .catch((error) => {
                    this.setState({ showLoader: false });
                    this.showAlertView("", error.error);
                    console.log(error);
                });
        });
        return promise;
    }

    //Author - Ashapak Patavegar
    //Task - Get emails from BW server
    getEmailsFromBWServer(rowsPerPage, pageNo) {
        this.setState({ disableRefresh: true });
        let pageSize = rowsPerPage ? rowsPerPage : this.state.rowsPerPage;
        let page = pageNo !== undefined ? pageNo : this.state.page;
        const url =
            "emailRuleConfig/emails?pageNo=" + page + "&pageSize=" + pageSize;
        http.sendRestRequest(url)
            .then((response) => {
                this.setState({
                    mails: response,
                    disableRefresh: false,
                });
                this.props.reloadInformationFeed(false);
            })
            .catch((error) => {
                this.showAlertView("", error.error);
                console.log(error);
            });
    }

    renderMailFolder = (item) => {
        const workspaceId = item.workspaceId;
        const spaceId = item.spaceId;
        this.props.setFeedItemToRedirect(item);
        this.setActiveWorkspace(workspaceId, spaceId, item?.workspaceName);
    };
    setActiveWorkspace = (workspaceId, spaceId, workspaceName) => {
        this.setState({ showLoader: true });
        let activeSpace = null;
        this.props
            .fetchSpaceListData(workspaceId)
            .then((data) => {
                let activeWSObject = {};
                activeWSObject["activeWorkspaceID"] = workspaceId;
                activeWSObject["activeWorkspaceName"] = workspaceName;
                activeWSObject["spaceList"] = this.props.spaceListData;
                let spaceList = this.props.spaceListData;
                let spaceArray = [];
                spaceList.forEach((item, i) => {
                    let spaceInstance = new Space();
                    let options = {};
                    options["spaceName"] = item.spaceName; //spaceNames[i]
                    options["spaceId"] = item.spaceId; //spaceIDs[i]
                    options["archive"] = item.archive;
                    spaceInstance.setData(options);
                    if (spaceId === item.spaceId) {
                        activeSpace = options;
                        // selectedSpaceIndex = i;
                    }
                    spaceArray.push(spaceInstance);
                });
                this.props.setActiveWorkspace(activeWSObject);
                this.props.setBoardList(spaceArray);
                // this.props.setActiveSpace(null);
                this.props.setActiveSpace(activeSpace);
                this.props.setActivePage("Workspace");
                this.setState({ showLoader: false });
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                console.log(err);
                this.setState({ showLoader: false });
            });
    };

    handleRefreshClick = () => {
        this.setState({
            page: 0,
            rowsPerPage: 10,
        });
        this.fetchLatestMailsFromGmailAndOutlookToBWServer().then(() => {
            this.getEmailsFromBWServer(10, 0);
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        this.props.fetchData(newPage, this.state.rowsPerPage);
        this.getEmailsFromBWServer(this.state.rowsPerPage, newPage);
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };

    handleChangeRowsPerPage = (event) => {
        let rowsPerPage = event.target.value;
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        });
        this.props.fetchData(0, parseInt(event.target.value, 10));
        this.getEmailsFromBWServer(rowsPerPage);
    };

    renderItems = () => {
        let isStatic = true;
        let data =
            this.props.informationFeedItems !== undefined &&
            this.props.informationFeedItems.length > 0
                ? this.props.informationFeedItems
                : this.infoFeedData;
        isStatic =
            this.state.mails &&
            this.state.mails.emails !== undefined &&
            this.state.mails.emails.length > 0
                ? false
                : true;

        let items = [];
        if (
            this.state.mails &&
            this.state.mails.emails &&
            this.state.mails.emails.length > 0
        ) {
            this.state.mails.emails.forEach((item, index) => {
                items.push(
                    <div
                        key={index}
                        className="row m-0 w-100"
                        style={{ maxHeight: "10rem" }}
                    >
                        <div className="col-1 p-0 doCenter">
                            <img
                                className={
                                    item.status === "false"
                                        ? "notificationDotHide"
                                        : ""
                                }
                                src="./images/Ellipse 50.png"
                                alt=""
                            />
                        </div>
                        <div
                            className="col-11 p-0"
                            onClick={
                                isStatic
                                    ? this.changeCurruntPageToIntegrations
                                    : () => this.renderMailFolder(item)
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <InfoFeedItem
                                type={item.accountType}
                                title={
                                    item.title !== undefined
                                        ? item.title
                                        : item.accountType
                                }
                                contentText={this.renderIntegrationBody(
                                    item,
                                    item.accountType
                                )}
                                attachment={item.attachment}
                            ></InfoFeedItem>
                        </div>
                    </div>
                );
            });
        } else {
            items.push(this.renderDefualtScreen());
        }
        return items;
    };
    renderDefualtScreen = () => {
        return (
            <div className="defualtContainer">
                <div className="row doCenter m-0 w-100">
                    <span className="w-100 defualt_title">
                        You have no incoming information to display
                    </span>
                </div>
                <div className="row m-0 w-100 mt-4 mb-2 doCenter">
                    <span className="w-100 defualt_subTitle">
                        Refresh the feed, wait for incoming information, or
                        modify your integration filters
                    </span>
                </div>
            </div>
        );
    };
    renderIntegrationBody = (itemData, type) => {
        if (itemData.contentText !== undefined) {
            return (
                <div className="row m-0 w-100 pl-4 pr-2">
                    <p className="twitBodyText">{itemData.contentText}</p>
                </div>
            );
        } else {
            switch (type.toLowerCase()) {
                case "outlook":
                    return this.renderOutlookItem(itemData);
                case "gmail":
                    return this.renderGmailItem(itemData);
            }
        }
    };
    renterTwitterItem = (itemData) => {
        return (
            <div className="feedCardBody row m-0 ">
                <div className="col-12 col-md-7 col-sm-12 doCenter p-0 pt-2">
                    <div className="row m-0 w-100 pl-4 pr-2">
                        <p className="twitBodyText">{itemData.contentText}</p>
                    </div>
                </div>
                <div className="col-10 col-md-5 col-sm-6 pr-3 doCenter">
                    <img className="previewImg" src={itemData.previewSrc}></img>
                </div>
            </div>
        );
    };
    renderOutlookItem = (itemData) => {
        return (
            <div className="feedCardBody">
                <div className="row m-0 w-100 mt-4 pl-4 pr-2 outlookBodyText">
                    <span className="w-100 textEllipsis" title={itemData.from}>
                        From : {itemData.from}
                    </span>
                </div>
                <div className="row m-0 w-100 mt-2 pl-4 pr-2 outlookBodyText">
                    <span
                        className="w-100 textEllipsis"
                        title={itemData.subject}
                    >
                        Subject : {itemData.subject}
                    </span>
                </div>
            </div>
        );
    };
    renderGmailItem = (itemData) => {
        return (
            <div className="feedCardBody">
                <div className="row m-0 w-100 mt-4 pl-4 pr-2 outlookBodyText">
                    <span className="w-100 textEllipsis">
                        From : {itemData.from}
                    </span>
                </div>
                <div className="row m-0 w-100 mt-2 pl-4 pr-2 outlookBodyText">
                    <span className="w-100 textEllipsis">
                        Subject : {itemData.subject}
                    </span>
                </div>
            </div>
        );
    };
    changeCurruntPageToIntegrations = () => {
        this.props.setActivePage("Integrations");
    };
    render() {
        const flag = this.props.disableRefreshFeed ? "none" : "auto";
        return (
            <>
                <div className="row w-100 m-0 mt-2 InfoHead">
                    <div
                        className="col"
                        style={{
                            paddingLeft: "0px",
                        }}
                    >
                        <span
                            className="feedTitle"
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            Information Feed
                        </span>
                    </div>
                    <div className="col-2 doCenter">
                        <i
                            className="fas fa-sync-alt"
                            // , pointerEvents: flag
                            style={
                                this.state.disableRefresh
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }
                            }
                            onClick={() => {
                                if (this.state.disableRefresh) {
                                    return;
                                } else {
                                    this.handleRefreshClick();
                                }
                            }}
                        ></i>
                    </div>
                </div>
                <div
                    className={"invisibleScroller"}
                    style={{
                        paddingRight: "16px",
                        overflowY: "auto",
                        background: "#ffffff",
                        boxShadow: "0px 8px 10px rgb(0 0 0 / 25%)",
                        flexBasis: "100%",
                        position: "relative",
                    }}
                >
                    {this.renderItems()}
                    <div className="row m-0 w-100 doCenter">
                        <span
                            style={{
                                fontfamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "18px",
                                lineHeight: "22px",
                                textDecorationLine: "underline",
                                color: "#0066FF",
                                cursor: "pointer",
                                width: "auto",
                            }}
                            className="mt-3"
                            onClick={this.changeCurruntPageToIntegrations}
                        >
                            View all integrations
                        </span>
                    </div>
                    {/* <div className="row w-100 doCenter">
                         {<img src="./images/DownArrow.png" style={{ margin: '10px' }} onClick={() => { alert("Functionality to be implemented!") }}></img>}
                    {/* </div> */}
                </div>
                {this.state.mails &&
                this.state.mails.emails &&
                this.state.mails.emails.length > 0 ? (
                    <div
                        style={{
                            position: "sticky",
                            bottom: "0",
                            background: "white",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            boxShadow: "0px 8px 10px rgb(0 0 0 / 25%)",
                        }}
                    >
                        <TablePagination
                            component="div"
                            count={
                                (this.state.mails &&
                                    this.state.mails.totalRows) ||
                                1
                            }
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </div>
                ) : null}
                <AlertDialog
                    open={this.state.showAlertModal}
                    setOpen={this.hideAlertView}
                    title={this.errMsgTitle}
                    message={this.errMsgBody}
                ></AlertDialog>
                <Loader show={this.state.showLoader} />
            </>
        );
    }
}
const mapDispatchToProps = () => {
    return {
        setActivePage,
        fetchSpaceListData,
        setBoardList,
        setActiveWorkspace,
        setActiveSpace,
        setFeedItemToRedirect,
        setFeedItems,
        reloadInformationFeed,
    };
};

const mapStateToProps = (state) => {
    return {
        currentPage: state.currentPage,
        disableRefreshFeed: state.disableRefreshFeed,
        spaceListData: state.spaceListData,
        informationFeedItems: state.informationFeedItems,
        existingIntegrationList: state.existingIntegrationList,
        newMailNotification: state.newMailNotification,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(InformationFeed);
