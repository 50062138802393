import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    validateEmail,
    validateUserName,
} from "../../../bae-client/Validations";
import { select } from "@material-ui/core";
import ControlledTreeView from "../tree-view/TreeView";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../bae-client/HTTP";
import Loader from "../Loader";
import { Divider } from "@mui/material";
import { Switch } from "@mui/material";
import Dropdown from "../dropdown/Dropdown";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { setSnackbarAction } from "../../../services/services/app";
import CustomDropdown from "../CustomDropdown";
import { ButtonComponent } from "../../button/button";
import { getDateByFormat } from "../../../bae-client/Utils";
import { EmptyRuleScreen } from "../../functional/UnityCentral/ContentPane/document-chain/modals/EmptyRuleScreen";
const attributesValues = [
    {
        text: "From Contains",
        value: "From Contains",
        id: 1,
    },
    {
        text: "Subject Contains",
        value: "Subject Contains",
        id: 2,
    },
    {
        text: "Body Contains",
        value: "Body Contains",
        id: 3,
    },
];
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #D7DEEA",
    boxShadow: 24,
    padding: "24px",
    paddingRight: "0px",
};
export const getTargetSpace = async (rule) => {
    const response = await http.sendRestRequest(
        `workspace/spaceList?workspaceId=${rule.workspaceId}`
    );
    const space = response?.find((s) => s.spaceId === rule.spaceId) || {};
    return space;
};

export const RuleRow = ({
    ruleName,
    paragraph,
    active,
    rule,
    renameHandler,
    deleteHandler,
    loadAllRules,
    setShowLoader,
}) => {
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);
    const [isToggled, setToggled] = React.useState(active);
    const [ruleMetaData, setRuleMetaData] = React.useState("");

    const handleToggle = () => {
        // setToggled(!isToggled);
        setShowLoader(true);
        const putPayload = {
            moveRuleRegistryId: rule.filterId,
            active: !active,
        };

        http.sendRestRequest(`moveRule`, "PUT", putPayload)
            .then(() => {
                setShowLoader(false);
                loadAllRules();
                dispatch(
                    setSnackbarAction({
                        message: "Filter updated successfully",
                        severity: "success",
                        open: true,
                    })
                );
            })
            .catch((error) => {
                setShowLoader(false);
                console.log(error);
                dispatch(
                    setSnackbarAction({
                        message: error?.error || "Something went wrong",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };

    React.useEffect(() => {
        if (rule) getMetaData(rule);
    }, [rule]);
    const getMetaData = async (rule) => {
        let metaDataMessage = "If email ";
        const { workspaceId, spaceId, folderId } = rule;
        const workspace = workspaceList.find(
            (w) => w.workspaceId === workspaceId
        );
        const space = await getTargetSpace(rule); //workspace.spaceList.find((s) => s.spaceId === spaceId);
        let storage = `${workspace.workspaceName}/${space?.spaceName}`;
        let conditionsArray = JSON.parse(rule.expression);
        if (parseInt(folderId))
            http.sendRestRequest(
                `spacePersistence/folderDetails?folderId=${parseInt(folderId)}`
            )
                .then((response) => {
                    storage = `${storage}/${response.folderNamePath}`;
                    if (
                        conditionsArray.length === 0 ||
                        conditionsArray[0] === ""
                    )
                        // return "Move all mails to " + storage;
                        setRuleMetaData("Move all mails to " + storage);
                    else {
                        conditionsArray.forEach((element, index) => {
                            if (index !== 0)
                                metaDataMessage = `${metaDataMessage} and `;
                            if (element.id === "email") {
                                metaDataMessage = `${metaDataMessage} ${element.key} ${element.method} ${element.value} `;
                            }
                            if (element.id === "attachment") {
                                metaDataMessage = `${metaDataMessage} Attachment ${element.method} ${element.key} ${element.keyName} as ${element.value} `;
                            }
                        });
                        metaDataMessage =
                            metaDataMessage + "then move email to " + storage;
                        // return metaDataMessage;
                        setRuleMetaData(metaDataMessage);
                    }
                })
                .catch((error) => console.log(error));
        else {
            if (conditionsArray.length === 0 || conditionsArray[0] === "")
                // return "Move all mails to " + storage;
                setRuleMetaData("Move all mails to " + storage);
            else {
                conditionsArray.forEach((element, index) => {
                    if (index !== 0)
                        metaDataMessage = `${metaDataMessage} and `;
                    if (element.id === "email") {
                        metaDataMessage = `${metaDataMessage} ${element.key} ${element.method} ${element.value} `;
                    }
                    if (element.id === "attachment") {
                        metaDataMessage = `${metaDataMessage} Attachment ${element.method} ${element.key} ${element.keyName} as ${element.value} `;
                    }
                });
                metaDataMessage =
                    metaDataMessage + "then move email to " + storage;
                // return metaDataMessage;
                setRuleMetaData(metaDataMessage);
            }
        }
    };
    return (
        <>
            <div className="row-heading">
                <Switch
                    checked={active}
                    style={{ cursor: "pointer" }}
                    onClick={handleToggle}
                />
                <div className="rule-name">
                    <div className="rule-heading textEllipsis" title={ruleName}>
                        {ruleName}
                    </div>
                    <div
                        title={ruleMetaData}
                        className="textEllipsis"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            letterSpacing: "-0.006em",
                            textAlign: "left",
                            color: "#21242C",
                        }}
                    >
                        {ruleMetaData}
                    </div>
                </div>
                <div
                    style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <span
                        className="edit-btn"
                        title="Edit Rule"
                        style={{ marginRight: "-36px" }}
                        onClick={() => renameHandler(rule)}
                    >
                        <img
                            src="./images/edit_rule.svg"
                            alt=""
                            style={{ width: "24px", height: "24px" }}
                        />
                    </span>
                    <span
                        title="Delete Rule"
                        className="delete-btn"
                        onClick={() => deleteHandler(rule)}
                    >
                        <img
                            src="./images/trash-1.svg"
                            alt=""
                            style={{ width: "24px", height: "24px" }}
                        />
                    </span>
                </div>
            </div>
            <div style={{ border: "1px solid #D7DEEA" }}></div>
        </>
    );
};
export default function NestedModal({
    open,
    onClose,
    showLoader,
    setShowLoader,
}) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState("1");
    const [openSecondModal, setOpenSecondModal] = React.useState(false);
    const [selectedRule, setSelectedRule] = React.useState(null);
    const [rules, setRules] = React.useState([]);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [recentValues, setRecentValues] = React.useState([]);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    React.useEffect(() => {
        loadAllRules();
        getRecentActivityList();
    }, []);

    const getRecentActivityList = () => {
        setShowLoader(true);
        const url = "moveRuleLog/list";
        http.sendRestRequest(url, "GET")
            .then((resp) => {
                setRecentValues(resp);
                setShowLoader(false);
            })
            .catch((error) => {
                console.error("Error fetching recent activity list:", error);
                setShowLoader(false);
            });
    };

    const loadAllRules = () => {
        setShowLoader(true);
        http.sendRestRequest("moveRule/list")
            .then((response) => {
                setShowLoader(false);
                let sortedResponse = response.sort(
                    (a, b) => b.moveRuleRegistryId - a.moveRuleRegistryId
                );
                setRules(
                    sortedResponse.map((rule) => {
                        return {
                            filterName: rule.ruleName,
                            filterId: rule.moveRuleRegistryId,
                            folderId: rule.targetFolderId,
                            spaceId: rule.targetSpaceId,
                            workspaceId: rule.targetWorkspaceId,
                            isActive: rule.active,
                            sourceWorkspaceId: rule.sourceWorkspaceId,
                            expression: rule.expression,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error("Error loading rules:", error);
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error?.error || "Error loading rules",
                        severity: "error",
                    })
                );
            });
    };

    const deleteHandler = (rule) => {
        const { filterId } = rule;
        setShowLoader(true);
        http.sendRestRequest(
            `moveRule?moveRuleRegistryId=${filterId}`,
            "DELETE"
        )
            .then(() => {
                setShowLoader(false);
                loadAllRules();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Filter deleted successfully",
                        severity: "success",
                    })
                );
            })
            .catch((error) => {
                console.error("Error deleting filter:", error);
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error?.error || "Error deleting filter",
                        severity: "error",
                    })
                );
            });
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const renameHandler = (rule) => {
        setSelectedRule(rule);
        setOpenSecondModal(true);
    };

    const clickNewMoveHandler = () => {
        setSelectedRule(null);
        setOpenSecondModal(true);
    };
    const slashRemove = (path) => {
        const lastCharacter = path[path.length - 1];
        if (lastCharacter === "\\") {
            path = path.slice(0, -1);
        }
        return path;
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 850 }}>
                    <TabContext value={value}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: 1,
                                borderColor: "divider",
                                marginLeft: "24px",
                                marginRight: "24px",
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab
                                    id="tab_moveRule_flow"
                                    data-id="tab_moveRule_flow"
                                    label="Move Rules"
                                    title="Move Rules"
                                    value="1"
                                    sx={{
                                        width: "152px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "-0.006em",
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_activity_flow"
                                    data-id="tab_activity_flow"
                                    label="Move Rule Log"
                                    title="Move Rule Log"
                                    value="2"
                                    sx={{
                                        width: "224px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "-0.006em",
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                    }}
                                />
                            </TabList>
                            <Button
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                variant="contained"
                                size="small"
                                title="New Move Rule"
                                sx={{ textTransform: "none" }}
                                onClick={clickNewMoveHandler}
                            >
                                New Move Rule
                            </Button>
                        </Box>
                        <TabPanel value="1">
                            <div
                                className={`${
                                    rules?.length ? "" : "recent-activity-log"
                                } nested-modal-body invisibleScroller`}
                                style={{
                                    height: "300px",
                                }}
                            >
                                {rules.length ? (
                                    rules.map((rule) => (
                                        <RuleRow
                                            ruleName={rule.filterName}
                                            paragraph={""}
                                            active={rule.isActive}
                                            key={rule.filterId}
                                            rule={rule}
                                            deleteHandler={deleteHandler}
                                            renameHandler={renameHandler}
                                            loadAllRules={loadAllRules}
                                            setShowLoader={setShowLoader}
                                        />
                                    ))
                                ) : (
                                    <EmptyRuleScreen
                                        label={"No rules to show"}
                                    />
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <div
                                className={`${
                                    recentValues?.length
                                        ? ""
                                        : "recent-activity-log"
                                } nested-modal-body invisibleScroller`}
                                style={{ height: "300px" }}
                            >
                                {recentValues.length ? (
                                    recentValues.map((item) => (
                                        <div
                                            key={item.ruleId}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div className="row-activity">
                                                <div
                                                    className="rule-name-heading textEllipsis"
                                                    title={item.ruleName}
                                                >
                                                    {item.ruleName}
                                                </div>
                                                <div className="rule-details">
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Document name:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.documentName
                                                            }
                                                        >
                                                            {item.documentName}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            From workspace
                                                            location:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.fromWorkspaceLocation
                                                            }
                                                        >
                                                            {
                                                                item.fromWorkspaceLocation
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            To workspace
                                                            location:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.toWorkspaceLocation
                                                            }
                                                        >
                                                            {slashRemove(
                                                                item.toWorkspaceLocation
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Date the document
                                                            entered unity
                                                            central:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.documentCreatedOn
                                                                    ? getDateByFormat(
                                                                          item.documentCreatedOn,
                                                                          "mm/dd/yyyy"
                                                                      )
                                                                    : null
                                                            }
                                                        >
                                                            {item.documentCreatedOn
                                                                ? getDateByFormat(
                                                                      item.documentCreatedOn,
                                                                      "mm/dd/yyyy"
                                                                  )
                                                                : null}
                                                        </span>
                                                    </div>
                                                    <div className="textEllipsis">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Date the document
                                                            got moved:{" "}
                                                        </span>
                                                        <span
                                                            title={
                                                                item.documentMovedOn
                                                                    ? getDateByFormat(
                                                                          item.documentMovedOn,
                                                                          "mm/dd/yyyy"
                                                                      )
                                                                    : null
                                                            }
                                                        >
                                                            {item.documentMovedOn
                                                                ? getDateByFormat(
                                                                      item.documentMovedOn,
                                                                      "mm/dd/yyyy"
                                                                  )
                                                                : null}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    border: "1px solid #D7DEEA",
                                                }}
                                            ></div>
                                        </div>
                                    ))
                                ) : (
                                    <EmptyRuleScreen
                                        label={"No logs to show"}
                                    />
                                )}
                            </div>
                        </TabPanel>
                    </TabContext>
                    <div className="nested-modal-container">
                        <div
                            className="action"
                            style={{ textAlign: "right", marginRight: "24px" }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ textTransform: "none" }}
                                onClick={() => onClose(false)}
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                    color: "black",
                                }}
                                title="Cancel"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    {openSecondModal && (
                        <ChildModal
                            open={openSecondModal}
                            handleClose={() => setOpenSecondModal(false)}
                            loadAllRules={loadAllRules}
                            selectedRule={selectedRule}
                            setSelectedRule={setSelectedRule}
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            showSnackbar={showSnackbar}
                            setShowSnackbar={setShowSnackbar}
                            snackbarMessage={snackbarMessage}
                            setSnackbarMessage={setSnackbarMessage}
                            rules={rules.map((item) => item.ruleName)}
                        />
                    )}
                </Box>
            </Modal>
            <Loader show={showLoader} />
        </div>
    );
}

const convertData = (data) => {
    const response = [];
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (element && element.documentId === 0) {
                response.push({
                    id: parseInt(key),
                    name: element.objectName,
                    children: convertData(element.children),
                    type: "folder",
                });
            }
        }
    }
    return response;
};
const getSpaceData = async (workspaceId, spaceId) => {
    const spaceData = await http.sendRestRequest(
        `spacePersistence/objectList?spaceId=${spaceId}`
    );
    const response = convertData(spaceData);

    return response;
};
const inputStyle = {
    width: "95%",
    margin: "8px",
    background: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};
function ChildModal({
    open,
    handleClose,
    loadAllRules,
    selectedRule,
    showLoader,
    setShowLoader,
    setSelectedRule,
    showSnackbar,
    setShowSnackbar,
    snackbarMessage,
    setSnackbarMessage,
    rules,
}) {
    const useStyles = makeStyles({
        root: {
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            background: "white !important",
            borderRadius: "6px !important",
            border: "none !important",
            margin: "0px",
        },
    });
    const classes = useStyles();
    const dispatch = useDispatch();
    const [ruleName, setRuleName] = React.useState("");
    const [error, setError] = React.useState("");
    const [firstNameErrorMsg, setFirstNameErrorMsg] = React.useState("");
    const workspaceList = useSelector((state) => state.workspaceList);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const [treeData, setTreeData] = React.useState([]);
    const [selectedStorage, setSelectedStorage] = React.useState(null);
    const [filterId, setFilterId] = React.useState("");
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);

    const [emailAttributes, setEmailAttributes] =
        React.useState(attributesValues);
    const [emailConditions, setEmailConditions] = React.useState([]);
    const [conditions, setConditions] = React.useState([]);

    const [emailCondition, setEmailCondition] = React.useState(null);
    const [emailConditionValue, setEmailConditionValue] = React.useState("");
    const [value1, setValue1] = React.useState(null);
    const [value2, setValue2] = React.useState(null);
    const [value3, setValue3] = React.useState(null);
    const [value4, setValue4] = React.useState(null);

    const buildTree = async () => {
        const data = [];
        for (let i = 0; i < workspaceList.length; i++) {
            if (workspaceList[i].workspaceType !== "SYSTEM") {
                let children =
                    workspaceList[i].workspaceId === selectedRule?.workspaceId
                        ? await http.sendRestRequest(
                              `workspace/spaceList?workspaceId=${workspaceList[i].workspaceId}`
                          )
                        : [];
                data.push({
                    id: workspaceList[i].workspaceId,
                    name: workspaceList[i].workspaceName,
                    type: "workspace",
                    children: children.map((s) => ({
                        id: s.spaceId,
                        name: s.spaceName,
                        type: "space",
                    })),
                });
            }
        }
        if (selectedRule) {
            const { workspaceId, spaceId, folderId } = selectedRule;
            setExpanded([workspaceId, spaceId, folderId]);
            setSelectedStorage({
                workspaceId,
                spaceId,
                folderId,
            });
        }
        setTreeData(data);
    };

    React.useEffect(() => {
        if (workspaceList && workspaceList.length) {
            buildTree();
        }
    }, [workspaceList]);

    React.useEffect(() => {
        if (selectedRule) {
            const { workspaceId, spaceId, folderId } = selectedRule;
            if (selectedRule.hasOwnProperty("filterId"))
                setFilterId(selectedRule.filterId);
            else setFilterId("");
            setRuleName(selectedRule.filterName);
            spreadExpression(JSON.parse(selectedRule.expression) || []);
            if (!folderId) {
                setExpanded([workspaceId, spaceId]);
                setSelected(spaceId);
                return;
            }
            setExpanded([workspaceId, spaceId, folderId]);
            setSelected(folderId);
        } else {
            setRuleName("");
            setSelectedStorage({});
        }
    }, [selectedRule]);
    React.useEffect(() => {
        http.sendRestRequest("object/attributes/fileAttributes")
            .then((response) => {
                const attr = response.map((item, index) => {
                    return { text: item, value: item, id: index + 1 };
                });
                setAttributes(attr);
            })
            .catch(() => {});
    }, []);
    const selectHandler = async (workspaceId, spaceId, folderId) => {
        if (!folderId && spaceId) {
            setShowLoader(true);
            getSpaceData(workspaceId, spaceId)
                .then((response) => {
                    const oldData = [...treeData];
                    const workspaceIndex = oldData.findIndex(
                        (item) => item.id === workspaceId
                    );
                    const spaceIndex = oldData[
                        workspaceIndex
                    ].children.findIndex((item) => item.id === spaceId);
                    oldData[workspaceIndex].children[spaceIndex].children =
                        response;
                    setTreeData(oldData);
                    setSelectedStorage({
                        workspaceId: workspaceId,
                        spaceId: spaceId,
                        folderId: folderId,
                    });
                    setError("");
                    setShowLoader(false);
                    if (expanded.includes(spaceId)) return;
                    setExpanded([...expanded, spaceId]);
                })
                .catch((err) => setShowLoader(false));
        } else if (!spaceId) {
            const spaceList = await http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            );
            const oldData = [...treeData];
            const workspaceIndex = oldData.findIndex(
                (item) => item.id === workspaceId
            );
            oldData[workspaceIndex].children = spaceList.map((s) => ({
                id: s.spaceId,
                name: s.spaceName,
                type: "space",
            }));
            setTreeData(oldData);
            if (expanded.includes(workspaceId)) return;
            setExpanded([...expanded, workspaceId]);
        } else {
            setSelectedStorage({
                workspaceId: workspaceId,
                spaceId: spaceId,
                folderId: folderId,
            });
            setError("");
            if (expanded.includes(folderId)) return;
            setExpanded([...expanded, folderId]);
        }
    };

    const validateFirstName = (firstName) => {
        // Add your validation logic here, assuming validateUserName is defined elsewhere
        let isValidName = validateUserName(firstName);
        if (isValidName === true) {
            setFirstNameErrorMsg("");
            return true;
        } else {
            setFirstNameErrorMsg(isValidName);
            return false; // Return false if validation fails
        }
    };

    const spreadExpression = (expression) => {
        const conditionsForEmail = [];
        const conditionsForAttachment = [];
        expression.forEach((element) => {
            if (element.id === "email") {
                let item = null;
                const key = element.key.split(" ")[0];
                if (key === "From") {
                    item = {
                        ["From Contains"]: element.value,
                    };
                }
                if (key === "Subject") {
                    item = {
                        ["Subject Contains"]: element.value,
                    };
                }
                if (key === "Body") {
                    item = {
                        ["Body Contains"]: element.value,
                    };
                }
                conditionsForEmail.push(item);
            }
            if (element.id === "attachment") {
                let item = null;
                if (element.key === "Attribute") {
                    item = {
                        value1: {
                            text: "Attribute",
                            value: "Attribute",
                            id: 1,
                        },
                        value2: {
                            text: element.method,
                            value: element.method,
                            id: 1,
                        },
                        value3: {
                            text: element.keyName,
                            value: element.keyName,
                            id: 1,
                        },
                        value4: element.value,
                    };
                }
                if (element.key === "text") {
                    item = {
                        value1: {
                            text: "Text",
                            value: "Text",
                            id: 1,
                        },
                        value2: {
                            text: element.method,
                            value: element.method,
                            id: 1,
                        },
                        value3: element.value,
                        value4: null,
                    };
                }
                conditionsForAttachment.push(item);
            }
        });
        setEmailConditions(conditionsForEmail);
        setConditions(conditionsForAttachment);
    };

    const handleSave = () => {
        if (!selectedStorage || !selectedStorage.spaceId) {
            setError("Please select Storage with atleast a space.");
            return;
        }
        const expression = [];
        conditions.forEach((condition) => {
            const object = {
                key:
                    condition?.value1?.text === "Text"
                        ? "text"
                        : condition?.value1?.text,
                method: condition?.value2?.text,
                value:
                    condition?.value1.text === "Text"
                        ? condition?.value3
                        : condition?.value4,
                keyName:
                    condition?.value1.text === "Text"
                        ? "rawText"
                        : condition?.value3?.text,
                id: "attachment",
            };
            expression.push(object);
        });
        emailConditions.forEach((condition) => {
            const objcetKey = Object.keys(condition)[0];
            const key = objcetKey.split(" ")[0];
            const method = objcetKey.split(" ")[1];
            const object = {
                key: key,
                method: method,
                value: condition[objcetKey],
                id: "email",
            };
            expression.push(object);
        });
        // if (!emailConditions.length && !conditions.length) {
        //     if (
        //         !(emailCondition && emailConditionValue) &&
        //         !(value1 && value2)
        //     ) {
        //         setError("Please add at least one email condition");
        //         return;
        //     }
        // }
        if (emailCondition && emailConditionValue) {
            expression.push({
                key: emailCondition.text.split(" ")[0],
                method: "Contains",
                value: emailConditionValue,
                id: "email",
            });
        }
        if (value1 && value2) {
            if (value1.text === "Text") {
                expression.push({
                    key: "text",
                    method: value2.text,
                    value: value3 || "",
                    keyName: "rawText",
                    id: "attachment",
                });
            } else if (value1.text === "Attribute") {
                expression.push({
                    key: value1.text,
                    method: value2.text,
                    value: value4 || "",
                    keyName: value3?.text,
                    id: "attachment",
                });
            }
        }

        if (!ruleName) {
            setError("Please enter rule name");
            return;
        }
        if (rules.includes(ruleName) && !filterId) {
            setError("Rule name already exists");
            return;
        }
        const payload = {
            userId: parseInt(sessionStorage.getItem("userId")),
            filterName: ruleName,
            workspaceId: parseInt(selectedStorage.workspaceId),
            spaceId: parseInt(selectedStorage.spaceId),
            folderId: parseInt(selectedStorage.folderId) || 0,
            expression: JSON.stringify(expression),
        };
        if (filterId) {
            const changedData = {
                filterId: selectedRule.filterId,
                userId: sessionStorage.getItem("userId"),
            };
            for (const key in payload) {
                if (
                    payload.hasOwnProperty(key) &&
                    payload[key] !== selectedRule[key]
                ) {
                    changedData[key] = payload[key];
                }
            }

            if (Object.keys(changedData).length === 2) {
                // No changes made
                setError("No changes made");
                return;
            }
            const putPayload = { moveRuleRegistryId: selectedRule.filterId };
            for (const key in changedData) {
                if (Object.hasOwnProperty.call(changedData, key)) {
                    if (key === "filterName")
                        putPayload.ruleName = changedData[key];
                    if (key === "expression")
                        putPayload.expression = changedData[key];
                    if (key === "workspaceId")
                        putPayload.targetWorkspaceId = changedData[key];
                    if (key === "spaceId")
                        putPayload.targetSpaceId = changedData[key];
                    if (key === "folderId")
                        putPayload.targetFolderId = changedData[key];
                }
            }
            if (putPayload.targetFolderId)
                putPayload.targetSpaceId = parseInt(selectedStorage.spaceId);
            if (putPayload.targetSpaceId)
                putPayload.targetWorkspaceId = parseInt(
                    selectedStorage.workspaceId
                );
            setShowLoader(true);
            http.sendRestRequest(`moveRule`, "PUT", putPayload)
                .then(() => {
                    setShowLoader(false);
                    loadAllRules();
                    clearData();
                    dispatch(
                        setSnackbarAction({
                            message: "Filter updated successfully",
                            severity: "success",
                            open: true,
                        })
                    );
                })
                .catch((error) => {
                    setShowLoader(false);
                    console.log(error);
                    dispatch(
                        setSnackbarAction({
                            message: error?.error || "Something went wrong",
                            severity: "error",
                            open: true,
                        })
                    );
                    handleClose();
                });
        } else {
            const payload = {
                ruleName: ruleName,
                sourceWorkspaceId: activeWorkspace?.workspaceId,
                targetWorkspaceId: parseInt(selectedStorage.workspaceId),
                targetSpaceId: parseInt(selectedStorage.spaceId),
                targetFolderId: parseInt(selectedStorage.folderId)
                    ? parseInt(selectedStorage.folderId)
                    : 0,
                expression: JSON.stringify(expression),
                active: true,
            };
            setShowLoader(true);
            http.sendRestRequest("moveRule", "POST", payload)
                .then(() => {
                    setShowLoader(false);
                    loadAllRules();
                    handleClose();
                    clearData();
                    dispatch(
                        setSnackbarAction({
                            message: "Filter created successfully",
                            severity: "success",
                            open: true,
                        })
                    );
                })
                .catch((error) => {
                    setShowLoader(false);
                    console.log(error);
                    dispatch(
                        setSnackbarAction({
                            message: error?.error || "Something went wrong",
                            severity: "error",
                            open: true,
                        })
                    );
                    handleClose();
                });
        }
    };
    const clearData = () => {
        handleClose();
        setRuleName("");
        setError("");
        setFilterId("");
        setConditions([]);
        setEmailConditions([]);
        setEmailCondition(null);
        setEmailConditionValue("");
        setValue1(null);
        setValue2(null);
        setValue3(null);
        setValue4(null);
        setFirstNameErrorMsg("");
        setSelectedStorage(null);
    };

    const handleAddEmailCondition = (event) => {
        if (emailCondition && emailCondition.text && emailConditionValue) {
            setEmailConditions([
                ...emailConditions,
                { [emailCondition.text]: emailConditionValue },
            ]);
            setEmailCondition(null);
            setEmailConditionValue("");
        }
        return;
    };
    const handleAddCondition = () => {
        setConditions([
            ...conditions,
            { value1: value1, value2: value2, value3: value3, value4: value4 },
        ]);
        setValue1(null);
        setValue2(null);
        setValue3(null);
        setValue4(null);
    };
    const handleRemoveCondition = (index) => {
        const newConditions = [...conditions];
        newConditions.splice(index, 1);
        setConditions(newConditions);
    };
    const handleRemoveEmailCondition = (index) => {
        const newEmailConditions = [...emailConditions];
        newEmailConditions.splice(index, 1);
        setEmailConditions(newEmailConditions);
    };

    const updateEmailConditions = (type, item, index) => {
        const newEmailConditions = [...emailConditions];
        if (type === "dropdown") {
            newEmailConditions[index] = {
                [item.text]: Object.values(emailConditions[index])[0],
            };
        }
        if (type === "input") {
            newEmailConditions[index] = {
                [Object.keys(emailConditions[index])[0]]: item,
            };
        }
        setEmailConditions(newEmailConditions);
    };
    const updateAttachmentConditions = (type, item, index, value) => {
        const newConditions = [...conditions];
        let object = {};
        if (value === "value1") {
            object = {
                value1: item,
                value2: null,
                value3: null,
                value4: null,
            };
        } else if (value === "value2") {
            object = {
                ...conditions[index],
                value2: item,
                value3: null,
                value4: null,
            };
        } else if (value === "value3") {
            object = {
                ...conditions[index],
                value3: item,
                value4: "",
            };
        } else if (value === "value4") {
            object = {
                ...conditions[index],
                value4: item,
            };
        } else return;
        newConditions[index] = object;
        setConditions(newConditions);
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 650 }} id="moveRuleModal">
                    <div
                        className="invisibleScroller"
                        id="moveRuleConditions"
                        style={{ paddingRight: "12px" }}
                    >
                        <div className="nested-modal-container invisibleScroller">
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                    marginBottom: "8px",
                                }}
                            >
                                <img src="./images/step-1.svg" alt="step" />
                                Set Rule
                            </div>
                            <input
                                autocomplete="off"
                                type="text"
                                placeholder="Name Rule"
                                name="ruleName"
                                className="input-rule-name"
                                value={ruleName}
                                onChange={(e) => {
                                    setRuleName(e.target.value);
                                    validateFirstName(e.target.value);
                                    setError("");
                                }}
                                style={{
                                    width: "44%",
                                    margin: "8px",
                                    background: "white",
                                    boxShadow:
                                        "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                }}
                            />
                            {firstNameErrorMsg && (
                                <small
                                    className="error"
                                    style={{ color: "red", paddingLeft: "4px" }}
                                >
                                    {firstNameErrorMsg}
                                </small>
                            )}
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-2.svg" alt="step" />
                                Conditions
                            </div>

                            {emailConditions.map(
                                (condition, conditionIndex) => (
                                    <>
                                        <div
                                            className="conditions"
                                            style={{ gap: "2px" }}
                                            key={conditionIndex}
                                        >
                                            <CustomDropdown
                                                items={attributesValues}
                                                label={"Select an condition"}
                                                customClassName={classes.root}
                                                handleSelection={(
                                                    index,
                                                    item
                                                ) =>
                                                    updateEmailConditions(
                                                        "dropdown",
                                                        item,
                                                        conditionIndex
                                                    )
                                                }
                                                selection={attributesValues.find(
                                                    (item) =>
                                                        item.text ===
                                                        Object.keys(
                                                            condition
                                                        )[0]
                                                )}
                                            />
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                placeholder="text"
                                                name="ruleTwo"
                                                className="input-rule-name"
                                                value={
                                                    Object.values(condition)[0]
                                                }
                                                onChange={(e) => {
                                                    updateEmailConditions(
                                                        "input",
                                                        e.target.value,
                                                        conditionIndex
                                                    );
                                                }}
                                                style={inputStyle}
                                            />
                                            <div
                                                className="labels"
                                                title="Remove"
                                            >
                                                <ButtonComponent
                                                    type={"secondary"}
                                                    // text={"Remove Condition"}
                                                    icon={
                                                        "./images/trash-fill.svg"
                                                    }
                                                    BtnHandler={() =>
                                                        handleRemoveEmailCondition(
                                                            conditionIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                            <div className="conditions" style={{ gap: "2px" }}>
                                <CustomDropdown
                                    items={emailAttributes}
                                    label={"Select an condition"}
                                    customClassName={classes.root}
                                    handleSelection={(index, item) =>
                                        setEmailCondition(item)
                                    }
                                    selection={""}
                                />
                                <input
                                    autocomplete="off"
                                    type="text"
                                    placeholder="text"
                                    name="ruleTwo"
                                    className="input-rule-name"
                                    value={emailConditionValue}
                                    onChange={(e) => {
                                        setEmailConditionValue(e.target.value);
                                    }}
                                    style={inputStyle}
                                />
                                <div
                                    className="labels"
                                    style={{ visibility: "hidden" }}
                                    title="Remove"
                                >
                                    <ButtonComponent
                                        type={"secondary"}
                                        // text={"Remove Condition"}
                                        icon={"./images/trash-fill.svg"}
                                        BtnHandler={() => {}}
                                        disable={true}
                                    />
                                </div>
                            </div>
                            <div className="labels">
                                <ButtonComponent
                                    type={"ghost"}
                                    text={"Add Condition"}
                                    icon={"./images/plus.svg"}
                                    BtnHandler={handleAddEmailCondition}
                                />
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-3.svg" alt="step" />
                                Add Conditions for attachment
                            </div>
                            {conditions.map((condition, conditionIndex) => (
                                <>
                                    <div
                                        className="conditions"
                                        style={{ gap: "2px" }}
                                        key={conditionIndex}
                                    >
                                        <CustomDropdown
                                            items={[
                                                {
                                                    text: "Attribute",
                                                    value: "Attribute",
                                                    id: 1,
                                                },
                                                {
                                                    text: "Text",
                                                    value: "Text",
                                                    id: 2,
                                                },
                                            ]}
                                            label={"Select an option"}
                                            customClassName={classes.root}
                                            handleSelection={(index, item) =>
                                                updateAttachmentConditions(
                                                    "dropdown",
                                                    item,
                                                    conditionIndex,
                                                    "value1"
                                                )
                                            }
                                            selection={condition.value1}
                                        />
                                        <CustomDropdown
                                            items={[
                                                {
                                                    text: "Contains",
                                                    value: "Contains",
                                                    id: 1,
                                                },
                                                {
                                                    text: "Not Contains",
                                                    value: "Not Contains",
                                                    id: 2,
                                                },
                                                {
                                                    text: "Equals",
                                                    value: "Equals",
                                                    id: 3,
                                                },
                                                {
                                                    text: "Not Equals",
                                                    value: "Not Equals",
                                                    id: 4,
                                                },
                                            ]}
                                            label={"Select an option"}
                                            // customClassName={"rippleDD rippleDDFilterView"}
                                            handleSelection={(index, item) => {
                                                updateAttachmentConditions(
                                                    "dropdown",
                                                    item,
                                                    conditionIndex,
                                                    "value2"
                                                );
                                            }}
                                            selection={condition.value2}
                                            customClassName={classes.root}
                                        />
                                        <div
                                            className="labels"
                                            style={{ visibility: "hidden" }}
                                            title="Remove"
                                        >
                                            <ButtonComponent
                                                type={"secondary"}
                                                // text={"Remove Condition"}
                                                icon={"./images/trash-fill.svg"}
                                                BtnHandler={() => {}}
                                                disable={true}
                                            />
                                        </div>
                                    </div>
                                    {condition.value1 &&
                                    condition.value2 &&
                                    condition.value1.text === "Attribute" ? (
                                        <div
                                            className="conditions"
                                            style={{ gap: "2px" }}
                                        >
                                            <CustomDropdown
                                                items={attributes}
                                                label={"Select an option"}
                                                // customClassName={"rippleDD rippleDDFilterView"}
                                                handleSelection={(
                                                    index,
                                                    item
                                                ) =>
                                                    updateAttachmentConditions(
                                                        "dropdown",
                                                        item,
                                                        conditionIndex,
                                                        "value3"
                                                    )
                                                }
                                                selection={condition.value3}
                                                customClassName={classes.root}
                                            />
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                placeholder={`Enter ${condition.value3?.text}`}
                                                name="attribute value"
                                                className="input-rule-name"
                                                value={condition.value4}
                                                onChange={(e) => {
                                                    updateAttachmentConditions(
                                                        "input",
                                                        e.target.value,
                                                        conditionIndex,
                                                        "value4"
                                                    );
                                                }}
                                                style={inputStyle}
                                            />
                                            <div
                                                className="labels"
                                                title="Remove"
                                            >
                                                <ButtonComponent
                                                    type={"secondary"}
                                                    // text={"Remove Condition"}
                                                    icon={
                                                        "./images/trash-fill.svg"
                                                    }
                                                    BtnHandler={() =>
                                                        handleRemoveCondition(
                                                            conditionIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {condition.value1 &&
                                    condition.value2 &&
                                    condition.value1.text === "Text" ? (
                                        <div
                                            className="conditions"
                                            style={{ gap: "2px" }}
                                        >
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                placeholder={`Enter Condition`}
                                                name="text value"
                                                className="input-rule-name"
                                                value={condition.value3}
                                                onChange={(e) => {
                                                    updateAttachmentConditions(
                                                        "input",
                                                        e.target.value,
                                                        conditionIndex,
                                                        "value3"
                                                    );
                                                }}
                                                style={inputStyle}
                                            />
                                            <div
                                                className="labels"
                                                title="Remove"
                                            >
                                                <ButtonComponent
                                                    type={"secondary"}
                                                    // text={"Remove Condition"}
                                                    icon={
                                                        "./images/trash-fill.svg"
                                                    }
                                                    BtnHandler={() =>
                                                        handleRemoveCondition(
                                                            conditionIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            ))}
                            <div
                                className="conditions"
                                style={{
                                    gap: "2px",
                                }}
                            >
                                <CustomDropdown
                                    items={[
                                        {
                                            text: "Attribute",
                                            value: "Attribute",
                                            id: 1,
                                        },
                                        { text: "Text", value: "Text", id: 2 },
                                    ]}
                                    label={"Select an option"}
                                    customClassName={classes.root}
                                    handleSelection={(index, item) =>
                                        setValue1(item)
                                    }
                                    selection={value1}
                                />
                                <CustomDropdown
                                    items={[
                                        {
                                            text: "Contains",
                                            value: "Contains",
                                            id: 1,
                                        },
                                        {
                                            text: "Not Contains",
                                            value: "Not Contains",
                                            id: 2,
                                        },
                                        {
                                            text: "Equals",
                                            value: "Equals",
                                            id: 3,
                                        },
                                        {
                                            text: "Not Equals",
                                            value: "Not Equals",
                                            id: 4,
                                        },
                                    ]}
                                    label={"Select condition"}
                                    // customClassName={"rippleDD rippleDDFilterView"}
                                    handleSelection={(index, item) => {
                                        setValue2(item);
                                    }}
                                    selection={value2}
                                    customClassName={classes.root}
                                />
                                <div
                                    className="labels"
                                    style={{ visibility: "hidden" }}
                                    title="Remove"
                                >
                                    <ButtonComponent
                                        type={"secondary"}
                                        // text={"Remove Condition"}
                                        icon={"./images/trash-fill.svg"}
                                        BtnHandler={() => {}}
                                        disable={true}
                                    />
                                </div>
                            </div>
                            {value2 && value1 && value1.text === "Attribute" ? (
                                <div
                                    className="conditions"
                                    style={{
                                        gap: "2px",
                                    }}
                                >
                                    <CustomDropdown
                                        items={attributes}
                                        label={"Select an option"}
                                        // customClassName={"rippleDD rippleDDFilterView"}
                                        handleSelection={(index, item) =>
                                            setValue3(item)
                                        }
                                        selection={value3}
                                        customClassName={classes.root}
                                    />
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        placeholder={`Enter ${
                                            value3?.text || "input"
                                        }`}
                                        name="attribute value"
                                        className="input-rule-name"
                                        value={value4}
                                        onChange={(e) => {
                                            setValue4(e.target.value);
                                        }}
                                        style={inputStyle}
                                    />
                                    <div
                                        className="labels"
                                        style={{ visibility: "hidden" }}
                                        title="Remove"
                                    >
                                        <ButtonComponent
                                            type={"secondary"}
                                            // text={"Remove Condition"}
                                            icon={"./images/trash-fill.svg"}
                                            BtnHandler={() => {}}
                                            disable={true}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {value1 && value2 && value1.text === "Text" ? (
                                <input
                                    autocomplete="off"
                                    type="text"
                                    placeholder={`Enter Condition`}
                                    name="text value"
                                    className="input-rule-name"
                                    value={value3}
                                    onChange={(e) => {
                                        setValue3(e.target.value);
                                    }}
                                    style={{
                                        width: "44%",
                                        margin: "8px",
                                        background: "white",
                                        boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                            ) : null}
                            <div className="labels">
                                <ButtonComponent
                                    type={"ghost"}
                                    text={"Add Condition"}
                                    icon={"./images/plus.svg"}
                                    BtnHandler={handleAddCondition}
                                />
                            </div>
                            <div
                                className="labels"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <img src="./images/step-4.svg" alt="step" />
                                Select Storage
                            </div>
                            <div className="tree-view invisibleScroller">
                                <ControlledTreeView
                                    data={treeData}
                                    setSelected={setSelected}
                                    setExpanded={setExpanded}
                                    expanded={expanded}
                                    selected={selected}
                                    selectHandler={selectHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="action"
                        style={{ paddingTop: "8px", paddingRight: "12px" }}
                    >
                        {error && (
                            <small
                                className="error"
                                style={{ color: "red", paddingLeft: "4px" }}
                            >
                                {error}
                            </small>
                        )}
                        <ButtonComponent
                            type={"outlined"}
                            text={"Cancel"}
                            BtnHandler={clearData}
                        />
                        <ButtonComponent
                            type={"primary"}
                            text={"Save"}
                            BtnHandler={handleSave}
                        />
                    </div>
                </Box>
            </Modal>
            <Loader show={showLoader} />
            <Snackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></Snackbar>
        </div>
    );
}
