import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { newMessage } from "../../../../../actions/WebSocketActions";
import { setUserIdToFullName } from "../../../../../actions/UserActions";
import {
    setChatList,
    setActiveChat,
    setSharedData,
} from "../../../../../actions/CommunicatorActions";
import http from "../../../../../bae-client/HTTP";
import "../../Communicator.css";
import ChatHistory from "./chatSection/chatHistory";
import ChatUser from "./chatSection/chatUser";
import NewChatUser from "./chatSection/newChatUser";
import ContactList from "./contactList";
import WelcomeChat from "./welcomeChat";
import Loader from "../../../../stylized/Loader";
import AlertDialog from "../../../../stylized/AlertDialog";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";
import {
    fetchUserListData,
    fetchDirectoryListData,
} from "../../../../functional/UnityCentral/LeftPane/Directory/DirectoryActions";
import RecentActivityManager from "../../../../../bae-client/RecentActivityManager";
function Communicator({
    chatList,
    setChatList,
    setActiveChat,
    activeChatUser,
    setUserIdToFullName,
}) {
    const [chatListData, setChatListData] = useState([]);
    const [isNewChat, setIsNewChat] = useState(false);
    const [selectedChat, setSelectedChat] = useState([]);
    const [selectetBackChat, setSelectedBackChat] = useState([]);
    const [chatCardUser, setChatCardUser] = useState([]);
    const [newChatUser, setNewChatUser] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [showPage, setShowPage] = useState(null);
    const [addNewUser, setAddNewUser] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [selectedContactIndex, setselectedContactIndex] = useState(-1);
    const [chatRegistryToUsersMap, setChatRegistryToUsersMap] = useState(
        new Map()
    );

    const [showLoader, setShowLoader] = useState(false);

    const dispatch = useDispatch();
    const webSocketNotificationReceived = useSelector(
        (state) => state.webSocketNotification
    );
    const sharedData = useSelector((state) => state.sharedData);

    const directoryList = useSelector((state) => state.directoryList);
    const directoryUsersList = useSelector((state) => state.directoryUsersList);

    // useEffect(() => {

    // }, [])

    useEffect(() => {
        if (directoryList && directoryList.length) {
            dispatch(
                fetchUserListData(
                    directoryList[directoryList.length - 1].directoryId
                )
            );
        }
    }, [directoryList]);

    useEffect(() => {
        if (directoryUsersList && directoryUsersList.length) {
            http.sendRestRequest("user/?active=true")
                .then((res) => {
                    let userIdToFullnameMap = new Map();
                    res.forEach((user) => {
                        userIdToFullnameMap.set(
                            user.userId,
                            user.firstName + " " + user.lastName
                        );
                    }); //map to get id to name
                    setUserIdToFullName(userIdToFullnameMap);
                    const valueToRemove = parseInt(sessionStorage.userId);
                    let allValue = [];
                    let getValue = chatList
                        .filter((item) => item.participantUserIds.length === 2)
                        .map((user) => user.participantUserIds)
                        .forEach((a) => a.forEach((b) => allValue.push(b.id)));

                    const filteredItems = res.filter(
                        (user) =>
                            user.userId !== parseInt(sessionStorage.userId)
                    );
                    let contacts = [];
                    directoryUsersList.forEach((user) => {
                        filteredItems.forEach((item) => {
                            if (
                                user.emailAddress.toLowerCase() ===
                                item.userEmail.toLowerCase()
                            ) {
                                contacts.push(item);
                            }
                        });
                    });
                    setAllContacts(contacts);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
        }
    }, [directoryUsersList]);

    useEffect(() => {
        if (
            sharedData &&
            (chatListData.length || showPage === "Invite") &&
            (selectedChat.length || showPage === "Invite") &&
            (chatCardUser || showPage === "Invite")
        ) {
            setNewChatUser(sharedData.users);
        }
    }, [sharedData, chatListData, selectedChat, chatCardUser, showPage]);
    useEffect(() => {
        if (newChatUser.length && sharedData) {
            setShowLoader(true);
            handleSendMessage(
                getSendMessage(
                    sharedData.message,
                    sharedData.messageType,
                    sharedData.folderStructure
                ),
                sharedData.messageType,
                sharedData.folderStructure
            );
            dispatch(setSharedData(null));
        }
    }, [newChatUser]);
    useEffect(() => {
        Mixpanel.callPageViewEvent("Chat");
        if (chatList && chatList.length) {
            setShowPage("ChatList");
            setChatListData(chatList);
            let map = new Map();
            chatList.forEach((chat) => {
                map.set(
                    chat.chatRegistryId,
                    chat.participantUserIds
                        .map((user) => user.id)
                        .filter(
                            (id) =>
                                parseInt(id) !==
                                parseInt(sessionStorage.getItem("userId"))
                        )
                );
            });

            setChatRegistryToUsersMap(map);
            if (activeChatUser !== null) {
                handleChatSelection(
                    activeChatUser.chatDetails,
                    activeChatUser.chat
                );
                setselectedContactIndex(activeChatUser.selectedContactIndex);
            } else {
                setChatCardUser(chatList[0]);
                setselectedContactIndex(0);
                let selectedChatUser = chatList[0].participantUserIds.filter(
                    (item) =>
                        parseInt(item.id) !==
                        parseInt(sessionStorage.getItem("userId"))
                );

                setSelectedChat(selectedChatUser);
                setSelectedBackChat(selectedChatUser);
            }
        } else setShowPage("Invite");
        dispatch(fetchDirectoryListData());
        // setShowLoader(true)
        // http.sendRestRequest("user/?active=true")
        //     .then((res) => {
        //         let userIdToFullnameMap = new Map();
        //         res.forEach((user) => {
        //             userIdToFullnameMap.set(
        //                 user.userId,
        //                 user.firstName + " " + user.lastName
        //             );
        //         }); //map to get id to name
        //         setUserIdToFullName(userIdToFullnameMap);
        //         const valueToRemove = parseInt(sessionStorage.userId);
        //         let allValue = [];
        //         let getValue = chatList
        //             .filter((item) => item.participantUserIds.length === 2)
        //             .map((user) => user.participantUserIds)
        //             .forEach((a) => a.forEach((b) => allValue.push(b.id)));

        //         const filteredItems = res.filter(
        //             (user) => user.userId !== parseInt(sessionStorage.userId)
        //         );

        //         setAllContacts(filteredItems);
        //         setShowLoader(false)
        //     })
        //     .catch((err) => {
        //         setShowAlertDialog(true);
        //         setShowLoader(false)
        //         setAlertDialogMessage(err.error);
        //         console.log(err);
        //     });
        return () => {
            setActiveChat(null);
        };
    }, []);

    const newChat = (event) => {
        setAddNewUser(false);
        setIsNewChat(true);
        setSelectedChat([]);
        //setChatCardUser(null);
    };

    const newUserChat = () => {
        setAddNewUser(true);
        setIsNewChat(true);
    };
    const removeList = () => {
        setAddNewUser(true);
        setIsNewChat(false);
        // setNewChatUser([]);
        setSelectedChat(selectetBackChat);
    };

    const handleChatSelection = (selectedChat, chat, index) => {
        let chatObj = {
            chatDetails: selectedChat,
            chat,
            selectedContactIndex: index,
        };

        setActiveChat(chatObj);
        setSelectedChat(selectedChat);
        setSelectedBackChat(selectedChat);
        setChatCardUser(chat);
        setIsNewChat(false);
        setAddNewUser(false);
        setselectedContactIndex(index);
        if (chat && !chat.isRead) {
            setShowLoader(true);
            http.sendRestRequest(
                `communicator/updateMessageStatus?chatRegistryId=${chat.chatRegistryId}`,
                "PUT"
            )
                .then((response) => {
                    http.sendRestRequest("communicator/chatList")
                        .then((res) => {
                            setShowPage("ChatList");
                            setChatListData(
                                res.sort((a, b) => {
                                    return (
                                        Date.parse(b.messageOn.split(".")[0]) -
                                        Date.parse(a.messageOn.split(".")[0])
                                    );
                                })
                            );
                            setChatList(res);
                            setChatCardUser(res[0]);
                            let selectedChatUser =
                                res[0].participantUserIds.filter(
                                    (item) =>
                                        parseInt(item.id) !==
                                        parseInt(
                                            sessionStorage.getItem("userId")
                                        )
                                );
                            setSelectedChat(selectedChatUser);
                            setSelectedBackChat(selectedChatUser);
                            setAddNewUser(false);
                            setShowLoader(false);
                            let map = new Map();
                            res.forEach((chat) => {
                                map.set(
                                    chat.chatRegistryId,
                                    chat.participantUserIds
                                        .map((user) => user.id)
                                        .filter(
                                            (id) =>
                                                parseInt(id) !==
                                                parseInt(
                                                    sessionStorage.getItem(
                                                        "userId"
                                                    )
                                                )
                                        )
                                );
                            });
                            setChatRegistryToUsersMap(map);
                        })
                        .catch((err) => {
                            setShowAlertDialog(true);
                            setAlertDialogMessage(err.error);
                            setShowLoader(false);
                            console.log(err);
                        });
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setShowLoader(false);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                });
        }
    };
    useEffect(() => {
        if (
            webSocketNotificationReceived &&
            (webSocketNotificationReceived.chatMessageObject ||
                webSocketNotificationReceived.notificationType ===
                    "Communicator.UserAddedOrDeleted")
        ) {
            setShowLoader(true);
            let url = "communicator/chatList";
            http.sendRestRequest(url)
                .then((res) => {
                    setShowLoader(false);
                    setShowPage("ChatList");
                    setChatListData(
                        res.sort((a, b) => {
                            return (
                                Date.parse(b.messageOn.split(".")[0]) -
                                Date.parse(a.messageOn.split(".")[0])
                            );
                        })
                    );
                    setChatList(res);
                    let map = new Map();
                    res.forEach((chat) => {
                        map.set(
                            chat.chatRegistryId,
                            chat.participantUserIds
                                .map((user) => user.id)
                                .filter(
                                    (id) =>
                                        parseInt(id) !==
                                        parseInt(
                                            sessionStorage.getItem("userId")
                                        )
                                )
                        );
                    });
                    setChatRegistryToUsersMap(map);
                    if (res.length === 1) {
                        setChatCardUser(res[0]);
                        let selectedChatUser = res[0].participantUserIds.filter(
                            (item) =>
                                parseInt(item.id) !==
                                parseInt(sessionStorage.getItem("userId"))
                        );
                        setSelectedChat(selectedChatUser);
                        setSelectedBackChat(selectedChatUser);
                        handleChatSelection(selectedChatUser, res[0], 0);
                    } else {
                        let user = null;
                        let userChatIndex = 0;
                        let selectedChat = [];
                        res.forEach((chat, index) => {
                            if (
                                chat.chatRegistryId ===
                                chatCardUser.chatRegistryId
                            ) {
                                user = chat;
                                userChatIndex = index;
                                selectedChat = chat.participantUserIds.filter(
                                    (item) =>
                                        parseInt(item.id) !==
                                        parseInt(
                                            sessionStorage.getItem("userId")
                                        )
                                );
                            }
                        });
                        if (user) {
                            setSelectedChat(user);
                            setSelectedBackChat(user);
                            handleChatSelection(
                                selectedChat,
                                user,
                                userChatIndex
                            );
                        }
                    }
                })
                .catch((err) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
            dispatch(newMessage(null));
        }
    }, [webSocketNotificationReceived]);

    const getFolderStructure = (object) => {
        return {
            [object.objectName]: {
                files:
                    (object.children &&
                        object.children.length &&
                        object.children
                            .filter((item) => {
                                return item.documentId;
                            })
                            .map((item) => {
                                return `${item.documentId}:${item.objectName}`;
                            })) ||
                    [],
                folders:
                    (object.children &&
                        object.children.length &&
                        object.children
                            .filter((item) => {
                                return !item.documentId;
                            })
                            .map((item) => {
                                return getFolderStructure(item);
                            })) ||
                    [],
            },
        };
    };
    const getSendMessage = (message, messageType, folderStructure) => {
        let returnMessage = "";
        if (messageType === "SPACE_OBJECT") {
            returnMessage = JSON.stringify({
                [message.spaceName]: message.spaceId,
            });
        } else if (messageType === "MSG_WORKSPACE_OBJECT") {
            returnMessage = JSON.stringify(message);
        } else if (messageType === "MSG_SPACE_OBJECT") {
            const jsonString = JSON.stringify({
                [message.spaceName]: message.spaceId.toString(),
                isDownload: message.isDownload ? message.isDownload : false,
                shareUpdates: message.shareUpdates
                    ? message.shareUpdates
                    : false,
                receiveUpdates: message.receiveUpdates
                    ? message.receiveUpdates
                    : false,
            });
            returnMessage = "|" + jsonString;
        } else if (messageType === "MSG_FOLDER_OBJECT") {
            const jsonString = JSON.stringify({
                [message.folderFileName]: message.documentId.toString(),
                isDownload: message.isDownload ? message.isDownload : false,
                shareUpdates: message.shareUpdates
                    ? message.shareUpdates
                    : false,
                receiveUpdates: message.receiveUpdates
                    ? message.receiveUpdates
                    : false,
            });
            returnMessage = "|" + jsonString;
        } else if (messageType === "RIPPLE_OBJECT") {
            returnMessage = JSON.stringify(message);
        } else if (messageType === "MSG_FLOW") {
            returnMessage = "|" + message; //"|" + JSON.stringify(message);
        } else {
            returnMessage = messageType
                ? `${message.documentId}:${message.folderFileName}:${message.isEditable}:${message.isDownload}`
                : message;
        }
        return returnMessage;
    };
    const isUserChatExists = () => {
        if (!chatRegistryToUsersMap.size)
            return { chatExists: false, chatRegistryId: "" };
        let chatExists = false;
        let chatRegistryId = "";
        for (const [key, value] of chatRegistryToUsersMap.entries()) {
            if (
                value.filter((id) => newChatUser.includes(id)).length ===
                    newChatUser.length &&
                value.filter((id) => newChatUser.includes(id)).length ===
                    value.length
            ) {
                chatList.forEach((chat, index) => {
                    if (chat.chatRegistryId === key) {
                        chatExists = true;
                        chatRegistryId = key;
                        // setChatCardUser(chat);
                        // setselectedContactIndex(index);
                        // setSelectedChat(
                        //     chat.participantUserIds.filter(
                        //         (item) =>
                        //             parseInt(item.id) !==
                        //             parseInt(sessionStorage.getItem("userId"))
                        //     )
                        // );
                    }
                });
            }
        }
        return { chatExists, chatRegistryId };
    };
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const getMembersPrivilege = (message) => {
        const array = message?.split("|");
        if (array?.length === 1) {
            let obj = JSON.parse(message);
            return {
                isDownload: obj?.isDownload,
                shareUpdates: obj?.shareUpdates,
                receiveUpdates: obj?.receiveUpdates,
            };
        } else if (array?.length === 2) {
            let obj = JSON.parse(array[1]);
            return {
                isDownload: obj?.isDownload,
                shareUpdates: obj?.shareUpdates,
                receiveUpdates: obj?.receiveUpdates,
            };
        } else
            return {
                isDownload: false,
                shareUpdates: false,
                receiveUpdates: false,
            };
    };
    const handleSendMessage = (message, messageType, metaData) => {
        setIsNewChat(false);
        if (message && !addNewUser) {
            const postPayload = {
                messageBy: parseInt(sessionStorage.getItem("userId")),
                message: message, //getSendMessage(message, messageType, folderStructure),
                participantUserIds: [
                    parseInt(sessionStorage.getItem("userId")),
                ].concat(newChatUser),
                messageType: messageType ? messageType : "MESSAGE",
            };
            const { chatExists, chatRegistryId } = isUserChatExists();
            const putPayload = {
                chatRegistryId:
                    chatExists && chatRegistryId
                        ? chatRegistryId
                        : chatCardUser && chatCardUser.chatRegistryId,
                message: message,
                messageBy: parseInt(sessionStorage.getItem("userId")),
                messageType: messageType ? messageType : "MESSAGE",
            };
            if (
                metaData &&
                (messageType === "MSG_SPACE_OBJECT" ||
                    messageType === "MSG_FOLDER_OBJECT" ||
                    messageType === "FOLDER_OBJECT" ||
                    messageType === "MSG_FLOW" ||
                    messageType === "MSG_WORKSPACE_OBJECT")
            ) {
                let liveShareUrl;
                let sharedUserId = newChatUser.length
                    ? newChatUser
                          .map((id) => {
                              return { id: id };
                          })
                          .filter(
                              (user) =>
                                  parseInt(user.id) !==
                                  parseInt(sessionStorage.userId)
                          )
                    : chatCardUser.participantUserIds.filter(
                          (user) =>
                              parseInt(user.id) !==
                              parseInt(sessionStorage.userId)
                      );

                const membersPrevilege = getMembersPrivilege(message);
                const allUserShare = sharedUserId.map((user) => {
                    messageType === "MSG_SPACE_OBJECT"
                        ? (liveShareUrl = `share/space?ownerUserId=${sessionStorage.userId}&ownerSpaceId=${metaData.spaceId}&sharedUserId=${user.id}&shareUpdates=${membersPrevilege.shareUpdates}&receiveUpdates=${membersPrevilege.receiveUpdates}&allowDownload=${membersPrevilege.isDownload}`)
                        : messageType === "MSG_FLOW"
                        ? (liveShareUrl = `share/generic?ownerUserId=${
                              sessionStorage.userId
                          }&ownerGenericId=${metaData.genericId}&sharedUserId=${
                              user.id
                          }&shareWholeFlow=${true}&shareUpdates=${
                              membersPrevilege.shareUpdates
                          }&receiveUpdates=${
                              membersPrevilege.receiveUpdates
                          }&allowDownload=${membersPrevilege.isDownload}`)
                        : messageType === "MSG_WORKSPACE_OBJECT"
                        ? (liveShareUrl = `share/workspace?ownerUserId=${sessionStorage.userId}&ownerWorkspaceId=${metaData.workspaceId}&sharedUserId=${user.id}&shareUpdates=${membersPrevilege.shareUpdates}&receiveUpdates=${membersPrevilege.receiveUpdates}&allowDownload=${membersPrevilege.isDownload}`)
                        : (liveShareUrl = `share/folder?ownerUserId=${sessionStorage.userId}&ownerFolderId=${metaData.folderFileId}&sharedUserId=${user.id}&shareUpdates=${membersPrevilege.shareUpdates}&receiveUpdates=${membersPrevilege.receiveUpdates}&allowDownload=${membersPrevilege.isDownload}`);
                    if (messageType === "MSG_FLOW") {
                        return http.sendRestRequest(liveShareUrl, "POST", []);
                    } else {
                        return http.sendRestRequest(liveShareUrl, "POST");
                    }
                });
                //share/generic?ownerUserId=1079&ownerGenericId=30289&sharedUserId=1034 - Sharing
                // messageType === "MSG_SPACE_OBJECT"
                //     ? (liveShareUrl = `share/space?ownerUserId=${sessionStorage.userId}&ownerSpaceId=${metaData.spaceId}&sharedUserId=${sharedUserId.id}`)
                //     : (liveShareUrl = `share/folder?ownerUserId=${sessionStorage.userId}&ownerFolderId=${metaData.folderFileId}&sharedUserId=${sharedUserId.id}`);
                // http.sendRestRequest(liveShareUrl, "POST")
                Promise.all(allUserShare)
                    .then(() => {
                        if (messageType === "MSG_WORKSPACE_OBJECT") {
                            let userIdToFullnameMap = new Map();
                            http.sendRestRequest("user/?active=true")
                                .then((res) => {
                                    res.forEach((user) => {
                                        userIdToFullnameMap.set(
                                            user.userId,
                                            user.firstName + " " + user.lastName
                                        );
                                    });
                                    let recentActivity =
                                        new RecentActivityManager();
                                    let dataObj = {
                                        objectType: "MEMBERS",
                                        objectId: sharedUserId[0].id,
                                        description: `${userIdToFullnameMap.get(
                                            sharedUserId[0].id
                                        )} joined in ${
                                            metaData?.workspaceName ||
                                            metaData?.workspaceId
                                        }`,
                                    };
                                    recentActivity
                                        .createRecentActivity(dataObj)
                                        .then((recentRes) => {})
                                        .catch((err) => console.log(err.error));
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        Mixpanel.callFolderManagementEvent("Share");
                    })
                    .then(() =>
                        http
                            .sendRestRequest(
                                "communicator/chat",
                                chatExists || !newChatUser.length
                                    ? "PUT"
                                    : "POST",
                                chatExists || !newChatUser.length
                                    ? putPayload
                                    : postPayload
                            )
                            .then((response) => {
                                setNewChatUser([]);
                                // if (chatCardUser && chatCardUser.isMessageRead) {
                                //     http.sendRestRequest(
                                //         `/communicator/updateMessageStatus?chatRegistryId=${chatCardUser.chatRegistryId}`,
                                //         "PUT"
                                //     )
                                //         .then((response) => {})
                                //         .catch((error) => console.log(error));
                                // }
                                http.sendRestRequest("communicator/chatList")
                                    .then((res) => {
                                        setShowPage("ChatList");
                                        setChatListData(
                                            res.sort((a, b) => {
                                                return (
                                                    Date.parse(
                                                        b.messageOn.split(
                                                            "."
                                                        )[0]
                                                    ) -
                                                    Date.parse(
                                                        a.messageOn.split(
                                                            "."
                                                        )[0]
                                                    )
                                                );
                                            })
                                        );
                                        let map = new Map();
                                        res.forEach((chat) => {
                                            map.set(
                                                chat.chatRegistryId,
                                                chat.participantUserIds
                                                    .map((user) => user.id)
                                                    .filter(
                                                        (id) =>
                                                            parseInt(id) !==
                                                            parseInt(
                                                                sessionStorage.getItem(
                                                                    "userId"
                                                                )
                                                            )
                                                    )
                                            );
                                        });
                                        setChatRegistryToUsersMap(map);
                                        setChatList(res);
                                        setChatCardUser(res[0]);
                                        let selectedChatUser =
                                            res[0].participantUserIds.filter(
                                                (item) =>
                                                    parseInt(item.id) !==
                                                    parseInt(
                                                        sessionStorage.getItem(
                                                            "userId"
                                                        )
                                                    )
                                            );
                                        setSelectedChat(selectedChatUser);
                                        setSelectedBackChat(selectedChatUser);
                                        handleChatSelection(
                                            selectedChatUser,
                                            res[0],
                                            0
                                        );
                                        setShowLoader(false);
                                    })
                                    .catch((error) => {
                                        setShowLoader(false);
                                        setShowAlertDialog(true);
                                        setAlertDialogMessage(error.error);
                                    });
                            })
                            .catch((error) => {
                                setShowLoader(false);
                                setShowAlertDialog(true);
                                setAlertDialogMessage(error.error);
                            })
                    )
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            } else
                http.sendRestRequest(
                    "communicator/chat",
                    chatExists || !newChatUser.length ? "PUT" : "POST",
                    chatExists || !newChatUser.length ? putPayload : postPayload
                )
                    .then((response) => {
                        if (
                            postPayload.messageType === "FILE_OBJECT" ||
                            putPayload.messageType === "FILE_OBJECT"
                        ) {
                            let splitFileName = postPayload.message
                                .split(":")
                                .pop()
                                .split(".");
                            let fileExt = splitFileName.pop();
                            Mixpanel.callFileManagementEvent("Share", fileExt);
                        } else if (
                            postPayload.messageType === "MSG_FILE_OBJECT" ||
                            putPayload.messageType === "MSG_FILE_OBJECT"
                        ) {
                            let splitFileName = JSON.parse(
                                postPayload.message.replace("|", "")
                            ).folderFileName.split(".");
                            let fileExt = splitFileName.pop();
                            Mixpanel.callFileManagementEvent("Share", fileExt);
                        }
                        setNewChatUser([]);
                        // if (chatCardUser && chatCardUser.isMessageRead) {
                        //     http.sendRestRequest(
                        //         `/communicator/updateMessageStatus?chatRegistryId=${chatCardUser.chatRegistryId}`,
                        //         "PUT"
                        //     )
                        //         .then((response) => {})
                        //         .catch((error) => console.log(error));
                        // }
                        http.sendRestRequest("communicator/chatList")
                            .then((res) => {
                                setShowPage("ChatList");
                                setChatListData(
                                    res.sort((a, b) => {
                                        return (
                                            Date.parse(
                                                b.messageOn.split(".")[0]
                                            ) -
                                            Date.parse(
                                                a.messageOn.split(".")[0]
                                            )
                                        );
                                    })
                                );
                                let map = new Map();
                                res.forEach((chat) => {
                                    map.set(
                                        chat.chatRegistryId,
                                        chat.participantUserIds
                                            .map((user) => user.id)
                                            .filter(
                                                (id) =>
                                                    parseInt(id) !==
                                                    parseInt(
                                                        sessionStorage.getItem(
                                                            "userId"
                                                        )
                                                    )
                                            )
                                    );
                                });
                                setChatRegistryToUsersMap(map);
                                setChatList(res);
                                setChatCardUser(res[0]);
                                let selectedChatUser =
                                    res[0].participantUserIds.filter(
                                        (item) =>
                                            parseInt(item.id) !==
                                            parseInt(
                                                sessionStorage.getItem("userId")
                                            )
                                    );
                                setSelectedChat(selectedChatUser);
                                setSelectedBackChat(selectedChatUser);
                                handleChatSelection(
                                    selectedChatUser,
                                    res[0],
                                    0
                                );
                                setShowLoader(false);
                            })
                            .catch((err) => {
                                console.log(err);
                                setShowAlertDialog(true);
                                setAlertDialogMessage(err.error);
                                setShowLoader(false);
                            });
                    })
                    .catch((err) => console.log(err));
        } else if (addNewUser && newChatUser.length) {
            const addUserPayload = {
                chatRegistryId: chatCardUser && chatCardUser.chatRegistryId,
                participantUserIds: chatCardUser.participantUserIds
                    .map((item) => item.id)
                    .concat(newChatUser),
            };

            http.sendRestRequest(
                "communicator/chat/participantUserIds",
                "PUT",
                addUserPayload
            )
                .then((response) => {
                    setNewChatUser([]);

                    http.sendRestRequest("communicator/chatList")
                        .then((res) => {
                            setShowPage("ChatList");
                            setChatListData(
                                res.sort((a, b) => {
                                    return (
                                        Date.parse(b.messageOn.split(".")[0]) -
                                        Date.parse(a.messageOn.split(".")[0])
                                    );
                                })
                            );
                            setChatList(res);
                            setChatCardUser(res[0]);
                            let selectedChatUser =
                                res[0].participantUserIds.filter(
                                    (item) =>
                                        parseInt(item.id) !==
                                        parseInt(
                                            sessionStorage.getItem("userId")
                                        )
                                );
                            setSelectedChat(selectedChatUser);
                            setSelectedBackChat(selectedChatUser);
                            setAddNewUser(false);
                            let map = new Map();
                            res.forEach((chat) => {
                                map.set(
                                    chat.chatRegistryId,
                                    chat.participantUserIds
                                        .map((user) => user.id)
                                        .filter(
                                            (id) =>
                                                parseInt(id) !==
                                                parseInt(
                                                    sessionStorage.getItem(
                                                        "userId"
                                                    )
                                                )
                                        )
                                );
                            });
                            setChatRegistryToUsersMap(map);
                        })
                        .catch((err) => {
                            setShowAlertDialog(true);
                            setAlertDialogMessage(err.error);
                        });
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                });
        }
    };
    const setNewChat = (toUser) => {
        setNewChatUser(toUser);
    };
    const setIsNewChatChange = (flag) => {
        setIsNewChat(flag);
    };
    return (
        <>
            <div
                id={"communicatorContainer"}
                className="w-100 invisibleScroller pt-0 pb-0"
                style={{
                    height: "100vh",
                    overflowY: "auto",
                    padding: "16px",
                }}
            >
                <div
                    className="row ml-1 m-0 p-0 topBorder"
                    style={{
                        boxShadow: "0px 16px 24px 0px rgb(0 0 0 / 6%)",
                    }}
                >
                    <div
                        id={"communicatorLeftPanel"}
                        className="col-4 p-0 rightBorder"
                        style={{
                            height: "93vh",
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                            borderRight: "1.5px solid #EBEBEB",
                            borderLeft: "1.5px solid #EBEBEB",
                        }}
                    >
                        <div
                            style={{
                                padding: "10px",
                                height: "3rem",
                                borderBottom: "1.5px solid #EBEBEB",
                            }}
                        >
                            <div
                                style={{ float: "left", fontSize: "1.125rem" }}
                            >
                                Chats
                            </div>
                            <span
                                id={"span_newChatHistory"}
                                style={{ float: "right", cursor: "pointer" }}
                                onClick={newChat}
                            >
                                <img
                                    alt="new chat"
                                    title="New Chat"
                                    src="./images/pencil-square.svg"
                                ></img>
                            </span>
                        </div>
                        <div className="invisibleScroller">
                            {showPage === "Invite" && (
                                <WelcomeChat
                                    setIsNewChatChange={setIsNewChatChange}
                                />
                            )}
                            {showPage === "ChatList" && (
                                <ContactList
                                    selectedContactIndex={selectedContactIndex}
                                    contactList={chatListData}
                                    handleChatSelection={handleChatSelection}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        id={"communicatorRightPanel"}
                        className="col p-0 rightBorder"
                        style={{
                            height: "93vh",
                            overflowY: "auto",
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                            borderRight: "1.5px solid #EBEBEB",
                        }}
                    >
                        <ChatUser
                            selectedUser={selectedChat}
                            newChatSend={newChat}
                            newUserChat={newUserChat}
                        ></ChatUser>
                        {isNewChat ? (
                            <NewChatUser
                                removeList={removeList}
                                setNewChat={setNewChat}
                                setIsNewChat={setIsNewChat}
                                setAddNewUser={setAddNewUser}
                                contactList={allContacts}
                                user={selectedChat}
                                sendMessage={handleSendMessage}
                            />
                        ) : null}
                        <ChatHistory
                            handleSendMessage={handleSendMessage}
                            selectedUser={chatCardUser}
                            isNewChat={isNewChat}
                            newUser={isNewChat}
                            newChatUser={newChatUser}
                            addNewUser={addNewUser}
                        ></ChatHistory>
                    </div>
                </div>
            </div>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
            <Loader show={showLoader}></Loader>
        </>
    );
}
const mapDispatchToProps = () => {
    return { setChatList, setActiveChat, setUserIdToFullName };
};

const mapStateToProps = (state) => {
    return { chatList: state.chatList, activeChatUser: state.activeChatUser };
};
export default connect(mapStateToProps, mapDispatchToProps())(Communicator);
