import CuboidManager from "./CuboidManager";
import http from "./HTTP";

class Document {
    constructor() {
        this.columns = [
            "Grouping 1",
            "Grouping 2",
            "Grouping 3",
            "Grouping 4",
            "Grouping 5",
            "Grouping 6",
            "X Coordinate",
            "Y Coordinate",
            "Width",
            "Height",
            "Attributes",
            "Text",
        ];
    }
    data = {
        collabId: -1,
        wbId: -1,
        documentId: -1,
        documentHeaderId: -1,
        collabName: "",
        wbname: "",
        headerName: "",
        fileName: "",
        files: "",
        isChoosen: false,
        metaRows: null,
        isLinked: false,
        isPersisted: false,
    };

    setData = (options) => {
        if (options.collabId !== null || options.collabId !== undefined) {
            this.data.collabId = options.collabId;
        }
        if (options.wbId !== null || options.wbId !== undefined) {
            this.data.wbId = options.wbId;
        }
        if (options.files !== null || options.files !== undefined) {
            this.data.files = options.files;
        }
        if (options.headerName !== null || options.headerName !== undefined) {
            this.data.headerName = options.headerName;
        }
        if (
            options.documentHeaderId !== null ||
            options.documentHeaderId !== undefined
        ) {
            this.data.documentHeaderId = options.documentHeaderId;
        }
        if (options.isChoosen !== null || options.isChoosen !== undefined) {
            this.data.isChoosen = options.isChoosen;
        }
        if (options.documentId !== null || options.documentId !== undefined) {
            this.data.documentId = options.documentId;
        }
        if (options.metaRows !== null || options.metaRows !== undefined) {
            this.data.metaRows = options.metaRows;
        }
        if (options.wbname !== null || options.wbname !== undefined) {
            this.data.wbname = options.wbname;
        }
        if (options.collabName !== null || options.collabName !== undefined) {
            this.data.collabName = options.collabName;
        }
        if (options.fileName !== null || options.fileName !== undefined) {
            this.data.fileName = options.fileName;
        }
        if (options.isPersisted !== null || options.isPersisted !== undefined) {
            this.data.isPersisted = options.isPersisted;
        }
        if (options.isLinked !== null || options.isLinked !== undefined) {
            this.data.isLinked = options.isLinked;
        }
    };
    getData() {
        return this.data;
    }
    setMetaData(modaldata) {
        this.data.metaRows = modaldata;
    }
    loadDocument = (documentId) => {
        this.data.documentId = documentId;
    };
    getCollabId = () => {
        return this.data.collabId;
    };
    getFile = () => {
        return this.data.files;
    };
    getName = () => {
        return this.data.files.name;
    };
    getDocumentPath() {
        let filePath = [];
        if (this.isLinked() === true) {
            let collabObj = {
                collabId: this.data.collabId,
                collabName: this.data.collabName,
            };
            let wbObj = {
                wbId: this.data.wbId,
                wbName: this.data.wbname,
            };
            let documentObj = {
                documentId: this.data.documentId,
                documentName: this.data.fileName,
            };
            filePath.push(collabObj, wbObj, documentObj);
            return filePath;
        } else {
            return null;
        }
    }
    verifyOrder = () => {
        const url =
            "workflow/checkDocumentOrder?documentHeaderId=" +
            this.data.documentHeaderId +
            "&parentHeaderId=" +
            this.data.parentCuboidId;
        return http.sendRestRequest(url, null, this.data.documentHeaderId);
    };
    setChoosen = (flag) => {
        this.data.isChoosen = flag;
    };
    isLinked = () => {
        if (this.data.documentId !== -1 && this.data.documentId !== undefined) {
            this.data.isLinked = true;
            return true;
        } else {
            this.data.isLinked = false;
            return false;
        }
    };
    // getTransectionTime() {
    // 	let latestTime;
    // 	let promise = new Promise((resolve, reject) => {
    // 		if (this.data.documentId > 0 && this.data.collabId > 0 && this.data.wbId > 0) {
    // 			let cuboidManager = new CuboidManager(this.data.collabId, this.data.wbId);
    // 			cuboidManager.getTransectionsById(this.data.documentId).then((txResponse) => {
    // 				latestTime = txResponse.txs[0].txChain.txTimeUTC;
    // 				let date = new Date(latestTime);
    // 				latestTime = date.toLocaleString('en-GB', { timeZone: 'UTC' }).split(",")[0];
    // 				// latestTime.replaceAll("/","-")
    // 				// let day = date.getDate(latestTime);
    // 				// let month = date.getMonth(latestTime) + 1;
    // 				// let year = date.getFullYear(latestTime);
    // 				let txDate = "";
    // 				// txDate = (day <= 9 ? '0' + day : day) + '-' + (month <= 9 ? '0' + month : month) + '-' + year + ' (dd-mm-yyyy)';
    // 				txDate = txDate + latestTime + " (dd/mm/yyyy)"
    // 				resolve(txDate);
    // 			});
    // 		}
    // 		else {
    // 			reject("Yet To Be Submited");
    // 		}
    // 	});
    // 	return promise;
    // }

    uploadDocumentMeta = (response) => {
        return this.exportDocumentCuboid(response)
            .then(this.exportHeaderDocumentCuboid)
            .then(this.verifyOrder);
    };
    linkDocument(targetDocManager) {
        targetDocManager.loadDocument(this.data.documentId);
        const url =
            "workflowSharing/linkSource?tableId=" +
            this.data.documentId +
            "&collaborationId=" +
            targetDocManager.getCollabId();
        return http
            .sendRestRequest(url, "POST")
            .then((data) => {
                if (data.Response !== "FAILURE") {
                    return targetDocManager
                        .exportHeaderDocumentCuboid()
                        .then(targetDocManager.verifyOrder);
                } else {
                    window.alert(
                        "Document already linked. Verifying workflow now.."
                    );
                    // return targetDocManager.verifyOrder();
                    return -1;
                }
            })
            .catch((errResp) => {
                console.log("error in link document : ", errResp);
            });
    }
    linkOutDocument(targetDocManager) {
        targetDocManager.loadDocument(this.data.documentId);
        const url =
            "workflowSharing/linkSource?tableId=" +
            this.data.documentId +
            "&collaborationId=" +
            targetDocManager.getCollabId();
        return http
            .sendRestRequest(url, "POST")
            .then((data) => {
                if (data.Response !== "FAILURE") {
                    alert("Document linked.");
                } else {
                    window.alert("Document already linked.");
                }
            })
            .catch((errResp) => {
                console.log(errResp);
            });
    }
    getRowsForNewCuboid = () => {
        let url = "readDocument?fileName=" + this.data.fileName;
        let rows = http
            .sendRestRequest(url, "get")
            .then((rowsData) => {
                return rowsData.Data;
            })
            .catch((errResp) => {
                console.log(errResp);
            });
        return rows;
    };
    setParent(parentCuboidId) {
        this.data.parentCuboidId = parentCuboidId;
    }
    setDocumentId(documentId) {
        this.data.documentId = documentId;
    }
    setDocumentHeaderId(documentHeaderId) {
        this.data.documentHeaderId = documentHeaderId;
    }
    setDocumentName(fileName) {
        this.data.fileName = fileName;
    }
    setHeaderDocumentName(fileName) {
        this.data.headerName = fileName + ".h";
    }
    setCollabId = (option) => {
        if (option.collabId !== undefined && option.collabId !== null) {
            this.data.collabId = option.collabId;
        }
        if (option.collabName !== undefined && option.collabName !== null) {
            this.data.collabName = option.collabName;
        }
    };

    setWbId = (option) => {
        if (option.wbId !== undefined && option.wbId !== null) {
            this.data.wbId = option.wbId;
        }
        if (option.wbname !== undefined && option.wbname !== null) {
            this.data.wbname = option.wbname;
        }
    };

    setPersistance() {
        this.data.isPersisted = true;
    }
}
export default Document;
