import React from "react";
import "./Style.css";
import { getIconPathByType } from "../../../../common/IconList";
function ListViewIntegration({ label, handleSettings }) {
    const renderImage = (label) => {
        switch (label) {
            case "GMAIL":
                return getIconPathByType("gmail_large");
            case "OUTLOOK":
                return getIconPathByType("outlook");
            default:
                break;
        }
    };
    const renderImgClass = (label) => {
        switch (label) {
            case "GMAIL":
                return "integrationGmailIcon";
            case "OUTLOOK":
                return "integrationOutlookIcon";
            default:
                break;
        }
    };
    const handleSettingClick = () => {
        handleSettings(label);
    };
    return (
        <div className="row m-0 intgrationCard mb-3">
            <div
                className="col-10"
                style={{ display: "flex", alignItems: "center" }}
            >
                <div className="row m-0 w-100">
                    <div className="col-2 p-0 intgrationMiddleCol">
                        <img
                            src={renderImage(label)}
                            alt=""
                            className={renderImgClass(label)}
                        />
                    </div>
                    <div className="col-10 pl-0 intgrationMiddleCol">
                        <span className="integraionTitle">{label}</span>
                    </div>
                </div>
            </div>
            <div className="col-2 p-0 intgrationRightCol doCenter">
                <span
                    id={"btn_settings_" + label}
                    className="integraionRightTitle cursorPointer"
                    onClick={handleSettingClick}
                >
                    Settings
                </span>
            </div>
        </div>
    );
}

export default ListViewIntegration;
