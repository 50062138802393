import React, { useState } from "react";
import CustomSnackbar from "../../../../../../stylized/CustomSnackbar/CustomSnackbar";
import DialogView from "../../../../../../stylized/DialogView";
import FormWrapper from "../../FormWrapper/FormWrapper";
import FilterListItem from "./FilterListItem";

function FilterListView({
    filterListObj,
    handleBackBtn,
    selectedIntegration,
    updateFilter,
    handleDeleteFilter,
}) {
    const [displayShow, setDisplayShow] = useState(false);
    const [showStepperForm, setShowStepperForm] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [curruntAction, setCurruntAction] = useState("createFilter");
    const renderFilterList = () => {
        let filterListArray = [];
        if (filterListObj && filterListObj.length > 0) {
            filterListObj.sort((a, b) => b.filterId - a.filterId);
            filterListObj.forEach((filterItem) => {
                filterListArray.push(
                    <FilterListItem
                        listItem={filterItem}
                        editFilter={handleEditFilter}
                        handleDeleteFilter={handleDeleteFilter}
                    ></FilterListItem>
                );
            });
            return filterListArray;
        } else {
            return (
                <div className="h-100 w-100 doCenter filterCreateNewText">
                    No Filter Found
                </div>
            );
        }
    };
    const handleEditFilter = (filterObj) => {
        setSelectedFilter(filterObj);
        setCurruntAction("updateFilter");
        setShowStepperForm(true);
    };
    const handleBackBtnClick = () => {
        handleBackBtn();
    };
    const updateFilterData = () => {
        updateFilter(selectedIntegration);
    };
    const handleCloseStepperForm = () => {
        setShowStepperForm(false);
    };
    const handleShowStepperForm = () => {
        setShowStepperForm(true);
    };
    const handleCreateNewFilter = () => {
        setSelectedFilter(null);
        setCurruntAction("createFilter");
        handleShowStepperForm(true);
    };

    const renderFormModal = () => {
        return (
            <DialogView
                show={showStepperForm}
                size="lg"
                handleClose={handleCloseStepperForm}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <FormWrapper
                    onHide={handleCloseStepperForm}
                    accountType={selectedIntegration}
                    hideFile={"true"}
                    Display={setDisplayShow}
                    saveButtonName={"NEXT"}
                    filterRawObj={selectedFilter}
                    updateFilterData={updateFilterData}
                    curruntAction={curruntAction}
                    filters={filterListObj}
                ></FormWrapper>
            </DialogView>
        );
    };
    return (
        <div className="intergrationFilterContainer pb-0">
            <div className="row m-0 mb-4 w-100">
                <img
                    className="cursorPointer"
                    onClick={handleBackBtnClick}
                    src="./images/Integration/back-arrow.png"
                    id="btn_integration_backBtn"
                    title="Back"
                    alt=""
                />
                <span className="integration_Title">
                    {selectedIntegration.toLowerCase() +
                        " Integration Settings"}
                </span>
            </div>
            <div className="row m-0 w-100 mt-4">
                <div
                    className="cursorPointer"
                    //style={{ marginLeft: "10px" }}
                    title={"New Filter"}
                    onClick={handleCreateNewFilter}
                    id={"btn_space_addspace"}
                    style={{ marginBottom: "10px", marginLeft: "8px" }}
                >
                    <img
                        src="./images/plus.svg"
                        className="bw_plusButton plusIcon"
                        title="New Filter"
                        alt="add filter"
                    ></img>
                    <span
                        id="title"
                        className="addLabel newFontMontserrat"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        New Filter
                    </span>
                </div>
            </div>
            <div className="filterListContainer invisibleScroller">
                {renderFilterList()}
                {showStepperForm && renderFormModal()}
            </div>
        </div>
    );
}

export default FilterListView;
