import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./components/functional/UnityCentral/App";
import reducers from "./reducers";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { usersAPI } from "./services/services/usersAPI";
import { workspaceAPI } from "./services/services/workspace";
import { spaceAPI } from "./services/services/space";
import { homeResourcesAPI } from "./services/services/home";
// import { store } from "./services/services/reduxStore";

const composeEnhancer =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

// ========================================

ReactDOM.render(
    <HMSRoomProvider>
        <Provider
            store={createStore(
                reducers,
                composeEnhancer(
                    applyMiddleware(
                        thunk,
                        usersAPI.middleware,
                        workspaceAPI.middleware,
                        spaceAPI.middleware,
                        homeResourcesAPI.middleware
                    )
                )
            )}
        >
            <App></App>
        </Provider>
    </HMSRoomProvider>,
    document.getElementById("root")
);
