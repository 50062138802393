import { Avatar } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

function ContactItemView({
    chatCard,
    value,
    title,
    subTitle,
    time,
    status,
    avatar,
}) {
    const activePage = useSelector((state) => state.activePage);
    return (
        <div className="contactBox row m-0 w-100">
            <div
                className={
                    activePage.toLowerCase() === "home" ? "col-2" : "col-3 pl-0"
                }
            >
                <div className="row m-0 w-100">
                    <div
                        className={
                            activePage.toLowerCase() === "home"
                                ? "col-5 p-0 pr-2 doCenter"
                                : "col-5 p-0 doCenter"
                        }
                        // className="col-5 p-0 doCenter"
                    >
                        <img
                            className={status ? "notificationDotHide" : ""}
                            src="./images/Ellipse 50.png"
                            alt=""
                        />
                    </div>
                    <div className="col-7 p-0  doCenter">
                        <Avatar className="avatar" aria-label="recipe">
                            {avatar}
                        </Avatar>
                    </div>
                </div>
            </div>
            <div
                className={
                    activePage.toLowerCase() === "home"
                        ? "col-7 pl-0 pr-2"
                        : "col-6 pl-0 pr-2"
                }
                // className="col-6 pl-0 pr-2"
            >
                <div className="row m-0 w-100">
                    <span className="contactTitle textEllipsis" title={title}>
                        {value}
                    </span>
                </div>
                <div className="row m-0 w-100">
                    <span
                        className="contactSubTitle textEllipsis"
                        title={subTitle}
                        style={{ fontWeight: !status ? "bold" : "normal" }}
                    >
                        {subTitle}
                    </span>
                </div>
            </div>
            <div className="col-3 p-0 doCenter">
                <div className="row m-0 w-100 timeStampDiv">
                    <span>{time}</span>
                </div>
            </div>
        </div>
    );
}

export default ContactItemView;
