import { Col, Card, Row } from "react-bootstrap";
import "./index.css";

export default function StatsCard(props) {
    const { title, amount, perc, subtitle, isCompare, amount2, isProActive } =
        props;
    const percAmount = Number(perc);
    const faClass = percAmount < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up";

    return (
        <Card
            className="card card-stats mb-4 mb-xl-0 "
            style={{
                borderRadius: "10px",
                border: " none",
                height: props.height,
                margin: "auto",
                width: "100%",
            }}
        >
            <div
                style={
                    isProActive
                        ? {
                              height: "63px",
                              marginBottom: "10px",
                          }
                        : { marginBottom: "10px" }
                }
            >
                <Card.Body className={isCompare ? "compareCards" : "statCards"}>
                    <Row
                        style={{
                            justifyContent: "left",
                            alignItems: "center",
                            padding: "8px",
                            height: "inherit",
                        }}
                    >
                        <div
                            className="dashboard_firstCol"
                            style={{
                                marginLeft: "12px",
                                width: "66%",
                                margintop: "-40px",
                            }}
                        >
                            <Col
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "50px",
                                }}
                            >
                                <div
                                    style={{
                                        flexBasis: "41%",
                                        minWidth: "40%",
                                    }}
                                >
                                    <h5
                                        className="card-title mb-0"
                                        title={title}
                                    >
                                        {title}
                                    </h5>
                                </div>
                                <div
                                    className="dashboard_ratio textEllipsis"
                                    style={{
                                        flexBasis: "30%",
                                        minWidth: "30%",
                                    }}
                                >
                                    <div title={amount}>
                                        <span className="h2 mb-0">
                                            {amount}
                                        </span>
                                    </div>
                                </div>
                                {isCompare ? (
                                    <div
                                        className="dashboard_ratio textEllipsis"
                                        style={{
                                            marginLeft: "10%",
                                            flexBasis: "30%",
                                            minWidth: "30%",
                                        }}
                                    >
                                        <div title={amount2}>
                                            <span className="h2 mb-0">
                                                {amount2}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </Col>
                        </div>
                    </Row>
                </Card.Body>
            </div>
        </Card>
    );
}
