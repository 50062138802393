import React, { useState } from "react";
import ReactFlow, {
    Handle,
    getBezierPath,
    getMarkerEnd,
} from "react-flow-renderer";
import "./index.css";
import ControlTower from "../../../bae-client/ControlTower";
import http from "../../../bae-client/HTTP";
import Button from "../../stylized/Button";
import CheckboxView from "../../stylized/CheckboxView";
import InlineRow from "../../stylized/InlineRow";
import AlertDialog from "../../stylized/AlertDialog";
import ListView from "../../stylized/ListView";
import PopoverViewCustom from "../../stylized/PopoverViewCustom";
import TitleBar from "./TitleBar";
import Loader from "../../stylized/Loader";
function RFCTVTest(props) {
    const [showLoader,setShowLoader]=useState(false)
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [genericBlockchainList, setGenericBlockchainList] = useState(null);
    const [selectedGenericBlockchain, setSelectedGenericBlockchain] = useState({
        genericTblName: "",
        genericTblId: "",
        instanceRegistryTblId: "",
    });
    const [genericBlockchainDDList, setGenericBlockchainDDList] = useState([]);
    const [instanceList, setInstanceList] = useState(null);
    const [instanceDropdownList, setInstanceDropdownList] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState({
        instanceId: "",
        instanceName: "",
    });
    const elements = [
        {
            id: "N1",
            type: "circle",
            position: { x: 100, y: 100 },
            data: { text: "N1", labelText: "Text : Node1" },
        },
        {
            id: "N2A",
            type: "circle",
            position: { x: 300, y: 100 },
            data: { text: "N2A", labelText: "Text : Node2A" },
        },
        {
            id: "N2B",
            type: "circle",
            position: { x: 300, y: 300 },
            data: { text: "N2B", labelText: "Text : Node2B" },
        },
        {
            id: "N2C",
            type: "circle",
            position: { x: 300, y: 500 },
            data: { text: "N2C", labelText: "Text : Node2C" },
        },
        {
            id: "N3A",
            type: "circle",
            position: { x: 500, y: 100 },
            data: { text: "N3A", labelText: "Text : Node3A" },
        },
        {
            id: "N3B",
            type: "circle",
            position: { x: 500, y: 300 },
            data: { text: "N3B", labelText: "Text : Node3B" },
        },
        {
            id: "N4",
            type: "circle",
            position: { x: 700, y: 100 },
            data: { text: "N4", labelText: "Text : Node4" },
        },
        {
            id: "N1N2A",
            source: "N1",
            target: "N2A",
            type: "StraightEdge", // 'custom',
            data: { text: "N1N2A" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N1N2B",
            source: "N1",
            target: "N2B",
            type: "StraightEdge", // 'custom',
            data: { text: "N1N2B" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N1N2C",
            source: "N1",
            target: "N2C",
            type: "StraightEdge", // 'custom',
            data: { text: "N1N2C" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N2AN3A",
            source: "N2A",
            target: "N3A",
            type: "StraightEdge", // 'custom',
            data: { text: "N2AN3A" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N2BN3B",
            source: "N2B",
            target: "N3B",
            type: "StraightEdge", // 'custom',
            data: { text: "N2BN3B" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N3AN4",
            source: "N3A",
            target: "N4",
            type: "StraightEdge", // 'custom',
            data: { text: "N3AN4" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N3BN4",
            source: "N3B",
            target: "N4",
            type: "StraightEdge", // 'custom',
            data: { text: "N3BN4" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N2AN2B",
            source: "N2A",
            target: "N2B",
            type: "StraightEdge", // 'custom',
            data: { text: "N2AN2B" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
        {
            id: "N2CN2B",
            source: "N2C",
            target: "N2B",
            type: "StraightEdge", // 'custom',
            data: { text: "N2CN2B" },
            arrowHeadType: "arrowclosed",
            //straight: "StraightEdge",
        },
    ];
    const customNodeStyles = {
        background: "#9CA8B3",
        color: "#FFF",
        padding: 10,
    };

    const RFCircleStyle = {
        // background: '#9CA8B3',
        // color: '#FFF',
        padding: 10,
        borderRadius: "50%",
        height: "100px",
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: "bold",
    };

    // starting_angle_degree = 135;
    // Note: Ending angle will be end_angle_degree + 90;
    // angle_to_increment = 90/(Number_of_handle + 1)
    // angle = starting_point_degree + angle_to_increment; // it will be executed multiple time.
    // bounding_box -> (minX, minY), (MaxX, maxY) --> midpoint will be center(cx, cy)
    // radius --> need to calculate using bounidng box
    // left top point = minx, minY
    // left bottom point = minX, MaxY
    // center(top + bottom) = (left top + left bottom)*0.5;
    // distance between center(top + bottom) and (cx, cy) = radius
    function pointsOnCircumferenceOfCircle(radius, angle, cx, cy) {
        angle = angle * (Math.PI / 180); // Convert from Degrees to Radians
        const x = cx + radius * Math.cos(angle);
        const y = cy + radius * Math.sin(angle);
        return { xpoint: x, ypoint: y };
    }

    const RFCircleShape = ({ data }) => {
        let test1 = pointsOnCircumferenceOfCircle(50, 0, 50, 50);
        let test2 = pointsOnCircumferenceOfCircle(50, 90, 50, 50);
        let test3 = pointsOnCircumferenceOfCircle(50, 180, 50, 50);
        let test4 = pointsOnCircumferenceOfCircle(50, 270, 50, 50);
        //console.log(test1, test2);
        //console.log(data);
        return (
            <>
                <div
                    className="doCenter"
                    style={{ color: "#030092", fontWeight: "bold" }}
                >
                    {data.labelText ? data.labelText : null}
                </div>
                <div style={RFCircleStyle} className="circleShapeColor">
                    {/* <Handle type="target" position="left" style={{ borderRadius: 0 }} />
            <div>{data.text}</div>
            <Handle type="source" position="right" style={{ borderRadius: 0 }} /> */}

                    <Handle
                        id="s1"
                        type="source"
                        position="left"
                        style={{
                            top: test1.ypoint,
                            left: test1.xpoint,
                            borderRadius: 0,
                            opacity: "0",
                        }}
                    />
                    <Handle
                        id="s2"
                        type="source"
                        position="left"
                        style={{
                            top: test2.ypoint,
                            left: test2.xpoint,
                            borderRadius: 0,
                            opacity: "0",
                        }}
                    />

                    <div>{data.text}</div>
                    <Handle
                        id="t1"
                        type="target"
                        position="right"
                        style={{
                            top: test3.ypoint,
                            left: test3.xpoint,
                            borderRadius: 0,
                            opacity: "0",
                        }}
                    />
                    <Handle
                        id="t2"
                        type="target"
                        position="right"
                        style={{
                            top: test4.ypoint,
                            left: test4.xpoint,
                            borderRadius: 0,
                            opacity: "0",
                        }}
                    />
                </div>
            </>
        );
    };
    const CustomEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        arrowHeadType,
        markerEndId,
    }) => {
        const edgePath = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });
        const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

        return (
            <>
                <path
                    id={id}
                    style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                />
                <text>
                    <textPath
                        href={`#${id}`}
                        style={{ fontSize: "12px" }}
                        startOffset="50%"
                        textAnchor="middle"
                    >
                        {data.text}
                    </textPath>
                </text>
            </>
        );
    };
    const nodeTypes = {
        //rectangle: RFRectangleShape,
        circle: RFCircleShape,
        //square: RFSquareShape
    };
    const edgeTypes = {
        custom: CustomEdge,
    };
    const renderTitle = (props) => {
        return (
            <TitleBar
                username={props.username}
                title={"Control Tower View"}
            ></TitleBar>
        );
    };
    const renderReactFlow = () => {
        return (
            <div style={{ height: 700 }}>
                <ReactFlow
                    elements={elements}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                />
            </div>
        );
    };
    const handleGenericBlockchainChnage = (
        index,
        listValue,
        listText,
        event
    ) => {
        genericBlockchainList.forEach((step, stepIndex) => {
            if (step.genericTblName === listValue) {
                //setSelectedGenericBlockchain(obj);
                setSelectedGenericBlockchain(() => ({
                    genericTblName: step.genericTblName,
                    genericTblId: step.genericTblId,
                    instanceRegistryTblId: step.instanceRegistryTblId,
                }));
            }
        });
    };
    const handleInstanceChnage = (index, listValue, listText, event) => {
        instanceList.forEach((step, stepIndex) => {
            if (step.instanceName === listValue) {
                setSelectedInstance(() => ({
                    instanceId: step.instanceId,
                    instanceName: step.instanceName,
                }));
            }
        });
    };
    const retriveGenericBlockchainList = () => {
        return new Promise((resolve, reject) => {
            let url = "genericBlockchain/genericList";
            http.sendRestRequest(url)
                .then((response) => {
                    setGenericBlockchainList(response);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    reject(error);
                });
        });
    };
    const renderGenericBlockchainDD = () => {
        setShowLoader(true)
        if (!genericBlockchainList) {
            let controlTower = new ControlTower();
            controlTower
                .retrieveGenericBlockchainList()
                .then((response) => {
                    let genericSteps = [];
                    response.forEach((instance) => {
                        let obj = {};
                        obj["text"] = instance.genericTblName;
                        obj["value"] = instance.genericTblName;
                        obj["id"] = instance.genericTblId;
                        genericSteps.push(obj);
                    });
                    setGenericBlockchainList(response);
                    setGenericBlockchainDDList(genericSteps);
                    setShowLoader(false)
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                    setShowLoader(false)
                });
        }
        if (genericBlockchainDDList.length === 0) {
            genericBlockchainDDList.push({
                text: "ITEM 1",
                value: "ITEM 1",
                id: 101,
            });
        }
        return (
            <div className="nameRippleBtn ml-3">
                <PopoverViewCustom
                    btnClass={"ctBtnFont"}
                    iconClass="fas fa-angle-down" //btnLabel="Generic Blockchain Name"
                    btnLabel={
                        selectedGenericBlockchain.genericTblName !== ""
                            ? selectedGenericBlockchain.genericTblName
                            : "Generic Blockchain Name"
                    }
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={genericBlockchainDDList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleGenericBlockchainChnage}
                    />
                </PopoverViewCustom>
            </div>
        );
    };
    const renderInstanceNameDD = () => {
        setShowLoader(true)
        if (!instanceList && selectedGenericBlockchain.genericTblId !== "") {
            let controlTower = new ControlTower();
            controlTower
                .retriveInstanceList(selectedGenericBlockchain.genericTblId)
                .then((response) => {
                    let instances = [];
                    response.forEach((item) => {
                        let obj = {};
                        obj["text"] = item.instanceName;
                        obj["value"] = item.instanceName;
                        obj["id"] = item.instanceId;
                        instances.push(obj);
                    });
                    setInstanceList(response);
                    setInstanceDropdownList(instances);
                    setShowLoader(false)
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                    setShowLoader(false)
                });
        }
        if (instanceDropdownList.length === 0) {
            instanceDropdownList.push({
                text: "ITEM 1",
                value: "ITEM 1",
                id: 101,
            });
        }
        return (
            <div className="nameRippleBtn ml-3">
                <PopoverViewCustom
                    btnClass={"ctBtnFont"}
                    iconClass="fas fa-angle-down"
                    btnLabel={
                        selectedInstance.instanceName !== ""
                            ? selectedInstance.instanceName
                            : "Instance Name"
                    }
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"InstanceName"}
                        items={instanceDropdownList}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleInstanceChnage}
                    />
                </PopoverViewCustom>
            </div>
        );
    };
    const getInstanceStepList = () => {
        let workspaceItems = [];
        workspaceItems.push({
            text: "ITEM 1",
            value: "ITEM 1",
            id: 101,
        });
        workspaceItems.push({
            text: "ITEM 2",
            value: "ITEM 2",
            id: 102,
        });
        return (
            <div className="nameRippleBtn ml-3">
                <PopoverViewCustom
                    btnClass={"ctBtnFont"}
                    btnLabel="Instance Name"
                    iconClass="fas fa-angle-down"
                >
                    <CheckboxView items={workspaceItems}> </CheckboxView>
                </PopoverViewCustom>
            </div>
        );
    };
    const renderDropdowns = () => {
        return (
            <InlineRow classname="rippleTopbar mt-2 doCenter">
                <div className="doCenter mr-1 ml-2">
                    {renderGenericBlockchainDD()}
                    {renderInstanceNameDD()}
                </div>
            </InlineRow>
        );
    };
    return (
        <>
            <div className="col-10 defaultScroller">
                <div className="container w-100 h-100 pl-0 pr-0">
                    {renderTitle(props)}
                    {renderDropdowns()}
                    {renderReactFlow()}
                </div>
            </div>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
}

export default RFCTVTest;
