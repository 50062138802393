export const setSelectedGenericDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_GENERIC_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedInstanceDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_INSTANCE_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedTransactionDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedPromiseDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_PROMISE_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedExceptionDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedExceptionStatusDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedToDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_TO_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setDashboardSearchTextFilterOne = (data) => {
    return {
        type: "SET_DASHBOARD_SEARCH_TEXT_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedFromDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_FROM_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedTimeDropdownValueFilterOne = (data) => {
    return {
        type: "SET_SELECTED_TIME_DROPDOWN_VALUE_FILTER_ONE",
        payload: data,
    };
}
export const setSelectedGenericDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_GENERIC_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedInstanceDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_INSTANCE_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedTransactionDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedPromiseDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_PROMISE_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedExceptionDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedExceptionStatusDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedToDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_TO_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setDashboardSearchTextFilterTwo = (data) => {
    return {
        type: "SET_DASHBOARD_SEARCH_TEXT_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedFromDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_FROM_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}
export const setSelectedTimeDropdownValueFilterTwo = (data) => {
    return {
        type: "SET_SELECTED_TIME_DROPDOWN_VALUE_FILTER_TWO",
        payload: data,
    };
}