import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ModalView from "../../../../stylized/ModalView";
import SelectBox from "../../../../stylized/SelectBox";
import "./RippleRecureModal.css";
import { Switch } from "@mui/material";
import { convertUTCTimeToLocalTime } from "../../../../../bae-client/Utils";
function RecureModalView(props) {
    let selectedTimestr = new Date().toUTCString().split(" ")[4];
    let newTime = 0;
    const [selectedTime, setselectedTime] = useState(selectedTimestr);
    const [checked, setChecked] = useState(true);
    const [isClick, setClick] = useState(false);
    const [selectedDate, setselectedDate] = useState(new Date());
    const [selectedEveryCount, setselectedEveryCount] = useState(0);
    const [selectedEveryType, setselectedEveryType] = useState("Weeks");
    const [selectedCuboidIndex, setselectedCuboidIndex] = useState(0);
    const [monday, setMonday] = useState(false);
    const [tue, setTue] = useState(false);
    const [thus, setThus] = useState(false);
    const [wed, setWed] = useState(false);
    const [fri, setFri] = useState(false);
    const [sat, setSat] = useState(false);
    const [sun, setSun] = useState(false);
    const everyItems = [
        {
            text: 1,
            value: 1,
        },
        {
            text: 2,
            value: 2,
        },
        {
            text: 3,
            value: 3,
        },
        {
            text: 4,
            value: 4,
        },
        {
            text: 5,
            value: 5,
        },
        {
            text: 6,
            value: 6,
        },
    ];
    const weeksItems = [
        {
            text: "Weeks",
            value: "Weeks",
        },
        {
            text: "Months",
            value: "Months",
        },
        {
            text: "Quarters",
            value: "Quarters",
        },
        {
            text: "Years",
            value: "Years",
        },
    ];

    useEffect(() => {
        if (props.data) {
            if (props.data.split("|")[0] === "DISABLE") {
                setChecked(false);
            }
            if (props.data.split("|")[1] === selectedTime) {
                setselectedTime(selectedTimestr);
            } else if (props.data.length === 0) {
                setselectedTime(selectedTimestr);
            } else if (props.data.split("|")[0] === "DISABLE") {
                setselectedTime(props.data.split("|")[2]);
            } else {
                setselectedTime(props.data.split("|")[1]);
            }
            // if (rowItem.rippleRecur.split("|")[0] === "DISABLE")

            const recureString = props.data;
            const valueChck = recureString.split("|").length;
            if (recureString !== "" && recureString !== undefined) {
                if (recureString.includes("@")) {
                    const weekString = recureString.split("@")[0];
                    updateWeeks(weekString);
                }
                if (recureString.includes("-")) {
                    const everyString = recureString
                        .split("@")[1]
                        .split("-")[1];
                    updateEvery(everyString);
                } else if (valueChck === 2) {
                    const days = recureString.split("|")[0];
                    const time = recureString.split("|")[1];
                    const validTime = time.split(":");
                    if (
                        validTime.length === 3 &&
                        validTime[0].length === 2 &&
                        validTime[1].length === 2 &&
                        validTime[2].length === 2
                    )
                        setExistingTime(time);

                    days.split(",").map((item) => {
                        switch (item) {
                            case "MON":
                                setMonday(true);
                                break;
                            case "TUE":
                                setTue(true);
                                break;
                            case "WED":
                                setWed(true);
                                break;
                            case "THU":
                                setThus(true);
                                break;
                            case "FRI":
                                setFri(true);
                                break;
                            case "SAT":
                                setSat(true);
                                break;
                            case "SUN":
                                setSun(true);
                                break;
                            default:
                                break;
                        }
                    });
                } else if (valueChck === 3) {
                    const days = recureString.split("|")[1];
                    const time = recureString.split("|")[2];
                    const validTime = time.split(":");
                    if (
                        validTime.length === 3 &&
                        validTime[0].length === 2 &&
                        validTime[1].length === 2 &&
                        validTime[2].length === 2
                    )
                        setExistingTime(time);
                    days.split(",").map((item) => {
                        switch (item) {
                            case "MON":
                                setMonday(true);
                                break;
                            case "TUE":
                                setTue(true);
                                break;
                            case "WED":
                                setWed(true);
                                break;
                            case "THU":
                                setThus(true);
                                break;
                            case "FRI":
                                setFri(true);
                                break;
                            case "SAT":
                                setSat(true);
                                break;
                            case "SUN":
                                setSun(true);
                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    setExistingTime(recureString);
                }
            }
        }
    }, [props.data]);
    const updateEvery = (everyString) => {
        if (everyString !== "") {
            const selectedEvery = everyString.split(" ")[0];
            const selectedType = everyString.split(" ")[1];
            if (selectedEvery !== undefined) {
                everyItems.forEach((key, index) => {
                    if (key.value === selectedEvery) {
                        setselectedCuboidIndex(index);
                    }
                });
            }
            if (selectedType !== undefined) {
                weeksItems.forEach((key, index) => {
                    if (key.value === selectedType) {
                        setselectedCuboidIndex(index);
                    }
                });
            }
            setselectedEveryCount(everyString.split(" ")[0]);
            setselectedEveryType(everyString.split(" ")[1]);
        }
    };
    const updateWeeks = (weekString) => {
        if ((weekString !== undefined && weekString) !== "") {
            const daysArray = weekString.split(",");
            daysArray.forEach((key, index) => {
                switch (key) {
                    case "M":
                        setMonday(true);
                        break;
                    case "T":
                        setTue(true);
                        break;
                    case "W":
                        setWed(true);
                        break;
                    case "TH":
                        setThus(true);
                        break;
                    case "F":
                        setFri(true);
                        break;
                    case "S":
                        setSat(true);
                        break;
                    case "SU":
                        setSun(true);
                        break;
                    default:
                        break;
                }
            });
        }
    };
    const setExistingTime = (dateString) => {
        let existingTime = convertUTCTimeToLocalTime(dateString)
            .split(" ")[0]
            .split(":");
        let existingAA = convertUTCTimeToLocalTime(dateString).split(" ")[1];
        let existingHH = 0;
        if (existingAA === "PM") {
            existingHH = 12 + parseInt(existingTime[0]);
        } else {
            existingHH = existingTime[0];
        }
        let existingMM = existingTime[1];
        let existingSS = existingTime[2];

        let curruntDate = new Date();
        curruntDate.setHours(existingHH);
        curruntDate.setMinutes(existingMM);
        curruntDate.setSeconds(existingSS);

        setselectedDate(curruntDate);
    };

    const setStartDate = (date) => {
        // sending time by UTC
        let dateObj = new Date(date);

        let UTCTime = dateObj.toUTCString().split(" ")[4];
        setselectedDate(date);

        setselectedTime(UTCTime);
    };
    const handleBtnClickInModal = () => {
        let dateObj = {
            mon: monday,
            tue: tue,
            thu: thus,
            wed: wed,
            fri: fri,
            sat: sat,
            sun: sun,
        };
        props.onSubmit(
            selectedEveryCount,
            selectedEveryType,
            selectedTime,
            dateObj,
            isClick
        );
    };
    const handleClick = () => {
        setClick(!isClick);
    };

    const handleChange = (e) => {
        setChecked(e.target.checked);
    };

    const handleEveryItemOnchange = (index, text, value, event) => {
        setselectedEveryCount(value);
    };
    const handleWeekItemOnchange = (index, text, value, event) => {
        setselectedEveryType(value);
    };
    const handleDayChange = (index) => {
        switch (index) {
            case 1:
                monday === false ? setMonday(true) : setMonday(false);
                break;
            case 2:
                tue === false ? setTue(true) : setTue(false);
                break;
            case 3:
                wed === false ? setWed(true) : setWed(false);
                break;
            case 4:
                thus === false ? setThus(true) : setThus(false);
                break;
            case 5:
                fri === false ? setFri(true) : setFri(false);
                break;
            case 6:
                sat === false ? setSat(true) : setSat(false);
                break;
            case 7:
                sun === false ? setSun(true) : setSun(false);
                break;
            default:
                break;
        }
    };

    const renderButtonGroup = () => {
        return (
            <div
                className={
                    checked === false
                        ? "weekDays-selector notAllowed"
                        : "weekDays-selector"
                }
            >
                <input
                    type="checkbox"
                    id="weekday-mon"
                    className="weekday"
                    onChange={(e) => handleDayChange(1)}
                />
                <label
                    for="weekday-mon"
                    className={
                        checked === false && monday === true
                            ? "recure_On_label selectedDate1"
                            : monday === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    MON
                </label>
                <input
                    type="checkbox"
                    id="weekday-tue"
                    className="weekday"
                    onChange={(e) => handleDayChange(2)}
                />
                <label
                    for="weekday-tue"
                    className={
                        checked === false && tue === true
                            ? "recure_On_label selectedDate1"
                            : tue === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    TUE
                </label>
                <input
                    type="checkbox"
                    id="weekday-wed"
                    className="weekday"
                    onChange={(e) => handleDayChange(3)}
                />
                <label
                    for="weekday-wed"
                    className={
                        checked === false && wed === true
                            ? "recure_On_label selectedDate1"
                            : wed === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    WED
                </label>
                <input
                    type="checkbox"
                    id="weekday-thu"
                    className="weekday"
                    onChange={(e) => handleDayChange(4)}
                />
                <label
                    for="weekday-thu"
                    className={
                        checked === false && thus === true
                            ? "recure_On_label selectedDate1"
                            : thus === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    THU
                </label>
                <input
                    type="checkbox"
                    id="weekday-fri"
                    className="weekday"
                    onChange={(e) => handleDayChange(5)}
                />
                <label
                    for="weekday-fri"
                    className={
                        checked === false && fri === true
                            ? "recure_On_label selectedDate1"
                            : fri === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    FRI
                </label>
                <input
                    type="checkbox"
                    id="weekday-sat"
                    className="weekday"
                    onChange={(e) => handleDayChange(6)}
                />
                <label
                    for="weekday-sat"
                    className={
                        checked === false && sat === true
                            ? "recure_On_label selectedDate1"
                            : sat === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    SAT
                </label>
                <input
                    type="checkbox"
                    id="weekday-sun"
                    className="weekday"
                    onChange={(e) => handleDayChange(7)}
                />
                <label
                    for="weekday-sun"
                    className={
                        checked === false && sun === true
                            ? "recure_On_label selectedDate1"
                            : sun === true
                            ? "recure_On_label selectedDate"
                            : "recure_On_label"
                    }
                >
                    SUN
                </label>
            </div>
        );
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={props.show}
            size="md"
            type="submit-cancel"
            title="Set"
            value="Set"
            id="addWorkspaceModalBtn"
            onHide={props.onHide}
            onclick={props.onclick}
            submitButtonText="Close"
            onSubmit={
                checked === false && props.data.split("|")[0] === "DISABLE"
                    ? props.onclick
                    : handleBtnClickInModal
            }
        >
            <div className="container">
                <h3
                    className="ripple-heading"
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "24px",
                    }}
                >
                    {props.isDashboardConfig
                        ? "Add Frequency"
                        : "Create Recurrance"}
                </h3>
                {props.isDashboardConfig ? null : (
                    <p
                        className="ripple-subheading"
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "16px",
                        }}
                    >
                        Re-run the Ripple and display results as an alert at the
                        follow time:
                    </p>
                )}

                <div
                    style={{
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {props.data ? (
                        <>
                            <label
                                className="m-0 mr-2 rippleLog_label"
                                style={{
                                    paddingLeft: "10px",
                                    paddingTop: "26px",
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                }}
                            >
                                Active:
                            </label>

                            <Switch
                                checked={checked}
                                onClick={handleClick}
                                onChange={handleChange}
                            ></Switch>
                        </>
                    ) : null}
                </div>
                <div className="row mb-4 w-100 m-0">
                    <div className="col-6 p-0" style={{ display: "flex" }}>
                        <label
                            className="m-0 mr-2 rippleLog_label"
                            style={{
                                paddingLeft: "10px",
                                paddingTop: "26px",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Select a day:
                        </label>
                        {/* <SelectBox
                            id={"evrySelectBox"}
                            {...(props.disabled !== undefined &&
                            props.disabled !== null
                                ? { disabled: props.disabled }
                                : null)}
                            // showEmptyText="select Time"
                            label="Occurs Every :"
                            classForSelectBox="box_btn recureModal_count_select"
                            className="inlineSelectboxLabel rippleLog_label w-100"
                            // setEmptyValue={-1}
                            items={everyItems}
                            onchange={handleEveryItemOnchange}
                            selectedIndex={selectedCuboidIndex}
                            disabled={true}
                        /> */}
                    </div>
                    {/* <div className="col-6">
                        <SelectBox
                            id={"weekSelectBox"}
                            {...(props.disabled !== undefined &&
                            props.disabled !== null
                                ? { disabled: props.disabled }
                                : null)}
                            // showEmptyText="select"
                            classForSelectBox="box_btn recureModal_week_select"
                            className="inlineSelectboxLabel"
                            // setEmptyValue={-1}
                            items={weeksItems}
                            onchange={handleWeekItemOnchange}
                            selectedIndex={selectedCuboidIndex}
                            disabled={true}
                        />
                    </div> */}
                </div>
                <div
                    className="row mb-4 mt-4 w-100 m-0"
                    style={{ alignItems: "center" }}
                >
                    {/* <label className="m-0 mr-2 rippleLog_label">On : </label> */}
                    {renderButtonGroup()}
                </div>
                <div className="row mb-4 mt-4 w-100 m-0 test">
                    <div className="recureTimeRow">
                        <label
                            className="m-0 mr-2 rippleLog_label"
                            style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            Select a Time :
                        </label>
                        <DatePicker
                            style={{ zIndex: 1 }}
                            selected={selectedDate}
                            onChange={setStartDate}
                            showTimeSelect
                            showTimeSelectOnly
                            showPopperArrow={true}
                            className={
                                checked === false
                                    ? "nameRippleBtn invisibleScroller notAllowed"
                                    : "nameRippleBtn invisibleScroller cursorPointer"
                            }
                            timeIntervals={15}
                            // timeCaption=""
                            dateFormat="h:mm aa"
                        />
                    </div>
                </div>
            </div>
        </ModalView>
    );
}
export default RecureModalView;
