import http from "./HTTP";

class ControlTower {
    retrieveNodeLink = (genericId) => {
        return new Promise((resolve, reject) => {
            let url = "controlTower/nodeLinks?genericId=" + genericId;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve({
                        ...response,
                        exception: {
                            "Compliance Office": "red",
                        },
                    });
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    retrieveExceptions = (genericId, instanceId) => {
        return new Promise((resolve, reject) => {
            let url = `documentChain/exception/instance?genericId=${genericId}&instanceId=${instanceId}`;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    retrieveInstanceList = (genericTblId) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericInstances?genericTblId=2000356
            let url = "documentChain/instance/list?genericId=" + genericTblId;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    retrieveInstanceDetails = (genericId, instanceId) => {
        return new Promise((resolve, reject) => {
            let url = `documentChain/instanceData?genericId=${genericId}&instanceId=${instanceId}`;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    retrieveGenericBlockchainList = () => {
        return new Promise((resolve, reject) => {
            let url = "documentChain/generic/list";
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    updateNodePositions = (genericId, payload) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/controlTowerNodePosition?genericTblId=2000703
            let url = `controlTower/nodesPosition?genericId=${genericId}`;
            http.sendRestRequest(url, "PUT", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    updateInstanceInfo = (
        blockChainId,
        instanceInformationTblId,
        instanceId,
        payload
    ) => {
        let url = `instanceBlockchain/instanceInformation?instanceBlockchainId=${blockChainId}&instanceInformationTblId=${instanceInformationTblId}&instanceId=${instanceId}`;
        let buffer = {};
        if (Object(payload.hasOwnProperty("Promise Date")))
            buffer["Promised Date"] = payload["Promise Date"];
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "PUT", buffer)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    retrieveGenericList = () => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericList
            let url = "genericBlockchain/genericList";
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    };
    retrieveGenericSteps = (genericTblId) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericSteps?genericTblId=2000356
            let url =
                "genericBlockchain/genericSteps?genericTblId=" + genericTblId;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    };
    retrieveGenericInstanceList = (genericTblId) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/genericBlockchain/genericInstances?genericTblId=2000356
            let url =
                "genericBlockchain/genericInstances?genericTblId=" +
                genericTblId;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    };
    retrieveGenericInstanceInformation = (
        instanceRegistryTblId,
        instanceId
    ) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/instance?instanceRegistryTblId=2000378&instanceId=8979
            let url =
                "instanceBlockchain/instance?instanceRegistryTblId=" +
                instanceRegistryTblId +
                "&instanceId=" +
                instanceId;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    };
    addOrUpdateNewInstance = (instanceBlockchainTblId, method, data) => {
        return new Promise((resolve, reject) => {
            //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/object?instanceBlockchainTblId=2000380
            let url =
                "instanceBlockchain/object?instanceBlockchainTblId=" +
                instanceBlockchainTblId;
            http.sendRestRequest(url, method, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    console.log(error);
                });
        });
    };

    updateInstanceDetailsCuboid = (genericTblId, instanceId) => {
        return new Promise((resolve, reject) => {
            let url = `instanceDetails/objects?genericTblId=${genericTblId}&instanceId=${instanceId}`;
            http.sendRestRequest(url, "POST")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };

    updateInstanceRegistryCuboid = (genericTblId) => {
        return new Promise((resolve, reject) => {
            let url = `instanceBlockchain/instanceRegistry?genericTblId=${genericTblId}`;
            http.sendRestRequest(url, "PUT")
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
}
export default ControlTower;
