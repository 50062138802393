import React, { useEffect } from "react";
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import { Button, InputBase } from "@mui/material";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon
import Menu from "@mui/material/Menu";
const columns = [
    { id: "user", label: "Name" },
    { id: "emailAddress", label: "Email" },
    { id: "userType", label: "Internal/External" },
    { id: "action", label: "Action" },
];

const DirectoryTable = ({
    data,
    directoryName,
    openInviteModal,
    handleClose,
    openRemoveModal,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(data.length / rowsPerPage);
    const [APIData, setAPIData] = React.useState(data);
    const [filteredResults, setFilteredResults] = React.useState([]);
    const [searchInput, setSearchInput] = React.useState("");
    const [actionRow, setActionRow] = React.useState(null);

    useEffect(() => {
        if (searchInput !== "") {
            const filteredData = data.filter((item) => {
                return item.emailAddress
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
            });
            setFilteredResults(filteredData);
        }
        let searchStr = document.getElementById("search_text").value;
        searchStr !== "" && searchItems(searchStr);
    }, [data]);
    useEffect(() => {
        if (searchInput !== "") {
            const filteredData = data.filter((item) => {
                return item.emailAddress
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
            });
            setFilteredResults(filteredData);
        }
    }, [searchInput]);
    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const paginatedData = data.slice(startIndex, endIndex);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const openModal = () => {
        openRemoveModal(actionRow);
        setAnchorEl(null);
    };

    const handleRowClick = (row) => {
        setActionRow(row);
    };
    return (
        <div>
            <div
                className="table-title"
                style={{
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "29px",
                    letterSpacing: "0.5142599940299988px",
                }}
            >
                {directoryName}
            </div>
            <div className="search-table">
                <div className="search-box">
                    <img
                        src="./images/sev.svg"
                        alt=""
                        style={{
                            width: "15px",
                            height: "18px",
                            marginLeft: "10px",
                        }}
                    />
                    <InputBase
                        id="search_text"
                        placeholder="Search users"
                        style={{
                            flex: 1,
                            paddingLeft: "12px",
                            fontFamily: "Montserrat",
                        }}
                        onChange={(e) => searchItems(e.target.value)}
                    />
                </div>
                <div>
                    <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={openInviteModal}
                    >
                        Invite Contacts
                    </Button>
                </div>
            </div>

            <TableContainer component={Paper}>
                {searchInput.length > 0 && filteredResults.length === 0 ? (
                    <div style={{ textAlign: "center", padding: "12%" }}>
                        No Results Found.
                    </div>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow style={{ paddingRight: "19px" }}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        className="table-head"
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(searchInput.length > 0 ? filteredResults : data)
                                .slice(
                                    page * rowsPerPage,
                                    (page + 1) * rowsPerPage
                                )
                                .map((row) => (
                                    <TableRow key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={
                                                    column.id === "user"
                                                        ? { width: "203px" }
                                                        : {}
                                                }
                                                onClick={() =>
                                                    handleRowClick(row)
                                                }
                                            >
                                                {column.id === "user" ? (
                                                    <div
                                                        className="p-0 doCenter"
                                                        style={{
                                                            marginLeft: "-30px",
                                                        }}
                                                    >
                                                        <Avatar
                                                            aria-label="user"
                                                            style={{
                                                                background:
                                                                    "#1B75BC",
                                                                fontSize:
                                                                    "medium",
                                                                height: "39px",
                                                                width: "39px",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                        <div
                                                            className="textEllipsis"
                                                            style={{
                                                                marginLeft:
                                                                    "10px",
                                                                width: "100px",
                                                            }}
                                                            title={`${row.firstName} ${row.lastName}`}
                                                        >
                                                            {`${row.firstName} ${row.lastName}`}
                                                        </div>
                                                    </div>
                                                ) : column.id === "action" &&
                                                  row.access ===
                                                      "Read/Write" ? (
                                                    <img
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                row
                                                            )
                                                        }
                                                        src="./images/options.png"
                                                        alt="Options"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                ) : (
                                                    row[column.id]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                >
                    <MenuItem id="deleteGen" onClick={openModal}>
                        <div>
                            <img
                                className="deleteIcon"
                                src="./images/delete.svg"
                                alt="Remove"
                                title="Remove"
                            />
                            <span className="btn_title" title="Remove">
                                Remove
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={
                    searchInput.length > 0
                        ? filteredResults.length
                        : data.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ height: "15%", flexBasis: "15%", overflow: "hidden" }}
            />
        </div>
    );
};

export default DirectoryTable;
