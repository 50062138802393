import React from "react";
import { connect } from "react-redux";
import {
    setActivePage,
    setActiveSpace,
    setActiveWorkspace,
    setArchiveInfo,
    setBoardList,
    setCollabInfo,
    setCurrentClickedSpaceIndex,
    setCurrentSettingsPage,
    setFilesforUpload,
    setGmailManager,
    setJiraManager,
    setLeftPanelView,
    setPage,
    setRightPanelView,
    setSpaceManager,
    setAlertManager,
    setAlertsWidget,
    setWorkspaceManager,
    updateArchiveInfoAlert,
} from "../../../../../../../actions";
import {
    addSpace,
    fetchSpaceListData,
    updateSpaceName,
} from "../../../../../../../actions/SpaceActions";
import CollaborationFetcher from "../../../../../../../bae-client/CollaborationFetcher";
import CuboidManager from "../../../../../../../bae-client/CuboidManager";
import Document from "../../../../../../../bae-client/Document";
import DocumentUploader from "../../../../../../../bae-client/DocumentUploader";
import http from "../../../../../../../bae-client/HTTP";
import Space from "../../../../../../../bae-client/Space";
import SpaceManager from "../../../../../../../bae-client/SpaceManager";
import config from "../../../../../../../config";
import "../../../../../../../index.css";
import AlertDialog from "../../../../../../stylized/AlertDialog";
import CardView from "../../../../../../stylized/CardView";
import Column from "../../../../../../stylized/Column";
import IconView from "../../../../../../stylized/IconView";
import InlineRow from "../../../../../../stylized/InlineRow";
import ListView from "../../../../../../stylized/ListView";
import Loader from "../../../../../../stylized/Loader";
import ModalView from "../../../../../../stylized/ModalView";
import PopoverView from "../../../../../../stylized/PopoverView";
import SliderView from "../../../../../../stylized/SliderView";
import TextBox from "../../../../../../stylized/TextBox";
import SpaceHeader from "./SpaceHeader";
import SpaceViewerModal from "./SpaceViewerModal";
import * as Mixpanel from "../../../../../../../mix-panel/Mixpanel";

class SpaceViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cardLenght: 6,
            selectedSpace: "",
            selectedSpaceId: 0,
            selectedMenuItem: "",
            crouselActiveIndex: 0,
            cardlist: null,
            spaceOpen: false,
            showLoader: false,
            showPopover: false,
            popoverTarget: null,
            addSpaceModalShow: false,
            modalShow: false,
            updateSpaceName: null,
            isEmpty: false,
            newSpaceName: null,
            updatedSpaceName: null,
            showAlertModal: false,
        };
        this.activeSpaceId = [];
        this.cardIdList = [];
        this.selectedCrouselIndex = 0;
        this.spaceName = "";
        this.newDocumentName = "";
        this.updatedSpacename = null;
        this.newSpacename = null;
        this.isEmpty = false;
        let nhid = http.getNHId();
        this.collabFetcher = new CollaborationFetcher(nhid);
        this.space = new Space();
        this.workspaceCollabId = null;
        this.workspaceWBId = null;
        this.workspaceRegistryId = null;
        this.workspacePersistenceId = null;

        this.spaceWBId = null;
        this.spaceRegistryId = null;
        this.spacePersistenceId = null;
    }
    componentDidMount() {
        // if (
        //     this.props.boardList !== null &&
        //     this.props.boardList !== undefined &&
        //     this.props.boardList.length > 0
        // )
        this.computeSpaceCards();
        if (this.props.activeSpace !== null) {
            this.setState({ selectedSpaceId: this.props.activeSpace.spaceId });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.cuboidInfoByName !== null &&
            this.workspaceCollabId === null
        ) {
            let dataByName = this.props.cuboidInfoByName;
            this.workspaceCollabId = dataByName.get("__Workspace");
            this.workspaceWBId = dataByName.get("Workspace");
            this.workspaceRegistryId = dataByName.get("Workspace Registry");
            this.workspacePersistenceId = dataByName.get(
                "Workspace Persistence"
            );

            this.spaceWBId = dataByName.get("Space");
            this.spaceRegistryId = dataByName.get("Space Registry");
            this.spacePersistenceId = dataByName.get("Space Persistence");
        }
        if (
            document.getElementById("fullscreenBtn") !== undefined &&
            document.getElementById("fullscreenBtn") !== null
        ) {
            document.getElementById("fullscreenBtn").disable = false;
        }
        if (prevProps.fullScreenView !== this.props.fullScreenView) {
            // this.resetStates();
            this.computeSpaceCards();
        }
        if (
            this.props.activeWorkspace.activeWorkspaceID !==
            prevProps.activeWorkspace.activeWorkspaceID
        ) {
            this.resetStates();
        }
        if (
            parseInt(prevState.selectedSpaceId) !==
            parseInt(this.state.selectedSpaceId)
        ) {
            this.computeSpaceCards();
        } else if (prevState.updateSpaceName !== this.state.updateSpaceName) {
            this.computeSpaceCards();
        } else if (
            this.props.boardList.length > 0 &&
            prevProps.boardList.length > 0
        ) {
            if (
                prevProps.boardList[0].data.spaceId !==
                    this.props.boardList[0].data.spaceId ||
                prevProps.boardList.length !== this.props.boardList.length
            ) {
                this.computeSpaceCards();
            }
        } else if (this.props.boardList.length === 0) {
            if (
                prevProps.activeWorkspace.activeWorkspaceID !==
                this.props.activeWorkspace.activeWorkspaceID
            ) {
                this.computeSpaceCards();
            }
        } else if (prevProps.boardList !== this.props.boardList) {
            this.computeSpaceCards();
        } else if (prevProps.activeSpace === null) {
            this.computeSpaceCards();
            this.setDefualtActiveSpace();
        } else {
        }
    }
    resetStates = () => {
        this.setState({
            selectedSpaceId: 0,
            crouselActiveIndex: 0,
        });
    };
    updateFetcherByWorkspace() {
        let promise = new Promise((resolve, reject) => {
            this.collabFetcher.getCollabList().then((fetcher) => {
                this.collabFetcher.getDataStructureByName();
                this.collabFetcher.getDataStructureById();
                this.props.setCollabInfo(fetcher);
                this.updateSpaceManagerByWorkspace()
                    .then(() => {
                        resolve("true");
                    })
                    .catch((err) => {
                        this.showAlertView(
                            "",
                            err && err.error
                                ? err.path
                                : "Something went wrong, please try again!"
                        );
                        console.log(err);
                    });
            });
        });
        return promise;
    }
    showPopover = (e, index, spaceInstance, spaceIndex) => {
        if (spaceInstance !== undefined) {
            if (this.props.activeSpace.spaceId !== spaceInstance.data.spaceId) {
                this.changeActiveSpace(spaceInstance, spaceIndex);
            }
        }
        if (index !== undefined && index !== null)
            this.props.setCurrentClickedSpaceIndex(index);
        this.setState({ showPopover: true, popoverTarget: e.currentTarget });
        // return <PopoverView id={"menu_" + e.currentTarget.id} show={this.state.showPopover} anchorEl={this.state.popoverTarget}></PopoverView>
    };
    closePopover = (e, i) => {
        this.setState({ showPopover: false, popoverTarget: null });
    };
    titleMenuClick = (e) => {
        this.showPopover(e);
    };
    generateAlertHeader = (spaceId) => {
        let items = [];
        items.push(
            <IconView
                id={"img_" + spaceId}
                dataType="rightIcon"
                src="./images/alertMenu.png"
                onclick={this.titleMenuClick}
            />
        );
        return items;
    };
    renderPopover = () => {
        return (
            <PopoverView
                id={"menu_" + this.state.selectedSpaceId}
                show={this.state.showPopover}
                anchorEl={this.state.popoverTarget}
                closePopover={this.closePopover}
            >
                {this.renderMenuItems()}
            </PopoverView>
        );
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderMenuItems = () => {
        let items = [];
        items.push({
            value: this.state.selectedSpaceId + "_menu_1",
            text: "Link To Ripple",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_2",
            text: "Archive Space",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_3",
            text: "Share Space",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_4",
            text: "Delete Space",
        });
        items.push({
            value: this.state.selectedSpaceId + "_menu_5",
            text: "Edit Space Name",
        });
        return (
            <ListView
                className="noBorder"
                listItemClassname="pl-4"
                id={"" + this.state.selectedSpaceId}
                label={"list_" + this.state.selectedSpaceId}
                items={items}
                showIcon="false"
                disableActiveIndex="true"
                onchange={this.handleShowLinkDocClick}
            />
        );
    };
    closeModal = () => {
        this.setState({
            modalShow: false,
        });
    };
    setText = (value, id, event) => {
        switch (id) {
            case "updateSpacename":
                {
                    this.updatedSpacename = value;
                    this.setState({ updatedSpaceName: value });
                }
                break;
            case "spacename":
                {
                    this.newSpacename = value;
                    this.setState({ newSpaceName: value });
                }
                break;
            default:
                break;
        }
    };

    generateRowForUpdateArchiveState = (status) => {
        let rows = {
            rows: [[status]],
        };
        return rows;
    };

    handleUpdatedSpaceNameSubmit = () => {
        if (
            this.props.activeBoard !== null &&
            this.props.activeBoard !== undefined
        ) {
            //let spaceManager = this.props.spaceManager;
            if (
                this.updatedSpacename === null ||
                this.updatedSpacename.trim().length === 0
            ) {
                // alert("Please Enter Space Name");
                this.showAlertView("", "Please Enter Space Name");
                return;
            }
            let data = {};
            //let IDs = spaceManager.getIDs();
            this.closeModal();
            this.setState({ showLoader: true });
            data = {
                spaceName: this.state.updatedSpaceName.trim(),
                spaceId: this.props.activeSpace.spaceId,
            };
            this.props
                .updateSpaceName(
                    //IDs.spaceRegistryId,
                    this.spaceRegistryId,
                    this.props.activeWorkspace.activeWorkspaceID,
                    data
                )
                .then(() => {
                    this.props
                        .fetchSpaceListData(
                            //this.props.activeWorkspace.workspacePersistenceId,
                            //IDs.spaceRegistryId,
                            this.workspacePersistenceId,
                            this.spaceRegistryId,
                            this.props.activeWorkspace.activeWorkspaceID
                        )
                        .then((data) => {
                            let activeWSObject = {};
                            activeWSObject["activeWorkspaceID"] =
                                this.props.activeWorkspace.activeWorkspaceID;
                            activeWSObject["activeWorkspaceName"] =
                                this.props.activeWorkspace.activeWorkspaceName;
                            activeWSObject["spaceList"] =
                                this.props.spaceListData;
                            activeWSObject["workspacePersistenceId"] =
                                this.props.activeWorkspace.workspacePersistenceId;
                            let spaceList = this.props.spaceListData;
                            let spaceArray = [];
                            spaceList.forEach((item, i) => {
                                let spaceInstance = new Space();
                                let options = {};
                                options["spaceName"] = item.spaceName; //spaceNames[i]
                                options["spaceId"] = item.spaceId; //spaceIDs[i]
                                options["archive"] = item.archive;
                                spaceInstance.setData(options);
                                spaceArray.push(spaceInstance);
                            });
                            this.props.setActiveWorkspace(activeWSObject);
                            this.props.setBoardList([]);
                            this.props.setBoardList(spaceArray);
                            this.props.setActiveSpace(this.props.activeSpace);
                            this.updatedSpacename = null;
                            // alert("Space name updated successfully");
                            this.showAlertView(
                                "",
                                "Space name updated successfully"
                            );
                            Mixpanel.callSpaceManagementEvent("Rename");
                            this.setState({ updatedSpaceName: null });
                        })
                        .catch((err) => {
                            this.showAlertView(
                                "",
                                err && err.error
                                    ? err.path
                                    : "Something went wrong, please try again!"
                            );
                            console.log(err);
                            this.setState({
                                updatedSpaceName: null,
                                showLoader: false,
                            });
                        });
                })
                .catch((err) => {
                    this.showAlertView(
                        "",
                        err && err.error
                            ? err.path
                            : "Something went wrong, please try again!"
                    );
                    // alert(err);
                    this.showAlertView("", err);
                    console.log(err);
                    this.setState({
                        updatedSpaceName: null,
                        showLoader: false,
                    });
                });
            // }
        } else {
            this.closeModal();
            this.setState({ showLoader: false });
            // alert(
            //     "Space is not created. Please create new space and then submit."
            // );
            this.showAlertView(
                "",
                "Space is not created. Please create new space and then submit."
            );
        }
    };
    handleShowLinkDocClick = (index, listValue, listText, event) => {
        if (listText !== undefined && listText === "Edit Space Name") {
            this.updatedSpacename = this.spaceName;
            this.setState({
                updatedSpaceName: this.spaceName,
                modalShow: true,
            });
        } else if (listText === "Link To Ripple") {
            this.props.setActivePage("Ripple Log");
        } else if (listText === "Archive Space") {
            this.archiveSpace();
        }
    };
    convertJSDateToExcelDate = (inDate) => {
        const retValue =
            25569 +
            (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
                (1000 * 60 * 60 * 24);
        return Number(retValue.toString().split(".")[0]);
    };
    // archiveSpace = () => {
    //     this.setState({ showLoader: true });
    //     this.space.setData(this.props.activeBoard);
    //     let spaceId = this.space.getSpaceId();
    //     let currentspaceName = this.space.getSpaceName();
    //     let boardType = this.space.getBoardType();
    //     let date = new Date();
    //     let dateStr = date.toLocaleString();
    //     dateStr = this.convertJSDateToExcelDate(date);
    //     let workspaceID = this.props.activeWorkspace.activeWorkspaceID;

    //     let wsCollabID = this.props.collabInfo.getCollabIdByName("__Workspace");
    //     let wsWbID = this.props.collabInfo.getWBIdByName(
    //         wsCollabID,
    //         "Workspace"
    //     );
    //     let wsPersistenceCuboidID = this.props.collabInfo.getCuboidIdByName(
    //         wsCollabID,
    //         wsWbID,
    //         "Workspace Persistence"
    //     );
    //     if (
    //         boardType === "space" &&
    //         spaceId !== null &&
    //         spaceId !== undefined
    //     ) {
    //         let workspacePersistence = new CuboidManager(wsCollabID, wsWbID);
    //         if (wsCollabID !== -1 && wsWbID !== undefined) {
    //             workspacePersistence
    //                 .loadGridById(wsPersistenceCuboidID)
    //                 .then((resp) => {
    //                     let wsIDColumnSeqNo = null;
    //                     let spaceIDColumnSeqNo = null;
    //                     let row = null;
    //                     let rowId = null;
    //                     let archiveColumnSeqNo = null;
    //                     let columnArray = resp.columns.colArr;
    //                     let archiveInfo = {};
    //                     columnArray.forEach((item, i) => {
    //                         switch (item.columnName) {
    //                             case "Archive":
    //                                 archiveColumnSeqNo = item.columnSequenceNo;
    //                                 break;
    //                             case "Workspace Id":
    //                                 wsIDColumnSeqNo = item.columnSequenceNo;
    //                                 break;
    //                             case "Space Id":
    //                                 spaceIDColumnSeqNo = item.columnSequenceNo;
    //                                 break;
    //                             default:
    //                                 break;
    //                         }
    //                     });
    //                     resp.getRows().forEach((re, rei) => {
    //                         let currentWSID = resp.getCellEditorRC(
    //                             re.rowSequenceNo,
    //                             wsIDColumnSeqNo
    //                         ).stringValue;
    //                         let currentSpaceID = resp.getCellEditorRC(
    //                             re.rowSequenceNo,
    //                             spaceIDColumnSeqNo
    //                         ).stringValue;
    //                         if (
    //                             parseInt(currentWSID) === workspaceID &&
    //                             parseInt(currentSpaceID) === spaceId
    //                         ) {
    //                             row = this.generateRowForUpdateArchiveState(1);
    //                             rowId = re.rowData.getRowId();

    //                             let obj = {
    //                                 rowId: rowId,
    //                                 Name: currentspaceName,
    //                                 archivedDate: dateStr,
    //                                 type: "space",
    //                             };
    //                             let archiveObj = this.props.archiveInfo;
    //                             let alertArchiveData = archiveObj.alertInfo;
    //                             let spaceArchiveData = archiveObj.spaceInfo;
    //                             spaceArchiveData.push(obj);
    //                             archiveInfo = {
    //                                 alertInfo: alertArchiveData,
    //                                 spaceInfo: spaceArchiveData,
    //                             };
    //                         }
    //                     });
    //                     workspacePersistence.updateCell(rowId, "Archive", 1); //(rowId, columnName, value)
    //                     workspacePersistence
    //                         .submit()
    //                         .then((resp) => {
    //                             // if (resp.Updation > 0) {
    //                             let newSpaceRegistry = new CuboidManager(
    //                                 wsCollabID,
    //                                 wsWbID
    //                             );
    //                             newSpaceRegistry
    //                                 .loadGridById(wsPersistenceCuboidID)
    //                                 .then((resp) => {
    //                                     this.updateFetcherByWorkspace()
    //                                         .then(() => {
    //                                             this.props.setArchiveInfo(
    //                                                 archiveInfo
    //                                             );
    //                                             this.setState({
    //                                                 showLoader: false,
    //                                             });
    //                                             this.updateWorkspaceTreeStructure_Archive()
    //                                                 .then((data) => {
    //                                                     console.log(data);
    //                                                     alert("Space archived");
    //                                                 })
    //                                                 .catch((err) => {
    //                                                     console.log(err);
    //                                                 });
    //                                         })
    //                                         .catch((err) => {
    //                                             console.log(err);
    //                                             this.setState({
    //                                                 showLoader: false,
    //                                             });
    //                                         });
    //                                 })
    //                                 .catch((err) => {
    //                                     this.setState({ showLoader: false });

    //                                     console.log(err);
    //                                 });
    //                             // }
    //                         })
    //                         .catch((err) => {
    //                             this.setState({ showLoader: false });

    //                             console.log(err);
    //                         });
    //                 })
    //                 .catch((err) => {
    //                     this.setState({ showLoader: false });
    //                     console.log(err);
    //                 });
    //         }
    //     } else {
    //         this.setState({ showLoader: false });
    //     }
    // };

    computeSpaceCards = () => {
        this.setState({ showLoader: true });
        let AccordianList = [];
        this.undefinedSpcaeCounter = 0;
        let totalAlertCount = 0;
        if (
            this.props.boardList !== undefined &&
            this.props.boardList !== null &&
            this.props.boardList.length > 0
        ) {
            this.props.fullScreenView !== undefined &&
            this.props.fullScreenView === true
                ? (totalAlertCount = 12)
                : (totalAlertCount = 6);
            let spaceList = this.props.boardList;
            let cardLenght = spaceList.length % totalAlertCount;
            let extraCards = totalAlertCount - cardLenght;
            for (let i = 0; i < spaceList.length; i++) {
                let spaceInstance = spaceList[i];
                let spaceID = spaceInstance.data.spaceId;
                let spaceName = spaceInstance.data.spaceName;
                let activeSpaceIndex = -1;
                if (spaceName === undefined) {
                    this.undefinedSpcaeCounter = this.undefinedSpcaeCounter + 1;
                    spaceName =
                        "__undefinedSpaceName_" + this.undefinedSpcaeCounter;
                }
                if (
                    this.state.selectedSpaceId === i &&
                    this.props.activeSpace !== null &&
                    spaceID === this.props.activeSpace.spaceId
                ) {
                    activeSpaceIndex = i;
                    this.setDefualtActiveSpace(
                        spaceInstance,
                        spaceName + "|" + i
                    );
                } else if (
                    spaceList !== null &&
                    spaceList.length > 0 &&
                    i === 0 &&
                    this.state.selectedSpaceId === 0
                ) {
                    activeSpaceIndex = i;
                    this.setDefualtActiveSpace(
                        spaceInstance,
                        spaceName + "|" + i
                    );
                }

                AccordianList.push(
                    <CardView
                        id={spaceName + "|" + i}
                        index={i}
                        activeIndex={this.state.selectedSpaceId}
                        onbodyclick={this.changeActiveSpace}
                        onMenuBtnClick={(e) =>
                            this.showPopover(
                                e,
                                i,
                                spaceInstance,
                                spaceName + "|" + i
                            )
                        }
                        spaceInstance={spaceInstance}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData={this.generateAlertHeader(
                            spaceName + "|" + i
                        )}
                        headerRightIcon={
                            <IconView
                                id={"img_" + this.state.selectedSpaceId}
                                dataType="rightIcon"
                                src="./images/alertMenu.png"
                                onclick={this.titleMenuClick}
                            />
                        }
                        bodyData={spaceName}
                        spaceId={spaceID}
                    ></CardView>
                );
            }
            let index = 0;
            while (index < extraCards) {
                AccordianList.push(
                    <CardView
                        index={spaceList.length + index}
                        activeIndex={this.state.selectedSpaceId}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            this.renderRowsForSlider(AccordianList, totalAlertCount / 2);
            return AccordianList;
        } else {
            let index = 0;
            while (index < 6) {
                AccordianList.push(
                    <CardView
                        index={index}
                        activeIndex={-1}
                        cardClass="spaceCard"
                        cardHeaderClass="spaceCard-header"
                        cardBodyClass="spaceCard-body"
                        headerData=""
                        bodyData=""
                    ></CardView>
                );
                index++;
            }
            this.renderRowsForSlider(AccordianList, 3);
            return AccordianList;
        }
    };
    renderRowsForSlider = (cardList, displayCount) => {
        let rowItems = [];
        let temp = [];
        let cardLists = cardList;
        let ColSize = 12 / displayCount;
        if (cardLists.length > 0) {
            for (let index = 0; index < cardLists.length; index++) {
                temp.push(
                    <Column className="doCenter p-0" size={ColSize}>
                        {cardLists[index]}
                    </Column>
                );
                if (temp.length === displayCount) {
                    rowItems.push(
                        <InlineRow classname="w-100 ht-50 pb-2">
                            {temp}
                        </InlineRow>
                    );
                    temp = [];
                }
            }
            this.setState({ cardlist: rowItems, showLoader: false });
        }
    };
    getDocumentList = (documentObjects) => {
        let documentListObject = {};
        let docArray = [];

        if ("No Documents" in documentObjects) {
            documentListObject[0] = "No Documents uploaded in this space";
        } else {
            Object.keys(documentObjects).forEach((key, index) => {
                let document = new Document();
                let documentList =
                    this.props.collabInfo.getCollabAndWhiteboardAndCuboidNamesBYCuboidID(
                        parseInt(documentObjects[key])
                    );
                let documentHeaderName = documentList[2] + ".h";

                let collabId = this.fetcher.getCollabIdByName(documentList[0]);
                let wbId = this.fetcher.getWBIdByName(
                    collabId,
                    documentList[1]
                );
                let documentHeaderId = this.fetcher.getCuboidIdByName(
                    collabId,
                    wbId,
                    documentHeaderName
                );
                let documentId = parseInt(documentObjects[key]);
                documentListObject[index] = documentList[2];
                let options = {
                    collabId: collabId,
                    wbId: wbId,
                    documentId: documentId,
                    documentHeaderId: documentHeaderId,
                    collabName: documentList[0],
                    wbname: documentList[1],
                    headerName: documentList[2] + ".h",
                    fileName: documentList[2],
                    files: {
                        name: documentList[2],
                    },
                    isChoosen: false,
                    metaRows: null,
                    isLinked: true,
                    isPersisted: true,
                };
                document.setData(options);
                docArray.push(document.getData());
            });
        }
        return docArray;
    };
    updateSpace(activeSpace) {
        if (activeSpace !== null && activeSpace !== undefined) {
            if (Object.keys(activeSpace).length > 0) {
                let space = new Space();
                space.setData(activeSpace);
                // this.activeSpaceDocuments = space.getDocuments();
                // space.setDocuments(this.activeSpaceDocuments);
                this.props.setFilesforUpload(null);
                this.props.setFilesforUpload(space.getData());
                // this.setState({ spaceOpen: true });
            }
        }
    }
    setDefualtActiveSpace = (spaceInstance, e) => {
        let cardId = e.split("|")[1];
        this.setState({ selectedSpaceId: parseInt(cardId) });
        let currentSpace = this.props.activeSpace;
        let spaceId = spaceInstance.getSpaceId();
        let spaceName = spaceInstance.getSpaceName();
        this.spaceName = spaceName;
        let documentObjects = spaceInstance.data.documentObjects;
        let boardType = spaceInstance.data.boardType;
        this.fetcher = this.props.collabInfo;
        if (currentSpace === null || currentSpace === undefined) {
            this.activeSpaceId.push(spaceId);
            currentSpace = {
                //documentObjects: this.getDocumentList(documentObjects),
                spaceName: spaceName,
                spaceId: spaceId,
                boardType: boardType,
            };
            this.props.setActiveSpace(null);
            this.props.setActiveSpace(currentSpace);
            this.updateSpace(currentSpace);
        } else {
            currentSpace = null;
            currentSpace = {
                //documentObjects: this.getDocumentList(documentObjects),
                spaceName: spaceName,
                spaceId: spaceId,
                boardType: boardType,
            };
            this.props.setActiveSpace(null);
            this.props.setActiveSpace(currentSpace);
            this.updateSpace(currentSpace);
        }
    };
    updateCrousel = (selectedSpaceIndex) => {
        let index = parseInt(selectedSpaceIndex);
        this.selectedCrouselIndex = Math.ceil((index + 1) / 6);
        if (index / 6)
            this.setState({
                crouselActiveIndex: this.selectedCrouselIndex - 1,
            });
    };
    changeActiveSpace = (spaceInstance, e) => {
        // if (this.props.activeSpace.spaceId !== spaceInstance.data.spaceId) {
        let cardId = -1;
        if (e.currentTarget !== undefined) {
            cardId = e.currentTarget.id.split("|")[1];
        } else {
            cardId = e.split("|")[1];
        }
        this.setState({ selectedSpaceId: parseInt(cardId) });
        let currentSpace = this.props.activeSpace;
        let spaceId = spaceInstance.getSpaceId();
        let spaceName = spaceInstance.getSpaceName();
        this.spaceName = spaceName;
        let documentObjects = spaceInstance.data.documentObjects;
        let boardType = spaceInstance.data.boardType;
        this.fetcher = this.props.collabInfo;
        if (currentSpace === null || currentSpace === undefined) {
            this.activeSpaceId.push(spaceId);
            currentSpace = {
                //documentObjects: this.getDocumentList(documentObjects),
                spaceName: spaceName,
                spaceId: spaceId,
                boardType: boardType,
            };
            this.props.setActiveSpace(null);
            this.props.setActiveSpace(currentSpace);
            this.updateCrousel(cardId);
            this.updateSpace(currentSpace);
        } else {
            currentSpace = null;
            currentSpace = {
                //documentObjects: this.getDocumentList(documentObjects),
                spaceName: spaceName,
                spaceId: spaceId,
                boardType: boardType,
            };
            this.props.setActiveSpace(null);
            this.props.setActiveSpace(currentSpace);
            this.updateCrousel(cardId);
            this.updateSpace(currentSpace);
            // if (this.props.currentPageType === "managespace") {
            this.props.onclick("data");
            // }
        }
        // }
    };

    createSpaceWhiteboard = (spaceId) => {
        // creating space related whiteboard for submit documnets
        let promise = new Promise((resolve, reject) => {
            let documentCollabId = this.fetcher.getCollabIdByName(
                config.documentCollaborationName
            );
            documentCollabId = documentCollabId.toString();
            let url = "collaboration/" + documentCollabId + "/whiteboard";
            let wbName = "Space_" + spaceId;
            let data = { wbName: wbName };
            // data = JSON.stringify(data);
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    let wbId = resp;
                    resolve(wbId);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    };
    addNewSpace = () => {
        if (
            this.props.spaceManager !== undefined &&
            this.props.spaceManager !== null
        ) {
            //let spaceManager = this.props.spaceManager;
            if (
                this.newSpacename === null ||
                this.newSpacename.trim().length === 0
            ) {
                // alert("Please Enter Space Name");
                this.showAlertView("", "Please Enter Space Name");
                return;
            }
            this.setState({
                showLoader: true,
                addSpaceModalShow: false,
            });
            this.closeAddSpaceModal();
            let data = {};

            data = { spaceName: this.state.newSpaceName.trim() };
            this.props
                .addSpace(
                    //IDs.spaceRegistryId,
                    this.spaceRegistryId,
                    this.props.activeWorkspace.activeWorkspaceID,
                    data
                )
                .then(() => {
                    let activeSpaceResponse = this.props.newSpace;
                    let activeSpace = null;
                    this.props
                        .fetchSpaceListData(
                            this.workspacePersistenceId,
                            this.spaceRegistryId,
                            this.props.activeWorkspace.activeWorkspaceID
                        )
                        .then((data) => {
                            let activeWSObject = {};
                            activeWSObject["activeWorkspaceID"] =
                                this.props.activeWorkspace.activeWorkspaceID;
                            activeWSObject["activeWorkspaceName"] =
                                this.props.activeWorkspace.activeWorkspaceName;
                            activeWSObject["spaceList"] =
                                this.props.spaceListData;
                            activeWSObject["workspacePersistenceId"] =
                                this.props.activeWorkspace.workspacePersistenceId;
                            let spaceList = this.props.spaceListData;
                            let spaceArray = [];
                            let selectedSpaceIndex = -1;
                            spaceList.forEach((item, i) => {
                                let spaceInstance = new Space();
                                let options = {};
                                options["spaceName"] = item.spaceName; //spaceNames[i]
                                options["spaceId"] = item.spaceId; //spaceIDs[i]
                                options["archive"] = item.archive;
                                spaceInstance.setData(options);
                                spaceArray.push(spaceInstance);
                                if (
                                    activeSpaceResponse.spaceId === item.spaceId
                                ) {
                                    activeSpace = options;
                                    selectedSpaceIndex = i;
                                }
                            });
                            this.props.setActiveWorkspace(activeWSObject);
                            this.props.setBoardList(spaceArray);
                            this.props.setActiveSpace(null);
                            this.props.setActiveSpace(activeSpace);
                            this.newSpacename = null;
                            this.selectedCrouselIndex = Math.ceil(
                                (selectedSpaceIndex + 1) / 6
                            );
                            if (selectedSpaceIndex / 6)
                                this.setState({
                                    showLoader: false,
                                    selectedSpaceId: selectedSpaceIndex,
                                    crouselActiveIndex:
                                        this.selectedCrouselIndex - 1,
                                });
                        })
                        .catch((err) => {
                            this.showAlertView(
                                "",
                                err && err.error
                                    ? err.path
                                    : "Something went wrong, please try again!"
                            );
                            this.newSpacename = null;
                            this.setState({ showLoader: false });
                        });
                })
                .catch((err) => {
                    this.showAlertView(
                        "",
                        err && err.error
                            ? err.path
                            : "Something went wrong, please try again!"
                    );
                    this.newSpacename = null;
                    // alert(err);
                    this.showAlertView("", err);
                    this.setState({ showLoader: false });
                });
            // }
            // }
        }
    };
    handleAddSpaceClick = () => {
        this.setState({ addSpaceModalShow: true });
    };
    closeAddSpaceModal = () => {
        if (this.state.addSpaceModalShow === true) {
            this.setState({
                addSpaceModalShow: false,
            });
        }
    };

    renderSpaceTable = (event) => {
        let selectedSpace = document.getElementById(event.target.id);
        selectedSpace.style.border = "3px solid #02C2FF";
        this.setState({ selectedSpace: "space1" });
    };

    renderSpaceContent = () => {
        // if (this.props.boardList !== null && this.props.boardList.length > 0 && this.props.boardList !== undefined) {
        let classname = "";
        if (this.props.rightPanelType === "alert") {
            classname = "spaceCardSectionWithAlert";
        } else {
            classname = "spaceCardSection";
        }
        return (
            <div className={classname + " w-100"}>
                <SliderView
                    rawData={this.state.cardlist}
                    crouselActiveIndex={this.state.crouselActiveIndex}
                    cardCountPerSlide={2}
                ></SliderView>
                {this.renderPopover()}
                <input
                    type="file"
                    id="file"
                    ref={"fileUploder"}
                    hidden={true}
                    onChange={this.onChangeFileUpload}
                    multiple
                />
            </div>
        );
        // }
    };
    handleButtonClick = (e) => {
        this.props.onclick(e);
    };
    handleViewFullScreen = () => {
        if (this.props.setViewFullScreen !== undefined) {
            this.props.setViewFullScreen();
        }
    };
    renderButton = () => {
        if (this.props.rightPanelType === "alert") {
            return (
                <input
                    id="manageSpaceBtn"
                    type="button"
                    onClick={this.handleButtonClick}
                    disabled={
                        this.props.boardList !== null &&
                        this.props.boardList.length > 0
                            ? false
                            : true
                    }
                    className="btn btn-light viewFSbtn newFontMontserrat"
                    value="MANAGE SPACES"
                ></input>
            );
        } else {
            return (
                <input
                    id="fullscreenBtn"
                    type="button"
                    className="btn btn-light viewFSbtn newFontMontserrat mb-5"
                    value="View Fullscreen"
                    onClick={this.handleViewFullScreen}
                ></input>
            );
        }
    };
    renderFullScreenButton = () => {
        if (this.props.fullScreenView === false) {
            return (
                <div
                    className="row w-100 doCenter"
                    style={{ marginTop: "20px" }}
                >
                    <div className="col-6">{this.renderButton()}</div>
                </div>
            );
        } else {
            return null;
        }
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    render() {
        this.fetcher = this.props.collabInfo;
        if (this.state.showLoader === true) {
            return <Loader show={this.state.showLoader}></Loader>;
        } else {
            return (
                <>
                    {/* {this.renderTitleBar()} */}
                    <SpaceHeader
                        handleAddSpaceClick={this.handleAddSpaceClick}
                        addSpaceModalShow={this.state.addSpaceModalShow}
                        closeAddSpaceModal={this.closeAddSpaceModal}
                        setText={this.setText}
                        addNewSpace={this.addNewSpace}
                    />
                    {this.renderSpaceContent()}
                    {this.renderFullScreenButton()}
                    <SpaceViewerModal
                        modalShow={this.state.modalShow}
                        closeModal={this.closeModal}
                        setText={this.setText}
                        label={this.spaceName}
                        value={this.state.updatedSpaceName}
                        handleUpdatedSpaceNameSubmit={
                            this.handleUpdatedSpaceNameSubmit
                        }
                    />
                    {this.renderAlertModal()}
                </>
            );
        }
    }
}
const mapDispatchToProps = () => {
    return {
        setFilesforUpload,
        setCollabInfo,
        setPage,
        setActiveSpace,
        setLeftPanelView,
        setRightPanelView,
        setSpaceManager,
        setBoardList,
        setCurrentSettingsPage,
        setJiraManager,
        setGmailManager,
        setAlertsWidget,
        setAlertManager,
        setCurrentClickedSpaceIndex,
        setActiveWorkspace,
        setWorkspaceManager,
        setActivePage,
        updateArchiveInfoAlert,
        setArchiveInfo,
        addSpace,
        fetchSpaceListData,
        updateSpaceName,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        currentPage: state.currentPage,
        leftPanel: state.leftPanel,
        rightPanel: state.rightPanel,
        boardList: state.boardList,
        activeBoard: state.activeBoard,
        activeSpace: state.activeSpace,
        jiraManager: state.jiraManager,
        gmailManager: state.gmailManager,
        spaceForUpload: state.spaceForUpload,
        spaceManager: state.spaceManager,
        workspaceManager: state.workspaceManager,
        currentClickedSpaceIndex: state.currentClickedSpaceIndex,
        currentPageType: state.currentPageType,
        activeWorkspace: state.activeWorkspace,
        workspaceFetcher: state.workspaceFetcher,
        archiveInfo: state.archiveInfo,
        spaceListData: state.spaceListData,
        cuboidInfoByName: state.cuboidInfoByName,
        cuboidInfoById: state.cuboidInfoById,
        newSpace: state.newSpace,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(SpaceViewer);
