import { Button } from "@material-ui/core";
import { Switch } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchLinkImportData,
    fetchRefreshData,
} from "../../../actions/WebGridActions";
import ControlTower from "../../../bae-client/ControlTower";
import WebGridInterfaceClass from "../../../bae-client/WebGridInterfaceClass";
import CustomSnackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import ListView from "../../stylized/ListView";
import Loader from "../../stylized/Loader";
import PopoverView from "../../stylized/PopoverView";
import instanceInfoColumns from "./Config";
import { NativeSelect } from "@mui/material";
import DatePickers from "./DatePickers";
import submitBufferStub from "./SubmitBufferStub";
import CustomCell from "./CustomCell";
import http from "../../../bae-client/HTTP";
import AlertDialog from "../../stylized/AlertDialog";
import RecureModalView from "../UnityCentral/ContentPane/RippleLog/RecureModalView";
const controlTower = new ControlTower();
const gridFunctionality = [
    { value: "submit", text: "SUBMIT" },
    // { value: "refresh", text: "REFRESH" },
    { value: "add_row", text: "ADD ROW" },
    { value: "delete_row", text: "DELETE ROW" },
];

const columnNameToTitleMap = new Map([
    ["User Email Address", "Email address to send alerts"],
    ["Generic Name", "Docuchain name"],
    ["Alert Type", "Alert type"],
    ["Step Name", "Docuchain step name(transaction type)"],
    ["From", "Sender"],
    ["To", "Receiver"],
    ["Model Name", "Docuchain name"],
    ["Instance Name", "Docuchain instance name"],
    ["Transaction Type", "Docuchain step name"],
    ["Promise Type", "State of instance(purchase order stage)"],
    ["Search Text", "Free text to select specific information in document"],
]);
function WebGridInterface({ cuboidId, options }) {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const [callPostSubmitAPI, setCallPostSubmitAPI] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [instanceBlockChainId, setInstanceBlockchainId] = useState("");
    const [postSubmitPayload, setPostSubmitPayload] = useState({});
    const [page, setPage] = React.useState(0);
    const [refreshResult, setRefreshResult] = useState({
        open: false,
        message: "",
        title: "",
    });
    const [submitResult, setSubmitResult] = useState({
        open: false,
        message: "",
        title: "",
    });
    const [instanceMapData, setInstanceMapData] = useState(null);
    const [stepNamesMapData, setStepNamesMapData] = useState(null);
    const [fromContactsMapData, setFromContactsMapData] = useState(null);
    const [toContactsMapData, setToContactsMapData] = useState(null);
    const [showRecureModal, setShowRecureModal] = useState(false);
    const [recurData, setRecurData] = useState({
        recurString: "",
        rowId: "",
        columnId: "",
    });

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [data, setData] = useState({});
    const [gridColumns, setGridColumns] = useState([]);
    const [columnDropdownValues, setColumnDropdownValues] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(null);
    const [cellChanges, setCellChanges] = useState([]);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [deletedRowToSubmit, setDeletedRowToSubmit] = useState([]);
    const [newRowToSubmit, setNewRowToSubmit] = useState([]);
    const [newRowCells, setNewRowCells] = useState([]);
    const [gridName, setGridName] = useState("");
    const linkImportData = useSelector((state) => state.linkImport);
    const refreshData = useSelector((state) => state.refresh);
    const [showAlert, setShowAlert] = useState(false);
    const [currentLinkImportedData, setCurrentLinkImportedData] =
        useState(null);
    const [activeChecked, setActiveChecked] = useState(false);

    const flowsData = useSelector((state) => state.DashboardDCFilterData);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLinkImportData(cuboidId));
    }, [cuboidId]);
    useEffect(() => {
        //if (linkImportData && linkImportData.info.gridId === cuboidId) {
        let url = "emailAlertsConfig/list";
        http.sendRestRequest(url, "GET").then((resp) => {
            console.log(resp);
            setData(resp);
            setGridColumns(resp.columnChainArray);
            setCurrentLinkImportedData(resp);
            setGridName(resp.info.gridName);
        });
        //}
    }, []); // [linkImportData]);
    useEffect(() => {
        if (gridColumns.length) {
            const instanceMap = new Map();
            const stepNameMap = new Map();
            const fromContactsMap = new Map();
            const toContactsMap = new Map();
            const genericNames = flowsData.map((gen) => {
                instanceMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.instances.map((instance) => instance.instanceName)
                );
                stepNameMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.stepNames
                );
                fromContactsMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.fromContacts
                );
                toContactsMap.set(
                    gen.genericRegistry.genericDisplayName,
                    gen.toContacts
                );

                return gen.genericRegistry.genericDisplayName;
            });
            const sortedGenericNames = genericNames.sort((a, b) =>
                a.localeCompare(b)
            );
            const from = flowsData.map((gen) => gen.fromContacts);
            const to = flowsData.map((gen) => gen.toContacts);
            const stepNames = flowsData.map((gen) => gen.stepNames);
            const instances = flowsData.map((gen) =>
                gen.instances.map((instance) => instance.instanceName)
            );
            if (data.info.gridName === "Email Alerts")
                setColumnDropdownValues({
                    1: [].concat.apply(["Select"], sortedGenericNames),
                    2: [
                        "Select",
                        "DELAY",
                        "SKIPPED",
                        "KEY MISSING",
                        "KEY MISMATCH",
                        "PROACTIVE ALERT",
                        "DATE CHANGED",
                        "OTHERS",
                    ],
                    3: [].concat.apply(["Select"], stepNames),
                    4: [].concat.apply(["Select"], from),
                    5: [].concat.apply(["Select"], to),
                });
            else {
                let updatedStepNames = [];
                stepNames.forEach((element) => {
                    element.forEach((ele) => updatedStepNames.push(ele));
                });
                setColumnDropdownValues({
                    0: [].concat.apply([], sortedGenericNames),
                    1: [].concat.apply(["Select"], instances),
                    2: [].concat.apply(
                        [],
                        Array.from(new Set(updatedStepNames.flat()))
                    ),
                    3: [].concat.apply(["Select"], from),
                    4: [].concat.apply(["Select"], to),
                    5: [
                        "Select",
                        "Initiation",
                        "Commitment",
                        "Fulfillment",
                        "Settlement",
                        "Completion",
                    ],
                    6: [
                        "DELAY",
                        "SKIPPED",
                        "KEY MISSING",
                        "KEY MISMATCH",
                        "PROACTIVE ALERT",
                        "DATE CHANGED",
                        "OTHERS",
                    ],
                    7: [
                        "Select",
                        "Last 1 day",
                        "Last 7 days",
                        "Last 15 days",
                        "Last 30 Days",
                        "Last 90 days",
                    ],
                });
            }

            setInstanceMapData(instanceMap);
            setStepNamesMapData(stepNameMap);
            setFromContactsMapData(fromContactsMap);
            setToContactsMapData(toContactsMap);
        }
    }, [gridColumns]);

    // this will run when refresh gets called
    useEffect(() => {
        if (refreshData) {
            if (
                refreshData.info &&
                data.info &&
                refreshData.info.importTxId === data.info.importTxId
            ) {
                setRefreshResult({
                    open: true,
                    title: "Cuboid Refresh Result",
                    message: "Data is unchanged! Nothing to refresh.",
                });
                return;
            }
            if (
                // refreshData.info.importTxId > data.info.importTxId &&
                refreshData.gridChangeBuffer.cellChanges.length
            ) {
                const refreshBuffer = { ...data };
                const unSubmittedCells = cellChanges.map((row) => row);
                refreshBuffer.info = refreshData.info;
                data.rowChainArray.forEach((row, rowIndex) => {
                    row.cells.forEach((cell, cellIndex) => {
                        refreshData.gridChangeBuffer.cellChanges.forEach(
                            (refreshCell) => {
                                if (
                                    refreshCell.rowId === cell.rowId &&
                                    refreshCell.columnId === cell.columnId
                                ) {
                                    refreshBuffer.rowChainArray[rowIndex].cells[
                                        cellIndex
                                    ] = refreshCell;
                                    if (unSubmittedCells.length) {
                                        unSubmittedCells[rowIndex].cells[
                                            cellIndex
                                        ] = refreshCell;
                                    }
                                }
                            }
                        );
                    });
                });
                setData(refreshBuffer);
                setCellChanges(unSubmittedCells);
                setRefreshResult({
                    open: true,
                    title: "Cuboid Refresh Result",
                    message: "Latest data is updated, \n Refresh Successful!",
                });
            }
        }
    }, [refreshData]);

    useEffect(() => {
        if (
            callPostSubmitAPI &&
            instanceBlockChainId &&
            options.instanceInformationTblId &&
            options.instanceId
        ) {
            controlTower
                .updateInstanceInfo(
                    instanceBlockChainId,
                    options.instanceInformationTblId,
                    options.instanceId,
                    postSubmitPayload
                )
                .then((response) => {
                    // console.log(response);
                })
                .catch((error) => console.log(error));
        }
    }, [callPostSubmitAPI, instanceBlockChainId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const closePopover = () => {
        setShowPopover(false);
        setPopoverTarget(null);
    };
    const renderMenu = () => {
        return (
            <ListView
                className="noBorder"
                listItemClassname="pl-4"
                id={"" + data.info.id}
                label={"list_" + data.info.id}
                items={gridFunctionality}
                showIcon="false"
                disableActiveIndex="true"
                onchange={menuItemClick}
            />
        );
    };
    const menuItemClick = (id, value, text, event) => {
        switch (value) {
            case "submit":
                _BW_Submit();
                break;

            case "refresh":
                dispatch(
                    fetchRefreshData(data.info.gridId, data.info.importTxId)
                );
                break;

            case "add_row":
                _Add_Row(id, text, event);
                break;
            case "delete_row":
                _Delete_Row(id, text, event);
                break;
            default:
                break;
        }
    };
    const _Delete_Row = (id, text, event) => {
        setDisable(true);
        setSubmitDisable(false);
        setDeletedRowToSubmit([selectedCell.rowId]);
    };
    const _Add_Row = (id, text, event) => {
        setDisable(true);
        setSubmitDisable(false);
        const selectedCell = {
            rowId: data.rowChainArray.length ? data.rowChainArray[0].rowId : -1,
            columnId: data.columnChainArray[0].columnId,
            rowSequenceNumber: data.rowChainArray.length
                ? data.rowChainArray[0].rowSequenceNumber
                : 0,
        };
        const rowToAdd = data.rowChainArray.length
            ? { ...data.rowChainArray[0] }
            : {
                  isActive: true,
                  rowId: -1,
                  previousRowOffset: 1,
                  previousRowId: -1,
                  rowSequenceNumber: 0,
                  rowName: " ",
                  ownerUserId: -1,
                  ownerEmail: " ",
                  rowCreationTxId: -1,
                  rowCreatorUserId: -1,
                  ownershipAssignedTxId: -1,
              };
        const newRow = {
            isActive: true,
            rowId: -1,
            previousRowOffset: 1,
            previousRowId: -1,
            rowSequenceNumber: 0,
            rowName: " ",
            ownerUserId: -1,
            ownerEmail: " ",
            rowCreationTxId: -1,
            rowCreatorUserId: -1,
            ownershipAssignedTxId: -1,
        };
        const cell = {
            cellId: -1,
            rowId: -1,
            columnId: 0,
            rowSequenceNumber: 1,
            columnSequenceNumber: 0,
            cellValue: "",
            cellFormula: "",
            isActive: true,
            txId: -1,
            changeFlag: 4,
            access: -1,
        };
        const newCells = [];
        const newData = { ...data };
        const rowIdArray = data.rowChainArray.map((row, index) => row.rowId);
        let changeIndex = rowIdArray.indexOf(selectedCell.rowId);
        if (rowIdArray.length)
            rowToAdd.cells = newData.rowChainArray[0].cells.map((cell) => {
                return {
                    ...cell,
                    rowId: -1,
                    rowSequenceNumber: changeIndex + 1,
                    cellValue: "",
                    cellFormula: "na",
                    changeFlag: 4,
                };
            });
        else
            rowToAdd.cells = newData.columnArray.map((element, index) => {
                return {
                    ...cell,
                    columnId: element,
                    columnSequenceNumber: index + 1,
                };
            });
        rowToAdd.rowId = "N" + newRowToSubmit.length;
        rowToAdd.rowSequenceNumber = changeIndex + 1;
        newData.rowChainArray.splice(changeIndex, 0, rowToAdd);
        newRow.previousRowId = changeIndex ? rowIdArray[changeIndex - 1] : -1;
        if (isNaN(parseInt(newRow.previousRowId))) newRow.previousRowId = -1;
        newRow.previousRowOffset = changeIndex;
        newRow.rowSequenceNumber = changeIndex + 1;
        newRow.rowId = rowToAdd.rowId;
        newData.columnArray.forEach((element, index) => {
            newCells.push({
                ...cell,
                columnId: element,
                rowSequenceNumber: rowToAdd.rowId,
                columnSequenceNumber: index + 1,
            });
        });
        const existingRows = [...newRowToSubmit];
        setNewRowToSubmit([newRow, ...existingRows]);
        const existingCells = [...newRowCells];
        setNewRowCells([...newCells, ...existingCells]);
    };
    const _BW_Submit = () => {
        let allCellChanges = [];
        if (
            currentLinkImportedData.rowChainArray &&
            (cellChanges.length > 0 ||
                newRowToSubmit.length ||
                deletedRowToSubmit.length)
        ) {
            if (cellChanges.length) {
                const changedRows = currentLinkImportedData.rowChainArray.map(
                    (row, reid) => {
                        return {
                            ...row,
                            cells: row.cells.forEach((cell, ceid) => {
                                let originalValue =
                                    currentLinkImportedData.rowChainArray[reid]
                                        .cells[ceid].cellValue;
                                const filteredRow = cellChanges.filter(
                                    (cellRow) => cellRow.rowId === row.rowId
                                );
                                if (filteredRow.length) {
                                    let changedValue =
                                        filteredRow[0].cells[ceid].cellValue;
                                    if (originalValue !== changedValue) {
                                        let changedCell =
                                            filteredRow[0].cells[ceid];
                                        let obj = {
                                            cellId: changedCell.cellId,
                                            rowId: changedCell.rowId,
                                            columnId: changedCell.columnId,
                                            rowSequenceNumber: isNaN(row.rowId)
                                                ? row.rowId
                                                : changedCell.rowSequenceNumber,
                                            columnSequenceNumber:
                                                changedCell.columnSequenceNumber,
                                            cellValue: changedCell.cellValue,
                                            cellFormula:
                                                changedCell.cellFormula,
                                            isActive: changedCell.isActive,
                                            txId: changedCell.txId,
                                            changeFlag: changedCell.changeFlag,
                                            access: changedCell.access,
                                        };
                                        allCellChanges.push(obj);
                                    }
                                }
                            }),
                        };
                    }
                );
            }

            let submitBuffer = submitBufferStub;

            let cellChangeData = [...newRowCells];
            allCellChanges.forEach((element) => {
                let itemToRemove = -1;
                if (element.rowId === -1) {
                    cellChangeData.forEach((ele, index) => {
                        if (
                            element.columnId === ele.columnId &&
                            element.rowSequenceNumber === ele.rowSequenceNumber
                        )
                            itemToRemove = index;
                    });
                    if (itemToRemove > -1) {
                        cellChangeData.splice(itemToRemove, 1);
                    }
                }
            });
            const rowIdArray = data.rowChainArray.map((row) => {
                return row.rowId;
            });
            const newRowsCellsSubmitBuffer = [
                ...cellChangeData.map((cell) => {
                    const rowIndex = rowIdArray.indexOf(cell.rowSequenceNumber);
                    return {
                        ...cell,
                        rowSequenceNumber: rowIndex + 1,
                    };
                }),
                ...allCellChanges.map((cell) => {
                    if (isNaN(cell.rowSequenceNumber)) {
                        const rowIndex = rowIdArray.indexOf(
                            cell.rowSequenceNumber
                        );
                        return {
                            ...cell,
                            rowSequenceNumber: rowIndex + 1,
                        };
                    } else return cell;
                }),
            ];
            const newRowsSubmitBuffer = newRowToSubmit.map((row, index) => {
                let prevRowId = -1;
                const prevRow = rowIdArray.indexOf(row.rowId)
                    ? data.rowChainArray[rowIdArray.indexOf(row.rowId) - 1]
                          .rowId
                    : -1;
                prevRowId = isNaN(prevRow) ? -1 : prevRow;
                return {
                    ...row,
                    rowId: -1,
                    rowSequenceNumber: rowIdArray.indexOf(row.rowId) + 1,
                    previousRowId: prevRowId,
                    previousRowOffset: 1,
                };
            });
            submitBuffer.gridChangeBuffer = {
                critical: -1,
                criticalLevel: -1,
                newRowArray: newRowsSubmitBuffer,
                deletedRowArray: deletedRowToSubmit,
                newColumnArray: [],
                deletedColumnArray: [],
                cellChanges: newRowsCellsSubmitBuffer,
            };
            submitBuffer.info = data.info;
            const _wgi = new WebGridInterfaceClass();
            if (
                (submitBuffer.info.gridName ===
                    "Email Alerts Rule Configuration" ||
                    submitBuffer.info.gridName ===
                        "Dashboard Report Rule Configuration") &&
                submitBuffer.gridChangeBuffer.newRowArray &&
                submitBuffer.gridChangeBuffer.newRowArray.length
            ) {
                const newRowCells = [];
                submitBuffer.gridChangeBuffer.cellChanges.forEach((element) => {
                    if (element.rowId === -1) newRowCells.push(element);
                });
                if (
                    submitBuffer.info.gridName ===
                    "Email Alerts Rule Configuration"
                ) {
                    if (
                        newRowCells.filter(
                            (cell) =>
                                cell.cellValue && cell.cellValue !== "Select"
                        ).length !== 7
                    ) {
                        setShowAlert(true);
                        return;
                    }
                } else if (
                    submitBuffer.info.gridName ===
                    "Dashboard Report Rule Configuration"
                ) {
                    let newOne = newRowCells.filter((cell) => {
                        if (
                            (cell.cellValue !== "" &&
                                cell.columnSequenceNumber === 1) ||
                            (cell.cellValue !== "" &&
                                cell.columnSequenceNumber === 8)
                        ) {
                            return cell;
                        }
                    });
                    if (newOne.length !== 2) {
                        setShowAlert(true);
                        return;
                    }
                }
            }
            setShowLoader(true);
            setSubmitDisable(true);
            _wgi._submitCuboid(data.info.gridId, submitBuffer)
                .then((response) => {
                    setSubmitResult({
                        open: true,
                        title: "Cuboid Submitted",
                        message:
                            "Latest data is updated, \n Submit Successful!",
                    });
                    setData({});
                    setCallPostSubmitAPI(true);
                    setNewRowToSubmit([]);
                    setDisable(false);
                    // setShowLoader(false);
                    setSubmitDisable(false);
                    setNewRowCells([]);
                    setCellChanges([]);
                    setDeletedRowToSubmit([]);
                    dispatch(fetchLinkImportData(response.info.gridId));
                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                });
        } else {
            setSubmitResult({
                open: true,
                title: "Cuboid Not Submitted",
                message: "Change any cell, \n and then click submit!",
            });
            setSubmitDisable(false);
        }
    };
    const convertJSDateToExcelDate = (inDate) => {
        const retValue =
            25569 +
            (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
                (1000 * 60 * 60 * 24);
        return Number(retValue.toString().split(".")[0]);
    };
    const onChange = (e, isEmailField, rowIndex) => {
        // const changedValue = e.target.value
        //     ? convertJSDateToExcelDate(new Date(e.target.value))
        //     : e.target.textContent;
        var changedValue = e.target.value
            ? e.target.value
            : e.target.textContent;
        var rowId = e.target.value
            ? e.target.parentElement.parentElement.id
            : e.target.id;
        var columnId = e.target.value
            ? e.target.parentElement.parentElement.dataset.itemid
            : e.target.dataset.itemid;
        let changedRows = null;
        if (e.target.value === "on" || e.target.value === "off") {
            rowId = e.target.parentElement.parentElement.parentElement.id;
            columnId =
                e.target.parentElement.parentElement.parentElement.dataset
                    .itemid;
            changedValue = changedValue === "on" ? "0" : "1";
        }
        if (isEmailField) {
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!changedValue.match(regexEmail)) {
                setInvalidEmail({ rowIndex: rowIndex });
                setSubmitDisable(true);
                return;
            } else {
                setSubmitDisable(false);
                setInvalidEmail(null);
            }
        }
        if (cellChanges.length !== 0) {
            changedRows = cellChanges.map((row) => {
                return {
                    ...row,
                    cells: row.cells.map((cell) => {
                        if (
                            parseInt(cell.columnId) === parseInt(columnId) &&
                            parseInt(cell.rowId) === parseInt(rowId)
                        ) {
                            if (e.target.value) {
                                setInstanceBlockchainId(row.cells[0].cellValue);
                                const column = data.columnChainArray.filter(
                                    (column) =>
                                        column.columnId ===
                                        parseInt(cell.columnId)
                                );
                                setPostSubmitPayload({
                                    [column[0].columnName]: changedValue,
                                });
                            }
                            return {
                                ...cell,
                                cellValue: changedValue,
                            };
                        } else return cell;
                    }),
                };
            });
        } else if (cellChanges.length === 0) {
            changedRows = data.rowChainArray.map((row) => {
                return {
                    ...row,
                    cells: row.cells.map((cell) => {
                        if (
                            parseInt(cell.columnId) === parseInt(columnId) &&
                            parseInt(cell.rowId) === parseInt(rowId)
                        ) {
                            if (e.target.value) {
                                setInstanceBlockchainId(row.cells[0].cellValue);
                                const column = data.columnChainArray.filter(
                                    (column) =>
                                        column.columnId ===
                                        parseInt(cell.columnId)
                                );
                                setPostSubmitPayload({
                                    [column[0].columnName]: changedValue,
                                });
                            }
                            return {
                                ...cell,
                                cellValue: changedValue,
                            };
                        } else return cell;
                    }),
                };
            });
        }
        setData({ ...data, rowChainArray: changedRows });
        setCellChanges([]);
        setCellChanges([...changedRows]);
    };

    const setOpenDialog = (value) => {
        if (value === false) {
            setRefreshResult({ open: false, message: "", title: "" });
            setSubmitResult({ open: false, message: "", title: "" });
        } else if (refreshResult.title !== "" && refreshResult.message !== "") {
            setRefreshResult({ ...refreshResult, open: value });
        } else if (submitResult.title !== "" && submitResult.message !== "") {
            setSubmitResult({ ...submitResult, open: value });
        }
    };
    const fetchRowsForInstanceID = (row) => {
        if (
            row.cells[instanceColumnIndex].columnId ===
            parseInt(instanceColumnId)
        ) {
            if (
                options.instanceId ===
                parseInt(row.cells[instanceColumnIndex].cellValue)
            ) {
                rowsCount = rowsCount + 1;
                return row;
            }
        }
    };
    const setInstanceColumnIdandIndex = (columnId, index) => {
        instanceColumnId = columnId;
        instanceColumnIndex = index;
        return null;
    };
    const addDateID = (columnId, index) => {
        dateColumnIds.push(columnId);
        return null;
    };

    const handleActiveChange = () => {
        activeChecked ? setActiveChecked(false) : setActiveChecked(true);
    };
    const onChangeHandler = (value, cuboidName, type) => {
        if (cuboidName === "Email Tile") {
            const gens = columnDropdownValues[1];
            setColumnDropdownValues({
                1: gens,
                2: [
                    "Select",
                    "DELAYED",
                    "SKIPPED",
                    "KEY MISSING",
                    "KEY MISMATCH",
                    "PROACTIVE ALERT",
                    "DATE CHANGED",
                    "OTHERS",
                ],
                3: [].concat.apply(["Select"], stepNamesMapData.get(value)),
                4: [].concat.apply(["Select"], fromContactsMapData.get(value)),
                5: [].concat.apply(["Select"], toContactsMapData.get(value)),
            });
        } else {
            if (type === "gen") {
                const gens = columnDropdownValues[0];
                const instanceValues = [];
                const stepNameValues = [];
                const fromContactsValues = [];
                const toContactsValues = [];
                value.split(",").forEach((value) => {
                    instanceValues.push(instanceMapData.get(value));
                    stepNameValues.push(stepNamesMapData.get(value));
                    fromContactsValues.push(fromContactsMapData.get(value));
                    toContactsValues.push(toContactsMapData.get(value));
                });
                setColumnDropdownValues({
                    0: gens,
                    1: [].concat.apply(["Select"], instanceValues),
                    2: [].concat.apply(
                        [],
                        Array.from(new Set(stepNameValues.flat()))
                    ),
                    3: [].concat.apply(["Select"], fromContactsValues),
                    4: [].concat.apply(["Select"], toContactsValues),
                    5: [
                        "Select",
                        "Initiation",
                        "Commitment",
                        "Fulfillment",
                        "Settlement",
                        "Completion",
                    ],
                    6: [
                        "DELAYED",
                        "SKIPPED",
                        "KEY MISSING",
                        "KEY MISMATCH",
                        "PROACTIVE ALERT",
                        "DATE CHANGED",
                        "OTHERS",
                    ],
                    7: [
                        "Select",
                        "Last 1 day",
                        "Last 7 days",
                        "Last 15 days",
                        "Last 30 Days",
                        "Last 90 days",
                    ],
                });
            }
        }
    };

    const getToggleTemplate = (cellValue, rowId) => {
        return (
            <Switch
                id={"switch_" + rowId}
                checked={parseInt(cellValue) ? true : false}
                value={parseInt(cellValue) ? "on" : "off"}
            ></Switch>
        );
    };

    const changeRecurCell = (time, freqObject, isActive) => {
        const freqArray = [];
        for (const key in freqObject) {
            if (Object.hasOwnProperty.call(freqObject, key)) {
                const element = freqObject[key];
                if (element) freqArray.push(key.toUpperCase());
            }
        }
        const { rowId, columnId } = recurData;
        let freqString = freqArray.join(",");
        freqString = freqString + "|" + time;
        onChange(
            {
                target: {
                    textContent: freqString,
                    id: rowId,
                    dataset: { itemid: columnId },
                },
            },
            false,
            7
        );
        setRecurData({ ...recurData, recString: freqString });
    };

    const renderRows = (row, rowIndex) => {
        return (
            <TableRow
                id={row.rowId}
                style={{
                    background:
                        row.rowId === "N0" && disable ? "whitesmoke" : "white",
                }}
                data-seqNumber={row.rowSequenceNumber}
            >
                {row.cells.map((cell, index) =>
                    excludedColumns.includes(cell.columnId) ? null : (
                        <TableCell
                            id={cell.rowId}
                            data-itemID={cell.columnId}
                            data-test={cell.txId}
                            // contentEditable={
                            //     !(
                            //         (columnDropdownValues &&
                            //             columnDropdownValues[index]) ||
                            //         toggleColumns.includes(cell.columnId)
                            //     )
                            // }
                            onInputCapture={
                                index === 0 &&
                                data.info.gridName ===
                                    "Email Alerts Rule Configuration"
                                    ? null
                                    : (e) => {
                                          onChange(e, false, rowIndex);
                                      }
                            }
                            onContextMenu={(e) => {
                                e.preventDefault();
                                // setPopoverTarget(e.currentTarget);
                                // // setSelectedCell({
                                // //     rowId: row.rowId,
                                // //     columnId: cell.columnId,
                                // //     rowSequenceNumber: row.rowSequenceNumber,
                                // // });
                                // setShowPopover(true);
                                if (!disable) {
                                    setPopoverTarget(e.currentTarget);
                                    setSelectedCell({
                                        rowId: row.rowId,
                                        columnId: cell.columnId,
                                        rowSequenceNumber:
                                            row.rowSequenceNumber,
                                    });
                                    setShowPopover(true);
                                }
                            }}
                            style={{
                                fontFamily: "Montserrat",
                                border: "1px solid #e0e0e0",
                                maxWidth: "16rem",
                                color:
                                    index === 0 &&
                                    invalidEmail &&
                                    rowIndex === invalidEmail.rowIndex
                                        ? "red"
                                        : "inherit",
                            }}
                            onClick={
                                index !== 9
                                    ? null
                                    : () => {
                                          setShowRecureModal(true);
                                          setRecurData({
                                              recurString: cell.cellValue,
                                              rowId: cell.rowId,
                                              columnId: cell.columnId,
                                          });
                                      }
                            }
                        >
                            {dateColumnIds.includes(cell.columnId) ? (
                                <DatePickers
                                    defaultValue={cell.cellValue}
                                ></DatePickers>
                            ) : columnDropdownValues &&
                              columnDropdownValues[index] ? (
                                <CustomCell
                                    cellValue={cell.cellValue}
                                    type={"dropdown"}
                                    values={columnDropdownValues[index]}
                                    onChangeHandler={onChangeHandler}
                                    onValueChange={onChange}
                                    index={index}
                                    cuboidName={data.info.gridName}
                                    rowId={cell.rowId}
                                    columnId={cell.columnId}
                                ></CustomCell>
                            ) : toggleColumns.includes(cell.columnId) ? (
                                getToggleTemplate(cell.cellValue, cell.rowId)
                            ) : index === 0 &&
                              data.info.gridName ===
                                  "Email Alerts Rule Configuration" ? (
                                <CustomCell
                                    cellValue={cell.cellValue}
                                    type={"input"}
                                    onChangeHandler={onChange}
                                    index={rowIndex}
                                    cuboidName={data.info.gridName}
                                    rowId={cell.rowId}
                                    columnId={cell.columnId}
                                />
                            ) : (
                                cell.cellValue
                            )}
                        </TableCell>
                    )
                )}
            </TableRow>
        );
    };
    let dateColumnNames = [];
    let instanceColumnId = null;
    let instanceColumnIndex = null;
    instanceInfoColumns &&
        instanceInfoColumns.filter((column) => {
            if (column.dataType === "Date") {
                dateColumnNames.push(column.columnName);
                return true;
            } else return false;
        });
    let dateColumnIds = [];
    let rowsCount = 0;
    const excludedColumns = [];
    const toggleColumns = [];
    return data.columnChainArray ? (
        <div
            className="col-12 defaultScroller"
            style={{
                display: "flex",
                flexDirection: "column",
                height: "114vh",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexBasis: "10%",
                    height: "10%",
                }}
            >
                <div></div>
                <div
                    style={{
                        margin: "10px",
                        padding: "6px 16px",
                        fontWeight: "bold",
                    }}
                    title={gridName}
                >
                    {/* {"Email Configuration"} */}
                    {gridName}
                </div>
                <div>
                    <Button
                        disabled={submitDisable}
                        className={""}
                        variant={"contained"}
                        style={{ margin: "10px" }}
                        onClick={submitDisable ? null : () => _BW_Submit()}
                    >
                        {"Submit"}
                    </Button>
                    <Button
                        variant={"contained"}
                        className={""}
                        style={{ margin: "10px" }}
                        // onClick={() =>
                        //     dispatch(
                        //         fetchRefreshData(
                        //             data.info.gridId,
                        //             data.info.importTxId
                        //         )
                        //     )
                        // }
                        disabled={disable}
                        onClick={() =>
                            // setSelectedCell({
                            //     rowId: data.rowChainArray[0].rowId,
                            //     columnId: data.columnChainArray[0].columnId,
                            //     rowSequenceNumber:
                            //         data.rowChainArray[0].rowSequenceNumber,
                            // });
                            menuItemClick(0, "add_row")
                        }
                    >
                        {"Add Row"}
                    </Button>
                </div>
            </div>
            <Paper
                style={{
                    height: "inherit",
                    flexBasis: "65%",
                    position: "relative",
                }}
            >
                <TableContainer
                    className="invisibleScroller spacelistTable"
                    style={{ padding: "0px" }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        size={"small"}
                    >
                        <TableHead>
                            <TableRow>
                                {data.columnChainArray.map((column, index) => {
                                    if (column.columnName === "Active") {
                                        toggleColumns.push(column.columnId);
                                    }
                                    // if (column.columnName === "Time Range") {
                                    //     excludedColumns.push(column.columnId);
                                    //     return null;
                                    // }
                                    return (
                                        <TableCell
                                            key={column.columnId}
                                            style={{
                                                maxWidth: "10rem",
                                                border: "1px solid #e0e0e0",
                                                fontWeight: "bold",
                                            }}
                                            title={
                                                columnNameToTitleMap.get(
                                                    column.columnName
                                                ) || column.columnName
                                            }
                                        >
                                            {column.columnName}
                                            {column.columnName === "Instance Id"
                                                ? setInstanceColumnIdandIndex(
                                                      column.columnId,
                                                      index
                                                  )
                                                : null}
                                            {dateColumnNames.includes(
                                                column.columnName
                                            )
                                                ? addDateID(column.columnId)
                                                : null}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {options.instanceRegistryTbl
                                ? data.rowChainArray
                                      .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      .map((row, rowIndex) => {
                                          return renderRows(row, rowIndex);
                                      })
                                : data.rowChainArray
                                      .filter((row) => {
                                          return fetchRowsForInstanceID(row);
                                      })
                                      .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      .map((row, rowIndex) => {
                                          return renderRows(row, rowIndex);
                                      })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.rowChainArray.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "0px",
                    }}
                />
            </Paper>
            <PopoverView
                id={"menu_"}
                show={showPopover}
                anchorEl={popoverTarget}
                closePopover={closePopover}
            >
                {renderMenu()}
            </PopoverView>
            <Loader show={showLoader} />
            <AlertDialog
                open={showAlert}
                setOpen={() => setShowAlert(false)}
                title={""}
                message={
                    gridName === "Email Alerts Rule Configuration"
                        ? "Please Select All Fields"
                        : // :"Please Select Model Name
                          "Please Select Model Name and Time Range"
                }
            ></AlertDialog>
            <CustomSnackbar
                open={refreshResult.open || submitResult.open}
                setOpen={setOpenDialog}
                alertMessage={refreshResult.message || submitResult.message}
            ></CustomSnackbar>
            <RecureModalView
                data={recurData.recurString}
                show={showRecureModal}
                onHide={() => setShowRecureModal(false)}
                handleDisableRipple={() => {}}
                onclick={() => setShowRecureModal(false)}
                onSubmit={(
                    selectedEveryCount,
                    selectedEveryType,
                    selectedTime,
                    dateObj,
                    isClick
                ) => {
                    changeRecurCell(selectedTime, dateObj, isClick);
                    setShowRecureModal(false);
                }}
                isDashboardConfig={true}
            ></RecureModalView>
        </div>
    ) : null;
}

export default WebGridInterface;
