import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "./style.css";

const Card = ({
    title,
    description,
    actionButton1,
    actionButton2,
    handleActionButton1Click,
    handleActionButton2Click,
}) => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    return (
        <div className="card-container">
            <div className="card-heading">{title}</div>
            <div className="card-description">{description}</div>
            <div style={{ flexBasis: "15%", height: "15%" }}>
                <Stack spacing={2} direction={"row"}>
                    <Button
                        className={
                            isReadOnlyUser ? "readOnly step-btn" : "step-btn"
                        }
                        variant="contained"
                        onClick={(event) =>
                            isReadOnlyUser
                                ? null
                                : handleActionButton1Click(event)
                        }
                        sx={{
                            height: "30px",

                            textTransform: "none",
                        }}
                        title={actionButton1}
                    >
                        {actionButton1}
                    </Button>
                    <Button
                        className="step-btn"
                        sx={{
                            height: "30px",

                            textTransform: "none",
                        }}
                        variant="outlined"
                        onClick={handleActionButton2Click}
                        title={actionButton2}
                    >
                        {actionButton2}
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export default Card;
