export const setUserIdToFullName = (response) => {
    return function (dispatch) {
        dispatch({
            type: "ID_TO_USER_FULLNAME_MAP",
            payload: response,
        });
    };
};
export const setUserIdToEmail = (response) => {
    return function (dispatch) {
        dispatch({
            type: "ID_TO_USER_EMAIL",
            payload: response,
        });
    };
};
