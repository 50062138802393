import { BWGraph } from "./RippleGraph";
import config from "./config";

let instanceSteps = [];
let genericSteps = [];
let mergeBlocks = [];
function convertJsonToBwGraph(setDataError) {
    const sourceIdArray = [];
    const bwGraph = new BWGraph();
    try {
        instanceSteps.forEach((element, index) => {
            let step = element;
            let instanceStepName = step.instanceStepName;
            let instanceStepId = step.instanceStepId;
            let branchName = step.branchName;
            let rootNode = bwGraph.getNode(instanceSteps[0].instanceStepId);
            if (!rootNode)
                bwGraph.createRoot(
                    instanceStepId,
                    "-1",
                    instanceStepName,
                    "Space"
                );

            if (index) {
                let stepNode = bwGraph.getNode(instanceStepId);
                if (!stepNode) {
                    if (branchName === instanceSteps[index - 1].branchName)
                        bwGraph.addNode(
                            instanceStepId,
                            "-1",
                            [step.parentInstanceStepId],
                            instanceStepName,
                            "Space"
                        );
                    else
                        bwGraph.addNode(
                            instanceStepId,
                            "-1",
                            [step.parentInstanceStepId],
                            instanceStepName,
                            "Space"
                        );
                }
            }
        });
        mergeBlocks.forEach((element, index) => {
            element.Source.forEach((id) => {
                bwGraph.addEdges(id.split(","), element.Target[0]);
            });
        });
        genericSteps.forEach((element, index) => {
            let step = element;
            let genericStepName = step.genericStepName;
            let genericStepId = step.genericStepId;
            let rootNode = bwGraph.getNode(genericSteps[0].genericStepId);
            if (!rootNode)
                bwGraph.createRoot(
                    genericStepId,
                    "-1",
                    genericStepName,
                    "Space"
                );
            let stepNode = bwGraph.getNode(genericStepId);
            if (!stepNode) {
                bwGraph.addNode(
                    genericStepId,
                    "-1",
                    [genericSteps[index - 1].genericStepId],
                    genericStepName,
                    "Space"
                );
            }
        });
        setDataError(null);
    } catch (error) {
        console.log("Error while converting json to bwGraph", error);
        setDataError(error);
    }
    return bwGraph;
}
const getExtension = (type) => {
    let src = null;
    const fileType = type.split(".").pop().toLowerCase().trim();

    switch (fileType) {
        case "txt":
            src = "./images/fileTypeIcon/text.svg";
            break;
        case "pdf":
            src = "./images/fileTypeIcon/pdf.svg";
            break;
        case "docx":
            src = "./images/word-icon.svg";
            break;
        case "jpg":
            src = "./images/fileTypeIcon/jpg-icon.svg";
            break;
        case "svg":
            src = "./images/fileTypeIcon/svg-icon.svg";
            break;
        case "mp3":
            src = "./images/fileTypeIcon/mp3-icon.svg";
            break;
        case "gif":
            src = "./images/fileTypeIcon/gif-icon.svg";
            break;
        case "mp4":
            src = "./images/fileTypeIcon/mp4-icon.svg";
            break;
        case "xlsx":
            src = "./images/excel-icon.svg";
            break;
        case "pptx":
            src = "./images/ppt-icon.svg";
            break;
        case "xml":
            src = "./images/fileTypeIcon/xml-file.svg";
            break;
        case "html":
            src = "./images/fileTypeIcon/htmlIcon.svg";
            break;
        default:
            src = "./images/basicfile.png";
            break;
    }
    return src;
};
const getImg = (type, label) => {
    let src = null;
    switch (type) {
        case "Workspace":
            src = "./images/Group 410.svg";
            break;
        case "Space":
            src = "./images/Group 441.svg";
            break;
        case "Folder1":
            src = "./images/Group 438.svg";
            break;
        case "Folder2":
            src = "./images/Group 438.svg";
            break;
        case "Folder3":
            src = "./images/Group 438.svg";
            break;
        case "Folder4":
            src = "./images/Group 438.svg";
            break;
        case "Folder5":
            src = "./images/Group 438.svg";
            break;
        case "Folder6":
            src = "./images/Group 438.svg";
            break;
        case "Folder7":
            src = "./images/Group 438.svg";
            break;
        case "Folder8":
            src = "./images/Group 438.svg";
            break;
        case "Folder9":
            src = "./images/Group 438.svg";
            break;
        case "Folder10":
            src = "./images/Group 438.svg";
            break;
        default:
            src = getExtension(label);
    }
    return src;
};

const getNodeColor = (type) => {
    let color = null;
    switch (type) {
        case "Workspace":
            color = config[1]["WorkspaceColor"];
            break;
        case "Space":
            color = config[2]["SpaceColor"];
            break;
        case "Folder1":
            color = config[3]["Folder1Color"];
            break;
        case "Folder2":
            color = config[4]["Folder2Color"];
            break;
        case "Folder3":
            color = config[5]["Folder3Color"];
            break;
        case "Folder4":
            color = config[6]["Folder4Color"];
            break;
        case "Folder5":
            color = config[7]["Folder5Color"];
            break;
        case "Folder6":
            color = config[8]["Folder6Color"];
            break;
        case "Folder7":
            color = config[9]["Folder7Color"];
            break;
        case "Folder8":
            color = config[10]["Folder8Color"];
            break;
        case "Folder9":
            color = config[11]["Folder9Color"];
            break;
        case "Folder10":
            color = config[12]["Folder10Color"];
            break;
        default:
            color = config[0]["DocumentColor"];
    }
    return color;
};
function convertBwGraphToReactFlow(bwGraph) {
    const position = { x: 0, y: 0 };
    let nodes = [];
    let edges = [];
    let elements = [];
    let i = 0;
    let bwGraphNodes = bwGraph.getNodes();
    let bwGraphEdges = bwGraph.getEdges();

    bwGraphNodes.forEach((element) => {
        const instanceStep = instanceSteps.filter(
            (step) => step.instanceStepId === parseInt(element.id)
        );
        const genericStep = genericSteps.filter(
            (step) => step.genericStepId === element.id
        );
        let node = {};
        let data = {};
        let style = {
            overflow: "hidden",
            textOverflow: "ellipsis",
        };
        let type = element.type;
        let label = element.label;
        node["id"] = element.id;
        data["docId"] = element.docId;
        node["type"] = "selectorNode";
        data["label"] = element.label;
        data["img"] = getImg(type, label);
        node["data"] = instanceStep.length
            ? { ...instanceStep[0], instanceSteps: instanceSteps }
            : genericStep.length
            ? genericStep[0]
            : null;
        style["white-space"] = "pre";
        style["background"] = getNodeColor(type);
        style["border-radius"] = "10px";
        // style["height"] = "175px";
        style["width"] = "400px";
        // style["border"] = getNodeBorderColor(type);
        node["style"] = style;
        node.position = position;
        nodes.push(node);
        i++;
    });
    bwGraphEdges.forEach((element) => {
        let edge = {};
        let style = {
            strokeWidth: "2.5",
            color: "red",
        };
        edge["id"] = element.source + element.target;
        edge["markerEndId"] = "edge_" + element.source + element.target;
        edge["source"] = element.source;
        edge["target"] = element.target;
        edge["type"] = "default";
        edge["style"] = style;
        edge["arrowHeadType"] = "arrowclosed";
        edge["step"] = "StepEdge";
        edges.push(edge);
    });
    elements = nodes.concat(edges);
    return elements;
}

const getMergeBlockObject = (selectedMergeBlocks) => {
    let selectedMergeBlockObject = [];
    // return selectedMergeBlocks.map((block) => {
    //     const data = block.properties.split(";");
    //     const source = data[0].split(":");
    //     const target = data[1].split(":");
    //     const sourceBlock = { [source[0]]: source.slice(1) };
    //     const targetBlock = { [target[0]]: target.slice(1) };
    //     return [sourceBlock, targetBlock];
    // });
    selectedMergeBlocks.forEach((block) => {
        const data = block.properties.split(";");
        const source = data[0].split(":");
        const target = data[1].split(":");
        selectedMergeBlockObject.push({
            [source[0]]: source.slice(1),
            [target[0]]: target.slice(1),
        });
    });
    return selectedMergeBlockObject;
};

const getParentStep = (branchSteps, currentStep) => {
    const currentStepIndex = branchSteps.indexOf(currentStep);
    return branchSteps[currentStepIndex - 1];
};
function BranchViewReactFlow(
    instanceData,
    genericData,
    selectedMergeBlocks,
    setDataError,
    originalInstanceSteps
) {
    const mergeBlockObject = getMergeBlockObject(selectedMergeBlocks);
    mergeBlocks = mergeBlockObject;
    const orderedGenericSteps = genericData.sort(
        (a, b) => a.genericOrderId - b.genericOrderId
    );
    let updatedInstanceSteps = [];
    orderedGenericSteps.forEach((element) => {
        const foundPromiseSteps = instanceData.filter(
            (instanceStep) =>
                instanceStep.instanceUniqueStepIdentifier ===
                element.genericStepUniqueIdentifier
        );
        updatedInstanceSteps = [...updatedInstanceSteps, ...foundPromiseSteps];
    });
    // instanceSteps = [...updatedInstanceSteps];
    const tempInstanceSteps = [];
    const genSteps = updatedInstanceSteps.map((step) => step.instanceStepId);
    const getTheParentStepId = (originalInstanceSteps, currentStep) => {
        if (currentStep.parentInstanceStepId == "0")
            return currentStep.parentInstanceStepId;
        const stepFound = originalInstanceSteps.find(
            (step) => currentStep.parentInstanceStepId == step.instanceStepId
        );
        if (genSteps.includes(parseInt(stepFound.instanceStepId)))
            return stepFound.instanceStepId.toString();
        else return getTheParentStepId(originalInstanceSteps, stepFound);
    };
    updatedInstanceSteps.forEach((element) => {
        tempInstanceSteps.push({
            ...element,
            parentInstanceStepId: getTheParentStepId(
                originalInstanceSteps,
                element
            ),
        });
    });
    instanceSteps = [...tempInstanceSteps];
    const promiseSteps = instanceData.map((step) => step.instanceStepId);
    // instanceSteps = instanceData.map((step, index) => {
    //     if (
    //         index !== 0 &&
    //         !promiseSteps.includes(parseInt(step.parentInstanceStepId))
    //     ) {
    //         const parentStep = getParentStep(
    //             instanceData.filter(
    //                 (item) => item.branchName === step.branchName
    //             ),
    //             step
    //         );
    //         if (parentStep) {
    //             return {
    //                 ...step,
    //                 parentInstanceStepId: "" + parentStep.instanceStepId,
    //             };
    //         } else return step;
    //         // if (step.branchName === instanceData[index - 1].branchName)
    //         //     return {
    //         //         ...step,
    //         //         parentInstanceStepId:
    //         //             "" + instanceData[index - 1].instanceStepId,
    //         //     };
    //         // else if (
    //         //     instanceData[index - 1].branchName ===
    //         //     instanceData[index - 2].branchName
    //         // ) {
    //         //     return {
    //         //         ...step,
    //         //         parentInstanceStepId:
    //         //             "" + instanceData[index - 2].instanceStepId,
    //         //     };
    //         // } else if (
    //         //     instanceData[index - 2].branchName ===
    //         //     instanceData[index - 3].branchName
    //         // ) {
    //         //     return {
    //         //         ...step,
    //         //         parentInstanceStepId:
    //         //             "" + instanceData[index - 3].instanceStepId,
    //         //     };
    //         // } else if (
    //         //     instanceData[index - 3].branchName ===
    //         //     instanceData[index - 4].branchName
    //         // ) {
    //         //     return {
    //         //         ...step,
    //         //         parentInstanceStepId:
    //         //             "" + instanceData[index - 4].instanceStepId,
    //         //     };
    //         // }
    //     } else return step;
    // });
    genericSteps = genericData.map((genStep) => {
        return {
            ...genStep,
            genericStepId: "10" + genStep.genericStepId,
        };
    });
    return convertBwGraphToReactFlow(convertJsonToBwGraph(setDataError));
}

export default BranchViewReactFlow;
