import mixpanel from "mixpanel-browser";
let mixpanelToken =
    window.runtimeConfig && window.runtimeConfig.mixpanelToken
        ? window.runtimeConfig.mixpanelToken
        : null;
if (mixpanelToken) mixpanel.init(mixpanelToken, { debug: true });

let actions = {
    identify: (id) => {
        if (mixpanelToken) mixpanel.identify(id);
    },
    alias: (id) => {
        if (mixpanelToken) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (mixpanelToken) mixpanel.track(name, props);
    },
    register: (props) => {
        if (mixpanelToken) mixpanel.register(props);
    },
    reset: () => {
        if (mixpanelToken) mixpanel.reset();
    },
    people: {
        set: (props) => {
            if (mixpanelToken) mixpanel.people.set(props);
        },
        set_once: (props) => {
            if (mixpanelToken) mixpanel.people.set_once(props);
        },
        increment: (prop) => {
            if (mixpanelToken) mixpanel.people.increment(prop);
        },
    },
};

export let Mixpanel = actions;

export function callPageViewEvent(pageTitle) {
    let pageProps = {};
    pageProps["Page Path"] = window.location.pathname;
    pageProps["Page Title"] = pageTitle;
    pageProps["Page Hostname"] = window.location.hostname;
    pageProps["Page URL"] = window.location.href;
    pageProps["Referrer Hostname"] = "";
    pageProps["Referrer URL"] = document.referrer;
    actions.track("Page View", pageProps);
}

export function callSignUpPageViewEvent() {
    let pageProps = {};
    actions.track("Sign Up Page View", pageProps);
}

export function callSignUpEvent(user) {
    actions.alias(user.email);
    actions.register({
        "Plan Type": user.plan,
    });
    actions.track("Sign Up");
    actions.people.set({
        "Plan Type": user.plan,
        $name: user.name,
        $email: user.email,
        "Reported Company": user.company,
        "Reported Industry": user.industry,
    });
}

export function callUserIdentityEvent(action, user) {
    let props = {
        "Identity Action" : action
    }
    actions.track("User Identity", props);
    if(action === "Logoff") {
        actions.reset();
        actions.people.set({
            "Last Logoff" : new Date().toISOString(),
        });
    }
    if(action === "Login") {
        actions.identify(user.email);
        actions.register({ "Plan Type": user.plan });
        actions.people.set({
            "Last Login" : new Date().toISOString(),
            $name: user.name,
            $email: user.email,
            "Reported Company": user.company,
            "Reported Industry": user.industry,
        });
    }
    if(action === "Password Change") {
        actions.people.set({
            "Last Password Change" : new Date().toISOString(),
        });
    }
    if(action === "Email Change") {
        actions.people.set({
            "Last Email Change" : new Date().toISOString(),
        });
    }
}

export function callContentCreationEvent(fileType) {
    let props = {
        "Create Content File Type" : fileType
    }
    actions.track("Content Creation", props);
    actions.people.set({
        "Last Content Created": new Date().toISOString(),
    });
    actions.people.set_once({
        "First Content Created": new Date().toISOString(),
    });
    actions.people.increment("Lifetime Contents Created");
}

export function callContentCreationExitEvent() {
    actions.track("Content Creation Exit");
}

export function callPageExitEvent(pageProps) {
    actions.track("Page Exit", pageProps);
}

export function callWorkspaceManagementEvent(action) {
    let props = {
        "Workspace Action" : action,
    };
    actions.track("Workspace Management", props);
    if (action === "Add") {
        actions.people.set({
            "Last Workspace Created": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Workspace Created": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Workspaces Created");
    } else if (action === "Rename") {
        actions.people.increment("Lifetime Workspaces Renamed");
    } else if (action === "Delete") {
        actions.people.set({
            "Last Workspace Deleted": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Workspace Deleted": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Workspaces Deleted");
    }
}

export function callItemManagementEvent(action, count) {
    let props = {
        "Item Action" : action,
    };
    actions.track("Item Management", props);
    if(action === "Archive") {
        actions.people.set({
            "Last Item Archived": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Item Archived": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Items Archived", count);
    } else if(action === "Delete") {
        actions.people.set({
            "Last Item Deleted": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Item Deleted": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Items Deleted", count);
    }
}

export function callSpaceManagementEvent(action) {
    let props = {
        "Space Action" : action
    };
    actions.track("Space Management", props);
    if (action === "Add") {
        actions.people.set({
            "Last Space Created": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Space Created": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Spaces Created");
    } else if (action === "Rename") {
        actions.people.increment("Lifetime Spaces Renamed");
    } else if (action === "Archive") {
    } else if ((action = "Delete")) {
    }
}

export function callFolderManagementEvent(action) {
    let props = {
        "Folder Action" : action
    };
    actions.track("Folder Management", props);
    if (action === "Add") {
        actions.people.set({
            "Last Folder Created": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Folder Created": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Folders Created");
    } else if (action === "Rename") {
        actions.people.increment("Lifetime Folders Renamed");
    } else if (action === "Archive") {
    } else if (action === "Delete") {
    } else if (action === "Share") {
        actions.people.set({
            "Last Folder Shared": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Folder Shared": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Folders Shared");
    } else if (action === "Move") {
        actions.people.set({
            "Last Folder Moved": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Folder Moved": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Folders Moved");
    } else if (action === "Download") {
        actions.people.set({
            "Last Folder Downloaded": new Date().toISOString(),
        });
        actions.people.set_once({
            "First Folder Downloaded": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Folders Downloaded");
    }
}

export function callFileManagementEvent(action, fileExt, fileSize) {
    let props = {
        "File Action" : action,
        "File Type Extension" : fileExt,
        "File Size" : fileSize ? fileSize : ""
    };
    actions.track("File Management", props);
    if (action === "Upload") {
        actions.people.set({
            "Last File Uploaded": new Date().toISOString(),
        });
        actions.people.set_once({
            "First File Uploaded": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Files Uploaded");
    } else if (action === "Archive") {
    } else if (action === "Delete") {
    } else if (action === "Share") {
        actions.people.set({
            "Last File Shared": new Date().toISOString(),
        });
        actions.people.set_once({
            "First File Shared": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Files Shared");
    } else if (action === "Move") {
        actions.people.set({
            "Last File Moved": new Date().toISOString(),
        });
        actions.people.set_once({
            "First File Moved": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Files Moved");
    } else if (action === "Download") {
        actions.people.set({
            "Last File Downloaded": new Date().toISOString(),
        });
        actions.people.set_once({
            "First File Downloaded": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Files Downloaded");
    } else if (action === "View") {
        actions.people.set({
            "Last File Viewed": new Date().toISOString(),
        });
        actions.people.set_once({
            "First File Viewed": new Date().toISOString(),
        });
        actions.people.increment("Lifetime Files Viewed");
    }
}
