import GoogleMapReact from "google-map-react";
export default function ControlTowerMap() {
    const defaultProps = {
        center: {
            lat: 16.705,
            lng: 74.2433,
        },
        zoom: 11,
    };
    const pathCoordinates = [
        { lat: 16.705, lng: 74.2433 },
        { lat: 13.0827, lng: 80.2707 },
    ];
    const handleApiLoaded = (map, maps) => {
        // let line = new maps.Polyline({
        //     path: pathCoordinates,
        //     options:{
        //         strokeColor: 'red',
        //     },
        // });
        // line.setMap(map);
    };

    return (
        <div
            id="control-tower-view"
            data-testid="control-tower-view"
            className="doCenter"
            style={{
                height: "80%",
                display: "flex",
                margin: "5px",
            }}
        >
            <div
                className="doCenter"
                style={{
                    width: "100%",
                    height: "inherit",
                    marginLeft: "25px",
                    flexDirection: "column",
                    border: "1px solid #A5A5A5",
                    borderRadius: "10px",
                    margin: "0px",
                    marginTop: "5px",
                    padding: "5px",
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(map, maps)
                    }
                >
                    <div
                        lat={16.705}
                        lng={74.2433}
                        style={{
                            width: "10px",
                            height: "10px",
                            border: "1px solid red",
                            display: "inline-block",
                        }}
                    ></div>
                    <div
                        lat={13.0827}
                        lng={80.2707}
                        style={{
                            width: "10px",
                            height: "10px",
                            border: "1px solid red",
                            display: "inline-block",
                        }}
                    ></div>
                </GoogleMapReact>
            </div>
        </div>
    );
}
