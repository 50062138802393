export const disableRefreshFeed = (flag = false, action) => {
    if (action.type === "REFRESH_FEED") {
        return action.payload;
    }
    return flag;
};
export const informationFeedItems = (items = [], action) => {
    if (action.type === "SET_FEED_ITEMS") {
        return action.payload;
    }
    return items;
};
export const informationFeedItemToRender = (item = null, action) => {
    if (action.type === "SET_FEED_ITEM_TO_REDIRECT") {
        return action.payload;
    }
    return item;
};
export const existingIntegrationList = (item = [], action) => {
    if (action.type === "SET_INTEGRATION_ITEM_LIST") {
        return action.payload;
    }
    return item;
};

export const newMailNotification = (item = false, action) => {
    if (action.type === "RELOAD_INFORMATION_FEED") {
        return action.payload;
    }
    return item;
};