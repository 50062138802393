import React from "react";
import "./Style.css";
function FilterListItem({ listItem, editFilter, handleDeleteFilter }) {
    const handleEditFilter = () => {
        editFilter(listItem);
    };
    const deleteFilterHandler = () => {
        handleDeleteFilter(listItem.filterId, listItem.accountType);
    };

    return (
        <div className="row filterItem_row m-0 ml-4 mr-4 mb-3">
            <div className="col-10 textEllipsis pr-0">
                <span
                    id={
                        listItem.filterId +
                        "_" +
                        listItem.filterName.toLowerCase()
                    }
                    title={listItem.filterName}
                    className="filterItem_title cursorPointer"
                >
                    {listItem.filterName}
                </span>
            </div>
            <div className="col-2 p-0 doCenter">
                <img
                    id={"btn_FilterListItem_editFilter_" + listItem.filterId}
                    src="./images/Integration/pencil-square 6.png"
                    alt=""
                    className="cursorPointer"
                    onClick={handleEditFilter}
                    title="Edit Filter"
                />
                <img
                    id={"btn_FilterListItem_removeFilter_" + +listItem.filterId}
                    src="./images/Integration/Trash_bin.png"
                    alt=""
                    className="cursorPointer ml-3"
                    onClick={deleteFilterHandler}
                    title="Remove Filter"
                />
            </div>
        </div>
    );
}

export default FilterListItem;
