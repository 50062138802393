import React, { useEffect, useRef, useState } from "react";
import { useQuill } from "react-quilljs";
import http from "../../../../../../bae-client/HTTP";
import "quill/dist/quill.snow.css";
import "./style.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { fetchFolderListData } from "../../../../../../actions/FolderActions";
import { connect, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ReactHtmlParser from "html-react-parser";
import * as Mixpanel from "../../../../../../mix-panel/Mixpanel";
import { setSnackbarAction } from "../../../../../../services/services/app";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ComposerModal = ({
    setComposerModalShow,
    selectedSpace,
    setShowDocumentModal,
    parentFolderId,
    isSpaceSelected,
    setIsCheckComposer,
    noteString,
    title,
    isCheckComposer,
    show,
    setAlertDialogMessage,
    setShowAlertDialog,
    onHide,
    fetchFolderData,
    parent,
}) => {
    const [open, setOpen] = React.useState(false);
    const [isComposer, setIsComposer] = React.useState(false);
    const fileNameRef = useRef("");
    const quillRef1 = useRef("");
    const dispatch = useDispatch();
    const toolbarOptions = [
        ["bold", "italic", "underline"],
        ["image"],
        // , "strike"],
        // ["blockquote", "code-block", "code", "image", "video", "formula"],
        // [
        //     { header: 1 },
        //     { header: 2 },
        // ],
        // [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }],
        // [{ indent: "-1" }, { indent: "+1" }],
        // [{ direction: "rtl" }],
        // [{ size: ["small", false, "large", "huge"] }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ color: [] }, { background: [] }],
        // [{ font: [] }],
        // [{ align: [] }],
        ["clean"],
    ];
    const { quillRef, quill } = useQuill({
        modules: {
            toolbar: toolbarOptions,
        },
    });
    useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(noteString || "");
        }
    }, [quill, noteString]);
    // useEffect(()=>[
    //     fileNameRef.current=title
    // ],[])
    useEffect(() => {
        let fileType = "html";
        Mixpanel.callContentCreationEvent(fileType);
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setIsCheckComposer(false);
        setShowDocumentModal(false);
    };

    function saveToUC() {
        if (selectedSpace.selectedSpace === null) {
            alert("Note can be saved in spaces and folders");
        }
        if (fileNameRef.current.value === "") {
            alert("Please enter file name");
        } else {
            let fileName = "",
                flag = false;

            if (title && title.slice(-5) === ".html" && isCheckComposer) {
                fileName = title;
                flag = true;
                setIsComposer(true);
            } else {
                fileName = fileNameRef.current.value + ".html";
                flag = false;
            }
            let text = document.querySelector(".ql-editor").innerHTML;
            var fileToSave = new File([text], fileName);
            const data = new FormData();
            data.append("file", fileToSave, encodeURIComponent(fileName));

            http.uploadFile(
                data,
                `rest/spacePersistence/file?spaceId=${
                    selectedSpace.id
                }&folderId=${parentFolderId}&isContentOverride=${flag}&isEditable=${true}`,
                "POST"
            )
                .then((response) => {
                    if (response.status === 200) {
                        let fileExt = fileName.split(".").pop();
                        Mixpanel.callFileManagementEvent("Upload", fileExt);
                        if (flag) {
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Note Edited successfully!`,
                                    severity: "success",
                                })
                            );
                        } else {
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Note Created successfully!`,
                                    severity: "success",
                                })
                            );
                        }

                        setOpen(true);
                        fetchFolderData(
                            parent.id === selectedSpace.id ? { id: 0 } : parent,
                            true
                        );
                    }
                    fileName = "";
                })
                .catch((error) => {
                    // setShowAlertDialog(true);
                    // setAlertDialogMessage(error.errorCode);
                    console.log(error);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `${error.error}`,
                            severity: "error",
                        })
                    );
                });
        }
        onHide();
    }
    const onClose = () => {
        Mixpanel.callContentCreationExitEvent();
        setComposerModalShow(false);
    };
    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                dialogClassName="modal-90w"
                aria-labelledby="nodeModalTitle"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="nodeModalTitle"
                        style={{ marginRight: "1%" }}
                    >
                        Note
                    </Modal.Title>
                    <Form>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Control
                                    ref={fileNameRef}
                                    style={{ maxWidth: "100%" }}
                                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    placeholder="Name Note"
                                    defaultValue={title}
                                    // disabled={
                                    //     title
                                    //         ? title.slice(-5) === ".html"
                                    //             ? true
                                    //             : false
                                    //         : false
                                    // }
                                ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Header>
                <Modal.Body>
                    {/* <input type="text" id="noteText" ref={quillRef} defaultValue={noteString}/> */}
                    {/* <div id="noteText" ref={quillRef}>
                        {ReactHtmlParser(noteString)}
                    </div> */}
                    <div ref={quillRef} id="noteText" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={onHide}
                        id="closeNote"
                        title="Close"
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={saveToUC}
                        id="saveNote"
                        title="Save"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Note saved!
                </Alert>
            </Snackbar>
        </div>
    );
};
const mapDispatchToProps = () => {
    return {
        fetchFolderListData,
    };
};
const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(ComposerModal);
