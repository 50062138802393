import React, { Component } from "react";

class GeneralTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: "",
        };
        this.parentInstanceBlockId = null;
        this.parentInstanceBlockDocumentId = null;
        this.parentInstanceBlockDocumentName = null;
        this.from = "from";
        this.to = "to";
        this.blockType = "Block Type";
        this.promiseType = "Promise Type";
    }
    setGeneralFormData = (response) => {
        Object.keys(response).map((key) => {
            switch (key) {
                case "parentInstanceBlockId":
                    {
                        this.parentInstanceBlockId = response[key];
                    }
                    break;
                case "parentInstanceBlockDocumentId":
                    {
                        this.parentInstanceBlockDocumentId = response[key];
                    }
                    break;
                case "parentInstanceBlockDocumentName":
                    {
                        this.parentInstanceBlockDocumentName = response[key];
                    }
                    break;
                case "from":
                    {
                        this.from = response[key];
                    }
                    break;
                case "to":
                    {
                        this.to = response[key];
                    }
                    break;
                case "blockType":
                    {
                        this.blockType = response[key];
                    }
                    break;
                case "promiseType":
                    {
                        this.promiseType = response[key];
                    }
                    break;
                default:
                    break;
            }
        });
    };
    componentDidMount = () => {
        this.setGeneralFormData(this.props.response);
        this.setState({ data: "updated" });
    };

    setFormText = (value, id, event) => {
        switch (id) {
            case "parentInstanceBlockId":
                {
                    this.parentInstanceBlockId = value;
                }
                break;
            case "parentInstanceBlockDocumentId":
                {
                    this.parentInstanceBlockDocumentId = value;
                }
                break;
            case "parentInstanceBlockDocumentName":
                {
                    this.parentInstanceBlockDocumentName = value;
                }
                break;
            case "from":
                {
                    this.from = value;
                }
                break;
            case "to":
                {
                    this.to = value;
                }
                break;
            case "blockType":
                {
                    this.blockType = value;
                }
                break;
            case "promiseType":
                {
                    this.promiseType = value;
                }
                break;
            default:
                break;
        }
    };

    // "parentInstanceBlockId": 0,  				//General
    // "parentInstanceBlockDocumentId": 0, 		//General
    // "parentInstanceBlockDocumentName": null, 	//General
    // "from": "XYZ", 								//General
    // "to": "",									//General
    // "blockType": "",							//General
    // "promiseType": ""							//General
    renderFormFields = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <div className="marginTopTen">
                    <label className="ctLabel">Parent Instance Block Id</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="parentInstanceBlockId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.parentInstanceBlockId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">
                        Parent Instance Block Document Id
                    </label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="parentInstanceBlockDocumentId"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.parentInstanceBlockDocumentId}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <label className="ctLabel">
                        Parent Instance Block Document Name
                    </label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="parentInstanceBlockDocumentName"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.parentInstanceBlockDocumentName}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <input
                        autocomplete="off"
                        type="text"
                        id="from"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.from}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                    <input
                        autocomplete="off"
                        type="text"
                        id="to"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.to}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div className="marginTopTen">
                    <input
                        autocomplete="off"
                        type="text"
                        id="blockType"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.blockType}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                    <input
                        autocomplete="off"
                        type="text"
                        id="promiseType"
                        className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                        placeholder={this.promiseType}
                        onChange={(event) =>
                            this.setFromText(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                {/* <div className="marginTopTen doCenter">
                    <Button id="submitButton" label={"SUBMIT"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"REFRESH"} onclick={this.updateUserProfile} className="ctButton"></Button>
                    <Button id="refreshButton" label={"BACK"} onclick={this.props.onclick} className="ctButton"></Button>
                </div> */}
            </div>
        );
    };
    render() {
        return <>{this.renderFormFields()}</>;
    }
}

export default GeneralTab;
