import React, { useState } from "react";
import Container from "@mui/material/Container";
import "./style.css";
import { Button } from "react-bootstrap";
import ModalView from "../../../../stylized/ModalView";
import { setActivePage } from "../../../../../actions";
import { connect } from "react-redux";

const NoWorkspacePage = ({ showModal, onHide, openCreateWorkspaceModal }) => {
    const isReadOnlyUser = sessionStorage.getItem('isReadOnlyUser') === "true";
    return (
        <React.Fragment>
            <ModalView show={showModal} onHide={onHide} footer={"false"}>
                <div
                    className="makeStyles-root-11"
                    style={{ paddingBottom: "32px", marginTop: "-32px" }}
                >
                    <Container maxWidth="lg">
                        <div>
                            <img
                                alt="Workspace"
                                className="workImage"
                                src="./images/Screenshot.png"
                            ></img>
                        </div>
                        <div>
                            <span
                                className="workText"
                                style={{ textAlign: "center" }}
                            >
                                Get started by creating your first Workspace
                            </span>
                            <br />

                            <span className="subText">
                                A Workspace is a centralized location for all
                                your documents, files, and data
                            </span>
                        </div>
                        <Button
                            className={isReadOnlyUser ? "workbtn readOnly" : "workbtn"}
                            onClick={isReadOnlyUser ? null : openCreateWorkspaceModal}
                            // onClick={(e) => {
                            //     e.stopPropagation();
                            //     setActivePage("Workspace");
                            // }}
                            // onClick={() => this.props.setActivePage("AddWorkspace")}
                        >
                            Create Workspace
                        </Button>``
                    </Container>
                </div>
            </ModalView>
        </React.Fragment>
    );
};

const mapDispatchToProps = () => {
    return {
        setActivePage,
    };
};
const mapStateToProps = (state) => {
    return {
        activePage: state.activePage,
    };
};
// export default NoWorkspacePage;
export default connect(mapStateToProps, mapDispatchToProps())(NoWorkspacePage);
