// Blockchain Transaction
export class Transaction {
    constructor(mId, mTime, mUser, mComment) {
        this.id = mId;
        this.time = mTime;
        this.user = mUser;
        this.comment = mComment;
    }
}

// Manage Blockchain Transactions
export class TransactionList {
    constructor() {
        this.TxList = [];
    }
    addTransaction(t, mUser, mComment) {
        const transaction = new Transaction(
            this.TxList.length,
            t,
            mUser,
            mComment
        );
        this.TxList.push(transaction);
        return transaction;
    }
    getLength() {
        return this.TxList.length;
    }
}

export class Edge {
    constructor(mSourceNodeId, mTargetNodeId) {
        this.sourceNodeId = mSourceNodeId;
        this.targetNodeId = mTargetNodeId;
    }
    getSourceNodeId() {
        return this.sourceNodeId;
    }
    getTargetNodeId() {
        return this.targetNodeId;
    }
}

// Management of Edges
export class EdgeList {
    constructor() {
        this.EdgeList = [];
    }
    addEdge(mSourceNodeId, mTargetNodeId) {
        const edge = new Edge(mSourceNodeId, mTargetNodeId);
        this.EdgeList.push(edge);
        return edge;
    }
    getTargetEdgesForType(mNodeId) {
        let targetEdges = [];
        this.EdgeList.forEach((element) => {
            if (element.getSourceNodeId() === mNodeId)
                targetEdges.push(element);
        });
        return targetEdges;
    }
    getEdgesForNode(mNode, mReturnSource, mReturnTarget) {
        this.EdgeList.forEach((element) => {
            if (element.target.id === mNode.id) mReturnSource.push(element);
            if (element.source.id === mNode.id) mReturnTarget.push(element);
        });
    }
    getEdges() {
        var edgeArray = [];
        this.EdgeList.forEach((element) => {
            edgeArray.push({
                source: element.getSourceNodeId().toString(),
                target: element.getTargetNodeId().toString(),
            });
        });
        return edgeArray;
    }
    getLength() {
        return this.EdgeList.length;
    }
}

export class Node {
    constructor(
        mId,
        mTransaction,
        mSourceId,
        mLabel,
        mType,
        sId,
        wId,
        isFolderCollapsed
    ) {
        this.id = mId;
        this.transaction = mTransaction;
        this.sourceId = mSourceId;
        this.label = mLabel;
        this.type = mType;
        this.stringvalue = "";
        this.spaceId = sId;
        this.workspaceId = wId;
        this.isFolderCollapsed = isFolderCollapsed;
    }
    setValue(mValue) {
        this.stringvalue = mValue;
    }
    getId() {
        return this.id;
    }
    getLabel() {
        return this.label;
    }
    getSourceId() {
        return this.sourceId;
    }
    getType() {
        return this.type;
    }
    getSpaceId() {
        return this.spaceId;
    }
    getWorkspaceId() {
        return this.workspaceId;
    }
    getIsFolderCollapsed() {
        return this.isFolderCollapsed;
    }
}

// Management of Nodes
export class NodeList {
    constructor() {
        this.NodeList = {};
    }
    addNode(
        mId,
        mTransaction,
        mSourceId,
        mLabel,
        mType,
        sId,
        wId,
        isFolderCollapsed
    ) {
        const n = new Node(
            mId,
            mTransaction,
            mSourceId,
            mLabel,
            mType,
            sId,
            wId,
            isFolderCollapsed
        );
        this.NodeList[mId] = n;
        return n;
    }
    getNode(mId) {
        return this.NodeList[mId];
    }
    getSpaceId() {
        return this.NodeList;
    }
    getNodes() {
        var nodeArray = [];
        for (let key in this.NodeList) {
            nodeArray.push({
                id: key,
                label: this.NodeList[key].getLabel(),
                type: this.NodeList[key].getType(),
                spaceId: this.NodeList[key].getSpaceId(),
                workspaceId: this.NodeList[key].getWorkspaceId(),
                isFolderCollapsed: this.NodeList[key].getIsFolderCollapsed(),
            });
        }
        return nodeArray;
    }
    getLength() {
        return this.NodeList.length;
    }
    getSourceNodeAddress(mSourceNodeIds) {
        const sourceNodeAddress = [];
        mSourceNodeIds.forEach((element) => {
            let sourceAddress = this.getNode(element).getAddress();
            sourceNodeAddress.push(sourceAddress);
        });
        return sourceNodeAddress;
    }
    getAddress() {
        for (let i = this.NodeList.length - 1; i > 0; i--) {
            let sourcePointArray = [];
            sourcePointArray = this.NodeList[i].SourcePoints;
            let node1 = null;
            sourcePointArray.forEach((element) => {
                node1 = this.getNodeForPoint(element);
                if (node1 !== null) {
                    node1.addAddressToAddressList(this.NodeList[i]);
                }
            });
        }
        return this.NodeList;
    }
}

export class BWGraph {
    constructor() {
        this.txList = new TransactionList();
        this.nodeList = new NodeList();
        this.edgeList = new EdgeList();
    }
    getNode(mId) {
        return this.nodeList.getNode(mId);
    }
    createRoot(mId, mTransaction, mLabel, mType) {
        this.node0 = this.nodeList.addNode(
            mId,
            mTransaction,
            "-1",
            mLabel,
            mType
        );
        return this.node0.getId();
    }
    addNode(
        mId,
        mTransaction,
        mSourceId,
        mLabel,
        mType,
        sId,
        wId,
        isFolderCollapsed
    ) {
        var mTargetNode = this.nodeList.addNode(
            mId,
            mTransaction,
            mSourceId,
            mLabel,
            mType,
            sId,
            wId,
            isFolderCollapsed
        );
        mSourceId.forEach((element) => {
            this.edgeList.addEdge(
                this.nodeList.getNode(element).getId(),
                mTargetNode.getId()
            );
        });
        return mTargetNode;
    }
    getNodes() {
        return this.nodeList.getNodes();
    }
    getEdges() {
        return this.edgeList.getEdges();
    }
}
