import React from "react";
import Container from "@mui/material/Container";
import "./style.css";

const NoSpacePage = (props) => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    return (
        // <React.Fragment>
        <div className="h-100 w-100 doCenter addSpace">
            {/* <Container maxWidth="lg"> */}
            <div>
                <img
                    alt="space"
                    className="newSpace"
                    src="./images/space.png"
                ></img>
            </div>
            <div>
                <h2 className="workText" style={{ textAlign: "center" }}>
                    Add a new space
                </h2>

                <span className="subText">
                    A space is an area within your workspace where you can store
                    files and folders
                </span>
            </div>
            <div
                style={{ marginTop: "0.5rem" }}
                className={
                    isReadOnlyUser ||
                    props.currentWorkspace.workspaceType === "SYSTEM"
                        ? "addBtn readOnly"
                        : "addBtn cursorPointer"
                }
                onClick={
                    isReadOnlyUser ||
                    props.currentWorkspace.workspaceType === "SYSTEM"
                        ? null
                        : props.handleAddSpaceClick
                }
            >
                <img
                    src="./images/AddWorkspace.svg"
                    alt="add space"
                    className={
                        isReadOnlyUser ||
                        props.currentWorkspace.workspaceType === "SYSTEM"
                            ? "bw_plusButton plusIcon readOnly"
                            : "bw_plusButton plusIcon"
                    }
                    src="./images/plus.svg"
                    title={"Add Space"}
                />
                <span
                    id="title"
                    title="Add Space"
                    className={
                        isReadOnlyUser ||
                        props.currentWorkspace.workspaceType === "SYSTEM"
                            ? "addLabel newFontMontserrat readOnly"
                            : "addLabel newFontMontserrat"
                    }
                >
                    Add Space
                </span>
            </div>
            {/* </Container> */}
        </div>
        // </React.Fragment>
    );
};

export default NoSpacePage;
