import http from "./HTTP";
import config from "../config";

class CollaborationFetcher {
    constructor(nhId) {
        this.nhId = nhId;
        this.restURI = "neighborhood/" + this.nhId + "/collaboration";

        this.collabListData = "";
        // this.collabId='';
        this.idToIndex = {};
        this.nameToIndex = {};
    }

    getDataStructureByName = () => {
        this.collabListData.forEach((item, index) => {
            this.nameToIndex[item.collabName] = {
                index: index,
                whiteboards: this.appendWhiteboards(item.wbList, "name"),
            };
        });
    };
    getDataStructureById = () => {
        this.collabListData.forEach((item, index) => {
            this.idToIndex[item.collabId] = {
                index: index,
                whiteboards: this.appendWhiteboards(item.wbList, "id"),
            };
        });
    };
    appendWhiteboards = (wbList, type) => {
        let wbTree = {};

        if (wbList !== null && wbList !== undefined) {
            if (type === "name") {
                wbList.forEach((item, index) => {
                    wbTree[item.wbName] = {
                        index: index,
                        grids: this.appendGrids(item.gridList, "name"),
                    };
                });
            } else {
                wbList.forEach((item, index) => {
                    wbTree[item.wbId] = {
                        index: index,
                        grids: this.appendGrids(item.gridList, "id"),
                    };
                });
            }
        }
        return wbTree;
    };

    appendGrids = (gridList, type) => {
        let grids = {};
        if (gridList !== null && gridList !== undefined) {
            if (type === "name") {
                gridList.forEach((item, index) => {
                    grids[item.gridName] = {
                        index: index,
                    };
                });
            } else {
                gridList.forEach((item, index) => {
                    grids[item.gridId] = {
                        index: index,
                    };
                });
            }
        }
        return grids;
    };

    getCollabList() {
        let collabInfoRequested = new Promise((resolve, reject) => {
            http.sendRestRequest(this.restURI)
                .then((result) => {
                    //can do extra interpretations if needed
                    this.collabListData = result;
                    //this.getDataStructure();
                    resolve(this);
                })
                .catch((respError) => {
                    alert(
                        "Internal server error occurred, Please contact Boardwalk admin!"
                    );
                    sessionStorage.clear();
                    window.location.reload();
                });
        });
        return collabInfoRequested;
    }
    getCollabIdByName(collabName) {
        let collabId = -1;
        if (collabName !== null && collabName !== undefined) {
            this.collabListData.forEach((collabItem) => {
                if (collabItem.collabName === collabName) {
                    collabId = collabItem.collabId;
                }
            });
        }
        return collabId;
    }

    getCuboidIdByName(cbId, whiteBoardId, cuboidName) {
        // let retCuboidId = -1;
        let collabIndex = -1;
        let wbIndex = -1;
        let cuiboidIndex = -1;
        if (
            cbId !== null &&
            cbId !== undefined &&
            cbId !== -1 &&
            whiteBoardId !== null &&
            whiteBoardId !== undefined &&
            whiteBoardId !== -1 &&
            cuboidName !== null &&
            cuboidName !== undefined &&
            cuboidName !== -1
        ) {
            if (
                cbId in this.idToIndex &&
                whiteBoardId in this.idToIndex[cbId].whiteboards
            ) {
                collabIndex = this.idToIndex[cbId].index;
                wbIndex = this.idToIndex[cbId].whiteboards[whiteBoardId].index;
            }

            let collabName = this.getCollabNameByIndex(collabIndex);
            let wbName = this.getWhiteboardNameByIndex(collabIndex, wbIndex);

            if (
                cuboidName in
                this.nameToIndex[collabName].whiteboards[wbName].grids
            ) {
                cuiboidIndex =
                    this.nameToIndex[collabName].whiteboards[wbName].grids[
                        cuboidName
                    ].index;
            }

            let cuboidID = this.getCuboidIdByIndex(
                collabIndex,
                wbIndex,
                cuiboidIndex
            );
            return cuboidID;
        } else {
            return -1;
        }
        // if ((cbId !== null && cbId !== undefined) && (whiteBoardId !== null && whiteBoardId !== undefined) && (cuboidName !== null && cuboidName !== undefined)) {
        // 	this.collabListData.forEach((collabItem) => {
        // 		if (collabItem.collabId === cbId) {
        // 			collabItem.wbList.forEach((wbItem) => {
        // 				if (wbItem.wbId === whiteBoardId) {
        // 					wbItem.gridList.forEach((gridItem) => {
        // 						if (gridItem.gridName === cuboidName) {
        // 							retCuboidId = gridItem.gridId;
        // 						}
        // 					});
        // 				}
        // 			});
        // 		}

        // 	});
        // }
        // return retCuboidId;
    }

    getWBIdByName(cbId, whiteBoardName) {
        let retWBId = -1;
        let collabIndex = -1;
        let wbId = -1;
        let wbIndex = -1;
        if (
            cbId !== null &&
            cbId !== undefined &&
            cbId !== -1 &&
            whiteBoardName !== null &&
            whiteBoardName !== undefined &&
            whiteBoardName !== -1
        ) {
            if (cbId in this.idToIndex) {
                collabIndex = this.idToIndex[cbId].index;
            }
            if (collabIndex !== undefined && collabIndex !== -1) {
                let collabName = this.getCollabNameByIndex(collabIndex);
                if (
                    whiteBoardName in this.nameToIndex[collabName].whiteboards
                ) {
                    wbIndex =
                        this.nameToIndex[collabName].whiteboards[whiteBoardName]
                            .index;
                }
                wbId = this.getWhiteboardIdByIndex(collabIndex, wbIndex);
            }
            retWBId = wbId;
        }
        // let retWBId = -1;
        // if ((cbId !== null && cbId !== undefined) && (whiteBoardName !== null && whiteBoardName !== undefined)) {
        // 	this.collabListData.forEach((collabItem) => {
        // 		if (collabItem.collabId.toString() === cbId.toString()) {
        // 			collabItem.wbList.forEach((wbItem) => {
        // 				if (wbItem.wbName === whiteBoardName) {
        // 					retWBId = wbItem.wbId
        // 				}
        // 			});
        // 		}
        // 	});
        // }
        return retWBId;
    }
    getCollabNameByIndex = (selectedCollabIndex) => {
        if (parseInt(selectedCollabIndex) === -1) return "";
        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].collabName;
    };

    getCollabIdByIndex = (selectedCollabIndex) => {
        if (parseInt(selectedCollabIndex) === -1) return -1;
        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].collabId;
    };
    getWhiteboardIdByIndex = (selectedCollabIndex, selectedWBIndex) => {
        if (
            parseInt(selectedCollabIndex) === -1 ||
            parseInt(selectedWBIndex) === -1
        )
            return -1;

        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].wbList[selectedWBIndex].wbId;
    };
    getWhiteboardNameByIndex = (selectedCollabIndex, selectedWBIndex) => {
        if (
            parseInt(selectedCollabIndex) === -1 ||
            parseInt(selectedWBIndex) === -1
        )
            return "";
        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].wbList[selectedWBIndex].wbName;
    };

    getCuboidNameByIndex = (
        selectedCollabIndex,
        selectedWBIndex,
        selectedCuboidIndex
    ) => {
        if (
            parseInt(selectedCollabIndex) === -1 ||
            parseInt(selectedWBIndex) === -1 ||
            parseInt(selectedCuboidIndex) === -1
        )
            return "";
        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].wbList[selectedWBIndex].gridList[
            selectedCuboidIndex
        ].gridName;
    };

    getCuboidIdByIndex = (
        selectedCollabIndex,
        selectedWBIndex,
        selectedCuboidIndex
    ) => {
        if (
            parseInt(selectedCollabIndex) === -1 ||
            parseInt(selectedWBIndex) === -1 ||
            parseInt(selectedCuboidIndex) === -1
        )
            return -1;
        const collabInfo = this.collabListData;
        return collabInfo[selectedCollabIndex].wbList[selectedWBIndex].gridList[
            selectedCuboidIndex
        ].gridId;
    };

    getCollaborations = () => {
        let collabs = [];
        const collabInfo = this.collabListData;
        if (collabInfo !== null && collabInfo !== undefined) {
            collabInfo.forEach((collabItem) => {
                collabs.push(collabItem);
            });
        }
        return collabs;
    };
    getWhiteboardsByIndex = (selectedCollabIndex) => {
        let whiteboards = [];
        const collabInfo = this.collabListData;
        if (
            collabInfo !== null &&
            collabInfo !== undefined &&
            collabInfo[selectedCollabIndex].wbList !== undefined
        ) {
            collabInfo[selectedCollabIndex].wbList.forEach((wbItem) => {
                whiteboards.push(wbItem);
            });
        }
        return whiteboards;
    };
    getCuboidsByIndex = (selectedCollabIndex, selectedWBIndex) => {
        let cuboids = [];
        const collabInfo = this.collabListData;
        if (
            collabInfo !== null &&
            collabInfo !== undefined &&
            selectedCollabIndex !== -1 &&
            selectedWBIndex !== -1
        ) {
            let gridList =
                collabInfo[selectedCollabIndex].wbList[selectedWBIndex]
                    .gridList;
            if (gridList !== undefined) {
                gridList.forEach((gridItem) => {
                    cuboids.push(gridItem);
                });
            }
        }
        return cuboids;
    };
    getCollabAndWhiteboardAndCuboidNamesBYCuboidID = (cuboidID) => {
        let info = [];
        let collabName = "";
        let wbName = "";
        let cuboidName = "";
        if (cuboidID !== undefined && cuboidID !== null) {
            this.collabListData.forEach((collabItem) => {
                if (collabItem.wbList !== undefined) {
                    if (
                        collabItem.collabName.search("Shared") === -1 &&
                        collabItem.collabName.search("shared") === -1
                    ) {
                        collabItem.wbList.forEach((wbItem) => {
                            if (wbItem.gridList !== undefined) {
                                wbItem.gridList.forEach((gridItem) => {
                                    if (gridItem.gridId === cuboidID) {
                                        collabName = collabItem.collabName;
                                        wbName = wbItem.wbName;
                                        cuboidName = gridItem.gridName;

                                        if (collabName !== "") {
                                            info.push(collabName);
                                        }
                                        if (
                                            collabName !== "" &&
                                            wbName !== ""
                                        ) {
                                            info.push(wbName);
                                        }
                                        if (
                                            collabName !== "" &&
                                            wbName !== "" &&
                                            cuboidName !== ""
                                        ) {
                                            info.push(cuboidName);
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            });
        }

        return info;
    };
}
export default CollaborationFetcher;
