import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./EmailAlertModal.css";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@mui/material";
import { validateEmail } from "../../../../../../bae-client/Validations";
import { setSelectedExceptionDropdownValue } from "../../../../../../actions/DashboardActions";
const useStyles = makeStyles({
    root: {
        // display: "flex",
        // width: "100%",
        // padding: "7px 16px 7px 16px",
        // borderRadius: "4.32px !important",
        // // border: "1.08px solid #D7DEEA !important",
        // justifyContent: "space-between",
        // marginLeft: "0px !important",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "462px !important",
    },
    from: {
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
    to: {
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
});
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #D7DEEA",
    boxShadow: 24,
    padding: "24px",
    borderRadius: "4px",
};
const textStyles = {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.006em",
    textAlign: "left",
    color: "#000000",
    fontStyle: "normal",
};

export default function EmailAlertModal({
    emailModal,
    genericList,
    setEmailModal,
    alertTypeList,
    stepNameMap,
    toContactsMapData,
    fromContactsMapData,
    handleSubmit,
    editRowData,
    isRowEdit,
    setSelectedRowData,
    setIsRowEdit,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [emailInput, setEmailInput] = useState("");
    const [selectedGeneric, setSelectedGeneric] = useState({});
    const [selectedAlertTypes, setSelectedAlertTypes] = useState([]);
    const [selectedSteps, setSelectedSteps] = useState({});
    const [selectedFromContacts, setSelectedFromContacts] = useState({});
    const [selectedToContacts, setSelectedToContacts] = useState({});
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [stepNameList, setStepNameList] = useState([]);
    const [fromList, setfromList] = useState([]);
    const [toList, setToList] = useState([]);
    const [genericError, setGenericError] = useState("");
    const [alertTypeError, setAlertTypeError] = useState("");
    const updateAllListValues = (values) => {
        setStepNameList(stepNameMap.get(values.text));
        setfromList(fromContactsMapData.get(values.text));
        setToList(toContactsMapData.get(values.text));
    };
    const handleGeneric = (index, values) => {
        setSelectedGeneric(values);
        updateAllListValues(values);
        setGenericError("");
        setSelectedSteps({});
        setSelectedFromContacts({});
        setSelectedToContacts({});
    };
    const exceptionList = [
        {
            text: "DELAYED",
            value: "DELAYED",
            id: "DELAYED",
        },
        {
            text: "SKIPPED",
            value: "SKIPPED",
            id: "SKIPPED",
        },
        {
            text: "KEY MISSING",
            value: "KEY MISSING",
            id: "KEY MISSING",
        },
        {
            text: "KEY MISMATCH",
            value: "KEY MISMATCH",
            id: "KEY MISMATCH",
        },
        {
            text: "PROACTIVE ALERT",
            value: "PROACTIVE ALERT",
            id: "PROACTIVE ALERT",
        },
        {
            text: "DATE CHANGED",
            value: "DATE CHANGED",
            id: "DATE CHANGED",
        },
        {
            text: "OTHERS",
            value: "OTHERS",
            id: "OTHERS",
        },
    ];
    useEffect(() => {
        if (emailModal && editRowData) {
            setEmailInput(editRowData.userEmailAddress);
            setSelectedGeneric({ text: editRowData.genericName });
            updateAllListValues({ text: editRowData.genericName });
            let selectedAlertTypes = editRowData.alertTypes.map((type) => {
                return { text: type };
            });
            setSelectedAlertTypes(selectedAlertTypes);

            setSelectedSteps(
                editRowData.stepName === ""
                    ? "Step Name"
                    : { text: editRowData.stepName }
            );
            editRowData.from &&
                setSelectedFromContacts({ text: editRowData.from });
            editRowData.to && setSelectedToContacts({ text: editRowData.to });
        } else {
            setEmailInput("");
            setSelectedGeneric({});
            setSelectedAlertTypes([]);
            setSelectedSteps({});
            setSelectedFromContacts({});
            setSelectedToContacts({});
        }
    }, [emailModal]);

    const handleEmailInput = (e) => {
        let email = e.target.value.trim();
        if (email !== "") {
            let isValidEmail = validateEmail(email);

            if (isValidEmail === true) {
                setInvalidEmail("");
                setEmailInput(e.target.value);
            } else setInvalidEmail(isValidEmail);
        }
    };
    const handleAlertTypeSelection = (index, values) => {
        setSelectedAlertTypes(values);
        setAlertTypeError("");
    };
    const handleStepNames = (index, values) => {
        setSelectedSteps(values);
    };
    const closeModal = () => {
        setInvalidEmail("");
        setGenericError("");
        setAlertTypeError("");
        setEmailModal(false);
        setSelectedRowData(null);
        setIsRowEdit(false);
    };
    const submitHandler = () => {
        let errors = false;

        if (!emailInput.trim()) {
            setInvalidEmail("Please enter a valid email");
            errors = true;
        } else {
            setInvalidEmail("");
        }

        if (!selectedGeneric.text) {
            setGenericError("Please select a generic name");
            errors = true;
        } else {
            setGenericError("");
        }

        if (selectedAlertTypes.length === 0) {
            setAlertTypeError("Please select at least one alert type");
            errors = true;
        } else {
            setAlertTypeError("");
        }

        if (errors) {
            return; // Prevent submission if there are errors
        }

        closeModal();

        let payload = {
            emailAlertConfigId: isRowEdit ? editRowData.emailAlertConfigId : 0,
            userEmailAddress: emailInput,
            genericName: selectedGeneric.text,
            alertTypes: selectedAlertTypes.map((type) => type.text),
            stepName: selectedSteps.text,
            from: selectedFromContacts.text,
            to: selectedToContacts.text,
        };

        !invalidEmail && handleSubmit(payload, isRowEdit);
    };
    return (
        <div>
            <Modal
                style={{
                    background: "rgba(0, 0, 0, 0.5)",
                }}
                open={emailModal}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        className="row doCenter"
                        style={{ flexDirection: "column" }}
                    >
                        <div className="email-header">
                            <div className="head-text">
                                {isRowEdit
                                    ? "Edit Email Alert Configuration"
                                    : "Create Email Alert Configuration"}
                            </div>
                            <img
                                src="./images/close-btn.svg"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setEmailModal(false);
                                }}
                            />
                        </div>
                        <div className="email-inputs">
                            <div style={{ marginBottom: "8px" }}>
                                <div className="input-label">User Email </div>
                                <div
                                    className={classes.root}
                                    style={{
                                        height: "36px",
                                    }}
                                >
                                    {/* <div > */}
                                    {isRowEdit ? (
                                        <input
                                            type="text"
                                            placeholder="Enter Email"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                padding: "6px 12px",
                                                borderRadius: "4px",
                                                border: "1px solid #9DA5B4",
                                                boxShadow:
                                                    "1px 2px 2px 0px #0000001F inset",
                                            }}
                                            defaultValue={emailInput}
                                            onChange={handleEmailInput}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="Enter Email"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                padding: "6px 12px",
                                                borderRadius: "4px",
                                                border: "1px solid #9DA5B4",
                                                boxShadow:
                                                    "1px 2px 2px 0px #0000001F inset",
                                            }}
                                            onChange={handleEmailInput}
                                        />
                                    )}

                                    {invalidEmail && (
                                        <small
                                            style={{
                                                color: "red",
                                                marginBottom: "12px",
                                            }}
                                        >
                                            {invalidEmail}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: invalidEmail ? "28px" : "12px",
                                }}
                            >
                                <CustomSearchDropdown
                                    items={genericList}
                                    label={"Generic Name"}
                                    id={"btn_select_generic"}
                                    idInfo={"generic"}
                                    multiSelect={false}
                                    isSearchable={true}
                                    isChipView={false}
                                    handleSelection={handleGeneric}
                                    selection={selectedGeneric}
                                    customClassName={classes.root}
                                    textStyles={textStyles}
                                />
                                {genericError && (
                                    <small
                                        style={{
                                            color: "red",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {genericError}
                                    </small>
                                )}
                            </div>
                            <div>
                                <CustomSearchDropdown
                                    items={exceptionList}
                                    label={"Exception Type"}
                                    id={"btn_select_exception-type"}
                                    idInfo={"ExceptionType"}
                                    multiSelect={true}
                                    isSearchable={true}
                                    isChipView={false}
                                    handleSelection={handleAlertTypeSelection}
                                    selection={selectedAlertTypes}
                                    customClassName={classes.root}
                                    textStyles={textStyles}
                                />
                                {alertTypeError && (
                                    <small
                                        style={{
                                            color: "red",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {alertTypeError}
                                    </small>
                                )}
                            </div>
                            <div>
                                <CustomSearchDropdown
                                    items={stepNameList}
                                    label={"Step Name"}
                                    id={"btn_select_step-name"}
                                    idInfo={"StepName"}
                                    multiSelect={false}
                                    isSearchable={true}
                                    isChipView={false}
                                    handleSelection={handleStepNames}
                                    selection={selectedSteps}
                                    customClassName={classes.root}
                                    textStyles={textStyles}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <CustomSearchDropdown
                                    items={fromList}
                                    label={"From"}
                                    id={"btn_select_from"}
                                    idInfo={"from"}
                                    multiSelect={false}
                                    isSearchable={true}
                                    isChipView={false}
                                    handleSelection={(index, values) => {
                                        setSelectedFromContacts(values);
                                    }}
                                    selection={selectedFromContacts}
                                    customClassName={classes.from}
                                    textStyles={textStyles}
                                />
                                <CustomSearchDropdown
                                    items={toList}
                                    label={"To"}
                                    id={"btn_select_to"}
                                    idInfo={"to"}
                                    multiSelect={false}
                                    isSearchable={true}
                                    isChipView={false}
                                    handleSelection={(index, values) => {
                                        setSelectedToContacts(values);
                                    }}
                                    selection={selectedToContacts}
                                    customClassName={classes.to}
                                    textStyles={textStyles}
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "477px",
                                height: "52px",
                                padding: "16px 0px 0px 0px",
                                gap: "8px",
                            }}
                        >
                            <button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "none",
                                    width: "83px",
                                    // height: '36px',
                                    padding: "6px 16px 6px 16px",
                                    borderRadius: "4px",
                                    border: "1px solid #D7DEEA", // You might want to specify the border style, e.g., solid
                                    gap: "8px",
                                    background: "#FFFFFF",
                                    color: "#000000",
                                    textTransform: "capitalize",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                onClick={closeModal}
                                title="Cancel"
                            >
                                cancel
                            </button>
                            <button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "none",
                                    width: "83px",
                                    // height: '36px',
                                    padding: "6px 16px 6px 16px",
                                    borderRadius: "4px",
                                    gap: "8px",
                                    background: "#0B5CD7",
                                    textTransform: "capitalize",
                                    color: "#FFFFFF",
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    letterSpacing: "0.4000000059604645px",
                                    textAlign: "left",
                                }}
                                onClick={submitHandler}
                                title="Okay"
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
