import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarAction } from "../../../services/services/app";

export default function CustomizedSnackbar() {
    const { open, message, severity } = useSelector((state) => state.snackbar);
    const dispatch = useDispatch();
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(
            setSnackbarAction({ open: false, message: "", severity: "success" })
        );
    };

    if (!open) return null;
    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity || "success"}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
