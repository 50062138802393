import React, { useEffect, useState } from "react";
import XMLViewer from "react-xml-viewer";
import http from "../../../bae-client/HTTP";
export default function XmlFileViewer({ fileUrl, fileName }) {
    const [xmlFiledata, setXmlFiledata] = useState("Loding ....");
    const getXmlFileData = () => {
        http.getDocument(fileUrl, "GET").then((response) => {
            setXmlFiledata(response.data);
        });
    };
    useEffect(() => {
        getXmlFileData();
    }, []);
    if (xmlFiledata !== undefined) {
        return (
            <div className="xmlViewer docViewContainer" id="xmlViewerContainer">
                <XMLViewer
                    invalidXml={
                        <div className="mt-3">
                            <span>Invalid xml file.please</span>
                            <a className="mr-1 ml-1" href={fileUrl} download>
                                download
                            </a>
                            <span>and open in local text editor.</span>
                        </div>
                    }
                    className="xmlFileBody"
                    xml={xmlFiledata}
                    id="xmlViewer"
                ></XMLViewer>
            </div>
        );
    } else {
        return null;
    }
}
