import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import "./CustomDropdown.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function CustomArrowDropDownIcon({ customId }) {
    return (
        <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowDropDownIcon"
            id={
                customId !== undefined
                    ? `${customId}_ArrowDropDownIcon`
                    : "ArrowDropDownIcon"
            }
        >
            <path d="M7 10l5 5 5-5z"></path>
        </svg>
    );
}
export default function CustomDropdown({
    items,
    dropdownWidth,
    dropdownLeft,
    label,
    height,
    id,
    idInfo,
    multiSelect,
    isSearchable,
    handleSelection,
    customClassName,
    selection,
    textStyles,
    openAddInstanceModal,
    customWidth,
    showInstanceCreate = false,
}) {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: dropdownWidth || 250,
                marginLeft: dropdownLeft || 0,
            },
        },
    };
    const [personName, setPersonName] = React.useState([]);
    const [itemValues, setItemValues] = React.useState([]);
    const [originalItems, setOriginalItems] = React.useState([]);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "& .MuiInputBase-input": {
            border: "none",
        },
    }));
    React.useEffect(() => {
        if (items.length && typeof items[0] === "object") {
            let itemValues = items.map((item) => item.text);
            setItemValues(itemValues);
            setOriginalItems(itemValues);
        } else {
            setItemValues(items);
            setOriginalItems(items);
        }
    }, [items]);

    React.useEffect(() => {
        let selectedValues = [];

        if (selection) {
            if (Array.isArray(selection)) {
                selection.forEach((item) => {
                    selectedValues.push(item.text);
                });
            } else {
                selectedValues.push(selection.text);
            }
        }
        setPersonName(selectedValues);
        setSelectedItems(selection);
    }, [selection]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value !== undefined) {
            if (
                value.includes("Select All") &&
                !(value.indexOf("Select All") === items.length)
            ) {
                setSelectedItems(items);
                setPersonName(itemValues);
                if (multiSelect) {
                    handleSelection(0, items);
                } else {
                    handleSelection(0, items[0]);
                }
                return;
            } else {
                setSelectedItems([]);
                setPersonName([]);
                if (value.indexOf("Select All") === items.length) return;
            }
            setPersonName(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
            let selectedItem = [];
            if (multiSelect) {
                selectedItem = items.filter((item) =>
                    value.includes(item.text)
                );
                setSelectedItems(selectedItem);
                handleSelection(0, selectedItem);
            } else {
                selectedItem = items.filter((item) => value === item.text);
                handleSelection(0, selectedItem[0]);
            }
        }
    };

    const handleBlur = () => {
        handleSelection(0, selectedItems);
    };
    const searchMenuItem =
        id === "btn_select_instance1" ? (
            <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                onClick={openAddInstanceModal}
                disabled={!showInstanceCreate}
            >
                <div
                    className="cursorPointer"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                    }}
                >
                    <img
                        style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "12px",
                        }}
                        src="./images/Add Space.png"
                        className="cursorPointer"
                        id="icons"
                        title="Create a new instance"
                        alt=""
                    />
                    <span
                        id="create_new_instance_span"
                        title="Create a new instance"
                        style={{ marginTop: "6px" }}
                    >
                        Create New Instance
                    </span>
                </div>
            </MenuItem>
        ) : null;
    const getItems = () => {
        let uniqueItemValues = [...new Set(itemValues)];
        return uniqueItemValues.map((item, index) => {
            return (
                <MenuItem
                    key={item}
                    title={item}
                    value={item}
                    id={`input_${idInfo}_${index + 2}`}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    {multiSelect ? (
                        <Checkbox
                            id={`input_${idInfo}_${index + 2}`}
                            checked={personName.indexOf(item) > -1}
                        />
                    ) : null}
                    <ListItemText primary={item} />
                </MenuItem>
            );
        });
    };

    const handleSearch = (event) => {
        if (event.target.value) {
            setItemValues(
                originalItems.filter((item) =>
                    item
                        .toLowerCase()
                        .includes(event.target.value.toString().toLowerCase())
                )
            );
        } else {
            if (items.length && typeof items[0] === "object") {
                let itemValues = items.map((item) => item.text);
                setItemValues(itemValues);
            } else {
                setItemValues(items);
            }
        }
        if (event && event.target) setSearchText(event.target.value);
    };

    const getTitle = () => {
        if (personName.length) {
            return personName.join(", ");
        }
        return label;
    };
    return (
        <div
            style={
                height
                    ? { height: "50px", width: "100%", margin: "8px" }
                    : { width: "100%", margin: "8px" }
            }
        >
            <FormControl
                id={label === "More" ? "more_id" : ""}
                sx={{ width: "100%" }}
                size="small"
                className={customClassName}
                title={getTitle()}
            >
                {/* <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ width: "70%" }}
                >
                    {label}
                </InputLabel> */}
                <Select
                    labelId="demo-multiple-checkbox-label"
                    sx={
                        textStyles
                            ? {
                                  fontFamily: "Montserrat",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "22px",
                                  letterSpacing: "-0.006em",
                                  textAlign: "left",
                                  height: "36px",
                                  "& ul": {
                                      listStyleType: "none",
                                      padding: 0,
                                  },
                                  "& li": {
                                      padding: "8px 16px",
                                      cursor: "pointer",
                                  },
                              }
                            : id === "btn_select_from" ||
                              id === "btn_select_to" ||
                              id === "btn_select_promise_type" ||
                              id === "btn_select_exception" ||
                              id === "btn_select_status"
                            ? {
                                  width: customWidth
                                      ? customWidth + "px"
                                      : "260px",
                                  height: "36px",
                                  padding: "7px 16px",
                                  gap: "0px",
                                  borderRadius: "4px",
                                  border: "1.08px solid transparent",
                                  justifyContent: "space-between",
                              }
                            : {
                                  fontFamily: "Montserrat",
                                  height: "36px",
                                  "& ul": {
                                      listStyleType: "none",
                                      padding: "0px",
                                  },
                                  "& li": {
                                      padding: "8px 16px",
                                      cursor: "pointer",
                                  },
                              }
                    }
                    id={id}
                    multiple={multiSelect}
                    value={personName}
                    displayEmpty
                    onChange={handleChange}
                    onBlur={multiSelect ? handleBlur : null}
                    renderValue={(selected) => {
                        if (
                            selected.length === 0 ||
                            (selected.includes(undefined) &&
                                selected.length === 1)
                        )
                            return (
                                <>
                                    {label === "More" ? (
                                        <img
                                            src="./images/more1.svg"
                                            alt=""
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "9px",
                                            }}
                                        />
                                    ) : null}
                                    <em>{label}</em>
                                </>
                            );
                        return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    IconComponent={() => (
                        <CustomArrowDropDownIcon customId={id} />
                    )}
                    input={textStyles ? <BootstrapInput /> : null}
                >
                    {items.length && isSearchable ? (
                        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                            <TextField
                                size="small"
                                id="search"
                                placeholder="Search by Name"
                                onChange={handleSearch}
                                InputProps={{
                                    endAdornment: (
                                        <SearchIcon
                                            style={{
                                                marginLeft: "8px",
                                                color: "rgba(0, 0, 0, 0.54)",
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </MenuItem>
                    ) : null}
                    {items.length && multiSelect && searchText === "" ? (
                        <MenuItem
                            key={"Select All"}
                            value={"Select All"}
                            onKeyDown={(e) => e.stopPropagation()}
                        >
                            <Checkbox
                                id={`input_${idInfo}_1`}
                                checked={selectedItems.length === items.length}
                            />
                            <ListItemText primary={"Select All"} />
                        </MenuItem>
                    ) : null}
                    {itemValues.length === 0 && (
                        <ListItemText primary={"No Result Found"} />
                    )}
                    {searchMenuItem}
                    {getItems()}
                </Select>
            </FormControl>
        </div>
    );
}
