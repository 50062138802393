export const DashboardData = (dashboardData = null, action) => {
    if (action.type === "FETCH_DASHBOARD_DATA") {
        return action.payload;
    }
    return dashboardData;
};

export const DashboardDCFilterData = (filterData = null, action) => {
    if (action.type === "LOAD_FILTER_DATA") {
        return action.payload;
    }
    return filterData;
};

export const instanceDataByGenericId = (filterData = [], action) => {
    if (action.type === "LOAD_INSTANCE_DATA") {
        return action.payload;
    }
    return filterData;
};
export const InstanceData = (data = null, action) => {
    if (action.type === "OPEN_INSTANCE") {
        return action.payload;
    }
    return data;
};
export const existingGenericData = (data = null, action) => {
    if (action.type === "SHOW_EXISTING_GENERIC") {
        return action.payload;
    }
    return data;
};
export const dashboardAlerts = (data = [], action) => {
    if (action.type === "SET_DASHBOARD_ALERTS") {
        return action.payload;
    }
    return data;
};
export const genericIdToNameMap = (data = null, action) => {
    if (action.type === "SET_GENERIC_ID_TO_NAME_MAP") {
        return action.payload;
    }
    return data;
};
export const genericIdToDisplayNameMap = (data = null, action) => {
    if (action.type === "SET_GENERIC_ID_TO_DISPLAY_NAME_MAP") {
        return action.payload;
    }
    return data;
};
export const genericNameToIdMap = (data = null, action) => {
    if (action.type === "SET_GENERIC_NAME_TO_ID_MAP") {
        return action.payload;
    }
    return data;
};

export const selectedGenericDropdownValuesAggrReport = (data = [], action) => {
    if (action.type === "SET_SELECTED_GENERIC_DROPDOWN_VALUES_AGGR_REPORT") {
        return action.payload;
    }
    return data;
};

///////////////////////////////
export const selectedDropdownModelIndex = (data = null, action) => {
    if (action.type === "SET_SELECTED_MODEL_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedGenericDropdownValue = (data = null, action) => {
    if (action.type === "SET_SELECTED_GENERIC_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedInstanceModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_INSTANCE_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedInstanceDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_INSTANCE_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
export const selectedBranchDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_BRANCH_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
//newly added
export const selectedTimeRangeDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_TIME_RANGE_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedTransactionModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_TRANSACTION_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedTransactionDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedFromModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_FROM_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedFromDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_FROM_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedToModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_TO_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedToDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_TO_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedPromiceModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_PROMICE_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedPromiseDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_PROMISE_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedExceptionModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedExceptionDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedExceptionModelStatus = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_DROPDOWN_STATUS") {
        return action.payload;
    }
    return data;
};
//new
export const selectedExceptionStatusDropdownValue = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//
export const selectedTimeModelIndex = (data = [], action) => {
    if (action.type === "SET_SELECTED_TIME_DROPDOWN_INDEX") {
        return action.payload;
    }
    return data;
};
//new
export const selectedTimeDropdownValue = (data = {}, action) => {
    if (action.type === "SET_SELECTED_TIME_DROPDOWN_VALUE") {
        return action.payload;
    }
    return data;
};
//

export const searchDashboardText = (data = [], action) => {
    if (action.type === "SET_DASHBOARD_SEARCH_TEXT") {
        return action.payload;
    }
    return data;
};
export const dashboardAlertData = (data = [], action) => {
    if (action.type === "SET_DASHBOARD_ALERT_DATA") {
        return action.payload;
    }
    return data;
};

export const selectedGenericIndexesState = (data = [], action) => {
    if (action.type === "SET_SELECTED_GENERIC_INDEXES_STATE") {
        return action.payload;
    }
    return data;
};

export const aggregatedReportData = (data = [], action) => {
    if (action.type === "SET_AGGREGATED_REPORT_DATA") {
        return action.payload;
    }
    return data;
};

//Flow_Compare
export const selectedGenericDropdownValueFilterOne = (data = null, action) => {
    if (action.type === "SET_SELECTED_GENERIC_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedInstanceDropdownValueFilterOne = (data = [], action) => {
    if (action.type === "SET_SELECTED_INSTANCE_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedTransactionDropdownValueFilterOne = (
    data = [],
    action
) => {
    if (action.type === "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedPromiseDropdownValueFilterOne = (data = [], action) => {
    if (action.type === "SET_SELECTED_PROMISE_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedExceptionDropdownValueFilterOne = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedExceptionStatusDropdownValueFilterOne = (
    data = [],
    action
) => {
    if (
        action.type ===
        "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE_FILTER_ONE"
    ) {
        return action.payload;
    }
    return data;
};
export const selectedToDropdownValueFilterOne = (data = [], action) => {
    if (action.type === "SET_SELECTED_TO_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const dashboardSearchTextFilterOne = (data = [], action) => {
    if (action.type === "SET_DASHBOARD_SEARCH_TEXT_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedFromDropdownValueFilterOne = (data = [], action) => {
    if (action.type === "SET_SELECTED_FROM_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedTimeDropdownValueFilterOne = (data = {}, action) => {
    if (action.type === "SET_SELECTED_TIME_DROPDOWN_VALUE_FILTER_ONE") {
        return action.payload;
    }
    return data;
};
export const selectedGenericDropdownValueFilterTwo = (data = null, action) => {
    if (action.type === "SET_SELECTED_GENERIC_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedInstanceDropdownValueFilterTwo = (data = [], action) => {
    if (action.type === "SET_SELECTED_INSTANCE_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedTransactionDropdownValueFilterTwo = (
    data = [],
    action
) => {
    if (action.type === "SET_SELECTED_TRANSACTION_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedPromiseDropdownValueFilterTwo = (data = [], action) => {
    if (action.type === "SET_SELECTED_PROMISE_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedExceptionDropdownValueFilterTwo = (data = [], action) => {
    if (action.type === "SET_SELECTED_EXCEPTION_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedExceptionStatusDropdownValueFilterTwo = (
    data = [],
    action
) => {
    if (
        action.type ===
        "SET_SELECTED_EXCEPTION_STATUS_DROPDOWN_VALUE_FILTER_TWO"
    ) {
        return action.payload;
    }
    return data;
};
export const selectedToDropdownValueFilterTwo = (data = [], action) => {
    if (action.type === "SET_SELECTED_TO_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const dashboardSearchTextFilterTwo = (data = [], action) => {
    if (action.type === "SET_DASHBOARD_SEARCH_TEXT_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedFromDropdownValueFilterTwo = (data = [], action) => {
    if (action.type === "SET_SELECTED_FROM_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
export const selectedTimeDropdownValueFilterTwo = (data = {}, action) => {
    if (action.type === "SET_SELECTED_TIME_DROPDOWN_VALUE_FILTER_TWO") {
        return action.payload;
    }
    return data;
};
