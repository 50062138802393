import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import "react-datepicker/dist/react-datepicker.css";
import { Stack, TextField } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const MuiDateTimePicker = ({selectedOption, selectedDateTime, setSelectedDateTime, selectedOptionq, defaultChecked}) => {
    //const [selectedDate, setSelectedDate] = useState(new Date());
    const minSelectableDate = new Date();

    function convertToMinutes(dateString) {
        var dateTime = new Date(dateString);
        var hour = dateTime.getHours();
        var minute = dateTime.getMinutes();
        var totalMinutes = hour * 60 + minute;
        return totalMinutes;
    }
    // var input = selectedDate;
    // var minutes = convertToMinutes(input);
    const handleDateTimeSelection = (selectedDate) => {
        //setSelectedDate(selectedDate);
        setSelectedDateTime(selectedDate);
    }

    return (
        // <LocalizationProvider
        //     dateAdapter={AdapterDayjs}
        // >
        //     <DemoContainer components={['DateTimePicker']}>
        //         <DateTimePicker
        //             minDate={minSelectableDate}
        //             disabled={selectedOptionValue}
        //             label="Select Date/Time"
        //             value={selectedDate}
        //             onChange={(newValue) => 
        //                 setSelectedDate(newValue)
        //             }
        //             TrapFocusProps={{ disableEnforceFocus: true }}
        //         />
        //     </DemoContainer>
        // </LocalizationProvider>
        <div>
            <DateTimePicker 
                minDate={minSelectableDate}
                onChange={(newVal) => handleDateTimeSelection(newVal)} 
                value={selectedDateTime} 
                disabled={selectedOption}
            />
        </div>
    );
};

export default MuiDateTimePicker;
