import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginContent } from "../../../actions";
import Billing from "./Billing";
import SignUp from "./SignUp";

class Plans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            curruntPage: "",
            activeButtonIndex: 2,
        };
    }

    setLoginContent = (content, btnIndex) => {
        if (this.props.activePage !== "Settings") {
            this.setState({
                curruntPage: content,
                activeButtonIndex: btnIndex,
            });
        }
    };

    renderMiddleText = () => {
        return (
            <div>
                <p className="plansDesc">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem
                </p>
            </div>
        );
    };
    renderPersonal = () => {
        return (
            <div className="signUpBorder mt-3">
                <div className="signUpTitle row w-100 m-0 pl-2">Personal</div>
                <div className="doCenter plansPricing">
                    <span className="signUpPricing">Free $0</span>
                </div>
                {this.renderMiddleText()}
                <div className="doCenter">
                    <ul
                        className="signUpList"
                        style={{
                            listStyleImage: "url(./images/grayBullet.png)",
                        }}
                    >
                        <li className="plansListItem pl-2">3 Workspaces</li>
                        <li className="plansListItem pl-2">6 Invites</li>
                        <li className="plansListItem pl-2">30 GB of Storage</li>
                    </ul>
                </div>
                <div className="doCenter">
                    <input
                        id="btn_plans_personal"
                        type="button"
                        className={
                            this.state.activeButtonIndex === 1
                                ? "btn btn-primary newFontMontserrat signUpButton active"
                                : "btn btn-primary newFontMontserrat signUpButton"
                        }
                        value="GET STARTED"
                        onClick={() => this.setLoginContent("signUp", 1)}
                    ></input>
                </div>
            </div>
        );
    };
    renderProfessional = () => {
        return (
            <div className="signUpBorder mt-3">
                <div className="signUpTitle row w-100 m-0 pl-2">
                    Professional
                </div>
                <div className="doCenter plansPricing">
                    <span className="signUpPricing">$29.99</span>
                </div>
                {this.renderMiddleText()}
                <div className="doCenter">
                    <ul
                        className="signUpList"
                        style={{
                            listStyleImage: "url(./images/grayBullet.png)",
                        }}
                    >
                        <li className="plansListItem pl-2">10 Workspaces</li>
                        <li className="plansListItem pl-2">6 Invites</li>
                        <li className="plansListItem pl-2">30 GB of Storage</li>
                    </ul>
                </div>
                <div className="doCenter">
                    <input
                        id="btn_plans_Professional"
                        type="button"
                        className={
                            this.state.activeButtonIndex === 2
                                ? "btn btn-primary newFontMontserrat signUpButton active"
                                : "btn btn-primary newFontMontserrat signUpButton"
                        }
                        value="GET STARTED"
                        onClick={() => this.setLoginContent("billing", 2)}
                    ></input>
                </div>
            </div>
        );
    };
    renderBusiness = () => {
        return (
            <div className="signUpBorder mt-3">
                <div
                    className="row w-100 m-0 pl-2"
                    style={{ alignItems: "center" }}
                >
                    <div className="col-10">
                        <span className="signUpTitle">Business</span>
                    </div>
                    <div className="col-2">
                        <img
                            src="./images/VectorSignup.png"
                            style={{ marginRight: "20px" }}
                        ></img>
                    </div>
                </div>
                <div className="doCenter plansPricing">
                    <span className="signUpPricing">Contact Us</span>
                </div>
                {this.renderMiddleText()}
                <div className="doCenter">
                    <ul
                        className="signUpList"
                        style={{
                            listStyleImage: "url(./images/grayBullet.png)",
                        }}
                    >
                        <li className="plansListItem pl-2">
                            Unlimited Workspaces
                        </li>
                        <li className="plansListItem pl-2">
                            Unlimited Invites
                        </li>
                        <li className="plansListItem pl-2">
                            Unlimited Storage
                        </li>
                    </ul>
                </div>
                <div className="doCenter">
                    <input
                        id="btn_plans_Business"
                        type="button"
                        className="btn btn-primary newFontMontserrat  signUpButton"
                        value="CONTACT SALES"
                        // onClick={() => this.setLoginContent("billing")}
                    ></input>
                </div>
            </div>
        );
    };
    render() {
        if (this.state.curruntPage === "signUp") {
            return <SignUp></SignUp>;
        } else if (this.state.curruntPage === "billing") {
            return <Billing></Billing>;
        } else {
            return (
                <div className="row doCenter m-0 w-100">
                    <div className="col-12 col-md-3 col-sm-6">
                        {this.renderPersonal()}
                    </div>
                    <div className="col-12 col-md-3 col-sm-6">
                        {this.renderProfessional()}
                    </div>
                    <div className="col-12 col-md-3 col-sm-6">
                        {this.renderBusiness()}
                    </div>
                </div>
            );
        }
    }
}
const mapDispatchToProps = () => {
    return {
        setLoginContent,
    };
};

const mapStateToProps = (state) => {
    return {
        loginContent: state.loginContent,
        activePage: state.activePage,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(Plans);
