import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

function ConfirmationBoxView({ title, body, open, handleOk, handleCancle }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleOkBtnClick = () => {
        handleOk();
    };
    const handleCancelBtnClick = () => {
        handleCancle();
    };
    return (
        <Dialog fullWidth={fullScreen} open={open}>
            <div className="row m-0 w-100">
                <div className="col-11">
                    <DialogTitle
                        id="responsive-dialog-title"
                        style={{ paddingLeft: "9px !important" }}
                    >
                        {title}
                    </DialogTitle>
                </div>
                <div className="col-1 p-0 doCenter">
                    <button
                        style={{ marginRight: "1rem", paddingTop: "0.5rem" }}
                        type="button"
                        onClick={handleCancelBtnClick}
                        className="close modalCloseIcon"
                        data-dismiss="modal"
                        id={"closeModalButton"}
                    >
                        &times;
                    </button>
                </div>
            </div>{" "}
            <DialogContent dividers={false}>{body}</DialogContent>
            <DialogActions>
                <Button
                    style={{
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        border: " 1px solid #000000",
                        marginBottom: "0.8rem",
                        width: "5rem",
                    }}
                    onClick={handleCancelBtnClick}
                >
                    Cancel
                </Button>
                <Button
                    style={{
                        background: "#0798EA",
                        borderRadius: "10px",
                        color: "white",
                        marginBottom: "0.8rem",
                        marginRight: "0.8rem",
                        width: "5rem",
                    }}
                    variant="contained"
                    onClick={handleOkBtnClick}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationBoxView;
