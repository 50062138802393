import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function MenuDropDown({
    id,
    items,
    onclose,
    anchorEl,
    invokerHTML,
    onchange,
}) {
    const handleListItemClick = (ext, itemIndex) => {
        if (ext !== undefined && ext !== null) {
            onchange(ext, itemIndex);
        }
    };

    return (
        <div>
            {/* <Button onClick={invokerHTML}></Button> */}
            <Menu
                id={id || "demo-positioned-menu"}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onclose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                style={{ marginTop: "-15px" }}
            >
                {Object.keys(items).map((item, index) => {
                    let insideItem = items[item];
                    return (
                        <MenuItem
                            title={insideItem.value}
                            onClick={() =>
                                handleListItemClick(insideItem.ext, index)
                            }
                        >
                            {insideItem.value}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
