import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
    validateName,
    validateStepperName,
} from "../../../../../../bae-client/Validations";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";
import "./style.css";
import Checkbox from "../../../../../stylized/Checkbox";
import Switch from "@mui/material/Switch";

export default function AddBranch({
    showBranch,
    steps,
    setBranchShow,
    closeModal,
    btnName,
    addBranch,
    title,
    instanceSteps,
    instanceDetails,
}) {
    const [branchId, setBranchId] = React.useState("");
    const [branchName, setBranchName] = React.useState("");
    const [checkedOne, setCheckedOne] = React.useState(true);
    const [errMessage, setErrMessage] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [branchIdChanged, setBranchIdChanged] = React.useState(false);
    useEffect(() => {
        setBranchId("");
        setBranchName("");
        setErrMessage("");
        setErrorMessage("");
    }, [showBranch]);
    const onBranchChange = (value) => {
        if (value !== "") {
            setBranchIdChanged(true);
        } else {
            setBranchIdChanged(false);
        }
        if (errMessage) setErrMessage("");
        if (value.length === 1 && value === " ") setBranchId(value.trim());
        else setBranchId(value);
    };

    const onBranchNameChange = (value) => {
        if (errorMessage) setErrorMessage("");
        if (/[^a-zA-Z0-9\s]+/.test(value)) {
            setErrorMessage("Special characters are not allowed in the name.");
        } else if (value.length === 1 && value === " ") {
            setBranchName(value.trim());
            if (
                !branchIdChanged &&
                (branchId === "" || branchId === branchName)
            )
                setBranchId(value);
        } else {
            setBranchName(value);
            if (
                !branchIdChanged &&
                (branchId === "" || branchId === branchName)
            )
                setBranchId(value);
        }
    };
    const updateOne = () => {
        setCheckedOne(!checkedOne);
    };
    const handleSubmit = () => {
        const isValidName = validateName(branchName);
        const isValidBranchId = validateName(branchId);
        const branchIds = steps.map((branchId) => branchId.branchId);
        setBranchIdChanged(false);
        if (branchId === "") {
            setErrMessage("This field should not be empty");
        } else if (branchId.includes(".") || branchName.includes(".")) {
            if (branchId.includes(".")) setErrMessage("Decimals Not Allowed");
            if (branchName.includes("."))
                setErrorMessage("Decimals Not Allowed");
        } else if (branchId === "" && branchName.length > 0) {
            setErrMessage("This field should not be empty");
        }
        // else if (parseInt(branchId) > 2147483646) {
        //     setErrMessage("Number is exceeding the Integer limit");
        // }
        else if (branchIds.includes(branchId)) {
            setErrMessage("Branch Id Already Exist");
        } else {
            if (isValidName === true) {
                const branchNames = steps.map((branchName) =>
                    branchName.branchName.toUpperCase().trim()
                );
                if (branchNames.includes(branchName.trim().toUpperCase()))
                    setErrorMessage("Branch Name Already Exist");
                else {
                    if (isValidBranchId !== true) {
                        setErrMessage(isValidBranchId);
                    } else {
                        const skippedStep = instanceSteps.find(
                            (step) =>
                                step.instanceStepName ===
                                    instanceDetails.instanceStepName &&
                                step.branchId === instanceDetails.branchId &&
                                step.durationStatus === "SKIPPED"
                        );
                        if (skippedStep)
                            addBranch(
                                branchId,
                                branchName,
                                skippedStep.instanceId,
                                skippedStep.instanceStepId,
                                setBranchShow,
                                checkedOne
                            );
                        else
                            addBranch(
                                branchId,
                                branchName,
                                instanceDetails.instanceId,
                                instanceDetails.instanceStepId,
                                setBranchShow,
                                checkedOne
                            );
                        setBranchId("");
                        setBranchName("");
                    }
                }
            } else {
                setErrorMessage(isValidName);
            }
        }
    };
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={showBranch}
            size="md"
            onHide={closeModal}
            title="Okay"
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            value="Name"
            onclick={closeModal}
            type="submit-cancel"
            submitButtonText="Close"
        >
            <div
                className="row doCenter"
                style={{
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        lineHeight: "27px",
                        color: "#404040",
                        width: "430px",
                        marginTop: "-32px",
                        marginBottom: "28px",
                        marginRight: "-9px",
                    }}
                >
                    Branch
                </div>
                {/* <img src="./images/Space 2.svg"></img> */}
                <TextBox
                    title="Branch Name"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    label="Branch Name"
                    onchange={onBranchNameChange}
                    style={{ marginTop: "-30px" }}
                    value={branchName}
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {errorMessage && (
                <>
                    <small style={{ color: "red", paddingLeft: "18px" }}>
                        {errorMessage}
                    </small>
                    <br />
                </>
            )}
            <div
                className="row doCenter"
                style={{ flexDirection: "column", marginTop: "20px" }}
            >
                {/* <img src="./images/Space 2.svg"></img> */}

                <TextBox
                    title="Branch Id"
                    id="newFolderName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                    type="text"
                    label="Branch Id"
                    style={{ marginTop: "-20px" }}
                    value={branchId}
                    onchange={onBranchChange}
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {errMessage && (
                <>
                    <small style={{ color: "red", paddingLeft: "18px" }}>
                        {errMessage}
                    </small>
                    <br />
                </>
            )}
            <div
                className="row doCenter"
                style={{
                    flexDirection: "row",
                    marginTop: "10px",
                    marginRight: "240px",
                }}
            >
                <input
                    type="checkbox"
                    checked={checkedOne}
                    id="weekday-mon"
                    className="weekday"
                    onChange={updateOne}
                />
                <label
                    className="ctLabel"
                    style={{
                        marginTop: "0.5rem",
                        width: "130px",
                        fontSize: "14px",
                        marginLeft: "3px",
                    }}
                >
                    Create Promise Blocks
                </label>
            </div>
        </ModalView>
    );
}
