import React, { Component } from "react";

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.checked = this.props.checked;
    }

    handleClick = (event) => {
        this.props.onclick(
            event.currentTarget.value,
            event.currentTarget.id,
            event
        );
    };
    render() {
        return (
            <div className="doCenter">
                <input
                    type="checkbox"
                    id={this.props.id}
                    onClick={(event) => this.handleClick(event)}
                    checked={this.props.checked ? this.props.checked : false}
                />
            </div>
        );
    }
}

export default Checkbox;
