import React from "react";

class SelectBox extends React.Component {
    constructor(props) {
        super(props);
        if (
            (this.props.label !== undefined && this.props.id === undefined) ||
            this.props.id === null
        )
            this.id = this.props.label.replace(/\s+/, "_");
        else this.id = this.props.id;
    }

    getText(value) {
        const items = this.props.items;
        for (let i = 0; i < items.length; i++) {
            if (value.toString() === items[i].value.toString())
                return items[i].text;
        }
    }
    getIndex(value) {
        const items = this.props.items;
        for (let i = 0; i < items.length; i++) {
            if (value.toString() === items[i].value.toString()) return i;
        }
        return -1;
    }

    handleChange = (event) => {
        if (this.props.onchange !== undefined && this.props.onchange !== null)
            this.props.onchange(
                this.getIndex(event.currentTarget.value),
                event.currentTarget.value,
                this.getText(event.currentTarget.value),
                event
            );
    };

    renderOptions() {
        const items = this.props.items;
        let optionElements = [];

        if (
            this.props.showEmptyText !== undefined &&
            this.props.showEmptyText !== null
        ) {
            let val = "";
            if (
                this.props.setEmptyValue !== undefined &&
                this.props.setEmptyValue !== null
            ) {
                val = this.props.setEmptyValue;
            }
            optionElements.push(
                <option value={val}>{this.props.showEmptyText}</option>
            );
        }
        for (let i = 0; i < items.length; i++) {
            optionElements.push(
                <option
                    value={items[i].value}
                    {...(this.props.selectedIndex !== undefined &&
                    this.props.selectedIndex !== null &&
                    parseInt(this.props.selectedIndex) === i
                        ? { selected: true }
                        : null)}
                >
                    {items[i].text}
                </option>
            );
        }
        return optionElements;
    }
    renderLabel = () => {
        if (this.props.label !== undefined) {
            return (
                <label className="m-0 mr-2" for={this.id}>
                    {this.props.label}
                </label>
            );
        } else {
            return null;
        }
    };
    renderClassname = () => {
        if (this.props.className !== undefined) {
            return this.props.className;
        } else {
            return "";
        }
    };
    renderClassnameForSelectBox = () => {
        if (this.props.classForSelectBox !== undefined) {
            return this.props.classForSelectBox;
        } else {
            return "";
        }
    };
    render() {
        return (
            <div className={"form-group " + this.renderClassname()}>
                {this.renderLabel()}
                <select
                    {...(this.props.disabled !== undefined &&
                    this.props.disabled !== null
                        ? { disabled: this.props.disabled }
                        : null)}
                    className={
                        "form-control " + this.renderClassnameForSelectBox()
                    }
                    id={this.id}
                    onChange={this.handleChange}
                >
                    {this.renderOptions()}
                </select>
            </div>
        );
    }
}

export default SelectBox;
