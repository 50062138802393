import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Tabs, Tab } from "react-bootstrap";
import NodeDetails from "./Details";

const columns = [
    { id: "name", label: "Document Name", align: "left" },
    { id: "receiver", label: "Receiver", align: "left" },
    { id: "date", label: "Date", align: "left" },
];

const useStyles = makeStyles({
    root: {
        width: "auto",
        borderRadius: "8px",
        marginLeft: "0px",
        marginTop: "-10px",
    },
    container: {
        maxWidth: 420,
        overflow: "hidden",
        paddingTop: "0",
    },
    table: {
        width: "100%",
        display: "table",
        border: "1px solid #A5A5A5",
        borderRadius: "8px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    cell: {
        height: "auto",
        width: "25%",
        fontSize: "8px",
        padding: "8px",
        marginBottom: "4px",
        borderBottom: "0px",
    },
});

const defaultDocDetails = () => {
    const docDetails = [];
    for (let i = 0; i < 5; i++) {
        docDetails.push({
            name: "",
            receiver: "",
            date: "",
        });
    }
    return docDetails;
};

export default function FileList({
    genericId,
    instanceId,
    docDetails,
    exceptionDetails,
    handleFileListView,
    isFileListCollapsed,
    refreshExceptionDetails,
    renderDocumentDisplayModal,
}) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const getWidthOfColumn = (colNumber) => {
        if (colNumber === 0) return "45%";
        else if (colNumber === 1) return "30%";
        else return "25%";
    };

    const handleDocClick = (documentId) => {
        renderDocumentDisplayModal(documentId);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getFileList = () => {
        return (
            <Paper
                className={classes.root}
                style={{ height: "100%", width: "100%" }}
            >
                <TableContainer className={classes.container}>
                    <Table
                        classes={{ root: classes.table }}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow style={{ display: "flex" }}>
                                {columns.map((column, i) => (
                                    <TableCell
                                        classes={{
                                            root: classes.cell,
                                        }}
                                        key={column.id}
                                        size={"small"}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            borderRight:
                                                i === columns.length - 1
                                                    ? null
                                                    : "1px solid #B9B9B9",
                                            width: getWidthOfColumn(i),
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            lineHeight: "16px",
                                            height: "56px !important",
                                            whiteSpaces: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {docDetails
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{
                                                display: "flex",
                                                marginTop:
                                                    index === 0 && "-5px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {columns.map((column, i) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        onClick={(e) =>
                                                            i === 0
                                                                ? handleDocClick(
                                                                      row["id"]
                                                                  )
                                                                : null
                                                        }
                                                        key={column.id}
                                                        align={column.align}
                                                        size={"small"}
                                                        style={{
                                                            borderRight:
                                                                i ===
                                                                columns.length -
                                                                    1
                                                                    ? null
                                                                    : "1px solid #B9B9B9",
                                                            borderBottom:
                                                                "1px solid #B9B9B9",
                                                            width: getWidthOfColumn(
                                                                i
                                                            ),
                                                            fontSize: "10px",
                                                            lineHeight: "16px",
                                                            minHeight: "24px",
                                                            padding:
                                                                "4px 4px 4px 8px",
                                                            whiteSpaces:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    };

    return (
        <div
            style={{
                height: "100%",
                maxWidth: "100%",
                margin: "5px",
                marginLeft: "0px",
                padding: "5px",
                display: "flex",
            }}
        >
            <div
                style={{
                    justifyContent: "end",
                    paddingRight: "2px",
                }}
            >
                <span
                    className="fileDetailsButton"
                    onClick={() => handleFileListView()}
                >
                    <i
                        className={
                            isFileListCollapsed
                                ? "fas fa-angle-double-left"
                                : "fas fa-angle-double-right"
                        }
                    ></i>
                </span>
            </div>
            {!isFileListCollapsed && genericId && instanceId && (
                <div
                    className="node-details"
                    style={{
                        width: "100%",
                    }}
                >
                    <Tabs
                        defaultActiveKey="details"
                        id="uncontrolled-tab-example"
                    >
                        <Tab eventKey="details" title="Details">
                            <NodeDetails exceptionDetails={exceptionDetails} />
                        </Tab>
                        <Tab
                            eventKey="files"
                            title="Files"
                            style={{ height: "100%" }}
                        >
                            {getFileList()}
                        </Tab>
                    </Tabs>
                </div>
            )}
            {!isFileListCollapsed && genericId && instanceId && (
                <div
                    onClick={() => {
                        refreshExceptionDetails(exceptionDetails);
                    }}
                    style={{
                        height: "30px",
                        width: "30px",
                        padding: "8px",
                        cursor: "pointer",
                    }}
                >
                    <i className="fas fa-sync-alt"></i>
                </div>
            )}
        </div>
    );
}
