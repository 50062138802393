import WebGridInterfaceClass from "../bae-client/WebGridInterfaceClass";
export const fetchLinkImportData = (cuboidId) => {
    return async (dispatch) => {
        const _wgi = new WebGridInterfaceClass();
        const response = await _wgi._linkImportCuboid(cuboidId);
        dispatch({
            type: "LINK_IMPORT",
            payload: response,
        });
    };
};

export const fetchRefreshData = (cuboidId, txId) => {
    return async (dispatch) => {
        const _wgi = new WebGridInterfaceClass();
        const response = await _wgi._refreshCuboid(cuboidId, txId);
        dispatch({
            type: "REFRESH",
            payload: response,
        });
    };
};
export const saveSubmitData = (cuboidId, bufferData) => {
    return async (dispatch) => {
        const _wgi = new WebGridInterfaceClass();
        const response = await _wgi._submitCuboid(cuboidId, bufferData);
        dispatch({
            type: "SUBMIT",
            payload: response,
        });
    };
};
