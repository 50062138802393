import http from "./HTTP";

class WebGridInterfaceClass {
    _linkImportCuboid = (cuboidId) => {
        return new Promise((resolve, reject) => {
            let url = `grid/${cuboidId}?importTxId=-1&view=LATEST&mode=0&baselineId=-1`;
            http.sendRestRequest(url)
                .then((response) => {
                    let rows = [];
                    if (response.rowChainArray) {
                        rows = response.rowChainArray.map((row) => {
                            return {
                                ...row,
                                cells: response.gridChangeBuffer.cellChanges.filter(
                                    (cell) => row.rowId === cell.rowId
                                ),
                            };
                        });
                    }
                    resolve({ ...response, rowChainArray: rows });
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    _refreshCuboid = (cuboidId, importTxId) => {
        return new Promise((resolve, reject) => {
            let url = `grid/${cuboidId}?importTxId=${importTxId}&view=LATEST&mode=0&baselineId=-1`;
            http.sendRestRequest(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
    _submitCuboid = (cuboidId, bufferData) => {
        return new Promise((resolve, reject) => {
            //https://<Boardwalk_App_URL>/rest/<v#>/grid?gridId=<gridid>
            let url = `grid?gridId=${cuboidId}`;
            http.sendRestRequest(url, "PUT", bufferData)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };
}

export default WebGridInterfaceClass;
