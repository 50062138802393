import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalView from "../../../../../stylized/ModalView";
import { Avatar, Divider, Menu, MenuItem, Switch } from "@mui/material";
import TextBox from "../../../../../stylized/TextBox";
import { useNavigate } from "react-router-dom";
import Automembers from "./Automembers";
import "./style.css";
import { setActivePage } from "../../../../../../actions";
import http from "../../../../../../bae-client/HTTP";
import { setSharedData } from "../../../../../../actions/CommunicatorActions";
import Loader from "../../../../../stylized/Loader";
import {
    setActivePageAction,
    setSnackbarAction,
} from "../../../../../../services/services/app";
import CustomDropdown from "../../../../../stylized/CustomDropdown";
import { ButtonComponent } from "../../../../../button/button";

const Members = ({
    setMembersList,
    showMembersModal,
    onHide,
    heading,
    id,
    activeSpace,
    activeFolder,
    allUsers,
    setAllUsers,
    membersList,
    currentMembers,
    setCurrentMembers,
    isLoader,
}) => {
    const navigate = useNavigate();
    const directoryUsers = useSelector((state) => state.directoryUsers);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const workspaceList = useSelector((state) => state.workspaceList);
    const loggedInUserId = parseInt(sessionStorage.getItem("userId"));
    const [ownerId, setOwnerId] = useState(null);
    const [reload, setReload] = useState(false);
    const membersRefreshAction = useSelector(
        (state) => state.MembersRefreshAction
    );
    const dispatch = useDispatch();

    const [usersToShow, setUsersToShow] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeWorkspaceMembers, setActiveWorkspaceMembers] = useState([]);
    const [usersToAdd, setUsersToAdd] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const open = Boolean(anchorEl);
    const hideModal = () => {
        onHide(false);
    };
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (directoryUsers) {
            const existingUsers = activeWorkspaceMembers.map((user) =>
                user.userEmail.toLowerCase()
            );
            const allUsersArray =
                allUsers &&
                allUsers.map((user) => user.userEmail.toLowerCase());
            setUsersToShow(
                directoryUsers.filter(
                    (user) =>
                        !existingUsers.includes(
                            user.emailAddress.toLowerCase()
                        ) &&
                        allUsersArray.includes(
                            user.emailAddress.toLowerCase()
                        ) &&
                        user.emailAddress.toLowerCase() !==
                            sessionStorage.getItem("user").toLowerCase()
                )
            );
        }
    }, [directoryUsers, activeWorkspaceMembers]);
    useEffect(() => {
        if (heading) {
            isLoader ? setShowLoader(false) : setShowLoader(true);
            http.sendRestRequest(`share/users/flow?genericId=${id}`)
                .then((response) => {
                    setShowLoader(false);
                    const sharedUsers = response.map(
                        (user) => user.sharedUserId
                    );
                    setCurrentMembers(response);

                    setActiveWorkspaceMembers(
                        allUsers.filter((user) =>
                            sharedUsers.includes(user.userId)
                        )
                    );

                    setMembersList(
                        allUsers.filter((user) =>
                            sharedUsers.includes(user.userId)
                        )
                    );
                    setCurrentMembers(response);
                })
                .catch((erro) => setShowLoader(false));
        }
        if (allUsers?.length === 0) {
            http.sendRestRequest("user/?active=true").then((resp) => {
                setAllUsers(resp);
            });
            if (activeFolder && activeSpace) {
                folderMembers(activeFolder.id);
            } else if (activeSpace && !activeFolder) {
                spaceMembers(activeSpace.id);
            } else if (activeWorkspace) {
                workspaceMembers(activeWorkspace.workspaceId);
            }
        }
    }, [allUsers, id]);
    useEffect(() => {
        if (membersRefreshAction) {
            if (
                activeWorkspace &&
                activeWorkspace.workspaceId &&
                activeWorkspace.workspaceId === membersRefreshAction.workspaceId
            ) {
                setShowLoader(true);
                workspaceMembers(membersRefreshAction.workspaceId);
            } else if (
                activeFolder &&
                activeSpace &&
                activeFolder.id === membersRefreshAction.folderId
            ) {
                setShowLoader(true);
                folderMembers(membersRefreshAction.folderId);
            } else {
                setShowLoader(true);
                spaceMembers(membersRefreshAction.spaceId);
            }
        }
    }, [membersRefreshAction]);
    useEffect(() => {
        if (activeFolder && activeSpace) {
            const sharedUsers = currentMembers.map((user) => user.sharedUserId);
            setActiveWorkspaceMembers(
                allUsers.filter((user) => sharedUsers.includes(user.userId))
            );

            setOwnerId(
                activeFolder?.sharedBy ||
                    activeSpace?.sharedBy ||
                    activeWorkspace?.sharedBy
            );
        } else if (activeSpace && !activeFolder) {
            const sharedUsers = currentMembers.map((user) => user.sharedUserId);
            setActiveWorkspaceMembers(
                allUsers.filter((user) => sharedUsers.includes(user.userId))
            );

            setOwnerId(activeSpace?.sharedBy || activeWorkspace?.sharedBy);
        } else if (activeWorkspace && activeWorkspace.workspaceId) {
            const sharedUsers = currentMembers.map((user) => user.sharedUserId);
            setActiveWorkspaceMembers(
                allUsers.filter((user) => sharedUsers.includes(user.userId))
            );
            setOwnerId(activeWorkspace?.sharedBy);
        }
    }, [activeWorkspace, activeSpace, activeFolder, allUsers, reload]);
    const handleToggle = (type, flag, memberId) => {
        const targetObject = currentMembers.find(
            (user) => user.sharedUserId === memberId
        );
        if (!targetObject) {
            dispatch(
                setSnackbarAction({
                    open: true,
                    severity: "error",
                    message: "Operation failed!",
                })
            );
            return;
        }
        if (activeSpace && !activeFolder) {
            let url = `share/users/space?spaceId=${activeSpace.id}`;
            let payload = {
                ...targetObject,
                [type]: !flag,
            };
            setShowLoader(true);
            http.sendRestRequest(url, "PUT", payload)
                .then((resp) => {
                    setShowLoader(false);
                    spaceMembers(activeSpace.id);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Operation completed successfully!",
                            severity: "success",
                        })
                    );
                })
                .catch((erro) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: erro?.error || "Operation failed!",
                            severity: "error",
                        })
                    );
                });
        } else if (activeFolder) {
            let url = `share/users/folder?folderId=${activeFolder.id}`;
            let payload = {
                ...targetObject,
                [type]: !flag,
            };
            setShowLoader(true);
            http.sendRestRequest(url, "PUT", payload)
                .then((resp) => {
                    setShowLoader(false);
                    folderMembers(activeFolder.id);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Operation completed successfully!",
                            severity: "success",
                        })
                    );
                })
                .catch((erro) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: erro?.error || "Operation failed!",
                            severity: "error",
                        })
                    );
                });
        } else {
            let url = `share/users/workspace?workspaceId=${activeWorkspace.workspaceId}`;
            let payload = {
                ...targetObject,
                [type]: !flag,
            };
            setShowLoader(true);
            http.sendRestRequest(url, "PUT", payload)
                .then((resp) => {
                    setShowLoader(false);
                    workspaceMembers(activeWorkspace.workspaceId);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Operation completed successfully!",
                            severity: "success",
                        })
                    );
                })
                .catch((erro) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: erro?.error || "Operation failed!",
                            severity: "error",
                        })
                    );
                });
        }
    };
    const spaceMembers = (id) => {
        http.sendRestRequest(`share/users/space?spaceId=${id}`)
            .then((response) => {
                setShowLoader(false);
                const sharedUsers = response.map((user) => user.sharedUserId);
                setActiveWorkspaceMembers(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );

                setMembersList(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );
                setCurrentMembers(response);
                setOwnerId(activeSpace?.sharedBy || activeWorkspace?.sharedBy);
            })
            .catch((erro) => setShowLoader(false));
    };
    const folderMembers = (id) => {
        http.sendRestRequest(`share/users/folder?folderId=${id}`)
            .then((response) => {
                setShowLoader(false);
                const sharedUsers = response.map((user) => user.sharedUserId);
                setActiveWorkspaceMembers(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );

                setMembersList(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );
                setCurrentMembers(response);
                setOwnerId(
                    activeFolder?.sharedBy ||
                        activeSpace?.sharedBy ||
                        activeWorkspace?.sharedBy
                );
            })
            .catch((erro) => setShowLoader(false));
    };
    const workspaceMembers = (id) => {
        http.sendRestRequest(`share/users/workspace?workspaceId=${id}`)
            .then((response) => {
                setShowLoader(false);
                const sharedUsers = response.map((user) => user.sharedUserId);
                setActiveWorkspaceMembers(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );

                setMembersList(
                    allUsers.filter((user) => sharedUsers.includes(user.userId))
                );
                setCurrentMembers(response);
                setOwnerId(activeWorkspace?.sharedBy);
            })
            .catch((erro) => setShowLoader(false));
    };
    const addMember = () => {
        const userEmail = usersToAdd.map((user) =>
            user.emailAddress.toLowerCase()
        );
        const targetUser = allUsers
            .filter((user) => userEmail.includes(user.userEmail.toLowerCase()))
            .map((userItem) => userItem.userId);
        const userSelection = selectedData.map((selection) => selection.text);
        if (targetUser && targetUser.length) {
            if (activeSpace && !activeFolder) {
                const message = {
                    spaceName: activeSpace.name,
                    spaceId: activeSpace.id,
                    isDownload: true, //userSelection.includes("Allow Download"),
                    shareUpdates: true, //userSelection.includes("Share Updates"),
                    receiveUpdates: true, //userSelection.includes("Receive Updates"),
                };
                dispatch(
                    setSharedData({
                        users: [...targetUser],
                        message: message,
                        messageType: "MSG_SPACE_OBJECT",
                        folderStructure: {
                            spaceId: activeSpace.id,
                            spaceName: activeSpace.name,
                        },
                    })
                );
            } else if (activeFolder) {
                const message = {
                    documentId: activeFolder.id,
                    folderFileName: activeFolder.name,
                    isDownload: true, //userSelection.includes("Allow Download"),
                    shareUpdates: true, //userSelection.includes("Share Updates"),
                    receiveUpdates: true, //userSelection.includes("Receive Updates"),
                };
                dispatch(
                    setSharedData({
                        users: [...targetUser],
                        message: message,
                        messageType: "MSG_FOLDER_OBJECT",
                        folderStructure: {
                            folderFileId: activeFolder.id,
                        },
                    })
                );
            } else {
                const tepm = workspaceList.find(
                    (workspace) =>
                        workspace.workspaceId === activeWorkspace.workspaceId
                );
                const workspaceData = { ...tepm };
                if (!workspaceData) return;
                workspaceData.isDownload = true;
                // userSelection.includes("Allow Download");
                workspaceData.shareUpdates = true;
                // userSelection.includes("Share Updates");
                workspaceData.receiveUpdates = true;
                // userSelection.includes("Receive Updates");
                dispatch(
                    setSharedData({
                        users: [...targetUser],
                        message: workspaceData,
                        messageType: "MSG_WORKSPACE_OBJECT",
                        folderStructure: {
                            workspaceId: workspaceData.workspaceId,
                        },
                    })
                );
            }

            dispatch(setActivePageAction("Communicator"));
            let basePathName = window.runtimeConfig.instanceName
                ? "/" + window.runtimeConfig.instanceName
                : "";
            navigate(`${basePathName}/chat`);
        }
    };
    return (
        <>
            <ModalView
                showHeader="true"
                footer="false"
                show={showMembersModal}
                onHide={hideModal}
                headerTitle="Mange Members"
            >
                <div className="title">
                    {activeFolder
                        ? `Manage ${activeFolder.name} Members`
                        : activeSpace
                        ? `Manage ${activeSpace.name} Members`
                        : heading
                        ? heading
                        : "Manage Workspace Members"}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    {ownerId === 0 || ownerId === loggedInUserId ? (
                        <div className="member-actions">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Automembers
                                    // data={directoryUsers || []}
                                    data={usersToShow}
                                    usersToAdd={usersToAdd}
                                    setUsersToAdd={setUsersToAdd}
                                ></Automembers>
                                {/* <span onClick={addMember}>add</span> */}
                            </div>
                            {/* <div style={{ width: "35%" }}>
                                <CustomDropdown
                                    items={[
                                        {
                                            text: "Share Updates",
                                            value: "Share Updates",
                                            id: 1,
                                        },
                                        {
                                            text: "Receive Updates",
                                            value: "Receive Updates",
                                            id: 2,
                                        },
                                        {
                                            text: "Allow Download",
                                            value: "Allow Download",
                                            id: 3,
                                        },
                                    ]}
                                    multiSelect={true}
                                    label={"Select an option"}
                                    // customClassName={"rippleDD rippleDDFilterView"}
                                    handleSelection={(index, item) => {
                                        setSelectedData(item);
                                    }}
                                    // customClassName={classes.root}
                                    selection={selectedData}
                                />
                            </div> */}
                            {!heading && (
                                <ButtonComponent
                                    type={"primary"}
                                    text={"Invite"}
                                    disable={
                                        usersToAdd.length === 0 ||
                                        usersToShow.length === 0 ||
                                        heading
                                    }
                                    BtnHandler={
                                        usersToAdd.length === 0 ||
                                        usersToShow.length === 0 ||
                                        heading
                                            ? () => {}
                                            : addMember
                                    }
                                    btnStyle={{ margin: "8px" }}
                                />
                            )}
                        </div>
                    ) : null}

                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        <div className="members-table-heading">
                            <div className="members-name">Name</div>
                            {/* <div className="members-share">Share Updates</div>
                            <div className="members-receive">
                                Receive Updates
                            </div>
                            <div className="members-download">
                                Allow Download
                            </div> */}
                        </div>
                    </div>
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {activeWorkspaceMembers.map((member, index) => {
                            const memberId =
                                currentMembers[index]?.sharedUserId;
                            const {
                                shareUpdates,
                                receiveUpdates,
                                allowDownload,
                            } = currentMembers[index];
                            if (!ownerId || !memberId) return null;
                            if (
                                ownerId !== loggedInUserId &&
                                memberId !== ownerId
                            )
                                return null;
                            const { firstName, lastName, userEmail } = member;
                            const avatar =
                                firstName.charAt(0) + lastName.charAt(0);
                            return (
                                <>
                                    <div className="members-table-row">
                                        <div className="members-name">
                                            <Avatar
                                                sx={{
                                                    bgcolor: "#0B5CD7",
                                                    fontWeight: "500",
                                                }}
                                                alt="Remy Sharp"
                                            >
                                                {avatar}
                                            </Avatar>
                                            <div
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                }}
                                            >
                                                {userEmail}
                                            </div>
                                        </div>
                                        {/* <div className="members-share">
                                            <Switch
                                                checked={shareUpdates}
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToggle(
                                                        "shareUpdates",
                                                        shareUpdates,
                                                        memberId
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="members-receive">
                                            <Switch
                                                checked={receiveUpdates}
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToggle(
                                                        "receiveUpdates",
                                                        receiveUpdates,
                                                        memberId
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="members-download">
                                            <Switch
                                                checked={allowDownload}
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToggle(
                                                        "allowDownload",
                                                        allowDownload,
                                                        memberId
                                                    )
                                                }
                                                disabled={
                                                    ownerId !== loggedInUserId
                                                }
                                            />
                                        </div> */}
                                    </div>
                                    <div className="devider"></div>
                                </>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                    >
                        {/* <div className="members-table-heading">
                            Other Members
                        </div> */}
                        {activeWorkspaceMembers.map((member, index) => {
                            const memberId =
                                currentMembers[index]?.sharedUserId;
                            const {
                                shareUpdates,
                                receiveUpdates,
                                allowDownload,
                            } = currentMembers[index];
                            if (!ownerId || !memberId) return null;
                            if (
                                ownerId === loggedInUserId ||
                                memberId === ownerId
                            )
                                return null;
                            const { firstName, lastName, userEmail } = member;
                            const avatar =
                                firstName.charAt(0) + lastName.charAt(0);
                            return (
                                <>
                                    <div className="members-table-row">
                                        <div className="members-name">
                                            <Avatar
                                                sx={{
                                                    bgcolor: "#0B5CD7",
                                                    fontWeight: "500",
                                                }}
                                                alt="Remy Sharp"
                                            >
                                                {avatar}
                                            </Avatar>
                                            <div
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                }}
                                            >
                                                {userEmail}
                                            </div>
                                        </div>
                                        {/* <div className="members-share">
                                            <Switch
                                                checked={shareUpdates}
                                                style={{ cursor: "pointer" }}
                                                disabled
                                            />
                                        </div>
                                        <div className="members-receive">
                                            <Switch
                                                checked={receiveUpdates}
                                                style={{ cursor: "pointer" }}
                                                disabled
                                            />
                                        </div>
                                        <div className="members-download">
                                            <Switch
                                                checked={allowDownload}
                                                style={{ cursor: "pointer" }}
                                                disabled
                                            />
                                        </div> */}
                                    </div>
                                    <div className="devider"></div>
                                </>
                            );
                        })}
                    </div>
                </div>
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                >
                    <MenuItem id="deleteGen" onClick={() => {}}>
                        <div>
                            <img
                                className="deleteIcon"
                                title="Remove"
                                src="./images/delete.svg"
                                alt="Remove"
                            />
                            <span className="btn_title" title="Remove">
                                Remove
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            </ModalView>
            <Loader show={showLoader}></Loader>
        </>
    );
};

export default Members;
