import "./button.css";
export const ButtonComponent = ({
    btnStyle,
    type,
    icon,
    text,
    BtnHandler,
    disable,
    id,
}) => {
    return (
        <div>
            <button
                style={
                    disable === true && text === "Download"
                        ? { visibility: "hidden" }
                        : btnStyle
                }
                id={id}
                className={
                    disable
                        ? icon && !text
                            ? "only-arrow disable"
                            : `btn-container disable`
                        : icon && !text
                        ? type === "ghost"
                            ? "only-arrow arrow-ghost"
                            : `only-arrow ${type}`
                        : `
                    btn-container ${type}`
                }
                onClick={disable ? null : BtnHandler}
            >
                {icon && !text && (
                    <img
                        src={icon}
                        alt="Arrow"
                        className="left-arrow"
                        style={{ height: "16px", width: "16px" }}
                    />
                )}
                {icon && text && (
                    <img
                        src={icon}
                        alt={text}
                        title={text}
                        className="left-arrow"
                        style={{ height: "16px", width: "16px" }}
                    />
                )}
                {text && <div title={text}>{text}</div>}
            </button>
        </div>
    );
};
