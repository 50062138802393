import SpaceManager from "../bae-client/SpaceManager";

export const fetchSpaceListData = (workspaceId) => {
    return async (dispatch) => {
        const _sm = new SpaceManager();
        const response = await _sm.loadSpaceList(workspaceId);
        dispatch({
            type: "FETCH_SPACE_LIST",
            payload: response,
        });
    };
};

export const addSpace = (spaceRegistryTblId, workspaceId, data) => {
    return async (dispatch) => {
        const _wm = new SpaceManager();
        const response = await _wm.addNewSpace(
            spaceRegistryTblId,
            workspaceId,
            data
        );
        dispatch({
            type: "ADD_SPACE",
            payload: response,
        });
    };
};
export const removeAddedSpace = () => {
    return { type: "ADD_SPACE", payload: null };
};

export const updateSpaceName = (spaceRegistryTblId, workspaceId, data) => {
    return async (dispatch) => {
        const _wm = new SpaceManager();
        const response = await _wm.updateSpaceNameWithAPI(
            spaceRegistryTblId,
            workspaceId,
            data
        );
        dispatch({
            type: "UPDATE_SPACE",
            payload: response,
        });
    };
};
export const setSpaceIdToNameMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "SPACE_ID_TO_NAME_MAP",
            payload: response,
        });
    };
};
export const setSpaceNameToIdMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "SPACE_NAME_TO_ID_MAP",
            payload: response,
        });
    };
};
export const setSpaceIdToWorkspaceIdMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "SPACE_ID_TO_WORKSPACE_ID_MAP",
            payload: response,
        });
    };
};
