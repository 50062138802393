import { Avatar } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setActivePage } from "../../../actions";
import http from "../../../bae-client/HTTP";
import user from "../../../bae-client/User";
import TextBox from "../../stylized/TextBox";
import UserProfileDropdown from "../common/UserProfile/UserProfileDropdown";
import "../common/UserProfile/UserProfile.css";
import InviteModal from "../common/Invite/InviteModal";
import config from "../../../config";
import userManager from "../../../bae-client/UserManager";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
class TitleBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserInfoModal: false,
            userInfoTarget: null,
            showUserModel: false,
        };
        this.username = http.getUserName();
        this.userInfoObj = {
            userName: this.username,
        };
    }

    handleUserInfoBtnClick = (e) => {
        this.setState({
            showUserInfoModal: true,
            userInfoTarget: e.currentTarget,
        });
    };
    closePopover = () => {
        this.setState({ showUserInfoModal: false, userInfoTarget: null });
    };
    closeUserInfoMenu = (show) => {
        this.setState({
            showUserInfoModal: show,
            userInfoTarget: null,
        });
    };
    openInviteModal = () => {
        this.setState({ showUserModel: true });
    };
    helpDropDown = () => {
        this.props.handleHelpDropDownModal(true);
        this.props.visibility(false);
    };
    closeInviteModal = () => {
        this.setState({ showUserModel: false });
    };
    renderInviteModal = () => {
        return (
            <InviteModal
                show={this.state.showUserModel}
                hide={this.closeInviteModal}
            ></InviteModal>
        );
    };
    renderSerachBox = () => {
        if (this.props.showSearchBox)
            return (
                <div
                    className="headerSearchBox row doCenter ml-1 m-0 cursorPointer"
                    title="Search Box"
                >
                    <div className="col-1 p-0">
                        <img
                            src="./images/SearchIcon.png"
                            alt=""
                            style={{ width: "29.58px", height: "26.9px" }}
                        />
                    </div>
                    <div className="col-8 pl-0 doCenter pr-0">
                        <div className="row w-100 m-0">
                            <div className="col">
                                <TextBox
                                    className="getAddress form-control serachInputBox"
                                    id="rippleSearchTextBox"
                                    clearAfterChange={true}
                                    type="text"
                                    label=""
                                    placeholder={false}
                                    // onkeydown={this.onClickEnterBtn}
                                    // onchange={this.setFromText}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 doCenter pl-0 pr-0">
                        <button
                            id="headerSearchBtn"
                            type="button"
                            className="btn headerSearchBtn"
                            // disabled={this.chipsArray.length > 0 ? false : true}
                            // onClick={this.onClickRipple}
                        >
                            Search
                        </button>
                    </div>
                </div>
            );
        else return null;
    };
    getAvtarTextByUsername = (userName) => {
        const userInfo = this.props.userInfo;
        if (userInfo) {
            if (userInfo.firstName && userInfo.lastName)
                return (
                    userInfo.firstName.charAt(0).toUpperCase() +
                    userInfo.lastName.charAt(0).toUpperCase()
                );
            else if (userInfo.firstName)
                return userInfo.firstName.charAt(0).toUpperCase();
            else if (userInfo.userEmail)
                return (
                    userInfo.userEmail.charAt(0).toUpperCase() +
                    userInfo.userEmail.charAt(1).toUpperCase()
                );
        }
        // let firstChar = "";
        // let secondChar = "";
        // if (userName.split(" ").length > 1) {
        //     firstChar = userName.split(" ")[0]?.charAt(0);
        //     secondChar = userName.split(" ")[1]?.charAt(0);
        // } else {
        //     firstChar = userName.split(" ")[0].charAt(0)?.toUpperCase();
        //     secondChar = userName.split(" ")[0].charAt(1)?.toUpperCase();
        // }
        // return firstChar + secondChar;
    };
    renderUserProfileMenu = () => {
        if (this.state.showUserInfoModal === true) {
            return (
                <UserProfileDropdown
                    show={this.state.showUserInfoModal}
                    hidePopover={this.closePopover}
                    target={this.state.userInfoTarget}
                    handleLogout={this.props.handleLogout}
                    setUser={this.props.setUser}
                    userInfo={this.userInfoObj}
                ></UserProfileDropdown>
            );
        }
    };
    renderUserImageNadIcon = () => {
        return (
            <div
                id={"div_lower_menu"}
                className="row userTitleDiv w-100 m-0"
                // style={{
                //     position: "absolute",
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "space-between",
                // }}
            >
                {/* <Button className="doCenter cursorPointer invite" style={{ marginRight: "16px", color: "#fff"}} onClick={this.openInviteModal} title="Invite" variatnt="dark">Invite</Button> */}
                {/* <DropdownButton id="help-dropdown" title="Help">
                    <Dropdown.Item
                        className="help"
                        href="mailto:support@unitycentral.io"
                    >
                        Submit Feedback
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="help"
                        href={config.helpURL}
                        target="_blank"
                    >
                        Knowledge Center
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="help"
                        href="mailto:support@unitycentral.io"
                    >
                        Contact Support
                    </Dropdown.Item>
                </DropdownButton> */}
                <div
                    id="div_leftPane_helpDropdown"
                    style={{
                        marginLeft: "-33px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    title="Help"
                    onClick={this.helpDropDown}
                >
                    <img
                        src="./images/fileTypeIcon/info.svg"
                        style={{ marginRight: "2px", width: "25px" }}
                    />
                    <span
                        id="spanIconHeading"
                        style={{ marginLeft: "63px", color: "white" }}
                    >
                        Help
                    </span>
                </div>
                {this.props.isHelpDropDownOpen && (
                    <div className="help">
                        <ul
                            style={{
                                listStyleType: "none",
                                padding: " 0px 17px",
                            }}
                        >
                            <li
                                style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                }}
                            >
                                <a
                                    href="mailto:support@boardwalktech.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    Submit Feedback
                                </a>
                            </li>

                            <li
                                style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                }}
                            >
                                <a
                                    href="https://ucinfo.boardwalktech.com/knowledge-center"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    Knowledge Center
                                </a>
                            </li>
                            <li
                                style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                }}
                            >
                                <a
                                    href="mailto:support@boardwalktech.com"
                                    target="_blank"
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    rel="noopener noreferrer"
                                >
                                    Contact Support
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
                <div
                    id="div_leftPane_invite"
                    onClick={this.openInviteModal}
                    style={{
                        marginTop: "20px",
                        marginLeft: "-33px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    title="Invite"
                    role="button"
                >
                    <img
                        src="./images/fileTypeIcon/userAdd.svg"
                        style={{ marginRight: "2px", width: "25px" }}
                    />
                    <span
                        id="spanIconHeading"
                        style={{ marginLeft: "58px", color: "white" }}
                    >
                        Invite
                    </span>
                </div>
                <div
                    id="btn_TitleBar_profileDropdown"
                    className="row m-0 doCenter"
                    title={this.username}
                    style={{ cursor: "pointer", margin: "16px" }}
                    onClick={this.handleUserInfoBtnClick}
                >
                    <Avatar
                        style={{
                            background: "#BC0078",
                            fontSize: "1rem",
                            marginTop: "20px",
                            marginLeft: "-33px",
                        }}
                        className="avatar"
                        title={this.username}
                    >
                        {this.getAvtarTextByUsername(this.username)}
                    </Avatar>
                </div>
                {this.renderInviteModal()}
            </div>
        );
    };

    renderManageSpaceHeader = () => {
        return (
            <div
                className="titleSection row w-100 m-0"
                style={{ height: "5rem" }}
            >
                <div className="titleDiv row m-0 w-100">
                    <div className="col p-0">
                        <div className="row m-0 activePageLeftTitle titleDiv">
                            {this.renderTitleImage()}
                            <div className="row m-0 ">
                                <span
                                    className="mainTitle textEllipsis"
                                    style={{ paddingLeft: "10px" }}
                                >
                                    {this.props.title}
                                </span>
                            </div>
                            <div className="row m-0">
                                <span
                                    className="subTitle"
                                    style={{ paddingLeft: "10px" }}
                                >
                                    {this.props.subTitle}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">{this.renderUserImageNadIcon()}</div>
                    {this.renderUserProfileMenu()}
                </div>
            </div>
        );
    };
    renderTitleImage = () => {
        if (this.props.page === "workspace") {
            return (
                <span className="mainTitle  textEllipsis" title={"Workspace"}>
                    {"Workspace"}
                </span>
            );
        }
        if (this.props.titleImage !== undefined) {
            return <img src={this.props.titleImage} alt="titleImage" />;
        } else {
            return null;
        }
    };
    renderWorkspaceEditHeader = () => {
        return (
            <div className="titleSection row w-100 m-0">
                <div className="col p-0">
                    <div className="activePageLeftTitle titleDiv">
                        {this.renderTitleImage()}
                        <span
                            className="mainTitle textEllipsis"
                            style={{ paddingLeft: "10px" }}
                        >
                            {this.props.title}
                        </span>
                    </div>
                </div>
                <div className="col">{this.renderUserImageNadIcon()}</div>
                {this.renderUserProfileMenu()}
            </div>
        );
    };
    renderSearchBox = () => {
        return (
            <div className="titleSection row w-100 m-0">
                <div className="col p-0">
                    <div className="activePageLeftTitle titleDiv">
                        {this.renderTitleImage()}
                    </div>
                </div>
                <div className="col">
                    {this.renderUserImageNadIcon()}
                    {this.renderUserProfileMenu()}
                </div>
            </div>
        );
    };
    render() {
        if (this.props.page === "managespace") {
            return this.renderManageSpaceHeader();
        } else if (this.props.page === "workspace") {
            //return this.renderWorkspaceEditHeader();
            return this.renderSearchBox();
        } else {
            return (
                <div className="titleSection row w-100 m-0">
                    <div className="col">{this.renderUserImageNadIcon()}</div>
                    {this.renderUserProfileMenu()}
                </div>
            );
        }
    }
}
const mapDispatchToProps = () => {
    return {
        setActivePage,
    };
};
const mapStateToProps = (state) => {
    return {
        activePage: state.activePage,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(TitleBar);
