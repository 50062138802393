import React from "react";
import "./style.css";

const InstanceContainer = ({ showButton, openAddInstanceModal }) => {
    return (
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div
                style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <div style={{ padding: "12px" }}>
                    <img src="./images/instance 1.svg" />
                </div>
                <div className="containerHeader">
                    There are no instances to display
                </div>
                {showButton ? (
                    <div
                        className="cursorPointer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                        }}
                        onClick={openAddInstanceModal}
                    >
                        <img
                            style={{
                                width: "15px",
                                height: "15px",
                            }}
                            src="./images/Add Space.png"
                            className="cursorPointer"
                            id="icons"
                            title="Create a new instance"
                            alt=""
                        />
                        <span
                            id="create_new_instance_span"
                            title="Create a new instance"
                            style={{ marginTop: "6px" }}
                        >
                            Create New Instance
                        </span>
                    </div>
                ) : (
                    <div
                        className="containerSubHeader"
                        style={{ margin: "auto" }}
                    >
                        Add Generic step to enable instance
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstanceContainer;
