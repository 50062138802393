export const workspaceList = (workspaceListData = null, action) => {
    if (action.type === "FETCH_WORKSPACE_LIST") {
        return action.payload;
    }
    // if (action.type === "ADD_WORKSPACE_LIST") {
    //     return action.payload;
    // }
    if (action.type === "RESET_WORKSPACE") return [];
    return workspaceListData;
};

export const addWorkspace = (addWorkspace = false, action) => {
    if (action.type === "ADD_WORKSPACE") {
        return true;
    }
    if (action.type === "CHANGE_FLAG") {
        return false;
    }
    return addWorkspace;
};
export const workspaceIdToName = (workspaceNameMap = null, action) => {
    if (action.type === "WORKSPACE_ID_TO_NAME_MAP") {
        return action.payload;
    }
    return workspaceNameMap;
};
export const workspaceNameToId = (workspaceNameToIdMap = null, action) => {
    if (action.type === "WORKSPACE_NAME_TO_ID_MAP") {
        return action.payload;
    }
    return workspaceNameToIdMap;
};
