import React from "react";

export default function WelcomeChat({ setIsNewChatChange }) {
    return (
        <div className="welcome-chat">
            <img className="chatIcon" src="./images/chat.png" />
            <h1>Welcome to the Chat!</h1>
            <p>Click the new message button below to start a new chat</p>
            <div
                className="chat-button cursorPointer"
                onClick={() => {
                    setIsNewChatChange(true);
                }}
            >
                <img
                    src="./images/msg.png"
                    alt="New Message"
                    title="New Message"
                />
                <span style={{ paddingLeft: "12px" }}>New Message</span>
            </div>
        </div>
    );
}
