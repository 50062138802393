import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import "./instance.css";
import { InstanceData } from "../../../../../../reducers/documentChain";

const InstanceDropdown = ({
    selectedGenericInstances,
    selectedInstance,
    handleCloseInstanceDropdown,
    getInstanceData,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [nameFilter, setNameFilter] = React.useState("");

    const open = Boolean(anchorEl);
    useEffect(() => {
        getInstanceData();
    }, InstanceData);
    const handleClick = (event) => {
        getInstanceData();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        handleCloseInstanceDropdown(e);
        setAnchorEl(null);
    };
    const handleKey = (e) => {
        e.stopPropagation();
        return false;
    };
    const handleChange = (e) => {
        e.stopPropagation();
        setNameFilter(e.target.value);
        return false;
    };
    const handleInstanceDropdown = () => {
        let filteredInstance = [];
        // selectedGenericInstances.forEach((instance) => {
        //     if (
        //         nameFilter &&
        //         instance.instanceName
        //             .toLowerCase()
        //             .includes(nameFilter.toLowerCase())
        //     ) {
        //         filteredInstance;
        //     }
        // });
        let instanceValues = selectedGenericInstances.filter((instance) =>
            nameFilter
                ? instance.instanceName
                    .toLowerCase()
                    .includes(nameFilter.toLowerCase())
                : true
        ).sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        
        instanceValues.length === 0
            ? filteredInstance.push(
                  <MenuItem
                      onClick={handleClose}
                      disableRipple
                      style={{
                          width: "200px",
                          paddingTop: "0px",
                      }}
                  >
                      <div className="w-100 textEllipsis">
                          No Result Found...
                      </div>
                  </MenuItem>
              )
            : filteredInstance.push(
                  instanceValues.map((instance, index) => {
                      return (
                          <MenuItem
                              key={instance.instanceId}
                              onClick={handleClose}
                              disableRipple
                              style={{
                                  width: "200px",
                                  paddingTop: "0px",
                              }}
                              id={instance.instanceId}
                          >
                              <div
                                  className="w-100 textEllipsis"
                                  title={instance.instanceName}
                                  id={instance.instanceId}
                              >
                                  {instance.instanceName}
                              </div>
                          </MenuItem>
                      );
                  })
              );
        return filteredInstance;
    };

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon id="icon_instanceDropdown" />}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "171px",
                    padding: "7px, 16px, 7px, 16px",
                    borderRadius: "4.32px",
                    border: "1.08px",
                    background: `linear-gradient(0deg, #FFFFFF, #FFFFFF),
                                    linear-gradient(0deg, #E0E0E0, #E0E0E0)`,
                    color: "black",
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                    // width: "auto",
                    // maxWidth: "200px",
                    // maxHeight: ",50px",
                    // padding: "5px 8px",
                    boxShadow:
                        "0px 1.0787670612335205px 2.157534122467041px 0px #0000001F",
                }}
            >
                <div
                    className="textEllipsis"
                    title={
                        selectedInstance
                            ? selectedInstance.instanceName
                            : "Instance Name"
                    }
                    style={{
                        textTransform: "none",
                        maxWidth: "120px",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "22px",
                        letterSpacing: "-0.006em",
                        textAlign: "left",
                    }}
                >
                    {selectedInstance
                        ? selectedInstance.instanceName
                        : "Instance Name"}
                </div>
            </Button>

            <Menu
                id="demo-customized-menu invisibleScroller"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                style={{
                    minHeight: "100px",
                    maxHeight: "350px",
                    padding: "0px",
                }}
                onClose={handleClose}
            >
                <MenuItem
                    style={{
                        fontFamily: "Montserrat",
                        backgroundColor: "white",
                        paddingTop: "0px",
                    }}
                    disableRipple
                >
                    <div
                        onKeyDown={handleKey}
                        style={{
                            background: "white",
                            width: "163px",
                            position: "fixed",
                            zIndex: "9999",
                            marginTop: "-5px",
                            height: "0px",
                            padding: "11px 7px 0px 0px",
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search..."
                            value={nameFilter}
                            className="updateSpaceName"
                            onChange={handleChange}
                            style={{
                                borderRadius: "10px",
                                position: "fixed",
                                zIndex: "999",
                                width: "167px",
                            }}
                        />
                    </div>
                </MenuItem>
                <div style={{ marginTop: "42px" }}>
                    {handleInstanceDropdown()}
                </div>
            </Menu>
        </>
    );
};

export default InstanceDropdown;
