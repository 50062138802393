import React, { useEffect, useState } from "react";
import ModalView from "../../../../stylized/ModalView";
import TextBox from "../../../../stylized/TextBox";
import Button from "@material-ui/core/Button";

export default function WorkspaceModal({
    addWorkspaceModalShow,
    closeModal,
    setText,
    handleAddWorkspaceClick,
    error,
}) {
    return (
        <ModalView
            id="addWorkspaceModalBtn"
            title="Add"
            value="Add"
            type="submit-cancel"
            onSubmit={handleAddWorkspaceClick}
            show={addWorkspaceModalShow}
            onHide={closeModal}
            footer={"false"}
            size="md"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <span
                    className="addWorkspaceModal addwork"
                    style={{ paddingLeft: "8px" }}
                >
                    Add New Workspace
                </span>
                <TextBox
                    id="workspacename"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onchange={setText}
                    label="Name your workspace..."
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
            </div>
            {error && (
                <>
                    {console.log(error)}
                    <small
                        style={{ color: "red", paddingLeft: "18px" }}
                        id="small_addWorkspace_errorMsg"
                    >
                        {error}
                    </small>
                    <br />
                </>
            )}
        </ModalView>
    );
}
