import React, { useState, useEffect } from "react";
import ListView from "../../../../stylized/ListView";
import { connect } from "react-redux";
import PopoverViewCustom from "../../../../stylized/PopoverViewCustom";
import "../WorkspaceContainer/style.css";
import http from "../../../../../bae-client/HTTP";
import ShareFile from "../WorkspaceContainer/AddFolderModal/ShareFile";
import DialogView from "../../../../stylized/DialogView";
import AlertDialog from "../../../../stylized/AlertDialog";
import Loader from "../../../../stylized/Loader";
import { setRippleResult } from "../../../../../actions/RippleActions";
import {
    setShowDrawer,
    setRippleDocumentResponse,
    setRippleKeywordSearched,
    setTimelineDocumentList,
    setChipArray,
    setSelectedWorkspaceIndex,
    setSelectedSpaceIndex,
    setSelectedFolderIndex,
    setSelectedPeriod,
    setSpaceArray,
    setRippleNameStr,
    setRippleId,
    setDeleteRippleId,
    setValue,
} from "../../../../../actions/Rippleaction";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import CustomDropdown from "../../../../stylized/CustomDropdown";
import { makeStyles } from "@material-ui/core/styles";
import CustomSearchDropdown from "../../../../stylized/CustomDropDown/index";

const useStyles = makeStyles({
    root: {
        width: "200px!important",
    },
});

function RippleLogHeaderView({
    handleSelectedFilter,
    registryId,
    loadLog,
    handleselectedAction,
    recureBtnClicked,
    linkBtnClicked,
    shareBtnClicked,
    sortBtnClicked,
    editRippleBtnClicked,
    showActions,
    selectedIndex,
    showData,
    removeHeader,
    setRippleResult,
    setShowDrawer,
    setChipArray,
    setSpaceArray,
    setRippleDocumentResponse,
    setRippleKeywordSearched,
    setTimelineDocumentList,
    setSelectedFolderIndex,
    setSelectedSpaceIndex,
    setSelectedWorkspaceIndex,
    setSelectedPeriod,
    setRippleNameStr,
    setRippleId,
    setDeleteRippleId,
    setValue,
    chipsArray,
    rippleId,
    rippleDeleteId,
}) {
    const classes = useStyles();
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const [selectedFilter, setSelectedFilter] = useState("");
    const [error, setError] = useState("");
    const [shareFile, setShareFile] = useState();
    const [rippleData, setRippleData] = useState(null);
    useEffect(() => {
        if (showData && showData.length && selectedIndex > -1)
            setRippleData(showData[selectedIndex]);
    }, [selectedIndex]);
    const filterItems = [
        {
            text: "Date",
            value: "date",
            id: "date_filter",
        },
        // {
        //     text: "Type",
        //     value: "type",
        //     id: "type_filter",
        // },
    ];
    const handleDateFilter = (index, listValue, listText, event) => {
        listValue && sortBtnClicked(listValue.value);
    };
    const handleShareClick = () => {
        setShareFile(true);
    };
    const closeShareClick = () => {
        setShareFile(false);
    };
    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            if (isDelete === "DELETE") {
                const body = rippleData.rippleId;
                setShowLoader(true);
                deleteItems(body)
                    .then((resp) => {
                        if (resp === 200) {
                            setShowLoader(false);
                            setShowSnackbar(true);
                            setSnackbarMessage(
                                "Ripple Log Deleted successfully!"
                            );
                            if (
                                rippleData.rippleId ===
                                rippleId.rippleRegistryId
                            ) {
                                setDeleteRippleId("Delete");
                                setShowDrawer(false);
                                setRippleDocumentResponse(null);
                                setRippleKeywordSearched(false);
                                setTimelineDocumentList([]);
                                setRippleResult(null);
                                setChipArray([]);
                                setSelectedWorkspaceIndex(null);
                                setSelectedFolderIndex(null);
                                setSelectedSpaceIndex(null);
                                setSelectedPeriod("");
                                setSpaceArray([]);
                                setRippleNameStr("");
                                setRippleId("");
                                setValue("");
                            }
                            removeHeader();
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err && err.error
                                ? err.error
                                : "Something went wrong, please try again!",
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };

    const deleteItems = (id) => {
        if (id > 0) {
            return new Promise((resolve, reject) => {
                let url = `ripple/rippleRegistry?rippleRegistryId=${id}`;

                http.sendRestRequest(url, "DELETE", id)
                    .then((resp) => {
                        resolve(resp);
                        loadLog();
                    })
                    .catch((err) => {
                        reject(err);
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                        console.log(err);
                    });
            });
        }
    };

    const deleteClickHandler = (e) => {
        setAlertDialogContent({
            title: "Delete Ripple Log",
            message: "Are you sure you want to Delete the selected ripple?",
            isAlert: true,
            buttonName: "Delete",
            buttonId: "btn_delete",
        });
        setAlertDialogShow(true);
    };
    const handleSelectedAction = (e, type) => {
        switch (type) {
            case "rename":
                editRippleBtnClicked();
                break;
            case "recure":
                recureBtnClicked();
                break;
            case "linkToAlert":
                linkBtnClicked("toAlert");
                break;
            case "linkToSpace":
                linkBtnClicked("toSpace");
                break;
            case "share":
                shareBtnClicked();

                break;
            default:
                break;
        }
    };
    const renderFilterDropdown = () => {
        return (
            <>
                {/* <div className="filterBtn ml-3">
                <PopoverViewCustom
                    id="drp_RippleLog_sort"
                    btnClass={"ctBtnFont"}
                    iconClass="fas fa-angle-down" //btnLabel="Generic Blockchain Name"
                    btnLabel={
                        selectedFilter !== "" ? selectedFilter : "Sort By"
                    }
                    isDropdown={true}
                >
                    <ListView
                        className="noBorder"
                        listItemClassname="pl-4"
                        id={"GenericBlockchainName"}
                        items={filterItems}
                        showIcon="false"
                        disableActiveIndex="true"
                        onchange={handleDateFilter}
                    />
                </PopoverViewCustom>
            </div> */}
                <CustomSearchDropdown
                    items={filterItems}
                    label={"Sort By"}
                    id={"sortBy"}
                    multiSelect={false}
                    isSearchable={true}
                    isChipView={false}
                    handleSelection={handleDateFilter}
                    customStyle={{
                        color: "red",
                        borderRadius: 20,
                        marginTop: 10,
                    }}
                    customClassName={classes.root}
                    dropdownPosition={'ripple'}
                ></CustomSearchDropdown>
            </>
        );
    };
    const renderActions = () => {
        return (
            <div
                className="btnGroup"
                style={{ marginLeft: "50px", paddingTop: "6px" }}
            >
                <div
                    className="addToBtn cur_pointer doCenter"
                    title={"Rename ripple"}
                    id="btn_rippleLog_renameLog"
                    onClick={(e) => handleSelectedAction(e, "rename")}
                >
                    <img
                        src="./images/Rename.svg"
                        className="bw_plusButton"
                    ></img>
                    <span
                        id="rip_title"
                        className="addLabel newFontMontserrat"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        Rename
                    </span>
                </div>
                <div
                    className="addToBtn cur_pointer doCenter"
                    title={"Recur ripple"}
                    id="btn_rippleLog_recure"
                    onClick={(e) => handleSelectedAction(e, "recure")}
                >
                    <img
                        src="./images/recur.svg"
                        className="bw_plusButton"
                    ></img>
                    <span
                        id="rip_title_recur"
                        className="addLabel newFontMontserrat"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        Recur
                    </span>
                </div>
                <div
                    className="addToBtn cur_pointer doCenter"
                    title={"Share ripple"}
                    id="btn_rippleLog_share"
                    onClick={handleShareClick}
                >
                    <img
                        src="./images/share 2.svg"
                        className="bw_plusButton"
                    ></img>
                    <span
                        id="rip_title_share"
                        className="addLabel newFontMontserrat"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        Share
                    </span>
                </div>
                <div
                    className="addToBtn cur_pointer doCenter"
                    title={"Delete Ripple"}
                    id="btn_rippleLog_delete"
                    onClick={deleteClickHandler}
                >
                    <img
                        src="./images/trash 2.svg"
                        className="bw_plusButton"
                    ></img>
                    <span
                        id="rip_title_delete"
                        className="addLabel newFontMontserrat"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        Delete
                    </span>
                </div>
                <div
                    className="recureBtn cur_pointer doCenter"
                    title={"Link ripple"}
                    id="btn_rippleLog_linkToAlert"
                    // onClick={(e) => handleSelectedAction(e, "linkToAlert")}
                >
                    {/* <img
                        className="linkRippleIcon"
                        src="././images/RippleLog/link.svg"
                    /> */}
                </div>
                <div
                    className="recureBtn cur_pointer doCenter"
                    title={"Link ripple"}
                    id="btn_rippleLog_linkToRipple"
                    // onClick={(e) => handleSelectedAction(e, "linkToSpace")}
                >
                    {/* <img
                        className="shareRippleToSpaceIcon"
                        src="././images/RippleLog/folder-symlink.svg"
                    /> */}
                </div>
                <div
                    className="shareBtn cur_pointer doCenter"
                    title={"Share ripple"}
                    id="btn_rippleLog_shareToRipple"
                    // onClick={(e) => handleSelectedAction(e, "share")}
                >
                    {/* <img
                        className="shareRippleIcon"
                        src="././images/RippleLog/share.svg"
                    /> */}
                    <ShareFile
                        showShareModal={shareFile}
                        closeModal={closeShareClick}
                        error={error}
                        showData={rippleData}
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <div class="rippleLog_header">
                <div className="row m-0">
                    {renderFilterDropdown()}
                    {showActions === true ? renderActions() : null}
                </div>
            </div>
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </>
    );
}

const mapDispatchToProps = () => {
    return {
        setRippleResult,
        setShowDrawer,
        setChipArray,
        setSpaceArray,
        setRippleDocumentResponse,
        setRippleKeywordSearched,
        setTimelineDocumentList,
        setSelectedFolderIndex,
        setSelectedSpaceIndex,
        setSelectedWorkspaceIndex,
        setSelectedPeriod,
        setRippleNameStr,
        setRippleId,
        setDeleteRippleId,
        setValue,
    };
};

const mapStateToProps = (state) => {
    return {
        chipsArray: state.chipsArray,
        rippleId: state.rippleId,
        rippleDeleteID: state.rippleDeleteID,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(RippleLogHeaderView);
