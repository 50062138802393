import React from "react";
import ModalView from "../../../../../../../stylized/ModalView";
import TextBox from "../../../../../../../stylized/TextBox";

export default function SpaceViewerModal({
    modalShow,
    closeModal,
    setText,
    label,
    value,
    handleUpdatedSpaceNameSubmit,
}) {
    const handleFocus = (event) => event.target.select();

    if (modalShow) {
        return (
            <ModalView
                show={modalShow}
                onHide={closeModal}
                footer={"false"}
                size="md"
            >
                <div
                    className="row doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <img src="./images/Space 2.svg" alt="space"></img>
                    <TextBox
                        id="updateSpacename"
                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                        type="text"
                        onchange={setText}
                        // label={label}
                        value={value}
                        formControl="Off"
                        onFocus={handleFocus}
                        doCenter={true}
                    ></TextBox>
                    <input
                        type="button"
                        className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                        style={{
                            height: "40px",
                            marginTop: "45px",
                            marginLeft: "10px",
                        }}
                        value="CHANGE"
                        disabled={label.trim() === value.trim() ? true : false}
                        onClick={handleUpdatedSpaceNameSubmit}
                    ></input>
                </div>
            </ModalView>
        );
    } else {
        return null;
    }
}
