import * as React from "react";
import "./checkBox.css";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    noFocus: {
        "&:focus": {
            outline: "none",
        },
    },
});

export default function Checkboxes({ defaultChecked, disable }) {
    const classes = useStyles();

    return (
        <div>
            <Checkbox
                defaultChecked={defaultChecked}
                classes={{ focusVisible: classes.noFocus }}
            />
            <Checkbox classes={{ focusVisible: classes.noFocus }} />
            <Checkbox
                disabled={disable}
                classes={{ focusVisible: classes.noFocus }}
            />
            <Checkbox
                disabled={true}
                defaultChecked={defaultChecked}
                classes={{ focusVisible: classes.noFocus }}
            />
        </div>
    );
}
