import http from "./HTTP";
import user from "./User";
class RecentActivityManager {

    createRecentActivity = (data) => {
        let userDetails = user.getBwUserInfo();
        const username =
            userDetails !== null
                ? userDetails.firstName + " " + userDetails.lastName
                : "";
        data.description = data.objectType === "MEMBERS" ? data.description : `${username} ${data.description}`
        let url = `userInteractions/recentActivity`;
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default RecentActivityManager;
