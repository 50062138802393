import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RippleManager from "../../../../../../../../../bae-client/RippleManager";
import RippleLogView from "../../../../../../../../stylized/RippleLogView";
import Button from "@material-ui/core/Button";
import "./style.css";
import AlertDialog from "../../../../../../../../stylized/AlertDialog";
import Loader from "../../../../../../../../stylized/Loader";
import http from "../../../../../../../../../bae-client/HTTP";

function ShareRipple({ onClose, handleAttach }) {
    const [rippleLogs, setRippleLogs] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedRippleData, setSelectedRippleData] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const spaceIdToName = useSelector((state) => state.spaceIdToName);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const workspaceList = useSelector((state) => state.workspaceList);

    useEffect(() => {
        const rippleManager = new RippleManager();
        setShowLoader(true);
        rippleManager
            .loadRippleLog()
            .then((response) => {
                setRippleLogs(response);
                sortDataByExistingState();
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
            });
    }, []);
    const sortDataByExistingState = () => {
        rippleLogs.sort((a, b) => {
            return (
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            );
        });
        return rippleLogs;
    };
    const handleRowSelected = (index) => {
        setSelectedRowIndex(index);
        setSelectedRippleData(rippleLogs[index]);
    };
    const handleShare = () => {
        let selectedRippleLogData = selectedRippleData;
        let workspaceFilterList = [];
        const workspaceIds = [];
        let spaceFilterList = [];
        let folderFilterList = [];
        if (selectedRippleLogData.filterCriteriaWorkspaces !== "") {
            selectedRippleLogData.filterCriteriaWorkspaces
                .split(",")
                .forEach((workspaceId) => {
                    const ws = workspaceList.find(
                        (w) => w?.workspaceId === parseInt(workspaceId)
                    );
                    if (ws) workspaceFilterList.push(ws.workspaceName);
                });
        }
        if (selectedRippleLogData.filterCriteriaSpaces !== "") {
            let url = `workspace/selectedWorkspaces/spaceList?workspaceIds=${selectedRippleLogData.filterCriteriaWorkspaces}`;
            http.sendRestRequest(url)
                .then((spaceList) => {
                    selectedRippleLogData.filterCriteriaSpaces
                        .split(",")
                        .forEach((spaceId) => {
                            const space = spaceList.find(
                                (s) => s?.spaceId === parseInt(spaceId)
                            );
                            if (space) spaceFilterList.push(space.spaceName);
                        });
                    if (selectedRippleLogData.filterCriteriaFolders !== "") {
                        folderFilterList =
                            selectedRippleLogData.filterCriteriaFolders.split(
                                ","
                            );
                    }
                    const selectedRawObj = {
                        rippleName: selectedRippleLogData.rippleName,
                        searchCriteria: selectedRippleLogData.searchCriteria,
                        filterCriteriaFolders: folderFilterList,
                        filterCriteriaSpaces: spaceFilterList,
                        filterCriteriaWorkspaces: workspaceFilterList,
                        filterCriteriaDates:
                            selectedRippleLogData.filterCriteriaDates,
                    };
                    handleAttach(selectedRawObj);
                    onClose();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const selectedRawObj = {
                rippleName: selectedRippleLogData.rippleName,
                searchCriteria: selectedRippleLogData.searchCriteria,
                filterCriteriaFolders: folderFilterList,
                filterCriteriaSpaces: spaceFilterList,
                filterCriteriaWorkspaces: workspaceFilterList,
                filterCriteriaDates: selectedRippleLogData.filterCriteriaDates,
            };
            handleAttach(selectedRawObj);
            onClose();
        }
    };
    return (
        <div style={{ overflow: "hidden" }}>
            <div
                className="row w-100 m-0 mb-3 header"
                style={{ alignItems: "center" }}
            >
                <div
                    className="col-11 p-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <img
                        className={"cursorPointer"}
                        alt="new chat"
                        src="./images/Ripple.svg"
                    ></img>
                    <span style={{ padding: "0px 12px" }}>
                        Share Ripple Query
                    </span>
                </div>
                <div
                    className="col-1 p-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <button
                        type="button"
                        onClick={onClose}
                        class="close modalCloseIcon"
                        data-dismiss="modal"
                        id={"closeModalButton"}
                    >
                        &times;
                    </button>
                </div>
            </div>
            <div className="rippleViewContainer invisibleScroller">
                <RippleLogView
                    rowItems={sortDataByExistingState()}
                    selectedIndex={selectedRowIndex}
                    handleRowSelected={handleRowSelected}
                    viewBtnCliked={() => {}}
                ></RippleLogView>
            </div>
            <div
                className="m-0 w-100"
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "12px",
                }}
            >
                <Button
                    onClick={onClose}
                    // className={classes.button}
                    variant={"outlined"}
                    style={{
                        marginRight: "5px",
                        minWidth: "5rem",
                        borderRadius: "10px",
                        height: "2.5rem",
                    }}
                >
                    Cancel
                </Button>
                <button
                    type="button"
                    onClick={handleShare}
                    className="btn btn-primary share"
                    data-dismiss="modal"
                    id={"shareModalButton"}
                    disabled={selectedRowIndex === -1 ? true : false}
                >
                    Share
                </button>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
                <Loader show={showLoader} />
            </div>
        </div>
    );
}

export default ShareRipple;
