import UnityBoard from './UnityBoard';
class Space extends UnityBoard {

    constructor() {
        super();
        this.setBoardType("space");
    }
    insertNewSpaceName(SpaceManager) {
        let promise = new Promise((resolve, reject) => {
            let optionsForDocUpload = {
                documentObjects: null,
                spaceName: this.data.spaceName,
                spaceId: this.data.spaceId,
            }
            SpaceManager.updateSpaceName(optionsForDocUpload).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
        return promise;
    }
    insertNewSpace(SpaceManager, parentId) {
        let promise = new Promise((resolve, reject) => {
            let docsForUpload = this.getDocumentsToUpload();
            if (docsForUpload.length > 0) {
                let optionsForDocUpload = {
                    documentObjects: docsForUpload,
                    spaceName: this.data.spaceName,
                    spaceId: this.data.spaceId,
                }
                SpaceManager.insertNewSpace(optionsForDocUpload, parentId).then((data) => {
                    resolve(this.data);
                }).catch((errResp) => {
                    console.log("error in space ", errResp);
                    reject(errResp);
                });
            } else {
                reject("No Documents to save");
            }
        });
        return promise;
    }
    setData(data) {
        super.setData(data);
        if (this.data.boardType === "canvas" || this.data.boardType === null)
            this.setBoardType("space");
    }
    setSpaceName = (name) => {
        this.data.spaceName = name;
    }
    getSpaceName = () => {
        return this.data.spaceName;
    }
    setSpaceId = (spaceId) => {
        this.data.spaceId = spaceId;
    }
    getSpaceId = () => {
        return this.data.spaceId;
    }
}
export default Space;