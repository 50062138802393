import WorkspaceManager from "../bae-client/WorkspaceManager";

export const fetchWorkspaceListData = (options) => {
    return async (dispatch) => {
        const _wm = new WorkspaceManager(options);
        const response = await _wm.loadWorkspaceListByAPI();
        dispatch({
            type: "FETCH_WORKSPACE_LIST",
            payload: response,
        });
    };
};

export const updateWorkspaceName = (options) => {
    return async (dispatch) => {
        const _wm = new WorkspaceManager();
        const response = await _wm.updateWorkspaceName(options.workspaceId);
        dispatch({
            type: "UPDATE_WORKSPACE_NAME",
            payload: response,
        });
    };
};

export const addWorkspace = (options, flag) => {
    if (flag) {
        return {
            type: "CHANGE_FLAG",
        };
    } else
        return async (dispatch) => {
            const workspaceOptions = options;
            const _wm = new WorkspaceManager();
            const response = await _wm.addNewWorkspace({
                workspaceName: workspaceOptions.workspaceName,
            });
            dispatch({
                type: "ADD_WORKSPACE",
                payload: response,
            });
            return response
        };
};

export const resetWorkspaceList = () => {
    return {
        type: "RESET_WORKSPACE",
        payload: null,
    };
};
export const setWorkspaceIdToNameMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "WORKSPACE_ID_TO_NAME_MAP",
            payload: response,
        });
    };
};
export const setWorkspaceNameToIdMap = (response) => {
    return function (dispatch) {
        dispatch({
            type: "WORKSPACE_NAME_TO_ID_MAP",
            payload: response,
        });
    };
};
