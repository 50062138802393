import React, { Component } from "react";
import {
    getBezierPath,
    getMarkerEnd,
    getSmoothStepPath,
    getEdgeCenter,
} from "react-flow-renderer";
import CTDocumentMeta from "./CTDocumentMeta";
const UCLine = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
}) => {
    const foreignObjectSize = (!data.documentListDisplay) ? 50 : 160;
    const smoothEdgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const stepEdgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: 0, // For step edge border radius is 0
    });
    const bezierEdgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });
    const linkImage = data.linkImage
        ? `./images/ControlTower/${data.exceptionIcon
            ? data.linkImage + "_Exception"
            : data.linkImage
        }.png`
        : null;
    let edgePath = null;
    switch (data.linkType) {
        case "default":
            edgePath = bezierEdgePath;
            break;
        case "smooth":
            edgePath = smoothEdgePath;
            break;
        case "step":
            edgePath = stepEdgePath;
            break;
        case "straight":
            edgePath = bezierEdgePath;
            break;
        default:
            break;
    }
    const getPath = () => {
        if (data.linkType === "straight") {
            return (
                <line
                    x1={sourceX}
                    y1={sourceY}
                    x2={targetX}
                    y2={targetY}
                    stroke="#A5A5A5"
                />
            );
        } else {
            return (
                <path
                    id={id}
                    style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    targetY={targetY}
                    targetX={targetX}
                    markerEnd={markerEnd}
                />
            );
        }
    };
    const handClick = (text) => {
        alert(text);
    };
    return (
        <>
            {getPath()}
            {data.docList && data.docList.length ? (
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={edgeCenterX - foreignObjectSize / 2}
                    y={edgeCenterY - foreignObjectSize / 2}
                    color={data.style ? "red" : ""}
                    style={
                        data.exception
                            ? {
                                border: "5px solid royalblue",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }
                            : { cursor: "pointer" }
                    }
                >
                    {(!data.documentListDisplay) ?
                        <img
                            src={linkImage}
                            style={{ marginTop: "15px", marginLeft: "15px" }}
                        ></img>
                        : <>
                            <CTDocumentMeta
                                sourceImage={linkImage}
                                title={data.docList[0]}
                                noOfDocuments={data.docList.length}
                                documents={data.docList}
                                arrowDirection={'Left'}
                            ></CTDocumentMeta>
                            <img
                                src={linkImage}
                                style={{ marginTop: "20px", marginLeft: "70px" }}
                            ></img>
                            <CTDocumentMeta
                                sourceImage={linkImage}
                                title={data.docList[0]}
                                noOfDocuments={data.docList.length}
                                documents={data.docList}
                                arrowDirection={data.arrowDirection}
                            ></CTDocumentMeta>
                        </>
                    }
                    {/* <body
                        style={{
                            paddingLeft: "4px",
                            backgroundColor: data.style ? "red" : "",
                        }}
                    >
                        <img src={linkImage}></img>
                    </body> */}
                </foreignObject>
            ) : null}
            {/* <text>
                <textPath
                    href={`#${id}`}
                    style={{ fontSize: "12px" }}
                    startOffset="50%"
                    textAnchor="middle"
                >
                    {data.text}
                </textPath>
            </text> */}
        </>
    );
};

export default UCLine;
