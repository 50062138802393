import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    setCollabInfo,
    setBlockchainResponse,
} from "../../../../../../actions";
import ReactFlow, { ControlButton, Controls } from "react-flow-renderer";
import UCCircle from "../../../../../stylized/graph/shapes/UCCircle";
import UCLine from "../../../../../stylized/graph/shapes/UCLine";
import ControlTower from "../../../../../../bae-client/ControlTower";
import TimelineView from "../../../TimlelineView";
import FileList from "../ControlTowerView/DocumentDetailsSection";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../../stylized/DocViwer";
import WebGridInterface from "../../../../WebGridInterface";
import { mapResponseToElements, getFormattedDate } from "./helper";
import AlertDialog from "../../../../../stylized/AlertDialog";
import http from "../../../../../../bae-client/HTTP";
import Loader from "../../../../../stylized/Loader";
const controlTower = new ControlTower();

const ControlTowerView = ({
    genericId,
    instanceDetailsTblId,
    instanceInformationTblId,
    instanceId,
    settings,
    saveClick,
    restoreClick,
    setBlockchainResponse,
}) => {
    // BAE client class to be called for fetching API
    const [showLoader, setShowLoader] = useState(false);
    const [elements, setElements] = useState([]);
    const [loadGeneric, setLoadGeneric] = useState(false);
    const [reactFlowInstance, setReactFlowInstance] = useState(false);
    const [isFileListCollapsed, setFileListCollapsed] = useState(false);
    const [instance, setInstance] = useState([]);
    const [docDetails, setDocDetails] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    // For Document modal display
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [lastParticipatingNode, setLastParticipatingNode] = useState("");
    const [timelineExpanded, setTimelineExpanded] = useState(true);
    const [exceptions, setExceptions] = useState([]);
    const [exceptionDetails, setExceptionDetails] = useState([]);
    const [nodeLinkDefinition, setNodeLinkDefinition] = useState([]);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [updateInstanceDetailsCuboid, setUpdateInstanceDetailsCuboid] =
        useState(false);
    const [documentListDisplay, setDocumentListDisplay] = useState(false);
    const [showAlertModal, setshowAlertModal] = useState(false);
    const instanceDetails = useRef([]);
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    var errMsgTitle = useRef("");
    var errMsgBody = useRef("");
    //const { transform } = useZoomPanHelper();
    const setTimelineView = () => {
        setTimelineExpanded(!timelineExpanded);
    };
    useEffect(() => {
        setDocumentList([]);
        setShowLoader(true);
        if (genericId) {
            controlTower
                .retrieveNodeLink(genericId)
                .then((response) => {
                    setShowLoader(false);
                    setElements([]);
                    setNodeLinkDefinition(response);
                    const elements = mapResponseToElements(response);
                    if (
                        elements &&
                        response &&
                        response.nodeDefinition &&
                        response.linkDefinition
                    ) {
                        setElements(elements);
                        setLoadGeneric(true);
                    } else {
                        setElements([]);
                    }
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                    setShowLoader(false);
                });
        }
    }, [genericId, restoreClick]);

    const converUTCDatetoLocalDate = (utcDate) => {
        let localDate = new Date(utcDate + " UTC");
        return localDate;
    };

    const getSourceEntity = (exc) => {
        if (instanceDetails.current.length) {
            let sourceEntityStep = instanceDetails.current.filter(
                (step) => step.instanceStepId === exc.instanceStepId
            );
            return sourceEntityStep[0].from;
        }
        return;
    };

    const getTargetEntity = (exc) => {
        if (instanceDetails.current.length) {
            let targetEntityStep = instanceDetails.current.filter(
                (step) => step.instanceStepId === exc.instanceStepId
            );
            return targetEntityStep[0].to;
        }
        return;
    };

    useEffect(() => {
        setDocDetails([]);
        setExceptionDetails([]);
        setShowLoader(true);
        instanceId &&
            loadGeneric &&
            controlTower
                .retrieveInstanceDetails(genericId, instanceId)
                .then((response) => {
                    setShowLoader(false);
                    instanceDetails.current = response;
                    if (elements && elements.length !== 0) {
                        const nodeNames = elements.map((element) => element.id);
                        // response.sort((a, b) =>
                        // converUTCDatetoLocalDate(a.actualDate).getTime() > converUTCDatetoLocalDate(b.actualDate).getTime()
                        //         ? 1
                        //         : converUTCDatetoLocalDate(b.actualDate).getTime() > converUTCDatetoLocalDate(a.actualDate).getTime()
                        //         ? -1
                        //         : 0
                        // );
                        const participatingNodes = []; //response.filter(

                        //    (step) => step.durationStatus !== "PENDING"

                        //);
                        //
                        let instanceBlockChainList = [];
                        response.forEach((element) => {
                            if (
                                !instanceBlockChainList.includes(element.from)
                            ) {
                                instanceBlockChainList.push(element.from);
                            }
                            if (!instanceBlockChainList.includes(element.to)) {
                                instanceBlockChainList.push(element.to);
                            }
                            if (element.durationStatus !== "PENDING") {
                                if (
                                    !participatingNodes.includes(element.from)
                                ) {
                                    participatingNodes.push(element.from);
                                }
                                if (!participatingNodes.includes(element.to)) {
                                    participatingNodes.push(element.to);
                                }
                            }
                        });
                        instanceBlockChainList.filter((element) =>
                            nodeNames.includes(element)
                        );

                        setLastParticipatingNode("");
                        setShowLoader(true);
                        controlTower
                            .retrieveExceptions(genericId, instanceId)
                            .then((exceptionResponse) => {
                                setShowLoader(false);
                                setExceptions(exceptionResponse);
                                let exceptionNode = [];
                                let exceptionLinkSource = [];
                                let exceptionLinkTarget = [];

                                if (exceptionResponse.length) {
                                    exceptionNode = exceptionResponse
                                        .filter(
                                            (exc) =>
                                                exc.exceptionType ===
                                                    "SKIPPED" ||
                                                exc.exceptionType ===
                                                    "KEY MISSING" ||
                                                exc.exceptionType ===
                                                    "KEY MISMATCH"
                                        )
                                        .map((exc) => getSourceEntity(exc));
                                    exceptionLinkSource = exceptionResponse
                                        .filter(
                                            (exc) =>
                                                exc.exceptionType === "DELAYED"
                                        )
                                        .map((exc) => getSourceEntity(exc));
                                    exceptionLinkTarget = exceptionResponse
                                        .filter(
                                            (exc) =>
                                                exc.exceptionType === "DELAYED"
                                        )
                                        .map((exc) => getTargetEntity(exc));
                                }
                                const newElements = elements.map((element) => {
                                    if (element.source) {
                                        if (
                                            exceptionLinkSource.includes(
                                                element.source
                                            ) &&
                                            exceptionLinkTarget.includes(
                                                element.target
                                            )
                                        ) {
                                            return {
                                                ...element,
                                                data: {
                                                    ...element.data,
                                                    exceptionIcon: true,
                                                    exception: false,
                                                    arrowDirection:
                                                        element.arrowDirection,
                                                    documentListDisplay:
                                                        documentListDisplay,
                                                    docList: response
                                                        .filter(
                                                            (i) =>
                                                                i.from ===
                                                                    element.source &&
                                                                i.to ===
                                                                    element.target
                                                        )
                                                        .map(
                                                            (i) =>
                                                                i.instanceBlockDocumentName
                                                        ),
                                                },
                                                style: { stroke: "red" },
                                            };
                                        } else {
                                            return {
                                                ...element,
                                                data: {
                                                    ...element.data,
                                                    exceptionIcon: false,
                                                    exception: false,
                                                    arrowDirection:
                                                        element.arrowDirection,
                                                    documentListDisplay:
                                                        documentListDisplay,
                                                    docList: response
                                                        .filter(
                                                            (i) =>
                                                                i.from ===
                                                                    element.source &&
                                                                i.to ===
                                                                    element.target
                                                        )
                                                        .map(
                                                            (i) =>
                                                                i.instanceBlockDocumentName
                                                        ),
                                                },
                                                style: {},
                                            };
                                        }
                                    }
                                    if (
                                        instanceBlockChainList.includes(
                                            element.id
                                        ) &&
                                        participatingNodes.includes(element.id)
                                    ) {
                                        let lastNode =
                                            participatingNodes[
                                                participatingNodes.length - 1
                                            ];
                                        setLastParticipatingNode(lastNode);
                                        // if (

                                        //     element.id ===
                                        //     instanceBlockChainList.slice(participatingNodes.length)[0]
                                        // ) {
                                        //     setLastParticipatingNode(
                                        //         element.id
                                        //     );
                                        //     lastNode = element.id;
                                        // }
                                        return {
                                            ...element,
                                            data: {
                                                ...element.data,
                                                exception:
                                                    exceptionNode.includes(
                                                        element.id
                                                    )
                                                        ? "red"
                                                        : "",
                                                style: {
                                                    ...element.data.style,
                                                    border: exceptionNode.includes(
                                                        element.id
                                                    )
                                                        ? "5px solid red"
                                                        : "5px solid mediumseagreen",
                                                },
                                                backgroundColor:
                                                    element.data.color,
                                            },
                                            style: {
                                                ...element.style,
                                                backgroundColor:
                                                    lastNode === element.id
                                                        ? "mediumseagreen"
                                                        : "white",
                                            },
                                        };
                                    } else
                                        return {
                                            ...element,
                                            data: {
                                                ...element.data,
                                                exception:
                                                    exceptionNode.includes(
                                                        element.id
                                                    ),
                                                style: {
                                                    ...element.data.style,
                                                    border: exceptionNode.includes(
                                                        element.id
                                                    )
                                                        ? "5px solid red"
                                                        : "5px solid royalblue",
                                                },
                                            },
                                            style: {
                                                ...element.style,
                                                backgroundColor: "white",
                                            },
                                        };
                                });
                                setElements(newElements);
                                let list = [];
                                const arr = response.map(
                                    (instanceBlockChain) => {
                                        if (
                                            instanceBlockChain.instanceBlockDocumentId &&
                                            instanceBlockChain.instanceBlockDocumentId >
                                                0
                                        ) {
                                            let obj = {};
                                            obj["document"] =
                                                instanceBlockChain.instanceBlockDocumentName;
                                            obj["documentId"] =
                                                instanceBlockChain.instanceBlockDocumentId;
                                            if (instanceBlockChain.actualDate) {
                                                let stringDate =
                                                    getFormattedDate(
                                                        instanceBlockChain.actualDate
                                                    );
                                                obj["date"] =
                                                    instanceBlockChain.actualDate; //stringDate;
                                            } else {
                                                obj["date"] = "Undefined";
                                            }
                                            obj["genericStepName"] =
                                                instanceBlockChain.genericStepName;
                                            obj["from"] =
                                                instanceBlockChain.from;
                                            obj["to"] = instanceBlockChain.to;
                                            let nodeName =
                                                instanceBlockChain.from;
                                            if (
                                                exceptionNode.includes(nodeName)
                                            ) {
                                                obj["exception"] = true;
                                            }
                                            list.push(obj);
                                        }
                                        return instanceBlockChain;
                                    }
                                );
                                setDocumentList(list);
                                setInstance(response);
                                setBlockchainResponse(response);
                            })
                            .catch((err) => {
                                setShowAlertDialog(true);
                                setAlertDialogMessage(err.error);
                                console.log(err);
                                setShowLoader(false);
                            });
                    }
                });
    }, [instanceId, loadGeneric, documentListDisplay]);
    const renderViewDocumentModal = () => {
        return (
            <DocViwer
                docName={selectedDocument}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const closeDocumentDisplayModal = () => {
        setShowDocumentModal(false);
        // setCurrentShowFileLocation("");
    };
    const renderDocumentDisplayModal = (documentId) => {
        setShowLoader(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                setShowLoader(false);
                setSelectedDocument(response.documentName);
                setCurrentShowFileLocation(response);
                setShowDocumentModal(true);
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    useEffect(() => {
        if (genericId && instanceId) {
            setShowLoader(true);
            controlTower
                .updateInstanceDetailsCuboid(genericId, instanceId)
                .then((response) => {
                    setShowLoader(false);
                    if (!updateInstanceDetailsCuboid)
                        setUpdateInstanceDetailsCuboid(true);
                })
                .catch((error) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                });
        }
    }, [genericId, instanceId]);
    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [timelineExpanded, isFileListCollapsed]);
    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [genericId, instanceId]);
    useEffect(() => {
        let timerFunc = setTimeout(() => {
            if (reactFlowInstance) {
                reactFlowInstance.fitView();
            }
        }, 500);
        return () => {
            clearTimeout(timerFunc);
        };
    }, [genericId]);
    const handleOnSaveClick = () => {
        setShowLoader(true);
        let ele = reactFlowInstance.toObject();
        let nodeDefinition = nodeLinkDefinition.nodeDefinition;
        if (ele.elements) {
            let payload = [];
            nodeDefinition.forEach((node, index) => {
                let nodeId = node.nodeId;
                let nodePosition =
                    ele.elements[index].position.x +
                    ";" +
                    ele.elements[index].position.y;
                let object = {
                    nodeId: nodeId,
                    position: nodePosition,
                };
                payload.push(object);
            });
            controlTower
                .updateNodePositions(genericId, payload)
                .then(() => {
                    showAlertView("", "Positions saved");
                    setShowLoader(false);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    console.log(err);
                    setShowLoader(false);
                });
        }
    };
    useEffect(() => {
        if (reactFlowInstance) {
            handleOnSaveClick();
        }
    }, [saveClick]);
    const nodeTypes = {
        //rectangle: RFRectangleShape,
        circle: UCCircle,
        //square: RFSquareShape
    };
    const edgeTypes = {
        custom: UCLine,
    };
    const onElementClick = (e, node) => {
        const nodeExceptionDetails = exceptions.filter(
            (exception) =>
                (exception.exceptionType === "SKIPPED" ||
                    exception.exceptionType === "KEY MISSING" ||
                    exception.exceptionType === "KEY MISMATCH") &&
                getSourceEntity(exception) === node.id
        );
        const linkExceptionDetails = exceptions.filter(
            (exception) =>
                exception.exceptionType === "DELAYED" &&
                getSourceEntity(exception) === node.source &&
                getTargetEntity(exception) === node.target
        );
        if (nodeExceptionDetails.length)
            setExceptionDetails(nodeExceptionDetails);
        else if (linkExceptionDetails.length)
            setExceptionDetails(linkExceptionDetails);
        else setExceptionDetails([]);
        setElements(
            elements.map((item) => {
                if (item.source) {
                    if (
                        linkExceptionDetails.length &&
                        item.source ===
                            getSourceEntity(linkExceptionDetails[0]) &&
                        item.target === getTargetEntity(linkExceptionDetails[0])
                    ) {
                        return {
                            ...item,
                            data: { ...item.data, exception: true },
                        };
                    } else {
                        return {
                            ...item,
                            data: { ...item.data, exception: false },
                        };
                    }
                } else {
                    return {
                        ...item,
                        style: {
                            ...item.style,
                            backgroundColor:
                                item.style.backgroundColor ===
                                    "mediumseagreen" ||
                                item.id === lastParticipatingNode
                                    ? "mediumseagreen"
                                    : "white",
                        },
                    };
                }
            })
        );
        const vendorName = node.id;
        const docList = instance
            .filter((i) => i.from === vendorName)
            .map((i, index) => {
                return {
                    name: `${i.instanceBlockDocumentName}`,
                    receiver: i.to,
                    date: i.documentDate && getFormattedDate(i.documentDate),
                    id: i.instanceBlockDocumentId,
                    //new Date(i.documentDate).toLocaleDateString(),
                };
            });
        const len = docList.length;
        for (let i = 0; i < len; i++) {
            docList.push({
                name: "",
                receiver: "",
                date: "",
            });
        }
        setElements((prevState) => {
            return prevState.map((item) => {
                if (item.id === vendorName) {
                    return {
                        ...item,
                        style: {
                            ...item.style,
                            backgroundColor: "royalblue",
                        },
                    };
                } else return item;
            });
        });
        setDocDetails(docList);
        //docList.length && setShow(true);
    };

    const handleFileListView = () => {
        setFileListCollapsed(!isFileListCollapsed);
    };

    // This is workaround implementation of refresh exception details for the DLA demo
    const refreshExceptionDetails = (prevExceptionDetails) => {
        if (prevExceptionDetails.length) {
            setShowLoader(true);
            controlTower
                .retrieveExceptions(genericId, instanceId)
                .then((exceptionResponse) => {
                    setShowLoader(false);
                    setExceptions(exceptionResponse);
                    const nodeExceptionDetails = exceptionResponse.filter(
                        (exception) =>
                            (prevExceptionDetails[0].exceptionType ===
                                "SKIPPED" ||
                                prevExceptionDetails[0].exceptionType ===
                                    "KEY MISSING" ||
                                prevExceptionDetails[0].exceptionType ===
                                    "KEY MISMATCH") &&
                            (exception.exceptionType === "SKIPPED" ||
                                exception.exceptionType === "KEY MISSING" ||
                                exception.exceptionType === "KEY MISMATCH") &&
                            getSourceEntity(exception) ===
                                getSourceEntity(prevExceptionDetails[0]) &&
                            (!getTargetEntity(prevExceptionDetails[0]) ||
                                getTargetEntity(exception) ===
                                    getTargetEntity(prevExceptionDetails[0]))
                    );
                    const linkExceptionDetails = exceptionResponse.filter(
                        (exception) =>
                            prevExceptionDetails[0].exceptionType ===
                                "DELAYED" &&
                            exception.exceptionType === "DELAYED" &&
                            getSourceEntity(exception) ===
                                getSourceEntity(prevExceptionDetails[0]) &&
                            getTargetEntity(exception) ===
                                getTargetEntity(prevExceptionDetails[0])
                    );
                    if (nodeExceptionDetails.length)
                        setExceptionDetails(nodeExceptionDetails);
                    else if (linkExceptionDetails.length)
                        setExceptionDetails(linkExceptionDetails);
                    else setExceptionDetails([]);
                })
                .catch((err) => {
                    setShowLoader(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                });
        }
    };

    const reactFlowWrapperHeight = settings
        ? "80%"
        : timelineExpanded
        ? "50vh"
        : "70vh";
    const reactFlowHeight = timelineExpanded ? "50vh" : "60vh";
    const hideAlertView = () => {
        setshowAlertModal(false);
    };
    const showAlertView = (title, body) => {
        errMsgTitle.current = title;
        errMsgBody.current = body;
        setshowAlertModal(true);
    };
    const renderAlertModal = () => {
        return (
            <AlertDialog
                open={showAlertModal}
                setOpen={hideAlertView}
                title={errMsgTitle.current}
                message={errMsgBody.current}
            ></AlertDialog>
        );
    };
    return (
        <>
            <div
                id="control-tower-view"
                data-testid="control-tower-view"
                className="doCenter"
                style={{
                    height: reactFlowWrapperHeight,
                    display: "flex",
                    margin: "5px",
                }}
            >
                <div
                    className="doCenter"
                    style={{
                        width: settings
                            ? "100%"
                            : isFileListCollapsed
                            ? "94%"
                            : "74%",
                        height: "inherit",
                        marginLeft: "25px",
                        flexDirection: "column",
                        border: "1px solid #A5A5A5",
                        borderRadius: "10px",
                        margin: "0px",
                        marginTop: "5px",
                    }}
                >
                    <ReactFlow
                        id={"ReactFlowDiagram"}
                        elements={elements}
                        onElementClick={onElementClick}
                        onLoad={setReactFlowInstance}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        zoomOnScroll={true}
                        defaultZoom={elements.length > 35 ? 0.3 : 0.5}
                        minZoom={elements.length > 35 ? 0.1 : 0.3}
                        maxZoom={1}
                        nodesDraggable={settings ? true : false}
                        style={{
                            height: reactFlowHeight,
                            margin: "0px",
                        }}
                    >
                        {genericId ? (
                            <Controls
                                style={{ border: "1px solid black" }}
                                showFitView={true}
                                showInteractive={false}
                            >
                                <ControlButton
                                    onClick={() =>
                                        setDocumentListDisplay(
                                            !documentListDisplay
                                        )
                                    }
                                >
                                    {!documentListDisplay ? (
                                        <i class="fas fa-list"></i>
                                    ) : (
                                        <i class="fas fa-image"></i>
                                    )}
                                </ControlButton>
                            </Controls>
                        ) : null}
                    </ReactFlow>
                </div>
                {settings ? null : (
                    <div
                        style={{
                            width: isFileListCollapsed ? "5%" : "35%",
                            height: "inherit",
                            border: "1px solid #A5A5A5",
                            borderRadius: "10px",
                            margin: "5px 0 0 5px",
                        }}
                    >
                        <FileList
                            genericId={genericId}
                            instanceId={instanceId}
                            docDetails={docDetails}
                            exceptionDetails={exceptionDetails}
                            handleFileListView={handleFileListView}
                            isFileListCollapsed={isFileListCollapsed}
                            refreshExceptionDetails={refreshExceptionDetails}
                            renderDocumentDisplayModal={
                                renderDocumentDisplayModal
                            }
                        />
                    </div>
                )}
            </div>
            {settings ? null : (
                <TimelineView
                    documentList={documentList ? documentList : []}
                    onNodeClick={renderDocumentDisplayModal}
                    timelineExpanded={timelineExpanded}
                    marginMultiplier={
                        documentList.length > 12 ? documentList.length % 12 : 0
                    }
                    setTimelineView={setTimelineView}
                ></TimelineView>
            )}
            {settings ||
            (instanceDetailsTblId &&
                updateInstanceDetailsCuboid &&
                instanceId) ? (
                <div
                    style={{
                        border: "1px solid #A5A5A5",
                        borderRadius: "10px",
                        margin: "8px",
                    }}
                >
                    <WebGridInterface
                        cuboidId={instanceDetailsTblId}
                        options={{
                            functionPosition: "right",
                            blockChainId: "",
                            instanceInformationTblId: instanceInformationTblId,
                            instanceId: instanceId,
                        }}
                    />
                </div>
            ) : null}
            {showDocumentModal ? (
                <DocumentViewerModal
                    showHeader={true}
                    footer="false"
                    show={showDocumentModal}
                    size="lg"
                    title={selectedDocument}
                    onHide={closeDocumentDisplayModal}
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={selectedDocument}
                    fileSource={currentShowFileLocation}
                >
                    {renderViewDocumentModal()}
                </DocumentViewerModal>
            ) : null}
            {renderAlertModal()}
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
};
const mapDispatchToProps = () => {
    return {
        setCollabInfo,
        setBlockchainResponse,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(ControlTowerView);
//export default ControlTowerView;
