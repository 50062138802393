import {
    createAction,
    createAsyncThunk,
    createReducer,
} from "@reduxjs/toolkit";
import { _BASE_QUERY, _REST_APP, _URL } from "../../constants";
import http from "../../../bae-client/HTTP";

export const setActivePageAction = createAction("SET_ACTIVE_PAGE");
export const setActiveWorkspaceAction = createAction("SET_ACTIVE_WORKSPACE");
export const setWorkspaceListAction = createAction("SET_WORKSPACE_LIST");
export const setAddWorkspaceModalAction = createAction(
    "SET_ADD_WORKSPACE_MODAL"
);
export const setSpaceListAction = createAction("SET_SPACE_LIST");
export const setSpaceListDataAction = (data) => {
    return {
        type: "SET_SPACE_LIST_DATA",
        payload: data,
    };
};
export const setWorkspaceIdToWorkspaceNameAction = createAction(
    "SET_WORKSPACE_ID_TO_WORKSPACE_NAME"
);
export const setWorkspaceNameToWorkspaceIdAction = createAction(
    "SET_WORKSPACE_NAME_TO_WORKSPACE_ID"
);
export const setActiveSpaceAction = createAction("SET_ACTIVE_SPACE");
export const setSnackbarAction = createAction("SET_SNACKBAR");
export const setWorkspaceRedirectionAction = createAction(
    "SET_WORKSPACE_REDIRECTION"
);
export const setMembersRefreshAction = createAction("SET_MEMBERS_REDIRECTION");
export const setEmailRecievedAction = createAction("SET_EMAIL_RECIEVED");
export const setRefreshLocation = createAction("SET_LOCATION_DATA");
export const setRippleFilterAction = createAction("SET_RIPPLE_FILTER_DATA");

export const fetchModulesAction = createAsyncThunk(
    "FETCH_MODULES",
    async () => {
        const response = await fetch(http.baseURL + "rest/modules/list", {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        });
        return response.json();
    }
);
export const fetchWorkspaceListAction = createAsyncThunk(
    "FETCH_WORKSPACE_LIST",
    async () => {
        const response = await http.sendRestRequest("workspace/workspaceList");
        return response;
    }
);
export const fetchSpaceListAction = createAsyncThunk(
    "FETCH_SPACE_LIST",
    async (payload) => {
        const response = await http.sendRestRequest(
            `workspace/spaceList?workspaceId=${payload}`
        );
        return response;
    }
);
export const addSpaceAction = createAsyncThunk("ADD_SPACE", async (payload) => {
    try {
        const response = await http.sendRestRequest(
            `space/space?workspaceId=${payload.workspaceId}`,
            "POST",
            { spaceName: payload.spaceName }
        );
        return response;
    } catch (err) {
        return err;
    }
});

export const fetchDirectoriesAction = createAsyncThunk(
    "FETCH_DIRECTORIES",
    async (payload) => {
        const response = await http.sendRestRequest("directory/list");
        return response;
    }
);

export const fetchDirectoryUsersAction = createAsyncThunk(
    "FETCH_DIRECTORY_USERS",
    async (payload) => {
        const response = await http.sendRestRequest(
            `directory/user/list?directoryId=${payload}`
        );
        return response;
    }
);

const activePageReducer = createReducer("Home", (builder) => {
    builder.addCase(setActivePageAction, (state, action) => action.payload);
});

const modulesReducer = createReducer([], (builder) => {
    builder.addCase(
        fetchModulesAction.fulfilled,
        (state, action) => action.payload
    );
});

const activeWorkspaceReducer = createReducer(null, (builder) => {
    builder.addCase(
        setActiveWorkspaceAction,
        (state, action) => action.payload
    );
});

const spaceListReducer = createReducer([], (builder) => {
    builder.addCase(setSpaceListAction, (state, action) => action.payload);
    builder.addCase(
        fetchSpaceListAction.fulfilled,
        (state, action) => action.payload
    );
    builder.addCase("SET_SPACE_LIST_DATA", (state, action) => action.payload);
});

const workspaceListReducer = createReducer([], (builder) => {
    builder.addCase(
        fetchWorkspaceListAction.fulfilled,
        (state, action) => action.payload
    );
});

const addWorkspaceModalReducer = createReducer(false, (builder) => {
    builder.addCase(
        setAddWorkspaceModalAction,
        (state, action) => action.payload
    );
});
const workspaceIdToNameReducer = createReducer(null, (builder) => {
    builder.addCase(
        setWorkspaceIdToWorkspaceNameAction,
        (state, action) => action.payload
    );
});

const workspaceNameToIdReducer = createReducer(null, (builder) => {
    builder.addCase(
        setWorkspaceNameToWorkspaceIdAction,
        (state, action) => action.payload
    );
});

const activeSpaceReducer = createReducer(null, (builder) => {
    builder.addCase(setActiveSpaceAction, (state, action) => action.payload);
});

const directoriesReducer = createReducer([], (builder) => {
    builder.addCase(
        fetchDirectoriesAction.fulfilled,
        (state, action) => action.payload
    );
});

const directoryUsersReducer = createReducer([], (builder) => {
    builder.addCase(
        fetchDirectoryUsersAction.fulfilled,
        (state, action) => action.payload
    );
});

const snackbarReducer = createReducer(
    {
        open: false,
        message: "",
        severity: "success",
    },
    (builder) => {
        builder.addCase(setSnackbarAction, (state, action) => {
            state.open = action.payload.open;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        });
    }
);

const workspaceRedirectionReducer = createReducer(null, (builder) => {
    builder.addCase(
        setWorkspaceRedirectionAction,
        (state, action) => action.payload
    );
});

const emailRecievedReducer = createReducer(null, (builder) => {
    builder.addCase(setEmailRecievedAction, (state, action) => action.payload);
});

const rippleFilterReducer = createReducer(null, (builder) => {
    builder.addCase(setRippleFilterAction, (state, action) => action.payload);
});

export {
    activePageReducer,
    modulesReducer,
    activeWorkspaceReducer,
    workspaceListReducer,
    spaceListReducer,
    workspaceIdToNameReducer,
    workspaceNameToIdReducer,
    activeSpaceReducer,
    addWorkspaceModalReducer,
    snackbarReducer,
    directoriesReducer,
    directoryUsersReducer,
    workspaceRedirectionReducer,
    emailRecievedReducer,
    rippleFilterReducer,
};
