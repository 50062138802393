import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    _URL,
    _REST_APP,
    _SPACE_ENDPOINT,
    _DELETE,
    _PUT,
    _POST,
} from "../../constants";
import http from "../../../bae-client/HTTP";

export const spaceAPI = createApi({
    reducerPath: "Space",
    baseQuery: fetchBaseQuery({
        baseUrl: http.baseURL,
        /**
         * Prepares the headers for the HTTP request.
         *
         * @param {Headers} headers - The headers to be prepared
         * @returns {Headers} - The prepared headers
         */
        prepareHeaders: (headers) => {
            // Set Access-Control-Allow-Origin to *
            headers.set("Access-Control-Allow-Origin", "*");
            // Set Content-Type to application/json
            headers.set("Content-Type", "application/json");
            // Set Authorization to the defined sessionStorage.getItem("token") value
            headers.set("Authorization", sessionStorage.getItem("token"));
            return headers;
        },
        tagTypes: ["Space"],
    }),
    endpoints: (builder) => ({
        /**
         * Query for spaces
         * @param {function} query - The query function
         */
        spaces: builder.query({
            query: (workspaceId) =>
                `${_SPACE_ENDPOINT}?workspaceId=${workspaceId}`,
        }),
        createSpace: builder.mutation({
            query: (payload) => {
                const { workspaceId, spaceName } = payload;
                return {
                    url: `${_SPACE_ENDPOINT}?workspaceId=${workspaceId}`,
                    method: _POST,
                    body: {
                        spaceName,
                    },
                };
            },
            invalidatesTags: ["Space"],
        }),
        deleteSpace: builder.mutation({
            /**
             * Function to generate a DELETE request options
             * @param {object} payload - The payload for the DELETE request
             * @returns {object} - The DELETE request options
             */
            query: (payload) => {
                return {
                    url: _SPACE_ENDPOINT,
                    method: _DELETE,
                    body: payload,
                };
            },
            invalidatesTags: ["Space"],
        }),
        updateSpace: builder.mutation({
            query: (payload) => {
                return {
                    url: _SPACE_ENDPOINT,
                    method: _PUT,
                    body: payload,
                };
            },
            invalidatesTags: ["Space"],
        }),
    }),
});

export const { useSpacesQuery } = spaceAPI;
