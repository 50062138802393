import { default as React, useEffect, useState } from "react";
import http from "../../../../../../bae-client/HTTP";
import Loader from "../../../../../stylized/Loader";
import ListViewIntegration from "./ListViewIntegration";
export default function TopPanel({ integrationList, handleSettings }) {
    // const cardlist = [];

    const [integrations, setintegrations] = useState(integrationList);
    const [showLoader, setshowLoader] = useState(false);

    useEffect(() => {
        setintegrations(integrationList);
    }, [integrationList]);
    const handleSettingsBtn = (label) => {
        handleSettings(label);
    };
    const renderIntegrations = () => {
        let integrationCards = [];
        if (integrations.length > 0) {
            integrations.forEach((type) => {
                integrationCards.push(
                    <ListViewIntegration
                        label={type}
                        handleSettings={handleSettingsBtn}
                    ></ListViewIntegration>
                );
            });
            return integrationCards;
        } else {
            return renderEmptyListPage();
        }
    };

    const renderEmptyListPage = () => {
        return (
            <div className="integrationEmptyContainer">
                <div className="row m-0 w-100 doCenter mb-3">
                    <img
                        src="./images/Integration/integration_icon.png"
                        alt=""
                        className="Integration_icon"
                    />
                </div>
                <div className="row m-0 w-100 doCenter">
                    <span class="integration_text">
                        Get started by installing one of our featured
                        integrations below
                    </span>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="row m-0 w-100 doCenter">
                <span className="integration_Title">Your Integrations</span>
            </div>
            <div className="integrationContainer_body pl-4 pr-4">
                {renderIntegrations()}
            </div>
            <Loader show={showLoader}></Loader>;
        </>
    );
}
