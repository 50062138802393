import React from "react";

const AddGeneric = ({ addStep }) => {
    const isReadOnlyUser = sessionStorage.getItem('isReadOnlyUser') === "true";
    return (
        <div className="addGenericContainer">
            <div
                className={isReadOnlyUser ? "readOnly" : "cursorPointer"}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                }}
                onClick={(e) => isReadOnlyUser ? null : addStep()}
            >
                <img
                    title="Add Step"
                    src="./images/Add Space.png"
                    className={isReadOnlyUser ? "readOnly" : "cursorPointer"}
                    id={"img_addstep_flow"}
                    alt=""
                    style={{
                        width: "15px",
                        height: "16px",
                        marginTop: "10px",
                        marginRight: "10px",
                    }}
                />
                <span
                    id={"span_addstep_flow"}
                    className={isReadOnlyUser ? "readOnly" : ""}
                    title="Add Step"
                    style={{ marginTop: "6px" }}
                >
                    Add Step
                </span>
            </div>
        </div>
    );
};

export default AddGeneric;
