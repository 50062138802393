import React, { Component } from "react";
import List from "./List";

class ListView extends Component {
    constructor(props) {
        super(props);
        if (this.props.id === undefined || this.props.id === null)
            this.id = this.props.label.replace(/\s+/, "_");
        else this.id = this.props.id;

        this.indexValue = -1;
        this.state = {
            activeRowIndex: 0,
            activeCellIndex: 0,
            activeItemIndex: false,
        };
        //this.activeRowIndex = 0;
        //this.activeCellIndex = 0;
        this.selectedItems = [];
    }

    onEnterKeyDown = (i, event) => {
        if (event.keyCode === 13) {
            this.handleChange(i, event);
        }
    };
    handleChange = (i, event) => {
        if (this.props.onchange !== undefined && this.props.onchange !== null) {
            this.props.onchange(
                i,
                this.props.items[i].value,
                this.props.items[i].text,
                event
            );
        }
        if (this.state.activeRowIndex != i) {
            this.setState({ activeRowIndex: i });
        }
    };

    handleClick = (i, event) => {
        if (this.props.onclick !== undefined && this.props.onclick !== null)
            this.props.onclick(
                this.props.items[i].value,
                this.props.items[i].text,
                event,
                i
            );
    };

    getIcon() {
        if (this.props.showIcon === "true")
            return (
                <div className="col-1 noPadding">
                    <i className="far fa-file-alt"></i>
                </div>
            );
        return null;
    }
    getButton = (i) => {
        let itemId = this.formulateId(i, 1);
        // if (this.props.showBtn === "true") return <div className="col-2 noPadding iconBtn" onClick={(event) => this.handleClick(i, event)} role="gridcell" id={itemId}><i aria-label="Click to perform action" className="fas fa-link"></i></div>
        // return (<div role="gridcell" id={itemId}>{this.props.children}</div>);
        return (
            <div role="gridcell" id={itemId}>
                {this.props.items[i].actionPanel}
            </div>
        );
    };
    renderOptionsClassname = () => {
        if (this.props.showIcon !== "true" && this.props.showBtn !== "true") {
            return "col-12 p-0 textEllipsis";
        } else if (
            this.props.showIcon === "true" ||
            this.props.showBtn === "true"
        ) {
            return "col-10 p-0 textEllipsis";
        } else if (
            this.props.showIcon === "true" &&
            this.props.showBtn === "true"
        ) {
            return "col-9 p-0 textEllipsis";
        }
    };
    handleUncheckClick = (i, value) => {
        if (this.props.uncheckclick !== undefined) {
            this.props.uncheckclick(i, value);
        }
    };
    renderOptions() {
        const items = this.props.items;
        this.selectedItems = this.props.selectedIndexes;
        let optionElements = [];
        let styleObject = this.props.color ? { color: "#495057" } : {};
        if (this.props.listType === "chk") {
            let selectedIndexes = this.props.selectedIndexes;
            for (let i = 0; i < items.length; i++) {
                let checkedStatus = null;
                if (
                    this.props.selectedIndexes !== undefined &&
                    this.props.selectedIndexes !== null
                ) {
                    //(i in selectedIndexes) ? checkedStatus = true : checkedStatus = false;
                    selectedIndexes.map((item) => {
                        if (i === item) {
                            checkedStatus = true;
                        }
                        return;
                    });
                }
                this.indexValue = items[i].value;
                let itemId = items[i].id;
                optionElements.push(
                    <div className="row" role="row">
                        <div className="col-2 doCenter">
                            <input
                                autocomplete="off"
                                type="checkbox"
                                className="cursor_pointer"
                                onChange={
                                    checkedStatus !== null &&
                                    checkedStatus === true
                                        ? (event) =>
                                              this.handleUncheckClick(
                                                  i,
                                                  items[i].value
                                              )
                                        : (event) => this.handleChange(i, event)
                                }
                                name={items[i].value}
                                value={items[i].value + "_" + items[i].id}
                                id={"checkbox_" + [i]}
                                checked={
                                    checkedStatus === null ||
                                    checkedStatus === undefined
                                        ? false
                                        : checkedStatus
                                }
                            />
                        </div>
                        <div className="col-9 pl-0 pr-0 textEllipsis">
                            <span title={items[i].text}>{items[i].text}</span>
                        </div>
                    </div>
                );
            }
        } else {
            for (let i = 0; i < items.length; i++) {
                this.indexValue = items[i].value;
                // let icon=this.getIcon;
                // let btn=this.getButton;
                let itemId = this.formulateId(i, 0);
                optionElements.push(
                    <div
                        className={
                            this.props.listRowClassname !== undefined
                                ? this.props.listRowClassname
                                : "row"
                        }
                        role="row"
                        onKeyDown={(event) => {
                            this.onEnterKeyDown(i, event);
                        }}
                        onClick={(event) => this.handleChange(i, event)}
                    >
                        {this.getIcon()}
                        <div
                            className={this.renderOptionsClassname()}
                            role="none"
                        >
                            <span
                                className={
                                    this.props.listItemClassname !== undefined
                                        ? this.props.listItemClassname +
                                          " " +
                                          "textEllipsis"
                                        : "textEllipsis"
                                }
                                value={items[i].value}
                                role="gridcell"
                                id={items[i].text}
                                title={items[i].text}
                                // {...((this.props.selectedValue !== undefined && this.props.selectedValue !== null && this.getIndex(this.props.selectedValue) === i) ? { className: "active", "aria-selected": true } : { "aria-selected": false })}> / decide on rowIndex
                                {...(this.state.activeRowIndex === i &&
                                this.props.disableActiveIndex !== "true"
                                    ? {
                                          className: "active ",
                                          "aria-selected": true,
                                      }
                                    : { "aria-selected": false })}
                                style={styleObject}
                            >
                                {items[i].text}
                            </span>
                        </div>
                        {this.getButton(i)}
                    </div>
                );
            }
        }
        return (
            <List
                liClassName={this.props.className}
                role="none"
                items={optionElements}
                activeItemIndex={
                    this.props.disableActiveIndex !== "true"
                        ? this.state.activeRowIndex
                        : ""
                }
            ></List>
        );
    }

    render() {
        return (
            <div className="gridList w-100">
                <div className="">
                    {/* <label id={"label-" + this.id}>{this.props.label}</label> */}
                    <div
                        aria-labelledby={"label-" + this.id}
                        id={this.id}
                        {...(this.props.disabled !== undefined &&
                        this.props.disabled !== null
                            ? {
                                  disabled: this.props.disabled,
                                  "aria-disabled": this.props.disabled,
                              }
                            : null)}
                        className="noOutline"
                        role="grid"
                        aria-activedescendant={this.formulateId(
                            this.state.activeRowIndex,
                            0
                        )}
                        tabIndex="0"
                        onKeyDown={this.handleKeydown}
                    >
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        );
    }

    formulateId(rowIndex, cellIndex) {
        return this.id + "cell-" + rowIndex + "-" + cellIndex;
    }

    navigateLeft() {
        if (this.state.activeCellIndex > 0) {
            //this.state.activeCellIndex--;
            this.setState({ activeCellIndex: this.state.activeCellIndex - 1 });
        }
    }

    navigateRight() {
        if (this.props.showBtn !== "true") return;
        if (this.state.activeCellIndex < 1) {
            //this.state.activeCellIndex++;
            this.setState({ activeCellIndex: this.state.activeCellIndex + 1 });
        }
    }

    navigateUp() {
        if (this.state.activeRowIndex > 0) {
            //this.state.activeRowIndex--;
            this.setState({ activeRowIndex: this.state.activeRowIndex - 1 });
        }
    }

    navigateDown() {
        if (this.state.activeRowIndex < this.props.items.length - 1) {
            //this.state.activeRowIndex++;
            this.setState({ activeRowIndex: this.state.activeRowIndex + 1 });
        }
    }

    handleKeydown = (event) => {
        switch (event.keyCode) {
            case 37:
                this.navigateLeft();
                event.preventDefault();
                break;
            case 38:
                this.navigateUp();
                event.preventDefault();
                break;
            case 39:
                this.navigateRight();
                event.preventDefault();
                break;
            case 40:
                this.navigateDown();
                event.preventDefault();
                break;
            case 13:
            case 32:
                this.handleActivate(event);
                break;
            default:
                return true;
        }
    };

    handleActivate = (event) => {};

    getIndex(value) {
        const items = this.props.items;
        for (let i = 0; i < items.length; i++) {
            if (value.toString() === items[i].value.toString()) return i;
        }
        return -1;
    }
}

export default ListView;
