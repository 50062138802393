import React from "react";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../actions";
import "./Archive.css";
const EmptyRipple = (props) => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div className="makeStyles-root-11 addSpace">
                <Container maxWidth="lg">
                    <div>
                        <img
                            alt="space"
                            className="newSpace"
                            src="./images/Nucleus 5.png"
                        ></img>
                    </div>
                    <div>
                        <h2 className="sText">
                            There are no Ripples to display
                        </h2>
                        <br />

                        <span className="subText">
                            Run your first Ripple to see it displayed here in
                            the log
                        </span>
                    </div>
                    <div
                        className="addBtn cursorPointer"
                        onClick={props.handleAddSpaceClick}
                    >
                        <button
                            title="Create Ripple"
                            class="cursorPointer btn share modalButton"
                            type="button"
                            id="Add Space"
                            value="ADD SPACE"
                            style={{
                                minWidth: "10rem",
                                height: "38px",
                                marginLeft: "10px",
                                background: "rgb(7, 152, 234)",
                                textTransform: "capitalize",
                            }}
                            onClick={() => {
                                dispatch(setActivePage("Ripple"));
                            }}
                        >
                            Create Ripple
                        </button>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmptyRipple;
