import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component } from "react";
import http from "../../../bae-client/HTTP";
import userManager from "../../../bae-client/UserManager";
import AlertDialog from "../../stylized/AlertDialog";
import ChipsView from "../../stylized/ChipsView";
import Loader from "../../stylized/Loader";
import ModalView from "../../stylized/ModalView";
import TextArea from "../../stylized/TextArea";
import TextBox from "../../stylized/TextBox";
import "./ContentPane/Invite/invite.css";
class InviteView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showUserModel: false,
            value: "",
            chipsArray: [],
            invited: false,
            emailError: "",
            messageError: "",
            showAlertDialog: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            isAlert: false,
        };
        this.wholeContacts = null;
        this.selectedUsername = "";
        this.searchText = "";
        this.chipsArray = [];
        this.messageBody = "";
    }
    changeOpenDialogFlag = (flag) => {
        this.setState({ showAlertDialog: flag });
    };
    showAlertView = (title, body) => {
        this.setState({
            showAlertView: true,
            alertDialogMessage: body,
            alertDialogTitle: title,
        });
    };
    componentDidMount() {
        userManager.loadUsers().then((resp) => {
            let allContacts = userManager.getAllProfiles();
            this.wholeContacts = this.parseLoadedContact(allContacts);
        });
    }
    parseLoadedContact = (contactRawList) => {
        let contactList = [];
        contactRawList.forEach((key, index) => {
            contactList.push({
                username: key.firstName + " " + key.lastName,
                userEmail: key.userEmail,
                userId: key.userId,
                company: "Not Specified",
                read: "false",
                chatCount: 0,
                profileImg: "A",
            });
        });
        return contactList;
    };
    addNewContact = (contactName) => {
        this.setState({ showLoader: true });
        this.wholeContacts.forEach((key) => {
            if (key.username === contactName) {
                let userId = "" + key.userId;
                this.setState({ showLoader: false });
            }
        });
    };
    handleIniviteButtonClick = () => {
        this.setState({ showUserModel: true });
    };
    sendInvite = (emails) => {
        let message = "";
        let url = "register/inviteUsers";
        if (this.messageBody !== "") message = this.messageBody;
        else message = "Welcome to the Unity Central!";
        let data = { emailAddresses: emails, message: message };
        this.setState({ showLoader: true, showUserModel: false });
        http.sendRestRegistraionRequest(url, "POST", data)
            .then((response) => {
                this.setState({ showLoader: false, invited: true });
                this.clearData();
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                this.setState({ showLoader: false });
            });
    };
    handleKeyDown = (value, key) => {
        if (value.trim() !== "") {
            this.createChip(value.trim());
        }
    };
    handleSendInviteButtonClick = () => {
        let emails = this.getChipsText();
        if (this.searchText !== "") {
            let isChipCreated = this.createChip(this.searchText.trim());
            if (isChipCreated) {
                emails = this.getChipsText();
                this.sendInvite(emails);
            }
        } else if (this.searchText === "" && emails.length) {
            emails = this.getChipsText();
            this.sendInvite(emails);
        } else if (!emails.length) {
            this.setState({ emailError: "Please add at least One email!" });
            return;
        }
    };
    clearData = () => {
        this.setState({ chipsArray: [] });
        this.chipsArray = [];
        this.searchText = "";
    };
    handleSkipButton = () => {
        this.props.hideDashboard();
    };
    closeModal = () => {
        this.clearData();
        this.setState({ showUserModel: false });
    };
    renderDropdownUI = () => {
        if (this.wholeContacts !== null) {
            return (
                <Autocomplete
                    multiple
                    id="inviteFromLogin"
                    onChange={(event, newValue) => {
                        this.addNewContact(newValue);
                    }}
                    style={{
                        width: "70%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    options={this.wholeContacts.map(
                        (option) => option.username
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Invite someone"
                            variant="outlined"
                        />
                    )}
                />
            );
        }
    };
    onClickRippleChip = (text) => {
        this.searchText = text;
        // this.onClickRipple();
    };
    putTextInChips = (text) => {
        let index = 0;
        index = this.chipsArray.length;
        this.chipsArray.push({
            key: text + "_" + index,
            label: text,
        });
        this.setState({ chipsArray: this.chipsArray });
    };
    handleDeleteChipItem = (chipToDelete, index) => {
        let items = this.chipsArray;
        items.splice(index, 1);
        this.setState({ chipsArray: items });
    };
    setFromText = (value, id, event) => {
        //takes input values from textbox component
        switch (id) {
            case "rippleSearchTextBox":
                {
                    this.searchText = value;
                }
                break;
            case "txt_Invite_messageBody": {
                this.messageBody = value;
                if (this.state.messageError && value)
                    this.setState({ messageError: "" });
            }
            default:
                break;
        }
    };
    getChipsText = () => {
        let str = [];
        this.chipsArray.forEach((element, index) => {
            str.push(element.label.toString());
        });
        return str;
    };
    isValidEmail = (mail) => {
        let mailformat =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (mail.match(mailformat)) {
            return true;
        } else {
            alert("The format of Email Address is not correct.");
            return false;
        }
    };
    createChip = (value) => {
        let isValidEmail = this.isValidEmail(value.trim());
        if (!isValidEmail) {
            return false;
        }
        if (value !== "" && isValidEmail) {
            let flag = false;
            this.chipsArray.forEach((item) => {
                if (item.label === this.searchText) {
                    flag = true;
                }
            });
            if (flag === false) {
                this.putTextInChips(this.searchText);
                this.searchText = "";
                this.setState({ emailError: "" });
            }
            document.getElementById("rippleSearchTextBox").value = "";
            return true;
        }
    };
    renderClassForChips = () => {
        if (this.chipsArray.length <= 3) {
            return "col-" + this.chipsArray.length * 3;
        } else {
            return "col-9";
        }
    };
    renderChipsInputUI = () => {
        return (
            <div className=" w-100 m-0 doCenter" style={{ flexWrap: "wrap" }}>
                <div className="invite_div_box">
                    {/* + this.renderClassForChips() */}
                    <div className="row w-100 m-0">
                        {/* <div
                                        className={
                                            "p-0 chiplistDiv " +
                                            this.renderClassForChips()
                                        }
                                    > */}
                        <ChipsView
                            items={this.state.chipsArray}
                            // onclick={this.onClickRippleChip}
                            ondelete={this.handleDeleteChipItem}
                        ></ChipsView>
                        {/* </div>
                                    <div className="col"> */}
                        <TextBox
                            divClass={"rippleSerachDiv"}
                            className="userSerachInputBox"
                            id="rippleSearchTextBox"
                            clearAfterChange={true}
                            type="text"
                            label={
                                this.state.chipsArray.length === 0
                                    ? "Enter e-mail"
                                    : "Enter another e-mail"
                            }
                            placeholder={true}
                            onkeydown={this.onClickEnterBtn}
                            handleSpecialKeys={this.handleKeyDown}
                            onchange={this.setFromText}
                        ></TextBox>
                    </div>
                    {/* <div
                        className={"p-0 chiplistDiv "}
                        style={{
                            flexDirection:
                                this.chipsArray.length > 2 ? "column" : "row",
                        }}
                    >
                        <ChipsView
                            items={this.state.chipsArray}
                            onclick={this.onClickRippleChip}
                            ondelete={this.handleDeleteChipItem}
                        ></ChipsView>
                        <TextBox
                            className=" getAddress form-control serachInputBox"
                            id="rippleSearchTextBox"
                            clearAfterChange={true}
                            type="text"
                            label="Enter e-mail"
                            placeholder={true}
                            onkeydown={this.onClickEnterBtn}
                            onchange={this.setFromText}
                        ></TextBox>
                    </div> */}
                </div>
                {this.state.emailError && (
                    <>
                        <small style={{ color: "red" }}>
                            {this.state.emailError}
                        </small>
                        <br />
                    </>
                )}
                <br />
            </div>
        );
    };

    renderTextArea = () => {
        if (this.chipsArray.length > 0) {
            return (
                <TextArea
                    divClass="w-100 doCenter"
                    className="invite_msg_box "
                    id="txt_Invite_messageBody"
                    rows="5"
                    cols="25"
                    label={"Write a custom message"}
                    onchange={this.setFromText}
                ></TextArea>
            );
        }
    };
    renderModalBody = () => {
        return (
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div className="row m-0 doCenter mb-4">
                    <span className="invite_modal_title">
                        Invite to Unity Central
                    </span>
                </div>
                {/* {this.renderDropdownUI()} */}
                {this.renderChipsInputUI()}
                {this.renderTextArea()}
                {this.state.messageError && (
                    <>
                        <small style={{ color: "red" }}>
                            {this.state.messageError}
                        </small>
                        <br />
                    </>
                )}
                <br />
                {/* <input
                    type="button"
                    className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                    style={{
                        height: "2.5rem",
                        marginTop: "2.8rem",
                        marginLeft: "0.6rem",
                        marginBottom: "1.5rem",
                    }}
                    value="SEND INVITATION"
                    onClick={this.handleSendInviteButtonClick}
                ></input> */}
                <button
                    type="submit"
                    id="btn_invite_submit"
                    className="btn btn-primary submitBtn mt-3"
                    style={{ fontWeight: "400", maxWidth: "20rem" }}
                    name="btn_signup"
                    onClick={this.handleSendInviteButtonClick}
                >
                    SEND INVITATION
                </button>
            </div>
        );
    };

    renderModal = () => {
        return (
            <ModalView
                show={this.state.showUserModel}
                onHide={this.closeModal}
                footer={"false"}
                size="md"
            >
                {this.renderModalBody()}
            </ModalView>
        );
    };
    renderAlertModel = () => {
        return (
            <ModalView
                show={this.state.invited}
                onHide={this.closeModal}
                footer={"false"}
                size="sm"
            >
                <div
                    className="w-100 doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <div className="row m-0 w-100 doCenter">
                        <span className="modalTitle">Invite Sent</span>
                    </div>
                    <button
                        type="submit"
                        id="btn_invite_closeInviteSuccessModal"
                        className="btn btn-primary submitBtn"
                        style={{
                            fontWeight: "400",
                            maxWidth: "7rem",
                            height: "3rem",
                            marginTop: "2rem",
                        }}
                        onClick={this.handleSkipButton}
                    >
                        DONE
                    </button>
                </div>
            </ModalView>
        );
    };
    render() {
        return (
            <div>
                <div className="row m-0 doCenter">
                    {/* <input
                        type="button"
                        className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton mb-3"
                        style={{ height: "2.5rem" }}
                        value="INVITE MEMBERS"
                        onClick={this.handleIniviteButtonClick}
                    ></input> */}
                    <div className="col"></div>
                    <div className="col-4">
                        <div className="row w-100 mt-3 doCenter m-0">
                            <button
                                type="submit"
                                id="btn_invite_openInviteModal"
                                className="btn btn-primary submitBtn"
                                style={{ fontWeight: "400", maxWidth: "20rem" }}
                                name="btn_signup"
                                onClick={this.handleIniviteButtonClick}
                            >
                                INVITE MEMBERS
                            </button>
                        </div>
                        <div className="row w-100 m-0 doCenter">
                            <span
                                style={{
                                    fontSize: "1.2rem",
                                    lineHeight: "1.3rem",
                                    cursor: "pointer",
                                    marginTop: "3rem",
                                }}
                                onClick={this.handleSkipButton}
                            >
                                SKIP
                            </span>
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
                {this.renderModal()}
                {this.renderAlertModel()}
                <Loader show={this.state.showLoader} />
                <AlertDialog
                    open={this.state.showAlertDialog}
                    message={this.state.alertDialogMessage}
                    title={this.state.alertDialogTitle}
                    isAlert={this.state.isAlert}
                    setOpen={this.changeOpenDialogFlag}
                ></AlertDialog>
            </div>
        );
    }
}

export default InviteView;
