import React from "react";
import ModalView from "../../../../../../stylized/ModalView";
import TextBox from "../../../../../../stylized/TextBox";
import "../../integration.css";

export default function OutlookModal({
    outlookModalShow,
    closeOutlookModal,
    setText,
    newFolderName,
    addNewFolder,
    handleFormShow,
    handleAutherization,
    modalType,
}) {
    return (
        <ModalView
            showHeader="false"
            footer="false"
            show={outlookModalShow}
            size="lg"
            onHide={closeOutlookModal}
            // onExit={(e) => console.log("exit call", e)}
            title=""
            onclick={closeOutlookModal}
            submitButtonText="Close"
        >
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <img
                    src={
                        modalType === "Outlook"
                            ? "images/Outlook-Large.png"
                            : "images/Gmail_icon.png"
                    }
                    alt="Outlook Logo"
                />
                <div className="updateSpaceNameTitle">
                    {modalType === "Outlook" ? "Outlook" : "Gmail"}
                </div>
                <TextBox
                    id="username"
                    className="updateSpaceName outlookModalTextbox paddingLeft"
                    type="text"
                    onchange={setText}
                    label="User name or email"
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
                <TextBox
                    id="password"
                    className="updateSpaceName outlookModalTextbox paddingLeft"
                    type="password"
                    onchange={setText}
                    label="Password"
                    formControl="Off"
                    doCenter={true}
                ></TextBox>
                <input
                    type="button"
                    className="btn btn-light outlookModalButton"
                    style={{
                        height: "40px",
                        marginTop: "45px",
                        marginLeft: "10px",
                    }}
                    disabled={newFolderName === "" ? true : false}
                    value="Authorize"
                    onClick={handleAutherization}
                ></input>
                {/* <input
                    type="button"
                    className="btn btn-light outlookModalButton"
                    style={{
                        height: "40px",
                        marginTop: "45px",
                        marginLeft: "10px",
                    }}
                    disabled={newFolderName === "" ? true : false}
                    value="Show Form"
                    onClick={handleFormShow}
                ></input> */}
            </div>
        </ModalView>
    );
}
