import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import http from "../../../../bae-client/HTTP";
import Loader from "../../../stylized/Loader";
import AlertDialog from "../../../stylized/AlertDialog";

export default function Agreement({
    agreements,
    setAgreementStatus,
    setUserAgreements,
    setUserLoggedIn,
}) {
    const [allAgreements, setAllAgreements] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [hasRead, setHasRead] = React.useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);

    React.useEffect(() => {
        setAllAgreements(agreements);
    }, []);

    const handleDisagree = () => {
        setHasRead(false);
        sessionStorage.clear();
        setUserLoggedIn(false);
    };

    const scrollEvent = (e) => {
        // const containerHeight = e.target.scrollHeight;
        // let currentScroll = e.target.scrollY + e.target.innerHeight;
        // // When the user is [modifier]px from the bottom, fire the event.
        // let modifier = 5;
        // if (currentScroll + modifier > containerHeight) {
        // }
        // if (
        //     e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
        //     1
        // )
        if (hasRead) return;
        if (
            e.target.scrollHeight - 5 <=
            e.target.scrollTop + e.target.clientHeight
        )
            setHasRead(true);
    };

    const handleAgree = (agreementId) => {
        setShow(true);
        http.sendRestRequest(
            `userLicenseAgreement/agree?agreementId=${agreementId}`,
            "PUT"
        )
            .then(() => {
                setHasRead(false);
                const nonApprovedAgreements = allAgreements.filter(
                    (agr) => agr.agreementId !== agreementId
                );
                if (!nonApprovedAgreements.length) {
                    setAgreementStatus(true);
                    setUserAgreements([]);
                } else setAllAgreements(nonApprovedAgreements);
                setShow(false);
            })
            .catch((error) => {
                console.log(error);
                setShow(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    if (allAgreements.length)
        return (
            <>
                <Card sx={{ minWidth: 275, margin: "5% 25% 0 25%" }}>
                    <div
                        style={{
                            padding: "8px",
                            fontWeight: "bold",
                            margin: "auto",
                            maxInlineSize: "fit-content",
                            // max-inline-size: fit-content;
                        }}
                    >
                        {"License Agreement"}
                    </div>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            <div
                                id="agreement"
                                style={{ height: "50vh" }}
                                className="invisibleScroller"
                                onScroll={scrollEvent}
                                dangerouslySetInnerHTML={{
                                    __html: allAgreements[0].agreementText,
                                }}
                            ></div>
                        </Typography>
                    </CardContent>
                    <CardActions style={{ float: "right" }}>
                        <Button
                            id="btn_policyAgreement_accept"
                            disabled={!hasRead}
                            size="small"
                            onClick={() =>
                                handleAgree(allAgreements[0].agreementId)
                            }
                            title="Accept"
                        >
                            Accept
                        </Button>
                        <Button
                            id="btn_policyAgreement_decline"
                            disabled={!hasRead}
                            size="small"
                            onClick={handleDisagree}
                            title="Decline"
                        >
                            Decline
                        </Button>
                    </CardActions>
                </Card>
                <Loader show={show}></Loader>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
            </>
        );
    else return null;
}
