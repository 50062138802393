import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./CustomSnackbar.css"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar({
    open,
    setOpen,
    alertMessage,
    autoHideTime,
    severity,
    position,
}) {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const vertical = (position && position.vertical) || "bottom";
    const horizontal = (position && position.horizontal) || "center";

    return (
        <Snackbar
            id={"toaster"}
            open={open}
            autoHideDuration={autoHideTime || 5000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert
                id={"alertMessage"}
                onClose={handleClose}
                severity={severity || "success"}
                sx={{ width: "100%" }}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    );
}
