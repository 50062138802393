import React, { Component } from "react";
import ListView from "./ListView";

class CheckboxView extends Component {
    constructor(props) {
        super(props);
    }
    handleChkChange = (e) => {};

    handleShowLinkDocClick = (index, listValue, listText, event) => {
        this.props.onchange(index, listValue);
    };
    handleUncheckClick = (i, value) => {
        if (this.props.uncheckclick !== undefined) {
            this.props.uncheckclick(i, value);
        }
    };
    render() {
        this.listofitems = this.props.items;
        this.selectedItems = this.props.checkedIndexes;
        return (
            <ListView
                className="noBorder dropdown_dashboard"
                listItemClassname="pl-4"
                listType="chk"
                checkBoxType={this.props.chktype}
                id={"workspaceChkList"}
                label={"list_workspaceChkList"}
                items={this.listofitems}
                selectedIndexes={this.props.checkedIndexes}
                showIcon="false"
                disableActiveIndex="true"
                onchange={this.handleShowLinkDocClick}
                uncheckclick={this.handleUncheckClick}
            />
        );
    }
}

export default CheckboxView;
