import { getIconPathByType } from "../../../common/IconList";

export const columns = [
    {
        id: "name",
        label: "Name",
        type: "link",
    },
    {
        id: "updatedOn",
        label: "Date last Modified",
    },
    {
        id: "createdOn",
        label: "Date Created",
    },
    {
        id: "isShared",
        label: "Shared",
    },
    {
        id: "owner",
        label: "Owner",
    },
    {
        id: "isStarred",
        label: "Starred",
        type: "star",
    },
];
export const flowcolumns = [
    {
        id: "name",
        label: "Flow Name",
        type: "link",
    },
    {
        id: "updatedOn",
        label: "Updated On",
    },
    {
        id: "createdOn",
        label: "Created On",
    },
    {
        id: "isShared",
        label: "Shared",
    },
    {
        id: "owner",
        label: "Owner",
    },
    {
        id: "isStarred",
        label: "Starred",
        type: "star",
    },
];
export const getIconUrls = (type, name) => {
    const array = name.split(".");
    const extension = array[array.length - 1];
    if (type === true) return "./images/star-white.svg";
    if (type === false) return "./images/star-yellow.svg";
    if (type === "file" || type === "extension")
        return getIconPathByType(extension.toLowerCase());
    if (type === "folder") return "./images/FOLDER.svg";
    if (type === "space") return "./images/spaceIcon.svg";
    if (type === "flows") return "./images/flowWksp.svg";
    if (
        (type === "emails" && name === "Inbox") ||
        (type === "emails" && name === "Outbox")
    )
        return "./images/inbox.svg";
    if (type === "emails" && name === "Attachments") {
        return "./images/paperclip (1).svg";
    }
    if (type === "emails") return "./images/email.svg";
};
export const extensions = [
    "pdf",
    "jpg",
    "png",
    "txt",
    "gif",
    "docx",
    "mp3",
    "mp4",
    "doc",
    "html",
    "htm",
    "xml",
    "pptx",
    "ppt",
    "xlsx",
    "xls",
    "ai",
    "json",
    "edi",
    "xlsm",
];
export const validateFileType = (fileName) => {
    const fileType = fileName.split(".").pop();
    const supportedFileTypes = extensions;
    return supportedFileTypes.includes(fileType.trim().toLowerCase());
};
