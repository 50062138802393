import CuboidManager from "./CuboidManager";
import http from "./HTTP";

class WorkspaceManager {
    constructor(option) {
        if (option !== undefined) {
            this.workspaceCollabId = option.workspaceCollabId;
            this.workspaceWBId = option.workspaceWBId;
            this.workspacePersistenceId = option.workspacePersistenceId;
            this.workspaceRegistryId = option.workspaceRegistryId;
        }

        // this.cuboidManager = new CuboidManager(
        //     this.workspaceCollabId,
        //     this.workspaceWBId
        // );
        this.workspaceResponse = null;
        this.workspaceIdToIndex = {};
        this.workspaceNameToIndex = {};
        this.ids = {};
        this.workspaceResponse = null;
        this.activeWorkspace = {};
    }

    setIDs = () => {
        this.ids = {
            workspaceCollabId: this.workspaceCollabId,
            workspaceWBId: this.workspaceWBId,
            workspacePersistenceId: this.workspacePersistenceId,
            workspaceRegistryId: this.workspaceRegistryId,
        };
    };
    getIDs = () => {
        return this.ids;
    };
    generateWorkpaceRegistryRow(data) {
        let rows = {
            rows: [[data.workspaceName]],
        };
        return rows;
    }
    generateWSPersistencepaceRow(data) {
        let rows = {
            rows: [[data.workspaceID, data.spaceID, data.archiveStatus]],
        };
        return rows;
    }
    // generateSpacePersistanceRows(data) {
    //     let documentsArray = data.documentObjects;
    //     let rowsArray = [];
    //     for (let index = 0; index < documentsArray.length; index++) {
    //         rowsArray.push([data.spaceId, documentsArray[index].data.documentId, "", "", "", "", ""]);
    //     }
    //     let rows = {
    //         "rows": rowsArray,
    //         "count": rowsArray.length
    //     }
    //     return rows;
    // }
    createDataStructure = () => {
        let response = this.getLoadWorkspaceResponse();
        response.forEach((key, index) => {
            if (key.workspaceId !== null && key.workspaceId !== undefined) {
                this.workspaceIdToIndex[key.workspaceId] = {
                    index: index,
                };
            }
            if (key.workspaceName !== null && key.workspaceName !== undefined) {
                this.workspaceNameToIndex[key.workspaceName] = {
                    index: index,
                };
            }
            if (
                index === 0 &&
                key.workspaceId !== null &&
                key.workspaceName !== null
            ) {
                this.activeWorkspace["activeWorkspaceID"] = key.workspaceId;
                this.activeWorkspace["activeWorkspaceName"] = key.workspaceName;
                this.activeWorkspace["spaceList"] = [];
            }
        });
    };
    getActiveWorkspace = () => {
        return this.activeWorkspace;
    };
    getMaximumWorkspaceID = () => {
        let maximumSpaceId = 0;
        if (Object.keys(this.workspaceIdToIndex).length > 0) {
            Object.keys(this.workspaceIdToIndex).forEach((key) => {
                if (parseInt(key) > maximumSpaceId) {
                    maximumSpaceId = parseInt(key);
                }
            });
        } else {
            maximumSpaceId = 10000000;
        }
        return maximumSpaceId;
    };

    checkExistingWorkpaceName = (workspaceName) => {
        let flag = false;
        if (workspaceName in this.workspaceNameToIndex) {
            flag = true;
        }
        return flag;
    };
    setLoadWorkspaceResponse(response) {
        this.workspaceResponse = response;
    }
    getLoadWorkspaceResponse() {
        return this.workspaceResponse;
    }
    loadWorkspaceListByAPI = (workspaceRegistryId) => {
        let url = "workspace/workspaceList";
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };
    loadWorkspace() {
        let workspaceArray = [];
        let workspacePersistance = this.cuboidManager.loadGridById(
            this.workspacePersistenceId
        );
        let workspaceRegistry = this.cuboidManager.loadGridById(
            this.workspaceRegistryId
        );
        workspaceArray.push(workspacePersistance);
        workspaceArray.push(workspaceRegistry);
        return Promise.all(workspaceArray);
    }

    updateWorkspaceName = () => {
        let url = "workspace/workspaceList";
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "PUT")
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };

    addNewWorkspace = (data) => {
        let url = "workspace/workspace";
        return new Promise((resolve, reject) => {
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
export default WorkspaceManager;
