export const response = {
    rippleRegistryId: 179765,
    rippleHierarchy: {
        150876: {
            workspaceId: 150876,
            workspaceName: "Orders",
            spaces: {
                150877: {
                    spaceId: 150877,
                    spaceName: "Inbound",
                    children: {
                        2031919: {
                            spaceId: 150877,
                            documentId: 2031919,
                            documentName: "Testing-Copy 3.txt",
                        },
                        2031917: {
                            spaceId: 150877,
                            documentId: 2031917,
                            documentName: "Testing-Copy 2.txt",
                        },
                        2031932: {
                            spaceId: 150877,
                            documentId: 2031932,
                            documentName: "Testing-Copy 5.txt",
                        },
                        2031915: {
                            spaceId: 150877,
                            documentId: 2031915,
                            documentName: "Testing-Copy 1.txt",
                        },
                        2031913: {
                            spaceId: 150877,
                            documentId: 2031913,
                            documentName: "Testing.txt",
                        },
                        2031943: {
                            spaceId: 150877,
                            documentId: 2031943,
                            documentName: "Testing-Copy 6.txt",
                        },
                        150927: {
                            spaceId: 150877,
                            folderId: 150927,
                            folderName: "walmart_inc",
                            isCollapsed: false,
                            children: {
                                150928: {
                                    spaceId: 150877,
                                    folderId: 150928,
                                    folderName: "PO",
                                    isCollapsed: false,
                                    children: {
                                        2031899: {
                                            spaceId: 150877,
                                            documentId: 2031899,
                                            documentName: "Testing-Copy 4.txt",
                                        },
                                        2031897: {
                                            spaceId: 150877,
                                            documentId: 2031897,
                                            documentName: "Testing-Copy 3.txt",
                                        },
                                        2031895: {
                                            spaceId: 150877,
                                            documentId: 2031895,
                                            documentName: "Testing-Copy 2.txt",
                                        },
                                        2031893: {
                                            spaceId: 150877,
                                            documentId: 2031893,
                                            documentName: "Testing-Copy 1.txt",
                                        },
                                        2031891: {
                                            spaceId: 150877,
                                            documentId: 2031891,
                                            documentName: "Testing.txt",
                                        },
                                    },
                                },
                            },
                        },
                        2031921: {
                            spaceId: 150877,
                            documentId: 2031921,
                            documentName: "Testing-Copy 4.txt",
                        },
                    },
                },
            },
        },
    },
    rippleChatMessages: [],
};
