const instanceInfoColumns = [
    { columnName: "Instance Id", dataType: "String" },
    { columnName: "Instance Blockchain Id", dataType: "String" },
    { columnName: "Generic Step Name", dataType: "String" },
    { columnName: "Document Name", dataType: "String" },
    { columnName: "From", dataType: "String" },
    { columnName: "To", dataType: "String" },
    { columnName: "PO Number", dataType: "String" },
    { columnName: "Delivery Date", dataType: "Date" },
    { columnName: "Quantity", dataType: "String" },
    { columnName: "Promise Date", dataType: "Date" },
    { columnName: "Actual Date", dataType: "Date" },
    { columnName: "Block Type", dataType: "String" },
    { columnName: "Promise type", dataType: "String" },
];

export default instanceInfoColumns;
