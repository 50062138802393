import React, { useState, useEffect } from "react";
import AlertList from "./AlertList";
import ModalView from "../../../../../../stylized/ModalView";
import CustomDateDropdown from "../../../../../../stylized/CustomDateDropdown";
import { makeStyles } from "@material-ui/core/styles";
import CustomSearchDropdown from "../../../../../../stylized/CustomDropDown/index";
import "./style.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Loader from "../../../../../../stylized/Loader";
import CustomSnackbar from "../../../../../../stylized/CustomSnackbar/CustomSnackbar";
import http from "../../../../../../../bae-client/HTTP";

const style = {
    position: "absolute",
    top: "50%",
    left: "53%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "264px",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "16px 7px",
    paddingTop: "2%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "4px",
};
const applyButtonStyle = {
    // width: "75px",
    height: "36px",
    padding: "6px 16px",
    gap: "8px",
    borderRadius: "4px 0px 0px 0px",
    backgroundColor: "#0B5CD7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
const resetButtonStyle = {
    // width: "125px",
    height: "36px",
    padding: "6px 16px",
    gap: "8px",
    borderRadius: "4px 0px 0px 0px",
    border: "1px solid #D7DEEA",
    backgroundColor: "white",
    opacity: "0.8", // Assuming you meant a slight transparency, adjust as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
const closeButtonStyle = {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
    fontSize: "24px",
};
export default function DashboardAlert({
    alertData,
    openInstance,
    instanceList,
    closeAlert,
    showHint,
    alertDisableDate,
}) {
    const [selectedAlerts, setSelectedAlerts] = useState({});
    const [show, setShow] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = useState({});
    const handleOpen = () => {
        setOpen(true);
    };
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [dateErr, setDateErr] = useState(false);
    const [selectedTimeState, setSelectedTimeState] = useState({});
    const [filteredAlerts, setFilteredAlerts] = useState([]);
    const [showClearAlertsModal, setShowClearAlertsModal] = useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [message, setMessage] = useState("");
    const setOpenDialog = (flag) => setOpenSnackMsg(flag);
    const [openSnackMsg, setOpenSnackMsg] = useState(false);
    const [dummyAlertTypeSelect, setDummyAlertTypeSelect] = useState({});
    const useStyles = makeStyles({
        root: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21.58px",
            letterSpacing: "-0.006em",
            textAlign: "left",
            borderRadius: "6px !important",
            display: "inline-flex",
            flexDirection: "column",
            position: "relative",
            padding: "0px",
            marginTop: "5px",
            marginLeft: "0px",
            border: "0px",
            verticalAlign: "top",
            width: "100%",
        },
        dateRoot: {},
    });
    const handleClose = () => {
        setOpen(false);
    };

    const elements = [
        {
            id: 0,
            text: "Delayed",
            value: "Delayed",
        },
        {
            id: 1,
            text: "Skipped",
            value: "Skipped",
        },
        {
            id: 2,
            text: "Key Missing",
            value: "Key Missing",
        },
        {
            id: 3,
            text: "Key Mismatch",
            value: "Key Mismatch",
        },
        {
            id: 4,
            text: "Proactive Alert",
            value: "Proactive Alert",
        },
        {
            id: 5,
            text: "Date Changed",
            value: "Date Changed",
        },
        {
            id: 6,
            text: "Others",
            value: "Others",
        },
    ];
    const classes = useStyles();
    useEffect(() => {
        if (alertData.length) {
            setFilteredAlerts(alertData);
        }
    }, [alertData]);

    useEffect(() => {
        const trueCheckCount = Object.values(selectedAlerts);
        setIsAllSelected(
            trueCheckCount.filter((value) => value === true).length ===
                filteredAlerts.length
        );
    }, [selectedAlerts]);
    const handleSelectedTimeFilterData = (data) => {
        setSelectedTimeState(data);
    };
    const filterByDateRange = (data, startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const stripTime = (dateString) => {
            const [datePart] = dateString.split(" ");
            return new Date(datePart);
        };
        return data.filter((item) => {
            const createdOn = stripTime(item.createdOn);
            return createdOn >= start && createdOn <= end;
        });
    };
    const handleApply = () => {
        let exceptionTypeSet;
        let filteredData;
        setSelectedValue(dummyAlertTypeSelect);

        if (dummyAlertTypeSelect.length) {
            exceptionTypeSet = new Set(
                dummyAlertTypeSelect.map((item) => item.value.toUpperCase())
            );
            filteredData = alertData.filter((alert) =>
                exceptionTypeSet.has(alert.exceptionType)
            );
        } else filteredData = alertData;

        if (filteredData.length) {
            let finalFilter = filteredData;
            if (selectedTimeState?.startDate && selectedTimeState?.endDate) {
                finalFilter = filterByDateRange(
                    filteredData,
                    selectedTimeState?.startDate,
                    selectedTimeState?.endDate
                );
            }
            setFilteredAlerts(finalFilter);
        } else {
            setFilteredAlerts([]);
        }
        setIsAllSelected(false);
        setSelectedAlerts({});
        setIsFilterApplied(true);
        handleClose();
    };
    const handleTransactionSelection = (index, selectedValue) => {
        setDummyAlertTypeSelect(selectedValue);
    };

    const handleSelectAll = () => {
        if (
            Object.values(selectedAlerts).filter((value) => value === true)
                .length === filteredAlerts.length
        ) {
            setSelectedAlerts({});
        } else {
            const newSelected = {};
            filteredAlerts.forEach((alert) => {
                newSelected[alert.exceptionLogObjectId] = true;
            });
            setSelectedAlerts(newSelected);
        }
    };

    const handleCheckboxChange = (alertId) => {
        setSelectedAlerts((prevSelected) => ({
            ...prevSelected,
            [alertId]: !prevSelected[alertId],
        }));
    };
    const handleDoneButtonClick = () => {
        if (Object.keys(selectedAlerts).length > 0) {
            setShowClearAlertsModal(true);
        }
    };

    const handleClearAlerts = () => {
        closeAlert(selectedAlerts);
        setSelectedAlerts({});
        setIsAllSelected(false);
        setShowClearAlertsModal(false);
        setSelectedValue([]);
        setSelectedTimeState({});
    };
    const handleReset = () => {
        setSelectedValue([]);
        setSelectedTimeState({});
        setIsAllSelected(false);
        setSelectedAlerts({});
        setIsFilterApplied(false);
        if (alertData.length) {
            setFilteredAlerts(alertData);
        }
    };
    const handleCancelClearAlerts = () => {
        setShowClearAlertsModal(false);
    };

    return (
        <div className="alertViewContainer">
            <div
                className="row stickyHeader"
                style={{
                    height: "5rem",
                    width: "97%",
                    marginLeft: "6px",
                    alignItems: "center",
                    paddingLeft: "6px",
                    paddingTop: "20px",
                    zIndex: "1",
                    borderRadius: "10px",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "53px",
                        padding: "2px 20px",
                    }}
                >
                    <div
                        className="alert-dashboard"
                        style={{ marginBottom: "10px" }}
                    >
                        {show === true && alertData.length > 0 ? (
                            <>
                                <input
                                    title="Select All"
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                    style={{
                                        marginRight: "8px",
                                        marginLeft: "-16px",
                                        width: "20px",
                                        height: "20px",
                                        gap: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #9DA5B4",
                                    }}
                                />
                            </>
                        ) : null}
                        <img src="./images/bell-fill.svg" alt="" />
                        <span className="titles" title="Alerts">
                            Alerts
                        </span>
                    </div>
                    <div
                        style={{
                            width: "auto",
                            display: "flex",
                            justifyContent: "center",
                            gap: "8px",
                        }}
                    >
                        <button
                            className="btn-dashboard"
                            disabled={alertData.length ? false : true}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                flexBasis: "100px",
                            }}
                            onClick={handleOpen}
                            title="Filters"
                        >
                            <img
                                src="./images/SlidersHorizontal.svg"
                                alt="Filter Icon"
                                style={{ marginRight: "4px" }}
                            />
                            Filters
                        </button>
                        <button
                            className="btn-dashboard"
                            disabled={
                                filteredAlerts.length && alertData.length
                                    ? false
                                    : true
                            }
                            style={{
                                marginBottom: "10px",
                                // flexBasis: "140px",
                                padding: "15px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                setShow(!show);
                                setSelectedAlerts({});
                            }}
                            title={
                                show === true && alertData.length > 0
                                    ? "Done"
                                    : "Select Alerts"
                            }
                        >
                            {show === true && alertData.length > 0
                                ? "Done"
                                : "Select Alerts"}
                        </button>
                        {Object.keys(selectedAlerts).length > 0 ? (
                            <button
                                className="btn-dashboard"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    justifyContent: "center",
                                    // flexBasis: "100px",
                                    width: "100%",
                                    paddingLeft: "12px",
                                }}
                                onClick={handleDoneButtonClick}
                                title="Clear"
                            >
                                Clear
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            {alertData && alertData.length ? (
                <AlertList
                    closeAlert={closeAlert}
                    openInstance={openInstance}
                    alertData={filteredAlerts && filteredAlerts}
                    instanceList={instanceList}
                    show={show}
                    selectedAlerts={selectedAlerts}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        marginTop: "100px",
                    }}
                >
                    <div
                        className="img-flow-alert"
                        style={{ paddingBottom: "20px" }}
                    >
                        <img src="./images/Empty State ICON.svg" alt="" />
                    </div>
                    <div className="grey-head">
                        Select Filters Above<br></br> to View Alert
                    </div>
                </div>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            margin: "0px 4px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "-12px",
                                marginLeft: "4px",
                            }}
                        >
                            <span
                                style={{ paddingLeft: "7px" }}
                                className="filter_alert"
                            >
                                Filter By:
                            </span>
                            <div style={closeButtonStyle} onClick={handleClose}>
                                &times;
                            </div>
                        </div>

                        <CustomSearchDropdown
                            items={elements}
                            label={"Alert Type"}
                            id={"btn_select_transaction"}
                            idInfo={"transaction"}
                            multiSelect={true}
                            isSearchable={false}
                            isChipView={false}
                            handleSelection={handleTransactionSelection}
                            selection={selectedValue}
                            customClassName={classes.root}
                            customWidth={127}
                        ></CustomSearchDropdown>
                        <CustomDateDropdown
                            label={"Time"}
                            setDateErr={setDateErr}
                            dateErr={dateErr}
                            setSelectedTimeFilter={handleSelectedTimeFilterData}
                            selection={selectedTimeState}
                            customClassName={classes.dateRoot}
                            disableDate={alertDisableDate}
                            height={36}
                            isDashboardAlert={true}
                            customWidth={142}
                            customMargin={"3% 0px 0px 0px"}
                        ></CustomDateDropdown>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0px 4px",
                        }}
                    >
                        <button
                            onClick={handleReset}
                            id="btn_reset"
                            title="Reset All Filters"
                            style={resetButtonStyle}
                        >
                            <span
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    letterSpacing: "0.4px",
                                    textAlign: "left",
                                    color: "black",
                                }}
                            >
                                Reset All Filters
                            </span>
                        </button>
                        <button
                            onClick={handleApply}
                            id="btn_apply"
                            title="Apply"
                            style={applyButtonStyle}
                        >
                            <span
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    letterSpacing: "0.4px",
                                    textAlign: "left",
                                    color: "white",
                                }}
                            >
                                Apply
                            </span>
                        </button>
                    </div>
                </Box>
            </Modal>

            {/* <Modal
                open={showClearAlertsModal}
                onClose={handleCancelClearAlerts}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="alert-text">Clear Alerts?</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0px 4px",
                        }}
                    >
                        <button
                            onClick={handleCancelClearAlerts}
                            id="btn_reset"
                            title="Reset All Filters"
                            style={resetButtonStyle}
                        >
                            <span
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    letterSpacing: "0.4px",
                                    textAlign: "left",
                                    color: "black",
                                }}
                            >
                                No
                            </span>
                        </button>
                        <button
                            onClick={handleClearAlerts}
                            id="btn_apply"
                            title="Apply"
                            style={applyButtonStyle}
                        >
                            <span
                                style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    letterSpacing: "0.4px",
                                    textAlign: "left",
                                    color: "white",
                                }}
                            >
                                Yes
                            </span>
                        </button>
                    </div>
                </Box>
            </Modal> */}

            <ModalView
                showHeader="false"
                footer="false"
                title="Yes"
                id="btn_folder_submit"
                onSubmit={handleClearAlerts}
                value="Name"
                type="submit-cancel"
                submitButtonText="No"
                show={showClearAlertsModal}
                size="sm"
                onHide={handleCancelClearAlerts}
                isDashboardAlert={true}
            >
                <div className="alert-text">Close Alerts?</div>
            </ModalView>

            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={openSnackMsg}
                setOpen={setOpenDialog}
                alertMessage={message}
            ></CustomSnackbar>
        </div>
    );
}
