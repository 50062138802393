import React from "react";

const SearchField = ({ onChangeHandler, text, value, id }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "6px 12px",
                borderRadius: "4px",
                border: "1px solid #D7DEEA",
                boxShadow: "1px 2px 2px 0px #21242C1F inset",
                width: "375px",
                height: "36px",
            }}
            id={id ? id : ""}
        >
            <div
                style={{ position: "relative", width: "100%" }}
                id="search-field"
            >
                <input
                    type="text"
                    name="search"
                    placeholder={value || "Search"}
                    id={
                        id === "search-dash1"
                            ? "search-dash1"
                            : id === "search-instances2"
                            ? "instance-search"
                            : "searchKey"
                    }
                    value={text}
                    onChange={onChangeHandler}
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fill: "#B7BECD", // Adjust icon color
                    }}
                >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </svg>
            </div>
        </div>
    );
};

export default SearchField;
