import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { setCollabInfo, setBlockchainResponse } from '../../../../actions';
import ListView from "../../../stylized/ListView";
import PopoverView from "../../../stylized/PopoverView";
import '../../../../../src/index.css'

function CTDocumentMeta({ sourceImage, title, noOfDocuments, documents, arrowDirection, blockchainResponse }) {
    const docList = [];
    const [popoverTarget, setPopoverTarget] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const getDocumentList = () => {
        for (let i = 0; i < documents.length; i++) {
            docList.push({ value: documents[i], text: getDocIndex(documents[i]) });
        }
        return docList;
    }
    const closePopover = () => {
        setShowPopover(false);
        setPopoverTarget(null);
    };
    const getDocIndex = (document) => {
        let title = document;
        //if (blockchainResponse) {
        blockchainResponse.forEach((item, idx) => {
            if (item.instanceBlockDocumentName &&
                item.instanceBlockDocumentName === document) {
                title = `${idx + 1}. ${document}`;
            }
        })
        //}
        return title
    }
    const renderMenu = () => {
        return (
            <ListView
                className="noBorder"
                listItemClassname="pl-4"
                id={"" + "moreDocs"}
                label={"list_" + "moreDocs"}
                items={(documents) ? getDocumentList() : []}
                showIcon="false"
                disableActiveIndex="true"
                onchange={() => { }}
            />
        );
    };
    const moreDocument = (e) => {
        e.preventDefault();
        setPopoverTarget(e.currentTarget);
        setShowPopover(true);
    };
    return (
        <div
            className="doCenter ctDocumentList"
            style={{
                //flexDirection: "column",
                //marginBottom: "10px",
                marginTop: '15px',
            }}
        >
            {/* {(arrowDirection && arrowDirection === "Right") ? <i className="fas fa-arrow-right fa-2x"></i> : <i className="fas fa-arrow-left fa-2x"></i>} */}
            {(arrowDirection && arrowDirection === "Right") ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>}
            <span className="fa-stack doCenter" style={{ verticalAlign: "top", flexDirection: "column", }} title={title}>
                <i className="far fa-circle fa-stack-2x"></i>
                <strong className="fa-stack-1x calendar-text">
                    {noOfDocuments}
                </strong>
            </span>
            <i
                className="fa fa-plus"
                //style={{ marginLeft: "25px" }}
                onClick={moreDocument}
            ></i>
            {/* <strong
                style={{
                    maxWidth: "85px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
                title={title}
            >
                {title}
            </strong> */}
            <PopoverView
                style={{ with: "100px" }}
                id={"menu_"}
                show={showPopover}
                anchorEl={popoverTarget}
                closePopover={closePopover}
            >
                {renderMenu()}
            </PopoverView>
        </div>
    );
}
const mapDispatchToProps = () => {
    // return {
    //     setCollabInfo,
    //     setBlockchainResponse
    // }
}

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        blockchainResponse: state.blockchainResponse
    }
}
export default connect(mapStateToProps, mapDispatchToProps())(CTDocumentMeta)
//export default CTDocumentMeta;
