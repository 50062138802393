import React from "react";

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.id === undefined || this.props.id === null)
            this.id = this.props.label.replace(/\s+/, "_");
        else this.id = this.props.id;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onchange &&
            this.props.onchange(
                event.currentTarget.value,
                event.currentTarget.id,
                event
            );
    }
    getRows = () => {
        let rows = "5";
        if (this.props.rows !== undefined || this.props.rows !== null) {
            rows = this.props.rows;
        }
        return rows;
    };
    getCols = () => {
        let columns = "20";
        if (this.props.cols !== undefined || this.props.cols !== null) {
            columns = this.props.cols;
        }
        return columns;
    };
    handleKeyDown = (e) => {
        if (
            this.props.onkeydown !== undefined &&
            e.keyCode === 13 &&
            !e.shiftKey
        )
            this.props.onkeydown(e.currentTarget.value);
    };
    render() {
        return (
            <div
                className={
                    this.props.divClass !== undefined
                        ? this.props.divClass
                        : "input-group"
                }
            >
                <textarea
                    className={"form-control " + this.props.className}
                    id={this.id}
                    aria-label={this.props.label}
                    name={this.id}
                    placeholder={this.props.label}
                    rows={this.getRows()}
                    cols={this.getCols()}
                    value={this.props.value}
                    title={this.props.title}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default TextArea;
