import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "@material-ui/core";
import { CardActions, IconButton } from "@mui/material";
import http from "../../bae-client/HTTP";
import { useDispatch } from "react-redux";
import { setSnackbarAction } from "../../services/services/app";
import Loader from "./Loader";

const AttributeContainer = ({
    options,
    reload,
    setReload,
    rowsSelected,
    currentRows,
}) => {
    const dispatch = useDispatch();
    const [attributes, setAttributes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const loadAttibutes = () => {
        const activeFolder = options[options.length - 1];
        const objectId = activeFolder?.id;
        let selectedFile = null;
        let parent = null;
        let space = null;
        let folderId = null;
        if (rowsSelected && rowsSelected.length === 1) {
            selectedFile = currentRows?.find(
                (row) => parseInt(row?.id) === parseInt(rowsSelected[0])
            );
        }
        setSelectedFile(selectedFile);
        if (selectedFile && selectedFile.documentId) {
            parent = options[options.length - 1];
            if (parent.type === "space") {
                space = parent;
                folderId = 0;
            } else {
                space = options[1];
                folderId = parent?.id;
            }
        }
        setLoader(true);
        const url =
            selectedFile && selectedFile.documentId
                ? `object/attributes/evaluates?documentId=${selectedFile?.documentId}`
                : `object/attributes?objectId=${objectId}`;
        http.sendRestRequest(url)
            .then((response) => {
                setLoader(false);
                setAttributes(response);
                setReload(false);
            })
            .catch((e) => {
                setLoader(false);
                setReload(false);
                dispatch(
                    setSnackbarAction({
                        message: e?.error || "Something went wrong",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };
    useEffect(() => {
        if (options && options.length && reload) {
            loadAttibutes();
        }
    }, [options, reload]);

    const deleteHandler = (attribute) => {
        const activeFolder = options[options.length - 1];
        const objectId = activeFolder?.id;
        setLoader(true);
        http.sendRestRequest(
            `object/attributes?objectId=${objectId}&&attributeId=${attribute?.id}`,
            "DELETE"
        )
            .then((response) => {
                setLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: "Attribute deleted successfully",
                        severity: "success",
                        open: true,
                    })
                );
                loadAttibutes();
            })
            .catch((e) => {
                setLoader(false);
                dispatch(
                    setSnackbarAction({
                        message: e?.error || "Something went wrong",
                        severity: "error",
                        open: true,
                    })
                );
            });
    };
    const editHandler = (attribute) => {};

    return loader ? (
        <Loader show={loader} />
    ) : (
        <>
            {attributes?.map((attribute, index) => (
                <Card key={attribute?.id || index}>
                    <div className="attribute-heading">
                        <CardHeader
                            title={attribute?.attributeName || ""}
                            action
                        />
                        <CardActions disableSpacing>
                            <IconButton
                                title="Edit"
                                onClick={() => editHandler(attribute)}
                            >
                                <img src="./images/edit_rule.svg" alt="" />
                            </IconButton>
                            <IconButton
                                title="Delete"
                                onClick={() => deleteHandler(attribute)}
                                disabled={selectedFile?.documentId}
                            >
                                <img src="./images/trash 2.svg" alt="" />
                            </IconButton>
                        </CardActions>
                    </div>
                    <p
                        style={{
                            padding: "8px",
                            margin: "4px",
                        }}
                    >
                        {attribute?.regularExpression || ""}
                    </p>
                </Card>
            ))}
        </>
    );
};

export default AttributeContainer;
