import React, { Component } from "react";
import "./index.css";
import Button from "../../stylized/Button";
import TextBox from "../../stylized/TextBox";
import http from "../../../bae-client/HTTP";
import ControlTowerBlankForm from "./ControlTowerBlankForm";
import ControlTowerTabs from "./ControlTowerTabs";
import TitleBar from "./TitleBar";
import AlertDialog from "../../stylized/AlertDialog";
import Loader from "../../stylized/Loader";
class ControlTowerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: "buttons",
            data: "",
            showAlertModal: false,
            showLoader:false
        };
        this.getDataAPIResponse = null;
        this.rowId = null;
        this.cuboidId = null;
        //for fetched data
        this.filename = null;
        this.parentname = null;
        this.genericsteptname = null;
        this.instanceame = null;
        this.genericblockchaintname = null;
        this.fromdoc = null;
    }

    setFromText = (value, id, event) => {
        switch (id) {
            case "filename":
                {
                    this.filename = value;
                }
                break;
            case "parentname":
                {
                    this.parentname = value;
                }
                break;
            case "genericsteptname":
                {
                    this.genericsteptname = value;
                }
                break;
            case "instanceame":
                {
                    this.instanceame = value;
                }
                break;
            case "genericblockchaintname":
                {
                    this.genericblockchaintname = value;
                }
                break;
            case "fromdoc":
                {
                    this.fromdoc = value;
                }
                break;
            default:
                break;
        }
    };
    setInstanceFormText = (value, id, event) => {
        switch (id) {
            case "cuboidId":
                {
                    this.cuboidId = value;
                }
                break;
            case "rowId":
                {
                    this.rowId = value;
                }
                break;
            default:
                break;
        }
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    handleShowTabs = () => {
        this.setState({ display: "tabs" });
    };
    setInstanceFormData = (response) => {
        Object.keys(response).map((key) => {
            switch (key) {
                case "instanceBlockDocumentName":
                    {
                        this.filename = response[key];
                    }
                    break;
                case "parentInstanceBlockDocumentName":
                    {
                        this.parentname = response[key];
                    }
                    break;
                case "genericStepName":
                    {
                        this.genericsteptname = response[key];
                    }
                    break;
                case "instanceName":
                    {
                        this.instanceame = response[key];
                    }
                    break;
                case "genericTblName":
                    {
                        this.genericblockchaintname = response[key];
                    }
                    break;
                case "from":
                    {
                        this.fromdoc = response[key];
                    }
                    break;
                default:
                    break;
            }
        });
    };
    handleShowInstanceForm = () => {
        let cuboidId = this.cuboidId;
        let rowId = this.rowId;
        if (cuboidId === null || rowId === null) {
            this.showAlertView("", "Please enter cuboid id and row id");
            return;
        }
        //http://localhost:8080/BAE_4_6/rest/instanceBlockchain/object?instanceBlockchainTblId=2000380&instanceBlockchainId=8996
        let url =
            "instanceBlockchain/object?instanceBlockchainTblId=" +
            cuboidId +
            "&instanceBlockchainId=" +
            rowId;
        this.setState({showLoader:true})
        http.sendRestRequest(url)
            .then((response) => {
                this.setState({ showLoader: false })

                if (response.instanceBlockchainId !== 0) {
            //(response.instanceId !== 0 && response.instanceName !== null)
                    this.setInstanceFormData(response);
                    this.getDataAPIResponse = response;
                    this.setState({ display: "form", data: "updated" });
                } else if (response.instanceBlockchainId === 0) {
                    this.showAlertView("", "Invalid cuboid id and/or row id");
                    return;
                }
            })
            .catch((error) => {
                this.setState({ showLoader: false })

                this.showAlertView(
                    "",
                    error && error.error
                        ? error.path
                        : "Something went wrong, please try again!"
                );
                console.error(error);
            });
    };
    handleFormBack = () => {
        this.cuboidId = null;
        this.rowId = null;
        this.setState({ display: "buttons" });
    };
    handleShowBlankForm = () => {
        this.setState({ display: "blankform" });
    };
    renderButtons = () => {
        if (this.state.display === "buttons") {
            return (
                <div className="row">
                    <div
                        className="col-12 doCenter directionCol"
                        style={{ height: "100vh" }}
                    >
                        <div className="marginTopTen doCenter">
                            <label className="ctLabel">Cuboid Id</label>
                            {/* <input type="text" id='parentname' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.parentname} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input> */}
                            <TextBox
                                id="cuboidId"
                                className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                                type="text"
                                onchange={this.setInstanceFormText}
                                formControl="Off"
                                doCenter={true}
                            ></TextBox>
                        </div>
                        <div className="marginTopTen doCenter">
                            <label className="ctLabel">Row Id</label>
                            {/* <input type="text" id='filename' className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack" placeholder={this.filename} onChange={(event) => this.setFromText(event.currentTarget.value, event.currentTarget.id, event)} ></input> */}
                            <TextBox
                                id="rowId"
                                className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                                type="text"
                                onchange={this.setInstanceFormText}
                                formControl="Off"
                                doCenter={true}
                            ></TextBox>
                        </div>
                        <Button
                            id="submitButton"
                            label={"SHOW BLANK FORM"}
                            onclick={this.handleShowBlankForm}
                            className="ctMainButton grow"
                        ></Button>
                        <Button
                            id="submitButton"
                            label={"REFRESH DATA"}
                            onclick={this.handleShowInstanceForm}
                            className="ctMainButton grow"
                        ></Button>
                        {/* <Button id="submitButton" label={"SHOW TABS"} onclick={this.handleShowTabs} className="ctMainButton grow"></Button> */}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
    renderTabs = () => {
        if (this.state.display === "tabs") {
            return (
                <ControlTowerTabs
                    response={this.getDataAPIResponse}
                    onclick={this.handleFormBack}
                ></ControlTowerTabs>
            );
        } else {
            return null;
        }
    };

    renderBlankForm = () => {
        if (this.state.display === "blankform") {
            return (
                <ControlTowerBlankForm
                    onclick={this.handleFormBack}
                ></ControlTowerBlankForm>
            );
        } else {
            return null;
        }
    };
    // componentDidMount() {
    //     if (this.props.genericInfo) {
    //         this.setState({ display: 'blankform' })
    //     }
    // }
    renderForm = () => {
        if (this.state.display === "form") {
            return (
                <div className="doCenter" style={{ flexDirection: "column" }}>
                    <div className="marginTopTen">
                        <label className="ctLabel">File Name</label>
                        <input
                            type="text"
                            autocomplete="off"
                            id="filename"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.filename}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen">
                        <label className="ctLabel">Parent Name</label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="parentname"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.parentname}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen">
                        <label className="ctLabel">Generic Step Name</label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="genericsteptname"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.genericsteptname}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen">
                        <label className="ctLabel">Instance Name</label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="instanceame"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.instanceame}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen">
                        <label className="ctLabel">
                            Generic Blockchain Name
                        </label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="genericblockchaintname"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.genericblockchaintname}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen">
                        <label className="ctLabel">From</label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="fromdoc"
                            className="loginPageTextBox loginPageBorder loginTextBoxMediumn fontBlack"
                            placeholder={this.fromdoc}
                            onChange={(event) =>
                                this.setFromText(
                                    event.currentTarget.value,
                                    event.currentTarget.id,
                                    event
                                )
                            }
                        ></input>
                    </div>
                    <div className="marginTopTen doCenter">
                        <Button
                            id="submitButton"
                            label={"SUBMIT"}
                            onclick={this.updateUserProfile}
                            className="ctButton"
                        ></Button>
                        <Button
                            id="refreshButton"
                            label={"REFRESH"}
                            onclick={this.updateUserProfile}
                            className="ctButton"
                        ></Button>
                        <Button
                            id="backButton"
                            label={"BACK"}
                            onclick={this.handleFormBack}
                            className="ctButton"
                        ></Button>
                        <Button
                            id="expandButton"
                            label={"EXPAND"}
                            onclick={this.handleShowTabs}
                            className="ctButton"
                        ></Button>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
    renderTitle = () => {
        return (
            <TitleBar
                username={this.props.username}
                title={"Control Tower"}
            ></TitleBar>
        );
    };
    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };
    render() {
        return (
            <div className="col-10 defaultScroller">
                <div className="container w-100 h-100 pl-0 pr-0">
                    {this.renderTitle()}
                    {this.renderButtons()}
                    {this.renderForm()}
                    {this.renderBlankForm()}
                    {this.renderTabs()}
                    {this.renderAlertModal()}
                    <Loader show={this.state.showLoader} />
                </div>
            </div>
        );
    }
}

export default ControlTowerForm;
