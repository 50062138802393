import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/core/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import "./style.css";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import { Password } from "@mui/icons-material";

const Root = styled("div")(
    ({ theme }) => `
  color: ${
      theme.palette.mode === "dark"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
    padding: 0 0 4px;
    line-height: 1.5;
    display: block;
`;
const InputWrapper = styled("div")(
    ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
        theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.65)"
            : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon onClick={onDelete} />
        </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
    ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  z-index:1;
  line-height: 22px;
  background-color: ${
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#ebf2ff"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 10px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
    background:#ebf2ff;
  }
`
);

const Listbox = styled("ul")(
    ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    cursor: pointer;
    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);
export default function ContactList({
    dropdownList,
    handleDropdownSelect,
    flowShare,
}) {
    // const inputRef = useRef();
    // const resultsRef = useRef();

    //   useEffect(() => {
    //    inputRef.current.focus();
    //  }, []);

    //  useEffect(() => {
    //     document.body.addEventListener('keydown', onKeydown);
    //     return () => {
    //       document.body.addEventListener('keydown', onKeydown);
    //     }
    //   }, []);
    const [isInputDisabled, setIsInputDisabled] = React.useState(false);
    function onKeydown(event) {
        const isUp = event.key === "ArrowUp";
        const isDown = event.key === "ArrowDown";

        if (isUp) {
        }
        if (isDown) {
        }
    }
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
        inputValue,
    } = useAutocomplete({
        id: "contactsSelection",
        multiple: true,
        options: dropdownList,
        getOptionLabel: (option) => option.userEmail,
    });

    useEffect(() => {
        handleDropdownSelect(value);
        if (flowShare) {
            if (value.length > 0) {
                setIsInputDisabled(true);
            } else {
                setIsInputDisabled(false);
            }
        }
    }, [value]);

    return (
        <Root>
            <div {...getRootProps()} id="selectedUserList">
                <InputWrapper
                    ref={setAnchorEl}
                    className={focused ? "focused test" : ""}
                >
                    {value.map((option, index) => (
                        <StyledTag
                            className="user_chips"
                            label={option.userEmail}
                            {...getTagProps({ index })}
                        />
                    ))}

                    <input
                        {...getInputProps()}
                        isMulti
                        disabled={isInputDisabled}
                    />
                </InputWrapper>
            </div>
            {groupedOptions.length > 0 && inputValue.length ? (
                <Listbox
                    className="invisibleScroller listBox"
                    {...getListboxProps()}
                >
                    {groupedOptions.map((option, index) => (
                        <li
                            tabIndex={index}
                            {...getOptionProps({ option, index })}
                        >
                            <div className="row m-0 w-100 mb-2">
                                <div className="col-2 p-0 doCenter">
                                    <Avatar
                                        aria-label="user"
                                        style={{
                                            background: "#1B75BC",
                                            fontSize: "medium",
                                            height: "39px",
                                            width: "39px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {option.firstName && option.lastName
                                            ? option.firstName
                                                  .charAt(0)
                                                  .toLocaleUpperCase() +
                                              option.lastName
                                                  .charAt(0)
                                                  .toLocaleUpperCase()
                                            : option.userEmail
                                                  .charAt(0)
                                                  .toLocaleUpperCase()}
                                    </Avatar>
                                </div>
                                <div
                                    className="col-8 p-0"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>
                                        {option.firstName && option.lastName
                                            ? option.firstName +
                                              " " +
                                              option.lastName
                                            : option.userEmail}
                                    </span>
                                </div>
                                <div className="col-2 p-0 doCenter">
                                    <CheckIcon fontSize="small" />
                                </div>
                            </div>
                        </li>
                    ))}
                </Listbox>
            ) : inputValue !== "" && groupedOptions.length === 0 ? (
                <Listbox
                    className="invisibleScroller listBox"
                    {...getListboxProps()}
                >
                    <li
                        className="doCenter"
                        style={{ minHeight: "2.5rem", marginTop: "0px" }}
                    >
                        No Result Found
                    </li>
                </Listbox>
            ) : null}
        </Root>
    );
}
