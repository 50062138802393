import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "relative",
        height: "100%",
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: "100%",
        flexShrink: 0,
        height: "100%",
        position: "relative",
        zIndex: 100,
    },
    drawerPaper: {
        width: "100%",
        position: "relative",
    },
}));

export default function DrawerView(props) {
    const classes = useStyles();
    const theme = useTheme();

    const handleDrawerClose = () => {
        props.hideDrawer(false);
    };

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer + " filterDrawer"}
                variant="persistent"
                anchor="right"
                open={props.show}
                classes={{
                    paper: classes.drawerPaper + " invisibleScroller",
                }}
            >
                {/* <div className={classes.drawerHeader}>
                    <IconButton
                        style={{ outline: "none", cursor: "pointer" }}
                        onClick={handleDrawerClose}
                    >
                        {theme.direction === "rtl" ? (
                            <img
                                className="mr-2"
                                src="./images/chevron-double-left.svg"
                            />
                        ) : (
                            <img
                                className="mr-2"
                                src="./images/chevron-double-right.svg"
                            />
                        )}
                        <label className="filterAccordian_mainTitle">
                            {props.title}
                        </label>
                    </IconButton>
                </div> */}
                <Divider />
                {props.children}
            </Drawer>
        </div>
    );
}
