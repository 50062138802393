import React, { Component } from "react";

class ListAlertView extends Component {
    constructor(props) {
        super(props);
        this.isReadOnlyUser =
            sessionStorage.getItem("isReadOnlyUser") === "true";
    }

    handleClickEvent = (e) => {
        if (this.props.onBodyClick)
            this.props.onBodyClick(this.props.type, this.props.alertInstance);
    };
    handleMenuClickEvent = (e) => {
        this.props.onMenuBtnClick(e);
    };
    renderClassname() {
        if (this.props.index === this.props.activeIndex)
            return "heighlightCard ";
        else return "";
    }
    renderLeftIcon = () => {
        if (this.props.headerLeftIcon !== undefined) {
            return this.props.headerLeftIcon;
        } else return <div className=""></div>;
    };
    renderRightIcon = () => {
        if (this.props.headerRightIcon !== undefined) {
            return (
                <div
                    className={
                        this.isReadOnlyUser
                            ? "cursor_pointer readOnly"
                            : "cursor_pointer"
                    }
                    onClick={
                        this.isReadOnlyUser ? null : this.handleMenuClickEvent
                    }
                    id={"alert_" + this.props.id}
                    style={{
                        marginLeft: "8rem",
                    }}
                >
                    {this.props.headerRightIcon}
                </div>
            );
        } else return <div className=""></div>;
    };
    renderTitle = () => {
        let title = "";
        if (
            this.props.headerTitle !== undefined &&
            this.props.type !== undefined
        ) {
            if (
                (this.props.type.toLowerCase() === "ripple" &&
                    this.props.headerTitle.split(":")[0] === "") ||
                this.props.headerTitle.split(":")[0] === " "
            ) {
                title = "Unsaved : " + this.props.headerTitle.split(":")[1];
            } else {
                title = this.props.headerTitle;
            }
        }
        if (this.props.headerTitle !== undefined) {
            return (
                <div className="col p-0 textEllipsis">
                    <span className="listHeader" title={title}>
                        {title}
                    </span>
                </div>
            );
        } else return <div className="col p-0"></div>;
    };
    renderBorder = () => {
        if (this.props.type === "alert") {
            return "borderRed";
        } else if (this.props.type.toLowerCase() === "reminder") {
            return "borderYellow";
        } else return "borderGreen";
    };
    render() {
        return (
            <div className="alertDiv w-100" id={this.props.id}>
                <div className="alertBody row m-0">
                    <div
                        className={
                            "col-1 doCenter p-0 "
                            //  + this.renderBorder()
                        }
                        style={{
                            marginRight: "1rem",
                        }}
                    >
                        {this.renderLeftIcon()}
                    </div>
                    <div
                        className={
                            this.isReadOnlyUser
                                ? "col-8 p-0 readOnly"
                                : this.props.isHaveAction
                                ? "col-8 p-0 cursor_pointer"
                                : "col-8 p-0 cursorDefault"
                        }
                        onClick={this.handleClickEvent}
                    >
                        <div className="row w-100 m-0 mt-2">
                            {this.renderTitle()}
                        </div>
                        <div className="row m-0 mt-2 w-100 listBody">
                            <span
                                className="textEllipsis"
                                title={this.props.bodyData}
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {this.props.bodyData}
                            </span>
                        </div>
                    </div>
                    <div className="col-2 doCenter p-0">
                        {this.renderRightIcon()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ListAlertView;
