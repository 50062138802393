import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterData } from "../../../../../../../../../actions/DashboardActions";
import SelectGenericDropdown from "../../../../../document-chain/dashboard/HeaderSection/Dropdowns/SelectGenericDropdown";
import SelectMultiselectDropdown from "../../../../../document-chain/dashboard/HeaderSection/Dropdowns/SelectMultiselectDropdown";
import "./style.css";
import DropdownBtn from "./DropdownBtn";
import http from "../../../../../../../../../bae-client/HTTP";
import AlertDialog from "../../../../../../../../stylized/AlertDialog";
import Loader from "../../../../../../../../stylized/Loader";

function ShareInstanceStep({ close, submit, isFlow }) {
    const filterRawData = useSelector((state) => state.DashboardDCFilterData);
    const [selectedModalIndexesState, setSelectedModalIndexesState] =
        useState(null);
    const [selectedInstanceIndexesState, setSelectedInstanceIndexesState] =
        useState(null);
    const [
        selectedTransactionIndexesState,
        setSelectedTransactionIndexesState,
    ] = useState(null);

    const dispatch = useDispatch();
    const [modelList, setModelList] = useState([]);
    const [instanceList, setInstanceList] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [transactionOrignalList, setTransactionOrignalList] = useState([]);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [showLoader,setShowLoader]=React.useState(false);

    useEffect(() => {
        if (filterRawData) {
            renderModelList();
        }
    }, [filterRawData]);
    useEffect(() => {
        if (
            selectedInstanceIndexesState !== null &&
            selectedInstanceIndexesState !== undefined
        ) {
            // renderInstanceList();
            fetchInstanceList();
        }
    }, [selectedInstanceIndexesState]);
    useEffect(
        () => {
            if (
                selectedModalIndexesState !== null &&
                selectedModalIndexesState !== undefined
            ) {
                renderInstanceList();
                // renderTransactionTypeList();
                fetchInstanceList();
            }
        },
        selectedModalIndexesState !== null
            ? [
                  selectedModalIndexesState,
                  filterRawData[selectedModalIndexesState].instances,
              ]
            : [selectedModalIndexesState]
    );
    const fetchInstanceList = () => {
          setShowLoader(true);
        if (
            selectedModalIndexesState !== null &&
            selectedInstanceIndexesState !== null
        ) {
            let genericId =
                filterRawData[selectedModalIndexesState].genericRegistry
                    .genericId;
            let instanceId =
                filterRawData[selectedModalIndexesState].instances[
                    selectedInstanceIndexesState
                ].instanceId;
            http.sendRestRequest(
                `documentChain/instanceData?genericId=${genericId}&instanceId=${instanceId}`
            )
                .then((instanceSteps) => {
                    setTransactionOrignalList(instanceSteps);
                    renderTransactionTypeList(instanceSteps);
                    setShowLoader(false)
                })
                .catch((error) => {
                    setShowLoader(false)
                    setTransactionOrignalList([]);
                    renderTransactionTypeList([]);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        } else {
            setShowLoader(false)
            setTransactionOrignalList([]);
            renderTransactionTypeList([]);
        }
    };

    const renderModelList = () => {
        let itemList = [];
        filterRawData.forEach((filterItem) => {
            itemList.push(filterItem.genericRegistry.genericDisplayName);
        });
        itemList.sort((a, b) => a.localeCompare(b));
        setModelList(itemList);
    };
    const renderInstanceList = () => {
        const itemList = [];
        filterRawData[selectedModalIndexesState].instances.forEach((item) => {
            itemList.push(item.instanceName);
        });
        setInstanceList(itemList);
    };
    const renderTransactionTypeList = (transactionList) => {
        const itemList = [];
        transactionList.forEach((item) => {
            itemList.push(item.instanceStepName);
        });
        setTransactionList(itemList);
    };
    const handleModalSelect = (modelName, index) => {
        setSelectedModalIndexesState(index);
        setSelectedInstanceIndexesState(null);
        setSelectedTransactionIndexesState(null);
        // setSelectedTimeState({});
    };
    const handleInstaceSelect = (instanceName, index) => {
        setSelectedInstanceIndexesState(index);
        setSelectedTransactionIndexesState(null);
    };
    const handleTransactionSelect = (transactionName, index) => {
        setSelectedTransactionIndexesState(index);
    };
    const handleCancle = () => {
        close();
    };
    const handleSubmit = () => {
        let obj = {};
        const sortedFilterData = [...filterRawData];
        sortedFilterData.sort((a, b) =>
            a.genericRegistry.genericDisplayName.localeCompare(
                b.genericRegistry.genericDisplayName
            )
        );
        if (selectedTransactionIndexesState !== null) {
            obj = transactionOrignalList[selectedTransactionIndexesState];
            const genericName =
                sortedFilterData[selectedModalIndexesState].genericRegistry
                    .genericName;
            obj["shareType"] = "step";
            obj["genericName"] = genericName;
        } else {
            if (isFlow) {
                const genericId =
                    sortedFilterData[selectedModalIndexesState].genericRegistry
                        .genericId;
                const genericName =
                    sortedFilterData[selectedModalIndexesState].genericRegistry
                        .genericName;
                const genericDisplayName =
                    sortedFilterData[selectedModalIndexesState].genericRegistry
                        .genericDisplayName;
                obj = {
                    genericId,
                    genericName,
                    shareType: "flow",
                    genericDisplayName,
                };
            } else {
                const genericId =
                    sortedFilterData[selectedModalIndexesState].genericRegistry
                        .genericId;
                const genericName =
                    sortedFilterData[selectedModalIndexesState].genericRegistry
                        .genericName;
                const instanceId =
                    sortedFilterData[selectedModalIndexesState].instances[
                        selectedInstanceIndexesState
                    ].instanceId;
                const instanceName =
                    sortedFilterData[selectedModalIndexesState].instances[
                        selectedInstanceIndexesState
                    ].instanceName;

                obj = {
                    genericId,
                    genericName,
                    instanceId,
                    instanceName,
                    shareType: "instance",
                };
            }
        }
        submit(obj);
    };
    return (
        <div className="stepContainerBox">
            <div className="stepHeader">
                <span>
                    {isFlow ? "Select Flow to share" : "Select Step to share"}
                </span>
            </div>
            <div id="shareDropdownContainer" className="row m-0 w-100 mb-3">
                <SelectGenericDropdown
                    modelListData={modelList}
                    handleModalSelect={handleModalSelect}
                    id="selectGenericNameDropdown"
                ></SelectGenericDropdown>
                {!isFlow && (
                    <DropdownBtn
                        modelListData={instanceList}
                        handleModalSelect={handleInstaceSelect}
                        selectedIndexes={selectedInstanceIndexesState}
                        label="select Instance"
                        id="selectInsanceNameDropdown"
                    ></DropdownBtn>
                )}

                {!isFlow && (
                    <DropdownBtn
                        modelListData={transactionList}
                        handleModalSelect={handleTransactionSelect}
                        selectedIndexes={selectedTransactionIndexesState}
                        label="select Instance Step"
                        id="selectInsanceStepNameDropdown"
                    ></DropdownBtn>
                )}
                {/* <SelectMultiselectDropdown
                    name={"Select Instance"}
                    id="selectInsanceNameDropdown"
                    selectedIndexes={selectedInstanceIndexesState}
                    modelListData={instanceList}
                    handleModalSelect={handleInstaceSelect}
                ></SelectMultiselectDropdown>
                <SelectMultiselectDropdown
                    selectedIndexes={selectedTransactionIndexesState}
                    name={"Transaction Type"}
                    modelListData={transactionList}
                    handleModalSelect={handleTransactionSelect}
                ></SelectMultiselectDropdown> */}
            </div>
            <div className="stepFooter">
                <Button
                    // disabled={}
                    onClick={handleCancle}
                    // className={classes.button}
                    variant={"contained"}
                    // defaultStep ? "outlined" :
                    style={{
                        margin: "5px",
                        minWidth: "5rem",
                        borderRadius: "10px",
                        height: "2.5rem",
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    // className={classes.button}
                    className="bw_btn"
                    disabled={
                        selectedModalIndexesState !== null && isFlow
                            ? false
                            : selectedModalIndexesState === null ||
                              selectedInstanceIndexesState === null
                            ? true
                            : false
                    }
                    style={{ margin: "5px", minWidth: "5rem" }}
                >
                    Submit
                </Button>
                <Loader show={showLoader}></Loader>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
            </div>
        </div>
    );
}

export default ShareInstanceStep;
