import * as React from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

export default function FileSelectionTree({
    data = {},
    selectHandler,
    setSelected,
    setExpanded,
    expanded,
    selected,
}) {
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        console.log(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
        console.log(nodeIds);
    };

    const renderTree = (nodes) =>
        nodes.length
            ? nodes?.map((node) => (
                  <TreeItem
                      key={node.id}
                      nodeId={node.id.toString()}
                      label={
                          <span style={styles.textEllipsis}>
                              {node.objectName}
                          </span>
                      }
                      icon={
                          node.documentId === 0 ? (
                              <>
                                  <ChevronRightIcon />
                                  <img
                                      src="./images/folder.svg"
                                      alt=""
                                      style={styles.treeIcon}
                                  />
                              </>
                          ) : (
                              <>
                                  <img
                                      src="./images/Subtract.png"
                                      alt=""
                                      style={styles.treeIcon}
                                  />
                              </>
                          )
                      }
                      onClick={() => selectHandler(node)} // Pass the entire node to handler
                      sx={styles.treeItem}
                  >
                      {node.children &&
                          renderTree(
                              node.children.map((item) => {
                                  return { ...item, parentId: node.id };
                              })
                          )}
                  </TreeItem>
              ))
            : null;

    // Filter data to include only folders and files, ensuring data is defined
    const filteredData = Object.values(data || {}).filter((item) => item);

    return (
        <Box sx={{ minHeight: 140, flexGrow: 1 }}>
            <TreeView
                aria-label="file tree"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                sx={styles.treeView}
            >
                {filteredData &&
                    renderTree(
                        filteredData.map((item) => {
                            return { ...item, parentId: 0 };
                        })
                    )}
            </TreeView>
        </Box>
    );
}

const styles = {
    treeView: {
        padding: "10px",
    },
    treeIcon: {
        paddingRight: "10px",
    },
    textEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "200px",
        display: "inline-block",
        paddingLeft: "23px",
        verticalAlign: "middle",
    },
    treeItem: {
        padding: "5px 0",
        ".MuiTreeItem-label": {
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            borderRadius: "4px",
            transition: "background-color 0.3s ease",
            // ":hover": {
            //     backgroundColor: "rgba(0, 0, 0, 0.1)",
            // },
        },
        ".css-1dewol9-MuiTreeItem-root .MuiTreeItem-content.Mui-selected .MuiTreeItem-label":
            {
                backgroundColor: "none",
            },
        // ".MuiTreeItem-content.Mui-selected .MuiTreeItem-label": {
        //     backgroundColor: "rgba(0, 0, 255, 0.1)",
        // },
    },
};
