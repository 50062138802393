import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentChainList } from "../../../../../../../actions/DocumentChainActions";
import http from "../../../../../../../bae-client/HTTP";
import AddDocumentChainModal from "../add-docu-chain-modal";
import "./style.css";
import AlertDialog from "../../../../../../stylized/AlertDialog";

const GenericContainer = ({ list }) => {
    const dispatch = useDispatch();
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";

    const [showAddDocumentChainModal, setShowAddDocumentChainModal] =
        React.useState(false);
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const newDocListClickHandler = () => {
        setShowAddDocumentChainModal(true);
    };
    const closeModal = () => {
        setShowAddDocumentChainModal(false);
    };
    const addNewDocumentChain = (displayName, documentChainName) => {
        setShowAddDocumentChainModal(false);
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/generic?genericName=${documentChainName}&genericDisplayName=${displayName}`,
            "POST"
        )
            .then((response) => {
                setSnackbarMessage("Flow added successfully!");
                setShowSnackbar(true);
                dispatch(fetchDocumentChainList());
                setShowLoader(false);
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                setShowLoader(false);
                console.log(error);
            });
    };

    return (
        <>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <div style={{ margin: "auto", textAlign: "center" }}>
                    <div className="flow-lib">
                        <img src="./images/flow1.svg" alt="" />
                    </div>
                    <div className="containerHeader">
                        Create Your First Flow
                    </div>

                    {/* <img
                            src="./images/Add Space.png"
                            className={
                                isReadOnlyUser
                                    ? "readOnly icon"
                                    : "cursorPointer icon"
                            }
                            alt=""
                            title="New Generic Document"
                        />
                        <span
                            className={
                                isReadOnlyUser
                                    ? "buttonText readOnly"
                                    : "buttonText"
                            }
                            title="New Generic Document"
                        >
                            New Generic Document
                        </span> */}
                    {/* <button
                        className={"btn share modalButton"}
                        type="button"
                        id={"createNew"}
                        style={{
                            minWidth: "8rem",
                            height: "38px",
                            margin: "0rem",
                            // marginLeft: "60px",
                            background: "#0798EA",
                            textTransform: "capitalize",
                        }}
                        title="Create New"
                        value={"Create New"}
                        onClick={isReadOnlyUser ? null : newDocListClickHandler}
                    >
                        Create Flow
                    </button> */}
                    <button
                        className={
                            isReadOnlyUser
                                ? "btn share modalButton readOnly"
                                : "btn share modalButton"
                        }
                        type="button"
                        id={"createNew"}
                        style={{
                            margin: "0rem",
                            // marginLeft: "10px",
                            textTransform: "capitalize",
                        }}
                        title="Create New"
                        value={"Create New"}
                        onClick={
                            isReadOnlyUser
                                ? ""
                                : newDocListClickHandler
                        }
                    >
                        Create New
                    </button>
                </div>
                <AddDocumentChainModal
                    showAddDocumentChainModal={showAddDocumentChainModal}
                    closeModal={closeModal}
                    heading={"Create New Flow"}
                    addNewDocumentChain={addNewDocumentChain}
                    list={list}
                ></AddDocumentChainModal>
            </div>
            <AlertDialog
                open={showAlertDialog}
                setOpen={setShowAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
        </>
    );
};

export default GenericContainer;
