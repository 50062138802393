import { Col, Card, Row } from "react-bootstrap";
import "./index.css";

export default function StatsCard(props) {
    const { title, amount, perc, subtitle } = props;
    const percAmount = Number(perc);
    const faClass = percAmount < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up";
    const textClass = !(percAmount < 0) ? "text-error" : "text-success";

    let iconSrc = "";
    if (title === "Total Initiated​ Instances") {
        iconSrc = "./images/fileTypeIcon/intiatedAlert.svg";
    } else if (title === "Total Completed​ Instances") {
        iconSrc = "./images/fileTypeIcon/completeInstance.svg";
    } else if (title === "Total Delayed​ Instances") {
        iconSrc = "./images/fileTypeIcon/stepAlert.svg";
    } else if (title === "Step Alerts") {
        iconSrc = "./images/fileTypeIcon/stepAlert.svg";
    } else if (title === "Total Documents") {
        iconSrc = "./images/fileTypeIcon/docs.svg";
    } else if (title === "Total On Time​ Instances") {
        iconSrc = "./images/fileTypeIcon/timeAlert.svg";
    } else if (title === "Proactive Alerts") {
        iconSrc = "./images/fileTypeIcon/timeAlert.svg";
    }

    return (
        <Card
            className="card card-stats mb-4 mb-xl-0 "
            style={{
                border: "none",
                height: "90px",
            }}
        >
            {title === "Alerts" ? (
                <div className="" style={{ marginBottom: "10px" }}>
                    <Card.Body className="statCards">
                        <Row
                            style={{
                                justifyContent: "left",
                                alignItems: "center",
                                padding: "8px",
                                height: "inherit",
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "60px",
                                    height: "60px",
                                    // margin: "10px 0px 0px 15px",
                                    padding: "14px 0px 0px 0px",

                                    borderRadius: "20%",
                                    textAlign: "center",
                                }}
                            >
                                <img src={iconSrc}></img>
                            </Col>
                            <div className="dashboard_firstCol">
                                <Col>
                                    <h4
                                        className="card-title mb-4"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {title}
                                    </h4>
                                </Col>
                            </div>

                            {/* <div className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                    <i className="fas fa-chart-bar"></i>
                                </div>
                            </div> */}
                        </Row>
                    </Card.Body>
                </div>
            ) : (
                <div>
                    <Card.Body className="statCards">
                        <div className="row-stats">
                            <div
                                className="col-stats"
                                style={{ marginTop: "15px" }}
                            >
                                <img src={iconSrc} className="img-col1"></img>
                            </div>
                            <div className="dashboard_firstCol">
                                <div
                                    style={
                                        title === "Total Documents"
                                            ? { marginRight: "75px" }
                                            : title ===
                                              "Total On Time​ Instances"
                                            ? { marginRight: "21px" }
                                            : title ===
                                              "Total Delayed​ Instances"
                                            ? { marginRight: "24px" }
                                            : title ===
                                              "Total Initiated​ Instances"
                                            ? { marginRight: "20px" }
                                            : title === "Step Alerts"
                                            ? {
                                                  marginRight: "129px",
                                                  marginTop: "8px",
                                              }
                                              : title === "Proactive Alerts"
                                            ? {
                                                  marginRight: "96px",
                                                  marginTop: "8px",
                                              }
                                            : {}
                                    }
                                >
                                    <h5 className="card-title mb-0 titles">
                                        {title}
                                    </h5>
                                    <div className="dashboard_ratio">
                                        <div>
                                            <span className="h2 mb-0">
                                                {amount}
                                            </span>
                                        </div>

                                        <div>
                                            {/* <p className="mt-3 mb-0 text-muted text-sm">
                                            <span
                                                className={`${textClass} mr-2`}
                                                style={{
                                                    paddingRight: "12px",
                                                }}
                                            >
                                                <i className={faClass}></i>
                                                3.48%
                                            </span>
                                            <span className="text-nowrap">{subtitle}</span>
                                        </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                    <i className="fas fa-chart-bar"></i>
                                </div>
                            </div> */}
                        </div>
                    </Card.Body>
                </div>
            )}
        </Card>
    );
}
