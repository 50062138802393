export default [
    { DocumentColor: "#FFFFFF" },
    { WorkspaceColor: "#EBFFE8" },
    { SpaceColor: "#D7F0FF" },
    { Folder1Color: "#FFFCEF" },
    { Folder2Color: "#FFFCEF" },
    { Folder3Color: "#FFFCEF" },
    { Folder4Color: "#FFFCEF" },
    { Folder5Color: "#FFFCEF" },
    { Folder6Color: "#FFFCEF" },
    { Folder7Color: "#FFFCEF" },
    { Folder8Color: "#FFFCEF" },
    { Folder9Color: "#FFFCEF" },
    { Folder10Color: "#FFFCEF" },
];
