import React, { useState, useEffect } from "react";
import TextBox from "../../../../../../stylized/TextBox";

import AlertDialog from "../../../../../../stylized/AlertDialog";

import ContactList from "./contactList";

export default function NewChatUser({
    setNewChat,
    contactList,
    sendMessage,
    removeList,
    user,
    setAddNewUser,
    setIsNewChat,
}) {
    const [newUser, setNewUser] = useState(false);
    const [userAdded, setUserAdded] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");

    const handleDropdownSelect = (userArray) => {
        let user1 = user.map((user) => user.id);
        let user2 = userArray.map((user) => user.userId);
        let isFounded = user1.some((ai) => user2.includes(ai));

        if (isFounded) {
            // setShowAlertDialog(true);
            // setAlertDialogMessage(
            //     "The contact you added is already present in the chat"
            // );
            if (userArray.length === 1) {
                setAddNewUser(false);
                setIsNewChat(false);
            }
        }

        if (userArray.length) {
            setNewUser(true);
            setDisableButton(false);
            setNewChat(userArray.map((user) => user.userId));
        } else {
            setDisableButton(true);
            setNewChat([]);
        }
    };
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setUserAdded(true);
        }
    };

    useEffect(() => {
        if (userAdded && newUser) {
            setNewUser(false);
            setUserAdded(false);
        }
    }, [userAdded, newUser]);
    return (
        <div
            id={"newChatAddress"}
            style={{
                padding: "8px 16px",
                borderBottom: "2px solid #EBEBEB",
                minHeight: "64px",
                zIndex: "99",
            }}
        >
            <span
                style={{
                    float: "left",
                    fontSize: "small",
                    paddingRight: "8px",
                    marginTop: "9px",
                }}
            >
                To:
            </span>
            {/* <span style={{ float: "left", fontSize: "small" }}>
                <input
                    type="text"
                    placeholder="Enter a name"
                    onChange={handleValueChange}
                    style={{ border: "none" }}
                    value={newUser}
                    onBlur={handleBlur}
                />
            </span> */}
            <div
                style={{ float: "left" }}
                id={"newChatInput"}
                onKeyDown={handleKeyPress}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <ContactList
                        dropdownList={contactList}
                        handleDropdownSelect={handleDropdownSelect}
                    ></ContactList>
                    <AlertDialog
                        open={showAlertDialog}
                        message={alertDialogMessage}
                        title={""}
                        isAlert={false}
                        setOpen={changeOpenDialogFlag}
                    ></AlertDialog>
                    {user.length > 0 ? (
                        <button
                            className={
                                disableButton
                                    ? "cursorNotAllowed"
                                    : "cursorPointer"
                            }
                            disabled={disableButton}
                            style={{
                                background: "#0798EA",
                                marginLeft: "20px",
                                borderRadius: "10px",
                                color: "white",
                                border: "none",
                                padding: "6px 21px",
                            }}
                            onClick={sendMessage}
                        >
                            Add
                        </button>
                    ) : null}
                    <button
                        className="cursorPointer"
                        style={{
                            background: "#fff",
                            marginLeft: "20px",
                            borderRadius: "10px",
                            color: "#000",
                            border: "1px solid #000",
                            padding: "6px 21px",
                        }}
                        onClick={removeList}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}
