export const directoryList = (directoryListData = null, action) => {
    if (action.type === "FETCH_DIRECTORY_LIST") {
        return action.payload;
    }
    return directoryListData;
};

export const directoryUsersList = (directoryUsersListData = null, action) => {
    if (action.type === "FETCH_DIRECTORY_USERS_LIST") {
        return action.payload;
    }
    return directoryUsersListData;
};