import React, { Component } from "react";
import { executeClickEvents } from "../helper/EventHelper";
import { Card } from "react-bootstrap";

class CardView extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        showHideFName: true,
    };

    handleClickEvent = (e, selectedId) => {
        if (this.props.onbodyclick !== undefined) {
            this.props.setSelectedCard(true, this.props.index, selectedId);
            // this.props.onSelect(true);
            this.renderClassname();
        }
    };

    handleDoubleClickEvent = (e) => {
        if (this.props.onbodyclick !== undefined) {
            this.props.onbodyclick(
                this.props.spaceInstance,
                e,
                this.props.index,
                this.props.fileName,
                this.props.fileType
            );
        }
    };
    handleMenuClickEvent = (e) => {
        if (this.props.onMenuBtnClick !== undefined) {
            this.props.onMenuBtnClick(e);
        }
    };
    renderClassname = () => {
        if (this.props.index === this.props.activeIndex)
            return "heighlightCard ";
        else return "";
    };
    renderLeftIcon = () => {
        if (this.props.headerLeftIcon !== undefined) {
            return (
                <div className="col-sm-2 p-0 doCenter">
                    {this.props.headerLeftIcon}
                </div>
            );
        } else {
            return <div className="col-sm-2 p-0"></div>;
        }
    };
    renderRightIcon = () => {
        if (this.props.headerRightIcon !== undefined) {
            return (
                <div
                    className="col-sm-2 doCenter"
                    style={{ cursor: "pointer" }}
                    onClick={this.handleMenuClickEvent}
                    id="menu_click"
                >
                    {this.props.headerRightIcon}
                </div>
            );
        } else {
            return <div className="col-sm-2"></div>;
        }
    };
    renderTitle = (text, className) => {
        if (this.props.headerTitle !== undefined) {
            return (
                <div className="col doCenter titleHeaderTitle p-0">
                    <span className={"textEllipsis " + className}>
                        {this.props.headerTitle}
                    </span>
                </div>
            );
        } else {
            return <div className="col p-0"></div>;
        }
    };
    changeState = () => {
        this.setState({ showHideFName: !this.state.showHideFName });
    };

    renderColums = () => {
        let items = [];
        items.push(this.renderLeftIcon());
        items.push(this.renderTitle());
        items.push(this.renderRightIcon());
        return items;
    };
    render() {
        const { showHideFName } = this.state;
        return (
            <div
                className={
                    " card " +
                    this.props.cardClass +
                    " " +
                    this.renderClassname()
                }
                id={"card_" + this.props.id}
                // onClick={this.handleClickEvent}>
            >
                <div className={"card-header " + this.props.cardHeaderClass}>
                    <div className="row w-100 m-0">{this.renderColums()}</div>
                </div>
                <div
                    className={"card-body " + this.props.cardBodyClass}
                    id={this.props.id}
                    onClick={(event) =>
                        executeClickEvents(
                            event,
                            this.handleClickEvent,
                            this.handleDoubleClickEvent,
                            this.props.id
                        )
                    }
                >
                    <p
                        style={{ fontSize: "5px" }}
                        className={
                            "spaceCard_body_text textEllipsis w-100 h-100 " +
                            this.props.cardBodyText
                        }
                        title={this.props.bodyData}
                    >
                        {this.props.imageSource ? (
                            <>
                                <img
                                    style={{ width: "40px" }}
                                    // style={{ padding: "25px" }}
                                    src={this.props.imageSource}
                                    alt="File/Folder Icon"
                                ></img>
                                <div
                                    className="spaceCard_body_text textEllipsis w-100"
                                    style={{ marginTop: "15px" }}
                                >
                                    {this.props.bodyData}
                                </div>
                            </>
                        ) : (
                            this.props.bodyData
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default CardView;
