import Chip from "@mui/material/Chip";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import http from "../../../../../../bae-client/HTTP";
import DocViwer from "../../../../../stylized/DocViwer";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import { getChatTimestamp } from "../../../../../../bae-client/Utils";
import AddStep from "../modals/AddStep";
import AddBranch from "../modals/AddBranch";
import FormWrapper from "../../Integration/FormWrapper/FormWrapper";
import DialogView from "../../../../../stylized/DialogView";
import AddStepStepper from "../modals/AddStepStepper";

import AlertDialog from "../../../../../stylized/AlertDialog";
import Loader from "../../../../../stylized/Loader";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
const InstanceStep = ({
    instanceDetails,
    durationStatus,
    instanceBlockDocumentName,
    uploadDocumentInTheInstance,
    genericId,
    handleInstanceClick,
    selectedInstance,
    steps,
    expectedDateMap,
    addBranch,
    fetchInstanceList,
    deleteBranch,
    onChangeFileUpload,
    uploadFileFromUC,
    previousStep,
    editStep,
    instanceSteps,
    index,
    isMergeModal,
    handleSourceRadioClick,
    handleTargetRadioClick,
    action,
    genericSteps,
}) => {
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [showStepperForm, setShowStepperForm] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const [showEditStepModal, setShowEditStepModal] = React.useState(false);
    const [branchShow, setBranchShow] = React.useState(false);
    const [branchDelete, setBranchDelete] = React.useState(false);
    const [rawDataToEdit, setRawDataToEdit] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const tableFileUplode = React.useRef(null);

    const [documentModalShow, setDocumentModalShow] = React.useState(false);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [currentShowFileName, setCurrentShowFileName] = React.useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] =
        React.useState("");
    const [showUploadOption, setShowUploadOption] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [anchorUploadEl, setanchorUploadEl] = React.useState(false);
    const openDocMenu = Boolean(anchorUploadEl);

    const open = Boolean(anchorEl);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setanchorUploadEl(null);
    };
    const handleCloseStepperForm = () => {
        setShowStepperForm(false);
    };
    const handleFormShow = () => {
        setShowStepperForm(true);
        setAnchorEl(null);
    };
    function findLastExecutedStep(data) {
        let latestDate = null;
        let latestActualDateItem = null; // Renamed variable to better reflect purpose

        data.forEach((item) => {
            if (item.actualDate) {
                let currentDate = new Date(item.actualDate);
                if (!latestDate || currentDate > latestDate) {
                    latestDate = currentDate;
                    latestActualDateItem = item; // Store the item itself, not its actualDate property
                }
            }
        });
        return latestActualDateItem; // Return the actualDate property of the latest item
    }

    const openDocumentSubMenu = (e) => {
        setanchorUploadEl(e.currentTarget);
        setAnchorEl(null);
    };
    const closeOpenDocumentSubMenu = () => {
        setanchorUploadEl(null);
    };
    const deleteClickHandler = (e) => {
        setAnchorEl(null);
        setAlertDialogContent({
            title: "Delete Branch",
            message: `Are you sure you want to delete rest of the steps from ${instanceDetails.branchName} Branch?`,
            isAlert: true,
            buttonName: "DELETE",
            buttonId: "btn_delete",
        });
        setAlertDialogShow(true);
    };
    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });

        if (response === "YES") {
            if (isDelete === "DELETE") {
                setShowLoader(true);
                http.sendRestRequest(
                    `documentChain/instance/branch?genericId=${instanceDetails.genericId}&instanceStepId=${instanceDetails.instanceStepId}`,
                    "DELETE"
                )
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            setSnackbarMessage("Branch Deleted successfully!");
                            handleInstanceClick(selectedInstance);
                            setShowSnackbar(true);
                        } else {
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err.error,
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        if (
            e.target &&
            (e.target.id === "upload" ||
                e.target.parentNode.id === "upload" ||
                e.target.id === "replace" ||
                e.target.parentNode.id === "replace")
        ) {
            // openDocumentSubMenu(e);
            tableFileUplode.current.click();
        } else if (
            e.target &&
            (e.target.id === "open" || e.target.parentNode.id === "open")
        ) {
            showModalView(
                instanceDetails.instanceBlockDocumentName,
                instanceDetails.instanceBlockDocumentId
            );
        } else if (
            e.target &&
            (e.target.id === "edit" || e.target.parentNode.id === "edit")
        ) {
            setShowEditStepModal(true);

            setRawDataToEdit({
                genericStepUniqueIdentifier:
                    instanceDetails.instanceUniqueStepIdentifier,
                toEntity: instanceDetails.to,
                fromEntity: instanceDetails.from,
                genericStepName: instanceDetails.instanceStepName,
                expectedDuration: instanceDetails.expectedDuration,
                expectedDurationFrom: instanceDetails.expectedDurationFrom,
                documentName: instanceDetails.instanceBlockDocumentName,
                workspaceId: instanceDetails.workspaceId,
                spaceId: instanceDetails.spaceId,
                folderIdPath: instanceDetails.folderIdPath,
                parentInstanceStepId: instanceDetails.parentInstanceStepId,
                expectedDate: instanceDetails.expectedDate,
                branchId: instanceDetails.branchId,
                storageLocation: instanceDetails.storageLocation,
            });
        } else if (
            e.target &&
            (e.target.id === "branch" || e.target.parentNode.id === "branch")
        ) {
            setBranchShow(true);
        }
    };
    const showModalView = (fileName, documentId) => {
        setShowLoader(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                setShowLoader(false);
                if (!response.documentStream) {
                    showAlertView(
                        "Operation Failed",
                        "Document not available, Please contact admin",
                        "",
                        "",
                        true
                    );
                } else {
                    setCurrentShowFileName(fileName);
                    const fileType = response.documentName.split(".").pop();
                    if (fileType === "pdf") {
                        setTimeout(() => {
                            setCurrentShowFileLocation(response);
                        }, 1);
                    } else {
                        setCurrentShowFileLocation(response);
                    }
                    setDocumentModalShow(true);
                }
            })
            .catch((error) => {
                setShowLoader(false);
                showAlertView("Operation Failed", error.error, "", "", true);
                console.log(error);
            });
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        // setCurrentShowFileLocation("");
    };

    const closeEditModal = () => {
        setShowEditStepModal(false);
        setRawDataToEdit(null);
        setBranchShow(false);
        setBranchDelete(false);
    };
    const isDataChanged = (newData) => {
        let newDataKeys = Object.keys(newData);
        let editedKeys = [];
        newDataKeys.forEach((key) => {
            if (
                rawDataToEdit[key] !== undefined &&
                rawDataToEdit[key] !== newData[key]
            ) {
                editedKeys.push(key);
            }
        });
        return editedKeys;
    };

    const editExistingStep = (data) => {
        setShowEditStepModal(false);
        let changedDataKeys = isDataChanged(data);
        let payload = {};
        if (changedDataKeys.length) {
            changedDataKeys.forEach((key) => {
                if (key === "fromEntity") {
                    payload["from"] = data[key];
                } else if (key === "toEntity") {
                    payload["to"] = data[key];
                } else if (key === "genericStepName") {
                    payload["instanceStepName"] = data[key];
                } else {
                    payload[key] = data[key];
                }
            });

            // payload.genericStepId = instanceDetails.genericStepId;
            // payload.stepName = instanceDetails.instanceStepName;
            // payload.parentInstanceStepId = instanceDetails.parentInstanceStepId;

            payload.instanceStepId = instanceDetails.instanceStepId;
            payload.folderIdPath =
                data.folderIdPath === "" ? "" : data.folderIdPath;
            // payload.instanceId = instanceDetails.instanceId;
            editStep(payload);
        }
        setRawDataToEdit(null);
    };
    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
        setShowEditStepModal(false);
        setShowStepperForm(false);
    };
    const handleShareAttachment = (shareObject) => {
        const fileData = {
            documentCuboidId: parseInt(shareObject.documentId),
            documentName: shareObject.folderFileName,
            headerCuboidId: parseInt(shareObject.folderFileId),
            spaceId: parseInt(shareObject.spaceId),
        };
        uploadFileFromUC(fileData, instanceDetails, genericId);
    };
    const renderUcDocumentUpload = (e) => {
        return (
            <DialogView
                show={showUploadOption}
                size="lg"
                handleClose={handleCloseUploadDialog}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <FormWrapper
                    selectedInstance={selectedInstance}
                    onHide={handleCloseUploadDialog}
                    accountType={"headerTitle"}
                    defaultStep={1}
                    Display={setDisplayShow}
                    // formType={"shareChat"}
                    handleShareAttachment={handleShareAttachment}
                    heading={"Upload File"}
                ></FormWrapper>
            </DialogView>
        );
    };

    const getMenuItems = (instanceDetails, index, steps) => {
        const lastExecutedStep = findLastExecutedStep(steps);
        const lastExecutedStepId = lastExecutedStep
            ? lastExecutedStep.instanceStepId
            : null;

        if (instanceDetails.durationStatus === "PENDING") {
            if (index !== 0)
                if (instanceDetails.blockType === "Optional")
                    return [
                        <MenuItem
                            onClick={handleClose}
                            id="upload"
                            key="upload"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="upload"
                                style={{ paddingLeft: "4px" }}
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/upload.png"
                                    id="upload-img"
                                    alt="Upload"
                                    title="Upload"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Upload"
                                >
                                    Upload
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={handleFormShow}
                            id="upload from workspace"
                            key="upload from workspace"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="upload from workspace"
                                style={{ paddingLeft: "4px" }}
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    id="upload-img-from-ws"
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/upload.png"
                                    alt="Upload from workspace"
                                    title="Upload from workspace"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Upload from workspace"
                                >
                                    Upload from workspace
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={handleClose}
                            id="edit"
                            key="edit"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id={`div_edit_${index}`}
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/Rename.svg"
                                    alt="Edit"
                                    title="Edit"
                                    style={{ width: "18px", height: "18px" }}
                                    id="edit"
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Edit"
                                    id="edit"
                                >
                                    Edit
                                </span>
                            </div>
                        </MenuItem>,
                    ];
                else
                    return [
                        <MenuItem
                            onClick={handleClose}
                            id="upload"
                            key="upload"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="upload"
                                style={{ paddingLeft: "4px" }}
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    id="upload-img"
                                    src="./images/upload.png"
                                    alt="Upload"
                                    title="Upload"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Upload"
                                >
                                    Upload
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={handleFormShow}
                            id="upload from workspace"
                            key="upload from workspace"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="upload from workspace"
                                style={{ paddingLeft: "4px" }}
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    id="upload-img-from-ws"
                                    src="./images/upload.png"
                                    alt="Upload from workspace"
                                    title="Upload from workspace"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Upload from workspace"
                                >
                                    Upload from workspace
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={handleClose}
                            id="edit"
                            key="edit"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id={`div_edit_${index}`}
                                calssName={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/Rename.svg"
                                    alt="Edit"
                                    title="Edit"
                                    style={{ width: "18px", height: "18px" }}
                                    id="edit"
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    id="edit"
                                    title="Edit"
                                >
                                    Edit
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={handleClose}
                            id="branch"
                            key="edit"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="branch"
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/branch.svg"
                                    alt="Branch"
                                    title="Branch"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Branch"
                                >
                                    Branch
                                </span>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            onClick={deleteClickHandler}
                            id="edit"
                            key="edit"
                            disabled={isReadOnlyUser}
                        >
                            <div
                                id="delete branch"
                                className={isReadOnlyUser ? "readOnly" : ""}
                            >
                                <img
                                    className={
                                        isReadOnlyUser
                                            ? "renameIcon readOnly"
                                            : "renameIcon"
                                    }
                                    src="./images/trash 2.svg"
                                    alt="Delete Branch"
                                    title="Delete Branch"
                                    style={{ width: "18px", height: "18px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Delete Branch"
                                >
                                    Delete Branch
                                </span>
                            </div>
                        </MenuItem>,
                    ];
            else
                return [
                    <MenuItem
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                id="upload-img"
                                src="./images/upload.png"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload"
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                id="upload-img-from-ws"
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id={`div_edit_${index}`}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                                id="edit"
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                                id="edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                ];
        } else if (
            instanceDetails.durationStatus === "DELAYED" ||
            instanceDetails.durationStatus === "CUSTOM" ||
            instanceDetails.durationStatus === "DATE CHANGED" ||
            instanceDetails.durationStatus === "DONE-RED"
        ) {
            if (instanceDetails.instanceBlockDocumentName) {
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id={`div_edit_${index}`}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                                id="edit"
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                                id="edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="branch"
                        key="branch"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                    lastExecutedStepId === instanceDetails.instanceStepId && (
                        <MenuItem
                            onClick={handleClose}
                            id="replace"
                            key="replace"
                        >
                            <div id="replace">
                                <img
                                    className="replaceIcon"
                                    src="./images/upload.png"
                                    alt="Replace"
                                    title="Replace"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Replace"
                                    style={{ paddingLeft: "15px" }}
                                >
                                    Replace Document
                                </span>
                            </div>
                        </MenuItem>
                    ),
                ];
            } else
                return [
                    <MenuItem
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                id="upload-img"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload"
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                id="upload-img-from-ws"
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id={`div_edit_${index}`}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                                id="edit"
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                                id="edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={deleteClickHandler}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="delete branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/trash 2.svg"
                                alt="Delete Branch"
                                title="Delete Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Delete Branch"
                            >
                                Delete Branch
                            </span>
                        </div>
                    </MenuItem>,
                ];
        } else if (
            instanceDetails.durationStatus === "DONE" ||
            instanceDetails.durationStatus === "DONE-GREEN"
        ) {
            if (index === 0 || instanceDetails.blockType === "Optional")
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id={`div_edit_${index}`}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                                id="edit"
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                                id="edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    lastExecutedStepId === instanceDetails.instanceStepId && (
                        <MenuItem
                            onClick={handleClose}
                            id="replace"
                            key="replace"
                        >
                            <div id="replace">
                                <img
                                    className="replaceIcon"
                                    src="./images/upload.png"
                                    alt="Replace"
                                    title="Replace"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Replace"
                                    style={{ paddingLeft: "15px" }}
                                >
                                    Replace Document
                                </span>
                            </div>
                        </MenuItem>
                    ),
                ];
            else
                return [
                    <MenuItem onClick={handleClose} id="open">
                        <div id="open">
                            <img
                                className="deleteIcon"
                                src="./images/open.png"
                                alt="Open"
                                title="Open"
                            />
                            <span className="btn_title" title="Open">
                                Open
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="edit"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id={`div_edit_${index}`}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/Rename.svg"
                                alt="Edit"
                                title="Edit"
                                style={{ width: "18px", height: "18px" }}
                                id="edit"
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Edit"
                                id="edit"
                            >
                                Edit
                            </span>
                        </div>
                    </MenuItem>,
                    <MenuItem
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>,
                    lastExecutedStepId === instanceDetails.instanceStepId && (
                        <MenuItem
                            onClick={handleClose}
                            id="replace"
                            key="replace"
                        >
                            <div id="replace">
                                <img
                                    className="replaceIcon"
                                    src="./images/upload.png"
                                    alt="Replace"
                                    title="Replace"
                                    style={{ width: "10px", height: "21px" }}
                                />
                                <span
                                    className={
                                        isReadOnlyUser
                                            ? "btn_title readOnly"
                                            : "btn_title"
                                    }
                                    title="Replace"
                                    style={{ paddingLeft: "15px" }}
                                >
                                    Replace Document
                                </span>
                            </div>
                        </MenuItem>
                    ),
                ];
        } else if (instanceDetails.durationStatus === "SKIPPED")
            return [
                index !== 0 && instanceDetails.blockType !== "Optional" && (
                    <MenuItem
                        onClick={handleClose}
                        id="branch"
                        key="edit"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="branch"
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/branch.svg"
                                alt="Branch"
                                title="Branch"
                                style={{ width: "18px", height: "18px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Branch"
                            >
                                Branch
                            </span>
                        </div>
                    </MenuItem>
                ),
                instanceSteps.filter(
                    (step) =>
                        step.instanceStepName ===
                            instanceDetails.instanceStepName &&
                        step.branchId === instanceDetails.branchId
                ).length < 2 && (
                    <MenuItem
                        onClick={handleClose}
                        id="upload"
                        key="upload"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                id="upload-img"
                                alt="Upload"
                                title="Upload"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload"
                            >
                                Upload
                            </span>
                        </div>
                    </MenuItem>
                ),
                instanceSteps.filter(
                    (step) =>
                        step.instanceStepName ===
                            instanceDetails.instanceStepName &&
                        step.branchId === instanceDetails.branchId
                ).length < 2 && (
                    <MenuItem
                        onClick={handleFormShow}
                        id="upload from workspace"
                        key="upload from workspace"
                        disabled={isReadOnlyUser}
                    >
                        <div
                            id="upload from workspace"
                            style={{ paddingLeft: "4px" }}
                            className={isReadOnlyUser ? "readOnly" : ""}
                        >
                            <img
                                id="upload-img-from-ws"
                                className={
                                    isReadOnlyUser
                                        ? "renameIcon readOnly"
                                        : "renameIcon"
                                }
                                src="./images/upload.png"
                                alt="Upload from workspace"
                                title="Upload from workspace"
                                style={{ width: "10px", height: "21px" }}
                            />
                            <span
                                className={
                                    isReadOnlyUser
                                        ? "btn_title readOnly"
                                        : "btn_title"
                                }
                                title="Upload from workspace"
                            >
                                Upload from workspace
                            </span>
                        </div>
                    </MenuItem>
                ),
            ];
    };

    /**
     * @param {Object} prevStep - the previous step object
     * @param {number} duration - the duration in minutes
     * @return {number} the calculated actual date in milliseconds
     */
    const getActualDate = (prevStep, duration) => {
        if (!prevStep) return "";
        if (prevStep.actualDate) {
            return new Date(prevStep.actualDate).getTime() + duration * 60000;
        }
        if (prevStep.expectedDate) {
            return new Date(prevStep.expectedDate).getTime() + duration * 60000;
        } else
            return getActualDate(
                instanceSteps.find(
                    (step) =>
                        step.instanceStepId === prevStep?.expectedDurationFrom
                ),
                prevStep.expectedDuration + duration
            );
    };
    /**
     * Returns the expected date for a given instance step.
     *
     * @param {object} instanceStep - The instance step for which to calculate the expected date
     * @return {Date} The expected date for the instance step in UTC format which needs to be converted to local date
     */
    const getExpectedDateForStep = (instanceStep) => {
        if (!instanceStep) return "";
        if (instanceStep.expectedDate) return instanceStep.expectedDate;
        const prevStep = instanceSteps.find(
            (step) => step.instanceStepId === instanceStep?.expectedDurationFrom
        );
        if (!prevStep) return "";
        const dateInTime = getActualDate(
            prevStep,
            instanceStep.expectedDuration
        );
        return new Date(dateInTime);
    };
    const renderExpectedTimeRow = () => {
        const isDocumentAvailable =
            instanceDetails.instanceBlockDocumentName !== "" ? true : false;
        const dateToShow = isDocumentAvailable
            ? instanceDetails.actualDate
            : instanceDetails.expectedDate;
        let expectedDate = instanceDetails?.expectedDate
            ? instanceDetails?.expectedDate
            : expectedDateMap?.get(instanceDetails?.instanceStepId)
                  ?.expectedDate;
        return (
            <div className="content textEllipsis">
                {isDocumentAvailable && "Uploaded On: "}
                {isDocumentAvailable &&
                    getChatTimestamp(dateToShow, "mm/dd/yyyy hh:mm aa")}
                <div>
                    {!isDocumentAvailable && "Expected On: "}
                    {!isDocumentAvailable
                        ? getChatTimestamp(expectedDate, "mm/dd/yyyy hh:mm aa")
                        : null}
                </div>
            </div>
        );
    };
    // const renderExpectedTimeRow = () => {
    //     const isInstanceStarted = instanceSteps?.filter(
    //         (step) => step.actualDate
    //     ).length
    //         ? true
    //         : false;
    //     const isDocumentAvailable =
    //         instanceDetails.instanceBlockDocumentName !== "" ? true : false;
    //     const dateToShow = isDocumentAvailable
    //         ? instanceDetails.actualDate
    //         : isInstanceStarted
    //         ? getExpectedDateForStep(instanceDetails)
    //         : instanceDetails.expectedDate;
    //     return (
    //         <div className="content textEllipsis">
    //             {isDocumentAvailable ? "Uploaded On: " : "Expected On: "}
    //             {getChatTimestamp(dateToShow, "mm/dd/yyyy hh:mm aa")}
    //         </div>
    //     );
    // };
    return (
        <>
            {instanceDetails.durationStatus === "PENDING" ||
            instanceDetails.durationStatus === undefined ||
            instanceDetails.durationStatus === "" ? (
                <div
                    className="pendingStepContainer"
                    style={{
                        border: "1px dashed #000000",
                        borderRadius: "10px",
                    }}
                >
                    <div>
                        {isMergeModal && action === "Next" ? (
                            <input
                                type="radio"
                                name={instanceDetails.branchName}
                                onChange={(event) => {
                                    handleSourceRadioClick(
                                        event,
                                        instanceSteps[index].instanceStepId,
                                        instanceSteps[index]
                                            .instanceUniqueStepIdentifier,
                                        instanceDetails.branchName
                                    );
                                }}
                            ></input>
                        ) : (
                            ""
                        )}
                        {isMergeModal && action === "Merge" ? (
                            <input
                                type="radio"
                                name="instanceStep"
                                onChange={(event) => {
                                    handleTargetRadioClick(
                                        event,
                                        instanceSteps[index].instanceStepId
                                    );
                                }}
                            ></input>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">
                            {`Name: ${instanceDetails.instanceStepName}`}
                        </div>

                        {!isMergeModal && (
                            <div
                                id={
                                    "div_intanceOpt_" +
                                    instanceDetails.instanceStepId
                                }
                                onClick={(event) => handleClick(event)}
                                style={{
                                    cursor: "pointer",
                                    height: "24px",
                                }}
                            >
                                <img
                                    alt={"options"}
                                    title="options"
                                    src="./images/options.png"
                                ></img>
                            </div>
                        )}
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">{`Branch Name: ${instanceDetails.branchName}`}</div>
                    </div>

                    <div
                        className="contentRow"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div
                            className="content textEllipsis"
                            title={instanceDetails.from}
                        >
                            From: {instanceDetails.from}
                        </div>
                        <div
                            className="content textEllipsis"
                            title={instanceDetails.to}
                            style={{ paddingLeft: "12px" }}
                        >
                            To: {instanceDetails.to}
                        </div>

                        <div className="content contentChip textEllipsis">
                            {instanceDetails.blockType === "Optional" ? (
                                <Chip
                                    label={"OPTIONAL"}
                                    style={{
                                        color: "black",
                                        background: "white",
                                        border: "1px solid black",
                                        width: "100px",
                                        height: "30px",
                                        marginBottom: "12px",
                                    }}
                                    size="medium"
                                ></Chip>
                            ) : null}
                        </div>
                    </div>

                    <div className="contentRow">
                        {/* <div className="content"> */}
                        {renderExpectedTimeRow(instanceDetails)}
                        {/* </div> */}
                        <div className="content contentChip textEllipsis">
                            <Chip
                                label={"PENDING"}
                                style={{
                                    width: "100px",
                                    height: "30px",
                                }}
                                size="medium"
                            ></Chip>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        border:
                            instanceDetails.durationStatus === "DONE-GREEN" ||
                            instanceDetails.durationStatus === "DONE"
                                ? "3px solid #02b734"
                                : instanceDetails.durationStatus === "DONE-RED"
                                ? "3px solid red"
                                : instanceDetails.durationStatus === "SKIPPED"
                                ? "3px solid yellow"
                                : "3px solid #cc0000",
                        backgroundColor:
                            instanceDetails.durationStatus === "DONE-GREEN" ||
                            instanceDetails.durationStatus === "DONE"
                                ? "#f4fbff"
                                : instanceDetails.durationStatus === "DONE-RED"
                                ? "rgba(252, 0, 0, 0.04)"
                                : instanceDetails.durationStatus === "SKIPPED"
                                ? "rgba(252, 252, 0, 0.04)"
                                : "rgba(252, 0, 0, 0.04)",
                    }}
                    className={
                        instanceDetails.durationStatus === "DELAYED" ||
                        instanceDetails.durationStatus === "CUSTOM" ||
                        instanceDetails.durationStatus === "DATE CHANGED" ||
                        instanceDetails.durationStatus === "DONE-RED"
                            ? "delayedStepContainer"
                            : "doneStepContainer"
                    }
                >
                    <div>
                        {isMergeModal && action === "Next" ? (
                            <input
                                type="radio"
                                name={instanceDetails.branchName}
                                onChange={(event) => {
                                    handleSourceRadioClick(
                                        event,
                                        instanceSteps[index].instanceStepId,
                                        instanceSteps[index]
                                            .instanceUniqueStepIdentifier,
                                        instanceDetails.branchName
                                    );
                                }}
                            ></input>
                        ) : (
                            ""
                        )}
                        {isMergeModal && action === "Merge" ? (
                            <input
                                type="radio"
                                name="instanceStep"
                                onChange={(event) => {
                                    handleTargetRadioClick(
                                        event,
                                        instanceSteps[index].instanceStepId
                                    );
                                }}
                            ></input>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">
                            {`Name: ${instanceDetails.instanceStepName}`}
                        </div>

                        {!isMergeModal && (
                            <div
                                id={
                                    "div_intanceOpt_" +
                                    instanceDetails.instanceStepId
                                }
                                onClick={(event) => handleClick(event)}
                                style={{
                                    cursor: "pointer",
                                    height: "24px",
                                }}
                            >
                                <img
                                    alt={"options"}
                                    title="options"
                                    src="./images/options.png"
                                ></img>
                            </div>
                        )}
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">{`Branch Name: ${instanceDetails.branchName}`}</div>
                    </div>
                    <div className="contentRow">
                        <div className="content textEllipsis">
                            File Name:{" "}
                            {instanceDetails.instanceBlockDocumentName}
                        </div>
                    </div>
                    <div
                        className="contentRow"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div className="content textEllipsis">
                            From: {instanceDetails.from}
                        </div>
                        <div
                            className="content textEllipsis"
                            style={{ paddingLeft: "12px" }}
                        >
                            To: {instanceDetails.to}
                        </div>
                        <div className="content contentChip textEllipsis">
                            {instanceDetails.blockType === "Optional" ? (
                                <Chip
                                    label={"OPTIONAL"}
                                    style={{
                                        color: "black",
                                        background: "white",
                                        border: "1px solid black",
                                        width: "100px",
                                        height: "30px",
                                        marginBottom: "12px",
                                    }}
                                    size="medium"
                                ></Chip>
                            ) : null}
                        </div>
                    </div>

                    {/* <div className="contentRow">
                        <div className="content">
                            Location: {instanceDetails.location}
                        </div>
                    </div> */}
                    <div className="contentRow">
                        {renderExpectedTimeRow(instanceDetails)}
                    </div>

                    <div
                        className="contentRow"
                        style={{ justifyContent: "flex-end" }}
                    >
                        {instanceDetails.exceptions.length ? (
                            <div className="flex ">
                                <div
                                    className="chips-container"
                                    style={{ display: "flex" }}
                                >
                                    {instanceDetails.hasOwnProperty(
                                        "exceptions"
                                    ) &&
                                        instanceDetails.exceptions.map(
                                            (exception, index) => (
                                                <div
                                                    className="content contentChip textEllipsis"
                                                    style={{
                                                        marginTop: "0px",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <Chip
                                                        key={index}
                                                        label={exception}
                                                        title={exception}
                                                        style={{
                                                            width:
                                                                exception ===
                                                                    "KEY MISMATCH" ||
                                                                exception ===
                                                                    "KEY MISSING"
                                                                    ? "130px"
                                                                    : "100px",
                                                            height: "30px",
                                                            background:
                                                                exception ===
                                                                    "DONE" ||
                                                                exception ===
                                                                    "DONE-GREEN"
                                                                    ? "#02b734"
                                                                    : exception ===
                                                                      "SKIPPED"
                                                                    ? "yellow"
                                                                    : "#cc0000",
                                                            color:
                                                                exception ===
                                                                    "DONE" ||
                                                                exception ===
                                                                    "DONE-GREEN"
                                                                    ? "white"
                                                                    : exception ===
                                                                      "SKIPPED"
                                                                    ? "black"
                                                                    : "white",
                                                        }}
                                                    />
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="content contentChip textEllipsis"
                                style={{ marginTop: "0px", padding: "5px" }}
                            >
                                <Chip
                                    title={
                                        instanceDetails.durationStatus ===
                                            "DONE-GREEN" ||
                                        instanceDetails.durationStatus ===
                                            "DONE-RED"
                                            ? "DONE"
                                            : instanceDetails.durationStatus
                                    }
                                    label={
                                        instanceDetails.durationStatus ===
                                            "DONE-GREEN" ||
                                        instanceDetails.durationStatus ===
                                            "DONE-RED"
                                            ? "DONE"
                                            : instanceDetails.durationStatus
                                    }
                                    style={{
                                        width:
                                            instanceDetails.durationStatus ===
                                                "KEY MISMATCH" ||
                                            instanceDetails.durationStatus ===
                                                "KEY MISSING"
                                                ? "130px"
                                                : "100px",
                                        height: "30px",
                                        background:
                                            instanceDetails.durationStatus ===
                                                "DONE" ||
                                            instanceDetails.durationStatus ===
                                                "DONE-GREEN"
                                                ? "#02b734"
                                                : instanceDetails.durationStatus ===
                                                  "SKIPPED"
                                                ? "yellow"
                                                : "#cc0000",
                                        color:
                                            instanceDetails.durationStatus ===
                                                "DONE" ||
                                            instanceDetails.durationStatus ===
                                                "DONE-GREEN"
                                                ? "white"
                                                : instanceDetails.durationStatus ===
                                                  "SKIPPED"
                                                ? "black"
                                                : "white",
                                    }}
                                    size="medium"
                                ></Chip>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* {renderSubmenu()} */}
            {/* {renderUcDocumentUpload()} */}
            {getMenuItems(instanceDetails, index, steps).filter((item) => item)
                .length ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {getMenuItems(instanceDetails, index, steps)}
                </Menu>
            ) : null}
            <input
                type="file"
                id="file"
                ref={tableFileUplode}
                hidden={true}
                onChange={(event) =>
                    onChangeFileUpload(
                        event,
                        instanceDetails,
                        genericId,
                        previousStep
                    )
                }
                onClick={(event) => {
                    event.currentTarget.value = null;
                }}
                multiple
            />
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={documentModalShow}
                size="lg"
                documentId={instanceDetails.instanceBlockDocumentId}
                onHide={handleDocumentModalClose}
                title=""
                modalBodyClass="documentViewModal"
                onclick={() => {}}
                submitButtonText="Close"
                headerTitle={currentShowFileName}
                fileSource={currentShowFileLocation}
            >
                {documentModalShow ? renderViewDocumentModal() : null}
            </DocumentViewerModal>

            <AddBranch
                showBranch={branchShow}
                setBranchShow={setBranchShow}
                closeModal={closeEditModal}
                title={"Branch"}
                btnName={"Delete"}
                steps={steps}
                addBranch={addBranch}
                instanceDetails={instanceDetails}
                instanceSteps={instanceSteps}
            ></AddBranch>

            {/* <DeleteBranch
                showBranch={branchDelete}
                closeModal={closeEditModal}
                deleteBranch={deleteBranch}
                genericId={setgenericBranchId}
                instanceStepId={setinstanceStepId}
                setBranchDelete={setBranchDelete}
                items={steps}
            ></DeleteBranch> */}

            {rawDataToEdit && rawDataToEdit.documentName ? (
                <AddStep
                    showAddDocumentChainModal={showEditStepModal}
                    closeModal={closeEditModal}
                    addNewStep={editExistingStep}
                    title={"Edit Step"}
                    btnName={"Update"}
                    genericStepList={[]}
                    editStepData={rawDataToEdit}
                    isInstanceStep={true}
                ></AddStep>
            ) : (
                <DialogView
                    show={showEditStepModal}
                    size="lg"
                    handleClose={closeEditModal}
                    showTitle={false}
                    showFooter={false}
                    onBackdropClick={false}
                    style={
                        displayShow ? { display: "none" } : { display: "block" }
                    }
                    className={displayShow ? "" : "invisibleScroller"}
                    scrollId={"GenericOrInstanceStepId"}
                >
                    <AddStepStepper
                        Display={setDisplayShow}
                        onHide={closeEditModal}
                        hideFile={"true"}
                        saveButtonName={"NEXT"}
                        genericStepList={instanceSteps.map((step) => {
                            return {
                                ...step,
                                genericStepUniqueIdentifier:
                                    step.instanceUniqueStepIdentifier,
                                genericStepName: step.instanceStepName,
                            };
                        })}
                        isInstanceStep={true}
                        title={"Edit Step"}
                        curruntAction={"edit"}
                        editStepData={rawDataToEdit}
                        addNewStep={editExistingStep}
                        nameBranch={instanceDetails.branchName}
                        parentStepId={
                            rawDataToEdit &&
                            parseInt(rawDataToEdit.parentInstanceStepId)
                        }
                        genericSteps={genericSteps}
                    ></AddStepStepper>
                </DialogView>
            )}
            <DialogView
                show={showStepperForm}
                size="lg"
                handleClose={handleCloseStepperForm}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
            >
                <FormWrapper
                    selectedInstance={selectedInstance}
                    fetchInstanceList={fetchInstanceList}
                    onHide={handleCloseUploadDialog}
                    accountType={"headerTitle"}
                    defaultStep={1}
                    Display={setDisplayShow}
                    instanceDetails={instanceDetails}
                    genericId={genericId}
                    heading={"Upload File"}
                ></FormWrapper>
            </DialogView>
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </>
    );
};

export default InstanceStep;
