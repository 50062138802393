import React, { Component } from "react";
import "../../../src/index.css";
class Dropdown extends Component {
    constructor(props) {
        super(props);
    }
    handleChange = (event) => {
        this.props.handleChanges(
            event.currentTarget.value,
            event.currentTarget.id,
            event
        );
    };
    render() {
        return this.props.items ? (
            <select
                id={this.props.dropdownID}
                disabled={this.props.disabled}
                name={this.props.dropdownName}
                onChange={this.handleChange}
            >
                {this.props.items.map((item) => {
                    return (
                        <option
                            key={item}
                            value={item}
                            selected={
                                this.props.value && this.props.value === item
                                    ? true
                                    : false
                            }
                        >
                            {item}
                        </option>
                    );
                })}
            </select>
        ) : null;
    }
}

export default Dropdown;
