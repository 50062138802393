import { getJsDateFromExcel } from "excel-date-to-js";

export function isFloatValue(anyValue) {
    return (
        (typeof anyValue === "string" &&
            anyValue.split(".").length === 2 &&
            parseFloat(anyValue)) ||
        (typeof anyValue === "number" && !Number.isInteger(anyValue))
    );
}
export function excelDateToJSDate(dateValue) {
    let strDate = "" + dateValue;
    let dateObject = getJsDateFromExcel(dateValue);
    return dateObject;
}
export function toLocaleTime(dateObject) {
    return dateObject.toLocaleTimeString("en-US", {
        timeZone: "UTC",
        timeSyle: "short",
    });
}
export function toLocaleDate(dateObject) {
    return dateObject.toLocaleDateString("en-US", { timeZone: "UTC" });
}
export function parseDateExcelToUTC(dateValue) {
    let dateObject = excelDateToJSDate(dateValue);
    let localDate = toLocaleDate(dateObject);
    let time = toLocaleTime(dateObject);
    let temp = time.split(" ")[0].split(":");
    temp = temp[0] + ":" + temp[1] + " " + time.split(" ")[1];
    return localDate + " - " + temp;
}
export function parseJSDateToExcelDate(inDate) {
    const retValue =
        25569 +
        (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
            (1000 * 60 * 60 * 24);
    // return Number(retValue.toString().split('.')[0]); //return date only
    return Number(retValue.toString()); // return date with time
}
export function isToday(dateObject) {
    let today = new Date();
    return (
        dateObject.getYear() === today.getYear() &&
        dateObject.getMonth() === today.getMonth() &&
        dateObject.getDate() === today.getDate()
    );
}
export function isDayToday(txDate) {
    // logic for return date by day
    let dateToCheck = new Date(txDate).toLocaleDateString();
    let todaysDate = new Date().toLocaleDateString();
    if (dateToCheck === todaysDate) {
        return true;
    } else {
        return false;
    }
}

export function getFormattedDate(date, isOnlyDate) {
    let ddStartDate = date.getDate();
    let mmStartDate = date.getMonth() + 1; //January is 0!
    let yyyyStartDate = date.getFullYear();
    if (ddStartDate < 10) {
        ddStartDate = "0" + ddStartDate;
    }
    if (mmStartDate < 10) {
        mmStartDate = "0" + mmStartDate;
    }
    let hhTime = date.getHours();
    let mmTime = date.getMinutes();
    let ssTime = date.getSeconds();
    let msTime = date.getMilliseconds();
    if (hhTime < 10) {
        hhTime = "0" + hhTime;
    }
    if (mmTime < 10) {
        mmTime = "0" + mmTime;
    }
    if (ssTime < 10) {
        ssTime = "0" + ssTime;
    }
    if (msTime < 10) {
        msTime = "00" + msTime;
    } else if (msTime < 100 && msTime > 9) {
        msTime = "0" + msTime;
    }
    let startDate = "";
    if(isOnlyDate) {
        startDate = 
        yyyyStartDate +
        "-" +
        mmStartDate +
        "-" +
        ddStartDate
    } else {
        startDate =
        yyyyStartDate +
        "-" +
        mmStartDate +
        "-" +
        ddStartDate +
        " " +
        hhTime +
        ":" +
        mmTime +
        ":" +
        ssTime +
        "." +
        msTime;
    }
    return startDate;
}

export function isDayYesterday(txDate) {
    let dateToCheck = new Date(txDate).toLocaleDateString();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (dateToCheck === yesterday.toLocaleDateString()) {
        return true;
    } else {
        return false;
    }
}
// export function convertServerDateIntoUTC(dateStr) {
//     return dateStr + " UTC";
// }
export function convertServerDateIntoUTC(serverDate) {
    let date = new Date(serverDate);
    let newDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );

    // let offset = date.getTimezoneOffset() / 60;
    // let hours = date.getHours();

    // newDate.setHours(hours - offset);

    return newDate;
}
export function convertLocalDateIntoUTCDate(dateStr) {
    let now = new Date(dateStr);
    let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return utc;
}
export function getParsedDateByDateAndTime(dateString) {
    let utcDate = convertServerDateIntoUTC(dateString);
    let dateStr = new Date(utcDate);
    let isToday = isDayToday(dateStr);
    let date = `${
        dateStr.getMonth() + 1
    }/${dateStr.getDate()}/${dateStr.getFullYear()}`;
    let hours = dateStr.getHours();
    let minutes = dateStr.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + "" + ampm;
    if (!isToday) {
        return date + " - " + strTime;
    } else return "Today - " + strTime;
}
export function getDateByRange(rangeType) {
    let utcDate = convertServerDateIntoUTC(new Date());
    let dateStr = "";
    switch (rangeType) {
        case "today":
            dateStr = new Date(utcDate);
            break;
        case "Last 7 days": {
            let now = new Date();
            let last30days = new Date(now.setDate(now.getDate() - 7));
            dateStr = new Date(convertServerDateIntoUTC(last30days));
            break;
        }
        case "Last 14 days": {
            let now = new Date();
            let last30days = new Date(now.setDate(now.getDate() - 14));
            dateStr = new Date(convertServerDateIntoUTC(last30days));
            break;
        }
        case "Last 30 days": {
            let now = new Date();
            let last30days = new Date(now.setDate(now.getDate() - 30));
            dateStr = new Date(convertServerDateIntoUTC(last30days));
            break;
        }
        case "PrevMonthStartDate": {
            let date = new Date();
            let fisrstDay = new Date(
                date.getFullYear(),
                date.getMonth() - 1,
                1
            );
            dateStr = new Date(convertServerDateIntoUTC(fisrstDay));
            break;
        }
        case "PrevMonthLastDate": {
            let date = new Date();
            let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            dateStr = new Date(convertServerDateIntoUTC(lastDay));
            break;
        }
        case "Last 90 days": {
            let now = new Date();
            let last30days = new Date(now.setDate(now.getDate() - 90));
            dateStr = new Date(convertServerDateIntoUTC(last30days));
            break;
        }
        case "Last Year": {
            let now = new Date();
            let lastYear = new Date(now.setDate(now.getDate() - 365));
            dateStr = new Date(convertServerDateIntoUTC(lastYear));
            break;
        }
        default:
            break;
    }
    let dd =
        dateStr.getDate() < 10 ? `0${dateStr.getDate()}` : dateStr.getDate();
    const month = dateStr.getMonth() + 1;
    let mm = month < 10 ? `0${month}` : month;
    return `${dateStr.getFullYear()}-${mm}-${dd}`;
}
export function getDateByFormat(dateString, format, type) {
    let utcDate = convertServerDateIntoUTC(dateString);
    //let time = utcDate.split(" ")[1].substring(0, 5);
    let dateStr = new Date(utcDate);
    let arrayTime = dateStr.toLocaleTimeString().split(":");
    arrayTime.pop();
    let time = arrayTime.join(":");
    let isToday = isDayToday(dateStr);
    let monthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    // if (!isToday) {
    let dd =
        dateStr.getDate() < 10 ? `0${dateStr.getDate()}` : dateStr.getDate();
    let mm =
        dateStr.getMonth() + 1 < 10
            ? `0${dateStr.getMonth() + 1}`
            : dateStr.getMonth() + 1;
    let month = monthArr[dateStr.getMonth()];
    if (format === "dd/mm/yyyy") {
        let date = `${dd}/${mm}/${dateStr.getFullYear()}`;
        return date + " " + time;
    } else if (format === "mm/dd/yyyy") {
        let hours = dateStr.getHours();
        let minutes = dateStr.getMinutes();
        let ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let date = `${mm}/${dd}/${dateStr.getFullYear()}`;
        return date + " " + `${hours}:${minutes} ${ampm}`;
    } else if (format === "month dd,yyyy" && type === "alert") {
        let hours = dateStr.getHours();
        let minutes = dateStr.getMinutes();
        let ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let date = `${month.substring(0, 3)} ${dd}, ${dateStr.getFullYear()}`;
        return `${date} at ${hours}:${minutes} ${ampm}`;
    } else if (format === "month dd,yyyy") {
        let date = `${month} ${dd},${dateStr.getFullYear()}`;
        return date + " " + time;
    } else if (format === "YYYY-MM-DD") {
        let date = `${dateStr.getFullYear()}-${mm}-${dd}`;
        return date;
    }
    // } else {
    //     return "Today";
    // }
}
export function getChatTimestamp(dateString, format) {
    if (dateString && dateString !== undefined) {
        let utcDate = convertServerDateIntoUTC(dateString);
        let dateStr = new Date(utcDate);
        let isToday = isDayToday(dateStr);
        let isYesterday = isDayYesterday(dateStr);
        let monthArr = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        let hh =
            dateStr.getHours() < 10
                ? `0${dateStr.getHours()}`
                : dateStr.getHours();
        let mm =
            dateStr.getMinutes() < 10
                ? `0${dateStr.getMinutes()}`
                : dateStr.getMinutes();
        let ampm = hh >= 12 ? "PM" : "AM";
        hh = hh % 12;
        hh = hh ? hh : 12;
        if (isToday == true) {
            if (format.includes("hh:mm aa")) {
                return `Today ${hh}:${mm} ${ampm}`;
            } else return `Today`;
        } else if (isYesterday === true) {
            if (format.includes("hh:mm aa")) {
                return `Yesterday ${hh}:${mm} ${ampm}`;
            } else {
                return `Yesterday`;
            }
        } else {
            let dd =
                dateStr.getDate() < 10
                    ? `0${dateStr.getDate()}`
                    : dateStr.getDate();
            let MM =
                dateStr.getMonth() + 1 < 10
                    ? `0${dateStr.getMonth() + 1}`
                    : dateStr.getMonth() + 1;
            let month = monthArr[dateStr.getMonth()];
            if (format === "dd/mm/yyyy") {
                let date = `${dd}/${MM}/${dateStr.getFullYear()}`;
                return date;
            } else if (format === "mm/dd/yyyy") {
                let date = `${MM}/${dd}/${dateStr.getFullYear()}`;
                return date;
            } else if (format === "mm/dd/yyyy hh:mm aa") {
                let date = `${MM}/${dd}/${dateStr.getFullYear()} ${hh}:${mm} ${ampm}`;
                return date;
            } else if (format === "month dd,yyyy") {
                let date = `${month} ${dd},${dateStr.getFullYear()}`;
                return date;
            }
        }
    } else return dateString;
}
export function convertUTCTimeToLocalTime(timeString) {
    if (timeString !== undefined && timeString !== "") {
        let localDate = new Date();
        let timeStringArr = timeString.split(":");
        let hh = timeStringArr[0];
        let mm = timeStringArr[1];
        let ss = timeStringArr[2];
        localDate.setUTCHours(hh);
        localDate.setUTCMinutes(mm);
        localDate.setUTCSeconds(ss);
        return localDate.toLocaleTimeString();
    }
}
export function validateName(name) {
    let pattern = new RegExp(/[\\/.?:*|"<>]/);
    let result = pattern.test(name);
    return result;
}
export function validateNameLength(name) {
    if (name.length > 100) {
        return false;
    } else {
        return true;
    }
}
export function ValidateEmail(userEmail) {
    var mailformat =
        /^(([^<>()\[\]\\.,;!:\s@"]+(\.[^<>()\[\]\\.,;:!\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (userEmail !== "" && userEmail.trim().match(mailformat)) {
        return true;
    } else {
        return false;
    }
}
