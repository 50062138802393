import React, { useState, useEffect } from "react";
import ControlTower from "../../../../../bae-client/ControlTower";
import WebGridInterface from "../../../WebGridInterface";
import AlertDialog from "../../../../stylized/AlertDialog";
import Loader from "../../../../stylized/Loader";
const controlTower = new ControlTower();

function Instance({ genericResponse }) {
    const [loadWebGrid, setLoadWebGrid] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showLoader, setshowLoader] = useState(false);
    
    useEffect(() => {
        if (genericResponse.genericTblId) {
            setshowLoader(true)
            controlTower
                .updateInstanceRegistryCuboid(genericResponse.genericTblId)
                .then((response) => {
                    setLoadWebGrid(true);
                    setshowLoader(false)
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setshowLoader(false)
                    setAlertDialogMessage(error.error);
                    console.error(error);
                });
        }
    }, []);
    return loadWebGrid ? (
        <>
            <WebGridInterface
                cuboidId={genericResponse.instanceRegistryTblId}
                options={{
                    functionPosition: "right",
                    instanceRegistryTbl: true,
                }}
            ></WebGridInterface>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
            <Loader show={showLoader}/>
        </>
    ) : null;
}

export default Instance;
