import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getDateByRange, getDateByFormat } from "../../bae-client/Utils";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 260,
        },
    },
};
function CustomArrowDropDownIcon(props) {
    return (
        <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowDropDownIcon"
            id="Date_Range_ArrowDropDownIcon"
        >
            <path d="M7 10l5 5 5-5z"></path>
        </svg>
    );
}

export default function CustomDateDropdown({
    setSelectedTimeFilter,
    selection,
    customClassName,
    dateErr,
    setDateErr,
    setDateInfo,
    err,
    customWidth,
    module,
    isDashboardAlert,
    label,
    customMargin,
    disableDate,
}) {
    const [selectedDateType, setSelectedDateType] = React.useState("");
    const [selectedStartDate, setSelectedStartDate] = React.useState(null);
    const [selectedLastDate, setSelectedLastDate] = React.useState(null);
    const [dateError, setDateError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("");
    React.useEffect(() => {
        if (selection) {
            setSelectedDateType(selection.selectedTimeType);
            setSelectedStartDate(dayjs(selection.startDate));
            setSelectedLastDate(dayjs(selection.endDate));
        }
    }, [selection]);
    React.useEffect(() => {
        if(!dateErr){
            setDateError(false);
            setErrMsg("");
            setDateErr(false);
        }
    }, [dateErr]);
    React.useEffect(() => {
        err && setDateInfo(dateError);
    }, [dateError]);
    React.useEffect(() => {
        if(errMsg === ""){
            setDateErr(false);
        }else setDateErr(true)
    }, [errMsg]);
    React.useEffect(() => {
        if (
            !selectedStartDate &&
            !selectedLastDate &&
            selectedDateType === "Custom" &&
            err
        ) {
            setErrMsg("select start date and end date");
            setDateError(true);
        }
    }, [selectedDateType]);
    const handleTypeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedDateType(value);
        setStratAndEndDates(value);
    };
    const setStratAndEndDates = (text) => {
        if (text === "All" || text === "Custom") {
            setSelectedStartDate(null);
            setSelectedLastDate(null);
            if (text === "All") {
                setErrMsg("");
                setDateError(false);
            }
        } else {
            setSelectedStartDate(dayjs(getDateByRange(text)));
            setSelectedLastDate(dayjs(new Date()));
            setDateError(false);
            setErrMsg("");
        }
    };

    const handleBlur = () => {
        let dateRangeObj;
        if (selectedDateType) {
            dateRangeObj = {
                selectedTimeType: selectedDateType,
                startDate:
                    selectedLastDate === null
                        ? ""
                        : getDateByFormat(
                              new Date(selectedStartDate),
                              "YYYY-MM-DD"
                          ),
                endDate:
                    selectedLastDate === null
                        ? ""
                        : getDateByFormat(
                              new Date(selectedLastDate),
                              "YYYY-MM-DD"
                          ),
            };
        } else {
            dateRangeObj = {
                selectedTimeType: "",
                startDate: "",
                endDate: "",
            };
        }
        setSelectedTimeFilter(dateRangeObj);
    };
    const handleStartDateChange = (date) => {
        // setSelectedStartDate(date);
        // let date = event.currentTarget.value;

        const date1 = new Date(selectedLastDate);
        const date2 = new Date(date);
        if (date1 < date2 && selectedLastDate !== null) {
            setDateError(true);
            setErrMsg("End Date should be greater");
            setSelectedStartDate(date);

            // resetDateFilters()
        } else if (date1 >= date2) {
            setSelectedStartDate(date);
            setDateError(false);
            setErrMsg("");
        } else {
            setSelectedStartDate(date);
        }
    };
    const handleLastDateChange = (date) => {
        // setSelectedLastDate(date);
        // let date = event.currentTarget.value;
        const date1 = new Date(selectedStartDate);
        const date2 = new Date(date);
        if (date1 > date2 && selectedStartDate !== null) {
            setSelectedLastDate(date);

            setDateError(true);
            setErrMsg("End Date should be greater");
            // resetDateFilters()
        } else if (date1 <= date2) {
            setSelectedLastDate(date);
            setDateError(false);
            setErrMsg("");
        } else {
            setSelectedLastDate(date);
        }
    };

    return (
        <div
            style={{
                width: module !== "dropDownWidth" && "100%",
                margin: customMargin ? customMargin : "8px",
                minWidth: module === "dropDownWidth" && "9rem",
            }}
        >
            <FormControl
                sx={{ width: "100%" }}
                size="small"
                className={customClassName}
            >
                {/* <InputLabel id="demo-multiple-checkbox-label">
                    Date Range
                </InputLabel> */}
                <Select
                    sx={{ fontFamily: "Montserrat", height: "36px", margin: 0 }}
                    labelId="demo-multiple-checkbox-label"
                    id="select-date-range-btn"
                    multiple={false}
                    value={selectedDateType}
                    onBlur={handleBlur}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        if (!selected)
                            return (
                                <em
                                    style={{
                                        paddingLeft: "7px",
                                    }}
                                >
                                    {label ? label : "Date Range"}
                                </em>
                            );
                        return selected;
                    }}
                    MenuProps={MenuProps}
                    IconComponent={CustomArrowDropDownIcon}
                    displayEmpty
                >
                    {isDashboardAlert === true ? null : (
                        <FormControl
                            sx={{
                                marginTop: 2,
                                width: "100%",
                                padding: "0px 8px",
                            }}
                            size="small"
                        >
                            {/* <InputLabel id="demo-multiple-checkbox-label">
                            Select Period
                        </InputLabel> */}
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="select-time-btn"
                                multiple={false}
                                value={selectedDateType}
                                onChange={handleTypeChange}
                                onClick={() => {}}
                                // input={<OutlinedInput label="Tag" />}
                                MenuProps={MenuProps}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected)
                                        return (
                                            <em
                                                style={{
                                                    fontStyle: "normal",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Select Period
                                            </em>
                                        );
                                    return selected;
                                }}
                            >
                                <MenuItem
                                    key={"Last 7 days"}
                                    id="Last 7 days"
                                    value={"Last 7 days"}
                                >
                                    Last 7 days
                                </MenuItem>
                                <MenuItem
                                    key={"Last 14 days"}
                                    value={"Last 14 days"}
                                    id="Last 14 days"
                                >
                                    Last 14 days
                                </MenuItem>
                                <MenuItem
                                    key={"Last 30 days"}
                                    value={"Last 30 days"}
                                    id="Last 30 days"
                                >
                                    Last 30 days
                                </MenuItem>
                                <MenuItem
                                    key={"Last 90 days"}
                                    value={"Last 90 days"}
                                    id="Last 90 days"
                                >
                                    Last 90 days
                                </MenuItem>
                                <MenuItem
                                    key={"Last Year"}
                                    value={"Last Year"}
                                    id="Last Year"
                                >
                                    Last Year
                                </MenuItem>
                                <MenuItem key={"All"} value={"All"} id="All">
                                    All
                                </MenuItem>
                                <MenuItem
                                    key={"Custom"}
                                    value={"Custom"}
                                    id="Custom"
                                >
                                    Custom
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl
                            sx={{
                                width: "100%",
                                padding: "0px 8px",
                            }}
                            size="small"
                        >
                            <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                    disabled={
                                        !(
                                            selectedDateType === "Custom" ||
                                            isDashboardAlert === true
                                        )
                                    }
                                    label="Start Date"
                                    value={selectedStartDate}
                                    disableFuture
                                    minDate={
                                        disableDate?.startDate
                                            ? dayjs(disableDate?.startDate)
                                            : null
                                    }
                                    maxDate={
                                        disableDate?.endDate
                                            ? dayjs(disableDate?.endDate)
                                            : null
                                    }
                                    onChange={(newDate) =>
                                        handleStartDateChange(newDate)
                                    }
                                />
                            </DemoContainer>
                        </FormControl>
                        <FormControl
                            sx={{
                                width: "100%",
                                padding: "0px 8px",
                            }}
                            size="small"
                        >
                            <DemoContainer
                                components={["DatePicker"]}
                                style={{ backgroundColor: "white" }}
                            >
                                <DatePicker
                                    disabled={
                                        !(
                                            selectedDateType === "Custom" ||
                                            isDashboardAlert === true
                                        )
                                    }
                                    label="End Date"
                                    value={selectedLastDate}
                                    disableFuture
                                    minDate={
                                        disableDate?.startDate
                                            ? dayjs(disableDate?.startDate)
                                            : null
                                    }
                                    maxDate={
                                        disableDate?.endDate
                                            ? dayjs(disableDate?.endDate)
                                            : null
                                    }
                                    onChange={(newDate) =>
                                        handleLastDateChange(newDate)
                                    }
                                />
                            </DemoContainer>
                        </FormControl>
                    </LocalizationProvider>
                    {dateError && (
                        <div style={{ color: "red", marginLeft: "1rem" }}>
                            {errMsg}
                        </div>
                    )}
                </Select>
            </FormControl>
        </div>
    );
}
