import CuboidManager from "./CuboidManager";
import http from "./HTTP";
class RippleManager {
    constructor() {
        // if (options !== undefined) {
        //     this.rippleCollabId = options.rippleCollabId;
        //     this.rippleWBId = options.rippleWBId;
        //     this.rippleLogCuboidId = options.rippleLogCuboidId;
        //     this.rippleRegistryCuboidId = options.rippleRegistryCuboidId;
        // }
        //this.rippleCuboidManager = new CuboidManager(this.rippleCollabId, this.rippleWBId);
        //this.rippleLogCuboidManager = new CuboidManager(this.rippleCollabId, this.rippleWBId);
        this.rippleCuboidData = null;
        this.rippleLogCuboidData = null;
        this.idToRippleName = {};
        this.idToIndex = {};
        this.nameToRippleId = {};
    }
    loadRippleLog = () => {
        return new Promise((resolve, reject) => {
            let url = "ripple/rippleRegistry";
            http.sendRestRequest(url)
                .then((response) => {
                    this.rippleCuboidData = response;
                    this.createDataStructreForRipple().then((res) => {
                        resolve(res);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    updateRippleName = (data) => {
        return new Promise((resolve, reject) => {
            let url = "ripple/rippleRegistry";
            http.sendRestRequest(url, "PUT", data)
                .then((response) => {
                    // this.rippleCuboidData = response;
                    this.loadRippleLog()
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    createDataStructreForRipple = () => {
        return new Promise((resolve, reject) => {
            let rippleLogData = [];
            if (
                this.rippleCuboidData !== null &&
                this.rippleCuboidData !== undefined
            ) {
                this.rippleCuboidData.forEach((logItem, index) => {
                    let logObj = {
                        rippleId: logItem.rippleRegistryId,
                        rippleName: logItem.rippleName,
                        searchCriteria: logItem.searchCriteria,
                        filterCriteriaFolders: logItem.filterCriteriaFolders,
                        filterCriteriaSpaces: logItem.filterCriteriaSpaces,
                        filterCriteriaDates: logItem.filterCriteriaDates,
                        filterCriteriaWorkspaces:
                            logItem.filterCriteriaWorkspaces,
                        rippleRecur: logItem.rippleRecur,
                        searchResults: logItem.searchResults,
                        updatedOn: logItem.updatedOn,
                        createdOn: logItem.createdOn,
                    };
                    rippleLogData.push(logObj);
                    this.idToRippleName[logItem.rippleId] = logItem.rippleName;
                    this.nameToRippleId[logItem.rippleName] =
                        logItem.rippleRegistryId;
                    this.idToIndex[logItem.rippleId] = index;
                });
                resolve(rippleLogData);
            } else {
                reject(rippleLogData);
            }
        });
    };
    getRowSequenseById = (rowId) => {
        return this.idToIndex[rowId];
    };
    checkExistingRippleName = (rippleName) => {
        let flag = false;
        if (rippleName in this.nameToRippleId) {
            flag = true;
        }
        return flag;
    };
    getRippleIdByName = (rippleName) => {
        return this.nameToRippleId[rippleName];
    };

    setRippleResponse = (response) => {
        this.rippleCuboidData = response;
    };
    setRipleLogResponse = (response) => {
        this.rippleLogCuboidData = response;
    };
    generateColData = (respData) => {
        // create column data for ripple log
        let colomnData = respData.columns.colArr;
        let colNames = [];
        colomnData.forEach((col) => {
            colNames.push(col.columnName);
        });
        return colNames;
    };
    generateRowData = (respData) => {
        // create row data for ripple log
        let rowObj = [];
        let responseData = respData.cells.mapRow;
        responseData.forEach((row, index) => {
            let rowData = row;
            let strValues = [];
            let rowId = null;
            rowData.forEach((col, index) => {
                rowId = col.rowId;
                strValues.push(col.stringValue);
            });
            rowObj.push({
                rowId: rowId,
                stringValue: strValues,
            });
        });
        rowObj.sort((a, b) => a.rowId - b.rowId);
        return rowObj;
    };
    generateRippleRow = (data) => {
        let rows = {
            rows: [data],
        };
        return rows;
    };
}
export default RippleManager;
