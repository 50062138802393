function getTargetAndContainer(fileType) {
    let elementsObj = {};
    if (
        fileType === "jpg" ||
        fileType === "png" ||
        fileType === "txt" ||
        fileType === "json" ||
        fileType === "edi"
    ) {
        let target = document.getElementById("proxy-renderer");
        let container = document.getElementById("react-doc-viewer");
        elementsObj.target = target;
        elementsObj.container = container;
        return elementsObj;
    } else if (
        fileType === "pdf" ||
        fileType === "ai" ||
        fileType === "docx" ||
        fileType === "doc" ||
        fileType === "xlsx" ||
        fileType === "xls" ||
        fileType === "pptx" ||
        fileType === "ppt" ||
        fileType === "mp4" ||
        fileType === "mp3"
    ) {
        let container = document.querySelector(".pg-viewer-wrapper");
        let target = document.getElementById("pg-viewer");
        elementsObj.target = target;
        elementsObj.container = container;
        return elementsObj;
    } else if (fileType === "xml") {
        let container = document.getElementById("xmlViewerContainer");
        let target = document.getElementById("xmlViewer");
        elementsObj.target = target;
        elementsObj.container = container;
        return elementsObj;
    }
}
export function zoomIn(fileType) {
    let elementsObj = getTargetAndContainer(fileType);
    let style = window.getComputedStyle(elementsObj.target);
    let scale = style.getPropertyValue("scale");
    if (scale < 2) {
        scale = parseFloat(scale) + 0.1;
        elementsObj.target.style.scale = scale;
        elementsObj.target.style.transformOrigin = "center center";
        elementsObj.container.style.overflow = "auto";
    }
}

export function zoomOut(fileType) {
    let elementsObj = getTargetAndContainer(fileType);
    let style = window.getComputedStyle(elementsObj.target);
    let scale = style.getPropertyValue("scale");
    if (scale > 1 && scale <= 2) {
        scale = parseFloat(scale) - 0.1;
        elementsObj.target.style.scale = scale;
    }
}
