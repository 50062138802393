import React, { useEffect, useState, useRef } from "react";
import http from "../../../../bae-client/HTTP";

export default function TokenExpiredAlert({
    open,
    handleSingIn,
    handleIgnore,
}) {
    // const [gamilRedirectURL, setGamilRedirectURL] = useState("#");
    const [outlookRedirectURL, setOutlookRedirectURL] = useState("#");
    // const gmailApi = useRef(null);
    const outlookApi = useRef(null);

    const handleAuthentication = (type) => {
        let url = `outlookIntegration/authentication`;
        http.sendRestRequest(url, "POST")
            .then((data) => {
                if (data.redirectUrl !== undefined) {
                    setOutlookRedirectURL(data.redirectUrl);
                    outlookApi.current.click();
                }
                handleSingIn();
            })
            .catch((data) => {
                // if (data.error === "User Doesn't exist") {
                //     setOutlookModalShow(true);
                // } else if (data.error === "Unsuccessful connection") {
                //     setOutlookModalShow(false);
                //     showAlertView("", "Invalid Credentials");
                // }
                handleSingIn();
            });
    };
    return open ? (
        <div
            style={{
                dispaly: "inline-block",
                width: "250px",
                height: "200px",
                backgroundColor: "#DCF0FF",
                position: "absolute",
                right: "20px",
                bottom: "20px",
                zIndex: "10000",
                alignItems: "center",
                borderRadius: "5%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <div style={{ fontSize: "14px", padding: "18px" }}>
                Token has expired for this outlook account, Please sign in
            </div>
            <div>
                <button
                    onClick={handleAuthentication}
                    className="btn addAlertBtn"
                >
                    Sign In
                </button>
                <button
                    onClick={handleIgnore}
                    className="btn btn-outlined"
                    style={{
                        border: "2px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "10px",
                    }}
                >
                    Ignore
                </button>
            </div>
            {/* <a
                href={gamilRedirectURL}
                target="_blank"
                rel="noopener noreferrer"
                ref={gmailApi}
                style={{ display: "none" }}
            ></a> */}
            <a
                href={outlookRedirectURL}
                target="_blank"
                rel="noopener noreferrer"
                ref={outlookApi}
                style={{ display: "none" }}
            ></a>
        </div>
    ) : null;
}
