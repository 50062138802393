import http from './HTTP';
import Document from './Document'

class DocumentUploader {
    constructor() {

    }

    uploadDocuments = (document, wbId) => {
        let verifyResponse = [];
        for (let docIndex = 0; docIndex < document.length; docIndex++) {
            let fileData = document[docIndex].getFile();
            const data = new FormData();
            data.append('file', fileData);
            let uploadResp = http.uploadFile(data, wbId).then((response) => {
                return response;
                // if (response.Status === "SUCCESS") {
                //     return document[docIndex].uploadDocumentMeta(response);
                // }

            }).catch((errorResp) => {
                console.log(errorResp);
                // alert("GridId :  " + errorResp.GridId);
                return errorResp;
            });
            verifyResponse.push(uploadResp);
        }
        return Promise.all(verifyResponse);
    }
}
export default DocumentUploader