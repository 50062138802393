import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
    validateName,
    validateStepperName,
} from "../../../../../../bae-client/Validations";
import ModalView from "../../../../../stylized/ModalView";
import TextBox from "../../../../../stylized/TextBox";
import "./style.css";
import Switch from "@mui/material/Switch";

export default function AddStep({
    showAddDocumentChainModal,
    closeModal,
    addNewStep,
    genericStepList,
    editStepData,
    btnName,
    title,
    isInstanceStep,
}) {
    const [docTitle, setdocTitle] = React.useState("");
    const [name, setName] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [dropdownOptions, setDropdownOptions] = React.useState([]);
    const [countValue, setCountValue] = React.useState("0");
    const [countTypeValue, setCountTypeValue] = React.useState("");
    const [timeValue, setTimeValue] = React.useState("");
    const [selectedDropdown, setSelectedDropdown] = React.useState("");
    const [isOptional, setIsOptional] = React.useState(false);
    const [selectTime, setSelectTime] = React.useState(false);
    const [selectDay, setSelectDay] = React.useState(false);
    const [fromValue, setFromValue] = React.useState("");
    const [toValue, setToValue] = React.useState("");
    const [promiseStageValue, setPromiseStageValue] = React.useState("");
    const [showMore, setShowMore] = React.useState(isInstanceStep);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [promiseStageErrorMessage, setPromiseStageErrorMessage] =
        React.useState("");
    const [error, setError] = useState({
        from: "",
        to: "",
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const getDurationForStep = (mins) => {
        const min = mins;
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return `${days} days`;
        } else if (hours > 0) {
            return `${hours} hours after previous step`;
        } else return `${min} minutes after previous step`;
    };
    useEffect(() => {
        if (editStepData !== undefined && editStepData !== null) {
            setName(editStepData.genericStepName);
            setFromValue(editStepData.fromEntity);
            setToValue(editStepData.toEntity);
            setCountValue(
                getDurationForStep(editStepData.expectedDuration).split(" ")[0]
            );
            setCountTypeValue(
                getDurationForStep(editStepData.expectedDuration).split(" ")[1]
            );
            setPromiseStageValue(editStepData.promiseType);
        }
    }, [editStepData]);

    const getExpectedDurationInHour = () => {
        if (countTypeValue.toLocaleLowerCase() === "days")
            return countValue * 24 * 60;
        else if (countTypeValue.toLocaleLowerCase() === "weeks")
            return countValue * 24 * 60 * 7;
        else if (countTypeValue.toLocaleLowerCase() === "months")
            return countValue * 24 * 60 * 30;
        else if (countTypeValue.toLocaleLowerCase() === "hours")
            return countValue * 60;
        else if (countTypeValue.toLocaleLowerCase() === "minutes")
            return parseInt(countValue);
        else return parseInt(countValue);
    };
    const handleCloseModal = () => {
        setName("");
        setDropdownOptions([]);
        setCountValue("0");
        setCountTypeValue("");
        setTimeValue("");
        setSelectedDropdown("");
        setIsOptional(false);
        setSelectTime(false);
        setSelectDay(false);
        setShowMore(false);
        setPromiseStageValue("");
        setToValue("");
        setFromValue("");
        setErrorMessage("");
        setPromiseStageErrorMessage("");
        closeModal();
    };
    const validateStepName = () => {
        const stepNames = genericStepList.map((stepName) =>
            stepName.genericStepName.toUpperCase().trim()
        );
        const isValidName = validateName(name);
        if (!name && !isInstanceStep) {
            setErrorMessage("Name cannot be empty");
            return false;
        } else if (isValidName !== true && !isInstanceStep) {
            setErrorMessage(isValidName);
            return false;
        } else if (
            (editStepData === null || editStepData === undefined) &&
            stepNames.includes(name.trim().toUpperCase())
        ) {
            setErrorMessage("Step Name Already Exist");
            return false;
        } else if (
            editStepData !== null &&
            editStepData !== undefined &&
            editStepData.genericStepName.toLowerCase().trim() !==
                name.toLowerCase().trim() &&
            stepNames.includes(name.trim().toUpperCase())
        ) {
            setErrorMessage("Step Name Already Exist");
            return false;
        } else return true;
    };

    const handleSubmit = () => {
        if (validateStepName() && error.from === "" && error.to === "") {
            addNewStep(
                {
                    genericStepName: name,
                    fromEntity: fromValue,
                    toEntity: toValue,
                    promiseType: promiseStageValue,
                    expectedDuration: getExpectedDurationInHour(),
                    informationAvailable: "",
                    isOptional: isOptional,
                },
                isInstanceStep
            );
            setName("");
            setDropdownOptions([]);
            setCountValue("0");
            setCountTypeValue("");
            setTimeValue("");
            setSelectedDropdown("");
            setIsOptional(false);
            setSelectTime(false);
            setSelectDay(false);
            setShowMore(false);
            setErrorMessage("");
            setPromiseStageValue("");
            setToValue("");
            setFromValue("");
        }
    };
    const resetError = () => {
        setError({ from: "", to: "" });
    };
    const onChange = (value, id) => {
        if (errorMessage) setErrorMessage("");
        if (error.from || error.to) {
            resetError();
        }
        if (id === "stepName") {
            setName(value);
        }
        if (id === "fromValue") {
            let isValidName = validateStepperName(value);
            if (isValidName === true) {
                setFromValue(value);
            } else {
                setError({ from: isValidName });
                setFromValue(value);
            }
        }
        if (id === "toValue") {
            let isValidName = validateStepperName(value);
            if (isValidName === true) {
                setToValue(value);
            } else {
                setError({ to: isValidName });
                setToValue(value);
            }
        }
        // if (value.length > 100) {
        //     setErrMsg("Name size should be maximum 100 characters");
        // }
        else {
            setdocTitle(value);
            setErrMsg("");
        }
    };
    const handleClick = (event) => {
        setSelectedDropdown("");
        setAnchorEl(event.currentTarget);
        if (event.currentTarget && event.currentTarget.id === "count") {
            setSelectedDropdown("count");
            setDropdownOptions([
                { id: 1, value: 1 },
                { id: 2, value: 2 },
                { id: 3, value: 3 },
                { id: 4, value: 4 },
                { id: 5, value: 5 },
                { id: 6, value: 6 },
                { id: 7, value: 7 },
                { id: 8, value: 8 },
                { id: 9, value: 9 },
                { id: 10, value: 10 },
                { id: 11, value: 11 },
                { id: 12, value: 12 },
                { id: 13, value: 13 },
                { id: 14, value: 14 },
                { id: 15, value: 15 },
                { id: 16, value: 16 },
                { id: 17, value: 17 },
                { id: 18, value: 18 },
                { id: 19, value: 19 },
                { id: 20, value: 20 },
                { id: 21, value: 21 },
                { id: 22, value: 22 },
                { id: 23, value: 23 },
                { id: 24, value: 24 },
            ]);
        }
        if (event.currentTarget && event.currentTarget.id === "countType") {
            setSelectedDropdown("countType");
            setDropdownOptions([
                { id: 5, value: "Minutes" },
                { id: 1, value: "Hours" },
                { id: 2, value: "Days" },
                { id: 3, value: "Weeks" },
                { id: 4, value: "Months" },
            ]);
        }
        if (event.currentTarget && event.currentTarget.id === "time") {
            setSelectedDropdown("time");
            setDropdownOptions([
                { id: 1, value: "day" },
                { id: 2, value: "week" },
                { id: 3, value: "month" },
            ]);
        }
        if (event.currentTarget && event.currentTarget.id === "promiseStage") {
            setPromiseStageErrorMessage("");
            setSelectedDropdown("promiseStage");
            setDropdownOptions([
                { id: 1, value: "Initiation" },
                { id: 2, value: "Commitment" },
                { id: 3, value: "Fulfillment" },
                { id: 4, value: "Settlement" },
                { id: 5, value: "Completion" },
            ]);
        }
    };
    const handleClose = (e) => {
        // handleCloseInstanceDropdown(e);
        // setDropdownValue(e.target.innerHTML);
        if (selectedDropdown === "count") {
            setCountValue(e.target.innerHTML);
        }
        if (selectedDropdown === "countType") {
            setCountTypeValue(e.target.innerHTML);
        }
        if (selectedDropdown === "time") {
            setTimeValue(e.target.innerHTML);
        }
        if (selectedDropdown === "promiseStage") {
            setPromiseStageValue(e.target.innerHTML);
            if (genericStepList !== undefined) {
                if (
                    genericStepList
                        .map((item) => item.promiseType)
                        .includes(e.target.innerHTML)
                )
                    setPromiseStageErrorMessage(
                        "The selected stage already present!"
                    );
            }
        }
        setAnchorEl(null);
    };
    const handleChangeOptionalToggel = (e) => {
        setIsOptional(e.target.checked);
    };
    return (
        <ModalView
            showHeader={true}
            footer="false"
            show={showAddDocumentChainModal}
            size="md"
            onHide={handleCloseModal}
            title={btnName}
            id="btn_folder_submit"
            onSubmit={handleSubmit}
            disabled={error.from !== "" || error.to !== "" ? true : false}
            value="Name"
            onclick={handleCloseModal}
            type="submit-cancel"
            submitButtonText="Close"
            headerTitle={title}
        >
            <div className="formLabel invisibleScroller">
                {isInstanceStep && title === "Add New Step" ? null : (
                    <div className="modalRow">
                        <div>*Name</div>
                        <TextBox
                            id="stepName"
                            className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                            type="text"
                            style={{ marginTop: "-20px" }}
                            onchange={onChange}
                            label="Name"
                            value={name}
                            formControl="Off"
                            doCenter={true}
                            disabled={isInstanceStep}
                        ></TextBox>
                        {errMsg && (
                            // <div className="w-100 m-0">
                            <>
                                <small
                                    style={{
                                        color: "red",
                                        paddingLeft: "45px",
                                        paddingTop: "10px",
                                    }}
                                >
                                    {errMsg}
                                </small>
                            </>
                        )}
                    </div>
                )}
                {errorMessage && (
                    <>
                        <small style={{ color: "red" }}>{errorMessage}</small>
                    </>
                )}
                {isInstanceStep ? null : (
                    <div className="modalSubRow">
                        <span>Make Optional</span>
                        {/* <span> */}

                        <Switch
                            style={{ zIndex: "1" }}
                            defaultChecked={isOptional}
                            checked={isOptional}
                            onChange={handleChangeOptionalToggel}
                            inputProps={{ "aria-label": "controlled" }}
                        ></Switch>
                        {/* </span> */}
                    </div>
                )}
                <div className="modalRow">
                    <div>Select time delay after previous step</div>
                    <div className="modalSubRow">
                        <div style={{ margin: "0px 12px 0px 0px" }}>
                            <Button
                                id="countType"
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon />}
                                className={
                                    editStepData && editStepData.documentName
                                        ? "disabledButtonStyle"
                                        : "buttonStyle"
                                }
                                disabled={
                                    editStepData && editStepData.documentName
                                        ? true
                                        : false
                                }
                            >
                                {countTypeValue || "Minutes"}
                            </Button>
                        </div>
                        <div style={{ margin: "0px 12px 0px 0px" }}>
                            <Button
                                id="count"
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon />}
                                className={
                                    editStepData && editStepData.documentName
                                        ? "disabledButtonStyle"
                                        : "buttonStyle"
                                }
                                disabled={
                                    editStepData && editStepData.documentName
                                        ? true
                                        : false
                                }
                            >
                                {countValue || "0"}
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <div className="modalRow">
                    <div>
                        <Checkbox
                            defaultChecked={selectTime}
                            style={{ marginLeft: "-9px" }}
                            onChange={(e) => setSelectTime(e.target.checked)}
                        ></Checkbox>{" "}
                        select time of day
                    </div>
                    {selectTime && (
                        <div
                            style={{
                                margin: "0px 12px 0px 0px",
                                maxWidth: "33%",
                            }}
                        >
                            <Button
                                id="time"
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon />}
                                className="buttonStyle"
                            >
                                {timeValue || "time"}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="modalRow">
                    <div>
                        <Checkbox
                            defaultChecked={selectDay}
                            style={{ marginLeft: "-9px" }}
                            onChange={(e) => setSelectDay(e.target.checked)}
                        ></Checkbox>
                        select day of week
                    </div>
                    {selectDay && (
                        <div className="weekDays-selector">
                            <input
                                type="checkbox"
                                id="weekday-mon"
                                className="weekday"
                                // onChange={(e) => handleDayChange(1)}
                            />
                            <label
                                for="weekday-mon"
                                // className={
                                //     monday === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                MON
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-tue"
                                className="weekday"
                                // onChange={(e) => handleDayChange(2)}
                            />
                            <label
                                for="weekday-tue"
                                // className={
                                //     tue === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                TUE
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-wed"
                                className="weekday"
                                // onChange={(e) => handleDayChange(3)}
                            />
                            <label
                                for="weekday-wed"
                                // className={
                                //     wed === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                WED
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-thu"
                                className="weekday"
                                // onChange={(e) => handleDayChange(4)}
                            />
                            <label
                                for="weekday-thu"
                                // className={
                                //     thus === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                THU
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-fri"
                                className="weekday"
                                // onChange={(e) => handleDayChange(5)}
                            />
                            <label
                                for="weekday-fri"
                                // className={
                                //     fri === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                FRI
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-sat"
                                className="weekday"
                                // onChange={(e) => handleDayChange(6)}
                            />
                            <label
                                for="weekday-sat"
                                // className={
                                //     sat === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                SAT
                            </label>
                            <input
                                type="checkbox"
                                id="weekday-sun"
                                className="weekday"
                                // onChange={(e) => handleDayChange(7)}
                            />
                            <label
                                for="weekday-sun"
                                // className={
                                //     sun === true
                                //         ? "recure_On_label selectedDate"
                                //         : "recure_On_label"
                                // }
                            >
                                SUN
                            </label>
                        </div>
                    )}
                </div> */}
                {showMore || isInstanceStep ? (
                    <>
                        {isInstanceStep ? null : (
                            <div className="modalRow">
                                <div>
                                    Add to Space or Folder in Unity Central
                                </div>
                                <TextBox
                                    id="spaceOrFolder"
                                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                                    type="text"
                                    style={{ marginTop: "-20px" }}
                                    onchange={onChange}
                                    label="Name"
                                    // value={}
                                    formControl="Off"
                                    doCenter={true}
                                ></TextBox>
                            </div>
                        )}
                        <div className="modalRow">
                            <div className="modalSubRow">
                                <div style={{ width: "53%" }}>From</div>
                                <div style={{ width: "47%" }}>To</div>
                            </div>
                            <div className="modalSubRow">
                                <div
                                    style={{
                                        maxWidth: "48%",
                                        marginRight: "13px",
                                    }}
                                >
                                    <TextBox
                                        id="fromValue"
                                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                                        type="text"
                                        style={{ marginTop: "-20px" }}
                                        onchange={onChange}
                                        label="Name"
                                        value={fromValue}
                                        formControl="Off"
                                        doCenter={true}
                                    ></TextBox>
                                    {error.from && (
                                        <small
                                            style={{
                                                color: "red",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            {error.from}
                                        </small>
                                    )}
                                </div>
                                <div style={{ maxWidth: "48%" }}>
                                    <TextBox
                                        id="toValue"
                                        className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                                        type="text"
                                        style={{ marginTop: "-20px" }}
                                        onchange={onChange}
                                        value={toValue}
                                        label="Name"
                                        formControl="Off"
                                        doCenter={true}
                                    ></TextBox>
                                    {error.to && (
                                        <small
                                            style={{
                                                color: "red",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            {error.to}
                                        </small>
                                    )}
                                </div>
                            </div>
                            {isInstanceStep ? null : (
                                <div className="modalRow">
                                    <div>Promise Stage</div>
                                    <div>
                                        <Button
                                            id="promiseStage"
                                            variant="outlined"
                                            disableElevation
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            className="buttonStyle"
                                            style={{ marginBottom: "12px" }}
                                        >
                                            {promiseStageValue ||
                                                "Promise Stage"}
                                        </Button>
                                    </div>
                                    {/* {promiseStageErrorMessage && (
                                        <>
                                            <small style={{ color: "red" }}>
                                                {promiseStageErrorMessage}
                                            </small>
                                        </>
                                    )} */}
                                </div>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
            {!isInstanceStep && (
                <div
                    style={{
                        textDecoration: "underline",
                        position: "absolute",
                    }}
                    className="formLabel cursorPointer"
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? "Show Less" : "Show More"}
                </div>
            )}

            <Menu
                classes={{ list: "menu-custom" }}
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {dropdownOptions.map((instance, index) => {
                    return (
                        <MenuItem
                            className="menu-item-custom"
                            key={instance.id}
                            onClick={handleClose}
                            disableRipple
                            id={instance.id}
                        >
                            {instance.value}
                        </MenuItem>
                    );
                })}
            </Menu>
        </ModalView>
    );
}
